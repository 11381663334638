import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';

import { TableColumn } from '../../interface/table-column.interface';
import { TableData } from '../../interface/table-data.interface';
import { TableCellDataTypesInterface } from '../../interface/tablecelldatatype.interface';
import { GridConstraintCellDetail } from '../../models/field.class';
import { GridCellData } from '../../models/record/gridfield.interface';
import { Record } from '../../models/record/record.class';
import { RecordField } from '../../models/record/recordfield.class';

@Component({
    selector: 'app-entity-record-field-grid-table',
    templateUrl: './entity-record-field-grid-table.component.html',
    styleUrls: ['./entity-record-field-grid-table.component.scss'],
})
export class EntityRecordFieldGridTableComponent implements OnInit, OnDestroy, OnChanges {
    @Input() public field: RecordField;
    @Input() public row: TableData;
    @Input() public column: TableColumn;
    @Input() public readOnly: boolean;
    @Input() public eventType: string;
    @Input() public record?: Record;
    @Input() public disabled: boolean;

    public cell: GridCellData;
    public cellMeta: GridConstraintCellDetail;
    public errorField: RecordField;
    public tableData: TableData[] = [];
    public cellDataTypes: TableCellDataTypesInterface;
    private recordId: string;
    public selectedColumns: string[] = [];
    public allColumns: TableColumn[] = [];

    constructor() {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.recordId || changes?.eventType?.currentValue) {
            this.applyFieldConfigs();
        }
    }
    private startComponent = () => {
        this.cell = this.field.gridData.cells.find(
            (item) => item.rowIndex == this.row.rowIndex && item.columnIndex == (this.column.id as any)
        );
        this.cell.value = this.cell.value ?? '';
        this.cellMeta = this.field.gridConstraints?.cellDetails?.find(
            (item) => item.columnIndex === this.cell?.columnIndex && item.rowIndex === this.cell?.rowIndex
        );
    };

    private applyFieldConfigs = () => {
        this.disabled =
            this.field?.autoCalculate ||
            this.field?.disabled ||
            (this.recordId && this.field?.isBusinessKey) ||
            this.field?.idGenerator !== undefined;
    };

    ngOnInit(): void {
        this.startComponent();
    }

    ngOnDestroy(): void {}
}
