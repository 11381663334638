import { createReducer, on } from '@ngrx/store';

import {
    AddBundleDetails,
    GetBundleInvitations,
    RemoveBundle,
    ResetBundles,
    ResetBundlesSearchText,
    SelectBundle,
    SelectBundleForInvitation,
    SetBundleInviations as SetBundleInvitations,
    SetBundles,
    SetBundlesOrganizationEntity,
    SetBundlesSearchText,
    UpdateBundleDetails,
} from '../actions';
import * as sessionActions from '../actions/session.actions';
import { bundlesState } from '../states/bundles.state';

export const BUNDLES_REDUCER = createReducer(
    bundlesState,
    on(sessionActions.SetCurrentOrganization, (state, action) => {
        return {
            ...state,
            currentOrganizationId: action.organization?.id,
        };
    }),
    on(SetBundlesOrganizationEntity, (state, action) => {
        return {
            ...state,
            organizations: action.organizationsAdapter.addOne(action.bundlesEntity, state.organizations),
        };
    }),
    on(SetBundlesSearchText, (state, action) => {
        return {
            ...state,
            searchText: action.searchText,
        };
    }),
    on(ResetBundlesSearchText, (state) => {
        return {
            ...state,
            searchText: undefined,
        };
    }),
    on(SetBundles, (state, action) => {
        const organizationEntity = state.organizations.entities[state.currentOrganizationId];
        return {
            ...state,
            organizations: action.organizationsAdapter.updateOne(
                {
                    id: state.currentOrganizationId,
                    changes: {
                        bundles: action.bundlesAdapter.addMany(action.bundlesResponse, organizationEntity.bundles),
                    },
                },
                state.organizations
            ),
        };
    }),
    on(SelectBundle, (state, action) => {
        return {
            ...state,
            selectedBundleId: action.id,
        };
    }),
    on(AddBundleDetails, (state, action) => {
        const organizationEntity = state.organizations.entities[state.currentOrganizationId];
        return {
            ...state,
            organizations: action.organizationsAdapter.updateOne(
                {
                    id: state.currentOrganizationId,
                    changes: {
                        bundles: action.bundlesAdapter.addOne(action.response, organizationEntity.bundles),
                    },
                },
                state.organizations
            ),
        };
    }),
    on(UpdateBundleDetails, (state, action) => {
        const organizationEntity = state.organizations.entities[state.currentOrganizationId];
        return {
            ...state,
            organizations: action.organizationsAdapter.updateOne(
                {
                    id: state.currentOrganizationId,
                    changes: {
                        bundles: action.bundlesAdapter.updateOne(
                            {
                                id: action.response.id,
                                changes: {
                                    ...action.response,
                                },
                            },
                            organizationEntity.bundles
                        ),
                    },
                },
                state.organizations
            ),
        };
    }),
    on(ResetBundles, (state) => {
        return {
            searchText: undefined,
            selectedBundleIds: [],
            currentOrganizationId: state.currentOrganizationId,
            organizations: state.organizations,
            selectedBundleId: undefined,
            bundleInvitations: state.bundleInvitations,
        };
    }),
    on(GetBundleInvitations, (state, action) => {
        return {
            ...state,
            selectedBundleId: action.id,
        };
    }),
    on(SetBundleInvitations, (state, action) => {
        return {
            ...state,
            bundleInvitations: {
                ...state.bundleInvitations,
                [state.selectedBundleId]: action.invitations,
            },
        };
    }),
    on(RemoveBundle, (state, action) => {
        const organizationEntity = state.organizations.entities[state.currentOrganizationId];
        return {
            ...state,
            organizations: action.organizationsAdapter.updateOne(
                {
                    id: state.currentOrganizationId,
                    changes: {
                        bundles: action.bundlesAdapter.removeOne(action.bundleId, organizationEntity.bundles),
                    },
                },
                state.organizations
            ),
        };
    }),
    on(SelectBundleForInvitation, (state, action) => {
        return {
            ...state,
            selectedBundleId: action.bundleId,
        };
    }),
    on(sessionActions.SessionLogoutSuccessfull, () => bundlesState)
);

export const BUNDLES_REDUCER_KEY = 'bundles';
