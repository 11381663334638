import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { translate } from '@ngneat/transloco';
import { BehaviorSubject } from 'rxjs';

import { TableColumn } from '../../interface/table-column.interface';
import { TableData } from '../../interface/table-data.interface';
import { TableCellDataTypesInterface } from '../../interface/tablecelldatatype.interface';
import { Message } from '../../models/record/message.class';
import { RecordField } from '../../models/record/recordfield.class';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';

@Component({
    selector: 'app-grid-field-modal',
    templateUrl: './grid-field-modal.component.html',
    styleUrls: ['./grid-field-modal.component.scss'],
})
export class GridFieldModalComponent implements OnInit {
    public field: RecordField;
    public readOnly: boolean;
    public recordUpdateDisabled: boolean;
    private recordId: string;
    public hideDisplayNameData: boolean;
    public allColumns: BehaviorSubject<TableColumn[]> = new BehaviorSubject([]);
    public selectedColumns: string[] = [];
    public tableData: BehaviorSubject<TableData[]> = new BehaviorSubject([]);
    public errorField: RecordField;
    public cellDataTypes: TableCellDataTypesInterface;
    public saveAndClose: (...args: any) => void;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

    initiateGridData = () => {
        this.buildColumns();
        this.buildSupportedDataTypes();
        this.buildTableData();
    };

    private buildSupportedDataTypes = () => {
        const constraints = this.field.gridConstraints;
        this.cellDataTypes = {};
        constraints.cellDetails.forEach((cell) => {
            if (!this.cellDataTypes[cell.rowIndex]) {
                this.cellDataTypes[cell.rowIndex] = {};
            }
            this.cellDataTypes[cell.rowIndex][cell.columnIndex] = {
                inputType: cell.datatype,
                outputFormat: cell.outputFormat,
                lookupInformationMetadata: cell.lookupInformationMetadata,
                entityUid: this.field.entityUid,
                fieldId: this.field.id,
                readOnly: this.recordId && cell.readOnly,
            };
        });
    };

    private buildColumns = () => {
        this.selectedColumns = [];
        const grid = this.field.gridConstraints;
        const columns: TableColumn[] = [];
        columns.push({
            hide: false,
            icon: undefined,
            id: 'firstRowColumn',
            name: translate('Rows'),
            type: 'text',
            hideInAllColumnsList: false,
            options: undefined,
            showCheckboxInHeader: false,
        });
        grid?.columnDetails.forEach((column) => {
            columns.push({
                hide: false,
                icon: undefined,
                id: column.index.toString(),
                name: column.name,
                type: this.readOnly ? 'text' : 'input',
                inputType: column.datatype,
                outputFormat: column.outputFormat,
            });
        });
        columns.forEach((column) => this.selectedColumns.push(column.id));
        this.allColumns.next(columns);
    };

    public buildTableData = () => {
        const field: RecordField = CommonUtilsService.cloneObject(this.errorField || this.field);
        const rows = field.gridConstraints.rowDetails;
        const data: TableData[] = [];
        let inAudit = false;
        rows.forEach((row) => {
            data.push({
                recordId: row.index as any,
                disabled: false,
                firstRowColumn: row.name,
                errors: undefined,
                rowIndex: row.index,
            });
        });
        const cells = field.gridConstraints.cellDetails;
        cells.forEach((cell) => {
            const row = data.find((record) => record.recordId === (cell.rowIndex as any));
            if (!row.errors) {
                row.errors = {};
            }
            if (!row.errors[cell.columnIndex]) {
                row.errors[cell.columnIndex] = new Message(undefined);
            }
            row[cell.columnIndex] = cell.defaultValue;
        });
        const gridData = field.gridData;
        gridData.cells?.forEach((cell) => {
            const cellMetaData = field.gridConstraints.cellDetails.find(
                (cellDetail) => cellDetail.columnIndex === cell.columnIndex && cellDetail.rowIndex === cell.rowIndex
            );
            const dataRecord = data.find((record) => record.recordId === (cell.rowIndex as any));
            const auditRecord = cell.valueDifference && Object.keys(cell.valueDifference).length > 0;
            inAudit = dataRecord.inAudit || (cell.valueDifference && Object.keys(cell.valueDifference).length > 0);
            dataRecord.inAudit = inAudit;
            if (dataRecord.inAudit && auditRecord) {
                dataRecord[cell.columnIndex + '_oldValue'] = cell.valueDifference.leftValue;
                dataRecord[cell.columnIndex + '_newValue'] = cell.valueDifference.rightValue;
            } else if (cell.value !== undefined) {
                dataRecord[cell.columnIndex] = cell.value;
            }
            if (cellMetaData.datatype === 'DATE' && cell.value && (cell.value as any).length > 0 && !this.readOnly) {
                dataRecord[cell.columnIndex] = cell.value;
            }
            const messageData: Message = dataRecord.errors[cell.columnIndex];
            cell.errors.forEach((error) => messageData.errors.push(error));
            cell.warnings.forEach((warning) => messageData.errors.push(warning));
        });
        if (inAudit) {
            data.forEach((record) => {
                record['firstRowColumn_newValue'] = record.firstRowColumn;
            });
        }
        this.tableData.next(data);
    };

    closeModal = () => {
        this.errorField = undefined;
    };

    ngOnInit(): void {}
}
