import { AfterViewInit, Directive, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { filter, Subscription } from 'rxjs';
import { FilingAttributeField, GetAppBridgeNodes, GetAppMetaData, getAppMetaData$, ReconciliationConfiguration } from 'taxilla-library';

import { AssetData } from '../../models/assetdata.class';
import { AssetService } from '../../models/assetservice.class';
import { BridgeNode } from '../../models/bridgeNode.interface';
import { WorkFlow } from '../../models/workflow.class';
import { ApiService } from '../../services/api/api.service';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { UtilsService } from '../../services/utils/utils.service';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[AppData]',
})
export class AppDataDirective implements AfterViewInit {
    @Input() app: AssetService;
    @Input() bridge: AssetService;
    @Input() report: BridgeNode;

    @Output() assetDataEmitter = new EventEmitter();
    @Output() requestEmitter = new EventEmitter();
    @Output() filingAttributesEmitter = new EventEmitter();
    @Output() instanceEmitter = new EventEmitter();
    @Output() workflowStagesEmitter = new EventEmitter();
    @Output() statusEmitter = new EventEmitter();
    @Output() nodesEmitter = new EventEmitter();
    @Output() bridgeMetadataEmitter = new EventEmitter();

    request: Request;
    instanceId: string;
    assetData: AssetData;
    workflowStages: WorkFlow[] = [];
    configuration: ReconciliationConfiguration;
    private appMetaSubscription: Subscription;

    constructor(private _taxilla: ApiService, private _utils: UtilsService, private store$: Store) {}

    private startController = () => {
        if (this.app.assetType === 'BRIDGE_ASSET') {
            if (!this.app.assets || this.app.assets.length === 0) {
                (this.app as any).loading = {
                    bridgeNodes: true,
                    assetMetaData: false,
                    request: false,
                    status: false,
                    attributes: false,
                    instanceId: false,
                    workflow: false,
                    instance: false,
                };
                this.getBridgeNodes();
                this.bridgeMetaData();
            } else {
                (this.app as any).loading = {
                    bridgeNodes: false,
                    assetMetaData: false,
                    request: true,
                    status: true,
                    attributes: true,
                    instanceId: true,
                    workflow: true,
                    instance: true,
                };
                const nodes = {};
                this.app.assets.forEach((asset) => (nodes[asset.id] = asset));
                this.app.assets = [];
                this.transformNodes(nodes);
            }
        } else {
            if (this.app.assetType === 'RECON') {
                this.getReconConfiguration();
                return;
            }
            if (this.app?.['componentType'] === 'FILE') {
                this.clearLoading();
                return;
            }
            if (!this.app.canViewAllProcesses) {
                return;
            }
            (this.app as any).loading = {
                bridgeNodes: false,
                assetMetaData: true,
                request: true,
                status: true,
                attributes: true,
                instanceId: true,
                workflow: true,
                instance: true,
            };
            this.getAssetMetaData();
        }
    };

    bridgeMetaData = () => {
        if ((this.app as any).assetData) {
            return;
        } else {
            const service = CommonUtilsService.cloneObject(new AssetService(this.app));
            service.assetMetaUId = service.assetMetaUId || service.id;
            this._taxilla.subscriptions.getBridgeMetaData(
                {
                    // service: this.app,
                    assetMetaUId: service.assetMetaUId,
                    hideAlerts: true,
                },
                {
                    successCallback: (response) => {
                        this.assetData = new AssetData(response);
                        this.bridgeMetadataEmitter.emit(this.assetData);
                    },
                    failureCallback: (response) => {
                        this._utils.alertError((response && response.msg) || 'Failed to get bridge meta data');
                    },
                }
            );
        }
    };

    private getReconConfiguration = () => {
        this._taxilla.assets
            .getReconciliationConfiguration({
                restApiName: this.app.restApiName,
                serviceId: this.app.restApiName,
                noAlerts: true,
            })
            .then((res) => {
                (this.app as any).loading.assetMetaData = false;
                this.configuration = res;
                this.assetDataEmitter.emit(this.configuration as any);
            });
    };

    private getBridgeNodes = (): any => {
        this.app.assets = [];
        (this.app as any).loading.bridgeNodes = true;
        this.store$.dispatch(GetAppBridgeNodes({ serviceId: this.app.serviceId }));
        this._taxilla.subscriptions.servicesUnderlyingBridge(
            this.app.assetMetaUId,
            {
                successCallback: (nodes) => {
                    this.transformNodes(nodes);
                },
                failureCallback: (res) => {
                    (this.app as any).loading.bridgeNodes = false;
                    this._utils.alertError((res && res.msg) || 'Failed to get package nodes');
                },
            },
            { noAlerts: true }
        );
    };

    transformNodes = async (nodes: {
        [property: string]: {
            componentType?: string;
            id?: string;
            level?: number;
            name?: string;
            nextNodes?: string[];
            restApiName?: string;
            version?: string;
        };
    }) => {
        const bridgeApps = {};
        (this.app as any).loading.bridgeNodes = false;
        const bridgePermissions = this.app.bridgePermissions;
        for (const id in nodes) {
            if (bridgePermissions && bridgePermissions[nodes[id].name]) {
                const node = nodes[id];
                const nodePermissions = bridgePermissions[node.name || (node as any).displayName];
                this._utils.addNodePermissions(nodes[id] as any, nodePermissions?.permissions);
                bridgeApps[id] = nodes[id];
            }
        }
        const assets: AssetService[] = [];
        /**
         * Looping for non-FILE type assets
         */
        await this.checkPermissionsForBridgeNodes(assets, bridgeApps);
        /**
         * Looping for asset type: FILE
         */
        Object.keys(bridgeApps)
            .filter((nodeId) => bridgeApps[nodeId].componentType === 'FILE')
            .forEach((nodeId) => {
                const node = bridgeApps[nodeId];
                assets
                    .filter((asset) => asset.nextNodes && asset.nextNodes.indexOf(nodeId) > -1)
                    .forEach((asset) => {
                        asset.assets = asset.assets || [];
                        asset.assets.push(node);
                    });
            });
        this.nodesEmitter.emit(
            assets.sort((a, b) => {
                if (a.level === b.level) {
                    return 0;
                } else if (a.level > b.level) {
                    return 1;
                } else if (a.level < b.level) {
                    return -1;
                }
            })
        );
    };

    checkPermissionsForBridgeNodes = (assets: AssetService[], bridgeApps: { [property: string]: BridgeNode }) => {
        return new Promise((resolve) => {
            Object.keys(bridgeApps)
                .filter((nodeId) => bridgeApps[nodeId].componentType === 'ASSET')
                .forEach(async (nodeId) => {
                    const node: AssetService = bridgeApps[nodeId] as any;
                    const control = new UntypedFormBuilder().group({});
                    node['stepControl'] = control;
                    node.assetMetaUId = bridgeApps[nodeId].id;
                    assets.push(node);
                });
            resolve(undefined);
        });
    };

    private getAssetMetaData = () => {
        if ((this.app as any).assetData) {
            this.assetData = (this.app as any).assetData;
            (this.app as any).loading.assetMetaData = false;
            this.fetchInstanceId();
        } else {
            (this.app as any).loading.assetMetaData = true;
            (this.app as any).loading.request = true;
            const service = CommonUtilsService.cloneObject(new AssetService(this.app));
            service.assetMetaUId = service.assetMetaUId || service.id;
            this.getAssetMetaDataSubscription(service)
                .then((response) => {
                    (this.app as any).loading.assetMetaData = false;
                    this.assetData = new AssetData(response);
                    this.assetDataEmitter.emit(this.assetData);
                    this.fetchInstanceId();
                })
                .catch((response) => {
                    this._utils.alertError((response && response.msg) || 'Failed to get asset meta data');
                    (this.app as any).loading.assetMetaData = false;
                });
        }
    };

    private getAssetMetaDataSubscription = (app: AssetService) => {
        this.store$.dispatch(
            GetAppMetaData({
                noAlerts: false,
                serviceId: app?.serviceId,
            })
        );
        return new Promise<AssetData>((resolve) => {
            this.appMetaSubscription?.unsubscribe();
            this.appMetaSubscription = this.store$
                .select(getAppMetaData$(app.serviceId))
                .pipe(filter((data) => data.templateUpdated && data.uid?.length > 0))
                .subscribe((event) => {
                    this.appMetaSubscription?.unsubscribe();
                    resolve(new AssetData(JSON.parse(JSON.stringify(event))));
                });
        });
    };

    private fetchFilingAttributes = async (instance) => {
        if ((this.app as any).filingAttributes) {
            (this.app as any).loading.attributes = false;
        } else if ((this.app as any).filingAttributes === false) {
            (this.app as any).loading.attributes = false;
            return;
        } else {
            (this.app as any).loading.attributes = true;
            const requestId = instance.assetRequests?.[0]?.requestId;
            const attributes = await this.getFilingAttributes(requestId, instance?.serviceId);
            (this.app as any).loading.attributes = false;
            this.filingAttributesEmitter.emit(attributes || false);
        }
    };

    getFilingAttributes = (requestId: string, serviceId: string) => {
        return new Promise((resolve) => {
            if (!requestId) {
                resolve(undefined);
            } else if (this.report) {
                this._taxilla.requests.getReportAttributes(
                    {
                        requestId,
                        serviceId,
                    },
                    {
                        successCallback: (response) => {
                            this.transformFilingAttributes(response as any);
                            resolve(response);
                        },
                        failureCallback: (response) => {
                            this._utils.alertError((response && response.msg) || 'Failed to get attributes');
                            (this.app as any).loading.attributes = false;
                        },
                    }
                );
            } else {
                this._taxilla.requests.getFilingAttributes(
                    {
                        requestId,
                        restApiName: this.app.restApiName,
                        noAlerts: true,
                    },
                    {
                        successCallback: (response) => {
                            this.transformFilingAttributes(response as any);
                            resolve(response);
                        },
                        failureCallback: (response) => {
                            this._utils.alertError((response && response.msg) || 'Failed to get attributes');
                            (this.app as any).loading.attributes = false;
                        },
                    }
                );
            }
        });
    };

    private transformFilingAttributes = (fields: FilingAttributeField[]) => {
        fields.forEach((field) => {
            const [entityId, fieldId] = field['keyId'].split('^');
            if (entityId && fieldId) {
                const fieldMetaData = this.assetData.getEntity(entityId)?.getField(fieldId);
                field.name = fieldMetaData?.displayName || fieldMetaData?.name || field.name;
            }
        });
    };

    private fetchInstanceId = () => {
        if (this.app['instance'] && this.app['instance']['assetDataId']) {
            (this.app as any).loading.instanceId = false;
            (this.app as any).loading.instance = false;
            this.instanceId = this.app['instance']['assetDataId'];
            this.fetchFilingAttributes(this.app['instance']);
            this.fetchWorkflow();
        } else if (this.app['instance'] && this.app['instance']['assetDataId'] === false) {
            (this.app as any).loading.instanceId = false;
            (this.app as any).loading.instance = false;
            return;
        } else {
            (this.app as any).loading.instanceId = true;
            (this.app as any).loading.instance = true;
            this._taxilla.instances.getInstances(
                {
                    fetchSize: 1,
                    restApiName: this.getRestApiName(),
                    noAlerts: true,
                    loadAssetRequests: true,
                },
                {
                    successCallback: (response) => {
                        (this.app as any).loading.instanceId = false;
                        (this.app as any).loading.instance = false;
                        this.instanceId = response && response[0] && response[0].assetDataId;
                        this.instanceEmitter.emit((response && response[0]) || false);
                        if (response && response[0]) {
                            this.fetchFilingAttributes(response && response[0]);
                        } else {
                            (this.app as any).loading.attributes = false;
                        }
                        this.fetchWorkflow();
                    },
                    failureCallback: (res) => {
                        this._utils.alertError((res && res.msg) || 'Failed to get instance');
                        (this.app as any).loading.instanceId = false;
                        (this.app as any).loading.instance = false;
                    },
                }
            );
        }
    };

    getServiceId = () => {
        const app = this.app;
        return app.serviceId;
    };

    getRestApiName = () => {
        const app = this.app;
        return app.restApiName;
    };

    getPreCaptureAttributes = (values) => {
        if ((this.app as any).filingAttributes) {
            (this.app as any).loading.attributes = false;
            // Do Nothing
        } else if ((this.app as any).filingAttributes === false) {
            (this.app as any).loading.attributes = false;
            return;
        } else {
            (this.app as any).loading.attributes = false;
            this.filingAttributesEmitter.emit(values || false);
        }
    };
    private fetchWorkflow = () => {
        if ((this.app as any).workflowStages) {
            (this.app as any).loading.workflow = false;
            return;
        } else {
            if (this.assetData.workflowMetadata.stages.length > 0) {
                (this.app as any).loading.workflow = true;
                this._taxilla.workflow
                    .getAllWorkflowStages({
                        instanceId: this.instanceId,
                        noAlerts: true,
                        serviceId: this.app.serviceId,
                        restApiName: this.app.restApiName,
                    })
                    .then((response) => {
                        (this.app as any).loading.workflow = false;
                        response = response || [];
                        this.workflowStages = [];
                        response.forEach((workflow) => {
                            const control = new UntypedFormBuilder().group({});
                            workflow['stepControl'] = control;
                            this.workflowStages.push(workflow);
                        });
                        this.workflowStagesEmitter.emit(response);
                    })
                    .catch(() => {
                        (this.app as any).loading.workflow = false;
                    });
            } else {
                (this.app as any).loading.workflow = false;
            }
        }
    };

    private clearLoading = () => {
        (this.app as any).loading = {
            bridgeNodes: false,
            assetMetaData: false,
            request: false,
            status: false,
            attributes: false,
            instanceId: false,
            workflow: false,
        };
    };

    ngAfterViewInit() {
        setTimeout(() => {
            this.startController();
        }, 10);
    }
}
