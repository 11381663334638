import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { environment } from '@env';
import { translate } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, filter, Subject, takeUntil } from 'rxjs';
import {
    ClearNotifications,
    CommonNotificationsService,
    getNotificationCount$,
    getNotificationResourcePagingState$,
    getNotificationsViaPagination$,
    getOutboundTransaformationCompleted$,
    NotificationEvent,
    PaginationInterface,
    ReconEvent,
    SetPagination,
    TableColumn,
} from 'taxilla-library';

import { TableData } from '../../interface/table-data.interface';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { UtilsService } from '../../services/utils/utils.service';

@Component({
    selector: 'app-common-notifications-eventlogs',
    templateUrl: './common-notifications-eventlogs.component.html',
    styleUrls: ['./common-notifications-eventlogs.component.scss'],
})
export class CommonNotificationsEventlogsComponent implements OnInit, OnChanges, OnDestroy {
    @Input() notificationsState: BehaviorSubject<{
        eventTypesList: { id: string; displayName: string }[];
    }>;
    @Input() resourceId: string;
    @Input() sourcePage: string;
    @Input() openProcessLogs: () => void;

    public allAssetsName: string;
    public pagination: BehaviorSubject<PaginationInterface> = new BehaviorSubject({
        size: 5,
        count: 0,
        pageIndex: 0,
        page: 0,
        pages: [],
        disableNext: true,
    });
    private unSubscribe = new Subject<void>();
    public event: NotificationEvent;
    public reconEvent: ReconEvent;
    public completed = false;
    public allTableColumns: BehaviorSubject<TableColumn[]> = new BehaviorSubject([]);
    public tableData: BehaviorSubject<TableData[]> = new BehaviorSubject([]);
    public selectedColumns: string[] = [];
    public eventData = [];
    public eventTypesList: { id: string; displayName: string }[] = [];
    public selectedEventType: string;
    public tableColumns = [
        {
            name: 'createdOn',
            displayName: translate('Time') as string,
            show: true,
        },
        {
            name: 'eventType',
            displayName: translate('Event Type') as string,
            show: true,
        },
        {
            name: 'displayDetail',
            displayName: translate('Details') as string,
            show: true,
        },
        {
            name: 'displayActions',
            displayName: translate('Actions') as string,
            show: true,
        },
    ];
    public outBoundLatestLog: string;

    constructor(public _utils: UtilsService, private store$: Store, private _notifications: CommonNotificationsService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.resourceId && changes?.resourceId.currentValue !== changes?.resourceId.previousValue) {
            this.selectedEventType = '';
            this.unSubscribe?.next();
            this.refreshNotifications(changes?.resourceId?.previousValue);
            this.initiateSubscriptions();
        }
    }

    createTableColumns = () => {
        const columns: TableColumn[] = [];
        this.selectedColumns = [];
        this.tableColumns?.forEach((column) => {
            columns.push({
                icon: undefined,
                id: column.name,
                name: column.displayName,
                options: undefined,
                type: 'text',
                hide: !column.show,
            });
        });
        for (let i = 0; i < columns.length; i++) {
            if (columns[i].hide === false) {
                this.selectedColumns.push(columns[i].id);
            }
        }
        this.allTableColumns.next(columns);
        this.updateTableData(this.eventData);
    };

    public updateTableData = (eventData) => {
        const tableData: TableData[] = [];
        eventData?.forEach((record) => {
            const allColumns = this.allTableColumns.value;
            const recordData: TableData = {
                recordId: record.name,
            };
            allColumns.forEach((column) => {
                const fieldValue = this.getFieldValue(column.id, this.tableColumns, record, this.outBoundLatestLog);
                recordData[column.id] = fieldValue;
            });
            tableData.push(recordData);
        });
        this.tableData.next(tableData);
    };

    public getAllNotifications = () => {
        this.store$.dispatch(
            ClearNotifications({
                resourceId: this.resourceId,
            })
        );
        this.store$.dispatch(
            SetPagination({
                resourceId: this.resourceId,
                size: this.pagination.value.size,
                index: 0,
                eventType: this.selectedEventType || '',
            })
        );
    };

    public refreshNotifications = (resourceId?: string) => {
        this.store$.dispatch(
            ClearNotifications({
                resourceId: resourceId || this.resourceId,
            })
        );
        this.store$.dispatch(
            SetPagination({
                resourceId: this.resourceId,
                size: this.pagination.value.size,
                index: 0,
                eventType: resourceId ? undefined : this.selectedEventType || '',
            })
        );
    };

    public updateData = async (tableData) => {
        const promises = [];
        tableData?.forEach(async (event) => {
            promises.push(this.getEventDetails(event, event.eventType));
        });
        await Promise.all(promises);
        return tableData;
    };

    public getEventDetails = async (event, eType) => {
        event['displayEventType'] = await this._notifications.getEventDetails(event, 'type');
        event['displayDetail'] = await this._notifications.getEventDetails(event, eType);
    };

    convertTimeAndAppend = (tableData) => {
        tableData.filter((obj) => {
            if (obj && obj.createdOn) {
                obj.createdOn = this._utils.transformDateToLocale(obj.createdOn, 'YYYY-MM-DDTHH:MM:SSZ', 'ddMMMyyyy HH:MM:SS AM', false);
            }
        });
        this.eventData = tableData;
        this.createTableColumns();
    };

    public getFieldValue = (displayValue, Columns, events, outBoundLatestLog) => {
        let fieldValue: any = '';
        for (let i = 0; i < Columns.length; i++) {
            if (displayValue === Columns[i].name) {
                if (displayValue === 'eventType') {
                    fieldValue = this._utils.convertEventTypesToNewNames(this._utils.convertEnumsToNormalWords(events[Columns[i].name]));
                    break;
                } else if (displayValue === 'displayActions') {
                    let chainNameFound = false;
                    if (events.payload.chainName && Array.isArray(outBoundLatestLog)) {
                        for (let j = 0; j < outBoundLatestLog.length; j++) {
                            if (events.eventId.includes(outBoundLatestLog[j])) {
                                chainNameFound = true;
                                break;
                            }
                        }
                    }
                    if (chainNameFound) {
                        fieldValue = events.payload.reportURL
                            ? `<a href="${environment.taxilla_api}/process/v1/files?fileUrl=${events.payload.reportURL}" target="_blank" rel="noopener"><span class="material-icons ml-2" title="Download">cloud_download</span></a>`
                            : '';
                        break;
                    } else {
                        fieldValue = '';
                        break;
                    }
                } else {
                    fieldValue = events[Columns[i].name];
                    break;
                }
            }
        }
        return fieldValue;
    };

    trackEventType(_index: number, eventType: any): any {
        return eventType.id;
    }

    public fetchItemsInPage = (pageIndex: number) => {
        this.store$.dispatch(
            SetPagination({
                resourceId: this.resourceId,
                size: this.pagination.value.size,
                index: pageIndex,
                eventType: this.selectedEventType || '',
                pagingState: this.pagination.value.pagingState || '',
            })
        );
    };

    public refreshItemsWithNewPageSize = (data: number) => {
        this.pagination.value.pageIndex = 0;
        this.store$.dispatch(
            SetPagination({
                resourceId: this.resourceId,
                size: data,
                index: this.pagination.value.pageIndex,
                eventType: this.selectedEventType || '',
                pagingState: this.pagination.value.pagingState || '',
            })
        );
    };

    private initiateSubscriptions = () => {
        combineLatest([
            this.store$.select(getNotificationResourcePagingState$(this.resourceId)),
            this.store$.select(getNotificationCount$(this.resourceId)),
        ])
            .pipe(takeUntil(this.unSubscribe))
            .subscribe(([pagination, count]) => {
                const nextPageData = {
                    count,
                    page: (pagination?.index || -1) + 1,
                    pageIndex: pagination?.index,
                    size: pagination?.size,
                    searchAfter: pagination?.pagingState,
                };
                this.pagination.next(nextPageData);
            });
        this.store$
            .select(getNotificationsViaPagination$(this.resourceId))
            .pipe(takeUntil(this.unSubscribe))
            .subscribe(async (logs) => {
                this.eventData = CommonUtilsService.cloneObject(logs);
                const tableData = await this.updateData(this.eventData);
                this.updateTableData(tableData);
            });
        this.store$
            .select(getOutboundTransaformationCompleted$(this.resourceId))
            .pipe(takeUntil(this.unSubscribe))
            .subscribe(async (log) => {
                this.outBoundLatestLog = CommonUtilsService.cloneObject(log);
            });
        this.notificationsState
            ?.pipe(
                takeUntil(this.unSubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe((data) => {
                this.eventTypesList = data.eventTypesList;
            });
    };

    translateMsg = (msg: string): string => translate(msg);

    ngOnInit() {
        this.createTableColumns();
        this.initiateSubscriptions();
        this.store$.dispatch(
            SetPagination({
                resourceId: this.resourceId,
                size: 10,
                index: 0,
                eventType: this.selectedEventType || '',
                pagingState: this.pagination.value.pagingState || '',
            })
        );
    }

    ngOnDestroy(): void {
        this.unSubscribe.next();
        this.unSubscribe.complete();
    }
}
