import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env';
import { translate, TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BroadcasterService } from 'ng-broadcaster';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import {
    CommonUtilsService,
    GetLocation,
    getOrganization$,
    GetSessionDetails,
    getSessionDetails$,
    Organization,
    RedirectFailure,
    UtilsService,
} from 'taxilla-library';

import { PasswordexpireComponent } from '../passwordexpire/passwordexpire.component';

@Component({
    selector: 'app-redirtect',
    templateUrl: './redirect.component.html',
    styleUrls: ['./redirect.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: '',
        },
    ],
})
export class RedirectComponent implements OnInit, OnDestroy {
    passwordExpire: any;
    private unsubscribe = new Subject<void>();
    hubStorageInitiated: boolean;
    private orgSubscription = new Subject<void>();

    constructor(
        private _commonUtils: CommonUtilsService,
        private _utils: UtilsService,
        private _broadcaster: BroadcasterService,
        private _router: Router,
        private _route: ActivatedRoute,
        public dialog: MatDialog,
        private store$: Store,
        private actions$: Actions
    ) {}

    private startComponent = async (res) => {
        const deletedUserDetails = ['email', 'contactNumber'];
        const filteredUserDetails = this._commonUtils.filterObjectProperties(deletedUserDetails, res.user);
        this._commonUtils.setInStorage('isAdmin', res.isAdmin);
        this._commonUtils.setInStorage('user', filteredUserDetails);
        this._commonUtils.setInStorage('session', true);
        if (res.passwordExpiresInDays >= 0 && res.passwordExpiresInDays <= 3) {
            setTimeout(() => {
                this.openPasswordExpiryModal(res);
            }, 3000);
        }
        this.getOrganizationDetails(res.currentOrganizationId || res.loggedInOrganizationId);
        this.getOrganization(res);
    };

    private getOrganization = (res) => {
        this.orgSubscription?.next();
        this.store$
            .select(getOrganization$(res.currentOrganizationId || res.loggedInOrganizationId))
            .pipe(
                takeUntil(this.orgSubscription),
                takeUntil(this.unsubscribe),
                filter((org) => org !== undefined)
            )
            .subscribe((org) => {
                this.orgSubscription?.next();
                this.setTimeZoneOffset(
                    res,
                    () => {
                        const lastLoggedInTime = CommonUtilsService.transformDateToLocale(
                            res.user.lastLogin,
                            'YYYY-MM-DDTHH:MM:SSZ',
                            'ddMMMyyyy HH:MM:SS AM',
                            false
                        );
                        this._commonUtils.setInStorage('lastLoginTime', lastLoggedInTime);
                    },
                    org
                );
                this.setOrganizationDependencies(org);
                this.setPostLoginDependencies(org);
                this._utils.alertSuccess(translate('Login successful. Redirecting in few seconds'));
            });
    };

    openPasswordExpiryModal = (expiresDate) => {
        this.dialog.open(PasswordexpireComponent, {
            panelClass: ['matDialogContainer', 'passwordExpiryDialog'],
            disableClose: true,
            data: {
                passwordExpiryDate: expiresDate.passwordExpiresInDays,
            },
        });
    };

    private getOrganizationDetails = (organizationId: string) => {
        this.store$.dispatch(GetLocation({ organizationId, update: false, fromComponent: 'redirect' }));
    };

    private setOrganizationDependencies = (organization: Organization) => {
        const rootOrg = this.getRootOrganization(organization as Organization);
        const deletedOrgDetalils = ['pan', 'addresses'];
        const filteredRootOrg = this._commonUtils.filterObjectProperties(deletedOrgDetalils, rootOrg);
        const filteredCurrentOrg = this._commonUtils.filterObjectProperties(deletedOrgDetalils, organization);
        this._commonUtils.setInStorage('loggedinorganization', filteredCurrentOrg);
        this._commonUtils.setInStorage('rootOrganization', filteredRootOrg);
        this._commonUtils.setInStorage('rootOrganizationId', rootOrg.id);
        this._commonUtils.setInStorage('currentOrganization', filteredCurrentOrg);
        this._commonUtils.setInStorage('currentOrganizationId', organization.id);
        this._commonUtils.setInStorage('orgTimeZone', organization.timeZone);
    };

    private setPostLoginDependencies = async (organization: Organization) => {
        this._broadcaster.broadcast('setStoreContext');
        const params = this._route.queryParams?.['value'];
        let returnUrl: string = params?.returnPage || this._commonUtils.getFromStorage('returnPage');
        if (!returnUrl) {
            returnUrl = undefined;
        }
        const isAdmin = this._commonUtils.getFromStorage('isAdmin');
        if (!returnUrl) {
            const redirectOnLogin = environment.redirectOnLogin;
            let path = redirectOnLogin ? redirectOnLogin : 'organizations/' + organization.id + '/home';
            if (organization.addresses?.length < 2 && isAdmin) {
                path = 'organizations/' + organization.id + '/settings/manageOrganization/edit/' + organization.id + '/redirect';
            }
            returnUrl = path;
        } else if (returnUrl.indexOf('http://') === -1) {
            returnUrl = returnUrl?.indexOf('/') === 0 ? returnUrl.substring(1) : returnUrl;
        }
        this._broadcaster.broadcast('loggingIn', true);
        await this.waitForHubStorage();
        this._broadcaster.broadcast('setCrossStorage', {
            returnUrl,
            callback: () => {
                this._broadcaster.broadcast('session', true);
                if (returnUrl?.indexOf('http') === -1) {
                    if (decodeURIComponent(returnUrl).indexOf('#') > -1) {
                        if (returnUrl.indexOf('/partner-collaborate/') > -1 && returnUrl.indexOf('/organizations/') === -1) {
                            returnUrl = `organizations/${organization.id}/${returnUrl}`;
                        }
                        this._router.navigateByUrl(returnUrl);
                    } else {
                        this._router.navigateByUrl(decodeURIComponent(returnUrl));
                    }
                } else if (returnUrl.indexOf(location.origin) > -1) {
                    this._router.navigateByUrl(returnUrl.substring(location.origin.length));
                } else {
                    window.location.href = returnUrl;
                }
                setTimeout(() => {
                    this._broadcaster.broadcast('loggingIn', false);
                }, 100);
            },
        });
    };

    waitForHubStorage = () => {
        return new Promise<void>((resolve) => {
            if (this.hubStorageInitiated) {
                resolve();
            } else {
                this._broadcaster.broadcast('getFromCrossStorage', {
                    property: 'session',
                    callback: () => {
                        this.hubStorageInitiated = true;
                    },
                });
                if (this.hubStorageInitiated) {
                    resolve();
                    return;
                }
                this._broadcaster
                    .on('hubstorageInitiated')
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe(() => {
                        this.hubStorageInitiated = true;
                        resolve();
                    });
            }
        });
    };

    private setTimeZoneOffset = (response, callback, organization?) => {
        const tz = response.organization?.timeZone || organization?.timeZone;
        const serverTimeZoneOffset = response['timeZoneOffset'];
        const data = {
            serverTimeZoneOffset,
            tz,
        };
        this._utils.setTimeZoneOffset(data, callback);
    };

    private getRootOrganization = (response: Organization): Organization => {
        let organization: Organization;
        if (response.parentId && response.parentId.length > 0) {
            organization = this.getRootOrganization(response.parent as Organization);
        } else if (!response.parentId) {
            return response;
        }
        return organization;
    };

    ngOnInit(): void {
        this.store$.dispatch(GetSessionDetails({}));
        this.store$
            .select(getSessionDetails$)
            ?.pipe(
                takeUntil(this.unsubscribe),
                filter((data) => data?.loggedInOrganizationId !== undefined)
            )
            .subscribe((event) => {
                this.startComponent(event);
            });
        this.actions$
            .pipe(ofType(RedirectFailure), takeUntil(this.unsubscribe))
            ?.subscribe(() => this._broadcaster.broadcast('logout', { noRedirect: true, hidePopup: true }));
    }

    ngOnDestroy() {
        this.orgSubscription.complete();
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
