import { WorkflowUserAction } from '@eninvoice/class/assets/workflowstagedata.class';
import { createAction, props } from '@ngrx/store';

import { AssetService } from '../../models/assetservice.class';
import { Entity } from '../../models/entity.class';
import { SearchCriteria } from '../../models/searchcriteria.global';

enum eninvoiceActions {
    SET_SELECTED_APP_DETAILS = '[ENINVOICE] Set Selected App Details',
    GET_APP_META_DATA = '[ENINVOICE] Get App Meta Data',
    SET_SELECTED_INSTANCE_ID = '[ENINVOICE] Set Selected Instance ID',
    SET_SELECTED_REQUEST_ID = '[ENINVOICE] Set Selected Request ID',
    CLEAR_INSTANCES_PAGINATION = '[ENINVOICE] Clear Instances Pagination',
    SET_INSTANCES_PAGINATION = '[ENINVOICE] Set Instances Pagination',
    SET_INSTANCES_SEARCH_CRITERIA = '[ENINVOICE] Set Instances Search Criteria Criteria',
    GET_SELECTED_INSTANCE_REPORTS = '[ENINVOICE] Get Selected Instance Reports',
    SELECTED_INSTANCE_GENERATE_REPORT = '[ENINVOICE] Selected Instance Generate Reports',
    SEARCH_INSTANCES = '[ENINVOICE] Search Instances',
    GET_SEARCH_REPORTS_PAGINATION = '[ENINVOICE] Get Search Reports Pagination',
    UPDATE_SEARCH_REPORTS_PAGINATION = '[ENINVOICE] Update Search Reports Pagination',
    CLEAR_SEARCH_REPORTS_PAGINATION = '[ENINVOICE] Clear Search Reports Pagination',
    GET_SEARCH_REPORTS = '[ENINVOICE] Get Search Reports',
    CLEAR_SEARCH_REPORTS = '[ENINVOICE] Clear Search Reports',
    GET_TRANSFORMATION_CHAINS = '[ENINVOICE] Get Transformation Chains',
    DOWNLOAD_SEARCH_REPORT = '[ENINVOICE] Download Search Report',
    GET_SELECTED_INSTANCE_WORKFLOW = '[ENINVOICE] Get Selected Instance Workflow',
    CLEAR_SELECTED_INSTANCE_WORKFLOW = '[ENINVOICE] Clear Selected Instance Workflow',
    SUBMIT_WORKFLOW = '[ENINVOICE] Submit Workflow',
}

export const SetEninvoiceSelectedAppDetails = createAction(
    eninvoiceActions?.SET_SELECTED_APP_DETAILS,
    props<{
        bridgeRestApiName: string;
        appRestApiName: string;
        reportName: string;
    }>()
);

export const GetEninvoiceAppMetaData = createAction(eninvoiceActions?.GET_APP_META_DATA);

export const SetEninvoiceSelectedInstanceId = createAction(
    eninvoiceActions?.SET_SELECTED_INSTANCE_ID,
    props<{
        instanceId: string;
    }>()
);

export const SetEninvoiceSelectedRequestId = createAction(
    eninvoiceActions?.SET_SELECTED_REQUEST_ID,
    props<{
        requestId: string;
    }>()
);

export const ClearEninvoiceInstancesPagination = createAction(eninvoiceActions?.CLEAR_INSTANCES_PAGINATION);

export const SetEninvoiceInstancesPagination = createAction(
    eninvoiceActions?.SET_INSTANCES_PAGINATION,
    props<{
        size: number;
        pageIndex: number;
    }>()
);

export const SetEninvoiceInstancesSearchCriteria = createAction(
    eninvoiceActions?.SET_INSTANCES_SEARCH_CRITERIA,
    props<{
        freeTextSearch: string;
        criteria: SearchCriteria;
    }>()
);
export const GetEninvoiceSelectedInstanceReports = createAction(
    eninvoiceActions?.GET_SELECTED_INSTANCE_REPORTS,
    props<{
        noAlerts: boolean;
        forceFetch: boolean;
    }>()
);

export const EninvoiceSelectedInstanceGenerateReport = createAction(
    eninvoiceActions?.SELECTED_INSTANCE_GENERATE_REPORT,
    props<{
        chainName: string;
        repositoryId: string;
    }>()
);

export const SearchEninvoiceInstances = createAction(eninvoiceActions?.SEARCH_INSTANCES, props<{ forceFetch: boolean }>());

export const GetEninvoiceSearchReportsPagination = createAction(eninvoiceActions?.GET_SEARCH_REPORTS_PAGINATION);

export const UpdateEninvoiceSearchReportsPagination = createAction(
    eninvoiceActions?.UPDATE_SEARCH_REPORTS_PAGINATION,
    props<{
        pageSize: number;
        pageIndex: number;
        pagingState: any;
        timeRange?: any[];
    }>()
);

export const ClearEninvoiceSearchReportsPagination = createAction(eninvoiceActions?.CLEAR_SEARCH_REPORTS_PAGINATION);

export const GetEninvoiceSearchReports = createAction(eninvoiceActions?.GET_SEARCH_REPORTS);

export const ClearEninvoiceSearchReports = createAction(eninvoiceActions?.CLEAR_SEARCH_REPORTS);

export const GetEninvoiceTransformationChains = createAction(eninvoiceActions?.GET_TRANSFORMATION_CHAINS);

export const DownloadEninvoiceSearchReport = createAction(
    eninvoiceActions?.DOWNLOAD_SEARCH_REPORT,
    props<{
        requestId: string;
        repositoryId: string;
        transformationName: string;
    }>()
);

export const GetEninvoiceSelectedInstanceWorkflow = createAction(
    eninvoiceActions.GET_SELECTED_INSTANCE_WORKFLOW,
    props<{
        noAlerts: boolean;
        notFirstIteration?: boolean;
        configurations?: any;
    }>()
);

export const ClearEninvoiceSelectedInstanceWorkflow = createAction(eninvoiceActions.CLEAR_SELECTED_INSTANCE_WORKFLOW);

export const SubmitEninvoiceWorkflow = createAction(
    eninvoiceActions.SUBMIT_WORKFLOW,
    props<{
        action: WorkflowUserAction;
        methodType: 'WORKFLOW_ACTION' | 'POST_WORKFLOW_INTERVENTION';
        entities: Entity[];
        service: AssetService;
        formFields: { [property: string]: string };
        currentOrganization: any;
        comment: string;
        noAlerts?: boolean;
        notFirstIteration?: boolean;
        currentWorkflowStage: any;
        actionVariables?: {
            selectedEntityRecords?: {
                records: {
                    entityId: string;
                    recordIds: string[];
                }[];
            };
        };
        executeTimestamp: number;
    }>()
);
