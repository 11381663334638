import { createAction, props } from '@ngrx/store';

import { SavedSearchOption } from '../../interface/savedsearchoption.interface';
import { FilterCriteria, SearchCriteria } from '../../models/searchcriteria.global';

enum SearchActions {
    GET_APP_FILTERS = '[SEARCH] Get App Filters',
    SET_DEFAULT_FILTERS = '[SEARCH] Set Default Search Filters',
    SET_APP_FILTERS = '[SEARCH] Set App Search Filters',
    SAVE_CRITERIA = '[SEARCH] Save Criteria',
    SAVED_CRITERIA = '[SEARCH] Saved Criteria',
    DELETE_SAVED_CRITERIA = '[SEARCH] Delete Saved Criteria',
    SET_SEARCH_INSTANCES_CRITERIA = '[SEARCH] Set Instances Criteria',
    SET_SEARCH_REQUESTS_CRITERIA = '[SEARCH] Set Requests Criteria',
    SET_SEARCH_RECORDS_CRITERIA = '[SEARCH] Set Records Criteria',
}

export const GetAppSearchFilters = createAction(
    SearchActions.GET_APP_FILTERS,
    props<{
        serviceId: string;
    }>()
);

export const SetDefaultSearchFilters = createAction(
    SearchActions.SET_DEFAULT_FILTERS,
    props<{
        serviceId: string;
        organizationId: string;
        filters: string[];
        criterias: {
            [property: string]: FilterCriteria;
        };
    }>()
);

export const SetAppSearchFilters = createAction(
    SearchActions.SET_APP_FILTERS,
    props<{
        serviceId: string;
        organizationId: string;
        filters: any[];
    }>()
);

export const SaveSearchCriteria = createAction(
    SearchActions.SAVE_CRITERIA,
    props<{
        serviceId: string;
        criteria: string;
        filterName: string;
        id?: string;
    }>()
);

export const SavedSearchCriteria = createAction(
    SearchActions.SAVED_CRITERIA,
    props<{
        serviceId: string;
        criteria: SavedSearchOption;
    }>()
);

export const DeleteSearchCriteria = createAction(
    SearchActions.DELETE_SAVED_CRITERIA,
    props<{
        serviceId: string;
        id: string;
    }>()
);

export const SetSearchInstancesCriteria = createAction(
    SearchActions.SET_SEARCH_INSTANCES_CRITERIA,
    props<{
        searchCriteria: SearchCriteria;
        criteriaName: string;
        serviceId: string;
    }>()
);

export const SetSearchRequestsCriteria = createAction(
    SearchActions.SET_SEARCH_REQUESTS_CRITERIA,
    props<{
        searchCriteria: SearchCriteria;
        criteriaName: string;
        serviceId: string;
    }>()
);

export const SetSearchRecordsCriteria = createAction(
    SearchActions.SET_SEARCH_RECORDS_CRITERIA,
    props<{
        searchCriteria: SearchCriteria;
        criteriaName: string;
        serviceId: string;
    }>()
);
