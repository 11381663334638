<div class="d-flex justify-content-between align-items-center position-relative" *transloco="let translate;">
    <h4 mat-dialog-title class="mat-dialog-title">{{translate('Search')}}</h4>
    <span class="material-icons top-0 right-0 position-absolute cursor-pointer" mat-dialog-close>close</span>
    <ng-container *ngIf="loadingBulkReports">
        <div class="loadingContainer">
            <app-dots-loader [showLoading]="true"></app-dots-loader>
        </div>
    </ng-container>
</div>
<div class="mainSearchFilters" *transloco="let translate;">
    <div class="filterSelection py-2">
        <mat-radio-group aria-labelledby="filter-selection" class="d-flex align-items-center mx-3" [(ngModel)]="selectedOutlookFilter">
            <mat-radio-button class="searchType" *ngFor="let filter of outlookSearchFilters" [value]="filter.name" [checked]="selectedOutlookFilter === filter.name">
                {{translate(filter.displayName)}}
            </mat-radio-button>
        </mat-radio-group>
    </div>
    <div class="ps position-relative selectedFilterContainer" style="height: 50vh;" [perfectScrollbar]>
        <div class="defaultFilterContainer d-flex align-items-center" *ngIf="selectedOutlookFilter === 'searchFilters'">
            <label>{{translate('Search Filters')}}</label>
            <mat-form-field class="w-100 col-9" id="searchField" appRemoveAriaOwns>
                <mat-label>
                    {{ (searchFilters?.length > 0) ? translate('Select Filter') : translate('No Search Filters')}}
                </mat-label>
                <mat-select [(ngModel)]="selectedSearchFilter">
                    <mat-option>{{translate('Select Filter')}}</mat-option>
                    <mat-form-field class="searchEntities searchInputField searhFilters px-4">
                        <input matInput [placeholder]="translate('Search Filter')" type="text" [(ngModel)]="selectAssetsName" onclick="event.stopPropagation()">
                        <button mat-button matSuffix mat-icon-button aria-label="clear" (click)="selectAssetsName = ''">
                            <mat-icon class='searchEntitiesIcons' *ngIf='selectAssetsName'>
                                close
                            </mat-icon>
                            <mat-icon *ngIf='!selectAssetsName' class="searchEntitiesIcons">
                                search
                            </mat-icon>
                        </button>
                    </mat-form-field>
                    <ng-container *ngFor="let option of searchFilters | FilterPipe : 'filterName' : selectAssetsName : undefined : undefined : undefined : 'default-filters'">
                        <ng-container *ngIf="option?.criteria; else isDefaultFilters ">
                            <ng-template [ngTemplateOutlet]="searchFilterOptions" [ngTemplateOutletContext]="{ option: option }"></ng-template>
                        </ng-container>
                        <ng-template #isDefaultFilters>
                            <ng-template [ngTemplateOutlet]="searchFilterOptions" [ngTemplateOutletContext]="{ option: option['default-filters'] }"></ng-template>
                        </ng-template>
                    </ng-container>
                    <ng-template #searchFilterOptions let-option="option">
                        <ng-container *ngIf="option.criteria; else isDefaultFilter">
                            <div class="searchFilterOption searchFilter">
                                <mat-option class="selectSearchFilterOpt" title="{{option.filterName}}" [value]="option">
                                    {{option.filterName}}
                                </mat-option>
                                <ng-template [ngTemplateOutlet]="filterActions" [ngTemplateOutletContext]="{ option: option }"></ng-template>
                            </div>
                        </ng-container>
                        <ng-template #isDefaultFilter>
                            <mat-divider></mat-divider>
                            <ng-container *ngFor="let defaultFilter of option | FilterPipe : undefined : selectAssetsName; let i = index">
                                <div class="searchFilterOption">
                                    <span class="material-icons mr-2 position-absolute" style="font-size: 16px; color: #00a651;top: 14px; left: 3px; z-index: 1;" [title]="translate('Default Filter')" stop-propagation>dns</span>
                                    <mat-option [value]="defaultFilter" class="position-relative" [title]="defaultFilter" stop-propagation>
                                        <span class="ml-2">
                                            {{defaultFilter}}
                                        </span>
                                    </mat-option>
                                    <ng-template [ngTemplateOutlet]="filterActions" [ngTemplateOutletContext]="{ option: defaultFilter }"></ng-template>
                                </div>
                            </ng-container>
                        </ng-template>
                    </ng-template>
                    <ng-template #filterActions let-option="option">
                        <span class="d-flex align-items-center justify-content-between searchFilterActions" stop-propagation>
                            <span class="d-flex align-items-center" stop-propagation>
                                <span class="material-icons editSaveFilter cursor-pointer" (click)="editSaveFilter(option)" [title]="translate('Edit filter')" stop-propagation>edit</span>
                                <span class="material-icons editSaveFilter mx-1 cursor-pointer" (click)="downloadReportWithSavedFilter(option)" [title]="translate('Download Filter Report')" stop-propagation>cloud_download</span>
                                <span class="material-icons deleteSaveFilter cursor-pointer" [hidden]="option && !option.criteria" stop-propagation (click)="deleteSaveFilter(option)" [title]="translate('Delete filter')">delete_forever</span>
                            </span>
                        </span>
                    </ng-template>
                    <ng-container *ngIf="searchFilters?.length > 0 && (searchFilters | multiFilter: selectAssetsName).length === 0 ">
                        <div class="noRecordsContainer">
                            <div class="noSearchRecords">
                                <span>No results found</span>
                            </div>
                        </div>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="ewayBillStatusContainer d-flex align-items-center" *ngIf="selectedOutlookFilter === 'ewaybillByStatus'">
            <label>{{translate('E-Way Bill Status')}}</label>
            <mat-form-field appRemoveAriaOwns>
                <mat-label>
                    {{ (savedSearchOptions?.length > 0) ? translate('Select Status') : translate('No Status')}}
                </mat-label>
                <mat-select [(ngModel)]="selectedSearchOption">
                    <mat-option>{{translate('Select Status')}}</mat-option>
                    <mat-form-field class="searchEntities searchInputField searhFilters">
                        <input matInput [placeholder]="translate('Search Filter')" type="text" [(ngModel)]="selectedSearchOption" stop-propagation>
                        <button mat-button matSuffix mat-icon-button aria-label="clear" (click)="selectedSearchOption = ''">
                            <mat-icon class='searchEntitiesIcons' *ngIf='selectedSearchOption'>
                                close
                            </mat-icon>
                            <mat-icon *ngIf='!selectedSearchOption' class="searchEntitiesIcons">
                                search
                            </mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-option *ngFor="let option of savedSearchOptions | multiFilter: selectedSearchOption" [value]="option.filterName">
                        {{option.filterName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <!-- bulk processing -->
        <div class="processStatusContainer" *ngIf="selectedOutlookFilter === 'processStatus'">
            <div class="d-flex align-items-center">
                <label class="mr-3">{{translate('Process Filter')}}</label>
                <mat-form-field class="col-6" appRemoveAriaOwns>
                    <mat-label>
                        {{ (processStatuses?.length > 0) ? translate('Select Status') : translate('No Status')}}
                    </mat-label>
                    <mat-select [(ngModel)]="selectedProcessFilter" (openedChange)="getProcessStatuses($event)">
                        <mat-option>{{translate('Select Status')}}</mat-option>
                        <mat-form-field class="searchInputField !px-4 !min-h-0">
                            <input matInput [placeholder]="translate('Search Filter')" type="text" [(ngModel)]="inputProcessFilter" stop-propagation>
                            <button mat-button matSuffix mat-icon-button aria-label="clear" (click)="inputProcessFilter = ''">
                                <mat-icon class="searchEntitiesIcons" *ngIf="inputProcessFilter">
                                    close
                                </mat-icon>
                                <mat-icon *ngIf="!inputProcessFilter" class="searchEntitiesIcons">
                                    search
                                </mat-icon>
                            </button>
                        </mat-form-field>
                        <ng-container *ngIf="processStatuses === undefined; else optionsTemplate">
                            <mat-progress-bar mode="query"></mat-progress-bar>
                        </ng-container>
                        <ng-template #optionsTemplate>
                            <ng-container *ngIf="processStatuses?.length > 0; else emptyMessage">
                                <mat-option *ngFor="let process of processSearchFilter()" [value]="process">
                                    {{process | titlecase}}
                                </mat-option>
                            </ng-container>
                            <ng-template #emptyMessage>
                                <button mat-menu-item disabled>No options available</button>
                            </ng-template>
                        </ng-template>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="customSearchContainer" *ngIf="selectedOutlookFilter === 'customSearch'">
            <mat-tab-group (selectedTabChange)="selectedTabChange($event)" class="w-100" [selectedIndex]="selectedTab">
                <mat-tab label="{{translate('Rules')}}">
                    <app-search-builder [search]="searchBuilder" [searchType]="'SEARCH'"></app-search-builder>
                </mat-tab>
                <mat-tab label="{{translate('Relations')}}">
                    <app-search-builder [search]="searchBuilder" [showQueryBuilderDirectly]="true" searchType="RELATION" [relationConfigExists]="true"></app-search-builder>
                </mat-tab>
                <mat-tab label="{{translate('Download Report')}}">
                    <ng-container *ngIf="searchBuilder.criteriaUpdated; else showCriteriaMessage">
                        <perfect-scrollbar style="height: 38vh;">
                            <div class="processDownloadReport downloadReportDropdownContainer mat-card w-100 !shadow-none">
                                <div class="transformationsContainer">
                                    <div class="col-9">
                                        <span class="bulkReportCheckBoxCls">
                                            <mat-checkbox [(ngModel)]="checkMultiple" name="'checkForMultiple'">
                                            </mat-checkbox>
                                        </span>
                                        <span class="checkBoxSpanCls">{{translate('Is bulk report?')}}</span>
                                        <span class="bulkReportSearchSpan">
                                            <span title="{{translate('Processes for which reports have been generated will be downloaded using this option.')}}" class="material-icons-outlined bulkinfoIcon">
                                                info
                                            </span>
                                        </span>
                                    </div>
                                    <div class="col-9 completeInstanceReportContainer">
                                        <span class="bulkReportCheckBoxCls">
                                            <mat-checkbox [(ngModel)]="isCompleteInstanceReport" name="'checkForCompleteInstanceReport'" (change)="checkCompleteInstanceReport = $event?.checked;">
                                            </mat-checkbox>
                                        </span>
                                        <span class="checkBoxSpanCls">{{translate('Is generate complete process report?')}}</span>
                                    </div>
                                    <ng-container *ngIf="checkMultiple">
                                        <div class="row noMargin">
                                            <div class="col-6">
                                                <material-selectgroup [required]="false" [options]="reportMetadata" [placeholder]="translate('Select Transformations')" [model]="$any(bulkTransformationsList)" [optionDisplayNameProperty]="'displayName'" [optionValueProperty]="'chainName'" (modelChange)="bulkTransformationsList = $event;" [groupDisplayNameProperty]="'orgName'" [groupValueProperty]="'transformations'" [title]="$any(bulkTransformationsList)" [isMultple]="true" [hideSelectIfValueExists]="true">
                                                </material-selectgroup>
                                            </div>
                                            <div class="col-4 bulkDownloadBtn">
                                                <material-button [label]="'Initiate'" (onTrigger)="createDownloadBulkRequest()" [disabled]="!bulkTransformationsList || (!bulkTransformationsList?.length)"></material-button>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="!checkMultiple">
                                        <div class="row noMargin">
                                            <div class="col-6">
                                                <mat-form-field appRemoveAriaOwns>
                                                    <mat-select placeholder="{{translate('Select Transformation')}}" [(value)]="selectedReportTransformation" name="transformation" disableOptionCentering>
                                                        <mat-optgroup *ngFor="let group of downloadReportTransformations;" [label]="group.orgName">
                                                            <mat-option *ngFor="let transformation of group.values" [value]="transformation" (onSelectionChange)="downloadErrorRepositoryId = group.orgId">
                                                                {{transformation.displayName || transformation.name}}
                                                            </mat-option>
                                                        </mat-optgroup>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-4 bulkDownloadBtn">
                                                <material-button [label]="translate('Initiate')" (onTrigger)="initiateDownloadReport()" [disabled]="!selectedReportTransformation"></material-button>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </perfect-scrollbar>
                    </ng-container>
                    <ng-template #showCriteriaMessage>
                        <div class="d-flex align-items-center justify-content-center w-full" style="height: 35vh;">
                            <span class="px-2">{{translate('Create Criteria From Rules Tab')}}</span>
                            <a (click)="selectedTabChange({index:0})">{{translate('Click Here')}}</a>
                        </div>
                    </ng-template>
                </mat-tab>
                <mat-tab label="{{translate('Save Filter')}}">
                    <div class="d-flex align-items-center justify-content-center">
                        <div class="d-flex align-items-center justify-content-center mat-card" style="width: 100%; height: 25vh;">
                            <mat-form-field class="col-6" style="left: 40px;">
                                <input type="text" matInput [placeholder]="translate('Enter Filter Name')" [(ngModel)]="saveFilterName" name="Filter">
                                <mat-icon matSuffix>mode_edit</mat-icon>
                            </mat-form-field>
                            <material-button class="col-4" style="left: 40px;" [label]="translate('Save and Search')" (onTrigger)="saveAndSearchWithFilter()" [disabled]="!saveFilterName"></material-button>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
        <!-- Filter by time -->
        <ng-container *ngIf="selectedOutlookFilter === 'filterByTime'">
            <app-time-filters [selectedOutlookFilter]="{name: selectedOutlookFilter}" [timeFilter]="timeFilter"></app-time-filters>
        </ng-container>
    </div>
    <div class="align-items-center buttonFields d-flex">
        <ng-container *ngIf="selectedOutlookFilter === 'searchFilters'">
            <material-button class="noOutline submitColor ml-auto" [label]="translate('Apply Filter')" [disabled]="!selectedSearchFilter" (onTrigger)="applyDefaultSearchFilter()"></material-button>
        </ng-container>
        <ng-container *ngIf="selectedOutlookFilter === 'processStatus'">
            <material-button class="noOutline submitColor ml-auto" [label]="translate('Apply Filter')" [disabled]="!selectedProcessFilter" (onTrigger)="applySelectedProcessFilter()"></material-button>
        </ng-container>
        <ng-container *ngIf="selectedOutlookFilter === 'customSearch'">
            <div class="d-flex align-items-center justify-content-start">
                <material-button [disabled]="!searchBuilder.criteriaUpdated" class="noOutline submitColor" [label]="translate('Search')" (onTrigger)="startNewSearch()" [hidden]="selectedTab === 3"></material-button>
                <material-button [disabled]="!searchBuilder.criteriaUpdated" class="noOutline submitColor" [label]="translate('Download')" (onTrigger)="selectedTab = 2" [hidden]="selectedTab === 2 || selectedTab === 3"></material-button>
                <material-button [disabled]="!searchBuilder.criteriaUpdated" class="noOutline submitColor" [label]="searchFilter?.id ? translate('Update Filter') : translate('Save Filter')" (onTrigger)="selectedTab = 3" [hidden]="selectedTab === 3"></material-button>
                <material-button class="noOutline submitColor mx-2" [label]="!searchBuilder.criteriaUpdated ? translate('Add Criteria') : translate('Update Criteria')" (onTrigger)="searchBuilder.buildCriterias()" [hidden]="selectedTab === 3 || selectedTab === 2"></material-button>
            </div>
            <button mat-button [disabled]="!searchBuilder.criteriaUpdated" (click)="resetFilterCriteria()" class="mat-elevation-z1 ml-auto" [hidden]="selectedTab === 3">{{translate('Reset Criteria')}}</button>
        </ng-container>
        <ng-container *ngIf="selectedOutlookFilter === 'filterByTime'">
            <material-button class="noOutline submitColor ml-auto" [label]="translate('Apply')" [disabled]="!timeFilter.endDate ||!timeFilter.startDate" (onTrigger)="searchByTimeRange()"></material-button>
        </ng-container>
        <ng-container *ngIf="selectedOutlookFilter">
            <button mat-button [hidden]="selectedTab === 3" (click)="clearAllFilters()" class="mat-elevation-z1 !ml-[10px]">{{translate('Clear Filters')}}</button>
        </ng-container>
    </div>
</div>
