import { Component, Input } from '@angular/core';
import { AssetService } from 'taxilla-library';

import type { WorkflowFormField } from '../../../../enreport/src/app/class/assets/workflowstagedata.class';

@Component({
    selector: 'app-workflow-fields-input',
    templateUrl: './workflow-fields-input.component.html',
})
export class WorkflowFieldsInputComponent {
    @Input() field: WorkflowFormField;
    @Input() app: AssetService;
    @Input() bridge: AssetService;

    trackByOptionsMethod(index: number): any {
        return index;
    }
}
