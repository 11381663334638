<app-downtime-active-message></app-downtime-active-message>
<div *transloco="let translate;">
    <div class="new-recon-process mat-elevation-z8">
        <div class="border-recon">
            <span class="process-header">
                {{translate('Run Reconciliation')}}
            </span>
            <div class="reconHeader">
                <material-button [color]="'default'" [label]="translate('View All Templates')" (click)="viewTemplates()"></material-button>
                <mat-form-field appearance="outline" appRemoveAriaOwns>
                    <mat-select placeholder="{{translate('Select template')}}" [(value)]="appliedTemplateId">
                        <mat-option [value]=""><em>--{{translate('Please select')}}--</em></mat-option>
                        <mat-form-field class="searchContentClass">
                            <input matInput [placeholder]="translate('Search template')" type="text" [(ngModel)]="searchedTemplate" onclick="event.stopPropagation()" />
                            <button mat-button matSuffix mat-icon-button aria-label="clear" (click)="searchedTemplate = ''">
                                <mat-icon class='searchEntitiesIcons' *ngIf='searchedTemplate'>
                                    close
                                </mat-icon>
                                <mat-icon *ngIf='!searchedTemplate' class="searchEntitiesIcons">
                                    search
                                </mat-icon>
                            </button>
                        </mat-form-field>
                        <mat-option *ngFor="let template of templates | multiFilter:searchedTemplate;" [value]="template.templateId">
                            {{template.templateName}}
                        </mat-option>
                        <div class="noTemplatesMsg" *ngIf="(templates | multiFilter:searchedTemplate)?.length === 0">
                            {{translate('No Template Found')}}
                        </div>
                    </mat-select>
                </mat-form-field>
                <material-button [label]="translate('Apply Template')" (click)="applyReconTemplate()"></material-button>
            </div>
        </div>
        <div class="process-body">
            <div class="process-steps">
                <div class="process-step request-step">
                    <div class="step-left-content">
                        <span class="name"> {{translate('Request Attributes')}} </span>
                        <span class="material-icons info-icon" matTooltip="{{translate('Information will shown here...')}}"> info_outline </span>
                    </div>
                    <div class="step-right-content">
                        <ng-container *ngIf="filingAttributeFields?.length > 0; else noFilingAttributes">
                            <div class="row">
                                <ng-container *ngFor="let field of filingAttributeFields">
                                    <div class="col-3 tolerance-step" *ngIf="!field.autoCalculate">
                                        <filingattribute-field [field]="field" [record]="filingAttributeFields" [service]="app" [type]="'filingDate'"></filingattribute-field>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="process-step matching-step">
                    <div class="step-left-content">
                        <span class="name"> {{translate('Matching Criteria')}} </span>
                        <span class="material-icons info-icon" matTooltip="{{translate('Information will shown here...')}}"> info_outline </span>
                    </div>
                    <div class="step-right-content">
                        <div class="matches-container row">
                            <div class="match col-4" *ngFor="let step of reconConfig?.steps" title="{{step.name}}">
                                <material-checkbox [showName]="true" [name]="step.name" [id]="step.id" [model]="step.selected" [description]="step.description" (modelChange)="step.selected = $event" [disabled]="step.mandatory">
                                </material-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="process-step source-step" *ngFor="let source of reconConfig?.sources; let i = index">
                    <div class="step-left-content">
                        <span class="name"> {{source.name}} </span>
                    </div>
                    <div class="step-right-content">
                        <div class="sources-container">
                            <ng-container *ngTemplateOutlet="sourceInputTemplate; context: { source: source, index: i }"></ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div class="process-buttons">
                <material-button class="cancel-btn" [color]="'default'" [label]="translate('Cancel')" (onTrigger)="cancelNewProcessCreation()"></material-button>
                <material-button class="submitColor" [hidden]="hideTemplateButton" [label]="selectedTemplateId? translate('Update Template'): translate('Save As Template')" (onTrigger)="saveOrUpdateTemplate()">
                </material-button>
                <material-button class="submitColor" [label]="translate('Run Reconciliation')" (onTrigger)="validateAndSubmitData()">
                </material-button>
            </div>
        </div>
    </div>

    <ng-template #noFilingAttributes>
        <div class="noTrasformationSelectedCls">
            <span class="renderContent">{{translate('No Pre-Capture Attributes found')}}</span>
        </div>
    </ng-template>

    <ng-template #sourceInputTemplate let-source="source" let-index="index">
        <mat-tab-group class="source-tab-group" (selectedTabChange)="source.selected && inputTypeNames[source.selected.value]" (selectedIndexChange)="source.selected.setValue($event);onInputTypeTabChange()" [selectedIndex]="source.selected.value">
            <ng-container>
                <mat-tab *ngFor="let type of inputTypeNames">
                    <ng-template mat-tab-label>
                        {{translate(type)}}
                    </ng-template>
                    <ng-container *ngIf="type === 'Upload File'">
                        <div class="header">
                            <span>{{translate('You need to select the data transformation first before uploading any file')}}</span>
                        </div>
                        <div class="source-transformations row">
                            <div class="transformations col-4 ml-n3">
                                <material-selectgroup [appearance]="'outline'" [required]="true" [options]="source.transformations" [placeholder]="translate('Select Data Transformation')" [model]="source.selectedTransformationName" [optionDisplayNameProperty]="'displayName'" [optionValueProperty]="'id'" (modelChange)="source.selectedTransformationName = $event; getSelectedTransformation(source)" [groupDisplayNameProperty]="'orgName'" [groupValueProperty]="'transformations'" [errors]="source?.transformationError || []" [title]="source.selectedTransformationName">
                                </material-selectgroup>
                            </div>
                            <div class="col-8 row p-0">
                                <div class="src-details" *ngFor="let src of source.transformationSources">
                                    <div class="file-upload col-2.5" [ngClass]="{'file-uploaded': src.fileName,'fileNameClass': src.fileName}">
                                        <fileUpload [data]="attachmentData" [clearFiles]="clearFiles" (newValue)="fileChanged($event, source, src)" (fileName)="changedFileName($event, source, src)"></fileUpload>
                                    </div>
                                    <div class="file-name col-5" *ngIf="!source.uploadError" matTooltip="{{src.fileName}}">
                                        {{src.fileName}}
                                    </div>
                                    <div class="file-name col-5 error-file-name" *ngIf="source.uploadError">
                                        {{src.fileName ? src.fileName : translate('Please upload file from computer')}}
                                    </div>
                                    <div class="src-info col-3.5">
                                        <p class="elip-txt" matTooltip="{{translate('Source Name')}} : {{src.name}}">{{translate('Source Name')}} :
                                            <span class="fileType">{{src.name | pascalToCamel}}</span>
                                        </p>
                                        <p class="elip-txt" matTooltip="{{translate('Supported file type')}} : {{src.sourceDataFormat}}">{{translate('Supported file type')}} : <span class="fileType">{{src.sourceDataFormat}}</span>
                                        </p>
                                        <p>
                                            {{translate('Download Source Template')}} :
                                            <span class="downloadLink" matTooltip="{{translate('Download Source Template')}}" (click)="downloadSourceTemplateUrl(source, src)">{{translate('Download')}}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="type === 'Pull the source file through an App'">
                        <div class="header">
                            <span>{{translate('You need to select the data transformation first before adding search conditions')}}</span>
                        </div>
                        <div class="source-transformations row">
                            <div class="transformations col-4 ml-n3">
                                <material-selectgroup [appearance]="'outline'" [required]="true" [options]="source.assetToAssetTransformations" [placeholder]="translate('Select Transformation')" [model]="source.selectedAssetToAssetTrnsName" [optionDisplayNameProperty]="'displayName'" [optionValueProperty]="'id'" (modelChange)="source.selectedAssetToAssetTrnsName = $event; getSelectedAssetToAssetTransformation(source)" [groupDisplayNameProperty]="'orgName'" [title]="source.selectedAssetToAssetTrnsName" [groupValueProperty]="'transformations'">
                                </material-selectgroup>
                            </div>
                        </div>
                        <div class="source-filters" *ngIf="source.selectedAssetToAssetTrnsName?.length > 0">
                            <div class="header">
                                <span class="search">{{translate('Search')}}</span>
                                <span class="check-tenants">
                                    <material-checkbox [name]="translate('Check Related Tenants')" [model]="source.unitConsiderationEnabled" [showName]="true" (modelChange)="source.unitConsiderationEnabled = $event">
                                    </material-checkbox>
                                </span>
                            </div>
                            <div class="">
                                <ng-container *ngTemplateOutlet="customSearch; context: { source: source }"></ng-container>
                            </div>
                        </div>
                    </ng-container>
                </mat-tab>
            </ng-container>
        </mat-tab-group>
    </ng-template>

    <ng-template #noTransformation>
        <div class="noTrasformationSelectedCls">
            <span class="renderContent">{{translate('Please select a transformation')}}</span>
        </div>
    </ng-template>

    <ng-template #customSearch let-source="source">
        <ng-container *ngIf="source.assetToAssetTransformationMetaData?.uid">
            <mat-tab-group class="w-100" [selectedIndex]="source.selectedSearchTab">
                <mat-tab label="{{translate('Rules')}}">
                    <app-search-builder [search]="source.search" searchType="SEARCH"></app-search-builder>
                    <div class="d-flex align-items-center justify-content-end pb-1 mt-4">
                        <material-button class="noOutline submitColor mx-2" [color]="'primary'" [label]="!source.search.criteriaUpdated ? translate('Add Criteria') : translate('Update Criteria')" (onTrigger)="source.search.buildCriterias()"></material-button>
                    </div>
                </mat-tab>
                <mat-tab label="{{translate('Relations')}}">
                    <div class="relationsTab pt-4">
                        <div class="queryBuilderMainCntr">
                            <app-search-builder [search]="source.search" [showQueryBuilderDirectly]="true" searchType="RELATION"></app-search-builder>
                        </div>
                        <div class="d-flex align-items-center justify-content-end mt-4">
                            <material-button class="noOutline submitColor" [label]="translate('Apply Relations')" (onTrigger)="source.search.applyRelations()"></material-button>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </ng-container>
    </ng-template>
</div>
