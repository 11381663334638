import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyTabGroup as MatTabGroup, MatLegacyTabHeader as MatTabHeader } from '@angular/material/legacy-tabs';
import { RootScope } from '@encomply/core/services/rootscope/rootscope.service';
import { translate } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { CommonUtilsService, PaginationInterface } from 'taxilla-library';

@Component({
    selector: 'role-permissions',
    templateUrl: './rolepermissions.component.html',
    styleUrls: ['./rolepermissions.component.scss'],
})
export class RolePermissionsComponent implements OnInit, OnChanges {
    @Input() rolePermissionsData;
    @Input() isCheckboxNeeded: boolean;
    @Input() isNextClicked: boolean;
    @ViewChild('matTabGroup') matTabGroup: MatTabGroup;

    activePermission: string;
    displayList: any;
    selectedAsset: any;
    selectedDisplayList: any;
    console = console.log;
    grandPermission: any;
    permissionNames = [];
    pagination: PaginationInterface = {
        searchAfter: undefined,
        size: 5,
        count: 0,
        pageIndex: 0,
    };
    currentPermissions: any;
    searchAssetValue: string;
    selected = new UntypedFormControl();
    rolesPermissionsSubscription: Subscription;
    AllRolePermissions: any;
    removePermissions = ['Manage Catalogue', 'Manage Event Calendar'];
    apps = [];
    public privateMasters: { [property: string]: boolean } = {};

    constructor(private R: RootScope, private _commonUtils: CommonUtilsService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.isNextClicked) {
            (this.matTabGroup?._tabHeader as MatTabHeader)?.updatePagination();
        }
    }

    setDefaultDisplyList = () => {
        this.apps = this.R.allSubscribedApps.value;
        this.permissionNames = [];
        if (this.AllRolePermissions && this.AllRolePermissions.length > 0) {
            (this.AllRolePermissions as any[]).forEach((element, index) => {
                if (element && element.name !== 'Assets' && element.name !== 'BridgeAssets') {
                    this.permissionNames.push(this.translateString(element.name === 'Recons' ? 'Recon' : element.name));
                }
                if (element.name === 'Assets') {
                    this.permissionNames.push(this.translateString('App'));
                }
                if (element.name === 'BridgeAssets') {
                    this.permissionNames.push(this.translateString('Bridge App'));
                }
                if (element.name === 'Assets' || element.name === 'BridgeAssets') {
                    element.subSections.forEach((subsection) => {
                        this.apps.find((app) => {
                            if (subsection.name === app.name) {
                                subsection.displayName = app.displayName;
                            }
                        });
                    });
                }
                if (index === 0) {
                    this.displayList = element;
                    this.activePermission = element.name;
                    this.selected.setValue(index);
                }
            });
        }
    };

    /**
     * Method to translate string values
     * @param msg is the string to be translated
     * @returns repective translate string
     */
    translateString = (msg: string): string => translate(`${msg}`);

    searchAssets = (assetName) => {
        if (assetName && assetName.length > 0) {
            const searchStr = assetName.toLowerCase();
            const searchPermissionsArray = [];
            this.currentPermissions.subSections.forEach((element) => {
                if (
                    (element.displayName && element.displayName.toLowerCase().includes(searchStr)) ||
                    element.name.toLowerCase().includes(searchStr)
                ) {
                    searchPermissionsArray.push(element);
                }
            });
            this.pagination = {
                count: searchPermissionsArray.length,
                page: undefined,
                pageIndex: 0,
                searchAfter: undefined,
                size: this.pagination.size ? this.pagination.size : 5,
            };
            const lowRange = this.pagination.size * this.pagination.pageIndex;
            const highRange = this.pagination.size * (this.pagination.pageIndex + 1);
            this.displayList = {
                name: this.currentPermissions.name,
                permissions: this.currentPermissions.permissions,
                subSections: (searchPermissionsArray as any[]).filter((_subSection, index) => index >= lowRange && index < highRange),
                uid: this.currentPermissions.uid,
            };
        } else {
            this.clearSearch();
        }
    };

    clearSearch = () => {
        this.searchAssetValue = '';
        this.pagination = {
            count: this.currentPermissions.subSections.length,
            page: undefined,
            pageIndex: 0,
            searchAfter: undefined,
            size: this.pagination.size ? this.pagination.size : 5,
        };
        const lowRange = this.pagination.size * this.pagination.pageIndex;
        const highRange = this.pagination.size * (this.pagination.pageIndex + 1);
        this.displayList = {
            name: this.currentPermissions.name,
            permissions: this.currentPermissions.permissions,
            subSections: (this.currentPermissions.subSections as any[]).filter(
                (_subSection, index) => index >= lowRange && index < highRange
            ),
            uid: this.currentPermissions.uid,
        };
    };

    changePermissions = (permission) => {
        if (permission.name === 'Assets' || permission.name === 'BridgeAssets') {
            this.pagination = {
                count: permission.subSections.length,
                page: undefined,
                pageIndex: 0,
                searchAfter: undefined,
                size: 5,
            };
            this.currentPermissions = permission;
            const lowRange = this.pagination.size * this.pagination.pageIndex;
            const highRange = this.pagination.size * (this.pagination.pageIndex + 1);
            this.displayList = {
                name: this.currentPermissions.name,
                permissions: this.currentPermissions.permissions,
                subSections: (this.currentPermissions.subSections as any[]).filter(
                    (_subSection, index) => index >= lowRange && index < highRange
                ),
                uid: this.currentPermissions.uid,
            };
            this.activePermission = permission.name;
        } else {
            this.displayList = permission;
            this.activePermission = permission.name;
        }
        this.searchAssetValue = '';
    };

    isArray = (data) => {
        return Array.isArray(data);
    };

    trackSubSection(_: number, item: any): any {
        return item.name;
    }

    trackPermission(_: number, item: any): any {
        return item.actionName;
    }

    checkAllPermissions = (event, permissions, parentPermissionObject) => {
        this.assignPermissionToChildren(event, permissions);
        this.checkPermissionsUnderRoot(parentPermissionObject);
    };

    private checkPermissionsUnderRoot = (parentPermissionObject) => {
        let allSelected = true;
        parentPermissionObject.permissions?.forEach((permission) => {
            allSelected = permission.selected && allSelected;
        });
        if (parentPermissionObject.permissions.selected !== undefined) {
            parentPermissionObject.permissions.selected = allSelected;
        }
        parentPermissionObject.subSections?.forEach((subSection) => {
            allSelected = this.checkPermissionsUnderRoot(subSection) && allSelected;
        });
        if (parentPermissionObject.selected !== undefined) {
            parentPermissionObject.selected = allSelected;
        }
        return allSelected;
    };

    getGrandParent = (parentPermission) => {
        this.AllRolePermissions.forEach((element) => {
            this.getPermission(element, parentPermission, {});
        });
    };

    getPermission = (element, parentPermission, grandParent) => {
        if (element.uid === parentPermission.uid) {
            this.grandPermission = grandParent;
            return;
        } else {
            if (element.subSections && element.subSections.length > 0) {
                element.subSections.forEach((subelement) => {
                    this.getPermission(subelement, parentPermission, element);
                });
            }
        }
    };

    public assignPermissionSelection = (event, permissions, mainPermissions) => {
        this.assignPermissionToChildren(event, permissions);
        this.checkPermissionsUnderRoot(mainPermissions);
    };

    private assignPermissionToChildren = (event, parentObject) => {
        parentObject?.forEach?.((permission) => {
            if (permission.selected !== undefined) {
                permission.selected = event;
            }
            permission.subSections?.forEach((subSection) => {
                if (subSection.selected !== undefined) {
                    subSection.selected = event;
                }
                subSection.selected = event;
                this.assignPermissionToChildren(event, subSection);
            });
        });
        parentObject?.permissions?.forEach?.((permission) => {
            if (permission.selected !== undefined) {
                permission.selected = event;
            }
            permission.subSections?.forEach((subSection) => {
                if (subSection.selected !== undefined) {
                    subSection.selected = event;
                }
                subSection.selected = event;
                this.assignPermissionToChildren(event, subSection);
            });
        });
        parentObject?.subSections?.forEach?.((subSection) => {
            if (subSection.selected !== undefined) {
                subSection.selected = event;
            }
            this.assignPermissionToChildren(event, subSection);
        });
    };

    fetchPermissionsInPage = (pageIndex) => {
        this.pagination.pageIndex = pageIndex;
        const searchValue = this.searchAssetValue?.toLowerCase();
        const filteredSubSections = this.currentPermissions.subSections.filter(
            (element) => !searchValue || element.name.toLowerCase().includes(searchValue)
        );
        const lowRange = this.pagination.size * this.pagination.pageIndex;
        const highRange = lowRange + this.pagination.size;
        this.displayList = {
            name: this.currentPermissions.name,
            permissions: this.currentPermissions.permissions,
            subSections: filteredSubSections.slice(lowRange, highRange),
            uid: this.currentPermissions.uid,
        };
    };

    refreshItemsWithNewPageSize = (pageSize) => {
        this.pagination.pageIndex = 0;
        this.pagination.size = pageSize;
        const searchValue = this.searchAssetValue?.toLowerCase();
        const subSections = this.currentPermissions.subSections.filter(
            (element) => !searchValue || element.name.toLowerCase().includes(searchValue)
        );
        const lowRange = this.pagination.size * this.pagination.pageIndex;
        const highRange = lowRange + this.pagination.size;
        this.displayList = {
            name: this.currentPermissions.name,
            permissions: this.currentPermissions.permissions,
            subSections: subSections.slice(lowRange, highRange),
            uid: this.currentPermissions.uid,
        };
    };

    public setPermissionContext = (permission) => {
        this.displayList = {};
        if ((permission.name === 'Assets' || permission.name === 'BridgeAssets') && this.isCheckboxNeeded) {
            this.pagination = {
                count: permission.subSections.length,
                page: undefined,
                pageIndex: 0,
                searchAfter: undefined,
                size: 5,
            };
            this.currentPermissions = permission;
            const lowRange = this.pagination.size * this.pagination.pageIndex;
            const highRange = this.pagination.size * (this.pagination.pageIndex + 1);
            this.displayList = {
                name: this.currentPermissions.name,
                permissions: this.currentPermissions.permissions,
                subSections: (this.currentPermissions.subSections as any[]).filter(
                    (_subSection, index) => index >= lowRange && index < highRange
                ),
                uid: this.currentPermissions.uid,
            };
            this.displayList.selected = this.checkForAllSelect(this.displayList.subSections);
            this.activePermission = permission.name;
        } else {
            this.displayList = permission;
            this.activePermission = permission.name;
        }
        this.searchAssetValue = '';
    };

    private checkForAllSelect = (subSectionList) => {
        let allSelected = true;
        subSectionList.forEach((subSection) => {
            allSelected = subSection.selected && allSelected;
        });
        return allSelected;
    };

    isEmpty = (obj) => {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    };

    getSelectedAssets = (assets) => {
        const selectedAssets = [];
        assets.forEach((element) => {
            if (element.selected) {
                selectedAssets.push(element);
            }
        });
        return selectedAssets || [];
    };

    checkPermissionSelected = (permissions) => {
        let count = 0;
        for (let i = 0; i < permissions.length; i++) {
            if (permissions[i].selected) {
                count = count + 1;
            }
        }
        if (count) {
            return true;
        } else {
            return false;
        }
    };

    updateSubdisplayList = (subDisplayList, event) => {
        if (!subDisplayList) {
            subDisplayList = {};
        }
        Object.assign(subDisplayList, event);
    };

    private buildMasterPrivateRepositoryCheck = () => {
        const currentOrganizationId = this._commonUtils.getFromStorage('currentorganizationid');
        const allMasters = this._commonUtils.getFromStorage('allmasters')?.[currentOrganizationId];
        this.privateMasters = Object.keys(allMasters || {}).reduce((map, masterId) => {
            map[masterId] =
                allMasters[masterId]?.organizationId === currentOrganizationId && allMasters[masterId]?.organizationId !== '111';
            return map;
        }, {});
    };

    ngOnInit() {
        this.buildMasterPrivateRepositoryCheck();
        this.rolesPermissionsSubscription = this.rolePermissionsData.subscribe((permissions) => {
            if (permissions) {
                this.AllRolePermissions = permissions;
                this.setDefaultDisplyList();
            }
        });
    }

    ngOnDestroy() {
        this.rolesPermissionsSubscription?.unsubscribe();
    }
}
