import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AuthorizationSystem } from '../../models/integrations/authorization-system.class';
import { AUTHORIZATION_SYSTEMS_REDUCER_REDUCER_KEY } from '../reducers';
import { AuthorizationSystemsState } from '../states';

const currentState = createFeatureSelector<AuthorizationSystemsState>(AUTHORIZATION_SYSTEMS_REDUCER_REDUCER_KEY);

const authorizationSystemName$ = createSelector(currentState, (state) => state.authorizationName);

const authorizationSystems$ = createSelector(currentState, authorizationSystemName$, (state) => state.configs);

export const getAuthorizationSystemId$ = createSelector(currentState, (state) => state.authorizationId);

export const getAuthorizationSystemOptions$ = createSelector(currentState, (state) => state.configOptions);

export const getAuthorizationSystems$ = createSelector(authorizationSystems$, authorizationSystemName$, (configs, configName) =>
    configs?.filter((config) => config.active)?.filter((config) => !configName || config.name.toLowerCase().includes(configName))
);

export const getAuthorizationSystem$ = createSelector(
    authorizationSystems$,
    getAuthorizationSystemId$,
    (configs, id): AuthorizationSystem => {
        if (id === 'create-new') {
            return new AuthorizationSystem();
        }
        return configs?.find((config) => config.id === id);
    }
);
