import { createReducer, on } from '@ngrx/store';

import {
    SetAppSearchFilters,
    SetDefaultSearchFilters,
    SetSearchInstancesCriteria,
    SetSearchRecordsCriteria,
    SetSearchRequestsCriteria,
} from '../actions/search.actions';
import * as sessionActions from '../actions/session.actions';
import { searchState } from '../states';

export const SEARCH_REDUCER = createReducer(
    searchState,
    on(SetDefaultSearchFilters, (state, action) => {
        return {
            ...state,
            organizations: {
                ...state.organizations,
                [action.organizationId]: {
                    ...state.organizations?.[action.organizationId],
                    [action.serviceId]: {
                        ...state.organizations?.[action.organizationId]?.[action.serviceId],
                        defaultFilters: {
                            'default-filters': action.filters,
                            filterCriterias: action.criterias,
                        },
                    },
                },
            },
        };
    }),
    on(SetAppSearchFilters, (state, action) => {
        return {
            ...state,
            organizations: {
                ...state.organizations,
                [action.organizationId]: {
                    ...state.organizations?.[action.organizationId],
                    [action.serviceId]: {
                        ...state.organizations?.[action.organizationId]?.[action.serviceId],
                        searchFilters: action.filters,
                    },
                },
            },
        };
    }),
    on(SetSearchInstancesCriteria, (state, action) => {
        return {
            ...state,
            searchCriteria: {
                ...state.searchCriteria,
                [action.serviceId]: {
                    ...state.searchCriteria?.[action.serviceId],
                    instances: {
                        ...state.searchCriteria?.[action.serviceId]?.instances,
                        [action.criteriaName]: action.searchCriteria,
                    },
                },
            },
        };
    }),
    on(SetSearchRequestsCriteria, (state, { serviceId, criteriaName, searchCriteria }) => {
        return {
            ...state,
            searchCriteria: {
                ...state.searchCriteria,
                [serviceId]: {
                    ...state.searchCriteria?.[serviceId],
                    requests: {
                        ...state.searchCriteria?.[serviceId]?.instances,
                        [criteriaName]: searchCriteria,
                    },
                },
            },
        };
    }),
    on(SetSearchRecordsCriteria, (state, { serviceId, criteriaName, searchCriteria }) => {
        return {
            ...state,
            searchCriteria: {
                ...state.searchCriteria,
                [serviceId]: {
                    ...state.searchCriteria?.[serviceId],
                    requests: {
                        ...state.searchCriteria?.[serviceId]?.instances,
                        [criteriaName]: searchCriteria,
                    },
                },
            },
        };
    }),
    on(sessionActions.SessionLogoutSuccessfull, () => searchState)
);

export const SEARCH_REDUCER_KEY = 'search';
