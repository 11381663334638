import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { HelpDocPreviewComponent } from '../../components/help-doc-preview/help-doc-preview.component';
import { AppTemplate } from '../../models/app-template.class';
import { AssetData } from '../../models/assetdata.class';
import { AssetService } from '../../models/assetservice.class';
import { BridgeNode } from '../../models/bridgeNode.interface';
import { ReconciliationConfiguration } from '../../models/reconciliation-configuration.class';
import { Transformation } from '../../models/transformation';
import { AlertError } from '../../store/actions';
import { BridgeService } from '../bridge/bridge.service';
import { CommonUtilsService } from '../commonutils/common-utils.service';
import { PermissionsService } from '../permissions/permissions.service';
import { RootScopeService } from '../rootscope/rootscope.service';
import { StoreService } from '../store/store.service';
import { SubscriptionsService } from '../subscriptions/subscriptions.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
    providedIn: 'root',
})
export class AssetsService {
    constructor(
        private _bridge: BridgeService,
        private _utils: UtilsService,
        protected R: RootScopeService,
        private _store: StoreService,
        private _commonUtils: CommonUtilsService,
        private _permissions: PermissionsService,
        private _subscriptions: SubscriptionsService,
        private store$: Store,
        private dialog: MatDialog
    ) {}

    /**
     * Method to get asset meta data
     * @param data Contains service data,
     * @param callbacks Contains success callback method,
     */
    getAssetMetaData = async (
        data: { assetMetaUId: string; name: string; noAlerts?: boolean },
        callbacks?: {
            successCallback: (response: AssetData) => void;
            failureCallback?: (response: any) => void;
        }
    ) => {
        const response = await this._store.publicScope.fetchValues(
            () => {
                return new Promise<AssetData>((resolve) => {
                    this._bridge.getAssetMetadata(
                        data.assetMetaUId,
                        async (res) => {
                            const metaDataResponse = res?.response;
                            const rulesPromise = this.getAssetComputationalRules(metaDataResponse?.uid, undefined, data.noAlerts);
                            const languagePromise = this.getAssetMetadataLanguageTemplate({
                                assetMetaUId: data.assetMetaUId,
                                noAlerts: data.noAlerts,
                            });
                            Promise.all([rulesPromise, languagePromise]).then(([computationalRules, template]) => {
                                metaDataResponse.computationalRules = computationalRules;
                                const assetData = new AssetData(res?.response || {}, template);
                                resolve(assetData);
                            });
                        },
                        (res) => {
                            callbacks?.failureCallback?.(res);
                            !callbacks?.failureCallback &&
                                this.store$.dispatch(
                                    AlertError({ message: (res && res.msg) || 'Failed to get meta data for ' + data.name })
                                );
                        },
                        data
                    );
                });
            },
            'assetMetaData',
            data.assetMetaUId
        );
        const metaData = response?.['assetMetaData']?.[data.assetMetaUId];
        if (callbacks?.successCallback) {
            callbacks.successCallback(new AssetData(metaData));
        } else {
            return new AssetData(metaData);
        }
    };

    /**
     * Method to get asset meta data
     * @param data Contains service data,
     * @param callbacks Contains success callback method,
     */
    getAssetMetaDataViaPromise = async (data: { assetMetaUId: string; name: string; noAlerts?: boolean }) => {
        return new Promise<AssetData>((resolve, reject) => {
            this._bridge.getAssetMetadata(
                data.assetMetaUId,
                async (res) => {
                    const metaDataResponse = res?.response;
                    const rulesPromise = this.getAssetComputationalRules(metaDataResponse?.uid, undefined, data.noAlerts);
                    const languagePromise = this.getAssetMetadataLanguageTemplate({
                        assetMetaUId: data.assetMetaUId,
                        noAlerts: data.noAlerts,
                    });
                    Promise.all([rulesPromise, languagePromise]).then(([computationalRules, template]) => {
                        metaDataResponse.computationalRules = computationalRules;
                        const assetData = new AssetData(JSON.parse(JSON.stringify(res?.response)) || {}, template);
                        resolve(assetData);
                    });
                },
                (res) => {
                    this.store$.dispatch(AlertError({ message: (res && res.msg) || 'Failed to get meta data for ' + data.name }));
                    reject();
                },
                data
            );
        });
    };

    getAssetMetadataLanguageTemplate = async (data: { assetMetaUId: string; noAlerts?: boolean }): Promise<AssetData> => {
        return new Promise(async (resolveLanguage) => {
            const id = data.assetMetaUId;
            const language = this._commonUtils.getActiveLanguage();
            const storeId = id + '_' + language;
            const response = await this._store.publicScope.fetchValues(
                () => {
                    return new Promise<AssetData>((resolve) => {
                        this._bridge.getAssetMetadataLanguageTemplate(
                            data.assetMetaUId,
                            async (res) => {
                                const metaDataResponse = res;
                                resolve(metaDataResponse);
                            },
                            () => {
                                resolve(undefined);
                            },
                            data
                        );
                    });
                },
                'assetLanguageTemplate',
                storeId
            );
            resolveLanguage(response?.['assetLanguageTemplate']?.[storeId]);
        });
    };

    /**
     * Method to get asset reconciliation configuration
     * @param data Contains Rest Api Name,
     * @param callbacks Contains success callback method,
     */
    getAssetReconciliationConfiguration = ({
        restApiName,
        serviceId,
        version,
        noAlerts,
        dontTransform,
    }: {
        restApiName: string;
        serviceId: string;
        version?: number;
        noAlerts?: boolean;
        dontTransform?: boolean;
    }) => {
        return new Promise<ReconciliationConfiguration>(async (resolve) => {
            const configVersion = `version_${version || ''}`;
            const response = await this._store.publicScope.fetchValues(
                () => {
                    return new Promise<AssetData>((resolve) => {
                        this._bridge.getAssetReconciliationConfiguration(
                            { restApiName, version, noAlerts },
                            (res) => {
                                resolve(res);
                            },
                            (res) => {
                                resolve(undefined);
                                this._utils.alertError((res && res.msg) || 'Failed to get configuration');
                            }
                        );
                    });
                },
                'reconciliationConfiguration',
                restApiName,
                configVersion
            );
            const configuration = response?.['reconciliationConfiguration']?.[restApiName]?.[configVersion];
            resolve(dontTransform ? configuration : new ReconciliationConfiguration(configuration, this._utils, serviceId));
        });
    };

    getAssetComputationalRules = (assetId: string, entityId?: string, noAlerts?: boolean): Promise<{ [property: string]: string }> => {
        return new Promise((resolve) => {
            this._bridge.getAssetComputationalRules(
                {
                    assetId,
                    entityId,
                    noAlerts,
                },
                (res) => {
                    resolve(res?.response || {});
                },
                (response) => {
                    this._utils.alertError(response?.msg || 'Failed to get computational rules for the asset');
                    resolve({});
                }
            );
        });
    };

    /**
     * Method to get asset workflow stages data
     * @param data Contains assetDataId, assetId
     * @param callbacks Contains success callback method,
     */
    getAssetWorkflowSatages = (
        data: { serviceId: string; assetDataId: string; assetId: string },
        callbacks: { successCallback: (response: any) => void }
    ) => {
        this._bridge.getAssetWorkflowSatages(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                this._utils.alertError(res?.msg || 'Failed to get workflow stages');
            }
        );
    };

    /**
     * Method to get asset Description
     * @param data Contains assetMetaUId data,
     * @param callbacks Contains success callback method,
     */
    getAssetDescription = (data: { assetMetaUId: string; assetType: string; id?: string; noLoading?: boolean }) => {
        return new Promise<string>((resolve) => {
            this._bridge.getAssetDescription(
                data,
                (res) => {
                    resolve(res?.response?.description || 'No Description Found');
                },
                (res) => {
                    this.store$.dispatch(AlertError({ message: res?.msg }));
                }
            );
        });
    };

    /**
     * Method to get asset meta data
     * @param data Contains service data,
     * @param callbacks Contains success callback method,
     */
    fetchEntities = (
        data: { assetId: string },
        callbacks: {
            successCallback: (res) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.fetchEntities(
            data.assetId,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to get asset Reports
     * @param data Contains Reports data,
     * @param callbacks Contains success callback method,
     */
    getReports = (
        data: { assetId: string },
        callbacks: {
            successCallback: (res) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.getReports(
            data.assetId,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to get asset filing attributes
     * @param data Contains bridge data, service data,
     * @param callbacks Contains success callback method,
     */
    getFilingAttributes = (
        data: { bridge?: AssetService; service: AssetService },
        callbacks: {
            successCallback?: (response: { assetId: string; businessKeys: string[]; filingAttributeFields: any[]; lookups: {} }) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.getFilingAttributes(
            {
                bridge: data.bridge,
                service: data.service,
            },
            (res) => {
                callbacks.successCallback(res?.response || {});
            },
            (res) => {
                if (callbacks.failureCallback) {
                    callbacks.failureCallback(res);
                } else {
                    this._utils.alertError(res?.msg || 'Failed to get filing data');
                }
            }
        );
    };

    /**
     * Method to get report filing attributes
     * @param data Contains bridge data, service data,
     * @param callbacks Contains success callback method,
     */
    getReportFilingAttributes = (
        data: { serviceId: string; fileName: string },
        callbacks: {
            successCallback?: (response: { assetId: string; businessKeys: string[]; filingAttributeFields: any[]; lookups: {} }) => void;
        }
    ) => {
        this._bridge.getReportFilingAttributes(
            data,
            (res) => {
                callbacks.successCallback((res && res.response) || {});
            },
            (res) => {
                this._utils.alertError((res && res.msg) || 'Failed to get filing data');
            }
        );
    };

    /**
     * Method to get transformations
     * @param data Contains asset ID
     * @param callbacks Contains success callback method, failure callback method
     */
    getTransformations = (
        data: { assetMetaUId: string },
        callbacks: {
            successCallback: (transformations: {
                [property: string]: {
                    [property: string]: {
                        defaulted: boolean;
                        description: string;
                        engineProps: {
                            type: string;
                            version: string;
                        };
                        mappedTargetMetadata: {
                            dataFormat: string;
                            disableStreamBased: boolean;
                            fileExtension: string;
                            mappedEntities: any[];
                            name: string;
                        };
                        mappingMetadata: {
                            mapId: string;
                            mapName: string;
                        };
                        assetToAssetMetadata?: {
                            assetLinks: any[];
                            name: string;
                            participantAssets: any[];
                        };
                        name: string;
                        sourceParts: {
                            response: AssetData;
                            bulkUpload: boolean;
                            entities: any[];
                            fileExtension: string;
                            name: string;
                            sourceDataFormat: string;
                        }[];
                        xmlVersion: string;
                    };
                };
            }) => void;
            failureCallback: (...args: any[]) => void;
        }
    ) => {
        this._bridge.getTransformations(
            data,
            (res) => {
                const transformationObject = res.response.transformations;
                callbacks.successCallback(transformationObject);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to get reconciliation transformations
     * @param data Contains asset ID
     * @param callbacks Contains success callback method, failure callback method
     */
    getReconciliationTransformations = (
        data: { assetId: string; sourceId: string },
        callbacks: {
            successCallback: (data: {
                transformations: {
                    [property: string]: {
                        repositoryId: string;
                        chainName: string;
                        displayName: string;
                        restApiName: string;
                        sourceTransformation: {
                            name: string;
                            description: string;
                            engineProps: {
                                type: string;
                                version: string;
                            };
                            sourceParts: [
                                {
                                    name: string;
                                    sourceDataFormat: string;
                                    fileExtension: string;
                                    sourceType: string;
                                    useDefaultEscapeChar: boolean;
                                    bulkUpload: boolean;
                                    entities: [];
                                }
                            ];
                            mappedTargetMetadata: {
                                name: string;
                                dataFormat: string;
                                fileExtension: string;
                                mappedEntities: [];
                                disableStreamBased: boolean;
                            };
                            mappingMetadata: {
                                mapName: string;
                            };
                            defaulted: boolean;
                            isService: boolean;
                            xmlVersion: string;
                        };
                    }[];
                };
                organizations: {
                    [property: string]: string;
                };
            }) => void;
            failureCallback: (...args: any[]) => void;
        }
    ) => {
        this._bridge.getReconciliationTransformations(
            data,
            (res) => {
                callbacks.successCallback(res.response);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to get all map transformations
     * @param data Contains asset ID
     * @param callbacks Contains success callback method, failure callback method
     */
    getAllMapTransformations = (data: { assetMetaUId: string; noAlerts?: boolean }) => {
        return new Promise<{
            chainNameVsChainDisplayName: { [property: string]: string };
            organizations: { [property: string]: string };
            transformationSources: {
                [property: string]: {
                    [property: string]: {
                        [property: string]: {
                            [property: string]: string;
                        };
                    };
                };
            };
            transformations: {
                [property: string]: {
                    [property: string]: Transformation;
                };
            };
        }>((resolve, reject) => {
            this._bridge.getAllMapTransformations(
                data,
                (res) => {
                    const transformationObject = res.response;
                    resolve(transformationObject);
                },
                (res) => {
                    reject(res?.msg);
                }
            );
        });
    };

    /**
     * Method to get transformations
     * @param data Contains asset ID and asset Name
     */

    getAllTransformations = (data: { assetMetaUId: string; assetName: string; noAlerts?: boolean }) => {
        return new Observable((observer) => {
            this._bridge.getAllTransformations(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to get active inbound / outbound transformation names
     * @param data Contains transformationType, asset rest api name
     */

    getActiveTransformationsByType = (data: {
        transformationType: 'INBOUND' | 'OUTBOUND';
        assetRestAPIName: string;
        noAlerts: boolean;
    }) => {
        return new Observable((observer) => {
            this._bridge.getActiveTransformationsByType(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to update active inbound / outbound transformation names
     * @param data Contains transformationType, active transformation names, asset rest api name
     */

    updateActiveTransformationsByType = (data: {
        transformationType: 'INBOUND' | 'OUTBOUND';
        activeTrNames: string[];
        assetRestAPIName: string;
        noAlerts: boolean;
    }) => {
        return new Observable((observer) => {
            this._bridge.updateActiveTransformationsByType(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to saveServiceAssetSetupAttr
     * @param data Contains saveServiceAssetSetupAttr details
     * @param callbacks Contains success callback method, failure callback method
     */
    saveServiceAssetSetupAttr = (
        data: {
            serviceId: string;
            assetId: string;
            assetName: string;
            fields: any[];
        },
        callbacks: {
            successCallback: (res) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.saveServiceAssetSetupAttr(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to getServiceSetUpAttributes
     * @param data Contains getServiceSetUpAttributes details
     * @param callbacks Contains success callback method, failure callback method
     */
    getServiceSetUpAttributes = (
        data: { serviceId: string; assetId: string; assetName: string },
        callbacks: {
            successCallback: (res) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.getServiceSetUpAttributes(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to get assetMetaData permissions
     * @param data contains serviceId
     * @param callbacks contains success and failure callbacks
     */
    getAssetMetaDataPermissions = (
        data: { serviceId: string; assetType: string; assetName: string },
        callbacks: {
            successCallback?: (response: any) => void;
            failureCallback?: (response: any) => void;
        }
    ) => {
        this._bridge.getAssetMetaDataPermissions(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to fetch metadata-types (Assets, widgets, bridges) based on tags
     */
    getTagBasedMetadataType = async (data: {
        orgId: string;
        tagkey: string;
        tagValue: string;
        metadataType: string;
        pageSize: number;
        pagingState: string;
        noAlerts?: boolean;
    }): Promise<
        {
            metadataId: string;
            metadataName: string;
            metadataType: string;
            repositoryId: string;
            restApiName: string;
            serviceId: string;
            tagKey: string;
            tagValue: string;
        }[]
    > => {
        const tagValue = Array.isArray(data.tagValue) ? data.tagValue.join(',') : data.tagValue;
        const response = await this._store.publicScope.fetchValues(
            () => {
                return new Promise((resolve) => {
                    this.recursivelyCheckForTags(data)
                        .then(async (res: any) => {
                            resolve(res);
                        })
                        .catch((res) => {
                            this._utils.alertError(res?.msg);
                            resolve(undefined);
                        });
                });
            },
            'appTags',
            data.orgId,
            data.tagkey,
            tagValue,
            data.metadataType
        );
        return response?.appTags?.[data.orgId]?.[data.tagkey]?.[tagValue]?.[data.metadataType];
    };

    recursivelyCheckForTags = (data: {
        orgId: string;
        tagkey: string;
        tagValue: string;
        metadataType: string;
        pageSize: number;
        pagingState: string;
        noAlerts?: boolean;
    }) => {
        let tags = [];
        return new Promise<any[]>((resolve, reject) => {
            this._bridge.getTagBasedMetadataType(
                data,
                async (res) => {
                    const pagingState = res?.response?.customPaginationOptions?.pagingState;
                    tags = [...(res?.response?.taggedMetadatas || [])];
                    if (pagingState?.length > 0) {
                        data.pagingState = pagingState;
                        const childTags = await this.recursivelyCheckForTags(data);
                        tags = [...tags, ...childTags];
                    }
                    resolve(tags);
                },
                (res) => {
                    reject(res);
                }
            );
        });
    };

    /**
     * Method to get getAssetWithVersion
     * @param data getAssetWithVersion
     * @param callbacks Contains success callback method, failure callback method
     */
    getAssetWithVersion = async (
        data: { serviceId: string },
        callbacks: {
            successCallback: (res) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        const response = await this._store.publicScope.fetchValues(
            () => {
                return new Promise((resolve) => {
                    this._bridge.getAssetWithVersion(
                        data,
                        (res) => {
                            resolve(res);
                        },
                        (res) => {
                            callbacks.failureCallback(res);
                        }
                    );
                });
            },
            'assetVersions',
            data.serviceId
        );
        callbacks.successCallback(response?.assetVersions?.[data.serviceId]);
    };

    /**
     * Method to validate Filing Attributes
     * @param data contains filingattributes
     * @param callbacks Contains success callback method, failure callback method
     */
    validateFilingAttributes = (
        data: {
            payload: any;
            restApiName: string;
            bridgeRestApiName: string;
        },
        callbacks: {
            successCallback: (res) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.validateFilingAttributes(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to get bridge source nodes
     * @param data contains bridge , service
     * @param callbacks Contains success callback method, failure callback method
     */
    getBridgeSourceNodes = (
        data: {
            service: AssetService;
            bridge: AssetService;
        },
        callbacks: {
            successCallback: (res) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.getBridgeSourceNodes(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to get asset filtering attributes
     * @param data Contains bridge data, service data,
     * @param callbacks Contains success callback method,
     */
    getFilteringAttributes = (
        data: {
            bridge?: AssetService;
            service: AssetService;
            repositoryId: string;
            transformationName: string;
        },
        callbacks: {
            successCallback: (res) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.getFilteringAttributes(
            {
                bridge: data.bridge,
                service: data.service,
                repositoryId: data.repositoryId,
                transformationName: data.transformationName,
            },
            (res) => {
                callbacks.successCallback((res && res.response) || {});
            },
            (res) => {
                this._utils.alertError((res && res.msg) || 'Failed to get filtering Attributes');
            }
        );
    };

    /**
     * Method to get asset tags
     */
    public getAllAssetTags = async (
        data: {
            serviceId: string;
            noAlerts?: boolean;
        },
        callbacks: {
            successCallback: (res) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        const response = await this._store.publicScope.fetchValues(
            () => {
                return new Promise((resolve) => {
                    this._bridge.getAssetTags(
                        data,
                        (res) => {
                            resolve(res && res.response);
                        },
                        (res) => {
                            if (callbacks.failureCallback) {
                                callbacks.failureCallback(res);
                            } else {
                                this._utils.alertError((res && res.msg) || 'Failed to get app tags');
                            }
                        }
                    );
                });
            },
            'allAppTags',
            data?.serviceId
        );
        const tags = response?.allAppTags?.[data?.serviceId];
        if (callbacks?.successCallback) {
            callbacks.successCallback(tags);
        } else {
            return tags;
        }
    };

    /**
     * Method to get asset tags
     */
    getAssetTags = async (
        data: {
            serviceId: string;
            tagKey?: string;
            noAlerts?: boolean;
        },
        callbacks: {
            successCallback: (res) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        const response = await this._store.publicScope.fetchValues(
            () => {
                return new Promise((resolve) => {
                    this._bridge.getAssetTags(
                        data,
                        (res) => {
                            resolve(res && res.response);
                        },
                        (res) => {
                            if (callbacks.failureCallback) {
                                callbacks.failureCallback(res);
                            } else {
                                this._utils.alertError((res && res.msg) || 'Failed to get app tags');
                            }
                        }
                    );
                });
            },
            'appTags',
            data?.serviceId,
            data?.tagKey
        );
        const tags = response?.appTags?.[data?.serviceId]?.[data?.tagKey];
        if (callbacks?.successCallback) {
            callbacks.successCallback(tags);
        } else {
            return tags;
        }
    };

    /**
     * Method to get asset tags
     */
    downloadErrorReport = (data: {
        defaultFilterName?: string;
        filterCriteria?: any;
        assetName: string;
        restApiServiceName: string;
        serviceId: string;
        reportName: string;
        repositoryId: string;
        generateCompleteInstanceReport: boolean;
    }) => {
        return new Observable((observer) => {
            this._bridge.downloadErrorReport(
                data,
                (res) => {
                    observer.next(res?.response);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     *  method to get all keys
     */
    getAllkeys = async (data?: { metadataTypes?: string }) => {
        if (!data) {
            data = {};
        }
        const id = data?.metadataTypes || 'allMetaDataTypes';
        const response = await this._store.privateScope.fetchValues(
            () => {
                return new Promise((resolve) => {
                    this._bridge.getTagKeys(
                        data,
                        (res) => {
                            resolve(res?.response);
                        },
                        (res) => {
                            this.store$.dispatch(
                                AlertError({
                                    message: res?.msg,
                                })
                            );
                            resolve([]);
                        }
                    );
                });
            },
            'metadataTypeKeys',
            id
        );
        return response?.metadataTypeKeys?.[id];
    };

    /**
     * Method to get all key values
     */
    getTagValuesByKey = async (data: { key: string; metadataTypes?: string }) => {
        const id = data?.metadataTypes || 'allMetaDataTypes';
        const response = await this._store.privateScope.fetchValues(
            () => {
                return new Promise((resolve) => {
                    this._bridge.getTagValuesByKey(
                        data,
                        (res) => {
                            resolve(res?.response);
                        },
                        (res) => {
                            this.store$.dispatch(
                                AlertError({
                                    message: res?.msg || 'Failed to get key values',
                                })
                            );
                        }
                    );
                });
            },
            'metadataTypeValues',
            data.key,
            id
        );
        return response?.metadataTypeValues?.[data.key]?.[id];
    };

    /**
     * Method to get all subscriptions by tag key
     */
    getSubscriptionsByTagKey = async (data: {
        key: string;
        metadataTypes?: string[];
    }): Promise<{
        customPaginationOptions: {
            fastForwardInNextPage: boolean;
            fetchSize: 100;
            lastRowIdentifiers: {};
        };
        taggedMetadatas: {
            metadataId: string;
            metadataName: string;
            metadataType: string;
            repositoryId: string;
            restApiName: string;
            serviceId: string;
            tagKey: string;
            tagValue: string;
        }[];
    }> => {
        const id = data?.metadataTypes?.join('|') || 'allMetaDataTypes';
        const response = await this._store.privateScope.fetchValues(
            () => {
                const subscriptions = this._bridge.getSubscriptionsByTagKey(data);
                subscriptions.catch((e) => {
                    this._utils.alertError(e?.msg || 'Failed to get subscriptions');
                });
                return subscriptions;
            },
            'servicesByTag',
            data.key,
            id
        );
        return response?.servicesByTag?.[data.key]?.[id];
    };

    /**
     * Method to get services based on tag values
     */
    getServicesByTagValues = async (
        data: {
            serviceTypes: string[];
            key: string;
            subscriptionStatus: string;
            size?: number;
            noAlerts?: boolean;
        },
        callbacks?: {
            successCallback: (res) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        const serviceTypes = data.serviceTypes?.join(',') || 'any';
        const response = await this._store.privateScope.fetchValues(
            () => {
                return new Promise((resolve) => {
                    this._bridge.getServicesByTagValues(
                        data,
                        (res) => {
                            resolve(res?.response?.taggedMetadatas);
                        },
                        (res) => {
                            this.store$.dispatch(
                                AlertError({
                                    message: res?.msg || 'Failed to get services',
                                })
                            );
                            resolve([]);
                        }
                    );
                });
            },
            'taggedApps',
            serviceTypes,
            data.key,
            data.subscriptionStatus
        );
        const taggedData = response?.taggedApps?.[serviceTypes]?.[data.key]?.[data.subscriptionStatus];
        if (callbacks?.successCallback) {
            callbacks.successCallback(taggedData);
        } else {
            return taggedData;
        }
    };

    /**
     * To get chain displaynames
     */
    getChainDisplayNames = async (data: { restApiName: string; assetId?: string; noAlerts?: boolean }) => {
        const id = data.assetId ? data.assetId : data.restApiName;
        const response = await this._store.publicScope.fetchValues(
            () => {
                return new Promise((resolve) => {
                    this._bridge.getChainDisplayNames(
                        data,
                        (res) => {
                            resolve(res && res.response);
                        },
                        (res) => {
                            this.store$.dispatch(
                                AlertError({
                                    message: res?.msg || 'Failed to get chain displaynames',
                                })
                            );
                        }
                    );
                });
            },
            'chainDisplayNames',
            id
        );
        return response?.chainDisplayNames?.[id];
    };

    /**
     * Method to get services based on tag values
     */
    getHelpDocument = (data) => {
        return new Promise((resolve) => {
            this._bridge.getHelpDocument(
                data,
                (res) => {
                    const documents = res?.response;
                    resolve(
                        Object.keys(documents).reduce((documentsList, file) => {
                            documentsList.push({
                                fileName: file,
                                fileUrl: documents[file],
                            });
                            return documentsList;
                        }, [])
                    );
                },
                (res) => {
                    this.store$.dispatch(
                        AlertError({
                            message: res?.msg || 'Failed to get help document',
                        })
                    );
                }
            );
        });
    };

    /**
     * Method to get help document data
     */
    getHelpDocumentUrl = (
        data,
        callbacks: {
            successCallback: (res) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.getHelpDocumentUrl(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                if (callbacks.failureCallback) {
                    callbacks.failureCallback(res);
                } else {
                    this._utils.alertError(res?.msg || 'Failed to get help document');
                }
            }
        );
    };

    /**
     * Method to get setup attributes change logs
     * @param data contains orgId, masterRestApiname, primarykeyvalues
     * @param s Success callback
     * @param f Failure callback
     */
    getSetupAttributeChangeLogs = (
        data: {
            restApiName: string;
        },
        callbacks: {
            successCallback: (
                changes: {
                    fieldId: string;
                    isGridColumn: boolean;
                    modifiedDate: string;
                    newValue: string;
                    oldValue: string;
                    userName: string;
                }[]
            ) => void;
            failureCallback?: (response) => void;
        }
    ) => {
        this._bridge.getSetupAttributeChangeLogs(
            data,
            (res) => {
                callbacks.successCallback(res?.response);
            },
            (response) => {
                if (callbacks.failureCallback) {
                    callbacks.failureCallback(response);
                } else {
                    this._utils.alertError(response && response.msg);
                }
            }
        );
    };

    /**
     * Method to create/Update bundle
     */
    updateBundle = (payload: { name: string; description: string; id: string }) => {
        return this._bridge.updateBundle(payload);
    };

    /**
     * Method to get bundles
     */
    getBundles = async (pageNumber?: number) => {
        return new Promise<any[] | string>((resolve, reject) => {
            pageNumber = pageNumber || 1;
            this._bridge
                .getBundles({
                    pageNumber: pageNumber,
                    pageSize: 50,
                })
                .then(async (response) => {
                    if (response.length === 50) {
                        const nextSet = (await this.getBundles(pageNumber + 1)) as any[];
                        response = [...response, ...nextSet];
                    }
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    /**
     * Method to get bundles
     */
    deleteBundle = (bundleId: string) => {
        return this._bridge.deleteBundle(bundleId);
    };

    /**
     * Method to get assignable subscriptions
     */
    getAssignableSubscriptions = async (bundleId: string) => {
        return new Promise<string[] | string>((resolve, reject) => {
            this._bridge
                .getAssignableSubscriptions(bundleId)
                .then((res) => {
                    resolve(
                        res?.response?.subscriptions?.reduce((appIds, app) => {
                            appIds.push(app.serviceId);
                            return appIds;
                        }, [])
                    );
                })
                .catch((error) => reject(error));
        });
    };

    /**
     * Method to get bundle subscriptions
     */
    getBundleSubscriptions = (bundleId: string) => {
        return this._bridge.getBundleSubscriptions(bundleId);
    };

    /**
     * Method to Update bundle subscriptions
     */
    updateBundleSubscriptions = (bundleId: string, payload: { serviceIds: string[] }) => {
        const bundlePromise = this._bridge.updateBundleSubscriptions(bundleId, payload);
        bundlePromise.catch((e) => {
            this._utils.alertError(e?.msg || 'Failed to create bundle');
        });
        return bundlePromise;
    };

    /**
     * Method to get bundle Invitations
     */
    getBundleInvitations = (bundleId: string) => {
        return this._bridge.getBundleInvitations(bundleId);
    };
    /**
     * Method to create bundle Invitations
     */
    createBundleInvitations = (bundleId: string, payload: { partnerRef: string; type: string }[]) => {
        return this._bridge.createBundleInvitations(bundleId, payload);
    };
    /**
     * Method to Delete bundle Invitations
     */
    deleteBundleInvitations = (bundleId: string, invitationId: string) => {
        return this._bridge.deleteBundleInvitations(bundleId, invitationId);
    };

    /*
     * Get recon manual override configurations
     * @param restApiName serviceId of the app
     */
    getReconOverrideConfigurations = (restApiName: string) => {
        const promise = this._bridge.getReconOverrideConfigurations(restApiName);
        promise.catch((e) => {
            this._utils.alertError(e?.msg || 'Failed to get manual settings');
        });
        return promise;
    };

    /**
     * update recon manual override configurations
     */
    submitReconOverrideConfigurations = (restApiName: string, payload: { allowOverride: boolean }[]) => {
        const promise = this._bridge.submitReconOverrideConfigurations(restApiName, payload);
        promise.catch((e) => {
            this._utils.alertError(e?.msg || 'Failed to update manual settings');
        });
        return promise;
    };

    /**
     * Get recon outbound report configurations
     * @param restApiName serviceId of the app
     */
    getReconOutboundReportConfigurations = (restApiName: string) => {
        const promise = this._bridge.getReconOutboundReportConfigurations(restApiName);
        promise.catch((e) => {
            this._utils.alertError(e?.msg || 'Failed to get report configurations');
        });
        return promise;
    };

    /**
     * update recon outbound report configurations
     */
    submitReconOutboundReportConfigurations = (
        restApiName: string,
        payload: {
            reportEndpoint: string;
            reportDisplayName: string;
            autogenerate: boolean;
        }[]
    ) => {
        const promise = this._bridge.submitReconOutboundReportConfigurations(restApiName, payload);
        promise.catch((e) => {
            this._utils.alertError(e?.msg || 'Failed to update report settings');
        });
        return promise;
    };

    /**
     * Get recon outbound app configurations
     * @param restApiName serviceId of the app
     */
    getReconOutboundAppConfigurations = (restApiName: string) => {
        const promise = this._bridge.getReconOutboundAppConfigurations(restApiName);
        promise.catch((e) => {
            this._utils.alertError(e?.msg || 'Failed to get app configurations');
        });
        return promise;
    };

    /**
     * update recon outbound app configurations
     */
    submitReconOutboundAppConfigurations = (
        restApiName: string,
        payload: {
            assetEndpoint: string;
            transformationEndpoint: string;
            assetDisplayName: string;
            requestAttributes: { [property: string]: any };
            autogenerate: boolean;
            transformationSourceName: string;
        }[]
    ) => {
        const promise = this._bridge.submitReconOutboundAppConfigurations(restApiName, payload);
        promise.catch((e) => {
            this._utils.alertError(e?.msg || 'Failed to update app settings');
        });
        return promise;
    };

    /**
     * delete recon outbound app configurations
     */
    deleteReconOutboundAppConfiguration = (restApiName: string, configAssetEndPoint: string) => {
        const organizationId = this._commonUtils.getFromStorage('currentOrganizationId');
        const promise = this._bridge.deleteReconOutboundAppConfiguration(restApiName, configAssetEndPoint, organizationId);
        promise.catch((e) => {
            this._utils.alertError(e?.msg || 'Failed to delete app settings');
        });
        return promise;
    };

    /**
     * Method to get and transform bridge nodes
     */
    public getAndTransformBridgeNodes = (app: AssetService) => {
        return new Promise<{ [property: string]: BridgeNode }>(async (resolve) => {
            if (app?.assetType === 'BRIDGE_ASSET') {
                await this.getBridgeNodes(app);
                await this._permissions.transformAppWithPermissions(app);
                const assetsInBridgePermissions = app.bridgePermissions;
                const apps = {};
                this.transformBridgeNodesResponse(app['subApps']);
                for (const id in app['subApps']) {
                    if (app['subApps'].hasOwnProperty(id)) {
                        const node = app['subApps'][id];
                        if (assetsInBridgePermissions?.[node.name]) {
                            apps[id] = node;
                        }
                    }
                }
                resolve(apps);
            } else {
                resolve(undefined);
            }
        });
    };

    public getBridgeNodes = (app: any): Promise<any> => {
        return new Promise((resolve) => {
            this._subscriptions.fetchServicesUnderlyingBridge(app.assetMetaUId, {
                successCallback: (res) => {
                    const clone = CommonUtilsService.cloneObject(res);
                    app.subApps = clone;
                    resolve(clone);
                },
                failureCallback: () => {},
            });
        });
    };

    public transformBridgeNodesResponse = (response: {
        [property: string]: {
            componentType: string;
            id: string;
            level: number;
            name: string;
            nextNodes: [];
            reports?: any[];
        };
    }) => {
        const reportsFound = [];
        for (const key in response) {
            if (response[key].nextNodes && response[key].nextNodes.length > 0) {
                response[key].reports = response[key].reports || [];
                response[key].nextNodes.forEach((nodeId) => {
                    const node = response[nodeId];
                    if (node?.componentType === 'FILE') {
                        response[key].reports.push(node);
                        if (reportsFound.indexOf(nodeId === -1)) {
                            reportsFound.push(nodeId);
                        }
                    }
                });
            }
        }
        for (const key in response) {
            if (reportsFound.indexOf(key) > -1) {
                delete response[key];
            }
        }
        return response;
    };

    /**
     * Method to get asset template
     * @param data Contains service id, noAlerts,
     */
    getAppTemplate = async (serviceId: string, noAlerts?: boolean) => {
        return this._bridge.getAppTemplate(serviceId, noAlerts);
    };

    /**
     * Method to submit asset template
     * @param data Contains service id, noAlerts,
     */
    submitAppTemplate = async (serviceId: string, template: AppTemplate) => {
        return this._bridge.submitAppTemplate(serviceId, template);
    };

    /**
     * Method to delete asset template
     * @param data Contains service id, noAlerts,
     */
    deleteAppTemplate = async (serviceId: string) => {
        return this._bridge.deleteAppTemplate(serviceId);
    };

    viewHelpDocument = (doc: { fileName: any; fileUrl: any }) => {
        const documentObj = {
            fileName: doc.fileName,
            fileUrl: doc.fileUrl,
        };
        this.getHelpDocumentUrl(documentObj, {
            successCallback: (res) => {
                const fileName = doc.fileName;
                const dialogRef = this.dialog.open(HelpDocPreviewComponent, {
                    panelClass: ['full-screen-modal'],
                    data: {
                        blob: res,
                        fileName,
                    },
                    disableClose: true,
                });
                dialogRef.afterClosed().subscribe(() => {
                    console.log('The Help Doc dialog was closed');
                });
            },
            failureCallback: () => {
                this._utils.alertWarning('Failed to get help document data');
            },
        });
    };

    /**
     * Method to get app configurator
     * @param data Contains service uid and asset meta uid
     */

    getAppConfigurator = (data: { assetMetaUID: string; serviceId: string; noAlerts?: boolean }) => {
        return new Observable((observer) => {
            this._bridge.getAppConfigurator(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };
}
