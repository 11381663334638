import { EntityState } from '@ngrx/entity';

import { NotificationEvent } from '../../interface/notifications-event.interface';
import { ReconEvent } from '../../interface/recon-event.interface';

export interface NotificationsState {
    instanceIdVsEvents: {
        [property: string]: EntityState<NotificationEvent>;
    };
    reconIdVsEvents: {
        [property: string]: EntityState<ReconEvent>;
    };
    pagination: {
        [property: string]: {
            size: number;
            index: number;
            pagingState: string;
            noPagingState?: boolean;
        };
    };
    emailNotifications?: {
        subject: string;
        messageContent: string;
        attachmentList: any[];
        createdDateTime: string;
        createdOn: string;
        emailId: string;
        eventType: string;
        groupId: string;
        messageId: string;
        orgId: string;
        reason: string;
        status: string;
        subscriptionId: string;
    }[];
    emailNotificationsPagination?: {
        size: number;
        index: number;
        pagingState: string;
    };
    eventTypes?: any[];
    emailContent?: {
        [property: string]: {
            attachmentList?: any[];
            messageContent?: string;
            subject?: string;
            emailId?: string;
            messageId?: string;
        };
    };
    eventSubscriptions?: {
        attachment?: string;
        bccEmails?: string[];
        ccEmails?: string[];
        contentTemplate?: string;
        criterion?: string;
        criterionExpression?: string;
        eventType?: string;
        groupId?: string;
        includeHierarchy?: boolean;
        isWorkQueueEnabled?: boolean;
        objectIdTemplate?: string;
        orgId: string;
        organizationIdTemplate?: string;
        service?: string;
        subjectTemplate?: string;
        subscriptionId?: string;
        toEmails?: string[];
    }[];
    eventSubscriptionsPagination?: {
        size: number;
        index: number;
        pagingState: string;
    };
}

export const notificationsState: NotificationsState = {
    instanceIdVsEvents: {},
    reconIdVsEvents: {},
    pagination: {},
};
