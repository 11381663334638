<mat-form-field *transloco="let translate" [appearance]="appearance">
    <input *ngIf="type !== 'number'" type="{{type}}" [dir]="dir" autocomplete="off" [autofocus]="autofocus" matInput placeholder="{{placeholder}}" name="{{name}}" title="{{title}}" id="{{id}}" class="{{classList && classList.join(' ')}}" [formControl]="control" [errorStateMatcher]="matcher" debounce="{{debounceAfter || 1000}}" (eventHandler)="debounceEventHandler.emit($event)" (keyup)="keyUpEventHandler.emit($event)" (keypress)="errors && errors.splice(0,1);">
    <input *ngIf="type === 'number'" type="number" [dir]="dir" autocomplete="off" [autofocus]="autofocus" matInput placeholder="{{placeholder}}" name="{{name}}" title="{{title}}" id="{{id}}" class="{{classList && classList.join(' ')}}" [formControl]="control" [errorStateMatcher]="matcher" debounce="{{debounceAfter || 1000}}" (eventHandler)="debounceEventHandler.emit($event)" (keyup)="keyUpEventHandler.emit($event)" (keypress)="errors && errors.splice(0,1);">
    <button mat-button *ngIf="control.value && control.value.length > 0 && disabled !== true && needIcon && !(errors && errors.length > 0)" matSuffix mat-icon-button>
        <span class="material-icons userIdIcon">
            done
        </span>
    </button>
    <button mat-button *ngIf="description && description.length > 0" stop-propagation matSuffix mat-icon-button aria-label="Description" tabindex="-1" title="{{description}}">
        <span class="material-icons-outlined fieldDescInfoIcon">
            info
        </span>
    </button>
    <button *ngIf="showClearText" mat-button matSuffix mat-icon-button aria-label="clear" (click)="control.setValue('')">
        <mat-icon class='locationManagementIcons' *ngIf='control?.value?.length > 0'>
            close
        </mat-icon>
    </button>
    <mat-hint align="end" *ngIf="maxLength">{{control.value && control.value.length || 0}} / {{maxLength}}</mat-hint>
    <mat-hint align="start" *ngIf="required && !(errors && errors.length > 0)">
        <strong>
            <span>* </span>{{translate('required')}}</strong>
    </mat-hint>
    <mat-hint align="start" *ngIf="!required && !(errors && errors.length > 0) && hint && hint.length > 0">
        {{hint}}
    </mat-hint>
    <mat-error *ngIf="errors && errors.length > 0" title="{{errors}}">
        {{errors}}
    </mat-error>
</mat-form-field>
