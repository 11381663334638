import { environment } from '@env';

import { AssetData } from '../../models/assetdata.class';
import { Entity } from '../../models/entity.class';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { ConfigurationInterface } from '../interface/configuration.interface';

const emailPattern = '[_a-zA-Z0-9-]+(.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(.[a-zA-Z0-9-]+)*(.[a-zA-Z]{2,4})';
const GSTINPattern = '[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}Z[0-9A-Z]{1}';
const phoneNumberPattern = '/^(?!0{10,15})([0-9]{10,15})$/';
const numberPattern = 'd+(.d+)?';
const distancePattern = '([1-3]?d{1,3}|4000)';

const getConfigurations = (): ConfigurationInterface[] => {
    return [
        {
            assetName: environment?.eninvoiceAppsConfig?.['outwardEwb']?.name || 'E-Waybill-V12A',
            restAPIName: environment?.eninvoiceAppsConfig?.['outwardEwb']?.restAPIName || 'ewaybillv12a',
            displayName: 'Outward E-Way Bills',
            show: true,
            mapToPrimaryEntity: {
                ewb_details: {
                    supply_type: 'supply_typee',
                    sub_type: 'supply_sbu_type',
                    doc_type: 'invoice_doc_type',
                    doc_no: 'inv_doc_num',
                    doc_date: 'invoice_doc_date',
                },
                partb: {
                    supp_typ: 'supply_typee',
                    supp_sub_type: 'supply_sbu_type',
                    inv_doc_type: 'invoice_doc_type',
                    invoice_num: 'inv_doc_num',
                    inv_date: 'invoice_doc_date',
                },
                trans_details: {
                    supp_typee: 'supply_typee',
                    supp_sub_typ: 'supply_sbu_type',
                    inv_doc_typ: 'invoice_doc_type',
                    inv_doc_number: 'inv_doc_num',
                    inv_doc_date: 'invoice_doc_date',
                },
                initiate_mvmoment: {
                    sup_typ: 'supply_typee',
                    sub_ty: 'supply_sbu_type',
                    document_typ: 'invoice_doc_type',
                    doc_number: 'inv_doc_num',
                    document_dt: 'invoice_doc_date',
                },
                add_mutli_veh: {
                    suppl_typ: 'supply_typee',
                    sub_tpe: 'supply_sbu_type',
                    docm_typ: 'invoice_doc_type',
                    docum_no: 'inv_doc_num',
                    docum_dt: 'invoice_doc_date',
                },
            },
            customFilters: {
                enabled: true,
                searchEntity: 'general_details',
                searchField: 'e_waybill_status',
                static: false,
            },
            entities: {
                general_details: {
                    maxNumberOfRecords: 1,
                    templates: {
                        feed: {
                            template: 'eway-feed',
                        },
                        detail: {
                            header: {
                                template: 'ewb-detailheader',
                            },
                        },
                    },
                    fields: [
                        {
                            id: 'remarks',
                            rules: [
                                {
                                    failureMessage: 'If Update reason is "Others", then remarks is mandatory',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'general_details',
                                            dependencyField: 'reason_update',
                                            value: '3',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'general_details',
                                            fieldId: 'remarks',
                                            type: 'isRequired',
                                        },
                                    ],
                                },
                                {
                                    failureMessage: 'If Cancel reason is "Others", then remarks is mandatory',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'general_details',
                                            dependencyField: 'reason_cancel',
                                            value: '4',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'general_details',
                                            fieldId: 'remarks',
                                            type: 'isRequired',
                                        },
                                    ],
                                },
                                {
                                    failureMessage: 'If Reject reason is "Others", then remarks is mandatory',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'general_details',
                                            dependencyField: 'reason_rejection',
                                            value: 'Other',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'general_details',
                                            fieldId: 'remarks',
                                            type: 'isRequired',
                                        },
                                    ],
                                },
                                {
                                    failureMessage: 'If Extension reason is "Others", then remarks is mandatory',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'general_details',
                                            dependencyField: 'ext_reason_code',
                                            value: '99',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'general_details',
                                            fieldId: 'remarks',
                                            type: 'isRequired',
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                ewb_details: {
                    maxNumberOfRecords: 1,
                    fields: [
                        {
                            id: 'supp_pers_tel',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid phone number',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: phoneNumberPattern,
                                        },
                                    ],
                                    ruleType: 'pattern',
                                },
                            ],
                        },
                        {
                            id: 'supplier_email',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid email Id',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: emailPattern,
                                        },
                                    ],
                                    ruleType: 'pattern',
                                },
                            ],
                        },
                        {
                            id: 'supplier_gstn',
                            rules: [
                                {
                                    failureMessage: 'GSTIN should not be empty if Registration status is "Registered Dealer"',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'notEqual',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'supplier_regis',
                                            value: 'Unregistered Dealer',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'ewb_details',
                                            fieldId: 'supplier_gstn',
                                            type: 'isRequired',
                                        },
                                    ],
                                },
                                {
                                    failureMessage: 'Enter a valid GSTIN number',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'notEqual',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'supplier_regis',
                                            value: 'Unregistered Dealer',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'ewb_details',
                                            fieldId: 'supplier_gstn',
                                            type: 'pattern',
                                            pattern: GSTINPattern,
                                        },
                                    ],
                                },
                                {
                                    failureMessage: 'GSTIN should be URP if Registration status is "Unregistered Dealer"',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'supplier_regis',
                                            value: 'Unregistered Dealer',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'ewb_details',
                                            fieldId: 'supplier_gstn',
                                            type: 'isRequired',
                                            value: 'URP',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'supplier_pincode',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid Pin Code',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleType: 'pattern',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: numberPattern,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'supplier_shipping_telephone',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid phone number',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleType: 'pattern',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: phoneNumberPattern,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'supplier_shipping_email',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid email Id',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: emailPattern,
                                        },
                                    ],
                                    ruleType: 'pattern',
                                },
                            ],
                        },
                        {
                            id: 'supplier_shipping_gstn',
                            rules: [
                                /* {
                    failureMessage: 'GSTIN should not be empty if Registration status is "Registered Dealer"',
                    ruleType: 'allAreRequired',
                    ruleCategory: 'CONDITIONAL',
                    ruleParams: [{
                        paramType: 'VALUE',
                        name: 'notEqual',
                        dependencyEntity: 'ewb_details',
                        dependencyField: 'supplier_shipping_registration',
                        value: 'Unregistered Dealer'
                    }],
                    mandatoryFields: [{
                        entityId: 'ewb_details',
                        fieldId: 'supplier_shipping_gstn',
                        type: 'isRequired'
                    }],
                }, */ {
                                    failureMessage: 'Enter a valid GSTIN number',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'notEqual',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'supplier_shipping_registration',
                                            value: 'Unregistered Dealer',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'ewb_details',
                                            fieldId: 'supplier_shipping_gstn',
                                            type: 'pattern',
                                            pattern: GSTINPattern,
                                        },
                                    ],
                                },
                                {
                                    failureMessage: 'GSTIN should be URP if Registration status is "Unregistered Dealer"',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'supplier_shipping_registration',
                                            value: 'Unregistered Dealer',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'ewb_details',
                                            fieldId: 'supplier_shipping_gstn',
                                            type: 'isRequired',
                                            value: 'URP',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'supplier_shipping_pincode',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid Pin Code',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleType: 'pattern',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: numberPattern,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'customer_phone',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid phone number',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleType: 'pattern',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: phoneNumberPattern,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'customer_billing_email',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid email Id',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: emailPattern,
                                        },
                                    ],
                                    ruleType: 'pattern',
                                },
                            ],
                        },
                        {
                            id: 'customer_billing_gstn',
                            rules: [
                                {
                                    failureMessage: 'GSTIN should not be empty if Registration status is "Registered Dealer"',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'notEqual',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'customer_registration',
                                            value: 'Unregistered Dealer',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'ewb_details',
                                            fieldId: 'customer_billing_gstn',
                                            type: 'isRequired',
                                        },
                                    ],
                                },
                                {
                                    failureMessage: 'Enter a valid GSTIN number',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'notEqual',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'customer_registration',
                                            value: 'Unregistered Dealer',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'ewb_details',
                                            fieldId: 'customer_billing_gstn',
                                            type: 'pattern',
                                            pattern: GSTINPattern,
                                        },
                                    ],
                                },
                                {
                                    failureMessage: 'GSTIN should be URP if Registration status is "Unregistered Dealer"',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'customer_registration',
                                            value: 'Unregistered Dealer',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'ewb_details',
                                            fieldId: 'customer_billing_gstn',
                                            type: 'isRequired',
                                            value: 'URP',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'customer_billing_pincode',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid Pin Code',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleType: 'pattern',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: numberPattern,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'cust_shipping_ctnumber',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid phone number',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleType: 'pattern',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: phoneNumberPattern,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'customer_email',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid email Id',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: emailPattern,
                                        },
                                    ],
                                    ruleType: 'pattern',
                                },
                            ],
                        },
                        {
                            id: 'customer_gstn',
                            rules: [
                                /* {
                    failureMessage: 'GSTIN should not be empty if Registration status is "Registered Dealer"',
                    ruleType: 'allAreRequired',
                    ruleCategory: 'CONDITIONAL',
                    ruleParams: [{
                        paramType: 'VALUE',
                        name: 'notEqual',
                        dependencyEntity: 'ewb_details',
                        dependencyField: 'cust_shipping_registration',
                        value: 'Unregistered Dealer'
                    }],
                    mandatoryFields: [{
                        entityId: 'ewb_details',
                        fieldId: 'customer_gstn',
                        type: 'isRequired'
                    }],
                }, */ {
                                    failureMessage: 'Enter a valid GSTIN number',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'notEqual',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'cust_shipping_registration',
                                            value: 'Unregistered Dealer',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'ewb_details',
                                            fieldId: 'customer_gstn',
                                            type: 'pattern',
                                            pattern: GSTINPattern,
                                        },
                                    ],
                                },
                                {
                                    failureMessage: 'GSTIN should be URP if Registration status is "Unregistered Dealer"',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'cust_shipping_registration',
                                            value: 'Unregistered Dealer',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'ewb_details',
                                            fieldId: 'customer_gstn',
                                            type: 'isRequired',
                                            value: 'URP',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'customer_pincode',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid Pin Code',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleType: 'pattern',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: numberPattern,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'distance_lev',
                            rules: [
                                {
                                    failureMessage: 'Enter distance in numbers only upto 4000',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleType: 'pattern',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: distancePattern,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'freight',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid value',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleType: 'pattern',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: numberPattern,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'pacaging_forwarding',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid value',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleType: 'pattern',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: numberPattern,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'insurance',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid value',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleType: 'pattern',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: numberPattern,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'reason_cn_dn',
                            rules: [
                                {
                                    failureMessage:
                                        'Reason for issuing of Credit/Debit Note is mandatory if document type is "Credit/Debit note"',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'doc_type',
                                            value: 'Credit Note',
                                        },
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'doc_type',
                                            value: 'Debit Note',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'ewb_details',
                                            fieldId: 'reason_cn_dn',
                                            type: 'isRequired',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'sub_supply_desc',
                            rules: [
                                {
                                    failureMessage: 'Reason is mandatory if supply subtype is Others',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'sub_type',
                                            value: 'Others',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'ewb_details',
                                            fieldId: 'sub_supply_desc',
                                            type: 'isRequired',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'original_invoice',
                            rules: [
                                {
                                    failureMessage: 'Original Invoice Number is mandatory if document type is Credit/Debit note"',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'doc_type',
                                            value: 'Credit Note',
                                        },
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'doc_type',
                                            value: 'Debit Note',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'ewb_details',
                                            fieldId: 'original_invoice',
                                            type: 'isRequired',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'original_date',
                            rules: [
                                {
                                    failureMessage: 'Original Invoice Date is mandatory if document type is Credit/Debit note"',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'doc_type',
                                            value: 'Credit Note',
                                        },
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'doc_type',
                                            value: 'Debit Note',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'ewb_details',
                                            fieldId: 'original_date',
                                            type: 'isRequired',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'original_invoice_value',
                            rules: [
                                {
                                    failureMessage: 'Original Invoice Date is mandatory if document type is Credit/Debit note"',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'doc_type',
                                            value: 'Credit Note',
                                        },
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'doc_type',
                                            value: 'Debit Note',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'ewb_details',
                                            fieldId: 'original_invoice_value',
                                            type: 'isRequired',
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                partb: {
                    maxNumberOfRecords: 1,
                    fields: [
                        {
                            id: 'trans_dcno',
                            rules: [
                                {
                                    failureMessage:
                                        'If Transportation mode is other than Road then Transportation Document Number is required',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'notEqual',
                                            dependencyEntity: 'partb',
                                            dependencyField: 'trns_mode',
                                            value: 'Road',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'partb',
                                            fieldId: 'trans_dcno',
                                            type: 'isRequired',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'trans_date',
                            rules: [
                                {
                                    failureMessage: 'If Transportation mode is other than Road then Transporter Document Date is required',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'notEqual',
                                            dependencyEntity: 'partb',
                                            dependencyField: 'trns_mode',
                                            value: 'Road',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'partb',
                                            fieldId: 'trans_date',
                                            type: 'isRequired',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'vehicle_number',
                            rules: [
                                {
                                    failureMessage: 'If Transportation mode is "Road" then Vehicle Number is required',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'partb',
                                            dependencyField: 'trns_mode',
                                            value: 'Road',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'partb',
                                            fieldId: 'vehicle_number',
                                            type: 'isRequired',
                                        },
                                    ],
                                },
                                {
                                    failureMessage: 'If Transportation mode is not "Road and In Transit" then Vehicle Number must be empty',
                                    ruleType: 'noneRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'partb',
                                            dependencyField: 'trns_mode',
                                            value: 'Rail',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'partb',
                                            fieldId: 'vehicle_number',
                                            type: 'mustBeEmpty',
                                        },
                                    ],
                                },
                                {
                                    failureMessage: 'If Transportation mode is not "Road and In Transit" then Vehicle Number must be empty',
                                    ruleType: 'noneRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'partb',
                                            dependencyField: 'trns_mode',
                                            value: 'Air',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'partb',
                                            fieldId: 'vehicle_number',
                                            type: 'mustBeEmpty',
                                        },
                                    ],
                                },
                                {
                                    failureMessage: 'If Transportation mode is not "Road and In Transit" then Vehicle Number must be empty',
                                    ruleType: 'noneRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'partb',
                                            dependencyField: 'trns_mode',
                                            value: 'Ship',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'partb',
                                            fieldId: 'vehicle_number',
                                            type: 'mustBeEmpty',
                                        },
                                    ],
                                },
                                {
                                    failureMessage: 'If Transportation mode is "In Transit" then Vehicle Number is optional',
                                    ruleType: 'noneRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'partb',
                                            dependencyField: 'trns_mode',
                                            value: 'In Transit',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'partb',
                                            fieldId: 'vehicle_number',
                                            type: 'isOptional',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'consign_status',
                            rules: [
                                {
                                    failureMessage: 'If Transportation mode is Road, then consignment status should be Movement',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'partb',
                                            dependencyField: 'trns_mode',
                                            value: 'Road',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'partb',
                                            fieldId: 'consign_status',
                                            type: 'isRequired',
                                            value: 'Movement',
                                        },
                                    ],
                                },
                                {
                                    failureMessage: 'If Transportation mode is Rail, then Consignment Status should be Movement',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'partb',
                                            dependencyField: 'trns_mode',
                                            value: 'Rail',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'partb',
                                            fieldId: 'consign_status',
                                            type: 'isRequired',
                                            value: 'Movement',
                                        },
                                    ],
                                },
                                {
                                    failureMessage: 'If Transportation mode is Air, then consignment status should be Movement',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'partb',
                                            dependencyField: 'trns_mode',
                                            value: 'Air',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'partb',
                                            fieldId: 'consign_status',
                                            type: 'isRequired',
                                            value: 'Movement',
                                        },
                                    ],
                                },
                                {
                                    failureMessage: 'If Transportation mode is Ship, then consignment status should be Movement',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'partb',
                                            dependencyField: 'trns_mode',
                                            value: 'Ship',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'partb',
                                            fieldId: 'consign_status',
                                            type: 'isRequired',
                                            value: 'Movement',
                                        },
                                    ],
                                },
                                {
                                    failureMessage: 'If Transportation Mode is In Transit, then Consignment Status should be Transit',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'partb',
                                            dependencyField: 'trns_mode',
                                            value: 'In Transit',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'partb',
                                            fieldId: 'consign_status',
                                            type: 'isRequired',
                                            value: 'Transit',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'transit_type',
                            rules: [
                                {
                                    failureMessage:
                                        'If Transportation mode is Road, and Consignment Status is Movement, then Transit Type should be blank',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'partb',
                                            dependencyField: 'trns_mode',
                                            value: 'Road',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'partb',
                                            fieldId: 'transit_type',
                                            type: 'mustBeEmpty',
                                        },
                                    ],
                                },
                                {
                                    failureMessage:
                                        'If Transportation mode is Rail, and Consignment Status is Movement, then Transit Type should be blank',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'partb',
                                            dependencyField: 'trns_mode',
                                            value: 'Rail',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'partb',
                                            fieldId: 'transit_type',
                                            type: 'mustBeEmpty',
                                        },
                                    ],
                                },
                                {
                                    failureMessage:
                                        'If Transportation mode is Ship, and Consignment Status is Movement, then Transit Type should be blank',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'partb',
                                            dependencyField: 'trns_mode',
                                            value: 'Ship',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'partb',
                                            fieldId: 'transit_type',
                                            type: 'mustBeEmpty',
                                        },
                                    ],
                                },
                                {
                                    failureMessage:
                                        'If Transportation mode is Air, and Consignment Status is Movement, then Transit Type should be blank',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'partb',
                                            dependencyField: 'trns_mode',
                                            value: 'Air',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'partb',
                                            fieldId: 'transit_type',
                                            type: 'mustBeEmpty',
                                        },
                                    ],
                                },
                                {
                                    failureMessage:
                                        'If Transportation mode is In Transit, and Consignment Status is Transit, then Transit Type is mandatory',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'partb',
                                            dependencyField: 'trns_mode',
                                            value: 'In Transit',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'partb',
                                            fieldId: 'transit_type',
                                            type: 'isRequired',
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                item_details: {
                    fields: [
                        {
                            id: 'qty',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid number',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: numberPattern,
                                        },
                                    ],
                                    ruleType: 'pattern',
                                },
                            ],
                        },
                    ],
                },
                notification_details: {
                    fields: [],
                    labelName: 'Notify Partner',
                    saveBtnName: 'Notify',
                    updateBtnName: 'Notify Again',
                    fieldsToDisableOnOldRecord: ['first_name', 'last_name', 'email', 'type'],
                    editMode: false,
                },
                initiate_mvmoment: {
                    fields: [],
                    labelName: 'Initiate',
                    saveBtnName: 'Initiate',
                    updateBtnName: 'Initiate',
                    editMode: false,
                    disableIfValueExists: ['group_numb'],
                    disableSubmitIfValueExists: ['group_numb'],
                    enableChildEntitiesOnlyIfFieldValueExists: ['group_numb'],
                    fieldsToAutoPopulate: [
                        {
                            id: 'transac_id',
                            by: 'uuid',
                            showAfter: false,
                        },
                        {
                            id: 'from_plac',
                            by: 'copy',
                            entityId: 'general_details',
                            fieldId: 'supplier_loc_nm',
                            showAfter: true,
                        },
                        {
                            id: 'from_stat',
                            by: 'copy',
                            entityId: 'ewb_details',
                            fieldId: 'supplier_state',
                            showAfter: true,
                        },
                        {
                            id: 'to_plac',
                            by: 'copy',
                            entityId: 'general_details',
                            fieldId: 'customer_loc_nm',
                            showAfter: true,
                        },
                        {
                            id: 'to_stat',
                            by: 'copy',
                            entityId: 'ewb_details',
                            fieldId: 'customer_billing_state',
                            showAfter: true,
                        },
                        {
                            id: 'ewbno',
                            by: 'copy',
                            entityId: 'general_details',
                            fieldId: 'ebn_prim',
                            showAfter: false,
                        },
                    ],
                    fieldsToHide: ['init_mvm', 'mul_create_date', 'transac_id', 'ewbno'],
                    fieldsToHideOnNewRecord: ['group_numb'],
                },
                add_mutli_veh: {
                    fields: [],
                    labelName: 'Add Vehicle',
                    saveBtnName: 'Add Vehicle',
                    updateBtnName: 'Update Vehicle',
                    editMode: false,
                    fieldsToDisableOnCondtion: [
                        {
                            id: 'tot_qty',
                            requiredField: 'veh_create_date',
                            condition: 'valueExists',
                        },
                        {
                            id: 'vehicle_numb',
                            requiredField: 'veh_create_date',
                            condition: 'valueExists',
                        },
                        {
                            id: 'transport_doc_number',
                            requiredField: 'veh_create_date',
                            condition: 'valueExists',
                        },
                    ],
                    fieldsToAutoPopulate: [
                        {
                            id: 'line_item_id',
                            by: 'uuid',
                            showAfter: false,
                        },
                        {
                            id: 'transaction_id',
                            by: 'copy',
                            entityId: 'initiate_mvmoment',
                            record: 'parent',
                            fieldId: 'transac_id',
                            showAfter: false,
                        },
                        {
                            id: 'from_places',
                            by: 'copy',
                            entityId: 'initiate_mvmoment',
                            record: 'parent',
                            fieldId: 'from_plac',
                            showAfter: false,
                        },
                        {
                            id: 'from_states',
                            by: 'copy',
                            entityId: 'initiate_mvmoment',
                            record: 'parent',
                            fieldId: 'from_stat',
                            showAfter: false,
                        },
                        {
                            id: 'to_place',
                            by: 'copy',
                            entityId: 'initiate_mvmoment',
                            record: 'parent',
                            fieldId: 'to_plac',
                            showAfter: false,
                        },
                        {
                            id: 'to_state',
                            by: 'copy',
                            entityId: 'initiate_mvmoment',
                            record: 'parent',
                            fieldId: 'to_stat',
                            showAfter: false,
                        },
                        {
                            id: 'ewb_numb',
                            by: 'copy',
                            entityId: 'general_details',
                            fieldId: 'ebn_prim',
                            showAfter: false,
                        },
                        {
                            id: 'group_num',
                            by: 'copy',
                            entityId: 'initiate_mvmoment',
                            record: 'parent',
                            fieldId: 'group_numb',
                            showAfter: false,
                        },
                        {
                            id: 'unit_cd',
                            by: 'copy',
                            entityId: 'initiate_mvmoment',
                            record: 'parent',
                            fieldId: 'code_unit',
                            showAfter: false,
                        },
                        {
                            id: 'transport',
                            by: 'copy',
                            entityId: 'initiate_mvmoment',
                            record: 'parent',
                            fieldId: 'trans_mode',
                            showAfter: false,
                        },
                        {
                            id: 'add_update',
                            by: 'value',
                            value: 'Yes',
                            showAfter: false,
                        },
                    ],
                    fieldsToAutoPopulateOnOldRecord: [
                        {
                            id: 'old_vehicle_no',
                            by: 'copy',
                            entityId: 'add_mutli_veh',
                            record: 'currentRecord',
                            fieldId: 'vehicle_numb',
                            showAfter: true,
                        },
                        {
                            id: 'old_tran_no',
                            by: 'copy',
                            entityId: 'add_mutli_veh',
                            record: 'currentRecord',
                            fieldId: 'transport_doc_number',
                            showAfter: true,
                        },
                        {
                            id: 'u_from_state',
                            by: 'copy',
                            entityId: 'add_mutli_veh',
                            record: 'currentRecord',
                            fieldId: 'from_states',
                            showAfter: true,
                        },
                        {
                            id: 'u_reason_code',
                            by: 'copy',
                            entityId: 'initiate_mvmoment',
                            record: 'parent',
                            fieldId: 'reasoncode',
                            showAfter: true,
                        },
                        {
                            id: 'reason_remark',
                            by: 'copy',
                            entityId: 'initiate_mvmoment',
                            record: 'parent',
                            fieldId: 'remark',
                            showAfter: true,
                        },
                        {
                            id: 'add_update',
                            by: 'value',
                            value: 'Yes',
                            showAfter: false,
                        },
                    ],
                    fieldsToHide: [
                        'line_item_id',
                        'unit_cd',
                        'transport',
                        'veh_create_date',
                        'up_create_date',
                        'transaction_id',
                        'from_places',
                        'from_states',
                        'to_place',
                        'to_state',
                        'ewb_numb',
                        'add_update',
                        'group_num',
                    ],
                    fieldsToHideOnNewRecord: [
                        'new_vehicle_no',
                        'old_vehicle_no',
                        'new_trans_no',
                        'old_tran_no',
                        'u_from_place',
                        'u_from_state',
                        'u_reason_code',
                        'reason_remark',
                    ],
                    fieldsToDisableOnOldRecord: [
                        'old_vehicle_no',
                        'old_tran_no',
                        'u_from_place',
                        'u_from_state',
                        'u_reason_code',
                        'reason_remark',
                    ],
                    mandateFieldsOnOldRecord: ['new_vehicle_no'],
                },
            },
            helperAssets: {
                'Download Report': {
                    assetName: environment?.eninvoiceAppsConfig?.['ewaybillreport']?.name || 'E-waybill-ReportV1',
                    restAPIName: environment?.eninvoiceAppsConfig?.['ewaybillreport']?.restAPIName || 'ewaybillreportv1',
                    entities: {
                        general_details: {
                            id: 'general_details',
                            allFields: false,
                            fieldsToShow: ['from_date', 'to_date'],
                            fieldsToAutoPopulate: [
                                {
                                    id: 'uuid',
                                    by: 'uuid',
                                    showAfter: false,
                                },
                            ],
                        },
                    },
                },
                Consolidate: {
                    assetName: environment?.eninvoiceAppsConfig?.['consolidatedEwb']?.name || 'Consolidated e-WayBill v6',
                    restAPIName: environment?.eninvoiceAppsConfig?.['consolidatedEwb']?.name || 'consolidatedewaybillv6',
                    entities: {},
                },
            },
        },
        {
            assetName: environment?.eninvoiceAppsConfig?.['inwardEwb']?.name || 'Consignee E-Waybill-V12A',
            restAPIName: environment?.eninvoiceAppsConfig?.['inwardEwb']?.name || 'consigneeewaybillv12a',
            displayName: 'Inward E-Way Bills',
            show: true,
            mapToPrimaryEntity: {
                ewb_details: {
                    supply_type: 'supply_typee',
                    sub_type: 'supply_sbu_type',
                    doc_type: 'invoice_doc_type',
                    doc_no: 'inv_doc_num',
                    doc_date: 'invoice_doc_date',
                },
                partb: {
                    supp_typ: 'supply_typee',
                    supp_sub_type: 'supply_sbu_type',
                    inv_doc_type: 'invoice_doc_type',
                    invoice_num: 'inv_doc_num',
                    inv_date: 'invoice_doc_date',
                },
                trans_details: {
                    supp_typee: 'supply_typee',
                    supp_sub_typ: 'supply_sbu_type',
                    inv_doc_typ: 'invoice_doc_type',
                    inv_doc_number: 'inv_doc_num',
                    inv_doc_date: 'invoice_doc_date',
                },
                initiate_mvmoment: {
                    sup_typ: 'supply_typee',
                    sub_ty: 'supply_sbu_type',
                    document_typ: 'invoice_doc_type',
                    doc_number: 'inv_doc_num',
                    document_dt: 'invoice_doc_date',
                },
                add_mutli_veh: {
                    suppl_typ: 'supply_typee',
                    sub_tpe: 'supply_sbu_type',
                    docm_typ: 'invoice_doc_type',
                    docum_no: 'inv_doc_num',
                    docum_dt: 'invoice_doc_date',
                },
            },
            entities: {
                general_details: {
                    maxNumberOfRecords: 1,
                    templates: {
                        feed: {
                            template: 'eway-feed',
                        },
                        detail: {
                            header: {
                                template: 'ewb-detailheader',
                            },
                        },
                    },
                    fields: [
                        {
                            id: 'remarks',
                            rules: [
                                {
                                    failureMessage: 'If Update reason is "Others", then remarks is mandatory',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'general_details',
                                            dependencyField: 'reason_update',
                                            value: '3',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'general_details',
                                            fieldId: 'remarks',
                                            type: 'isRequired',
                                        },
                                    ],
                                },
                                {
                                    failureMessage: 'If Cancel reason is "Others", then remarks is mandatory',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'general_details',
                                            dependencyField: 'reason_cancel',
                                            value: '4',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'general_details',
                                            fieldId: 'remarks',
                                            type: 'isRequired',
                                        },
                                    ],
                                },
                                {
                                    failureMessage: 'If Reject reason is "Others", then remarks is mandatory',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'general_details',
                                            dependencyField: 'reason_rejection',
                                            value: 'Other',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'general_details',
                                            fieldId: 'remarks',
                                            type: 'isRequired',
                                        },
                                    ],
                                },
                                {
                                    failureMessage: 'If Extension reason is "Others", then remarks is mandatory',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'general_details',
                                            dependencyField: 'ext_reason_code',
                                            value: '99',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'general_details',
                                            fieldId: 'remarks',
                                            type: 'isRequired',
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                ewb_details: {
                    maxNumberOfRecords: 1,
                    fields: [
                        {
                            id: 'supp_pers_tel',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid phone number',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: phoneNumberPattern,
                                        },
                                    ],
                                    ruleType: 'pattern',
                                },
                            ],
                        },
                        {
                            id: 'supplier_email',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid email Id',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: emailPattern,
                                        },
                                    ],
                                    ruleType: 'pattern',
                                },
                            ],
                        },
                        {
                            id: 'supplier_gstn',
                            rules: [
                                {
                                    failureMessage: 'GSTIN should not be empty if Registration status is "Registered Dealer"',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'notEqual',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'supplier_regis',
                                            value: 'Unregistered Dealer',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'ewb_details',
                                            fieldId: 'supplier_gstn',
                                            type: 'isRequired',
                                        },
                                    ],
                                },
                                {
                                    failureMessage: 'Enter a valid GSTIN number',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'notEqual',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'supplier_regis',
                                            value: 'Unregistered Dealer',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'ewb_details',
                                            fieldId: 'supplier_gstn',
                                            type: 'pattern',
                                            pattern: GSTINPattern,
                                        },
                                    ],
                                },
                                {
                                    failureMessage: 'GSTIN should be URP if Registration status is "Unregistered Dealer"',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'supplier_regis',
                                            value: 'Unregistered Dealer',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'ewb_details',
                                            fieldId: 'supplier_gstn',
                                            type: 'isRequired',
                                            value: 'URP',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'supplier_pincode',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid Pin Code',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleType: 'pattern',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: numberPattern,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'supplier_shipping_telephone',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid phone number',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleType: 'pattern',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: phoneNumberPattern,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'supplier_shipping_email',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid email Id',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: emailPattern,
                                        },
                                    ],
                                    ruleType: 'pattern',
                                },
                            ],
                        },
                        {
                            id: 'supplier_shipping_gstn',
                            rules: [
                                /* {
                    failureMessage: 'GSTIN should not be empty if Registration status is "Registered Dealer"',
                    ruleType: 'allAreRequired',
                    ruleCategory: 'CONDITIONAL',
                    ruleParams: [{
                        paramType: 'VALUE',
                        name: 'notEqual',
                        dependencyEntity: 'ewb_details',
                        dependencyField: 'supplier_shipping_registration',
                        value: 'Unregistered Dealer'
                    }],
                    mandatoryFields: [{
                        entityId: 'ewb_details',
                        fieldId: 'supplier_shipping_gstn',
                        type: 'isRequired'
                    }],
                }, */ {
                                    failureMessage: 'Enter a valid GSTIN number',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'notEqual',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'supplier_shipping_registration',
                                            value: 'Unregistered Dealer',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'ewb_details',
                                            fieldId: 'supplier_shipping_gstn',
                                            type: 'pattern',
                                            pattern: GSTINPattern,
                                        },
                                    ],
                                },
                                {
                                    failureMessage: 'GSTIN should be URP if Registration status is "Unregistered Dealer"',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'supplier_shipping_registration',
                                            value: 'Unregistered Dealer',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'ewb_details',
                                            fieldId: 'supplier_shipping_gstn',
                                            type: 'isRequired',
                                            value: 'URP',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'supplier_shipping_pincode',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid Pin Code',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleType: 'pattern',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: numberPattern,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'customer_phone',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid phone number',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleType: 'pattern',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: phoneNumberPattern,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'customer_billing_email',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid email Id',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: emailPattern,
                                        },
                                    ],
                                    ruleType: 'pattern',
                                },
                            ],
                        },
                        {
                            id: 'customer_billing_gstn',
                            rules: [
                                {
                                    failureMessage: 'GSTIN should not be empty if Registration status is "Registered Dealer"',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'notEqual',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'customer_registration',
                                            value: 'Unregistered Dealer',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'ewb_details',
                                            fieldId: 'customer_billing_gstn',
                                            type: 'isRequired',
                                        },
                                    ],
                                },
                                {
                                    failureMessage: 'Enter a valid GSTIN number',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'notEqual',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'customer_registration',
                                            value: 'Unregistered Dealer',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'ewb_details',
                                            fieldId: 'customer_billing_gstn',
                                            type: 'pattern',
                                            pattern: GSTINPattern,
                                        },
                                    ],
                                },
                                {
                                    failureMessage: 'GSTIN should be URP if Registration status is "Unregistered Dealer"',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'customer_registration',
                                            value: 'Unregistered Dealer',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'ewb_details',
                                            fieldId: 'customer_billing_gstn',
                                            type: 'isRequired',
                                            value: 'URP',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'customer_billing_pincode',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid Pin Code',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleType: 'pattern',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: numberPattern,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'cust_shipping_ctnumber',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid phone number',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleType: 'pattern',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: phoneNumberPattern,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'customer_email',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid email Id',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: emailPattern,
                                        },
                                    ],
                                    ruleType: 'pattern',
                                },
                            ],
                        },
                        {
                            id: 'customer_gstn',
                            rules: [
                                /* {
                    failureMessage: 'GSTIN should not be empty if Registration status is "Registered Dealer"',
                    ruleType: 'allAreRequired',
                    ruleCategory: 'CONDITIONAL',
                    ruleParams: [{
                        paramType: 'VALUE',
                        name: 'notEqual',
                        dependencyEntity: 'ewb_details',
                        dependencyField: 'cust_shipping_registration',
                        value: 'Unregistered Dealer'
                    }],
                    mandatoryFields: [{
                        entityId: 'ewb_details',
                        fieldId: 'customer_gstn',
                        type: 'isRequired'
                    }],
                }, */ {
                                    failureMessage: 'Enter a valid GSTIN number',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'notEqual',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'cust_shipping_registration',
                                            value: 'Unregistered Dealer',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'ewb_details',
                                            fieldId: 'customer_gstn',
                                            type: 'pattern',
                                            pattern: GSTINPattern,
                                        },
                                    ],
                                },
                                {
                                    failureMessage: 'GSTIN should be URP if Registration status is "Unregistered Dealer"',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'cust_shipping_registration',
                                            value: 'Unregistered Dealer',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'ewb_details',
                                            fieldId: 'customer_gstn',
                                            type: 'isRequired',
                                            value: 'URP',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'customer_pincode',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid Pin Code',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleType: 'pattern',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: numberPattern,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'distance_lev',
                            rules: [
                                {
                                    failureMessage: 'Enter distance in numbers only upto 4000',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleType: 'pattern',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: distancePattern,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'freight',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid value',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleType: 'pattern',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: numberPattern,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'pacaging_forwarding',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid value',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleType: 'pattern',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: numberPattern,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'insurance',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid value',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleType: 'pattern',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: numberPattern,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'reason_cn_dn',
                            rules: [
                                {
                                    failureMessage:
                                        'Reason for issuing of Credit/Debit Note is mandatory if document type is "Credit/Debit note"',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'doc_type',
                                            value: 'Credit Note',
                                        },
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'doc_type',
                                            value: 'Debit Note',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'ewb_details',
                                            fieldId: 'reason_cn_dn',
                                            type: 'isRequired',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'sub_supply_desc',
                            rules: [
                                {
                                    failureMessage: 'Reason is mandatory if supply subtype is Others',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'sub_type',
                                            value: 'Others',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'ewb_details',
                                            fieldId: 'sub_supply_desc',
                                            type: 'isRequired',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'original_invoice',
                            rules: [
                                {
                                    failureMessage: 'Original Invoice Number is mandatory if document type is Credit/Debit note"',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'doc_type',
                                            value: 'Credit Note',
                                        },
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'doc_type',
                                            value: 'Debit Note',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'ewb_details',
                                            fieldId: 'original_invoice',
                                            type: 'isRequired',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'original_date',
                            rules: [
                                {
                                    failureMessage: 'Original Invoice Date is mandatory if document type is Credit/Debit note"',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'doc_type',
                                            value: 'Credit Note',
                                        },
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'doc_type',
                                            value: 'Debit Note',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'ewb_details',
                                            fieldId: 'original_date',
                                            type: 'isRequired',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'original_invoice_value',
                            rules: [
                                {
                                    failureMessage: 'Original Invoice Date is mandatory if document type is Credit/Debit note"',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'doc_type',
                                            value: 'Credit Note',
                                        },
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'doc_type',
                                            value: 'Debit Note',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'ewb_details',
                                            fieldId: 'original_invoice_value',
                                            type: 'isRequired',
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                partb: {
                    maxNumberOfRecords: 1,
                    fields: [
                        {
                            id: 'trans_dcno',
                            rules: [
                                {
                                    failureMessage:
                                        'If Transportation mode is other than Road then Transportation Document Number is required',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'notEqual',
                                            dependencyEntity: 'partb',
                                            dependencyField: 'trns_mode',
                                            value: 'Road',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'partb',
                                            fieldId: 'trans_dcno',
                                            type: 'isRequired',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'trans_date',
                            rules: [
                                {
                                    failureMessage: 'If Transportation mode is other than Road then Transporter Document Date is required',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'notEqual',
                                            dependencyEntity: 'partb',
                                            dependencyField: 'trns_mode',
                                            value: 'Road',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'partb',
                                            fieldId: 'trans_date',
                                            type: 'isRequired',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'vehicle_number',
                            rules: [
                                {
                                    failureMessage: 'If Transportation mode is "Road" then Vehicle Number is required',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'partb',
                                            dependencyField: 'trns_mode',
                                            value: 'Road',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'partb',
                                            fieldId: 'vehicle_number',
                                            type: 'isRequired',
                                        },
                                    ],
                                },
                                {
                                    failureMessage: 'If Transportation mode is not "Road" then Vehicle Number must be empty',
                                    ruleType: 'noneRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'notEqual',
                                            dependencyEntity: 'partb',
                                            dependencyField: 'trns_mode',
                                            value: 'Road',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'partb',
                                            fieldId: 'vehicle_number',
                                            type: 'mustBeEmpty',
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                item_details: {
                    fields: [
                        {
                            id: 'qty',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid number',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: numberPattern,
                                        },
                                    ],
                                    ruleType: 'pattern',
                                },
                            ],
                        },
                    ],
                },
                notification_details: {
                    fields: [],
                    labelName: 'Notify Partner',
                    saveBtnName: 'Notify',
                    updateBtnName: 'Notify Again',
                    editMode: false,
                    fieldsToDisableOnOldRecord: ['first_name', 'last_name', 'email', 'type'],
                },
                initiate_mvmoment: {
                    fields: [],
                    labelName: 'Initiate',
                    saveBtnName: 'Initiate',
                    updateBtnName: 'Initiate',
                    editMode: false,
                    disableIfValueExists: ['group_numb'],
                    disableSubmitIfValueExists: ['group_numb'],
                    enableChildEntitiesOnlyIfFieldValueExists: ['group_numb'],
                    fieldsToAutoPopulate: [
                        {
                            id: 'transac_id',
                            by: 'uuid',
                            showAfter: false,
                        },
                        {
                            id: 'from_plac',
                            by: 'copy',
                            entityId: 'general_details',
                            fieldId: 'supplier_loc_nm',
                            showAfter: true,
                        },
                        {
                            id: 'from_stat',
                            by: 'copy',
                            entityId: 'ewb_details',
                            fieldId: 'supplier_state',
                            showAfter: true,
                        },
                        {
                            id: 'to_plac',
                            by: 'copy',
                            entityId: 'general_details',
                            fieldId: 'customer_loc_nm',
                            showAfter: true,
                        },
                        {
                            id: 'to_stat',
                            by: 'copy',
                            entityId: 'ewb_details',
                            fieldId: 'customer_billing_state',
                            showAfter: true,
                        },
                        {
                            id: 'ewbno',
                            by: 'copy',
                            entityId: 'general_details',
                            fieldId: 'ebn_prim',
                            showAfter: false,
                        },
                    ],
                    fieldsToHide: ['init_mvm', 'mul_create_date', 'transac_id', 'ewbno'],
                    fieldsToHideOnNewRecord: ['group_numb'],
                },
                add_mutli_veh: {
                    fields: [],
                    labelName: 'Add Vehicle',
                    saveBtnName: 'Add Vehicle',
                    updateBtnName: 'Update Vehicle',
                    editMode: false,
                    fieldsToDisableOnCondtion: [
                        {
                            id: 'tot_qty',
                            requiredField: 'veh_create_date',
                            condition: 'valueExists',
                        },
                        {
                            id: 'vehicle_numb',
                            requiredField: 'veh_create_date',
                            condition: 'valueExists',
                        },
                        {
                            id: 'transport_doc_number',
                            requiredField: 'veh_create_date',
                            condition: 'valueExists',
                        },
                    ],
                    fieldsToAutoPopulate: [
                        {
                            id: 'line_item_id',
                            by: 'uuid',
                            showAfter: false,
                        },
                        {
                            id: 'transaction_id',
                            by: 'copy',
                            entityId: 'initiate_mvmoment',
                            record: 'parent',
                            fieldId: 'transac_id',
                            showAfter: false,
                        },
                        {
                            id: 'from_places',
                            by: 'copy',
                            entityId: 'initiate_mvmoment',
                            record: 'parent',
                            fieldId: 'from_plac',
                            showAfter: false,
                        },
                        {
                            id: 'from_states',
                            by: 'copy',
                            entityId: 'initiate_mvmoment',
                            record: 'parent',
                            fieldId: 'from_stat',
                            showAfter: false,
                        },
                        {
                            id: 'to_place',
                            by: 'copy',
                            entityId: 'initiate_mvmoment',
                            record: 'parent',
                            fieldId: 'to_plac',
                            showAfter: false,
                        },
                        {
                            id: 'to_state',
                            by: 'copy',
                            entityId: 'initiate_mvmoment',
                            record: 'parent',
                            fieldId: 'to_stat',
                            showAfter: false,
                        },
                        {
                            id: 'ewb_numb',
                            by: 'copy',
                            entityId: 'general_details',
                            fieldId: 'ebn_prim',
                            showAfter: false,
                        },
                        {
                            id: 'group_num',
                            by: 'copy',
                            entityId: 'initiate_mvmoment',
                            record: 'parent',
                            fieldId: 'group_numb',
                            showAfter: false,
                        },
                        {
                            id: 'unit_cd',
                            by: 'copy',
                            entityId: 'initiate_mvmoment',
                            record: 'parent',
                            fieldId: 'code_unit',
                            showAfter: false,
                        },
                        {
                            id: 'transport',
                            by: 'copy',
                            entityId: 'initiate_mvmoment',
                            record: 'parent',
                            fieldId: 'trans_mode',
                            showAfter: false,
                        },
                        {
                            id: 'add_update',
                            by: 'value',
                            value: 'Yes',
                            showAfter: false,
                        },
                    ],
                    fieldsToAutoPopulateOnOldRecord: [
                        {
                            id: 'old_vehicle_no',
                            by: 'copy',
                            entityId: 'add_mutli_veh',
                            record: 'currentRecord',
                            fieldId: 'vehicle_numb',
                            showAfter: true,
                        },
                        {
                            id: 'old_tran_no',
                            by: 'copy',
                            entityId: 'add_mutli_veh',
                            record: 'currentRecord',
                            fieldId: 'transport_doc_number',
                            showAfter: true,
                        },
                        {
                            id: 'u_from_state',
                            by: 'copy',
                            entityId: 'add_mutli_veh',
                            record: 'currentRecord',
                            fieldId: 'from_states',
                            showAfter: true,
                        },
                        {
                            id: 'u_reason_code',
                            by: 'copy',
                            entityId: 'initiate_mvmoment',
                            record: 'parent',
                            fieldId: 'reasoncode',
                            showAfter: true,
                        },
                        {
                            id: 'reason_remark',
                            by: 'copy',
                            entityId: 'initiate_mvmoment',
                            record: 'parent',
                            fieldId: 'remark',
                            showAfter: true,
                        },
                        {
                            id: 'add_update',
                            by: 'value',
                            value: 'Yes',
                            showAfter: false,
                        },
                    ],
                    fieldsToHide: [
                        'line_item_id',
                        'unit_cd',
                        'transport',
                        'veh_create_date',
                        'up_create_date',
                        'transaction_id',
                        'from_places',
                        'from_states',
                        'to_place',
                        'to_state',
                        'ewb_numb',
                        'add_update',
                        'group_num',
                    ],
                    fieldsToHideOnNewRecord: [
                        'new_vehicle_no',
                        'old_vehicle_no',
                        'new_trans_no',
                        'old_tran_no',
                        'u_from_place',
                        'u_from_state',
                        'u_reason_code',
                        'reason_remark',
                    ],
                    fieldsToDisableOnOldRecord: [
                        'old_vehicle_no',
                        'old_tran_no',
                        'u_from_place',
                        'u_from_state',
                        'u_reason_code',
                        'reason_remark',
                    ],
                    mandateFieldsOnOldRecord: ['new_vehicle_no'],
                },
            },
            helperAssets: {
                'Download Report': {
                    assetName: environment?.eninvoiceAppsConfig?.['ewaybillreport']?.name || 'E-waybill-ReportV1',
                    restAPIName: environment?.eninvoiceAppsConfig?.['ewaybillreport']?.restAPIName || 'ewaybillreportv1',
                    entities: {
                        general_details: {
                            id: 'general_details',
                            allFields: false,
                            fieldsToShow: ['from_date', 'to_date'],
                            fieldsToAutoPopulate: [
                                {
                                    id: 'uuid',
                                    by: 'uuid',
                                    showAfter: false,
                                },
                            ],
                        },
                    },
                },
                Consolidate: {
                    assetName: environment?.eninvoiceAppsConfig?.['consolidatedEwb']?.name || 'Consolidated e-WayBill v6',
                    restAPIName: environment?.eninvoiceAppsConfig?.['consolidatedEwb']?.name || 'consolidatedewaybillv6',
                    entities: {},
                },
            },
        },
        {
            assetName: 'E-Waybill-V14A',
            restAPIName: 'ewaybillv14A',
            displayName: 'Outward E-Way Bills 14A',
            show: true,
            entities: {
                general_details: {
                    maxNumberOfRecords: 1,
                    templates: {
                        feed: {
                            template: 'eway-feed',
                        },
                        detail: {
                            header: {
                                template: 'ewb-detailheader',
                            },
                        },
                    },
                    fields: [],
                },
            },
        },
        {
            assetName: environment?.eninvoiceAppsConfig?.['transporterEwb']?.name || 'Transporter E-Waybill-V12A',
            restAPIName: environment?.eninvoiceAppsConfig?.['transporterEwb']?.name || 'transporterewaybillv12a',
            displayName: 'Transporter E-Way Bills',
            show: true,
            mapToPrimaryEntity: {
                ewb_details: {
                    supply_type: 'supply_typee',
                    sub_type: 'supply_sbu_type',
                    doc_type: 'invoice_doc_type',
                    doc_no: 'inv_doc_num',
                    doc_date: 'invoice_doc_date',
                },
                partb: {
                    supp_typ: 'supply_typee',
                    supp_sub_type: 'supply_sbu_type',
                    inv_doc_type: 'invoice_doc_type',
                    invoice_num: 'inv_doc_num',
                    inv_date: 'invoice_doc_date',
                },
                trans_details: {
                    supp_typee: 'supply_typee',
                    supp_sub_typ: 'supply_sbu_type',
                    inv_doc_typ: 'invoice_doc_type',
                    inv_doc_number: 'inv_doc_num',
                    inv_doc_date: 'invoice_doc_date',
                },
                initiate_mvmoment: {
                    sup_typ: 'supply_typee',
                    sub_ty: 'supply_sbu_type',
                    document_typ: 'invoice_doc_type',
                    doc_number: 'inv_doc_num',
                    document_dt: 'invoice_doc_date',
                },
                add_mutli_veh: {
                    suppl_typ: 'supply_typee',
                    sub_tpe: 'supply_sbu_type',
                    docm_typ: 'invoice_doc_type',
                    docum_no: 'inv_doc_num',
                    docum_dt: 'invoice_doc_date',
                },
            },
            customFilters: {
                enabled: true,
                searchEntity: 'general_details',
                searchField: 'e_waybill_status',
                static: false,
            },
            entities: {
                general_details: {
                    maxNumberOfRecords: 1,
                    templates: {
                        feed: {
                            template: 'eway-feed',
                        },
                        detail: {
                            header: {
                                template: 'ewb-detailheader',
                            },
                        },
                    },
                    fields: [
                        {
                            id: 'remarks',
                            rules: [
                                {
                                    failureMessage: 'If Update reason is "Others", then remarks is mandatory',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'general_details',
                                            dependencyField: 'reason_update',
                                            value: '3',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'general_details',
                                            fieldId: 'remarks',
                                            type: 'isRequired',
                                        },
                                    ],
                                },
                                {
                                    failureMessage: 'If Cancel reason is "Others", then remarks is mandatory',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'general_details',
                                            dependencyField: 'reason_cancel',
                                            value: '4',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'general_details',
                                            fieldId: 'remarks',
                                            type: 'isRequired',
                                        },
                                    ],
                                },
                                {
                                    failureMessage: 'If Reject reason is "Others", then remarks is mandatory',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'general_details',
                                            dependencyField: 'reason_rejection',
                                            value: 'Other',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'general_details',
                                            fieldId: 'remarks',
                                            type: 'isRequired',
                                        },
                                    ],
                                },
                                {
                                    failureMessage: 'If Extension reason is "Others", then remarks is mandatory',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'general_details',
                                            dependencyField: 'ext_reason_code',
                                            value: '99',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'general_details',
                                            fieldId: 'remarks',
                                            type: 'isRequired',
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                ewb_details: {
                    maxNumberOfRecords: 1,
                    fields: [
                        {
                            id: 'supp_pers_tel',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid phone number',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: phoneNumberPattern,
                                        },
                                    ],
                                    ruleType: 'pattern',
                                },
                            ],
                        },
                        {
                            id: 'supplier_email',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid email Id',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: emailPattern,
                                        },
                                    ],
                                    ruleType: 'pattern',
                                },
                            ],
                        },
                        {
                            id: 'supplier_gstn',
                            rules: [
                                {
                                    failureMessage: 'GSTIN should not be empty if Registration status is "Registered Dealer"',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'notEqual',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'supplier_regis',
                                            value: 'Unregistered Dealer',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'ewb_details',
                                            fieldId: 'supplier_gstn',
                                            type: 'isRequired',
                                        },
                                    ],
                                },
                                {
                                    failureMessage: 'Enter a valid GSTIN number',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'notEqual',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'supplier_regis',
                                            value: 'Unregistered Dealer',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'ewb_details',
                                            fieldId: 'supplier_gstn',
                                            type: 'pattern',
                                            pattern: GSTINPattern,
                                        },
                                    ],
                                },
                                {
                                    failureMessage: 'GSTIN should be URP if Registration status is "Unregistered Dealer"',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'supplier_regis',
                                            value: 'Unregistered Dealer',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'ewb_details',
                                            fieldId: 'supplier_gstn',
                                            type: 'isRequired',
                                            value: 'URP',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'supplier_pincode',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid Pin Code',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleType: 'pattern',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: numberPattern,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'supplier_shipping_telephone',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid phone number',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleType: 'pattern',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: phoneNumberPattern,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'supplier_shipping_email',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid email Id',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: emailPattern,
                                        },
                                    ],
                                    ruleType: 'pattern',
                                },
                            ],
                        },
                        {
                            id: 'supplier_shipping_gstn',
                            rules: [
                                /* {
                    failureMessage: 'GSTIN should not be empty if Registration status is "Registered Dealer"',
                    ruleType: 'allAreRequired',
                    ruleCategory: 'CONDITIONAL',
                    ruleParams: [{
                        paramType: 'VALUE',
                        name: 'notEqual',
                        dependencyEntity: 'ewb_details',
                        dependencyField: 'supplier_shipping_registration',
                        value: 'Unregistered Dealer'
                    }],
                    mandatoryFields: [{
                        entityId: 'ewb_details',
                        fieldId: 'supplier_shipping_gstn',
                        type: 'isRequired'
                    }],
                }, */ {
                                    failureMessage: 'Enter a valid GSTIN number',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'notEqual',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'supplier_shipping_registration',
                                            value: 'Unregistered Dealer',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'ewb_details',
                                            fieldId: 'supplier_shipping_gstn',
                                            type: 'pattern',
                                            pattern: GSTINPattern,
                                        },
                                    ],
                                },
                                {
                                    failureMessage: 'GSTIN should be URP if Registration status is "Unregistered Dealer"',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'supplier_shipping_registration',
                                            value: 'Unregistered Dealer',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'ewb_details',
                                            fieldId: 'supplier_shipping_gstn',
                                            type: 'isRequired',
                                            value: 'URP',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'supplier_shipping_pincode',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid Pin Code',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleType: 'pattern',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: numberPattern,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'customer_phone',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid phone number',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleType: 'pattern',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: phoneNumberPattern,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'customer_billing_email',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid email Id',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: emailPattern,
                                        },
                                    ],
                                    ruleType: 'pattern',
                                },
                            ],
                        },
                        {
                            id: 'customer_billing_gstn',
                            rules: [
                                {
                                    failureMessage: 'GSTIN should not be empty if Registration status is "Registered Dealer"',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'notEqual',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'customer_registration',
                                            value: 'Unregistered Dealer',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'ewb_details',
                                            fieldId: 'customer_billing_gstn',
                                            type: 'isRequired',
                                        },
                                    ],
                                },
                                {
                                    failureMessage: 'Enter a valid GSTIN number',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'notEqual',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'customer_registration',
                                            value: 'Unregistered Dealer',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'ewb_details',
                                            fieldId: 'customer_billing_gstn',
                                            type: 'pattern',
                                            pattern: GSTINPattern,
                                        },
                                    ],
                                },
                                {
                                    failureMessage: 'GSTIN should be URP if Registration status is "Unregistered Dealer"',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'customer_registration',
                                            value: 'Unregistered Dealer',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'ewb_details',
                                            fieldId: 'customer_billing_gstn',
                                            type: 'isRequired',
                                            value: 'URP',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'customer_billing_pincode',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid Pin Code',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleType: 'pattern',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: numberPattern,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'cust_shipping_ctnumber',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid phone number',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleType: 'pattern',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: phoneNumberPattern,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'customer_email',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid email Id',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: emailPattern,
                                        },
                                    ],
                                    ruleType: 'pattern',
                                },
                            ],
                        },
                        {
                            id: 'customer_gstn',
                            rules: [
                                /* {
                    failureMessage: 'GSTIN should not be empty if Registration status is "Registered Dealer"',
                    ruleType: 'allAreRequired',
                    ruleCategory: 'CONDITIONAL',
                    ruleParams: [{
                        paramType: 'VALUE',
                        name: 'notEqual',
                        dependencyEntity: 'ewb_details',
                        dependencyField: 'cust_shipping_registration',
                        value: 'Unregistered Dealer'
                    }],
                    mandatoryFields: [{
                        entityId: 'ewb_details',
                        fieldId: 'customer_gstn',
                        type: 'isRequired'
                    }],
                }, */ {
                                    failureMessage: 'Enter a valid GSTIN number',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'notEqual',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'cust_shipping_registration',
                                            value: 'Unregistered Dealer',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'ewb_details',
                                            fieldId: 'customer_gstn',
                                            type: 'pattern',
                                            pattern: GSTINPattern,
                                        },
                                    ],
                                },
                                {
                                    failureMessage: 'GSTIN should be URP if Registration status is "Unregistered Dealer"',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'cust_shipping_registration',
                                            value: 'Unregistered Dealer',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'ewb_details',
                                            fieldId: 'customer_gstn',
                                            type: 'isRequired',
                                            value: 'URP',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'customer_pincode',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid Pin Code',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleType: 'pattern',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: numberPattern,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'distance_lev',
                            rules: [
                                {
                                    failureMessage: 'Enter distance in numbers only upto 4000',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleType: 'pattern',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: distancePattern,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'freight',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid value',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleType: 'pattern',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: numberPattern,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'pacaging_forwarding',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid value',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleType: 'pattern',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: numberPattern,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'insurance',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid value',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleType: 'pattern',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: numberPattern,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'reason_cn_dn',
                            rules: [
                                {
                                    failureMessage:
                                        'Reason for issuing of Credit/Debit Note is mandatory if document type is "Credit/Debit note"',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'doc_type',
                                            value: 'Credit Note',
                                        },
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'doc_type',
                                            value: 'Debit Note',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'ewb_details',
                                            fieldId: 'reason_cn_dn',
                                            type: 'isRequired',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'sub_supply_desc',
                            rules: [
                                {
                                    failureMessage: 'Reason is mandatory if supply subtype is Others',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'sub_type',
                                            value: 'Others',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'ewb_details',
                                            fieldId: 'sub_supply_desc',
                                            type: 'isRequired',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'original_invoice',
                            rules: [
                                {
                                    failureMessage: 'Original Invoice Number is mandatory if document type is Credit/Debit note"',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'doc_type',
                                            value: 'Credit Note',
                                        },
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'doc_type',
                                            value: 'Debit Note',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'ewb_details',
                                            fieldId: 'original_invoice',
                                            type: 'isRequired',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'original_date',
                            rules: [
                                {
                                    failureMessage: 'Original Invoice Date is mandatory if document type is Credit/Debit note"',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'doc_type',
                                            value: 'Credit Note',
                                        },
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'doc_type',
                                            value: 'Debit Note',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'ewb_details',
                                            fieldId: 'original_date',
                                            type: 'isRequired',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'original_invoice_value',
                            rules: [
                                {
                                    failureMessage: 'Original Invoice Date is mandatory if document type is Credit/Debit note"',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'doc_type',
                                            value: 'Credit Note',
                                        },
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'ewb_details',
                                            dependencyField: 'doc_type',
                                            value: 'Debit Note',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'ewb_details',
                                            fieldId: 'original_invoice_value',
                                            type: 'isRequired',
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                partb: {
                    maxNumberOfRecords: 1,
                    fields: [
                        {
                            id: 'trans_dcno',
                            rules: [
                                {
                                    failureMessage:
                                        'If Transportation mode is other than Road then Transportation Document Number is required',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'notEqual',
                                            dependencyEntity: 'partb',
                                            dependencyField: 'trns_mode',
                                            value: 'Road',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'partb',
                                            fieldId: 'trans_dcno',
                                            type: 'isRequired',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'trans_date',
                            rules: [
                                {
                                    failureMessage: 'If Transportation mode is other than Road then Transporter Document Date is required',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'notEqual',
                                            dependencyEntity: 'partb',
                                            dependencyField: 'trns_mode',
                                            value: 'Road',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'partb',
                                            fieldId: 'trans_date',
                                            type: 'isRequired',
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 'vehicle_number',
                            rules: [
                                {
                                    failureMessage: 'If Transportation mode is "Road" then Vehicle Number is required',
                                    ruleType: 'allAreRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'equal',
                                            dependencyEntity: 'partb',
                                            dependencyField: 'trns_mode',
                                            value: 'Road',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'partb',
                                            fieldId: 'vehicle_number',
                                            type: 'isRequired',
                                        },
                                    ],
                                },
                                {
                                    failureMessage: 'If Transportation mode is not "Road" then Vehicle Number must be empty',
                                    ruleType: 'noneRequired',
                                    ruleCategory: 'CONDITIONAL',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'notEqual',
                                            dependencyEntity: 'partb',
                                            dependencyField: 'trns_mode',
                                            value: 'Road',
                                        },
                                    ],
                                    mandatoryFields: [
                                        {
                                            entityId: 'partb',
                                            fieldId: 'vehicle_number',
                                            type: 'mustBeEmpty',
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                item_details: {
                    fields: [
                        {
                            id: 'qty',
                            rules: [
                                {
                                    failureMessage: 'Enter a valid number',
                                    ruleCategory: 'VALIDATION_RULE',
                                    ruleParams: [
                                        {
                                            paramType: 'VALUE',
                                            name: 'pattern',
                                            value: numberPattern,
                                        },
                                    ],
                                    ruleType: 'pattern',
                                },
                            ],
                        },
                    ],
                },
                notification_details: {
                    fields: [],
                    labelName: 'Notify Partner',
                    saveBtnName: 'Notify',
                    updateBtnName: 'Notify Again',
                    editMode: false,
                    fieldsToDisableOnOldRecord: ['first_name', 'last_name', 'email', 'type'],
                },
                initiate_mvmoment: {
                    fields: [],
                    labelName: 'Initiate',
                    saveBtnName: 'Initiate',
                    updateBtnName: 'Initiate',
                    editMode: false,
                    disableIfValueExists: ['group_numb'],
                    disableSubmitIfValueExists: ['group_numb'],
                    enableChildEntitiesOnlyIfFieldValueExists: ['group_numb'],
                    fieldsToAutoPopulate: [
                        {
                            id: 'transac_id',
                            by: 'uuid',
                            showAfter: false,
                        },
                        {
                            id: 'from_plac',
                            by: 'copy',
                            entityId: 'general_details',
                            fieldId: 'supplier_loc_nm',
                            showAfter: true,
                        },
                        {
                            id: 'from_stat',
                            by: 'copy',
                            entityId: 'ewb_details',
                            fieldId: 'supplier_state',
                            showAfter: true,
                        },
                        {
                            id: 'to_plac',
                            by: 'copy',
                            entityId: 'general_details',
                            fieldId: 'customer_loc_nm',
                            showAfter: true,
                        },
                        {
                            id: 'to_stat',
                            by: 'copy',
                            entityId: 'ewb_details',
                            fieldId: 'customer_billing_state',
                            showAfter: true,
                        },
                        {
                            id: 'ewbno',
                            by: 'copy',
                            entityId: 'general_details',
                            fieldId: 'ebn_prim',
                            showAfter: false,
                        },
                    ],
                    fieldsToHide: ['init_mvm', 'mul_create_date', 'transac_id', 'ewbno'],
                    fieldsToHideOnNewRecord: ['group_numb'],
                },
                add_mutli_veh: {
                    fields: [],
                    labelName: 'Add Vehicle',
                    saveBtnName: 'Add Vehicle',
                    updateBtnName: 'Update Vehicle',
                    editMode: false,
                    fieldsToDisableOnCondtion: [
                        {
                            id: 'tot_qty',
                            requiredField: 'veh_create_date',
                            condition: 'valueExists',
                        },
                        {
                            id: 'vehicle_numb',
                            requiredField: 'veh_create_date',
                            condition: 'valueExists',
                        },
                        {
                            id: 'transport_doc_number',
                            requiredField: 'veh_create_date',
                            condition: 'valueExists',
                        },
                    ],
                    fieldsToAutoPopulate: [
                        {
                            id: 'line_item_id',
                            by: 'uuid',
                            showAfter: false,
                        },
                        {
                            id: 'transaction_id',
                            by: 'copy',
                            entityId: 'initiate_mvmoment',
                            record: 'parent',
                            fieldId: 'transac_id',
                            showAfter: false,
                        },
                        {
                            id: 'from_places',
                            by: 'copy',
                            entityId: 'initiate_mvmoment',
                            record: 'parent',
                            fieldId: 'from_plac',
                            showAfter: false,
                        },
                        {
                            id: 'from_states',
                            by: 'copy',
                            entityId: 'initiate_mvmoment',
                            record: 'parent',
                            fieldId: 'from_stat',
                            showAfter: false,
                        },
                        {
                            id: 'to_place',
                            by: 'copy',
                            entityId: 'initiate_mvmoment',
                            record: 'parent',
                            fieldId: 'to_plac',
                            showAfter: false,
                        },
                        {
                            id: 'to_state',
                            by: 'copy',
                            entityId: 'initiate_mvmoment',
                            record: 'parent',
                            fieldId: 'to_stat',
                            showAfter: false,
                        },
                        {
                            id: 'ewb_numb',
                            by: 'copy',
                            entityId: 'general_details',
                            fieldId: 'ebn_prim',
                            showAfter: false,
                        },
                        {
                            id: 'group_num',
                            by: 'copy',
                            entityId: 'initiate_mvmoment',
                            record: 'parent',
                            fieldId: 'group_numb',
                            showAfter: false,
                        },
                        {
                            id: 'unit_cd',
                            by: 'copy',
                            entityId: 'initiate_mvmoment',
                            record: 'parent',
                            fieldId: 'code_unit',
                            showAfter: false,
                        },
                        {
                            id: 'transport',
                            by: 'copy',
                            entityId: 'initiate_mvmoment',
                            record: 'parent',
                            fieldId: 'trans_mode',
                            showAfter: false,
                        },
                        {
                            id: 'add_update',
                            by: 'value',
                            value: 'Yes',
                            showAfter: false,
                        },
                    ],
                    fieldsToAutoPopulateOnOldRecord: [
                        {
                            id: 'old_vehicle_no',
                            by: 'copy',
                            entityId: 'add_mutli_veh',
                            record: 'currentRecord',
                            fieldId: 'vehicle_numb',
                            showAfter: true,
                        },
                        {
                            id: 'old_tran_no',
                            by: 'copy',
                            entityId: 'add_mutli_veh',
                            record: 'currentRecord',
                            fieldId: 'transport_doc_number',
                            showAfter: true,
                        },
                        {
                            id: 'u_from_state',
                            by: 'copy',
                            entityId: 'add_mutli_veh',
                            record: 'currentRecord',
                            fieldId: 'from_states',
                            showAfter: true,
                        },
                        {
                            id: 'u_reason_code',
                            by: 'copy',
                            entityId: 'initiate_mvmoment',
                            record: 'parent',
                            fieldId: 'reasoncode',
                            showAfter: true,
                        },
                        {
                            id: 'reason_remark',
                            by: 'copy',
                            entityId: 'initiate_mvmoment',
                            record: 'parent',
                            fieldId: 'remark',
                            showAfter: true,
                        },
                        {
                            id: 'add_update',
                            by: 'value',
                            value: 'Yes',
                            showAfter: false,
                        },
                    ],
                    fieldsToHide: [
                        'line_item_id',
                        'unit_cd',
                        'transport',
                        'veh_create_date',
                        'up_create_date',
                        'transaction_id',
                        'from_places',
                        'from_states',
                        'to_place',
                        'to_state',
                        'ewb_numb',
                        'add_update',
                        'group_num',
                    ],
                    fieldsToHideOnNewRecord: [
                        'new_vehicle_no',
                        'old_vehicle_no',
                        'new_trans_no',
                        'old_tran_no',
                        'u_from_place',
                        'u_from_state',
                        'u_reason_code',
                        'reason_remark',
                    ],
                    fieldsToDisableOnOldRecord: [
                        'old_vehicle_no',
                        'old_tran_no',
                        'u_from_place',
                        'u_from_state',
                        'u_reason_code',
                        'reason_remark',
                    ],
                    mandateFieldsOnOldRecord: ['new_vehicle_no'],
                },
            },
            helperAssets: {
                'Download Report': {
                    assetName: environment?.eninvoiceAppsConfig?.['ewaybillreport']?.name || 'E-waybill-ReportV1',
                    restAPIName: environment?.eninvoiceAppsConfig?.['ewaybillreport']?.restAPIName || 'ewaybillreportv1',
                    entities: {
                        general_details: {
                            id: 'general_details',
                            allFields: false,
                            fieldsToShow: ['from_date', 'to_date'],
                            fieldsToAutoPopulate: [
                                {
                                    id: 'uuid',
                                    by: 'uuid',
                                    showAfter: false,
                                },
                            ],
                        },
                    },
                },
                Consolidate: {
                    assetName: environment?.eninvoiceAppsConfig?.['consolidatedEwb']?.name || 'Consolidated e-WayBill v6',
                    restAPIName: environment?.eninvoiceAppsConfig?.['consolidatedEwb']?.restAPIName || 'consolidatedewaybillv6',
                    entities: {},
                },
            },
        },
        {
            assetName: environment?.eninvoiceAppsConfig?.['consolidatedEwb']?.name || 'Consolidated e-WayBill v6',
            restAPIName: environment?.eninvoiceAppsConfig?.['consolidatedEwb']?.restAPIName || 'consolidatedewaybillv6',
            displayName: 'Consolidated E-Way Bills',
            entities: {
                generaldetails: {
                    maxNumberOfRecords: 1,
                    templates: {
                        feed: {
                            template: 'consolidated-feed',
                        },
                        detail: {
                            header: {
                                template: 'consolidated-detailheader',
                            },
                        },
                    },
                    fields: [],
                },
            },
        },
        {
            assetName: environment?.eninvoiceAppsConfig?.['ewbupdate']?.name || 'E-Way Bill-Update',
            restAPIName: environment?.eninvoiceAppsConfig?.['ewbupdate']?.name || 'ewbupdate',
            displayName: 'E-Way Bill Update',
            show: false,
        },
        {
            assetName: environment?.eninvoiceAppsConfig?.['ewaybillreport']?.name || 'E-waybill-ReportV1',
            restAPIName: environment?.eninvoiceAppsConfig?.['ewaybillreport']?.restAPIName || 'ewaybillreportv1',
            displayName: 'E-waybill-ReportV1',
            show: false,
        },
        {
            assetName: environment?.eninvoiceAppsConfig?.['ewbpanindia']?.name || 'EWB-Pan-India',
            restAPIName: environment?.eninvoiceAppsConfig?.['ewbpanindia']?.name || 'ewbpanindia',
            displayName: 'EWB-Pan-India',
            show: true,
        },
        {
            assetName: environment?.eninvoiceAppsConfig?.['ewbstatewide']?.name || 'EWB-Statewide',
            restAPIName: environment?.eninvoiceAppsConfig?.['ewbstatewide']?.name || 'ewbstatewide',
            displayName: 'EWB-Statewide',
            show: true,
        },
        {
            assetName: environment?.eninvoiceAppsConfig?.['ewbsplit']?.name || 'EWB-Split',
            restAPIName: environment?.eninvoiceAppsConfig?.['ewbsplit']?.name || 'ewbsplit',
            displayName: 'EWB-Split',
            show: true,
        },
    ];
};

const getCustomAsset = (restAPIName: string): ConfigurationInterface => {
    const configurations = getConfigurations();
    for (let i = 0; i < configurations.length; i++) {
        if (configurations[i].restAPIName === restAPIName) {
            return configurations[i];
        }
    }
};

const copyConfigurationToEntities = (entities: Entity[], asset: ConfigurationInterface) => {
    const customFields = [
        'maxNumberOfRecords',
        'labelName',
        'saveBtnName',
        'updateBtnName',
        'fieldsToAutoPopulate',
        'fieldsToHideOnNewRecord',
        'editMode',
        'disableIfValueExists',
        'enableChildEntitiesOnlyIfFieldValueExists',
        'disableSubmitIfValueExists',
        'fieldsToDisableOnOldRecord',
        'fieldsToAutoPopulateOnOldRecord',
        'mandateFieldsOnOldRecord',
        'fieldsToDisableOnCondtion',
    ];
    const customEntities = (asset && asset.entities) || {};
    const customEntitiesKeys = Object.keys(customEntities);
    if (customEntitiesKeys && customEntitiesKeys.length > 0) {
        entities &&
            entities.forEach((entity) => {
                entity.mapToPrimaryEntity = asset.mapToPrimaryEntity && asset.mapToPrimaryEntity[entity.uid];
                for (const entityId in customEntities) {
                    if (entity.uid === entityId) {
                        const customEntity = customEntities[entityId];
                        entity.templates = customEntity.templates;
                        for (const fieldId in customEntity) {
                            if (customFields.indexOf(fieldId) > -1) {
                                entity[fieldId] = customEntity[fieldId];
                            }
                        }
                        entity.fields
                            .filter((field) => customEntity.fieldsToHide && customEntity.fieldsToHide.indexOf(field.uid) > -1)
                            .forEach((field) => {
                                field.show = false;
                            });
                    }
                }
                entity.entities?.length > 0 && copyConfigurationToEntities(entity.entities, asset);
            });
    }
};

export const transformAppMetaData = (restApiName: string, appData: AssetData): AssetData => {
    if (!restApiName || !appData) {
        return undefined;
    }
    const asset = getCustomAsset(restApiName);
    if (asset?.restAPIName?.length > 0) {
        const assetData: AssetData = CommonUtilsService.cloneObject(appData);
        copyConfigurationToEntities(assetData.entities, asset);
        return assetData;
    }
    return appData;
};
