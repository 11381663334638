<ng-container [ngSwitch]="field.uiType" *transloco="let translate;">
    <ng-container *ngSwitchCase="'RADIOBUTTON'">
        <div class="row booleanFieldContainer radioGroup">
            <div class="col-6 booleanLabel" [title]="field.displayName || field.name">
                <span>{{field.displayName || field.name}} : </span>
            </div>
            <span *ngIf="required && field.message.errors?.length === 0" class="mat-form-field-subscript-wrapper requiredCls">*{{translate('Required')}}</span>
            <div class="col-6 booleanValues">
                <mat-radio-button class="inputRadioButton margin5px" *ngFor="let option of options;" [value]="option.value" [checked]='checkIfRadioBtnSelected(option)' (change)='modelChange.emit(option.value)' [name]="field.displayName || field.name">
                    {{option.displayName}}
                </mat-radio-button>
            </div>
            <mat-error class="mat-form-field-subscript-wrapper requiredCls" *ngIf="field.message.errors?.length > 0" title="{{field.message.errors[0]}}">
                {{field.message.errors[0]}}
            </mat-error>
        </div>
    </ng-container>
    <ng-container *ngSwitchDefault>
        <material-select [disabled]="disabled" [options]="options" [placeholder]="field.displayName || field.name" [errors]="errors" [warnings]="warnings" [name]="field.displayName || field.name" [title]="field.displayName || field.name" [required]="required" [model]="field.value || fieldValue" [description]="description" (modelChange)="valueChanged($event)" [appearance]="appearance"></material-select>
    </ng-container>
</ng-container>
