import { Injectable } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { createEntityAdapter } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, delay, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { ApiService } from '../../services/api/api.service';
import { AlertError, AlertSuccess, ResetBundles, SelectBundle } from '../actions';
import { SetAppBridgeNodes, SetAppDescription, SetAppHelpDocumentation } from '../actions/apps.actions';
import * as actions from '../actions/manage-apps.actions';
import { SetCurrentOrganization } from '../actions/session.actions';
import {
    getManageAppsAssignableSubscriptionIdsByBundleId$,
    getManageAppsBundleSubscribedAppIds$,
    getManageAppsOrganization$,
    getManageAppsOrganizationEntity$,
    getManageAppsPageStatus$,
    getManageAppsSelectedBundleId$,
    getManageAppsSelectedOrgAndAppIds$,
    getManageAppsSelectedServicesIds$,
    getManageAppsServices$,
    getManageAppsServicesByServiceId$,
    getManageAppsServicesByServiceId$ as getManageAppsServicesMap$,
    getManageAppsSubscriptionStatus$,
    getServicesByFilter$,
    getSubscriptionApprovalCount$,
} from '../selectors';
import { manageAppsAdapters, ManageAppsOrganizationEntity, Service } from '../states';

@Injectable()
export class ManageAppsEffects {
    constructor(private actions$: Actions, private _api: ApiService, private store$: Store) {}

    setCurrentOrganization$ = createEffect(() => {
        return this.actions$.pipe(
            delay(10),
            ofType(SetCurrentOrganization, actions.ManageAppsSetOrganization),
            withLatestFrom(this.store$.select(getManageAppsOrganizationEntity$)),
            mergeMap(([action, organizationEntity]) => {
                if (organizationEntity) {
                    return [];
                }
                const servicesAdapter = createEntityAdapter<Service>();
                const servicesEntity: ManageAppsOrganizationEntity = {
                    id: action.organization?.id,
                    services: servicesAdapter.getInitialState(),
                };
                manageAppsAdapters[action.organization.id] = servicesAdapter;
                return of(
                    actions.SetManageAppsOrganizationEntity({
                        servicesEntity,
                        organizationsAdapter: manageAppsAdapters.organizations,
                    })
                );
            })
        );
    });

    setAppsStatus$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.SetManageAppsStatus),
            delay(10),
            withLatestFrom(
                this.store$.select(getManageAppsOrganizationEntity$),
                this.store$.select(getManageAppsServices$),
                this.store$.select(getManageAppsPageStatus$)
            ),
            mergeMap(([_action, organizationEntity, services, pagination]) => {
                if (pagination.getByPagination) {
                    return [];
                }
                if (services?.length > 0) {
                    return [];
                }
                return from(
                    this._api.subscriptions.getAssets({
                        pageIndex: 1,
                        size: 200,
                        type: 'ASSET|WIDGET|BRIDGE_ASSET|RECON|DASHBOARD',
                        organizationId: organizationEntity?.id,
                        noVariableLoading: true,
                        orgIdArray: [organizationEntity?.id],
                    })
                ).pipe(
                    map((response) =>
                        actions.SetManageAppsResponse({
                            organizationsAdapter: manageAppsAdapters.organizations,
                            services: (response as any)?.allApps || (response as any)?.services,
                            orgIdVsName: response?.orgIdVsName,
                            servicesAdapter: manageAppsAdapters[organizationEntity.id],
                        })
                    ),
                    catchError(() => [])
                );
            })
        );
    });

    setAppsStatusByPagination$ = createEffect(() => {
        return this.actions$.pipe(
            delay(10),
            ofType(
                actions.SetManageAppsStatus,
                actions.SetManageAppsPageIndex,
                actions.SetManageAppsPageSize,
                actions.ManageAppsSetOrgsAndApps,
                actions.ManageAppsUpdateProperty
            ),
            withLatestFrom(
                this.store$.select(getManageAppsOrganizationEntity$),
                this.store$.select(getServicesByFilter$),
                this.store$.select(getManageAppsPageStatus$),
                this.store$.select(getManageAppsSubscriptionStatus$),
                this.store$.select(getManageAppsSelectedOrgAndAppIds$)
            ),
            mergeMap(([_action, organizationEntity, filteredServices, pagination, status, { orgIdArray, itemIdArray }]) => {
                if (!pagination.getByPagination) {
                    return [];
                }
                if (filteredServices?.length !== pagination.pageSize) {
                    return from(
                        this._api.subscriptions.getAssets({
                            status: status,
                            pageIndex: pagination.pageIndex + 1,
                            size: pagination.pageSize,
                            type: 'ASSET|WIDGET|BRIDGE_ASSET|RECON|DASHBOARD',
                            organizationId: organizationEntity?.id,
                            noVariableLoading: true,
                            orgIdArray: orgIdArray,
                            itemIdArray: itemIdArray,
                            subscriptionType: 'SUBSCRIPTION_APPROVAL',
                            getByPagination: true,
                        })
                    ).pipe(
                        map((response) =>
                            actions.SetManageAppsResponse({
                                organizationsAdapter: manageAppsAdapters.organizations,
                                services: (response as any)?.allApps || (response as any)?.services,
                                orgIdVsName: response?.orgIdVsName,
                                servicesAdapter: manageAppsAdapters[organizationEntity.id],
                            })
                        ),
                        catchError(() => [])
                    );
                }
                return [];
            })
        );
    });

    setAppsCount$ = createEffect(() => {
        return this.actions$.pipe(
            delay(10),
            ofType(actions.SetManageAppsStatus, actions.ManageAppsSetOrgsAndApps, actions.ManageAppsUpdateMany),
            withLatestFrom(
                this.store$.select(getManageAppsOrganizationEntity$),
                this.store$.select(getManageAppsPageStatus$),
                this.store$.select(getManageAppsSubscriptionStatus$),
                this.store$.select(getManageAppsSelectedOrgAndAppIds$),
                this.store$.select(getSubscriptionApprovalCount$)
            ),
            mergeMap(([_action, organizationEntity, pagination, status, { orgIdArray, itemIdArray }, count]) => {
                if (!pagination.getByPagination || count > 0) {
                    return [];
                }
                return from(
                    this._api.subscriptions.subscribedAssetsCount({
                        organizationId: organizationEntity?.id,
                        subscriptionType: 'SUBSCRIPTION_APPROVAL',
                        status: status,
                        orgIdArray: orgIdArray,
                        itemIdArray: itemIdArray,
                    })
                ).pipe(
                    map((response) => actions.ManageAppsSetCount({ count: response as number })),
                    catchError((errorResponse) => of(AlertError({ message: errorResponse })))
                );
            })
        );
    });

    setManageAppsTaggedApps$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.GetManageAppsTaggedApps),
            withLatestFrom(this.store$.select(getManageAppsServicesMap$)),
            mergeMap(([action, services]) => {
                let filteredAppsByTags = action.serviceIds.reduce((filteredResponse, serviceId) => {
                    filteredResponse.push({
                        value: services[serviceId].displayName || services[serviceId].itemName,
                        displayName: services[serviceId].displayName || services[serviceId].itemName,
                        id: serviceId,
                    });
                    return filteredResponse;
                }, []);
                return of(
                    actions.SetManageAppsTaggedAppsResponse({
                        filteredAppsByTags,
                    })
                );
            })
        );
    });

    updateAssetDescription$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SetAppDescription),
            withLatestFrom(this.store$.select(getManageAppsOrganizationEntity$), this.store$.select(getManageAppsServicesByServiceId$)),
            mergeMap(([action, organizationEntity, services]) => {
                return of(
                    actions.UpdateManageAppsService({
                        organizationsAdapter: manageAppsAdapters.organizations,
                        servicesAdapter: manageAppsAdapters[organizationEntity.id],
                        assetId: services[action.serviceId]?.id,
                        property: 'description',
                        propertyValue: action.description,
                    })
                );
            })
        );
    });

    updateAssetHelpDocument$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SetAppHelpDocumentation),
            withLatestFrom(this.store$.select(getManageAppsOrganizationEntity$), this.store$.select(getManageAppsServicesByServiceId$)),
            mergeMap(([action, organizationEntity, services]) => {
                if (action.bridgeServiceId) {
                    return of(
                        actions.UpdateManageAppsBridgeNode({
                            organizationsAdapter: manageAppsAdapters.organizations,
                            servicesAdapter: manageAppsAdapters[organizationEntity.id],
                            assetId: services[action.bridgeServiceId]?.id,
                            property: 'helpDocuments',
                            propertyValue: action.helpDocuments,
                            bridgeNodeId: action.bridgeNodeId,
                        })
                    );
                }
                return of(
                    actions.UpdateManageAppsService({
                        organizationsAdapter: manageAppsAdapters.organizations,
                        servicesAdapter: manageAppsAdapters[organizationEntity.id],
                        assetId: services[action.serviceId]?.id,
                        property: 'helpDocuments',
                        propertyValue: action.helpDocuments,
                    })
                );
            })
        );
    });

    setBridgeNodes$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SetAppBridgeNodes),
            withLatestFrom(this.store$.select(getManageAppsOrganizationEntity$)),
            mergeMap(([action, organizationEntity]) => {
                if (!action.assetId) {
                    return [];
                }
                const assetId = Object.keys(organizationEntity?.services?.entities).find(
                    (key) => organizationEntity?.services?.entities[key].itemId === action.assetId
                );
                return of(
                    actions.UpdateManageAppsService({
                        organizationsAdapter: manageAppsAdapters.organizations,
                        servicesAdapter: manageAppsAdapters[organizationEntity.id],
                        assetId: assetId,
                        property: 'bridgeNodes',
                        propertyValue: action.bridgeNodes,
                    })
                );
            })
        );
    });

    submitManageAppsSelectedServiceIds$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.SubmitManageAppsSelectedServiceIds),
            withLatestFrom(this.store$.select(getManageAppsOrganizationEntity$), this.store$.select(getManageAppsServicesByServiceId$)),
            mergeMap(([action, organizationEntity, services]) => {
                return this._api.subscriptions
                    .subscribeToServices({
                        organizationId: organizationEntity.id,
                        payload: {
                            itemIds: action.ids,
                            orgIds: action.orgIds,
                        },
                    })
                    .pipe(
                        map(() => {
                            this.store$.dispatch(AlertSuccess({ message: translate('Subscription request placed successfully') }));
                            return actions.ManageAppsUpdateMany({
                                ids: action.ids.reduce((ids, serviceId) => {
                                    ids.push(services[serviceId].id);
                                    return ids;
                                }, []),
                                organizationsAdapter: manageAppsAdapters.organizations,
                                servicesAdapter: manageAppsAdapters[organizationEntity.id],
                                property: 'status',
                                propertyValue: 'PENDING',
                            });
                        }),
                        catchError((res) => {
                            return of(AlertError({ message: res }));
                        })
                    );
            })
        );
    });

    getAssignableSubscriptionIds$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.GetManageAppsAssignableSubscriptionIds, SelectBundle),
            delay(10),
            withLatestFrom(
                this.store$.select(getManageAppsAssignableSubscriptionIdsByBundleId$),
                this.store$.select(getManageAppsSelectedBundleId$)
            ),
            mergeMap(([_action, subscriptionIds, bundleId]) => {
                if (subscriptionIds?.length > 0 || !bundleId) {
                    return [];
                }
                return from(this._api.assets.getAssignableSubscriptions(bundleId)).pipe(
                    map((response) => {
                        return actions.SetManageAppsAssignableSubscriptionIds({ ids: response as string[] });
                    }),
                    catchError((error) => of(AlertError({ message: error })))
                );
            })
        );
    });

    getBundleSubscribedApps$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SelectBundle),
            delay(10),
            withLatestFrom(this.store$.select(getManageAppsBundleSubscribedAppIds$)),
            mergeMap(([action, bundleAppIds]) => {
                if (!action.id || bundleAppIds.length > 0) {
                    return of(
                        actions.SetManageAppsBundleSubscriptionIds({
                            ids: bundleAppIds,
                        })
                    );
                }
                return from(this._api.assets.getBundleSubscriptions(action.id)).pipe(
                    map((response) => {
                        return actions.SetManageAppsBundleSubscriptionIds({
                            ids: response as string[],
                        });
                    }),
                    catchError((error) => of(AlertError({ message: error })))
                );
            })
        );
    });

    updateManageAppsSelections$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                actions.SetManageAppsBundleSubscriptionIds,
                actions.SetManageAppsStatus,
                actions.SetManageAppsResponse,
                actions.SelectManageAppsServices,
                ResetBundles
            ),
            delay(10),
            withLatestFrom(this.store$.select(getManageAppsSelectedServicesIds$)),
            mergeMap(([action, serviceIds]) => {
                return of(
                    actions.SetManageAppsSelectedServiceIds({
                        ids: action['ids'] || serviceIds,
                    })
                );
            })
        );
    });

    SubmitAppStatus$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.ManageAppsSubmitAppStatus),
            withLatestFrom(this.store$.select(getManageAppsOrganization$), this.store$.select(getSubscriptionApprovalCount$)),
            mergeMap(([action, organizationId, count]) => {
                return this._api.subscriptions
                    .updateSubscribedServices({
                        organizationId: organizationId,
                        payload: action.payload,
                    })
                    .pipe(
                        map(() => {
                            this.store$.dispatch(AlertSuccess({ message: this.getMessage(action.payload.status) }));
                            this.store$.dispatch(actions.ManageAppsSetCount({ count: count - action.payload.subscriptionIds.length }));
                            return actions.ManageAppsUpdateProperty({
                                ids: action.payload.subscriptionIds,
                                organizationsAdapter: manageAppsAdapters.organizations,
                                servicesAdapter: manageAppsAdapters[organizationId],
                                property: 'status',
                                propertyValue: action.payload.status,
                            });
                        }),
                        catchError((error) => of(AlertError({ message: error })))
                    );
            })
        );
    });

    private getMessage = (action: string) => {
        switch (action) {
            case 'APPROVED':
                return translate('Subscription approved');
            case 'REJECTED':
                return translate('Subscription rejected');
            case 'PENDING_DEACTIVATE':
                return translate('Subscription deactivated');
            case 'ACTIVATE':
                return translate('Subscription activated');
        }
    };
}
