<span [ngSwitch]="field && field.uiTypeMetadata">
    <span *ngSwitchCase="'TEXTFIELD'">
        <ng-container [ngSwitch]="field.datatype">
            <span *ngSwitchCase="'GRID'">
                <grid-field [field]="field" [recordUpdateDisabled]="recordUpdateDisabled" [recordId]="record?.id"></grid-field>
            </span>
            <ng-container *ngSwitchCase="'ATTACHMENT'">
                <ng-container *ngTemplateOutlet="attachment"></ng-container>
            </ng-container>
            <ng-container *ngSwitchDefault>
                <ng-container *ngIf="field.hasMasterLookup">
                    <asset-masterlookup [currentOrganizationId]="currentOrganizationId" [disabled]="!enableField" [isMasterSearchDisabled]="!field.masterSearchEnabled" [classList]="classList" [field]="field" [value]="field.value" [errors]="field.message.errors" [warnings]="field.message.warnings" [required]="field.isMandatory || field.isBusinessKey" [masters]="record.masters" [description]="field.description || field.displayName" [fieldsMap]="fieldsMap" (modelChange)="valueChanged($event); fieldChanged()" lookupHint="lookupHint">
                    </asset-masterlookup>
                </ng-container>
                <ng-container *ngIf="!field.hasMasterLookup">
                    <material-input [disabled]="!enableField" [placeholder]="field.displayName" [errors]="field.message.errors" [title]="field.displayName" [model]="field.value" [classList]="classList" [debounceAfter]="1000" (modelChange)="valueChanged($event)" [required]="field.isMandatory || field.isBusinessKey" [warnings]="field.message.warnings" [description]="field.description || field.displayName" (debounceEventHandler)="debounceEventHandler.emit($event)" [appearance]="appearance">
                    </material-input>
                </ng-container>
            </ng-container>
        </ng-container>
    </span>
    <span *ngSwitchCase="'TEXTAREA'">
        <material-textarea [disabled]="!enableField" [placeholder]="field.displayName" [errors]="field.message.errors" [title]="field.displayName" [model]="field.value" [classList]="classList" [debounceAfter]="1000" (modelChange)="field.value = $event; modelChange.emit($event)" [description]="field.description || field.displayName" [required]="field.isMandatory || field.isBusinessKey" (debounceEventHandler)="debounceEventHandler.emit($event)" [appearance]="appearance">
        </material-textarea>
    </span>
    <span *ngSwitchCase="'DROPDOWN'">
        <app-assetlookup [disabled]="!enableField" [field]="field" [errors]="field.message.errors" [required]="field.isMandatory || field.isBusinessKey" [conditionalValue]="getConditionValue()" [service]="service" [description]="field.description || field.displayName" (modelChange)="valueChanged($event)" [appearance]="appearance">
        </app-assetlookup>
    </span>
    <span *ngSwitchCase="'DATE'">
        <material-input-date [disabled]="!enableField" [placeholder]="field.displayName" [title]="field.displayName" [model]="field.value" [errors]="field.message.errors" [classList]="classList" [debounceAfter]="1000" [required]="field.isMandatory || field.isBusinessKey" [description]="field.description || field.displayName" [dateFormat]="field.outputFormat" [inputOnlyDisabled]="true" (modelChange)="field.value = $event; modelChange.emit($event)" (debounceEventHandler)="debounceEventHandler.emit($event)" [appearance]="appearance">
        </material-input-date>
    </span>
    <span *ngSwitchCase="'PASSWORD'">
        <material-input-password [disabled]="!enableField" [errors]="field.message.errors" [placeholder]="field.displayName" [title]="field.displayName" [model]="field.value" [classList]="classList" [debounceAfter]="1000" (modelChange)="field.value = $event; modelChange.emit($event)" [required]="field.isMandatory || field.isBusinessKey" [description]="field.description || field.displayName" (debounceEventHandler)="debounceEventHandler.emit($event)" [appearance]="appearance">
        </material-input-password>
    </span>
    <span *ngSwitchCase="'RADIOBUTTON'">
        <app-assetlookup [disabled]="!enableField" [field]="field" [errors]="field.message.errors" [required]="field.isMandatory || field.isBusinessKey" [warnings]="field.message.warnings" [conditionalValue]="getConditionValue()" [service]="service" (modelChange)="valueChanged($event)"> </app-assetlookup>
    </span>
    <span *ngSwitchCase="'CHECKBOX'">
        <material-group-checkbox [disabled]="!enableField" [menuName]="field.displayName" [classList]="classList" [options]="field.lookupValues && field.lookupValues.displayVsValue" [value]="$any(field.value)" [optionDisplayNameProperty]="'key'" [optionValueProperty]="'value'" [errors]="field.message.errors" [required]="field.isMandatory || field.isBusinessKey" [warnings]="field.message.warnings" [description]="field.description || field.displayName" [field]="field" [conditionalValue]="getConditionValue()" (onOptionsChanged)="field.value = $event; modelChange.emit($event)" [currentApp]="service" [currentBridge]="bridge"></material-group-checkbox>
    </span>
    <span *ngSwitchCase="'ATTACHMENT'">
        <ng-container *ngTemplateOutlet="attachment"></ng-container>
    </span>
    <span *ngSwitchCase="'GRID'">
        <grid-field [field]="field" [recordUpdateDisabled]="recordUpdateDisabled" [recordId]="record?.id"></grid-field>
    </span>
    <span *ngSwitchDefault>
        {{field && field.uiTypeMetadata}}
        <!-- {{field | json}} -->
    </span>
</span>

<ng-template #attachment>
    <span *ngIf="field.show" class="fieldAttachmentContainer">
        <ng-container *ngIf="field.isBusinessKey || field.isMandatory">
            <span class="fieldAttachmentLabel requiredAttachment">
                <div class="fieldAttachmentLabelText">
                    {{field.displayName}}
                    <span class="material-symbols-outlined" *ngIf="field.description?.length > 0" [title]="field.description">
                        info
                    </span>:
                </div>
                <div class="fieldAttachmentRequiredText" [class.mat-error]="field.message.errors.length > 0 || field.message.warnings.length > 0" [class.mat-has-warning]="field.message.warnings.length > 0">
                    {{
                        field.message.errors.length > 0 ? field.message.errors : (field.message.warnings.length > 0 ? field.message.warnings : '* required')
                    }}
                </div>
            </span>
        </ng-container>
        <ng-container *ngIf="!field.isBusinessKey && !field.isMandatory">
            <span class="fieldAttachmentLabel">
                {{field.displayName}}
                <span class="material-symbols-outlined" *ngIf="field.description?.length > 0" [title]="field.description">
                    info
                </span>:
            </span>
        </ng-container>
        <span class="fieldAttachmentIcon" *transloco="let translate">
            <mat-icon [title]="translate('Attach File')" (click)="openAttachModal()">attach_file</mat-icon>
        </span>
    </span>
</ng-template>
