import { createFeatureSelector, createSelector } from '@ngrx/store';

import { NotificationEvent } from '../../interface/notifications-event.interface';
import { ReconEvent } from '../../interface/recon-event.interface';
import { NOTIFICATIONS_REDUCER_KEY } from '../reducers';
import { NotificationsState } from '../states';

const currentState = createFeatureSelector<NotificationsState>(NOTIFICATIONS_REDUCER_KEY);

export const getNotificationsMap$ = createSelector(currentState, (state) => state.instanceIdVsEvents);

const getNotificationsByResourceId$ = (resourceId: string) => createSelector(getNotificationsMap$, (map) => map?.[resourceId]);

export const getNotificationIds$ = (resourceId: string) =>
    createSelector(getNotificationsByResourceId$(resourceId), (entity) => entity?.ids as string[]);

export const getNotificationCount$ = (resourceId: string) => createSelector(getNotificationIds$(resourceId), (ids) => ids?.length);

export const getNotifications$ = (resourceId: string) =>
    createSelector(getNotificationsByResourceId$(resourceId), (entity) =>
        (entity?.ids as string[])?.reduce((events, id) => {
            events.push(entity?.entities?.[id]);
            return events;
        }, [] as NotificationEvent[])
    );

export const getNotification$ = (resourceId: string, id: string) =>
    createSelector(getNotificationsByResourceId$(resourceId), (entity) => entity?.entities?.[id]);

export const getNotificationsPagingStateMap$ = createSelector(currentState, (state) => state.pagination);

export const getNotificationResourcePagingState$ = (resourceId: string) =>
    createSelector(getNotificationsPagingStateMap$, (pagingStateMap) => pagingStateMap?.[resourceId]);

export const getReconEventsMap$ = createSelector(currentState, (state) => state.reconIdVsEvents);

const getReconEventsByResourceId$ = (resourceId: string) => createSelector(getReconEventsMap$, (map) => map?.[resourceId]);

export const getReconEventIds$ = (resourceId: string) =>
    createSelector(getReconEventsByResourceId$(resourceId), (entity) => entity?.ids as string[]);

export const getReconEvents$ = (resourceId: string) =>
    createSelector(getReconEventsByResourceId$(resourceId), (entity) =>
        (entity?.ids as string[])?.reduce((events, id) => {
            events.push(entity?.entities?.[id]);
            return events;
        }, [] as ReconEvent[])
    );

export const getReconEvent$ = (resourceId: string, id: string) =>
    createSelector(getReconEventsByResourceId$(resourceId), (entity) => entity?.entities?.[id]);

export const getNotificationsPagingState$ = createSelector(currentState, (state) => state.pagination);

export const getOutboundTransaformationCompleted$ = (resourceId: string) =>
    createSelector(getNotifications$(resourceId), getNotificationResourcePagingState$(resourceId), (notifications, _paginationStateMap) => {
        const includedChains = [];
        return notifications
            ?.filter((event, _index) => event.eventType === 'OUTBOUND_TRANSFORMATION_COMPLETED')
            ?.reduce((chains, event) => {
                !includedChains.includes(event.payload.chainName) &&
                    chains.push(event.eventId) &&
                    includedChains.push(event.payload.chainName);
                return chains;
            }, [] as string[]);
    });

export const getNotificationsViaPagination$ = (resourceId: string) =>
    createSelector(getNotifications$(resourceId), getNotificationResourcePagingState$(resourceId), (notifications, paginationStateMap) => {
        const start = paginationStateMap?.index * paginationStateMap?.size;
        const end = (paginationStateMap?.index + 1) * paginationStateMap?.size;
        return notifications?.filter((_id, index) => index >= start && index < end) || [];
    });

export const getEventTypes$ = createSelector(currentState, (state) => state.eventTypes);

export const getEmailNotifications$ = createSelector(currentState, (state) => state.emailNotifications);

export const getEventSubscriptions$ = createSelector(currentState, (state) => state.eventSubscriptions);

export const getEmailNotificationsPagingState$ = createSelector(currentState, (state) => state.emailNotificationsPagination);

export const getEventSubscriptionsPagingState$ = createSelector(currentState, (state) => state.eventSubscriptionsPagination);

export const getEventSubscriptionsViaPagination$ = createSelector(
    getEventSubscriptions$,
    getEventSubscriptionsPagingState$,
    (records, pagination) => {
        const start = pagination?.index * pagination?.size;
        const end = (pagination?.index + 1) * pagination?.size;
        return records?.filter((_record, index) => index >= start && index < end) || [];
    }
);

export const getEmailNotificationsViaPagination$ = createSelector(
    getEmailNotifications$,
    getEmailNotificationsPagingState$,
    (records, pagination) => {
        const start = pagination?.index * pagination?.size;
        const end = (pagination?.index + 1) * pagination?.size;
        return records?.filter((_record, index) => index >= start && index < end) || [];
    }
);
