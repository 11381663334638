import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { Router } from '@angular/router';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SettingsMenuInterface } from '../../interface/settings-menu.interface';
import { UtilsService } from '../../services/utils/utils.service';

@Component({
    selector: 'app-settings-nested-menu',
    templateUrl: './settings-nested-menu.component.html',
    styleUrls: ['./settings-nested-menu.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: '',
        },
    ],
})
export class SettingsNestedMenuComponent implements OnInit {
    @Input() isNewUI: boolean = false;
    @Input() isSettingsSelected: boolean;
    @Input() main: boolean;
    @Input() menu: SettingsMenuInterface;

    @Output() childMenuClosedEvent = new EventEmitter();

    @ViewChild('matSettingsMenuTrigger', { static: false }) _settingsMenuTrigger: MatMenuTrigger;

    unsubscribe = new Subject<void>();
    isIE = this._utils.isIEBrowser();
    hideMenuTimeout: any;
    mousePosition: { x: number; y: number };
    elementPosition: { x: { left: number; right: number }; y: { top: number; bottom: number } };

    constructor(private _router: Router, private _utils: UtilsService) {}

    setRoute = (item: SettingsMenuInterface) => {
        this._router.navigate(item.route.split('/'));
    };

    openSettingsMenu = async (item?: SettingsMenuInterface) => {
        this.menu.opened = true;
        if (this.menu.hasChildren && (!this.menu.children || this.menu.children.length === 0) && this.menu.loadChildren) {
            this.loadChildren(this.menu);
        }
        this.menu.trigger = this._settingsMenuTrigger;
        if (!this._settingsMenuTrigger['_menuOpen']) {
            this._settingsMenuTrigger.toggleMenu();
            this._settingsMenuTrigger.menuClosed.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
                this.removeEventBindings();
                setTimeout(() => {
                    this.childMenuClosedEvent.emit();
                }, 200);
            });
        }
        clearTimeout(this.hideMenuTimeout);
        if (!item) {
            this.checkAndCloseTheMenu();
        } else {
            this.removeEventBindings();
        }
    };

    checkAndCloseTheMenu = () => {
        !this.mousePosition && document.querySelector('body').addEventListener('mousemove', this.getMousePosition);
        !this.elementPosition && this.getElementPosition();
        this.hideMenuTimeout = setTimeout(() => {
            if (this.checkMousePositionWRTElement()) {
                this.checkAndCloseTheMenu();
            } else {
                this.removeEventBindings();
                this.menu.opened = false;
                this.menu.trigger.toggleMenu();
            }
        }, 100);
    };

    removeEventBindings = () => {
        document.querySelector('body').removeEventListener('mousemove', this.getMousePosition);
        this.mousePosition = undefined;
        this.elementPosition = undefined;
    };

    private checkMousePositionWRTElement = () => {
        const mouse = this.mousePosition;
        const element = this.elementPosition;
        return (
            mouse?.x >= element?.x?.left && mouse?.x <= element?.x?.right && mouse?.y >= element?.y?.top && mouse?.y <= element?.y?.bottom
        );
    };

    private getMousePosition = (event: MouseEvent) => {
        this.mousePosition = {
            x: event.clientX,
            y: event.clientY,
        };
        return this.mousePosition;
    };

    private getElementPosition = () => {
        const element = document.querySelector('.settingsGearIcon');
        const position = element.getBoundingClientRect();
        this.elementPosition = {
            x: { left: position.left, right: position.right },
            y: { top: position.top, bottom: position.bottom },
        };
        return this.elementPosition;
    };

    loadChildren = async (menu) => {
        if (menu.childrenLoading) {
            return;
        }
        menu.childrenLoading = true;
        await menu.loadChildren(menu);
        menu.childrenLoading = false;
    };

    closeSettingsMenu = () => {
        this.menu.opened = false;
        setTimeout(() => {
            this.closeMenus(this.menu);
        }, 100);
    };

    closeMenus = (menu: SettingsMenuInterface) => {
        if (menu.opened) {
            return menu;
        } else if (menu.hasChildren && menu.children?.length > 0) {
            const childMenu = menu.children.find((item) => this.closeMenus(item));
            if (!childMenu && menu.trigger?.['_menuOpen']) {
                menu.trigger.toggleMenu();
            }
            return childMenu;
        } else if (menu.hasChildren && menu.children?.length === 0 && menu.trigger?.['_menuOpen']) {
            menu.trigger.toggleMenu();
        }
        return false;
    };

    childMenuClosedEventListener = () => {
        this.closeMenus(this.menu);
    };

    ngOnInit(): void {}

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
