import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { RoleUser } from '../../models/roleuser.class';
import { User } from '../../models/user.class';
import { BridgeService } from '../bridge/bridge.service';

@Injectable({
    providedIn: 'root',
})
export class UsersService {
    constructor(private _bridge: BridgeService) {}

    /**
     * method to get users array
     * @param data Contains Organization Id
     * @param callbacks Contains Success Callback Method, Failure Callback Method
     */
    getUsers = (callbacks?: { successCallback: (users: User[]) => void; failureCallback: (...args: any[]) => void }) => {
        this._bridge.getUsers(
            (res) => {
                const users = [];
                const response = res || [];
                response.forEach((user) => {
                    users.push(new User(user));
                });
                callbacks.successCallback(users);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * method to add a user
     * @param data  Contains user and roles details
     * @param callbacks Contains Success Callback Method, Failure Callback Method
     */
    addUser = (data: RoleUser) => {
        return new Observable((observer) => {
            this._bridge.addUsers(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * method to update a user
     * @param data  Contains user and roles details
     * @param callbacks Contains Success Callback Method, Failure Callback Method
     */
    updateUserDetails = (data: RoleUser) => {
        return new Observable((observer) => {
            this._bridge.updateUserDetails(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * method to activate a user
     * @param data Contains user details
     * @param callbacks Contains Success Callback Method, Failure Callback Method
     */
    activateUser = (
        data: User,
        callbacks?: {
            successCallback: (response: { msg: string }) => void;
            failureCallback: (...args: any[]) => void;
        }
    ) => {
        this._bridge.activateUser(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * method to deactivate a user
     * @param data contains user details
     * @param callbacks Contains Success Callback Method, Failure Callback Method
     */
    deactivateUser = (
        data: User,
        callbacks?: {
            successCallback: (response: { msg: string }) => void;
            failureCallback: (...args: any[]) => void;
        }
    ) => {
        this._bridge.deactivateUser(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * method to check if a userid already exists
     * @param data Contains User Id
     * @param callbacks Contains Success Callback Method, Failure Callback Method
     */
    validateUserId = (
        data: {
            userId: string;
        },
        callbacks?: {
            successCallback: (response) => void;
            failureCallback: (...args: any[]) => void;
        }
    ) => {
        this._bridge.validateUserId(
            data,
            (res) => {
                callbacks.successCallback(res?.response);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };
    /**
     * method to check if a emailId already exists
     * @param data Contains Organization Id, email Id
     * @param callbacks Contains Success Callback Method, Failure Callback Method
     */
    validateEmailId = (
        email: string,
        callbacks?: {
            successCallback: (response) => void;
            failureCallback: (...args: any[]) => void;
        }
    ) => {
        this._bridge.validateEmailId(
            email,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * method to get roles before adding a user
     * @param data Contains user details
     * @param callbacks Contains Success Callback Method, Failure Callback Method
     */
    getUserRoles = (
        data: User,
        callbacks?: {
            successCallback: (...args: any[]) => void;
            failureCallback: (...args: any[]) => void;
        }
    ) => {
        this._bridge.getUserRoles(
            data.id,
            (res) => {
                callbacks.successCallback(res || []);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * method to get roles before adding a user
     * @param data Contains user details
     * @param callbacks Contains Success Callback Method, Failure Callback Method
     */
    getUserRolesPromise = (userId: string) => {
        return new Promise((resolve, reject) => {
            this._bridge.getUserRoles(
                userId,
                (res) => {
                    resolve(res || []);
                },
                (res) => {
                    reject(res);
                }
            );
        });
    };

    /**
     * method to get users array
     * @param data Contains Organization Id
     * @param callbacks Contains Success Callback Method, Failure Callback Method
     */
    getOrgUsers = (
        data: {
            organizationId: string;
        },
        callbacks?: {
            successCallback: (users: User[]) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.getOrgUsers(
            data,
            (res) => {
                const users = [];
                const response = res || [];
                response.forEach((user) => {
                    users.push(new User(user));
                });
                callbacks.successCallback(users);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    getOrgUsersObservable = (data: { organizationId: string }) => {
        return new Observable((observer) => {
            this._bridge.getOrgUsers(
                data,
                (res) => {
                    const users = [];
                    const response = res || [];
                    response.forEach((user) => {
                        users.push(new User(user));
                    });
                    observer.next(response);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * method to get users
     * @param data Contains Organization Id, page number and size
     * @param callbacks Contains Success Callback Method, Failure Callback Method
     */
    getUsersByPagination = (data: { organizationId: string; pageNumber: number; pageSize: number; criterias?: {} }) => {
        return new Observable((observer) => {
            this._bridge.getUsersByPagination(
                data,
                (res) => {
                    const users = [];
                    const response = res || [];
                    response.forEach((user) => {
                        users.push(new User(user));
                    });
                    observer.next(response);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * method to get users count
     * @param data Contains Organization Id
     * @param callbacks Contains Success Callback Method, Failure Callback Method
     */
    getUsersCount = (
        data: {
            organizationId: string;
            criterias?: {};
        },
        callbacks?: {
            successCallback: (...args: any[]) => void;
            failureCallback: (...args: any[]) => void;
        }
    ) => {
        this._bridge.getUsersCount(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * method to reset user password
     * @param data Contains user Id
     * @param callbacks Contains Success Callback Method, Failure Callback Method
     */
    reserUserPassword = (
        data: {
            userId: string;
        },
        callbacks?: {
            successCallback: (...args: any[]) => void;
            failureCallback: (...args: any[]) => void;
        }
    ) => {
        this._bridge.reserUserPassword(
            data,
            (res) => {
                callbacks.successCallback(res?.response);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to reset password
     * @param data Contains user Id
     */
    resetUserPassword = (data: { userId: string }) => {
        return new Observable<{ msg: string }>((observer) => {
            this._bridge.reserUserPassword(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to unlock password
     * @param data Contains userId
     * @param callbacks Contains Success callback method, Failure callback method
     */
    unlockPassword = (data: { userId: string }) => {
        return new Observable((observer) => {
            this._bridge.unlockPassword(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.next(res);
                    observer.complete();
                }
            );
        });
    };
    /**
     * method to get all users without having user management permission
     * @param data Contains organization Id
     * @param callbacks Contains Success Callback Method, Failure Callback Method
     */
    getAllUsers = (
        data: {
            organizationId: string;
        },
        callbacks?: {
            successCallback: (...args: any[]) => void;
            failureCallback: (...args: any[]) => void;
        }
    ) => {
        this._bridge.getAllUsers(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to Get All Users
     * @param data Contains user Id
     */
    getResetPasswordUsers = (data: { organizationId: string }) => {
        return new Observable<{ users: [] }>((observer) => {
            this._bridge.getAllUsers(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * method to get user details
     * @param data Contains user Id
     * @param callbacks Contains Success Callback Method, Failure Callback Method
     */
    getCurrentUserDetails = () => {
        return new Promise((resolve, reject) => {
            this._bridge.getCurrentUserDetails(
                (res) => {
                    resolve(res);
                },
                (res) => {
                    reject(res);
                }
            );
        });
    };

    /**
     * Method to get digital signature details
     */
    getDigitalSignatureDetails = (callbacks?: { successCallback: (...args: any[]) => void; failureCallback: (...args: any[]) => void }) => {
        this._bridge.getDigitalSignatureDetails(
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * method to get user details
     * @param data Contains user Id
     * @param callbacks Contains Success Callback Method, Failure Callback Method
     */
    getSupportedRegions = () => {
        return new Observable((observer) => {
            this._bridge.getSupportedRegions(
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * method to get user details
     * @param data Contains user Id
     * @param callbacks Contains Success Callback Method, Failure Callback Method
     */
    getUserDetailsById = (
        data: {
            userId: string;
        },
        callbacks?: {
            successCallback: (...args: any[]) => void;
            failureCallback: (...args: any[]) => void;
        }
    ) => {
        this._bridge.getUserDetailsById(
            data.userId,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * method to get user details
     * @param data Contains user Id
     * @param callbacks Contains Success Callback Method, Failure Callback Method
     */
    public getUserDetails = (userId: string) => {
        return new Promise((resolve, reject) => {
            this._bridge.getUserDetailsById(
                userId,
                (res) => {
                    resolve(res);
                },
                (res) => {
                    reject(res);
                }
            );
        });
    };
}
