import { createReducer, on } from '@ngrx/store';

import * as sessionActions from '../actions/session.actions';
import { sessionState } from '../states';

export const SESSION_REDUCER = createReducer(
    sessionState,
    on(sessionActions.ClearApplicationMiscProperties, (state) => {
        return {
            ...state,
            applicationMiscProperties: {
                'min-mfa-answers': undefined,
                'password-policy': undefined,
                'password-policy-msg': undefined,
                'seed-token': undefined,
                'x-auth-token': undefined,
            },
        };
    }),
    on(sessionActions.SetApplicationMiscProperties, (state, action) => {
        return {
            ...state,
            applicationMiscProperties: {
                'min-mfa-answers': action?.['min-mfa-answers'],
                'password-policy': action?.['password-policy'],
                'password-policy-msg': action?.['password-policy-msg'],
                'seed-token': action?.['seed-token'],
                'x-auth-token': action?.['x-auth-token'],
            },
        };
    }),
    on(sessionActions.SetCurrentOrganization, (state, action) => {
        return {
            ...state,
            currentOrganizationId: action?.organization?.id,
            providerOrganization: action?.organization?.id === '111',
        };
    }),
    on(sessionActions.SetLoggedInOrganization, (state, action) => {
        return {
            ...state,
            loggedInOrganizationId: action?.organization?.id,
        };
    }),
    on(sessionActions.SetActiveLanguage, (state, action) => {
        return {
            ...state,
            activeLanguage: action.language,
        };
    }),
    on(sessionActions.SetSessionDetails, (state, action) => {
        return {
            ...state,
            currentOrganizationId: action?.currentOrg?.id,
            isAdmin: action?.isAdmin,
            loggedInOrganizationId: action?.loggedInOrg?.id,
            passwordExpiresInDays: action?.passwordExpiresInDays,
            session: action?.loggedInOrg?.id ? true : false,
            timeZoneOffset: action?.timeZoneOffset,
            sessionUser: action?.user,
            lastLogin: action?.user?.['lastLogin'],
        };
    }),
    on(sessionActions.setWorkQCount, (state, action) => {
        return {
            ...state,
            workQCount: action.count,
        };
    }),
    on(sessionActions.setWorkQMessages, (state, action) => {
        return {
            ...state,
            workQMessages: action.messages,
        };
    }),
    on(sessionActions.SetProviderAdmin, (state, action) => {
        return {
            ...state,
            providerAdmin: action.providerAdmin,
        };
    }),
    on(sessionActions.SessionLogoutSuccessfull, () => sessionState)
);

export const SESSION_REDUCER_KEY = 'session';
