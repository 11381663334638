import { createAction, props } from '@ngrx/store';

import { WorkFlow } from '../../models/workflow.class';

const enum workflowTypes {
    GET_WORKFLOW = '[WORKFLOW] Get Workflow',
    SET_WORKFLOW = '[WORKFLOW] Set Workflow',
    EXECUTE_WORKFLOW = '[WORKFLOW] Execute Workflow',
    EXECUTED_WORKFLOW = '[WORKFLOW] Executed Workflow',
    SET_LOADING = '[WORKFLOW] Set Loading',
    CHECK_FOR_MAX_TIME = '[WORKFLOW] Check for max time',
}

export const GetWorkflow = createAction(
    workflowTypes.GET_WORKFLOW,
    props<{
        instanceId: string;
        noAlerts: boolean;
        serviceId: string;
        restApiName?: string;
        notFirstIteration?: boolean;
        configurations?: any;
    }>()
);

export const SetWorkflow = createAction(
    workflowTypes.SET_WORKFLOW,
    props<{
        instanceId: string;
        workflows: WorkFlow[];
    }>()
);

export const ExecuteWorkflowStage = createAction(
    workflowTypes.EXECUTE_WORKFLOW,
    props<{
        instanceIds: string[];
        serviceId: string;
        apiAction: string;
        comment: string;
        formFields: { [property: string]: string };
        executeTimestamp: number;
        configurations?: any;
        actionVariables?: {
            selectedEntityRecords?: {
                records: {
                    entityId: string;
                    recordIds: string[];
                }[];
            };
        };
    }>()
);

export const ExecutedWorkflowStage = createAction(
    workflowTypes.EXECUTED_WORKFLOW,
    props<{
        instanceIds: string[];
        executeTimestamp: number;
    }>()
);

export const SetWorkflowLoading = createAction(
    workflowTypes.SET_LOADING,
    props<{
        instanceIds: string[];
        loading: boolean;
    }>()
);

export const CheckForMaxTime = createAction(
    workflowTypes.CHECK_FOR_MAX_TIME,
    props<{
        instanceId: string;
        notFirstIteration?: boolean;
        configurations?: any;
    }>()
);
