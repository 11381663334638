import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AssetData } from '../../models/assetdata.class';
import { AssetService } from '../../models/assetservice.class';
import { Entity } from '../../models/entity.class';
import { Record } from '../../models/record/record.class';
import { Transformation } from '../../models/transformation';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { GST_FILING_REDUCER_KEY } from '../reducers';
import { GSTFilingState } from '../states';
import {
    getAppConfiguratorsMap$,
    getAppPrimaryEntity$,
    getAppsMetaDataMap$,
    getAppsTemplate$,
    getEntity,
    getSubscribedAppsApiMap$,
    getSubscribedAppsMap$,
} from './apps.selector';
import {
    getInstance$,
    getInstancesOrgServicesMap$,
    getSearchInstancesMap$,
    getSelectedInstancesMap$,
    getSelectedProcessStatus$,
    getServiceInstancesMap$,
    getServicesInstancesMap$,
    hasInstanceEntityErrors$,
    hasInstanceErrors$,
} from './instances.selector';
import { getRecords$, getRecordsOrg$ } from './records.selector';
import { getRequests$, getRequestsOrgServicesMap$, getServiceRequestsMap$ } from './requests.selector';
import { getCurrentOrganizationId$ } from './session.selector';
import { getGroupsMap$ } from './shared.selector';
import { getTagsObject$, getTagValueVsServiceIds$ } from './tags.selector';
import { getInboundDetailedTransformations$ } from './transformations.selector';

const currentState = createFeatureSelector<GSTFilingState>(GST_FILING_REDUCER_KEY);

export const currentFilingYear$ = createSelector(currentState, (state) => state.currentYear);

export const currentFilingMonth$ = createSelector(currentState, (state) => state.currentMonth);

export const currentFilingQuarter$ = createSelector(currentState, (state) => state.currentQuarter);

export const currentFilingAppGroup$ = createSelector(currentState, (state) => state.currentAppGroup);

export const getGSTFilingCriteria$ = createSelector(
    currentFilingYear$,
    currentFilingMonth$,
    currentFilingQuarter$,
    (year, month, quarter) => `${year || ''}|${month || ''}|${quarter || ''}`
);

export const getFilingSearchInstanceIds$ = (serviceId: string, year?: string, month?: string, quarter?: string, otpApp?: boolean) =>
    createSelector(getSearchInstancesMap$(serviceId), getGSTFilingCriteria$, (instancesMap, criteria) => {
        let newCriteria = otpApp
            ? '||'
            : year?.length > 0 && (month?.length > 0 || quarter?.length > 0)
            ? `${year || ''}|${month || ''}|${quarter || ''}`
            : criteria;
        return instancesMap?.[newCriteria];
    });

export const getInstancesMapCriteriaLength$ = (serviceId: string) =>
    createSelector(
        getFilingSearchInstanceIds$(serviceId),
        getSelectedProcessStatus$(serviceId),
        getFilingInstancesByStatus$(serviceId),
        (instancesMapCriteria, selectedProcessStatus, filingInstancesByStatus) => {
            if (selectedProcessStatus === undefined) {
                return instancesMapCriteria?.length || 0;
            } else {
                return filingInstancesByStatus?.length || 0;
            }
        }
    );

export const getClearanceRunNumber$ = (serviceId: string, instanceId: string) =>
    createSelector(getFilingSearchInstanceIds$(serviceId), (instanceIds) => (instanceIds?.slice(0).reverse().indexOf(instanceId) || 0) + 1);

export const getFilingSearchRequest$ = (serviceId: string) =>
    createSelector(getRequestsOrgServicesMap$, getRequests$, (orgRequestsMap, requests) => {
        return requests?.[orgRequestsMap?.[serviceId]?.searchedRequestIds?.['||']?.[0]];
    });

export const getFilingInstanceGSTN$ = (instanceId: string, serviceId: string) =>
    createSelector(getInstance$(instanceId), getAssetGSTFilingGSTINField$(serviceId), (instance, field): string => {
        return instance?.processViewAttributes?.[field?.uid];
    });

export const getAssetGSTFilingGSTINField$ = (serviceId: string) =>
    createSelector(getAppPrimaryEntity$(serviceId), (entity) =>
        entity?.fields
            .filter((field) => field.tagEntries?.length > 0)
            .find((field) => field.tagEntries.find((tag) => tag.key === 'gst-filing-field' && tag.value === 'gstin'))
    );

export const getGSTFilingIdentifierField$ = (serviceId: string) =>
    createSelector(getAppPrimaryEntity$(serviceId), (entity) =>
        entity?.fields
            .filter((field) => field.tagEntries?.length > 0)
            .find((field) => field.tagEntries.find((tag) => tag.key === 'gst-filing-field' && tag.value === 'identifier'))
    );

export const getGSTFilingDescriptionField$ = (serviceId: string) =>
    createSelector(getAppPrimaryEntity$(serviceId), (entity) =>
        entity?.fields
            .filter((field) => field.tagEntries?.length > 0)
            .find((field) => field.tagEntries.find((tag) => tag.key === 'gst-filing-field' && tag.value === 'description'))
    );

export const getAssetGSTFilingStateNameField$ = (serviceId: string) =>
    createSelector(getAppPrimaryEntity$(serviceId), (entity) =>
        entity?.fields
            .filter((field) => field.tagEntries?.length > 0)
            .find((field) => field.tagEntries.find((tag) => tag.key === 'gst-filing-field' && tag.value === 'statename'))
    );

export const getFilingInstanceStateName$ = (instanceId: string, serviceId: string) =>
    createSelector(getInstance$(instanceId), getAssetGSTFilingStateNameField$(serviceId), (instance, field): string => {
        return instance?.processViewAttributes?.[field?.uid];
    });

export const getFilingState$ = (instanceId: string, serviceId: string) =>
    createSelector(
        getFilingInstanceGSTN$(instanceId, serviceId),
        getFilingInstanceStateName$(instanceId, serviceId),
        (code, stateName): string => {
            return stateName || CommonUtilsService.gstnStateCode[code?.substring(0, 2)];
        }
    );

export const getFilingInstanceIdentifier$ = (instanceId: string, serviceId: string) =>
    createSelector(getInstance$(instanceId), getGSTFilingIdentifierField$(serviceId), (instance, field): string => {
        return instance?.processViewAttributes?.[field?.uid];
    });

export const getFilingInstanceDescription$ = (instanceId: string, serviceId: string) =>
    createSelector(getInstance$(instanceId), getGSTFilingDescriptionField$(serviceId), (instance, field): string => {
        return instance?.processViewAttributes?.[field?.uid];
    });

export const getGroupIds$ = createSelector(getGroupsMap$, (groups) => Object.keys(groups || {}));

export const getFilteredGSTFilingAppConstants$ = createSelector(
    getGroupsMap$,
    currentFilingAppGroup$,
    (groups, selectedGroupName) => groups?.[selectedGroupName]
);

export const getGSTFilingApps$ = createSelector(getFilteredGSTFilingAppConstants$, getSubscribedAppsMap$, (consts, appsMap) => {
    return consts?.reduce((apps, app) => {
        const service: AssetService = appsMap?.[app.serviceId];
        service && apps.push(service);
        return apps;
    }, [] as AssetService[]);
});

export const getOTPAppByReference$ = createSelector(
    getGSTFilingApps$,
    getAppConfiguratorsMap$,
    getSubscribedAppsApiMap$,
    (apps, configuratorMap, allAppsMap) => {
        let referenceAppDetails: string;
        apps?.find((app) => {
            referenceAppDetails =
                configuratorMap?.[app.serviceId]?.[app.assetMetaUId]?.wrapperUI?.referenceApps?.otpAppDetails?.appRestApiName;
            return !!referenceAppDetails;
        });
        return allAppsMap?.[referenceAppDetails];
    }
);

export const getOTPAppConfigurator$ = createSelector(
    getOTPAppByReference$,
    getAppConfiguratorsMap$,
    (apps, configuratorMap) => configuratorMap?.[apps?.serviceId]?.[apps?.assetMetaUId]?.wrapperUI?.otpAppView
);

export const getFilingAppByTagValue$ = (tagValue: string) =>
    createSelector(getTagsObject$, (tagsMap) => {
        return (tagsMap?.[`gst-filing-app||APPROVED`] || []).find((app) => app.tagValue === tagValue);
    });

export const getFilingAppServiceIdByTagValue$ = (tagValue: string) =>
    createSelector(getFilingAppByTagValue$(tagValue), (app) => app?.serviceId);

export const getFilingAppServiceByTagValue$ = (tagValue: string) =>
    createSelector(getFilingAppServiceIdByTagValue$(tagValue), getSubscribedAppsMap$, (serviceId, appsMap) => appsMap?.[serviceId]);

export const getGSTFilingAppsMap$ = createSelector(getGSTFilingApps$, (apps) =>
    apps?.reduce((appsMap, app) => {
        appsMap[app.serviceId] = app;
        return appsMap;
    }, {} as { [property: string]: AssetService })
);

export const getGSTFilingApp$ = (serviceId: string) =>
    createSelector(getGSTFilingApps$, (apps) => apps?.find((app) => app.serviceId === serviceId));

export const getFilingInstanceIdsByUpdated$ = (serviceId: string, otpApp?: boolean) =>
    createSelector(
        getFilingSearchInstanceIds$(serviceId, undefined, undefined, undefined, otpApp),
        getServicesInstancesMap$,
        (instanceIds, instancesMap) => {
            return instanceIds?.slice(0).sort((a, b) => {
                const aDate = new Date(instancesMap[a].updateOn).getTime();
                const bDate = new Date(instancesMap[b].updateOn).getTime();
                return bDate - aDate;
            });
        }
    );

export const getFilingInstanceIdsWithContext$ = (serviceId: string, otpApp?: boolean) =>
    createSelector(
        getFilingInstanceIdsByUpdated$(serviceId, otpApp),
        currentFilingYear$,
        currentFilingMonth$,
        currentFilingQuarter$,
        (instanceIds, year, month, quarter) => {
            return {
                instanceIds: instanceIds,
                year: year,
                month: month,
                quarter: quarter,
            };
        }
    );

export const getOTPAuthApp$ = createSelector(
    getOTPAppByReference$,
    getTagValueVsServiceIds$('gstin-otp-authentication', ''),
    getSubscribedAppsMap$,
    (otpAppByReference, tagsMap, appsMap) => {
        return otpAppByReference || appsMap?.[tagsMap?.['gstin']];
    }
);

export const getOTPAuthAppMetaData$ = createSelector(
    getOTPAuthApp$,
    getAppsMetaDataMap$,
    (otpApp, metaDataMap) => metaDataMap?.[otpApp?.serviceId]?.[otpApp?.assetMetaUId]
);

const getOTPAppExpiryField$ = createSelector(getOTPAuthAppMetaData$, getOTPAppConfigurator$, (metaDataMap, configurator) =>
    metaDataMap?.entities?.[0]?.fields?.find(
        (field) =>
            field.uid === configurator?.expiryFieldId ||
            field.tagEntries?.find((tag) => tag.value === 'timestamp' && tag.key === 'gst-filing-field')
    )
);

const getOTPActiveField$ = createSelector(getOTPAuthAppMetaData$, getOTPAppConfigurator$, (metaDataMap, configurator) =>
    metaDataMap?.entities?.[0]?.fields?.find(
        (field) =>
            field.uid === configurator?.activeFieldId ||
            field.tagEntries?.find((tag) => tag.value === 'active' && tag.key === 'gst-filing-field')
    )
);

export const getOTPAppUserNameField$ = createSelector(getOTPAuthAppMetaData$, getOTPAppConfigurator$, (metaDataMap, configurator) =>
    metaDataMap?.entities?.[0]?.fields?.find(
        (field) =>
            field.uid === configurator?.userNameFieldId ||
            field.tagEntries?.find((tag) => tag.value === 'username' && tag.key === 'gst-filing-field')
    )
);

export const getOTPAppOTPField$ = createSelector(getOTPAuthAppMetaData$, getOTPAppConfigurator$, (metaDataMap, configurator) =>
    metaDataMap?.entities?.[0]?.fields?.find(
        (field) =>
            field.uid === configurator?.passwordFieldId ||
            field.tagEntries?.find((tag) => tag.value === 'otp' && tag.key === 'gst-filing-field')
    )
);

export const getOTPInstanceId$ = (gstin: string) =>
    createSelector(
        getInstancesOrgServicesMap$,
        getOTPAuthApp$,
        (instancesMap, otpApp) => instancesMap?.[otpApp?.serviceId]?.searchedInstances?.[gstin]?.[0]
    );

export const getOTPInstance$ = (gstin: string) =>
    createSelector(getOTPInstanceId$(gstin), getServicesInstancesMap$, (instanceId, instancesMap) => instancesMap?.[instanceId]);

export const getOTPRecordId$ = (gstin: string) =>
    createSelector(
        getRecordsOrg$,
        getOTPAuthApp$,
        getInstancesOrgServicesMap$,
        getOTPAuthAppMetaData$,
        (recordOrgMap, app, instancesMap, metaData) => {
            const instanceId = instancesMap?.[app?.serviceId]?.searchedInstances?.[gstin]?.[0];
            const primaryEntity = metaData?.entities?.[0];
            return recordOrgMap?.[app?.serviceId]?.[instanceId]?.[`|${primaryEntity?.uid}`]?.recordIds?.[0];
        }
    );

export const getOTPRecordLoading$ = (gstin: string) =>
    createSelector(
        getRecordsOrg$,
        getOTPAuthApp$,
        getInstancesOrgServicesMap$,
        getOTPAuthAppMetaData$,
        (recordOrgMap, app, instancesMap, metaData) => {
            const instanceId = instancesMap?.[app?.serviceId]?.searchedInstances?.[gstin]?.[0];
            const primaryEntity = metaData?.entities?.[0];
            return recordOrgMap?.[app?.serviceId]?.[instanceId]?.[`|${primaryEntity?.uid}`]?.loading;
        }
    );

export const getOTPRecord$ = (gstin: string) =>
    createSelector(getOTPRecordId$(gstin), getRecords$, (recordId, recordsMap) => recordsMap?.[recordId]);

export const getOTPExpiryTimeStampField$ = (gstin: string) =>
    createSelector(getOTPRecord$(gstin), getOTPAppExpiryField$, (record, metaField) => {
        const recordField = record?.fields?.find((field) => field.id === metaField?.uid);
        return recordField;
    });

export const getOTPActiveStatusField$ = (gstin: string) =>
    createSelector(getOTPRecord$(gstin), getOTPActiveField$, (record, metaField) => {
        const recordField = record?.fields?.find((field) => field.id === metaField?.uid);
        return recordField;
    });

export const getOTPUserNameField$ = (gstin: string) =>
    createSelector(getOTPRecord$(gstin), getOTPAppUserNameField$, (record, metaField) => {
        return record?.fields?.find((field) => field.id === metaField?.uid);
    });

export const getOTField$ = (gstin: string) =>
    createSelector(getOTPRecord$(gstin), getOTPAppOTPField$, (record, metaField) => {
        const recordField = record?.fields?.find((field) => field.id === metaField?.uid);
        return recordField;
    });

export const getOTPExpiryTime$ = (gstin: string) =>
    createSelector(getOTPExpiryTimeStampField$(gstin), (field) => {
        const date = field?.value && new Date(parseInt(field.value));
        return date && CommonUtilsService.transformDateToLocale(date as any, 'DATE', 'ddMMMyyyy HH:MM:SS AM', true);
    });

export const getOTPActiveStatus$ = (gstin: string) =>
    createSelector(getOTPActiveStatusField$(gstin), getOTPExpiryTimeStampField$(gstin), (field, timestampField): string => {
        if (field?.id) {
            return field?.value;
        }
        const currentTimeStamp = new Date().getTime();
        return timestampField && parseInt(timestampField.value) > currentTimeStamp ? 'Active' : 'Inactive';
    });

const getReconContextCriteria = (year: string, month: string) => {
    year = year || '' + new Date().getFullYear();
    const dateMonth = new Date().getMonth() + 1;
    const date = dateMonth > parseInt(month) ? new Date(parseInt(year), dateMonth, 0).getDate() + '' : new Date().getDate() + '';
    const fromDate = (year && month && `${year}-${month?.length === 1 ? `0${month}` : month}-01`) || '';
    let toDate = (year && month && `${year}-${month?.length === 1 ? `0${month}` : month}-${date.length === 1 ? `0${date}` : date}`) || '';
    if (year && month && (CommonUtilsService.transformDateToLocale(toDate, 'yyyy-mm-dd', 'DATE', true) as any) > new Date()) {
        toDate = CommonUtilsService.transformDateToLocale(new Date() as any, 'DATE', 'yyyy-MM-dd', true) as string;
    }
    return `${fromDate}|${toDate}`;
};

export const getFilingReconRequestIds$ = (serviceId: string) =>
    createSelector(
        getServiceRequestsMap$(serviceId),
        currentFilingYear$,
        currentFilingMonth$,
        (requestsMap, year, month) => requestsMap?.searchedRequestIds?.[getReconContextCriteria(year, month)]
    );

export const getFilingReconRequestsCount$ = (serviceId: string) =>
    createSelector(getFilingReconRequestIds$(serviceId), (requestIds) => requestIds?.length || 0);

export const getFilingReconRequest$ = (serviceId: string) =>
    createSelector(getFilingReconRequestIds$(serviceId), getRequests$, (requestIds, requests) => {
        return requests?.[requestIds?.[0]];
    });

export const getFilingReconRequestIdsByPage$ = (serviceId: string) =>
    createSelector(getFilingReconRequestIds$(serviceId), getFilingReconPaginationMap$(serviceId), (requestIds, pageMap) => {
        const start = (pageMap?.pageIndex || 0) * (pageMap?.pageSize || 0);
        const end = start + (pageMap?.pageSize || 0);
        return requestIds?.filter((_id, index) => end === 0 || (index >= start && index < end));
    });

export const getFilingReconOrganization$ = createSelector(
    currentState,
    getCurrentOrganizationId$,
    (state, organizationId) => state.reconPagination?.[organizationId]
);

const getFilingReconService$ = (serviceId: string) => createSelector(getFilingReconOrganization$, (orgMap) => orgMap?.[serviceId]);

export const getFilingReconPaginationMap$ = (serviceId: string) =>
    createSelector(
        getFilingReconService$(serviceId),
        currentFilingYear$,
        currentFilingMonth$,
        (serviceMap, year, month) => serviceMap?.[getReconContextCriteria(year, month)]
    );

export const getSelectedFilingInstanceIds$ = (serviceId: string, year?: string, month?: string, quarter?: string) =>
    createSelector(getGSTFilingCriteria$, getSelectedInstancesMap$(serviceId), (criteria, instancesMap) => {
        let newCriteria = year && (month || quarter) ? `${year || ''}|${month || ''}|${quarter || ''}` : criteria;
        return instancesMap?.[newCriteria] || [];
    });

export const selectedHasCompletedFilingInstances$ = (serviceId: string) =>
    createSelector(
        getSelectedFilingInstanceIds$(serviceId),
        getServicesInstancesMap$,
        (instanceIds, instances) => instanceIds?.find((id) => instances?.[id]?.instanceState === 'REQUEST_COMPLETED') !== undefined
    );

export const getFilingProcessStatuses$ = (serviceId: string) =>
    createSelector(getFilingSearchInstanceIds$(serviceId), getServicesInstancesMap$, (instanceIds, instances) => {
        let statuses: string[] = [];
        instanceIds?.forEach((id) => statuses.push(instances?.[id]?.instanceStatus));
        return [...new Set(statuses.sort())];
    });

export const getFilingProcessStatusesByCriteria$ = (serviceId: string, year?: string, month?: string, quarter?: string) =>
    createSelector(
        currentFilingYear$,
        currentFilingMonth$,
        currentFilingQuarter$,
        getServiceInstancesMap$(serviceId),
        (storeYear, storeMonth, storeQuarter, serviceInstance) => {
            let criteria =
                year?.length > 0 && (month?.length > 0 || quarter?.length > 0)
                    ? `${year || ''}|${month || ''}|${quarter || ''}`
                    : `${storeYear || ''}|${storeMonth || ''}|${storeQuarter || ''}`;
            return serviceInstance?.instancesByStatus?.[criteria];
        }
    );

export const getFilingInstancesByStatus$ = (serviceId: string, year?: string, month?: string, quarter?: string) =>
    createSelector(
        getSelectedProcessStatus$(serviceId),
        getFilingProcessStatusesByCriteria$(serviceId, year, month, quarter),
        (selectedProcessStatus, processStatusesByCriteria) => processStatusesByCriteria?.[selectedProcessStatus]
    );

export const getFilingTransformations$ = (serviceId) =>
    createSelector(getInboundDetailedTransformations$(serviceId), (organizationsMap) => {
        let organizationsList: {
            orgName: string;
            transformations: Transformation[];
            repositoryId: string;
        }[] = [];
        organizationsMap.forEach((orgMap) => {
            const isActiveInboundTrsExists = !!orgMap.transformations.find((trans) => trans.active);
            const transformations = orgMap.transformations.filter(
                (transformation) =>
                    (isActiveInboundTrsExists && transformation.active && (transformation.isFileUpload || transformation.isSearchAsset)) ||
                    (!isActiveInboundTrsExists && (transformation.isFileUpload || transformation.isSearchAsset))
            );
            transformations?.length > 0 &&
                organizationsList.push({
                    ...orgMap,
                    transformations,
                });
        });
        return organizationsList.sort((a, b) => {
            if (a.repositoryId === '111') {
                return 1;
            }
            if (b.repositoryId === '111') {
                return -1;
            }
            return 0;
        });
    });

export const doesFilingInstanceHaveErrors$ = (instanceId: string) =>
    createSelector(
        hasInstanceEntityErrors$(instanceId),
        hasInstanceErrors$(instanceId),
        getInstance$(instanceId),
        (entityHasErrors, instanceHasErrors, instance) => entityHasErrors || instanceHasErrors || instance?.errorStatus?.length > 0
    );

export const getFilingSelectedInstanceDetails$ = createSelector(currentState, (state) => state.selected);

export const getFilingSelectedServiceId$ = createSelector(getFilingSelectedInstanceDetails$, (selected) => selected.serviceId);

export const getFilingSelectedAssetId$ = createSelector(
    getFilingSelectedServiceId$,
    getSubscribedAppsMap$,
    (serviceId, appsMap) => appsMap?.[serviceId]?.assetMetaUId
);

export const getFilingSelectedInstanceId$ = createSelector(getFilingSelectedInstanceDetails$, (selected) => selected.instanceId);

export const getFilingSelectedInstanceViewMap$ = createSelector(getFilingSelectedInstanceDetails$, (selected) => selected.viewLevel);

export const getFilingSelectedEntityDetails$ = createSelector(getFilingSelectedInstanceDetails$, (selected) => {
    return {
        ...selected?.viewLevel?.[selected.currentViewLevel],
        level: selected.currentViewLevel,
    };
});

export const getFilingParentEntityDetails$ = createSelector(getFilingSelectedInstanceDetails$, (selected) => {
    return {
        ...selected?.viewLevel?.[selected?.currentViewLevel - 1],
        level: selected?.currentViewLevel - 1,
    };
});

export const getFilingSelectedParentRecordId$ = createSelector(getFilingParentEntityDetails$, (parentView) => parentView?.recordId);

export const getFilingSelectedEntityId$ = createSelector(getFilingSelectedEntityDetails$, (view) => view?.entityId);

export const getFilingSelectedRecordId$ = createSelector(getFilingSelectedEntityDetails$, (view) => view?.recordId);

export const getFilingSelectedAppMetaData$ = createSelector(
    getFilingSelectedServiceId$,
    getFilingSelectedAssetId$,
    getAppsMetaDataMap$,
    getAppsTemplate$,
    getAppConfiguratorsMap$,
    (serviceId, assetId, metaDataMap, appsTemplateMap, appConfiguratorsMap) => {
        const appTemplate = appsTemplateMap?.[serviceId]?.uiTemplate;
        const meta = JSON.parse(JSON.stringify(metaDataMap?.[serviceId]?.[assetId] || {}));
        const template = JSON.parse(JSON.stringify((appTemplate as any) || {}));
        const assetData = new AssetData(meta, template);
        assetData.configurator = appConfiguratorsMap?.[serviceId]?.[assetId];
        appTemplate?.uid && assetData.configurator && meta?.uid && (assetData.templateUpdated = true);
        return assetData;
    }
);

export const getFilingSelectedEntity$ = createSelector(getFilingSelectedEntityDetails$, getFilingSelectedAppMetaData$, (view, metaData) => {
    const entity = getEntity(metaData?.entities || [], view?.entityId) as any as Entity;
    return entity;
});

export const getFilingNonSelectedEntity$ = createSelector(
    getFilingSelectedEntityDetails$,
    getFilingSelectedAppMetaData$,
    (view, metaData) => {
        return metaData?.entities?.filter((entity) => entity.uid !== view.entityId);
    }
);

const getFilingRecordsOrgMap$ = createSelector(currentState, getRecordsOrg$, (state, recordOrgsMap) => {
    const { serviceId, instanceId } = state?.selected;
    return recordOrgsMap?.[serviceId]?.[instanceId];
});

const getFilingRecordsEntityMap$ = createSelector(
    getFilingRecordsOrgMap$,
    getFilingSelectedParentRecordId$,
    getFilingSelectedEntityId$,
    (recordOrgsMap, parentRecordId, entityId) => {
        return recordOrgsMap?.[`${parentRecordId || ''}|${entityId}`];
    }
);

export const getFilingRecordsSearchCriteria$ = createSelector(getFilingRecordsEntityMap$, (recordsMap) => recordsMap?.searchCriteria);

export const getFilingRecordsPagination$ = createSelector(getFilingRecordsEntityMap$, (recordsMap) => {
    return {
        pageSize: recordsMap?.pageSize || 20,
        pageIndex: recordsMap?.currentPageIndex || 0,
    };
});

export const getFilingEntityNonPaginatedRecordIds$ = createSelector(getFilingRecordsEntityMap$, (recordOrgsMap) => {
    return recordOrgsMap?.searchCriteria ? recordOrgsMap?.searchRecordIds : recordOrgsMap?.recordIds;
});

export const getFilingEntityRecordIds$ = createSelector(
    getFilingEntityNonPaginatedRecordIds$,
    getFilingRecordsPagination$,
    (recordIds, pagination) => {
        const start = pagination.pageIndex * pagination.pageSize;
        const end = (pagination.pageIndex + 1) * pagination.pageSize;
        return recordIds?.filter((_id, index) => index >= start && index < end);
    }
);

export const getFilingEntitySearchRecordIds$ = createSelector(
    getFilingRecordsEntityMap$,
    getFilingRecordsPagination$,
    (recordOrgsMap, pagination) => {
        const start = pagination.pageIndex * pagination.pageSize;
        const end = (pagination.pageIndex + 1) * pagination.pageSize;
        return recordOrgsMap?.searchRecordIds?.filter((_id, index) => index >= start && index < end);
    }
);

export const getFilingEntityRecordsCount$ = createSelector(
    getFilingRecordsEntityMap$,
    getFilingRecordsPagination$,
    (recordOrgsMap, pagination) => {
        const recordsCount =
            (recordOrgsMap?.searchCriteria ? recordOrgsMap?.searchRecordIds?.length : recordOrgsMap?.recordIds?.length) || 0;
        const pageSizeCount = pagination.pageSize * (pagination.pageIndex + 1);
        if (recordsCount > pageSizeCount) {
            return recordsCount;
        }
        if (recordsCount === pageSizeCount) {
            return recordsCount + 1;
        }
        return recordsCount;
    }
);

export const getFilingEntityRecords$ = createSelector(getFilingEntityRecordIds$, getRecords$, (recordIds, records) => {
    return recordIds?.reduce((entityRecords, recordId) => {
        const record = records[recordId];
        record && entityRecords.push(record);
        return entityRecords;
    }, []);
});

export const getFilingPrimaryEntityRecordIds$ = createSelector(
    currentState,
    getRecordsOrg$,
    getFilingSelectedAppMetaData$,
    (state, recordOrgsMap, metaData) => {
        const { serviceId, instanceId } = state?.selected;
        const entityId = metaData?.entities?.find((entity) => entity?.primary)?.uid;
        const recordIds = recordOrgsMap?.[serviceId]?.[instanceId]?.[`|${entityId}`]?.recordIds;
        return recordIds;
    }
);

export const getFilingPrimaryEntityRecords$ = createSelector(getFilingPrimaryEntityRecordIds$, getRecords$, (recordIds, records) => {
    return recordIds?.reduce((entityRecords, recordId) => {
        const record = records[recordId];
        record && entityRecords.push(record);
        return entityRecords;
    }, []);
});

export const getFilingInstanceRecordsStatus$ = createSelector(
    getRecordsOrg$,
    getFilingSelectedInstanceDetails$,
    getFilingParentEntityDetails$,
    getFilingSelectedEntityDetails$,
    (recordsOrgMap, instanceDetails, parentView, currentView) => {
        const loading =
            recordsOrgMap?.[instanceDetails?.serviceId]?.[instanceDetails?.instanceId]?.[
                `${parentView?.recordId || ''}|${currentView?.entityId}`
            ]?.loading;
        return loading || loading === undefined;
    }
);

const getFilingSource1Map$ = createSelector(getFilingRecordsEntityMap$, (entityMap) => entityMap?.reconSpecific?.source1);

export const getFilingSource1RecordsPagination$ = createSelector(getFilingSource1Map$, (recordsMap) => {
    return {
        pageSize: recordsMap?.pageSize || 20,
        pageIndex: recordsMap?.currentPageIndex || 0,
    };
});

const getFilingSource1RecordsIds$ = createSelector(getFilingSource1Map$, getFilingSource1RecordsPagination$, (sourceMap, pagination) => {
    const start = pagination.pageIndex * pagination.pageSize;
    const end = (pagination.pageIndex + 1) * pagination.pageSize;
    return sourceMap?.recordIds?.filter((_id, index) => index >= start && index < end);
});

export const getFilingSource1Records$ = createSelector(getFilingSource1RecordsIds$, getRecords$, (recordIds, records) => {
    return recordIds?.reduce((entityRecords, recordId) => {
        const record = records[recordId];
        record && entityRecords.push(record);
        return entityRecords;
    }, []);
});

export const getFilingSource1RecordsCount$ = createSelector(
    getFilingSource1Map$,
    getFilingSource1RecordsPagination$,
    (recordOrgsMap, pagination) => {
        const recordsCount = recordOrgsMap?.recordIds?.length || 0;
        const pageSizeCount = (pagination?.pageSize || 0) * ((pagination?.pageIndex || 0) + 1);
        if (recordsCount > pageSizeCount) {
            return recordsCount;
        }
        if (recordsCount === pageSizeCount) {
            return recordsCount + 1;
        }
        return recordsCount;
    }
);

const getFilingSource2Map$ = createSelector(getFilingRecordsEntityMap$, (entityMap) => entityMap?.reconSpecific?.source2);

export const getFilingSource2RecordsPagination$ = createSelector(getFilingSource2Map$, (recordsMap) => {
    return {
        pageSize: recordsMap?.pageSize || 20,
        pageIndex: recordsMap?.currentPageIndex || 0,
    };
});

const getFilingSource2RecordsIds$ = createSelector(getFilingSource2Map$, getFilingSource2RecordsPagination$, (sourceMap, pagination) => {
    const start = pagination.pageIndex * pagination.pageSize;
    const end = (pagination.pageIndex + 1) * pagination.pageSize;
    return sourceMap?.recordIds?.filter((_id, index) => index >= start && index < end);
});

export const getFilingSource2Records$ = createSelector(getFilingSource2RecordsIds$, getRecords$, (recordIds, records) => {
    return recordIds?.reduce((entityRecords, recordId) => {
        const record = records[recordId];
        record && entityRecords.push(record);
        return entityRecords;
    }, []);
});

export const getFilingSource2RecordsCount$ = createSelector(
    getFilingSource2Map$,
    getFilingSource2RecordsPagination$,
    (recordOrgsMap, pagination) => {
        const recordsCount = recordOrgsMap?.recordIds?.length || 0;
        const pageSizeCount = (pagination?.pageSize || 0) * ((pagination?.pageIndex || 0) + 1);
        if (recordsCount > pageSizeCount) {
            return recordsCount;
        }
        if (recordsCount === pageSizeCount) {
            return recordsCount + 1;
        }
        return recordsCount;
    }
);

const getFilingMainAssociationField$ = createSelector(getFilingSelectedEntity$, (entity) =>
    entity?.fields?.find((field) => field?.tagEntries?.find((tag) => tag.key === 'association' && tag.value === 'main'))
);

const getReconcileAssociationField$ = createSelector(
    getFilingSelectedAppMetaData$,
    (metaData) => metaData?.tagEntries?.find((tag) => tag.key === 'association-id')?.value
);

const getReconcileConfiguratorAssociationField$ = createSelector(
    getFilingSelectedAppMetaData$,
    (metaData) => metaData?.configurator?.wrapperUI?.clearanceView?.associationFieldId
);

export const getFilingMainAssociationFieldId$ = createSelector(
    getReconcileConfiguratorAssociationField$,
    getFilingMainAssociationField$,
    getReconcileAssociationField$,
    (configuratorAssociationFieldId, field1, fieldId) => configuratorAssociationFieldId || field1?.uid || fieldId
);

const getFilingRecordsGroupedMap$ = createSelector(getFilingRecordsEntityMap$, (entityMap) => entityMap?.reconSpecific?.grouped);

export const getFilingGroupedRecordsPagination$ = createSelector(getFilingRecordsGroupedMap$, (recordsMap) => {
    return {
        pageSize: recordsMap?.pageSize || 20,
        pageIndex: recordsMap?.currentPageIndex || 0,
    };
});

const getFilingGroupedAssociationIds$ = createSelector(
    getRecords$,
    getFilingRecordsGroupedMap$,
    getFilingMainAssociationFieldId$,
    (records, sourceMap, associationFieldId) => {
        return sourceMap?.recordIds?.reduce(
            (objectMap, recordId) => {
                const associationId = records?.[recordId]?.fields?.find((field) => field.id === associationFieldId)?.value;
                associationId && !objectMap.associationIds.includes(associationId) && objectMap.associationIds.push(associationId);
                if (associationId && !objectMap.associationIdsMap[associationId]) {
                    objectMap.associationIdsMap[associationId] = [];
                }
                associationId && objectMap.associationIdsMap[associationId].push(recordId);
                return objectMap;
            },
            { associationIds: [], associationIdsMap: {} } as {
                associationIds: string[];
                associationIdsMap: { [property: string]: string[] };
            }
        );
    }
);

const getFilingPaginatedAssociationIds$ = createSelector(
    getFilingGroupedAssociationIds$,
    getFilingGroupedRecordsPagination$,
    (associationMap, pagination) => {
        const start = pagination.pageIndex * pagination.pageSize;
        const end = (pagination.pageIndex + 1) * pagination.pageSize;
        return {
            associationIds: associationMap?.associationIds?.filter((_id, index) => index >= start && index < end),
            associationIdsMap: associationMap?.associationIdsMap,
        };
    }
);

export const getFilingGroupedRecords$ = createSelector(getRecords$, getFilingPaginatedAssociationIds$, (recordsMap, associationMap) => {
    const ids = associationMap?.associationIds;
    return (
        ids?.reduce((records, id) => {
            associationMap?.associationIdsMap?.[id]?.forEach((recordId) => records.push(recordsMap[recordId] as any));
            return records;
        }, [] as Record[]) || []
    );
});

export const getFilingGroupedRecordsCount$ = createSelector(getFilingGroupedAssociationIds$, (recordOrgsMap) => {
    return recordOrgsMap?.associationIds?.length || 0;
});

export const getFilingNonDetachedGroupEntityId$ = createSelector(currentState, (state) => state.selected?.nonDetachedSourceEntityId);

export const getFilingDetachedGroupSource1EntityId$ = createSelector(currentState, (state) => state.selected?.detachedSource1EntityId);

export const getFilingDetachedGroupSource2EntityId$ = createSelector(currentState, (state) => state.selected?.detachedSource2EntityId);

export const getFilingNonDetachedEntity$ = createSelector(
    getFilingNonDetachedGroupEntityId$,
    getFilingSelectedAppMetaData$,
    (entityUid, metaData) => getEntity(metaData?.entities || [], entityUid) as any as Entity
);

const getFilingNonDetachedEntityMap$ = createSelector(
    getFilingRecordsOrgMap$,
    getFilingNonDetachedGroupEntityId$,
    (recordOrgsMap, entityId) => {
        return recordOrgsMap?.[`|${entityId}`];
    }
);

const getFilingNonDetachedRecordsGroupMap$ = createSelector(
    getFilingNonDetachedEntityMap$,
    (entityMap) => entityMap?.reconSpecific?.grouped
);

const getFilingNonDetachedGroupAssociationIds$ = createSelector(
    getRecords$,
    getFilingNonDetachedRecordsGroupMap$,
    getFilingMainAssociationFieldId$,
    (records, sourceMap, associationFieldId) => {
        return sourceMap?.recordIds?.reduce(
            (objectMap, recordId) => {
                const associationId = records?.[recordId]?.fields?.find((field) => field.id === associationFieldId)?.value;
                associationId && !objectMap.associationIds.includes(associationId) && objectMap.associationIds.push(associationId);
                if (associationId && !objectMap.associationIdsMap[associationId]) {
                    objectMap.associationIdsMap[associationId] = [];
                }
                associationId && objectMap.associationIdsMap[associationId].push(recordId);
                return objectMap;
            },
            { associationIds: [], associationIdsMap: {} } as {
                associationIds: string[];
                associationIdsMap: { [property: string]: string[] };
            }
        );
    }
);

export const getFilingNonDetachedGroupRecordsPagination$ = createSelector(getFilingNonDetachedRecordsGroupMap$, (recordsMap) => {
    return {
        pageSize: recordsMap?.pageSize || 20,
        pageIndex: recordsMap?.currentPageIndex || 0,
    };
});

const getFilingPaginatedNonDetachedGroupAssociationIds$ = createSelector(
    getFilingNonDetachedGroupAssociationIds$,
    getFilingNonDetachedGroupRecordsPagination$,
    (associations, pagination) => {
        const start = pagination.pageIndex * pagination.pageSize;
        const end = (pagination.pageIndex + 1) * pagination.pageSize;
        return {
            ...associations,
            associationIds: associations?.associationIds?.filter((_id, index) => index >= start && index < end),
        };
    }
);

export const getFilingNonDetachedGroupRecords$ = createSelector(
    getRecords$,
    getFilingPaginatedNonDetachedGroupAssociationIds$,
    (recordsMap, associationMap) => {
        const ids = associationMap?.associationIds;
        return (
            ids?.reduce((records, id) => {
                associationMap?.associationIdsMap?.[id]?.forEach((recordId) => records.push(recordsMap[recordId] as any));
                return records;
            }, [] as Record[]) || []
        );
    }
);

export const getFilingNonDetachedGroupRecordsCount$ = createSelector(
    getFilingNonDetachedGroupAssociationIds$,
    getFilingNonDetachedGroupRecordsPagination$,
    (recordOrgsMap, pagination) => {
        const recordsCount = recordOrgsMap?.associationIds?.length || 0;
        const pageSizeCount = (pagination?.pageSize || 0) * ((pagination?.pageIndex || 0) + 1);
        if (recordsCount > pageSizeCount) {
            return recordsCount;
        }
        if (recordsCount === pageSizeCount) {
            return recordsCount + 1;
        }
        return recordsCount;
    }
);

const getFilingDetachedSource1RecordsEntityMap$ = createSelector(
    getFilingRecordsOrgMap$,
    getFilingDetachedGroupSource1EntityId$,
    (recordOrgsMap, entityId) => {
        return recordOrgsMap?.[`|${entityId}`];
    }
);

const getFilingDetachedSource1Map$ = createSelector(
    getFilingDetachedSource1RecordsEntityMap$,
    (entityMap) => entityMap?.reconSpecific?.source1
);

export const getFilingDetachedSource1RecordsPagination$ = createSelector(getFilingDetachedSource1Map$, (recordsMap) => {
    return {
        pageSize: recordsMap?.pageSize || 20,
        pageIndex: recordsMap?.currentPageIndex || 0,
    };
});

const getFilingDetachedSource1RecordsIds$ = createSelector(
    getFilingDetachedSource1Map$,
    getFilingDetachedSource1RecordsPagination$,
    (sourceMap, pagination) => {
        const start = pagination.pageIndex * pagination.pageSize;
        const end = (pagination.pageIndex + 1) * pagination.pageSize;
        return sourceMap?.recordIds?.filter((_id, index) => index >= start && index < end);
    }
);

export const getFilingDetachedSource1RecordsCount$ = createSelector(
    getFilingDetachedSource1Map$,
    getFilingDetachedSource1RecordsPagination$,
    (recordOrgsMap, pagination) => {
        const recordsCount = recordOrgsMap?.recordIds?.length || 0;
        const pageSizeCount = (pagination?.pageSize || 0) * ((pagination?.pageIndex || 0) + 1);
        if (recordsCount > pageSizeCount) {
            return recordsCount;
        }
        if (recordsCount === pageSizeCount) {
            return recordsCount + 1;
        }
        return recordsCount;
    }
);

export const getFilingDetachedSource1Records$ = createSelector(getFilingDetachedSource1RecordsIds$, getRecords$, (recordIds, records) => {
    return recordIds?.reduce((entityRecords, recordId) => {
        const record = records[recordId];
        record && entityRecords.push(record);
        return entityRecords;
    }, []);
});

const getFilingDetachedSource2RecordsEntityMap$ = createSelector(
    getFilingRecordsOrgMap$,
    getFilingDetachedGroupSource2EntityId$,
    (recordOrgsMap, entityId) => {
        return recordOrgsMap?.[`|${entityId}`];
    }
);

const getFilingDetachedSource2Map$ = createSelector(
    getFilingDetachedSource2RecordsEntityMap$,
    (entityMap) => entityMap?.reconSpecific?.source2
);

export const getFilingDetachedSource2RecordsPagination$ = createSelector(getFilingDetachedSource2Map$, (recordsMap) => {
    return {
        pageSize: recordsMap?.pageSize || 20,
        pageIndex: recordsMap?.currentPageIndex || 0,
    };
});

export const getFilingDetachedSource2RecordsCount$ = createSelector(
    getFilingDetachedSource2Map$,
    getFilingDetachedSource2RecordsPagination$,
    (recordOrgsMap, pagination) => {
        const recordsCount = recordOrgsMap?.recordIds?.length || 0;
        const pageSizeCount = (pagination?.pageSize || 0) * ((pagination?.pageIndex || 0) + 1);
        if (recordsCount > pageSizeCount) {
            return recordsCount;
        }
        if (recordsCount === pageSizeCount) {
            return recordsCount + 1;
        }
        return recordsCount;
    }
);

const getFilingDetachedSource2RecordsIds$ = createSelector(
    getFilingDetachedSource2Map$,
    getFilingDetachedSource2RecordsPagination$,
    (sourceMap, pagination) => {
        const start = pagination.pageIndex * pagination.pageSize;
        const end = (pagination.pageIndex + 1) * pagination.pageSize;
        return sourceMap?.recordIds?.filter((_id, index) => index >= start && index < end);
    }
);

export const getFilingDetachedSource2Records$ = createSelector(getFilingDetachedSource2RecordsIds$, getRecords$, (recordIds, records) => {
    return recordIds?.reduce((entityRecords, recordId) => {
        const record = records[recordId];
        record && entityRecords.push(record);
        return entityRecords;
    }, []);
});

export const getReconInstanceRecordsStatus$ = createSelector(
    getRecordsOrg$,
    getFilingSelectedInstanceDetails$,
    getFilingParentEntityDetails$,
    getFilingSelectedEntityDetails$,
    getFilingNonDetachedEntityMap$,
    getFilingDetachedSource1RecordsEntityMap$,
    getFilingDetachedSource1RecordsEntityMap$,
    (recordsOrgMap, instanceDetails, parentView, currentView, nonDetachedMap, source1Map, source2Map) => {
        const instanceMap = recordsOrgMap?.[instanceDetails?.serviceId]?.[instanceDetails?.instanceId];
        const loading = instanceMap?.[`${parentView?.recordId || ''}|${currentView?.entityId}`]?.loading;
        const nonDetachedLoading = nonDetachedMap?.loading;
        const source1Loading = source1Map?.loading;
        const source2Loading = source2Map?.loading;
        return loading || nonDetachedLoading || source1Loading || source2Loading;
    }
);
