import { Injectable } from '@angular/core';
import { environment } from '@env';
import { ApiService, UtilsService } from 'taxilla-library';

@Injectable({
    providedIn: 'root',
})
export class VersionCheckService {
    private currentMainHash?: string;
    private currentVendorHash?: string;
    public version?: string;

    constructor(private _api: ApiService, private _libUtils: UtilsService) {}

    public initVersionCheck(frequency: number = 1000 * 60 * 90): void {
        setInterval(() => this.checkVersion(), frequency);
        this.checkVersion();
    }

    private checkVersion(): void {
        this._api.common.getVersionJson(
            { url: environment['encomply-ui'] },
            {
                successCallback: (res) => {
                    const mainHashChanged = this.hasHashChanged(this.currentMainHash, res.mainHash);
                    const vendorHashChanged = this.hasHashChanged(this.currentVendorHash, res.vendorHash);
                    if (mainHashChanged || vendorHashChanged) {
                        this._libUtils.alertWarning('There is a new update available. Please refresh the page.', 10000);
                    }
                    this.currentMainHash = res.mainHash;
                    this.currentVendorHash = res.vendorHash;
                    this.version = res.version;
                },
                failureCallback: () => {
                    console.log('Could not get version.json');
                },
            }
        );
    }

    private hasHashChanged(currentHash?: string, newHash?: string): boolean {
        return currentHash !== undefined && currentHash !== newHash;
    }
}
