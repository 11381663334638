import { createAction, props } from '@ngrx/store';

import { Instance } from '../../models/instance.interface';

enum actionTypes {
    GET_INSTANCE = '[INSTANCES] Get Instance',
    SET_INSTANCE = '[INSTANCES] Set Instance',
    SET_PAGINATION = '[INSTANCES] Set Pagination',
    SET_PAGING_STATE = '[INSTANCES] Set Paging State',
    CLEAR_PAGINATION = '[INSTANCES] Clear Pagination',
    CLEAR_SEARCH_INSTANCES = '[INSTANCES] Clear Search Instances',
    SEARCH_INSTANCES = '[INSTANCES] Search Instances',
    SEARCH_FILING_INSTANCES = '[INSTANCES] Get Search Instances',
    GET_OTP_INSTANCES = '[INSTANCES] Get OTP Instances',
    SET_SEARCH_INSTANCES_RESPONSE = '[INSTANCES] Set Search Instances Response',
    GET_INSTANCE_STATE = '[INSTANCES] Get Instance State',
    SET_INSTANCE_STATE = '[INSTANCES] Set Instance Errors',
    SET_INSTANCE_ENTITY_STATE = '[INSTANCES] Set Instance Entity State',
    CLEAR_INSTANCE_DATA = '[INSTANCES] Clear Data',
    CANCEL_INSTANCES = '[INSTANCES] Cancel',
    REMOVE_INSTANCES = '[INSTANCES] Remove',
    SELECT_INSTANCES = '[INSTANCES] Select',
    GET_PROCESS_STATUSES = '[INSTANCES] Get Process Status',
    SET_PROCESS_STATUSES = '[INSTANCES] Set Process Status',
    GET_INSTANCES_BY_PROCESS_STATUS = '[INSTANCES] Get Instances by Process Status',
    SET_INSTANCES_BY_PROCESS_STATUS = '[INSTANCES] Set Instances by Process Status',
    SELECT_PROCESS_STATUS = '[INSTANCES] Select Process Status',
    SET_SELECTED_PROCESS_STATUS = '[INSTANCES] Set Selected Process Status',
    CREATE_BULK_REPORT_DOWNLOAD_REQUEST = '[INSTANCES] Create Bulk Report Download Request',
    SET_BULK_REPORT_DOWNLOAD_REQUEST = '[INSTANCES] Set Bulk Report Download Request',
    SET_INSTANCES_LOADING = '[INSTANCES] Set Instances Loading',
    SET_INSTANCE_DATA_TABLE_SCROLL = '[INSTANCES] Set Instance Data Table Scroll',
    SET_INSTANCE_DATA_TABLE_RECORD_SELECT = '[INSTANCES] Set Instance Data Table Record Select',
    CLEAR_SEARCHED_INSTANCES = '[INSTANCES] Clear Searched Instances',
}

export const GetInstance = createAction(
    actionTypes.GET_INSTANCE,
    props<{
        serviceId: string;
        instanceId: string;
        noAlerts: boolean;
    }>()
);

export const SetInstance = createAction(
    actionTypes.SET_INSTANCE,
    props<{
        instanceId: string;
        instance: Instance;
    }>()
);

export const SetInstancesPagination = createAction(
    actionTypes.SET_PAGINATION,
    props<{
        organizationId: string;
        serviceId: string;
        size: number;
        pageIndex: number;
    }>()
);

export const SetInstancesPagingState = createAction(
    actionTypes.SET_PAGING_STATE,
    props<{
        organizationId: string;
        serviceId: string;
        pagingState: any;
    }>()
);

export const ClearInstancesPagination = createAction(
    actionTypes.CLEAR_PAGINATION,
    props<{
        organizationId: string;
        serviceId: string;
    }>()
);

export const ClearSearchInstances = createAction(
    actionTypes.CLEAR_SEARCH_INSTANCES,
    props<{
        organizationId: string;
        serviceId: string;
        criteriaName: string;
    }>()
);

export const GetFilingInstances = createAction(
    actionTypes.SEARCH_FILING_INSTANCES,
    props<{
        serviceId: string;
        noAlerts?: boolean;
        timelineContextNotRequired?: boolean;
        isBackgroundProcess?: boolean;
    }>()
);

export const GetOTPInstance = createAction(
    actionTypes.GET_OTP_INSTANCES,
    props<{
        gstinNumber: string;
    }>()
);

export const SetSearchInstancesResponse = createAction(
    actionTypes.SET_SEARCH_INSTANCES_RESPONSE,
    props<{
        organizationId: string;
        serviceId: string;
        criteriaName: string;
        instances: Instance[];
        module?: string;
        forceFetch?: boolean;
    }>()
);

export const GetInstanceState = createAction(
    actionTypes.GET_INSTANCE_STATE,
    props<{
        serviceId: string;
        instanceId: string;
        noAlerts: boolean;
    }>()
);

export const SetInstanceState = createAction(
    actionTypes.SET_INSTANCE_STATE,
    props<{
        instanceId: string;
        state: { errors: string[]; warnings: string[] };
    }>()
);

export const SetInstanceEntityErrors = createAction(
    actionTypes.SET_INSTANCE_ENTITY_STATE,
    props<{
        instanceId: string;
        state: { [property: string]: string };
    }>()
);

export const ClearInstanceData = createAction(
    actionTypes.CLEAR_INSTANCE_DATA,
    props<{
        serviceId?: string;
        instanceId: string;
    }>()
);

export const CancelInstances = createAction(
    actionTypes.CANCEL_INSTANCES,
    props<{
        instanceIds: string[];
        comment?: string;
        serviceId: string;
        searchCriteriaString?: string;
    }>()
);

export const RemoveInstances = createAction(
    actionTypes.REMOVE_INSTANCES,
    props<{
        instanceIds: string[];
        serviceId: string;
        organizationId: string;
        criteria: string;
    }>()
);

export const SelectInstances = createAction(
    actionTypes.SELECT_INSTANCES,
    props<{
        organizationId: string;
        serviceId: string;
        criteria: string;
        instanceIds: string[];
    }>()
);

export const GetProcessStatuses = createAction(
    actionTypes.GET_PROCESS_STATUSES,
    props<{
        serviceId: string;
        noAlerts: boolean;
    }>()
);

export const SetProcessStatuses = createAction(
    actionTypes.SET_PROCESS_STATUSES,
    props<{
        organizationId: string;
        serviceId: string;
        statuses: { [property: string]: number };
    }>()
);

export const GetInstancesByProcessStatus = createAction(
    actionTypes.GET_INSTANCES_BY_PROCESS_STATUS,
    props<{
        serviceId: string;
        processStatus: string;
        noAlerts?: boolean;
    }>()
);

export const SetInstancesByProcessStatus = createAction(
    actionTypes.SET_INSTANCES_BY_PROCESS_STATUS,
    props<{
        organizationId: string;
        serviceId: string;
        criteria: string;
        processStatus: string;
        instances: string[];
    }>()
);

export const SelectProcessStatus = createAction(
    actionTypes.SELECT_PROCESS_STATUS,
    props<{
        serviceId: string;
        processStatus: string;
    }>()
);

export const SetSelectedProcessStatus = createAction(
    actionTypes.SET_SELECTED_PROCESS_STATUS,
    props<{
        organizationId: string;
        serviceId: string;
        processStatus: string;
    }>()
);

export const CreateBulkReportsDownloadRequest = createAction(
    actionTypes.CREATE_BULK_REPORT_DOWNLOAD_REQUEST,
    props<{
        serviceId: string;
        transformationNames: string[];
        criteria?: any;
    }>()
);

export const SetBulkReportsDownloadRequest = createAction(
    actionTypes.SET_BULK_REPORT_DOWNLOAD_REQUEST,
    props<{
        serviceId: string;
        downloadUrl: string;
        requestId: string;
    }>()
);

export const SetInstancesLoading = createAction(
    actionTypes.SET_INSTANCES_LOADING,
    props<{
        organizationId: string;
        serviceId: string;
        category: 'instances' | 'statuses';
        loading: boolean;
    }>()
);

export const setInstanceDataTableScroll = createAction(
    actionTypes.SET_INSTANCE_DATA_TABLE_SCROLL,
    props<{
        templateId: string;
        left: number;
        top: number;
    }>()
);

export const SetInstanceDataTableRecordSelect = createAction(
    actionTypes.SET_INSTANCE_DATA_TABLE_RECORD_SELECT,
    props<{
        templateId: string;
        allSelected: boolean;
        recordIds: string[];
        associationIds: string[];
    }>()
);

export const SearchInstances = createAction(
    actionTypes.SEARCH_INSTANCES,
    props<{
        organizationId: string;
        serviceId: string;
        criteriaName: string;
        noAlerts?: boolean;
        module?: string;
        isBackgroundProcess?: boolean;
        searchString?: string;
        forceFetch?: boolean;
    }>()
);

export const ClearSearchedInstances = createAction(
    actionTypes.CLEAR_SEARCHED_INSTANCES,
    props<{
        serviceId: string;
        searchString?: string;
        noAlerts?: boolean;
        module?: string;
    }>()
);
