import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { translate } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { ApiService } from '../../services/api/api.service';
import * as actions from '../actions';
import { getApplicationProperties$ } from '../selectors';

@Injectable()
export class AuthEffects {
    constructor(private actions$: Actions, private _api: ApiService, private store$: Store, private router$: Router) {}

    getClientDetails$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.AuthGetClientDetails),
            mergeMap((action) => {
                return this._api.session.getClientDetailsByDomain(action.payload).pipe(
                    map((response) => actions.ClientDetailsSuccess(response)),
                    catchError((errorResponse) => of(actions.ClientDetailsFailed(errorResponse)))
                );
            })
        );
    });

    validateCode$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.InternalAuthTokenValidation),
            mergeMap((action) => {
                return this._api.session.validateCode(action.payload).pipe(
                    map((response: any) => actions.InternalAuthTokenValidationSuccess(response)),
                    catchError((e) => of(actions.AlertError({ message: e?.msg || translate('Failed to validate code') })))
                );
            })
        );
    });

    validateToken$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.InternalAccessTokenValidation),
            mergeMap((action) => {
                return this._api.session.authenticateToken(action.token).pipe(
                    map(() => actions.InternalAccessTokenValidationSuccess()),
                    catchError((e) => {
                        this.router$.navigate(['']);
                        return of(actions.AlertError({ message: e?.msg || translate('Failed to authenticate token') }));
                    })
                );
            })
        );
    });

    validateAuthCodeExternally$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.AuthCodeExternalValidation),
            mergeMap((action) => {
                return this._api.session.validateCodeExternally(action.payload).pipe(
                    map(() => actions.ExternalCodeValidationSuccess()),
                    catchError((e) => {
                        this.store$.dispatch(actions.AlertError({ message: e?.msg || translate('Failed to authenticate token') }));
                        return of(actions.ExternalCodeValidationFailed());
                    })
                );
            })
        );
    });

    submitLDAPDetails$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.SubmitLDAPDetails),
            mergeMap((action) => {
                return this._api.session.submitLDAPDetails(action.payload).pipe(
                    map((response) => actions.LdapLoginSuccess(response)),
                    catchError((errorResponse) => of(actions.LdapLoginFailed(errorResponse)))
                );
            })
        );
    });

    resetPassword$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.ResetPassword),
            mergeMap((action) => {
                return this._api.session.resetPassword(action.payload).pipe(
                    map((response) => {
                        this.router$.navigate(['']);
                        return actions.AlertSuccess({ message: response?.msg || translate('Password reset successful') });
                    }),
                    catchError((e) => of(actions.AlertError({ message: e?.msg || translate('Failed to set password') })))
                );
            })
        );
    });

    setPassword$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.SetPassword),
            mergeMap((action) => {
                return this._api.session.setPassword(action.payload as any).pipe(
                    map((response) => {
                        this.store$.dispatch(actions.AlertSuccess({ message: response.msg || translate('Password set successfully.') }));
                        return actions.PasswordUpdated({ success: true });
                    }),
                    catchError((e) => {
                        this.store$.dispatch(actions.AlertError({ message: e?.msg || translate('Your Account Setup already completed.') }));
                        return of(actions.PasswordUpdated({ success: false }));
                    })
                );
            })
        );
    });

    getSecurityQuestions$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.GetSecurityQuestions),
            mergeMap(() => {
                return this._api.session.getSecurityQuestions().pipe(
                    map((response) => actions.GetSecurityQuestionsSuccess(response as any)),
                    catchError((e) => of(actions.AlertError({ message: e?.msg })))
                );
            })
        );
    });
    fetchCaptcha$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.FetchCaptcha),
            mergeMap((action) => {
                return this._api.session.fetchCaptcha().pipe(
                    map((response: any) => actions.FetchCaptchaSuccess({ ...action.payload, ...response })),
                    catchError((e) => of(actions.AlertError({ message: e?.msg || translate('Failed to get Captcha') })))
                );
            })
        );
    });
    getContextDetailWithKey$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.GetContextDetailWithKey),
            mergeMap((action) => {
                return this._api.session.getContextDetailsWithAuthorizationKey(action.payload).pipe(
                    map((response) => actions.GetContextDetailWithKeySuccess(response as any)),
                    catchError((e) =>
                        of(actions.AlertError({ message: e?.msg || translate('Failed to get user and organization details') }))
                    )
                );
            })
        );
    });

    getProviderOrganizations$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.GetProviderOrganizations),
            mergeMap(() => {
                return this._api.organization.providerOrganizationsDropdownList().pipe(
                    map((response) => {
                        response.sort((a, b) =>
                            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0
                        );
                        return actions.GetProviderOrganizationsSuccess({ result: response });
                    }),
                    catchError((errorResponse) => of(actions.AlertError({ message: errorResponse })))
                );
            })
        );
    });

    getProvider$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.GetProvider),
            mergeMap(() => {
                return this._api.session.getProvider().pipe(
                    map((response: any) => {
                        return actions.GetProviderCallback({ result: response });
                    }),
                    catchError(() => [])
                );
            })
        );
    });

    getApplicationProperties$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.GetApplicationProperties),
            withLatestFrom(this.store$.select(getApplicationProperties$)),
            mergeMap(([_action, applicationProperties]) => {
                if (applicationProperties?.['taxilla.version'] !== undefined) {
                    actions.SetApplicationProperties({ result: applicationProperties });
                    return [];
                } else {
                    return this._api.session.getApplicationProperties().pipe(
                        map((response) => {
                            return actions.SetApplicationProperties({ result: response });
                        }),
                        catchError((errorResponse) => of(actions.AlertError({ message: errorResponse })))
                    );
                }
            })
        );
    });

    userRegistration$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.UserRegistration),
            mergeMap((action) => {
                return this._api.session.register(action.payload).pipe(
                    map((response: any) => {
                        return actions.UserRegistrationSuccess({ result: response });
                    }),
                    catchError((errorResponse) => of(actions.UserRegistrationFailed(errorResponse)))
                );
            })
        );
    });

    get4ECPermission$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.Get4ECPermission),
            mergeMap(() => {
                return from(this._api.common.has4EcApprovalAccess()).pipe(
                    map((response) => {
                        return actions.Set4ECPermission({ hasAccess: response?.has4ECAccess });
                    }),
                    catchError((errorResponse) => of(actions.AlertError({ message: errorResponse })))
                );
            })
        );
    });
}
