import { AppTemplate, AssetData, AssetService, BridgeNode, ReconciliationConfiguration } from 'taxilla-library';

import { AppConfigurator } from '../../models/app-configurator.class';

export const appsState: AppsState = {
    currentOrganizationId: undefined,
    appsDescriptionByServiceId: {},
    appsHelpDocumentsByServiceId: {},
    subscribedServices: {},
    bridgeServices: {},
    assetsMetaData: {},
    reconConfiguration: {},
    loading: {},
    selectedApp: {
        bridgeServiceId: undefined,
        serviceId: undefined,
        category: undefined,
        entityId: undefined,
    },
    appsTemplate: {},
    appConfigurators: {},
};

export interface AppsState {
    currentOrganizationId: string;
    appsDescriptionByServiceId: { [property: string]: string };
    appsHelpDocumentsByServiceId: { [property: string]: { [property: string]: string }[] };
    bridgeServices: {
        [serviceId: string]: {
            [property: string]: BridgeNode;
        };
    };
    subscribedServices: {
        [property: string]: AssetService[];
    };
    appsTemplate: {
        [property: string]: {
            // organizationId vs services map
            [property: string]: {
                // serviceId vs template
                isOfCurrentOrg: boolean;
                uiTemplate: AppTemplate;
            };
        };
    };
    assetsMetaData: {
        [property: string]: {
            [property: string]: AssetData;
        };
    };
    appConfigurators: {
        // Service ID vs Configurator
        [property: string]: {
            // asset meta uid vs Configurator
            [property: string]: AppConfigurator;
        };
    };
    reconConfiguration: {
        [property: string]: ReconciliationConfiguration;
    };
    loading: {
        [property: string]: {
            meta: boolean;
            template: boolean;
            reconConfiguration: boolean;
            bridgeNodes: boolean;
            configurator: boolean;
        };
    };
    selectedApp: {
        bridgeServiceId: string;
        serviceId: string;
        category: 'Entity' | 'Details' | 'Workflow';
        entityId: string;
    };
}
