import { Component, DoCheck, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyFormFieldAppearance } from '@angular/material/legacy-form-field';
import { Subscription } from 'rxjs';

import { ModifiedErrorStateMatcher } from '../../../extensions/errorstatematcher/errorstatematcher';

@Component({
    selector: 'material-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
})
export class MaterialSelectComponent implements OnInit, DoCheck, OnChanges, OnDestroy {
    @Input() options: any[];
    @Input() createOptionRequired: boolean = false;
    @Input() placeholder: string;
    @Input() name: string;
    @Input() title: string;
    @Input() model: any;
    @Input() item: string;
    @Input() id: string;
    @Input() classList: string[];
    @Input() required: boolean;
    @Input() errors: any[];
    @Input() warnings: any[];
    @Input() disabled: boolean;
    @Input() optionDisplayNameProperty?: string;
    @Input() optionValueProperty?: string;
    @Input() emitFirstEvent?: boolean;
    @Input() noSelectIfValidOptionSelected: boolean;
    @Input() hideSelectIfValueExists: boolean;
    @Input() description: string;
    @Input() isSearchNotRequired: boolean;
    @Input() isMultiple: boolean;
    @Input() appearance: MatLegacyFormFieldAppearance;
    @Input() noLabel: boolean;
    @Input() noHints: boolean;

    @Output() createOptionCallBack = new EventEmitter();
    @Output() editOptionCallBack = new EventEmitter();
    @Output() modelChange = new EventEmitter();
    @Output() optionData = new EventEmitter();
    @Output() debounceEventHandler = new EventEmitter();

    matcher = new ModifiedErrorStateMatcher();
    control = new UntypedFormControl('');
    valueSubscription: Subscription;
    searchColumns = undefined;
    selectedOption: any;

    constructor() {}

    ngDoCheck() {
        if (this.errors === undefined && this.control.getError('errors') === undefined) {
            // do nothing
        } else if (
            (this.errors === undefined || (this.errors && this.errors.length === 0)) &&
            this.control.getError('errors') !== undefined
        ) {
            this.control.setErrors(undefined);
        } else if (
            this.errors !== undefined &&
            this.errors.length > 0 &&
            JSON.stringify(this.errors) !== JSON.stringify(this.control.getError('errors'))
        ) {
            this.control.setErrors({ errors: this.errors });
        }
        if (this.model?.length > 0 && this.options?.length > 0 && !this.selectedOption) {
            this.prepareSelectedOption();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.model) {
            this.control.value !== changes.model.currentValue && this.control.setValue(this.model);
            this.prepareSelectedOption();
        }
        if (changes.disabled) {
            if (changes.disabled.currentValue === true) {
                this.control.disable();
            } else {
                this.control.enable();
            }
        }
        if (changes?.options?.currentValue) {
            this.prepareSelectedOption();
        }
    }

    modelChanged = (event) => {
        this.model = event;
        this.modelChange.next(this.model);
        this.debounceEventHandler.emit(event);
    };

    sendOptionsData = (option) => {
        this.optionData.next(option);
    };

    private prepareSelectedOption() {
        this.selectedOption = this.options?.find(
            (option) => option?.[this.optionValueProperty || 'value'] === this.model || option === this.model
        );
    }

    ngOnInit() {
        this.valueSubscription = this.control.valueChanges.subscribe((event) => this.modelChanged(event));
        this.prepareSelectedOption();
        if (this.model !== undefined && this.emitFirstEvent) {
            this.modelChanged(this.model);
        }
    }

    ngOnDestroy(): void {
        this.valueSubscription && this.valueSubscription.unsubscribe();
    }
}
