import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { CommonUtilsService } from 'taxilla-library';

import { ApiService } from '../../services/api/api.service';
import {
    AlertError,
    DownloadSearchReport,
    GeneratedReport,
    GenerateReport,
    GenerateReportError,
    GetReports,
    GetSearchReports,
    LoadingReports,
    SetDownloadableSearchReports,
    SetReports,
    SetSearchReports,
} from '../actions';
import { getReportsLoadingMap$, getReportsMap$, getSearchReportsOrgMapByCurrentOrgId$, getSubscribedAppsMap$ } from '../selectors';

@Injectable()
export class ReportsEffects {
    constructor(private store$: Store, private actions$: Actions, private _api: ApiService) {}

    getReports = createEffect(() =>
        this.actions$.pipe(
            ofType(GetReports),
            withLatestFrom(
                this.store$.select(getSubscribedAppsMap$),
                this.store$.select(getReportsMap$),
                this.store$.select(getReportsLoadingMap$)
            ),
            mergeMap(([action, apps, reportsMap, reportsLoadingMap]) => {
                const reports = reportsMap?.[action.instanceId];
                const app = apps?.[action.serviceId];
                if (!app) {
                    return of(GetReports(action));
                }
                if ((reports?.length > 0 || reportsLoadingMap?.[action.instanceId]) && !action.forceFetch) {
                    return [];
                }
                this.store$.dispatch(LoadingReports({ instanceId: action.instanceId, loading: true }));
                return from(
                    this._api.commonReports.generateReports(
                        {},
                        [],
                        action.instanceId,
                        action.assetId || app.assetMetaUId,
                        app,
                        action.noAlerts
                    )
                ).pipe(
                    map((res: any[]) => {
                        res.forEach((resp) => {
                            resp?.chains?.sort((a, b) => {
                                const aName = a.displayName;
                                const bName = b.displayName;
                                if (aName < bName) {
                                    return -1;
                                } else if (aName > bName) {
                                    return 1;
                                }
                                return 0;
                            });
                        });
                        this.store$.dispatch(
                            SetReports({
                                instanceId: action.instanceId,
                                reports: res,
                            })
                        );
                        return LoadingReports({ instanceId: action.instanceId, loading: false });
                    }),
                    catchError(() => of(LoadingReports({ instanceId: action.instanceId, loading: false })))
                );
            })
        )
    );

    generateReport = createEffect(() =>
        this.actions$.pipe(
            ofType(GenerateReport),
            withLatestFrom(this.store$.select(getSubscribedAppsMap$)),
            mergeMap(([action, apps]) => {
                const app = apps?.[action.serviceId];
                if (!app) {
                    return of(GenerateReport(action));
                }
                this.store$.dispatch(LoadingReports({ instanceId: action.instanceId, loading: true }));
                return from(
                    this._api.commonReports.generateReport({
                        assetId: app.assetMetaUId,
                        chainName: action.chainName,
                        currentService: app,
                        instanceId: action.instanceId,
                        orgNamesMap: {},
                        reports: [],
                        dontFetchReports: true,
                        noAlerts: action.noAlerts,
                    })
                ).pipe(
                    map(() => {
                        this.store$.dispatch(
                            GeneratedReport({
                                instanceId: action.instanceId,
                                serviceId: action.serviceId,
                                repositoryId: action.repositoryId,
                                chainName: action.chainName,
                                transformationName: action.transformationName,
                                timeStamp: action.timeStamp,
                            })
                        );
                        return LoadingReports({ instanceId: action.instanceId, loading: false });
                    }),
                    catchError(() => {
                        this.store$.dispatch(
                            GenerateReportError({ instanceId: action.instanceId, transformationName: action.transformationName })
                        );
                        return of(LoadingReports({ instanceId: action.instanceId, loading: false }));
                    })
                );
            })
        )
    );

    getSearchReports = createEffect(() =>
        this.actions$.pipe(
            ofType(GetSearchReports),
            withLatestFrom(this.store$.select(getSearchReportsOrgMapByCurrentOrgId$)),
            mergeMap(([action, orgMap]) => {
                const data = orgMap?.[action.serviceId];
                const reports = data?.searchReports;
                const pagination = data?.searchReportsPagination;
                let selectedPageReports = [];
                if (pagination) {
                    const start = pagination?.pageIndex * pagination?.pageSize;
                    const end = (pagination?.pageIndex + 1) * pagination?.pageSize;
                    selectedPageReports = reports?.filter((_report, index) => index >= start && index < end) || [];
                }
                if (selectedPageReports?.length > 0) {
                    return [];
                }
                return from(
                    this._api.commonReports.getReportProcesses({
                        serviceId: 'search-requests/' + action?.serviceId,
                        size: pagination?.pageSize,
                        previousPagingState: pagination?.pagingState,
                        noAlerts: action.noAlerts,
                        timeRange: pagination?.timeRange,
                    })
                ).pipe(
                    map((res: { reports: any; pagingState: any }) => {
                        return SetSearchReports({
                            serviceId: action.serviceId,
                            reports: res.reports,
                            pagingState: res.pagingState,
                        });
                    }),
                    catchError((e) => {
                        return of(AlertError({ message: e?.msg || 'Failed to get search reports' }));
                    })
                );
            })
        )
    );

    downaloadSearchReport = createEffect(() =>
        this.actions$.pipe(
            ofType(DownloadSearchReport),
            withLatestFrom(this.store$.select(getSearchReportsOrgMapByCurrentOrgId$)),
            mergeMap(([action, orgMap]) => {
                const reports = orgMap?.[action.serviceId]?.downloadedSearchReports || {};
                if (reports?.[action.requestId] !== undefined) {
                    return [];
                }
                return from(
                    this._api.commonReports.generateSearchReport({
                        assetId: action.assetId,
                        requestId: action.requestId,
                        repositoryId: action.repositoryId,
                        transformation: action.transformationName,
                    })
                ).pipe(
                    map((res: any) => {
                        const key = Object.keys(res?.report_details)?.[0];
                        const reportDataKey = Object.keys(res?.report_details[key])?.[0];
                        const report = res?.report_details[key]?.[reportDataKey]?.[0];
                        let clonedReports = CommonUtilsService.cloneObject(reports);
                        clonedReports[action.requestId] = report;
                        return SetDownloadableSearchReports({
                            serviceId: action.serviceId,
                            reports: clonedReports,
                        });
                    }),
                    catchError((res: any) => {
                        return of(AlertError({ message: res.msg || 'Failed to download search report' }));
                    })
                );
            })
        )
    );
}
