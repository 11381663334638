import {
    IntegrationConfigField,
    IntegrationModal,
    IntegrationsSubSection,
    IntegrationTabStructure,
} from '../../interface/integrationmodal.interface';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { RecordData } from './integration-record-data.class';

export class IntegrationRecord {
    appId?: string;

    configuredData?: string;

    id?: string;

    name?: string;

    organizationId?: string;

    type?: string;

    tableValues?: { [property: string]: { [property: string]: string | boolean | string[] } };

    recordData?: IntegrationModal;

    originalData?: { [property: string]: any };

    subOrganizationId?: string;

    integrationMethod?: string;

    isValid?: boolean;

    active?: boolean;

    createdByName?: string;

    createdBy?: string;

    createdOn?: string;

    lastUpdatedByName?: string;

    lastUpdatedBy?: string;

    lastUpdatedOn?: string;

    constructor(record: IntegrationRecord, metaData: IntegrationModal) {
        if (!record) {
            return;
        }
        const keysToRemove = ['integrationSettings'];
        keysToRemove.forEach((key) => {
            if ((record as Object).hasOwnProperty(key)) {
                delete record[key];
            }
        });
        this.appId = record?.appId;
        this.configuredData = record?.configuredData;
        this.originalData =
            (record?.originalData && CommonUtilsService.cloneObject(record.originalData)) || CommonUtilsService.cloneObject(record);
        this.originalData['configuredData'] = (this.configuredData && JSON.parse(this.configuredData)) || {};
        this.id = record?.id;
        this.name = record?.name;
        this.organizationId = record?.organizationId;
        this.type = record?.type || metaData.api.toLowerCase();
        // tslint:disable-next-line: no-use-before-declare
        this.recordData = new RecordData(this.originalData as any, metaData);
        this.subOrganizationId = record?.subOrganizationId;
        this.integrationMethod = record?.integrationMethod;
        this.isValid = record?.isValid !== undefined ? record.isValid : false;
        this.active = record?.active;
        this.createdByName = record?.createdByName;
        this.createdBy = record?.createdBy;
        this.createdOn = record?.createdOn;
        this.lastUpdatedByName = record?.lastUpdatedByName;
        this.lastUpdatedBy = record?.lastUpdatedBy;
        this.lastUpdatedOn = record?.lastUpdatedOn;

        this.tableValues = {};
        this.recordData.tableFieldIds?.forEach((fieldId) => {
            const field = this.recordData.fields.find((dataField) => dataField.id === fieldId);
            if (field) {
                this.tableValues[fieldId] = {
                    displayName: field.displayName,
                    id: fieldId,
                    value: field.value,
                };
            } else if (!field && this.recordData.subSections) {
                this.recordData.subSections.forEach((subFieldObject) => {
                    let subField: IntegrationConfigField;
                    for (let i = 0; i < subFieldObject.tabs.length; i++) {
                        const tab = subFieldObject.tabs[i];
                        subField = tab.fields.find((metaDataField) => metaDataField.id === fieldId);
                        if (subField) {
                            break;
                        }
                    }
                    if (subField) {
                        this.tableValues[fieldId] = {
                            displayName: subField.displayName,
                            id: fieldId,
                            value: subField.value,
                        };
                    }
                });
            }
        });
    }

    addNewTabRecord? = (tab: IntegrationTabStructure) => {
        if (!tab.records) {
            tab.records = [];
        }
        if (tab.array) {
            const newRecord: IntegrationTabStructure['records'][0] = {
                fields: [],
                recordActions: [],
            };
            tab.fields.forEach((tabField) => {
                const newTabField: IntegrationConfigField = CommonUtilsService.cloneObject(tabField);
                newTabField.value = newTabField.defaultValue;
                newRecord.fields.push(newTabField);
            });
            tab.recordActions.forEach((tabField) => {
                const newTabField: IntegrationConfigField = CommonUtilsService.cloneObject(tabField);
                newTabField.value = newTabField.defaultValue;
                newRecord.recordActions.push(newTabField);
            });
            tab.records.push(newRecord);
        }
    };

    getRecordField? = (fieldId: string, tab?: IntegrationTabStructure, _subSectionRecord?: any): IntegrationConfigField => {
        return this.getRecordFieldByProperty('id', fieldId, tab);
    };

    setRecordFieldValue? = (fieldId: string, fieldProperty: string, value: any, tab?: IntegrationTabStructure) => {
        const field = this.getRecordField(fieldId, tab);
        field && (field[fieldProperty] = value);
    };

    getRecordFieldByProperty? = (propertyName: string, propertyValue: any, tab?: IntegrationTabStructure): IntegrationConfigField => {
        if (tab) {
            let field = tab.fields && tab.fields.find((recordField) => recordField[propertyName] === propertyValue);
            if (!field) {
                field = tab.recordActions && tab.recordActions.find((recordField) => recordField[propertyName] === propertyValue);
            }
            if (field) {
                return field;
            }
        } else {
            let field = this.recordData.fields.find((recordField) => recordField[propertyName] === propertyValue);
            if (!field) {
                this.recordData.subSections &&
                    this.recordData.subSections.forEach((section) => {
                        !field &&
                            (field = section.fields && section.fields.find((sectionField) => sectionField[propertyName] === propertyValue));
                    });
            }
            if (field) {
                return field;
            }
        }
    };

    getRecordFieldValyeByProperty? = (propertyName: string, propertyValue: any): string | boolean | string[] => {
        const field = this.getRecordFieldByProperty(propertyName, propertyValue);
        return field && field.value;
    };

    getMasterField? = (): IntegrationConfigField => {
        const field = this.recordData.fields.find((recordField) => recordField.isMasterField);
        if (field) {
            return field;
        }
    };

    canHideField? = (field: IntegrationConfigField, tab?: IntegrationTabStructure, _subSectionRecord?: any) => {
        const masterField = this.getMasterField();
        const masterValue = masterField && masterField.value;
        let satisfied = false;
        if (field.conditionalDisplay) {
            let satisfiedConditions;
            field.conditionalDisplay &&
                field.conditionalDisplay.forEach((conditionalDisplay) => {
                    switch (conditionalDisplay.type) {
                        case 'ALL_MUST_SATISFY':
                            satisfiedConditions = conditionalDisplay.conditions.filter((condition) => {
                                const requiredField = this.getRecordField(condition.fieldId, tab);
                                let requiredValue = requiredField && requiredField.value;
                                if (requiredField === undefined) {
                                    requiredValue = this[condition.fieldId];
                                }
                                return condition.valueExists
                                    ? requiredValue !== undefined
                                    : condition.requiredValue !== undefined
                                    ? condition.requiredValue === requiredValue
                                    : false;
                            });
                            satisfied = satisfied || satisfiedConditions.length === conditionalDisplay.conditions.length;
                            break;
                        case 'ANY_SATISFY':
                            satisfiedConditions = conditionalDisplay.conditions.filter((condition) => {
                                const requiredField = this.getRecordField(condition.fieldId);
                                let requiredValue = requiredField && requiredField.value;
                                if (requiredField === undefined) {
                                    requiredValue = this[condition.fieldId];
                                }
                                return condition.valueExists
                                    ? requiredValue !== undefined
                                    : condition.requiredValue !== undefined
                                    ? condition.requiredValue === requiredValue
                                    : false;
                            });
                            satisfied = satisfied || satisfiedConditions.length > 0;
                            break;
                        default:
                            break;
                    }
                });
            if (!satisfied) {
                if (!tab && masterField) {
                    if (masterValue === false) {
                        field.value = field.defaultValue;
                    } else if (masterValue === true) {
                        field.value = undefined;
                    }
                } else if (tab) {
                    if (tab.array) {
                        /** @todo Do nothing for now */
                    } else {
                        field.value = field.value || field.defaultValue || undefined;
                    }
                }
            }
            return !satisfied;
        }
        return satisfied;
    };

    canAssignDefaultValue? = (field: IntegrationConfigField, tab?: IntegrationTabStructure['records'][0]) => {
        let satisfied = false;
        if (field.defaultValueIf) {
            let satisfiedConditions;
            field.defaultValueIf &&
                field.defaultValueIf.forEach((conditionalDefault) => {
                    switch (conditionalDefault.type) {
                        case 'ALL_MUST_SATISFY':
                            satisfiedConditions = conditionalDefault.conditions.filter((condition) => {
                                const requiredField = this.getRecordField(condition.fieldId, tab as any);
                                let requiredValue = requiredField && requiredField.value;
                                if (requiredField === undefined) {
                                    requiredValue = this[condition.fieldId];
                                }
                                return condition.valueExists
                                    ? requiredValue !== undefined
                                    : condition.requiredValue !== undefined
                                    ? condition.requiredValue === requiredValue
                                    : false;
                            });
                            satisfied = satisfied || satisfiedConditions.length === conditionalDefault.conditions.length;
                            break;
                        case 'ANY_SATISFY':
                            satisfiedConditions = conditionalDefault.conditions.filter((condition) => {
                                const requiredField = this.getRecordField(condition.fieldId);
                                let requiredValue = requiredField && requiredField.value;
                                if (requiredField === undefined) {
                                    requiredValue = this[condition.fieldId];
                                }
                                return condition.valueExists
                                    ? requiredValue !== undefined
                                    : condition.requiredValue !== undefined
                                    ? condition.requiredValue === requiredValue
                                    : false;
                            });
                            satisfied = satisfied || satisfiedConditions.length > 0;
                            break;
                        default:
                            break;
                    }
                });
        }
        return satisfied;
    };

    canDisableField? = (field: IntegrationConfigField, tab?: IntegrationTabStructure) => {
        let satisfied = false;
        if (field.conditionalDisable) {
            let satisfiedConditions;
            field.conditionalDisable &&
                field.conditionalDisable.forEach((conditionalDisable) => {
                    switch (conditionalDisable.type) {
                        case 'ALL_MUST_SATISFY':
                            satisfiedConditions = conditionalDisable.conditions.filter((condition) => {
                                const requiredField = this.getRecordField(condition.fieldId, tab);
                                let requiredValue = requiredField && requiredField.value;
                                if (requiredField === undefined) {
                                    requiredValue = this[condition.fieldId];
                                }
                                return condition.valueExists
                                    ? requiredValue !== undefined
                                    : condition.requiredValue !== undefined
                                    ? condition.requiredValue === requiredValue
                                    : false;
                            });
                            satisfied = satisfied || satisfiedConditions.length === conditionalDisable.conditions.length;
                            break;
                        case 'ANY_SATISFY':
                            satisfiedConditions = conditionalDisable.conditions.filter((condition) => {
                                const requiredField = this.getRecordField(condition.fieldId);
                                let requiredValue = requiredField && requiredField.value;
                                if (requiredField === undefined) {
                                    requiredValue = this[condition.fieldId];
                                }
                                return condition.valueExists
                                    ? requiredValue !== undefined
                                    : condition.requiredValue !== undefined
                                    ? condition.requiredValue === requiredValue
                                    : false;
                            });
                            satisfied = satisfied || satisfiedConditions.length > 0;
                            break;
                        default:
                            break;
                    }
                });
        }
        return satisfied;
    };

    canHideSubSection = (subSection: IntegrationsSubSection, _subSectionRecord?: any) => {
        let satisfied = false;
        if (subSection.conditionalDisplay) {
            let satisfiedConditions;
            subSection.conditionalDisplay &&
                subSection.conditionalDisplay.forEach((conditionalDisplay) => {
                    switch (conditionalDisplay.type) {
                        case 'ALL_MUST_SATISFY':
                            satisfiedConditions = conditionalDisplay.conditions.filter((condition) => {
                                const requiredField = this.getRecordField(condition.fieldId);
                                return condition.valueExists
                                    ? requiredField.value !== undefined
                                    : condition.requiredValue !== undefined
                                    ? requiredField && condition.requiredValue === requiredField.value
                                    : false;
                            });
                            satisfied = satisfied || satisfiedConditions.length === conditionalDisplay.conditions.length;
                            break;
                        default:
                            break;
                    }
                });
            if (!satisfied) {
                if (subSection.haveTabs) {
                    subSection.tabs.forEach((tab) => {
                        if (tab.array && tab.records) {
                            tab.records.forEach((tabRecord) => {
                                tabRecord.fields.forEach((tabRecordField) => {
                                    tabRecordField.value =
                                        tabRecordField.defaultValue !== undefined ? tabRecordField.defaultValue : undefined;
                                });
                                tabRecord.recordActions &&
                                    tabRecord.recordActions.forEach((tabActionField) => {
                                        tabActionField.value =
                                            tabActionField.defaultValue !== undefined ? tabActionField.defaultValue : undefined;
                                    });
                            });
                        } else if (tab.fields && tab.fields.length > 0) {
                            tab.fields.forEach((tabRecordField) => {
                                tabRecordField.value = tabRecordField.defaultValue !== undefined ? tabRecordField.defaultValue : undefined;
                            });
                            tab.recordActions &&
                                tab.recordActions.forEach((tabActionField) => {
                                    tabActionField.value =
                                        tabActionField.defaultValue !== undefined ? tabActionField.defaultValue : undefined;
                                });
                        }
                    });
                } else if (subSection.fields) {
                    subSection.fields.forEach((subSectionField) => {
                        subSectionField.value = subSectionField.defaultValue !== undefined ? subSectionField.defaultValue : undefined;
                    });
                }
            }
            return !satisfied;
        }
        return satisfied;
    };

    isConditionalArray = (field: IntegrationConfigField) => {
        let satisfied = false;
        let satisfiedConditions: { fieldId: string; requiredValue: boolean; array: boolean }[];
        field.conditionalArray &&
            field.conditionalArray.forEach((conditions) => {
                if (satisfied) {
                    return;
                }
                switch (conditions.type) {
                    case 'ALL_MUST_SATISFY':
                        satisfiedConditions = conditions.conditions.filter((condition) => {
                            const requiredField = this.getRecordField(condition.fieldId, undefined);
                            let requiredValue = requiredField && requiredField.value;
                            if (requiredField === undefined) {
                                requiredValue = this[condition.fieldId];
                            }
                            return condition.requiredValue !== undefined ? condition.requiredValue === requiredValue : false;
                        });
                        satisfied = satisfied || satisfiedConditions.length === conditions.conditions.length;
                        break;
                    case 'ANY_SATISFY':
                        satisfiedConditions = conditions.conditions.filter((condition) => {
                            const requiredField = this.getRecordField(condition.fieldId);
                            let requiredValue = requiredField && requiredField.value;
                            if (requiredField === undefined) {
                                requiredValue = this[condition.fieldId];
                            }
                            return condition.requiredValue !== undefined ? condition.requiredValue === requiredValue : false;
                        });
                        satisfied = satisfied || satisfiedConditions.length > 0;
                        break;
                    default:
                        break;
                }
            });
        return satisfied && satisfiedConditions && satisfiedConditions[0] && satisfiedConditions[0].array;
    };
}
