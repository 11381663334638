import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BridgeService } from '../bridge/bridge.service';
import { StoreService } from '../store/store.service';

@Injectable({
    providedIn: 'root',
})
export class EventsubscriptionService {
    constructor(private _bridge: BridgeService, private _store: StoreService) {}

    /**
     * Method to fetch subscribeb events
     * @param data contains orgId, eventType, fetchSize, pagingState
     * @param callbacks contains success and failure calls
     */
    getSubscribedEvents = (data: { orgId: string; eventType?: string[]; groupId?: string; fetchSize: number; pagingState: string }) => {
        return new Observable((observer) => {
            this._bridge.getSubscribedEvents(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to fetch event definations
     * @param callbacks contains success and failure calls
     */
    getEventDefinitions = async (callbacks?: { successCallback: (res) => void; failureCallback: (res) => void }) => {
        const response = await this._store.publicScope.fetchValues(() => {
            return new Promise((resolve) => {
                this._bridge.getEventDefinitions(
                    (res) => {
                        resolve(res);
                    },
                    (res) => {
                        callbacks.failureCallback(res);
                    }
                );
            });
        }, 'eventDefinitions');
        if (callbacks?.successCallback) {
            callbacks.successCallback(response?.eventDefinitions);
        } else {
            return response?.eventDefinitions;
        }
    };

    /**
     * Method to create event subscriptions
     * @param data: contains event payload
     * @param callbacks: contains success and failure callbacks
     */
    createEventSubscriptions = (data: any) => {
        return new Observable((observer) => {
            this._bridge.createEventSubscriptions(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };
    /**
     * Method to create event subscriptions
     * @param data: contains event payload
     * @param callbacks: contains success and failure callbacks
     */
    editEventSubscriptions = (data: any) => {
        return new Observable((observer) => {
            this._bridge.editEventSubscriptions(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };
    /**
     * Method to delete event subscription
     * @param data: contains event payload
     * @param callbacks: contains success and failure callbacks
     */
    deleteEventSubscription = (data: { orgId: string; eventType: string; subscriptionId: string; groupId?: string }) => {
        return new Observable((observer) => {
            this._bridge.deleteEventSubscription(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };
    /**
     * Method to fetch email notifications
     * @param data contains groupId, eventType, subscriptionId, fromdate,fetchsize, pagingstate, status and mailId
     * @param callbacks contains success and failure calls
     */
    getEmailNotifications = (data: {
        groupId: string;
        eventType: string;
        subscriptionId: string;
        size: number;
        pageState: any;
        fromDate: string;
        status: string;
        mailId: string;
        noAlerts: boolean;
    }) => {
        return new Observable((observer) => {
            this._bridge.getEmailNotifications(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };
    /**
     * Method to resend email
     * @param data Contains  orgId,toEmails,ccEmails,bccEmails,subject,messageContent,attachmentsList

     * @param callbacks contains success and failure calls
     */
    resendEmail = (data: {
        orgId: string;
        toEmails: any[];
        ccEmails: any[];
        bccEmails: any[];
        subject: string;
        messageContent: string;
        attachmentList: any[];
        contextProperties: { unitId: string; eventType: string; groupId: string; subscriptionId: string };
    }) => {
        return new Observable((observer) => {
            this._bridge.resendMail(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };
}
