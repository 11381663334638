import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { ApiService, ConfirmationDialogService, MastersResponseRecord, RecordField } from 'taxilla-library';

import { AssetService } from '../../models/assetservice.class';
import { Entity } from '../../models/entity.class';
import { Record } from '../../models/record/record.class';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { UtilsService } from '../../services/utils/utils.service';

@Component({
    selector: 'app-entity-template',
    templateUrl: './entity-template.component.html',
    styleUrls: ['./entity-template.component.scss'],
})
export class EntityTemplateComponent implements OnChanges, OnInit, OnDestroy {
    @Input() public entity: Entity;
    @Input() public parentEntityUid: string;
    @Input() public opened: boolean;
    @Input() public parentMasterTablesData: string;
    @Input() public parentId: string;
    @Input() public parentTempId: string;
    @Input() public masterRecordsResponse: {
        [tableId: string]: {
            [pipedColumnKeys: string]: MastersResponseRecord[];
        };
    };
    @Input() eventType: string;
    @Input() public instanceId: string;
    @Input() public service: AssetService;
    @Input() public parentLookupsData: string;
    @Input() public lookupsResponse: {
        [condition: string]: RecordField['lookupValues'];
    };

    @Output() lookUpUpdated = new EventEmitter();
    @Output() public recordUpdateDisabled: boolean;

    public showChildEntities: { [id: string]: boolean } = {};
    public showErrors: { [id: string]: boolean } = {};
    public showRecordSpace: { [id: string]: boolean } = {};
    public sections: {
        name: string;
        fields: { name: string; id: string; uiTypeMetadata: string; isRequired: boolean }[];
        colSpan: number;
        rowSpan: number;
    }[] = [];
    public totalColumnsCount = 1;
    public definedSectionsCount: number;
    public currentSize: number;
    public newSize: number = 10;
    public checkboxes: boolean[] = [];
    public selectAllRecordsButton = false;
    public selectedRecordsLength: number;
    public selectedPageSize: number = 5;
    public pageSizes: number[] = [5, 10, 20, 50, 100];
    public showPageSizeSelector: boolean = false;
    public showLoadMore: boolean = false;
    public showAttachments: { [id: string]: string } = {};
    public showGrids: { [id: string]: string } = {};
    public showTextAreas: { [id: string]: string } = {};
    public textAreaField: RecordField;

    constructor(public _api: ApiService, private confirmationService: ConfirmationDialogService, protected _libUtils: UtilsService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.opened) {
            if (changes?.opened?.currentValue) {
                if (this.entity.entityData instanceof Record) {
                    this.entity.entityData = [];
                }
                (this.entity.entityData as Record[]).length === 0 && this.loadMore();
            } else {
                this.deleteEmptyRecords();
            }
        }
        if (changes?.parentMasterTablesData) {
            (this.entity?.entityData as Record[]).forEach(
                (record) => (record['parentMasterTablesData'] = this.parentMasterTablesData.slice(0))
            );
        }
    }

    public selectRecords = () => {
        (this.entity.entityData as Record[]).forEach((record) => {
            record.selected = this.selectAllRecordsButton;
        });
        this.selectedRecordsLength = (this.entity.entityData as Record[]).filter((record) => record.selected).length;
    };

    public isSelectAllChecked = () => {
        this.selectedRecordsLength = (this.entity.entityData as Record[]).filter((record) => record.selected).length;
        const recordsLength = (this.entity.entityData as Record[]).length;
        this.selectAllRecordsButton = this.selectedRecordsLength === recordsLength;
    };

    public removeMultipleItems = () => {
        if (!this.selectedRecordsLength) {
            return;
        }
        const removedRecords = (this.entity.entityData as Record[]).filter((record) => record.selected);
        this.removeItems(removedRecords, 'multiple');
    };

    public removeItems = (records: Record[], type = 'single') => {
        const confirmConfig = {
            title: this.translateMsg('Confirmation'),
            message: this.translateMsg(`Are you sure you want to delete Record${records.length > 1 ? 's' : ''}?`),
            btnOkText: this.translateMsg('Ok'),
            btnCancelText: this.translateMsg('Cancel'),
        };
        this.confirmationService
            .confirm(confirmConfig.title, confirmConfig.message, confirmConfig.btnOkText, confirmConfig.btnCancelText)
            .subscribe((action) => {
                if (action) {
                    this.deleteRecord(
                        records.filter((record) => record.id !== undefined),
                        records.length
                    );
                    records
                        .filter((record) => record.id === undefined)
                        .forEach((record) => {
                            const entityData = this.entity.entityData as Record[];
                            const recordIndex = entityData.findIndex((entityRecord) => entityRecord.tempId === record.tempId);
                            entityData.splice(recordIndex, 1);
                        });
                    if (type === 'single') {
                        if (this.selectedRecordsLength > 0) {
                            this.selectedRecordsLength = this.selectedRecordsLength - 1;
                        }
                    } else {
                        this.selectedRecordsLength = 0;
                    }
                    this.selectAllRecordsButton = false;
                }
            });
    };

    public deleteRecord = (records: Record[], numRecordsToDelete: number) => {
        if (records.length === 0) {
            return;
        }
        this._api.records.deleteRecord(
            {
                assetId: this.service.assetMetaUId,
                entityId: this.entity.uid,
                instanceId: this.instanceId,
                restApiName: this.service.restApiName,
                payload: records.reduce(
                    (itemsToDelete, record) => {
                        itemsToDelete.push({
                            id: record.id,
                            parentRecordId: record.parentId === '-' ? undefined : record.parentId,
                        });
                        return itemsToDelete;
                    },
                    [] as {
                        id: string;
                        parentRecordId: string;
                    }[]
                ),
            },
            {
                successCallback: (response) => {
                    const idsToDelete = records.reduce((deletedItems, record) => {
                        deletedItems.push(record.id);
                        return deletedItems;
                    }, [] as string[]);
                    idsToDelete.forEach((id) => {
                        const index = (this.entity.entityData as Record[]).findIndex((record) => record.id === id);
                        (this.entity.entityData as Record[]).splice(index, 1);
                    });
                    this._libUtils.alertSuccess(response?.msg || translate('Record(s) deleted successfully.'));
                    this.selectAllRecordsButton = false;
                    this.selectedRecordsLength -= numRecordsToDelete;
                },
            }
        );
    };

    public createSections = () => {
        const businessKeySection = {
            name: 'Business Keys',
            fields: [],
            colSpan: 0,
            rowSpan: 1,
        };
        this.entity.fields
            ?.filter((field) => field.isBusinessKey)
            .forEach((field) => {
                businessKeySection.colSpan += 1;
                businessKeySection.fields.push({
                    name: field.displayName,
                    id: field.uid,
                });
            });
        this.sections.push(businessKeySection);
        this.entity.fields
            .filter((field) => !field.isBusinessKey)
            .forEach((field) => {
                const section = this.sections.find((sec) => sec.name === field.fieldSection);
                if (!section) {
                    this.sections.push({
                        name: field.fieldSection,
                        colSpan: 1,
                        rowSpan: 1,
                        fields: [
                            {
                                name: field.displayName,
                                id: field.uid,
                                uiTypeMetadata: field.uiTypeMetadata,
                                isRequired: field.isBusinessKey || field.mandatory,
                            },
                        ],
                    });
                } else {
                    section.colSpan += 1;
                    section.fields.push({
                        name: field.displayName,
                        id: field.uid,
                        uiTypeMetadata: field.uiTypeMetadata,
                        isRequired: field.isBusinessKey || field.mandatory,
                    });
                }
            });
        this.sections = this.sections.filter((section) => section.fields.length > 0);
        this.definedSectionsCount = this.sections.filter((section) => section.name?.length > 0).length;
        this.sections.forEach((section) => {
            section.fields.forEach(() => {
                this.totalColumnsCount += 1;
            });
        });
        if (this.entity.entities?.length > 0) {
            this.totalColumnsCount += this.eventType === 'edit' ? 2 : 1;
        }
    };

    public loadMore = (entity: Entity = this.entity, newSize: number = this.selectedPageSize) => {
        if (!(!this.parentEntityUid || (this.parentEntityUid && this.parentId))) {
            return;
        }
        this._api.records.getRecords(
            {
                entity: entity,
                instanceId: this.instanceId,
                pagingState: entity.pagingState,
                parentRecordId: this.parentId,
                size: newSize,
                restApiName: this.service.restApiName,
            },
            {
                successCallback: (records, response) => {
                    entity.pagingState = response.pagingState;
                    records?.forEach(() => {
                        const uniqueRecords = records?.filter((record) => {
                            return !(entity.entityData as Record[]).some((existingRecord) => existingRecord.id === record.id);
                        });
                        entity?.entities?.length > 0 &&
                            uniqueRecords.forEach((record) => {
                                record.entities = CommonUtilsService.cloneObject(entity.entities);
                            });
                        (entity.entityData as Record[]).push(...uniqueRecords);
                    });
                },
            }
        );
        this.currentSize = newSize;
    };

    public createNewRecord = () => {
        const record = new Record(undefined, this.entity);
        record.tempId = UtilsService.guid();
        record.parentId = this.parentId || this.parentTempId;
        if (!(this.entity.entityData as Record[])) {
            (this.entity.entityData as Record[]) = [];
        }
        record.entities = CommonUtilsService.cloneObject(this.entity.entities);
        record['parentMasterTablesData'] = CommonUtilsService.cloneObject(this.parentMasterTablesData);
        (this.entity.entityData as Record[]).push(record);
    };

    private deleteEmptyRecords = () => {
        (this.entity?.entityData as Record[])?.forEach((record, index) => {
            const field = record.fields.find((fieldItem) => fieldItem.value !== undefined);
            if (!field) {
                (this.entity.entityData as Record[]).splice(index, 1);
            }
        });
    };

    public toggleChildEntities = (id: string) => {
        this.showChildEntities[id] = !this.showChildEntities[id];
        this.showErrors[id] = false;
        this.showAttachments[id] = undefined;
        this.toggleRecordSpace(id, this.showChildEntities[id]);
        this.showGrids[id] = undefined;
    };

    public toggleErrors = (id: string) => {
        this.showErrors[id] = !this.showErrors[id];
        this.showChildEntities[id] = false;
        this.showAttachments[id] = undefined;
        this.toggleRecordSpace(id, this.showErrors[id]);
    };

    public openAttachmentEvent = (field: RecordField, id: string) => {
        this.showAttachments[id] = this.showAttachments[id] ? undefined : field.id;
        this.showChildEntities[id] = false;
        this.showErrors[id] = false;
        this.toggleRecordSpace(id, !!this.showAttachments[id]);
    };

    public openGridEvent = (field: RecordField, id: string) => {
        this.showGrids[id] = this.showGrids[id] ? undefined : field.id;
        this.showAttachments[id] = undefined;
        this.showChildEntities[id] = false;
        this.showErrors[id] = false;
        this.toggleRecordSpace(id, !!this.showGrids[id]);
    };

    public editTextAreaEvent = (field: RecordField, id: string) => {
        this.showTextAreas[id] = this.showTextAreas[id] ? undefined : field.id;
        this.showGrids[id] = undefined;
        this.showAttachments[id] = undefined;
        this.showChildEntities[id] = false;
        this.showErrors[id] = false;
        this.textAreaField = field;
        this.toggleRecordSpace(id, !!this.showTextAreas[id]);
    };

    decodeTextFieldValue = (value: any) => {
        return value ? decodeURIComponent(value) : '';
    };

    private toggleRecordSpace = (id: string, show?: boolean) => {
        this.showRecordSpace[id] = show !== undefined ? show : !this.showRecordSpace;
    };

    translateMsg = (string: string): string => {
        return translate(`${string}`);
    };

    ngOnInit(): void {
        this.createSections();
        this.entity.entities?.length > 0 &&
            (this.entity.entityData as Record[]).forEach((record) => {
                if (record?.id !== undefined) {
                    this.toggleChildEntities(record.id);
                }
            });
    }

    ngOnDestroy(): void {
        this.deleteEmptyRecords();
    }
}
