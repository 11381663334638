import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import {
    MatLegacySnackBar as MatSnackBar,
    MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition,
    MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition,
} from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, ChildActivationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AssetService, BridgeNode, CommonUtilsService, UtilsService } from 'taxilla-library';

import { RootScope } from '../rootscope/rootscope.service';
import { TaxillaApiService } from '../taxillaapi/taxillaapi.service';

/**
 * Utils provider
 * This provider is used to handle all common utility methods.
 */
@Injectable({
    providedIn: 'root',
})
export class Utils {
    constructor(
        private _router: Router,
        private activatedRoute: ActivatedRoute,
        private R: RootScope,
        public snackBar: MatSnackBar,
        private browserUrl: Location,
        private _commonUtils: CommonUtilsService,
        private _taxilla: TaxillaApiService,
        private _libUtils: UtilsService
    ) {}

    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';

    checkNumber = Utils.checkNumber;
    checkDoubleNumber = Utils.checkDoubleNumber;
    checkNumberWithoutDecimal = Utils.checkNumberWithoutDecimal;
    checkNumberForAccessApi = Utils.checkNumberForAccessApi;
    checkDistance = Utils.checkDistance;
    checkZeroes = Utils.checkZeroes;
    checkPhoneNumberwithLimit = Utils.checkPhoneNumberwithLimit;
    checkInvoiceNumber = Utils.checkInvoiceNumber;
    acceptGSTIN = Utils.acceptGSTIN;
    getPropertyValue = Utils.getPropertyValue;
    checkMaxLength = Utils.checkMaxLength;
    checkMinValue = Utils.checkMinValue;
    RouteRefreshSubscription: Subscription;
    checkInteger = Utils.checkInteger;
    checkFloat = Utils.checkFloat;
    checkOrgCode = Utils.checkOrgCode;

    transformDateToLocale = CommonUtilsService.transformDateToLocale;
    transformDateToDefaultFormat = CommonUtilsService.transformDateToDefaultFormat;
    /**
     * Regex method to check the if the input string contains only number.
     */
    static checkNumber = (number) => {
        const pattern = /^\d+(\.\d+)?$/;
        return pattern.test(number);
    };

    /**
     * Regex method to check the if the input is integer or not.
     */
    static checkInteger = (number) => {
        const pattern = /^\d+$/;
        return pattern.test(number);
    };

    /**
     * Regex method to check the if the input is float.
     */
    static checkFloat = (number) => {
        const pattern = /^[+-]?([0-9]*[.])?[0-9]+/;
        return pattern.test(number);
    };

    /**
     * Regex method to check the if the input string contains only number without decimal.
     */
    static checkNumberWithoutDecimal = (number) => {
        const pattern = /^\d+(\d+)?$/;
        return pattern.test(number);
    };
    /**
     * Regex method to check the if the input string contains double number.
     */
    static checkDoubleNumber = (number) => {
        const pattern = /^\d{0,2}(\.\d{0,2}){0,1}$/;
        return pattern.test(number);
    };

    /**
     * Regex method to check the if the input string contains only number.
     */
    static checkNumberForAccessApi = (number) => {
        const pattern = /^[0-9]*$/;
        return pattern.test(number);
    };

    static checkOrgCode = (code) => {
        const pattern = /^([a-zA-Z0-9-]+)$/;
        return pattern.test(code);
    };

    static checkDistance = (number) => {
        const pattern = /^([1-3]?\d{1,3}|4000)$/;
        if (number) {
            if (number.match(pattern)) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    };

    static checkZeroes = (number) => {
        const pattern = /^[^123456789]+$/;
        if (number.match(pattern)) {
            return false;
        } else {
            return true;
        }
    };

    static checkPhoneNumberwithLimit = (str) => {
        const phoneno = /^(?!0{10,15})([0-9]{10,15})$/;
        if (str) {
            if (str.match(phoneno)) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    };

    // To check Invoice Number

    static checkInvoiceNumber = (number) => {
        const regex = /^[[A-Za-z0-9\\/\_-]{0,16}]*$/;
        if (number) {
            if (number.match(regex)) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    };

    // checking length of value
    static checkMaxLength = (value, charcount) => {
        if (value && charcount) {
            if (value.length < charcount) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    };

    // checking length of value
    static checkMinValue = (value, minValue = 0) => {
        if (value) {
            return parseInt(value, undefined) > minValue;
        }
        return false;
    };

    /**
     * Regex method to check the if the input string is a valid GSTIN number.
     */
    static acceptGSTIN = (gst_in) => {
        const regex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}Z[0-9A-Z]{1}$/;
        gst_in = gst_in && gst_in.trim();
        return gst_in && (regex.test(gst_in) || /URP/.test(gst_in));
    };

    /**
     * Method to generate random string
     */
    static s4 = () => {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(10)
            .substring(0, 1);
    };

    /**
     * Method to generate random guid
     */
    static guid = (length?) => {
        let str = '';
        for (let i = 0; i < (length || 4); i++) {
            const rn = Utils.s4();
            str += rn + '';
        }
        return str;
    };

    static getPropertyValue = (propertyKeys: string[], object: { [property: string]: any }) => {
        let returnObject: any;
        propertyKeys &&
            propertyKeys.forEach((key) => {
                if (typeof object[key] === 'object') {
                    /** Do Something */
                    returnObject = object[key];
                } else if (typeof object[key] === 'string' && propertyKeys.indexOf(key) === propertyKeys.length - 1) {
                    returnObject = object[key];
                } else if (typeof object[key] === 'string' && propertyKeys.indexOf(key) !== propertyKeys.length - 1) {
                    if (object[object[key]]) {
                        returnObject = Utils.getPropertyValue(propertyKeys.splice(propertyKeys.indexOf(key), 1), object[object[key]]);
                    }
                }
            });
        return returnObject;
    };

    /**
     * Setting a page route
     */
    setRoute = (url, options?) => {
        this.setUrlRoute({ url: url }, options);
    };

    /**
     * Setting a page route with router
     */
    setUrlRoute = (data, options?) => {
        if (typeof data.url === 'string') {
            this._router.navigate([data.url], options);
        } else if (Array.isArray(data.url)) {
            this._router.navigate(data.url, options);
        }
    };

    /**
     * Setting a page route with router
     */
    setSilentRoute = (url) => {
        this._router.navigateByUrl(url);
    };

    /**
     * Setting a page route with params
     */
    setRouteWithParam = (url, params) => {
        this._router.navigate([url, params]);
    };

    /**
     * Regex to control the input length to 16 characters max
     */
    checkForMaxNums = (str) => {
        const pattern = /^[a-zA-Z]{16}$/;
        if (!str.match(pattern)) {
            return true;
        } else {
            return false;
        }
    };

    /**
     * Regex method to check the if the input string has atleast one alphabet and one numeric character.
     */
    atleastOneAlphaNumeric = (str) => {
        const patternd = /\d/;
        const patterna = /[a-zA-Z]/i;
        const result = patterna.test(str) && patternd.test(str) && !this.invalidChars(str);
        return !result;
    };

    atleastOneAlphaNumericForLoc = (str) => {
        const patternd = /\d/;
        const patterna = /[a-zA-Z]/i;
        const result = patterna.test(str) && patternd.test(str);
        return result;
    };
    /**
     * Regex method to check if the input has one alphabet and one numeric character[custom method for adding location modal]
     */
    alphaNumericMust = (str) => {
        const regex = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/;
        if (str) {
            if (str.match(regex)) {
                return true;
            } else {
                this._libUtils.alertError('Location name must be alphanumeric', 3000);
            }
        }
        return false;
    };

    /**
     * Regex method to check the if the input string contains any invalid characters like ( > < . : ) characters.
     */
    invalidChars = (str, isArray?: boolean) => {
        const pattern2 = /^(.)*<(.)*>(.)*$/;
        const pattern3 = /^(.)*:(.)*$/;
        let result = false;
        if (isArray) {
            str.forEach((item) => {
                result = result || pattern2.test(item) || pattern3.test(item);
            });
        } else {
            result = pattern2.test(str) || pattern3.test(str);
        }
        return result;
    };

    /**
     * Regex method to check the if the input string contains number or hyphen.
     */
    checkNumberPlusHyphen = (numberspecial) => {
        const pattern = /^[+]?[0-9-]+[0-9]+$/;
        return pattern.test(numberspecial);
    };

    /**
     * Regex method to check the if the input string  is a valid phone number.
     */
    checkPhoneNumber = (str) => {
        const phoneno = /^(?!0{10})([0-9]{10})$/;
        return str && str.match(phoneno) && str.trim().length >= 5 && str.trim().length <= 10;
    };

    checkSpecialChrs = (str) => {
        const pattern = /^[[A-Za-z]{1}[A-Za-z0-9_$!&*. -]*$/;
        if (str && str.match(pattern)) {
            return true;
        }
        return false;
    };

    checkSpecialChrsWithHyphen = (str) => {
        const pattern = /^[[A-Za-z]{1}[A-Za-z0-9-]*$/;
        if (str && str.match(pattern)) {
            return true;
        }
        return false;
    };

    checkUserId = (str) => {
        const pattern = /^[a-zA-Z0-9._@-]*$/;
        if (str && str.match(pattern)) {
            return true;
        }
        return false;
    };

    checkForSpecialChars = (str) => {
        const pattern = /[!@#$%^&*(),.?":{}|<>]/;
        if (str.match(pattern)) {
            return true;
        } else {
            return false;
        }
    };

    checkPostalCode = (str, zipcode) => {
        if (str) {
            if (str.match(zipcode)) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    };

    /**
     * Regex method to check the if the input string  is a valid phone number.
     */
    checkFaxNumber = (str) => {
        const faxNo =
            /^([\(\+])?([0-9]{1,2}([\s])?)?([\+|\(|\-|\)|\s])?([0-9]{2,3})([\-|\)|\.|\s]([\s])?)?([0-9]{2,3})?([\.|\-|\s])?([0-9]{4,7})$/;
        return faxNo.test(str);
    };

    /**
     * Regex method to check the if the input string is a valid URL.
     */
    checkUrl = (url) => {
        let regex: RegExp;
        if (/^(http[s]?:\/\/www\.)/.test(url)) {
            regex =
                /^((http|HTTP)[sS]?:\/\/[wW]{3}.)([a-zA-Z0-9]+[a-z0-9-]{0,}[.])?[a-zA-Z0-9][-a-zA-Z0-9]+[a-zA-Z0-9][.][a-zA-Z]{2,4}(.[a-zA-Z]{2,4})?(.[a-zA-Z]{2,4})?(?:\/)?$/;
        } else if (/^((http|HTTP)[sS]?:\/\/)/.test(url)) {
            regex =
                /^(http|HTTP)[sS]?:\/\/([a-zA-Z0-9]+[a-z0-9-]{0,}[.])?[a-zA-Z0-9][-a-zA-Z0-9]+[a-zA-Z0-9][.][a-zA-Z]{2,4}(.[a-zA-Z]{2,4})?(.[a-zA-Z]{2,4})?(?:\/)?$/;
        } else if (/^([wW]{3}\.)/.test(url)) {
            regex =
                /^[wW]{3}\.([a-zA-Z0-9]+[a-z0-9-]{0,}[.])?[a-zA-Z0-9][-a-zA-Z0-9]+[a-zA-Z0-9][.][a-zA-Z]{2,4}(.[a-zA-Z]{2,4})?(.[a-zA-Z]{2,4})?(?:\/)?$/;
        } else {
            regex =
                /^([a-zA-Z0-9]+[a-z0-9-]{0,}[.])?[a-zA-Z0-9][-a-zA-Z0-9]+[a-zA-Z0-9][.][a-zA-Z]{2,4}(.[a-zA-Z]{2,4})?(.[a-zA-Z]{2,4})?(?:\/)?$/;
        }
        return regex.test(url);
    };

    checkWebUrl = (url) => {
        const urlExp =
            /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
        if (url) {
            if (url.match(urlExp)) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    };

    checkName = (name) => {
        name = (name as string).trim();
        if (!name || (name as string).trim() === '') {
            return false;
        } else if (name && (name as string).length > 30) {
            return false;
        } else if (this.invalidChars(name)) {
            return false;
        } else if (!this.acceptOrgName(name)) {
            return false;
        }
        return true;
    };

    /**
     * Regex to replace multiple spaces with single
     */
    replaceMultipleSpacesWithSingle = (str) => {
        return str.replace(/\s\s+/g, ' ');
    };

    /**
     * Regex method to replace special characters with - character.
     */
    replaceSpecialChars = (str) => {
        return str.replace(/[^a-zA-Z0-9]/g, '-');
    };

    /**
     * Regex method to check the if the input string is a valid organization name.
     */
    acceptOrgName = (org_name) => {
        const regex = /^([A-Za-z0-9 ]{0,99})*$/;
        return org_name && regex.test(org_name);
    };

    /**
     * Regex method to check the if the input string is a valid originkey or not.
     */
    acceptOriginKey = (key) => {
        const regex = /^([A-Za-z0-9_-]{0,99})*$/;
        return key && regex.test(key);
    };

    acceptUserId = (userId) => {
        const regex = /^[A-Za-z0-9_.@\s-]*$/;
        if (userId) {
            if (userId.match(regex)) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    };

    /**
     * Regex method to check the if the input string includes letters,numbers and space.
     */
    acceptLocationName = (loc_name) => {
        const regex = /^[0-9A-Za-z\s]+$/;
        if (loc_name) {
            if (loc_name.match(regex)) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    };

    removeWhitespaces = (data) => {
        if (data && data !== undefined) {
            const gstin = data.trim();
            return gstin;
        }
    };
    /**
     * Regex method to check the if the input string is a valid PAN number.
     */
    acceptPAN = (pan) => {
        const regex = /^([A-Z]{3}[C,P,H,F,A,T,B,L,J,G][A-Z][0-9]{4}[A-Z])$|^$|^(\\d *?){11}$/;
        return pan && pan.match(regex);
    };

    /**
     * Regex method to check the if the input string is a valid ABN number.
     */
    acceptABN = (abn) => {
        const regex = /^(\d *?){11}$/;
        return abn && abn.match(regex);
    };

    /**
     * Regex method to check the if the input string is a valid TIN number. (15 digit number)
     */
    acceptTIN = (tin) => {
        const regex = /^(\d *?){15}$/;
        return tin && tin.match(regex);
    };

    /**
     * Regex method to check the address
     */
    acceptAddress = (address) => {
        const regex = /^[[A-Za-z0-9+ +:+.+#+,+/+\x26+\-]{0,49}]*$/;
        if (address) {
            if (address.match(regex)) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    };

    /**
     * Regex method to check the if the input string is a valid phone number.
     */
    acceptPhoneNo = (phone_no) => {
        const regex = /^[0-9]{10}$|^$/;
        if (phone_no) {
            if (phone_no.match(regex)) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    };

    /**
     * Regex method to check the if the input string is a valid australia phone number.
     */
    acceptAusPhoneNo = (phone_no) => {
        const regex = /^[0-9]{11}$|^$/;
        if (phone_no) {
            if (phone_no.match(regex)) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    };

    /**
     * Regex method to check the if the input string contains alphabets.
     */
    acceptAlphabets = (str) => {
        const regex = /^[A-Za-z+ +]*$/;

        if (str) {
            if (str.match(regex)) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    };

    acceptPortNumber = (str) => {
        const regex = /^([0-9]{0,4})$/;
        if (str) {
            if (str.match(regex)) {
                return true;
            } else {
                return false;
            }
        }
    };

    acceptIpAddress = (str) => {
        const regex = /^[A-Za-z0-9\/_.:-]*$/;
        if (str) {
            if (str.match(regex)) {
                return true;
            } else {
                return false;
            }
        }
    };

    acceptFolderPath = (str) => {
        const regex = /^[A-Za-z0-9\\/\_-]+$/;
        if (str) {
            if (str.match(regex)) {
                return true;
            } else {
                return false;
            }
        }
    };

    toFindStarInString = (str) => {
        const regex = /^[/*]+$/;
        if (str) {
            if (str.match(regex)) {
                return true;
            } else {
                return false;
            }
        }
    };
    acceptAlphaNumeric = (str) => {
        const regex = /^[A-Za-z0-9 ]*$/;
        if (str) {
            if (str.match(regex)) {
                return true;
            } else {
                return false;
            }
        }
    };
    acceptClientName = (str) => {
        const regex = /^[A-Za-z0-9 _-]+$/;
        if (str) {
            if (str.match(regex)) {
                return true;
            } else {
                return false;
            }
        }
    };

    acceptClientId = (str) => {
        const regex = /^[A-Za-z0-9_-]+$/;
        if (str) {
            if (str.match(regex)) {
                return true;
            } else {
                return false;
            }
        }
    };

    acceptRoleName = (str) => {
        const regex = /^[0-9A-Za-z ]+$/;
        if (str) {
            if (str.match(regex)) {
                return true;
            } else {
                return false;
            }
        }
    };

    checkValueExists = (value: any): boolean => {
        if (typeof value === 'string') {
            return value !== undefined && value.trim().length > 0;
        }
        if (typeof value === 'object' && this.isEmpty(value)) {
            return false;
        }
        return value !== undefined;
    };

    /**
     * Transforming a text to first letter upper case and remaining lower case
     */
    transformText = (str) => {
        str = str.toLowerCase();
        return str.charAt(0).toUpperCase() + str.substring(1);
    };

    /**
     * method to check object empty or not
     */
    isEmpty = (obj) => {
        return obj === null || undefined
            ? true
            : (() => {
                  for (const prop in obj) {
                      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                          return false;
                      }
                  }
                  return true;
              })();
    };

    guid = (length?) => Utils.guid(length);

    /**
     * Get operators per datatype for elastic search query builder
     */

    getOperatorsPerDataType = (dt?) => {
        const operatorsPerDataType = {
            date: ['=', '<=', '>', 'is null', 'is not null'],
            textarea: ['=', '!=', 'contains', 'is null', 'is not null'],
            string: ['=', '!=', 'contains', 'is null', 'is not null'],
            boolean: ['='],
        };

        if (dt) {
            return operatorsPerDataType[dt];
        }

        return operatorsPerDataType;
    };

    /**
     * Get allowed datatype
     */

    getDataType = (dataType: string) => {
        const dt = dataType && dataType.toLowerCase();
        let allowedDT = '';
        switch (dt) {
            case 'string':
                allowedDT = 'string';
                break;
            case 'textarea':
                allowedDT = 'textarea';
                break;
            case 'boolean':
                allowedDT = 'boolean';
                break;
            case 'date':
                allowedDT = 'date';
                break;
            case 'double':
            case 'int':
            case 'number':
            case 'integer':
                allowedDT = 'number';
                break;
            case 'checkbox':
                allowedDT = 'category';
                break;
            default:
                allowedDT = 'term';
                break;
        }
        return allowedDT;
    };

    getAlphabetsArray = () => {
        return [
            'A',
            'B',
            'c',
            'D',
            'E',
            'F',
            'G',
            'H',
            'I',
            'J',
            'K',
            'L',
            'M',
            'N',
            'O',
            'P',
            'Q',
            'R',
            'S',
            'T',
            'U',
            'V',
            'W',
            'X',
            'Y',
            'Z',
        ];
    };

    /*
    Checking the given path is present on the URL
    */
    isCurrentPath = (path) => {
        return this.activatedRoute['_routerState'] && this.activatedRoute['_routerState'].snapshot.url.indexOf(path) > -1;
    };
    /**
     * Check current path in the browser
     */
    hasCurrentPathInUrl = (path) => {
        return this.browserUrl && this.browserUrl.path() && this.browserUrl.path().indexOf(path) > -1;
    };

    createNode = (org?) => {
        if (!org) {
            return;
        }
        org.nextNodes = [];
        return org;
    };

    addToMap = (node, map) => {
        if (!node) {
            return;
        }
        !map && (map = {});
        // Update: Decided to override it as in createNode a new node object will be created in the second iterations. This will have a new address in memory. So, we'll need this new address.
        map[node.id] = node;
        return map;
    };

    getOrganizationName = (organization?, delimiter?) => {
        if (!organization) {
            return;
        }
        if (!organization.parent) {
            return organization.name;
        } else {
            return this.getOrganizationName(organization.parent) + (delimiter ? '  ' + delimiter + '  ' : '  |  ') + organization.name;
        }
    };

    isObject = (obj) => {
        return obj && typeof obj === 'object' && obj instanceof Object && !(obj instanceof Array);
    };

    getOrgLoginId = (name) => {
        let orgName = (name as string).trim();
        orgName = this.replaceMultipleSpacesWithSingle(orgName);
        orgName = this.replaceSpecialChars(orgName);
        orgName = orgName.toLowerCase();
        const orgLoginId = orgName + '-' + this.guid(5);
        return orgLoginId;
    };

    isEquivalent = (a, b) => {
        const aProps = Object.getOwnPropertyNames(a);
        const bProps = Object.getOwnPropertyNames(b);
        if (aProps.length !== bProps.length) {
            return false;
        }
        let count = 0;
        aProps.forEach((propName) => {
            if (a[propName] !== b[propName] && typeof a[propName] !== 'object') {
                count = count + 1;
            }
        });
        if (count) {
            return false;
        } else {
            return true;
        }
    };

    getAppCategory = (tags: AssetService['tags']) => {
        let category = 'enReport';
        const displayTags = {
            enreport: 'enReport',
            eninvoice: 'enInvoice',
            ewb: 'enInvoice',
        };
        const validTags = Object.keys(displayTags);
        if (tags) {
            tags.forEach((tagObject) => {
                if (category) {
                    return;
                }
                const tag = tagObject.tagValue;
                if (validTags.indexOf(tag.toLowerCase()) >= 0) {
                    if (
                        this.R.RESTRICTEDPROCESSINGUIS &&
                        this.R.RESTRICTEDPROCESSINGUIS.length &&
                        this.R.RESTRICTEDPROCESSINGUIS.indexOf(tag.toLowerCase()) >= 0
                    ) {
                        category = this.R.DEFAULTPROCESSINGUI ? displayTags[this.R.DEFAULTPROCESSINGUI] : displayTags['enreport'];
                    } else {
                        category = displayTags[tag];
                    }
                }
            });
        }
        return category;
    };

    indexOf = (arr, item, ignoreCase?) => {
        if (!arr || !arr.length) {
            return -1;
        }
        if (!item) {
            return -1;
        }
        if (!ignoreCase) {
            return arr.indexOf(item);
        } else {
            const arr2 = arr.map((tag) => tag.toLowerCase());
            for (let i = 0; i < arr2.length; i++) {
                if (arr2[i] === item.toLowerCase()) {
                    return i;
                }
            }
        }
    };

    findParentNode = (element: Element, parentClass: string): Element => {
        if (element.parentElement) {
            const isParent = element.parentElement.classList.value.indexOf(parentClass) > -1;
            if (isParent) {
                return element;
            } else {
                return this.findParentNode(element.parentElement, parentClass);
            }
        }
    };

    isPermissionAvailable = async (permissionName, app, bridge?) => {
        const allPermissions = await this._taxilla.permissions.getPermissionsByCriteria();
        let appPermissions;
        if (bridge) {
            appPermissions =
                allPermissions?.BRIDGE_ASSET &&
                allPermissions.BRIDGE_ASSET[bridge.serviceId] &&
                allPermissions.BRIDGE_ASSET[bridge.serviceId][app.name];
        } else {
            appPermissions = allPermissions?.ASSET && allPermissions.ASSET[app.serviceId];
        }
        return appPermissions?.permissions && appPermissions.permissions[permissionName] ? true : false;
    };

    /**
     * Refresh current route
     */
    refreshCurrentRoute = () => {
        // completely removing reloading of components on switch of locations
        const currentUrl = this._router.url;
        this._router.navigateByUrl(currentUrl + '/dummy', { skipLocationChange: true }); // This is a temporary fix. TODO: Revisit it at the earliest.
        ((url) => {
            this.RouteRefreshSubscription = this._router.events.subscribe((event) => {
                if (event instanceof ChildActivationEnd) {
                    this._router.navigate([url]);
                    this.RouteRefreshSubscription && this.RouteRefreshSubscription.unsubscribe();
                }
            });
        })(currentUrl);
    };

    /**
     * {JSDoc}
     *
     * The splice() method changes the content of a string by removing a range of
     * characters and/or adding new characters.
     *
     * @param string newSubStr The String that is spliced in.
     * @param number start Index at which to start changing the string.
     * @param number delCount An integer indicating the number of old chars to remove.
     * @return string A new string with the spliced substring.
     */
    insertInString = (string: string, start: number, delCount: number, newSubStr: string): string => {
        return string.slice(0, start) + newSubStr + string.slice(start + Math.abs(delCount));
    };

    getServiceId = (bridge?: AssetService, app?: AssetService, report?: BridgeNode): string => {
        app = app || this.R.current.app;
        bridge = bridge || this.R.current.bridge;
        if (report) {
            return (bridge && bridge.serviceId) || app.serviceId;
        } else if (bridge) {
            return bridge.serviceId + '.' + app.name;
        }
        return app.serviceId;
    };

    getRestApiName = () => {
        const app = this.R.current.app;
        const report = this.R.current.report;
        if (report) {
            return report.restApiName;
        } else if (app) {
            return app.restApiName;
        }
    };

    extractFileNameFromCassLink = (cassLink) => {
        if (!cassLink) {
            return;
        }
        return cassLink.substring(cassLink.lastIndexOf('/') + 1);
    };

    convertJSONtoBlob = (object): Blob => {
        const blob = new Blob([JSON.stringify(object)], {
            type: 'application/json',
        });
        return blob;
    };

    changeFileName = (file: File, newFileName: string) => {
        const newFile = file.slice(0, file[0].size, file[0].type);
        return new File([newFile], newFileName, { type: file[0].type });
    };

    sortBridgeAssets = (bridgeNodes: Map<string, BridgeNode>): BridgeNode[] => {
        const bridges = [];
        Object.keys(bridgeNodes).forEach((key) => {
            bridges.push(bridgeNodes[key]);
        });
        const sortedBridges = bridges.sort((a: BridgeNode, b: BridgeNode) => {
            return (a.level as any as number) - (b.level as any as number);
        });
        return sortedBridges;
    };

    filterRecordsBasedOnMultipleColumns = (recordsArr: any[], filterJson: any) => {
        if (!recordsArr || !recordsArr.length) {
            return;
        }
        if (!filterJson) {
            return recordsArr;
        }
        let filteredRecordsArr = recordsArr;

        for (const column in filterJson) {
            if (filterJson[column] && filterJson[column].length) {
                filteredRecordsArr = filteredRecordsArr.filter((record) => {
                    if (record[column] !== undefined && record[column] !== null) {
                        const colval = record[column].toString();
                        return colval.toLowerCase().indexOf(filterJson[column].toLowerCase()) >= 0;
                    }
                    return false;
                });
            }
        }
        return filteredRecordsArr;
    };

    getMaproDataType = (macreDatatype: string) => {
        const map = {
            int: 'NUMBER',
            long: 'BIG_NUMBER',
            float: 'FLOAT',
            double: 'DECIMAL',
            char: 'CHAR',
            string: 'TEXT',
            date: 'TIMESTAMP',
            boolean: 'BOOLEAN',
        };
        return map[macreDatatype];
    };

    isIEBrowser = (): boolean => {
        const userAgent = window.navigator.userAgent;
        const isIE = /MSIE|Trident/.test(userAgent);
        return isIE;
    };

    downloadHelpDocuments = (docObj) => {
        if (docObj && Object.keys(docObj).length) {
            Object.keys(docObj).forEach((file) => {
                const fileUrl = docObj[file];
                const fileUrlArr = fileUrl.split('/');
                const fileName = fileUrlArr.splice(-1)[0];
                const url =
                    this.R.TAXILLA_API_URL +
                    '/process/v1/files?fileUrl=' +
                    encodeURIComponent(fileUrl) +
                    '&fileName=' +
                    encodeURIComponent(fileName);
                window.open(url, '_blank');
            });
        } else {
            this._libUtils.alertWarning('Help document is not available for this service');
        }
    };

    downloadHelpDocument = (docObj) => {
        const fileUrl = docObj.fileUrl;
        const fileUrlArr = fileUrl.split('/');
        const fileName = fileUrlArr.splice(-1)[0];
        const url =
            this.R.TAXILLA_API_URL +
            '/process/v1/files?fileUrl=' +
            encodeURIComponent(fileUrl) +
            '&fileName=' +
            encodeURIComponent(fileName);
        window.open(url, '_blank');
    };

    setTimeZoneOffset = (
        data: {
            serverTimeZoneOffset: any;
            tz: string;
        },
        callback?: (...args: any[]) => void
    ) => {
        const tz = data.tz;
        const tzOffset: any = this._commonUtils.getTimeZoneOffset(tz);
        const userTimeZoneOffset = !isNaN(parseInt(tzOffset, undefined)) ? parseInt(tzOffset) : -330;
        const serverTimeZoneOffset = !isNaN(parseInt(data.serverTimeZoneOffset, undefined)) ? parseInt(data.serverTimeZoneOffset) : 0;
        const totalOffset = userTimeZoneOffset - serverTimeZoneOffset;
        this._commonUtils.setInStorage('timeZoneOffset', totalOffset);
        this._commonUtils.setInStorage('serverTimeZoneOffset', serverTimeZoneOffset);
        this._commonUtils.setOffsetTime();
        callback && callback();
    };

    waitForAppToLoad = () => {
        return new Promise<void>((resolve) => {
            if (this.R.allSubscribedApps.value !== undefined) {
                resolve();
            } else {
                setTimeout(async () => {
                    await this.waitForAppToLoad();
                    resolve();
                }, 100);
            }
        });
    };

    stripHtml(html: string) {
        let div = document.createElement('DIV');
        div.innerHTML = html;
        let cleanText = div.innerText;
        div = null;
        return cleanText;
    }
}
