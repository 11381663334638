import { Component, Input, OnInit } from '@angular/core';

import { TableColumn } from '../../interface/table-column.interface';
import { TableCellDataTypesInterface } from '../../interface/tablecelldatatype.interface';
import { RecordField } from '../../models/record/recordfield.class';

@Component({
    selector: 'table-input',
    templateUrl: './table-input.component.html',
    styleUrls: ['./table-input.component.scss'],
})
export class TableInputComponent implements OnInit {
    @Input() column: TableColumn;
    @Input() element;
    @Input() columnId: string;
    @Input() cellDataTypes: TableCellDataTypesInterface;

    field: RecordField;
    gridCellIndex: string;

    constructor() {}

    columnValueChanged = () => {};

    ngOnInit() {
        const cell = this.cellDataTypes[this.element.rowIndex][this.columnId];
        this.gridCellIndex = this.element.rowIndex + '_' + this.columnId;
        if (cell?.lookupInformationMetadata) {
            this.field = new RecordField(undefined, undefined, undefined);
            this.field.id = cell.fieldId;
            this.field.value = this.element[this.columnId];
            this.field.entityUid = cell.entityUid;
            this.field.lookupInformation = cell.lookupInformationMetadata;
        }
    }
}
