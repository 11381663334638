<div class="workflowContainer collabConfigContainer" *transloco="let translate;">
    <div class="userMainTableConatiner">
        <div class="settingsHeader" [hidden]="hideHeader">
            <span class="settingsNameText">{{translate('Collaboration Configuration')}}</span>
            <div class="settingsDataActionsContainer" *ngIf="showAllAppsList">
                <mat-icon [title]="translate('Close')" class="closeIcon" (click)="closeDrawer.emit()">cancel</mat-icon>
            </div>
        </div>
        <div class="row CollaboarationContainer">
            <div class="col-3 collaborationAppsListContainer" *ngIf="showAllAppsList">
                <div class="collaborationAppsHeader">
                    {{translate('All Apps')}}
                </div>
                <perfect-scrollbar [style.height]="'calc(100vh - ' + containerHeightReduction + ' - 43px'">
                    <div class="collaborationAppsList">
                        <div class="searchAppInputDiv">
                            <mat-form-field class="searchTenants searchInputField">
                                <input matInput [placeholder]="translate('Search App')" #appToFocus type="text" [(ngModel)]="filterAppName">
                                <button mat-button matSuffix mat-icon-button aria-label="clear" (click)='clearSearchAppName()'>
                                    <mat-icon class='locationManagementIcons' *ngIf='filterAppName'> close </mat-icon>
                                    <mat-icon *ngIf='!filterAppName' class="locationManagementIcons"> search
                                    </mat-icon>
                                </button>
                            </mat-form-field>
                        </div>
                        <ng-container *ngFor="let app of services | FilterPipe: 'displayName' : filterAppName : null : 'name';let i = index;">
                            <ng-container *ngIf="componentType !=='BUNDLES' && app.assetType === 'BRIDGE_ASSET' && (!showOnlySelected || app.selected)">
                                <div class="collapseContainer">
                                    <a class="invoiceCollapseHeader d-flex manageSectionHeader" id="FilterHeader" aria-expanded="false" (click)="fetchChildApps(app);isMenuItemVisible[i] = !isMenuItemVisible[i]" data-toggle="collapse">
                                        <span class="display-inlineBlock">
                                            <img class="headerIcon bridgeIcon" src="/assets/images/folder.png" alt="Bridge App">
                                        </span>
                                        <span class="display-inlineBlock textOverFlowContent" style="margin-right: auto;" [title]="app.displayName|| app.name">
                                            {{app.displayName|| app.name}}
                                        </span>
                                        <span class="display-inlineBlock">
                                            <span class="material-icons" [hidden]="isMenuItemVisible[i]">keyboard_arrow_right</span>
                                            <span class="material-icons" [hidden]="!isMenuItemVisible[i]">keyboard_arrow_down</span>
                                        </span>
                                    </a>
                                    <div class="invoiceCollapseBody" id="link_{{app.restApiName}}" [hidden]="!isMenuItemVisible[i]">
                                        <ng-container *ngFor="let childAsset of app.assets">
                                            <ng-container *ngIf="childAsset.componentType !== 'FILE'">
                                                <div class="invoiceCollapseHeader manageSectionHeader" id="{{childAsset.restApiName + 'FilterHeader'}}" (click)="setAsSelectedApp(childAsset, app)" [class.selectedAsset]="selectedApp.value?.restApiName === childAsset.restApiName && selectedBridge.value?.restApiName === app.restApiName">
                                                    <span class="display-inlineBlock textOverFlowContent" [title]="childAsset.displayName || childAsset.name">
                                                        {{childAsset.displayName || childAsset.name}}
                                                    </span>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="(!app.assetType || app.assetType === 'ASSET') && (!showOnlySelected || app.selected)">
                                <div class="invoiceCollapseHeader d-flex manageSectionHeader" id="{{app.restApiName + 'FilterHeader'}}" (click)="setAsSelectedApp(app)" [class.selectedAsset]="selectedApp.value?.restApiName === app.restApiName">
                                    <span class="display-inlineBlock">
                                        <span class="display-inlineBlock">
                                            <img class="headerIcon" src="/assets/images/file.png" alt="App">
                                        </span>
                                    </span>
                                    <span class="display-inlineBlock textOverFlowContent" style="margin-right: auto" [title]="app.displayName || app.name">
                                        {{app.displayName || app.name}}
                                    </span>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </perfect-scrollbar>
            </div>
            <div class="appCollaborationConfigContainer" [class.col-9]="showAllAppsList" [class.col-12]="!showAllAppsList" style="padding: 0">
                <perfect-scrollbar *ngIf="selectedApp.value?.restApiName" [style.height]="'calc(100vh - ' + (containerHeightReduction || '158px') + ')'">
                    <div class="appCollaborationSettings">
                        <ng-container *ngIf="!workflowLoadStatus.loading">
                            <div class="row innerContent innerselectContent">
                                <div class="col-12 workflowRowSpacing">
                                    <div class="col-6 versionSelectContainer  displyInlineBlock" *ngIf='assetDetails.assets.length > 0'>
                                        <material-select [options]="assetDetails.assets" [noSelectIfValidOptionSelected]="true" [placeholder]="translate('Select Version')" [model]="selectedVersion" (optionData)="onChangeOfAssetVersion($event);" [optionDisplayNameProperty]='"version"' [optionValueProperty]='"version"' [hideSelectIfValueExists]="true">
                                        </material-select>
                                    </div>
                                    <div class="col-6 versionSelectContainer displyInlineBlock" *ngIf='assetDetails.bridgeAssets.length > 0'>
                                        <material-select [options]="assetDetails.bridgeAssets" [placeholder]="translate('Select Version')" [model]="selectedBridgeAssetVersion" (optionData)="getParticipantAssets($event);" [optionDisplayNameProperty]='"version"' [optionValueProperty]='"version"' [hideSelectIfValueExists]="true">
                                        </material-select>
                                    </div>
                                    <div class="col-6 versionSelectContainer displyInlineBlock" *ngIf='participantAssets.length > 0'>
                                        <material-select [options]="participantAssets" [placeholder]="translate('Select Asset')" [model]="selectedBridgeAssetName" (optionData)="onChangeOfAssetVersion($event);" [optionDisplayNameProperty]='"displayName"' [optionValueProperty]='"name"' [hideSelectIfValueExists]="true">
                                        </material-select>
                                    </div>
                                    <div class="col-12 displyInlineBlock">
                                        <div class="row accessApiRowContainer">
                                            <div class="labelText">
                                                {{translate('Is Auto Invite')}}:
                                            </div>
                                            <div class="col-5 acessApiradioGroup">
                                                <material-slidetoggle [model]="collabConfig.isAutoInvite" (changeToggle)="changeAutoInviteStatus('global', $event?.model)">
                                                </material-slidetoggle>
                                            </div>
                                            <div class="labelText">
                                                {{translate('Is Enabled')}}:
                                            </div>
                                            <div class="col-2 acessApiradioGroup">
                                                <material-slidetoggle [model]="collabConfig.isEnabled" (changeToggle)="changeEnabledStatus('global', $event.model)">
                                                </material-slidetoggle>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row accessApiRowContainer innerContent heading">
                                <div class="col-4">
                                    {{translate('Partner App Settings')}}
                                </div>
                                <div class="col-8 addIconDiv">
                                    <mat-icon [title]="translate('Add New Partner App Setting')" class="addIcon addIconColor ml-3" aria-hidden="true" (click)="addNewPartnerAppSetting()">add_circle</mat-icon>
                                </div>
                            </div>
                            <div class="innerContent partnerAppSetting" *ngFor="let appSetting of collabConfig.partnerAppSettings; let i = index; trackBy: trackByIndexMethod">
                                <div class="col-12 removeIconDiv" [hidden]="collabConfig.partnerAppSettings.length < 2">
                                    <mat-icon [title]="translate('Remove Partner App Setting')" class="addIcon removeIconColor ml-3" aria-hidden="true" (click)="removePartnerAppSetting(i)">remove</mat-icon>
                                </div>
                                <div class="row accessApiRowContainer topRow">
                                    <div class="col-6">
                                        <material-input [placeholder]="translate('Partner function')" [model]='appSetting.partnerFunction' (modelChange)='appSetting.partnerFunction = $event;' [required]='true' [errors]="eventErrors?.partnerAppSettings[i]?.partnerFunction"></material-input>
                                    </div>
                                    <div class="col-6">
                                        <material-select [options]="primaryEntityFields" [placeholder]="translate('Select Partner Field')" [name]="translate('Select Partner Field')" [title]="translate('Select Partner Field')" [required]="false" [model]="appSetting.partnerField" (modelChange)="appSetting.partnerField = $event;" [optionDisplayNameProperty]='"name"' [optionValueProperty]='"uid"'></material-select>
                                    </div>
                                </div>
                                <div class="targetAssetDiv sectionDiv">
                                    <div class="row accessApiRowContainer">
                                        <div class="col-12 mainLabel">
                                            <span>{{translate('Target Asset')}}</span>
                                        </div>
                                    </div>
                                    <div class="row accessApiRowContainer">
                                        <div class="col-6">
                                            <material-select [options]="services" [placeholder]="translate('Select Asset')" [name]="translate('Select Asset')" [title]="translate('Select Asset')" [required]="false" [model]="targetApp[i].name" [optionValueProperty]='"displayName"' (modelChange)="fetchVersionsOfApp(i, $event);"></material-select>
                                        </div>
                                        <div class="col-6">
                                            <material-select [options]="targetApp[i].versions" [placeholder]="translate('Select Version')" [name]="translate('Select Version')" [title]="translate('Select Version')" [required]="false" [model]="targetApp[i].version" (modelChange)="onTargetAppVersionChange(i, $event);" [optionDisplayNameProperty]='"version"' [optionValueProperty]='"uid"' [hideSelectIfValueExists]="true"></material-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="collabActionsDiv sectionDiv">
                                    <div class="row accessApiRowContainer">
                                        <div class="col-4 mainLabel">
                                            <span>{{translate('Collaboration Actions')}}</span>
                                            <span>
                                                <mat-icon [title]="translate('Add New Stage Actions')" class="addIcon addIconColor ml-3" aria-hidden="true" (click)="addNewStageActions(i)">add_circle</mat-icon>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="accessApiRowContainer collabActions" *ngFor="let collabStage of appSetting.collaborationActions | keyvalue; let j = index; trackBy: trackByIndexMethod">
                                        <mat-icon [title]="translate('Remove Stage Actions')" class="removeIcon removeIconColor ml-3" aria-hidden="true" (click)="removeStageActions(i, collabStage.key)">remove</mat-icon>
                                        <div class="row accessApiRowContainer topRow">
                                            <div class="col-6">
                                                <material-select [options]="taskDetails" [placeholder]="translate('Select Workflow Stage')" [name]="translate('Select Workflow Stage')" [title]="translate('Select Workflow Stage')" [required]="false" [model]="collabStage.key" (modelChange)="collabStage.key = $event; onCollabStageChange(i, j, $event)" [optionDisplayNameProperty]='"name"' [optionValueProperty]='"taskDefinitionKey"'></material-select>
                                            </div>
                                            <div class="col-6">
                                                <material-select [options]="currentAppWorkflowStageData[collabStage.key]" [placeholder]="translate('Select Action')" [name]="translate('Select Action')" [title]="translate('Select Action')" [required]="false" [model]="collabStage.value" (modelChange)="collabStage.value = $event; onCollabStageActionChange(i, $event, collabStage)" [isMultiple]="true"></material-select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row accessApiRowContainer">
                                    <div class="col-6">
                                        <material-select [options]="taskDetails" [placeholder]="translate('Change Allowed Stages')" [name]="translate('Change Allowed Stages')" [title]="translate('Change Allowed Stages')" [required]="false" [model]="appSetting.changeAllowedStages" (modelChange)="appSetting.changeAllowedStages = $event" [optionDisplayNameProperty]='"name"' [optionValueProperty]='"taskDefinitionKey"' [isMultiple]="true"></material-select>
                                    </div>
                                    <div class="col-6">
                                        <div class="row accessApiRowContainer">
                                            <div class="labelText">
                                                {{translate('Is Auto Invite')}}:
                                            </div>
                                            <div class="col-2 acessApiradioGroup">
                                                <material-slidetoggle [model]="appSetting.isAutoInvite" (changeToggle)="changeAutoInviteStatus(i, $event?.model)">
                                                </material-slidetoggle>
                                            </div>
                                            <div class="labelText">
                                                {{translate('Is Enabled')}}:
                                            </div>
                                            <div class="col-2 acessApiradioGroup">
                                                <material-slidetoggle [model]="appSetting.isEnabled" (changeToggle)="changeEnabledStatus(i, $event?.model)">
                                                </material-slidetoggle>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="workflowLoadStatus.loading">
                            <div class="col-3 versionSelectContainer displyInlineBlock loaderBlock isLoading" *ngIf='assetDetails.assets.length > 0'>
                                <material-select [options]="assetDetails.assets" [noSelectIfValidOptionSelected]="true" [placeholder]="translate('Select Version')" [model]="selectedVersion" (optionData)="getWorkflowStages($event);" [optionDisplayNameProperty]='"version"' [optionValueProperty]='"version"' [hideSelectIfValueExists]="true">
                                </material-select>
                            </div>
                            <div class="noWorkflowMessg displyInlineBlock loaderBlock isLoading">
                                <span>{{translate("This Asset Version doesn't have Workflow")}}</span>
                            </div>
                        </ng-container>
                        <div class="row" [hidden]="bundleId">
                            <div class="col-12 workflowSaveButton">
                                <material-button [label]="isEdit ? translate('Save Config') : translate('Create Config')" (onTrigger)="createConfig()"></material-button>
                                <material-button *ngIf="isEdit" [label]="translate('Delete Config')" [color]="'cancelBtn'" (onTrigger)="deleteConfig()"></material-button>
                            </div>
                        </div>
                    </div>
                </perfect-scrollbar>
                <div class="noAppSelected" *ngIf="!selectedApp.value?.restApiName">
                    <div class="noAppSelectedText">
                        {{translate('Select App on the left to proceed')}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
