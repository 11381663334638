import { createAction, props } from '@ngrx/store';

import { AuthorizationSystem } from '../../models/integrations/authorization-system.class';

enum AuthorizationSystemTypes {
    SET_AUTHORIZATION_SYSTEM_OPTIONS = '[AUTHORIZATIONS] Set authorization system Options',
    GET_AUTHORIZATION_SYSTEMS = '[AUTHORIZATIONS] Get Authorization Systems',
    SET_AUTHORIZATION_SYSTEMS = '[AUTHORIZATIONS] Set authorization systems',
    SET_AUTHORIZATION_SYSTEM_ID = '[AUTHORIZATIONS] Set Authorization System ID',
    SET_AUTHORIZATION_SYSTEM_NAME = '[AUTHORIZATIONS] Set Authorization System Name',
    DELETE_AUTHORIZATION_SYSTEM = '[AUTHORIZATIONS] Delete authorization stystem',
    SUBMIT_AUTHORIZATION_SYSTEM = '[AUTHORIZATIONS] Submit authorization stystem',
}

export const SetAuthorizationSystemOptions = createAction(
    AuthorizationSystemTypes.SET_AUTHORIZATION_SYSTEM_OPTIONS,
    props<{
        RESTAPI: { displayName: string; id: string }[];
        EMAIL: { displayName: string; id: string }[];
    }>()
);

export const GetAuthorizationSystems = createAction(AuthorizationSystemTypes.GET_AUTHORIZATION_SYSTEMS);

export const SetAuthorizationSystems = createAction(
    AuthorizationSystemTypes.SET_AUTHORIZATION_SYSTEMS,
    props<{
        authorizationSystems: AuthorizationSystem[];
        updated?: boolean;
    }>()
);

export const SetAuthorizationSystemId = createAction(
    AuthorizationSystemTypes.SET_AUTHORIZATION_SYSTEM_ID,
    props<{
        authorizationId: string;
    }>()
);

export const SetAuthorizationSystemName = createAction(
    AuthorizationSystemTypes.SET_AUTHORIZATION_SYSTEM_NAME,
    props<{
        name: string;
    }>()
);

export const SubmitAuthorizationSystem = createAction(
    AuthorizationSystemTypes.SUBMIT_AUTHORIZATION_SYSTEM,
    props<{
        authorizationSystem: FormData;
        update: boolean;
    }>()
);

export const DeleteAuthorizationSystem = createAction(
    AuthorizationSystemTypes.DELETE_AUTHORIZATION_SYSTEM,
    props<{
        authorizationSystem: FormData;
    }>()
);
