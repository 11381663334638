import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppTemplate, AssetData, AssetService, CommonUtilsService, Entity } from 'taxilla-library';

import {
    getChainLoadingStatus$,
    getCurrentOrganizationId$,
    getInboundLoadingStatus$,
    getInstancesLoadingStatus$,
    getInstancesStatusesLoadingStatus$,
    getOutboundLoadingStatus$,
    getReconTemplatesLoading$,
    getRequestLoading$,
    getTaggedAppServiceIds$,
    getTransformationDetailLoadingStatus$,
} from '.';
import { APPS_REDUCER_KEY } from '../reducers';
import { AppsState } from '../states';

const currentState = createFeatureSelector<AppsState>(APPS_REDUCER_KEY);

export const getAppsOrganization$ = createSelector(currentState, (state) => state.currentOrganizationId);

export const getAppDescriptions$ = createSelector(currentState, (state) => state.appsDescriptionByServiceId);

export const getAppHelpDocuments$ = createSelector(currentState, (state) => state.appsHelpDocumentsByServiceId);

export const getBridgeNodesMap$ = createSelector(currentState, (state) => state.bridgeServices);

export const getBridgeNodes$ = (assetMetaUId: AssetService['assetMetaUId']) =>
    createSelector(getBridgeNodesMap$, (nodesMap) => nodesMap?.[assetMetaUId]);

export const getSubscribedApps$ = createSelector(currentState, (state) => state.subscribedServices?.[state.currentOrganizationId]);

const getServicesMap$ = createSelector(getSubscribedApps$, (apps) =>
    apps?.reduce((map, app) => {
        map[app.serviceId] = app;
        return map;
    }, {} as { [property: string]: AssetService })
);

export const getSubscribedAppsMap$ = createSelector(
    getServicesMap$,
    getSubscribedApps$,
    getBridgeNodesMap$,
    (appsMap, apps, bridgesMap) => {
        return {
            ...(appsMap || {}),
            ...Object.keys(bridgesMap || {}).reduce((nodesMap, assetMetaUId) => {
                const app = apps?.find((service) => service.assetMetaUId === assetMetaUId);
                Object.keys(bridgesMap[assetMetaUId] || {}).forEach((nodeId) => {
                    const node = bridgesMap[assetMetaUId][nodeId];
                    nodesMap[node.serviceId] = JSON.parse(JSON.stringify(node));
                    nodesMap[node.serviceId].bridge = app;
                    nodesMap[node.serviceId].assetMetaUId = node.id;
                });
                return nodesMap;
            }, {}),
        } as { [property: string]: AssetService };
    }
);

export const getServicesApiMap$ = createSelector(getSubscribedApps$, (apps) =>
    apps?.reduce((map, app) => {
        map[app.restApiName] = app;
        return map;
    }, {} as { [property: string]: AssetService })
);

export const getSubscribedAppsApiMap$ = createSelector(
    getServicesApiMap$,
    getSubscribedApps$,
    getBridgeNodesMap$,
    (appsMap, apps, bridgesMap) => {
        return {
            ...(appsMap || {}),
            ...Object.keys(bridgesMap || {}).reduce((nodesMap, bridgeAppMetaUId) => {
                const bridgeApp = apps?.find((service) => service.assetMetaUId === bridgeAppMetaUId);
                Object.keys(bridgesMap[bridgeAppMetaUId] || {}).forEach((nodeId) => {
                    const node = bridgesMap[bridgeAppMetaUId][nodeId];
                    if (!node.restApiName) {
                        return;
                    }
                    nodesMap[node.restApiName] = JSON.parse(JSON.stringify(node));
                    nodesMap[node.restApiName].bridge = bridgeApp;
                    nodesMap[node.restApiName].assetMetaUId = node.id;
                });
                return nodesMap;
            }, {}),
        } as { [property: string]: AssetService };
    }
);

export const getApp$ = (serviceId: string) => createSelector(getSubscribedAppsMap$, (appsMap) => appsMap?.[serviceId]);

export const getAppByApiName$ = (restApiName: string) =>
    createSelector(getSubscribedApps$, (apps) => apps?.find((app) => app.restApiName === restApiName));

export const getSubscribedAppsByTag$ = (tagKey: string, tagValue: string) =>
    createSelector(getSubscribedApps$, getTaggedAppServiceIds$(tagKey, tagValue), (apps, serviceIds) =>
        apps?.filter?.((app) => serviceIds?.includes(app.serviceId))
    );

export const getAppDescription$ = (serviceId: string) =>
    createSelector(currentState, (state) => state.appsDescriptionByServiceId[serviceId]);

export const getAppsMetaDataMap$ = createSelector(currentState, (state) => state.assetsMetaData);

export const getAppsTemplate$ = createSelector(
    currentState,
    getCurrentOrganizationId$,
    (state, organizationId) => state?.appsTemplate?.[organizationId]
);

export const getAppTemplate$ = (serviceId: string) =>
    createSelector(getAppsTemplate$, (appsTemplate) => appsTemplate?.[serviceId]?.uiTemplate);

export const getTransformedAppTemplate$ = (serviceId: string) =>
    createSelector(getAppTemplate$(serviceId), getAppsMetaDataMap$, getSubscribedAppsMap$, (template, metaDataMap, appsMap) => {
        const app = appsMap[serviceId];
        return new AppTemplate(metaDataMap?.[serviceId]?.[app?.assetMetaUId], template);
    });

export const getSelectedAppEntityTemplate$ = (serviceId: string) =>
    createSelector(getTransformedAppTemplate$(serviceId), getSelectedAppEntityId$, (template, entityid) => {
        return getEntity(template?.entities || [], entityid);
    });

export const getEntity = (entities: AppTemplate['entities'] | Entity[], entityId: string) => {
    let entityFound: AppTemplate['entities'][0] = undefined;
    entities?.forEach((entity) => {
        if (entityFound) {
            return;
        }
        if (entity.uid === entityId) {
            entityFound = entity;
            return;
        }
        entity?.entities?.length > 0 && (entityFound = getEntity(entity.entities, entityId));
    });
    return entityFound;
};

export const getAppConfiguratorsMap$ = createSelector(currentState, (state) => state?.appConfigurators);

export const getAppMetaData$ = (serviceId: string, assetMetaUId?: string) =>
    createSelector(
        getAppsMetaDataMap$,
        getAppTemplate$(serviceId),
        getSubscribedAppsMap$,
        getAppConfiguratorsMap$,
        (metaDataMap, appTemplate, appsMap, appConfiguratorsMap) => {
            const app = appsMap?.[serviceId];
            const metaData = metaDataMap?.[serviceId]?.[assetMetaUId || app?.assetMetaUId] || {};
            const meta = metaData && JSON.parse(JSON.stringify(metaData));
            const template = JSON.parse(JSON.stringify((appTemplate as any) || {}));
            const assetData = new AssetData(CommonUtilsService.cloneObject(meta), template);
            assetData.configurator = appConfiguratorsMap?.[serviceId]?.[assetMetaUId || app?.assetMetaUId];
            appTemplate?.uid && meta?.uid && assetData?.configurator !== undefined && (assetData.templateUpdated = true);
            return assetData;
        }
    );

export const getAppMetaDataWorkflow$ = (serviceId: string) =>
    createSelector(getAppMetaData$(serviceId), (assetData) => assetData?.workflowMetadata);

export const getAppPrimaryEntity$ = (serviceId: string) =>
    createSelector(getAppMetaData$(serviceId), (metaData) => metaData?.entities.find((entity) => entity.primary));

export const getReconConfigurations$ = createSelector(currentState, (state) => state.reconConfiguration);

export const getReconConfiguration$ = (serviceId: string) => createSelector(getReconConfigurations$, (configs) => configs?.[serviceId]);

export const getAppsLoading$ = createSelector(currentState, (state) => state.loading);

export const getAppLoadingStatus$ = (serviceId: string) => createSelector(getAppsLoading$, (state) => state?.[serviceId]);

export const getSelectedAppServiceId$ = createSelector(currentState, (state) => state?.selectedApp?.serviceId);

export const getSelectedApp$ = createSelector(
    getSelectedAppServiceId$,
    getSubscribedAppsMap$,
    (serviceId, appsMap) => appsMap?.[serviceId]
);

const getSelectedBridgeServiceId$ = createSelector(currentState, (state) => state?.selectedApp?.bridgeServiceId);

export const getSelectedBridgeApp$ = createSelector(
    getSelectedBridgeServiceId$,
    getSubscribedAppsMap$,
    (serviceId, appsMap) => appsMap?.[serviceId]
);

export const getSelectedBridgeAssetId$ = createSelector(
    getSelectedBridgeServiceId$,
    getSubscribedAppsMap$,
    (serviceId, appsMap) => appsMap?.[serviceId]?.assetMetaUId
);

export const getSelectedAppAssetId$ = createSelector(
    getSelectedAppServiceId$,
    getSubscribedAppsMap$,
    (serviceId, appsMap) => appsMap?.[serviceId]?.assetMetaUId
);

export const getSelectedAppCategory$ = createSelector(currentState, (state) => state?.selectedApp?.category);

export const getSelectedAppEntityId$ = createSelector(currentState, (state) => state?.selectedApp?.entityId);

export const getSelectedAppMetaData$ = createSelector(
    getAppsMetaDataMap$,
    getAppsTemplate$,
    getSelectedAppServiceId$,
    getSelectedAppAssetId$,
    (metaDataMap, appTemplatesMap, serviceId, assetId) => transformMetaDataWithTemplate(metaDataMap, appTemplatesMap, serviceId, assetId)
);

export const transformMetaDataWithTemplate = (
    metaDataMap: {
        [property: string]: {
            [property: string]: AssetData;
        };
    },
    appTemplatesMap: {
        [property: string]: {
            isOfCurrentOrg: boolean;
            uiTemplate: AppTemplate;
        };
    },
    serviceId: string,
    assetId: string
) => {
    const appTemplate = appTemplatesMap?.[serviceId]?.uiTemplate;
    const meta = JSON.parse(JSON.stringify(metaDataMap?.[serviceId]?.[assetId] || {}));
    const template = JSON.parse(JSON.stringify((appTemplate as any) || {}));
    const assetData = new AssetData(meta, template);
    appTemplate?.uid && meta?.uid && (assetData.templateUpdated = true);
    return assetData;
};

export const getSelectedAppEntity$ = createSelector(getSelectedAppMetaData$, getSelectedAppEntityId$, (metaData, entityId) =>
    getSelectedEntity(metaData?.entities, entityId)
);

const getSelectedEntity = (entities: Entity[], id: string) => {
    let selectedEntity: Entity;
    entities?.find((entity) => {
        if (selectedEntity) {
            return;
        }
        selectedEntity = isSelectedEntity(entity, id);
    });
    return selectedEntity;
};

const isSelectedEntity = (entity: Entity, id: string) => {
    if (entity.uid === id) {
        return entity;
    }
    return entity?.entities?.length > 0 && getSelectedEntity(entity.entities, id);
};

export const getSelectedBridgeMetaData$ = createSelector(
    getSelectedBridgeServiceId$,
    getSelectedBridgeAssetId$,
    getAppsMetaDataMap$,
    getAppsTemplate$,
    (serviceId, assetId, metaDataMap, appTemplatesMap) => transformMetaData(serviceId, assetId, metaDataMap, appTemplatesMap)
);

const transformMetaData = (
    serviceId: string,
    assetId: string,
    metaDataMap: AppsState['assetsMetaData'],
    appTemplatesMap: AppsState['appsTemplate']['111']
) => {
    const appTemplate = appTemplatesMap?.[serviceId]?.uiTemplate;
    const meta = JSON.parse(JSON.stringify(metaDataMap?.[serviceId]?.[assetId] || {}));
    const template = JSON.parse(JSON.stringify((appTemplate as any) || {}));
    const assetData = new AssetData(meta, template);
    appTemplate?.uid && meta?.uid && (assetData.templateUpdated = true);
    return assetData;
};

export const getCompleteAppLoadingStatus$ = (serviceId: string) =>
    createSelector(
        getAppLoadingStatus$(serviceId),
        getInboundLoadingStatus$(serviceId),
        getOutboundLoadingStatus$(serviceId),
        getChainLoadingStatus$(serviceId),
        getTransformationDetailLoadingStatus$(serviceId),
        getInstancesLoadingStatus$(serviceId),
        getInstancesStatusesLoadingStatus$(serviceId),
        getRequestLoading$(serviceId),
        getReconTemplatesLoading$(serviceId),
        (app, inbound, outbound, chainNames, transformationDetail, instances, statuses, requests, templates) =>
            app?.meta ||
            app?.template ||
            app?.reconConfiguration ||
            inbound ||
            outbound ||
            chainNames ||
            transformationDetail ||
            instances ||
            statuses ||
            requests ||
            templates ||
            false
    );

export const getAppDisplayName$ = (serviceId: string) =>
    createSelector(getApp$(serviceId), getAppTemplate$(serviceId), (app, template) => template?.displayName || app?.displayName);
