import { Chain } from '../../models/chain.interface';

import type { Transformation } from '../../models/transformation';

export const transformationsState: TransformationsState = {
    organizationsMap: {},
};

export interface TransformationsState {
    organizationsMap: {
        [property: string]: {
            /* ordId */
            services: {
                /* obj */
                [property: string]: {
                    /* serviceId */
                    transformations: {
                        inboundDetailed: {
                            [property: string]: { [property: string]: Transformation };
                        };
                        inbound: Transformation[];
                        outbound: Transformation[];
                        activeTransformations: {
                            inbound: string[];
                            outbound: string[];
                        };
                        integrationsChains: {
                            inbound: Chain[];
                            outbound: Chain[];
                        };
                        masterScheduler: {
                            inbound: Chain[];
                            outbound: Chain[];
                        };
                        chainsAndTransformations: {
                            inbound: string[];
                            outbound: string[];
                        }[];
                        chains: {
                            orgId: string;
                            orgName: string;
                            values: {
                                autoGenerate: boolean;
                                category: string;
                                chainTypeMetadata: string;
                                defaulted: boolean;
                                displayName: string;
                                executionPlanMetadata: string;
                                internal: boolean;
                                isService: boolean;
                                multiRequestOnly: boolean;
                                name: string;
                                order: number;
                                orderedTransformations: [
                                    {
                                        name: string;
                                        targetStores: string[];
                                        reportNameConfig: string[];
                                    }
                                ];
                                restAPIName: string;
                            }[];
                        }[];
                    };
                    chainDisplayNames: { [property: string]: string };
                    transformationsByChainName: { [property: string]: string[] };
                    relativeOrganizations: { [property: string]: string };
                    loading: {
                        inbound: boolean;
                        outbound: boolean;
                        chainNames: boolean;
                        transformationsDetail: boolean;
                    };
                };
            };
        };
    };
}
