<mat-form-field [appearance]="appearance">
    <textarea type="text" (change)="inputChange($event)" autocomplete="off" matInput cdkTextareaAutosize cdkAutosizeMinRows="{{rows || 1}}" [rows]="rows" cdkAutosizeMaxRows="10" placeholder="{{placeholder}}" name="{{name}}" title="{{title}}" id="{{id}}" class="{{classList && classList.join(' ')}}" [formControl]="control" [errorStateMatcher]="matcher" debounce="{{debounceAfter || 1000}}" (eventHandler)="debounceEventHandler.emit($event)" (keyup)="keyUpEventHandler.emit($event)" (keypress)="errors && errors.splice(0,1);"></textarea>
    <button mat-button *ngIf="control.value && control.value.length > 0 && disabled !== true && needIcon && !(errors && errors.length > 0)" matSuffix mat-icon-button>
        <span class="material-icons userIdIcon">
            done
        </span>
    </button>
    <button mat-button *ngIf="description && description.length > 0" matSuffix mat-icon-button aria-label="Description" tabindex="-1">
        <span class="material-icons-outlined fieldDescInfoIcon" title="{{description}}">
            info
        </span>
    </button>

    <mat-hint align="end" *ngIf="maxLength">{{control.value && control.value.length || 0}} / {{maxLength}}</mat-hint>
    <mat-hint align="start" *ngIf="required && !(errors && errors.length > 0)">
        <strong>
            <span>* </span>Required</strong>
    </mat-hint>
    <mat-hint align="start" *ngIf="!required && !(errors && errors.length > 0) && hint && hint.length > 0">
        {{hint}}
    </mat-hint>
    <mat-error *ngIf="errors && errors.length > 0" title="{{errors}}">
        {{errors}}
    </mat-error>
</mat-form-field>
