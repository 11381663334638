import { createReducer, on } from '@ngrx/store';

import * as sessionActions from '../actions/session.actions';
import * as sharedActions from '../actions/shared.actions';
import { sharedState } from '../states';

export const SHARED_REDUCER = createReducer(
    sharedState,
    on(sharedActions.AlertError, (state, action) => {
        return {
            ...state,
            messages: [...state.messages, action.message],
            lastMessage: action.message,
        };
    }),
    on(sharedActions.AlertSuccess, (state, action) => {
        return {
            ...state,
            messages: [...state.messages, action.message],
            lastMessage: action.message,
        };
    }),
    on(sharedActions.SetLogoHref, (state, action) => {
        return {
            ...state,
            logoHref: action.logoHref,
        };
    }),
    on(sharedActions.SetSupportedRegions, (state, action) => {
        return {
            ...state,
            supportedRegions: action.supportedRegions,
        };
    }),

    on(sharedActions.SetNavMap, (state, { payload }) => ({
        ...state,
        leftNavMap: payload,
    })),

    on(sharedActions.SetGroupsMap, (state, { payload }) => ({
        ...state,
        groupsMap: payload,
    })),

    on(sharedActions.SetSearchedValue, (state, action) => {
        return {
            ...state,
            searchText: action.searchText,
        };
    }),

    on(sharedActions.SetSelectedCategory, (state, action) => {
        return {
            ...state,
            selectedCategory: action.selectedCategory,
        };
    }),

    on(sessionActions.SessionLogoutSuccessfull, () => sharedState)
);

export const SHARED_REDUCER_KEY = 'shared';
