<div class="integrationTabStructure integrationsPostManStructure" *transloco="let translate;">
    <div class="integrationTabHeaders">
        <ng-container *ngFor="let tab of subSection.tabs">
            <span class="integrationTabHeaderText col-2" [class.activeIntegrationTabHeader]="tab.active" [class.disabledTab]="isTabDisabled(tab, record, subSection.tabs, subSectionRecord)" (click)="setAsActiveTab(tab, subSection.tabs)">
                {{translate(tab.displayName)}}
            </span>
        </ng-container>
    </div>
    <div class="integrationSectionTabContent">
        <ng-container *ngFor="let tab of subSection.tabs">
            <ng-container *ngIf="tab.active" [ngSwitch]="tab.array">
                <ng-container *ngSwitchCase="true">
                    <div class="tabRecordsContainer" *ngIf="tab.records && tab.records.length > 0">
                        <ng-container *ngFor="let tabRecord of tab.records; let i = index">
                            <div class="tabRecordContainer row">
                                <div [ngClass]="tab.canAddProperty ? 'addRecords' : 'tabRecordFieldsContainer'" [class.hasTabRecordActions]="tabRecord.recordActions && tabRecord.recordActions.length > 0">
                                    <ng-container *ngFor="let field of tabRecord.fields">
                                        <ng-container *ngIf="!field.position">
                                            Not implemented yet
                                        </ng-container>
                                        <ng-container *ngIf="field.position === 'SBS' || field.position === 'UNIQUE-SBS'">
                                            <div class="fieldPositionSBS">
                                                <ng-container *ngTemplateOutlet="integrationField; context: { record: tabRecord, field: field, hide: hideField(field, record, tab), parent: record }"></ng-container>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </div>
                                <div [ngClass]="tab.canAddProperty ?'addRecordsActions': 'tabRecordActionsContainer'" *ngIf="(tabRecord.recordActions && tabRecord.recordActions.length > 0) || (tab.canAddProperty&& tab.canDeleteProperty)">
                                    <ng-container *ngFor="let field of tabRecord.recordActions">
                                        <div [ngClass]="field.canAddProperty ?'addRecordsActions': 'tabRecordActionContainer'">
                                            <ng-container *ngTemplateOutlet="integrationField; context: { record: tabRecord, field: field, hide: hideField(field, record, tab), parent: record }"></ng-container>
                                        </div>
                                    </ng-container>
                                    <div [ngClass]="tab.canAddProperty ?'addRecordsActions': 'tabRecordActionContainer'" *ngIf="tab.canAddRecord || tab.canAddProperty">
                                        <mat-icon (click)="addNewTabRecord(tab)">add</mat-icon>
                                    </div>
                                    <div [ngClass]="tab.canAddProperty ?'addRecordsActions': 'tabRecordActionContainer'" *ngIf="tab.canDeleteRecord || tab.canDeleteProperty">
                                        <mat-icon (click)="deleteTabRecord(tab, i)">delete</mat-icon>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="false">
                    <div class="integrationRecordField" *ngFor="let field of tab.fields">
                        <ng-container *ngTemplateOutlet="integrationField; context: { record: tab, field: field, hide: hideField(field, record, tab), parent: record }"></ng-container>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
</div>

<ng-template #integrationField let-record="record" let-field="field" let-hide="hide" let-parent="parent">
    <ng-container *ngIf="field.displayName !== 'Key' && field.displayName !== 'Value' && field.displayName !== 'Raw Text' && field.displayName !== 'Body Type'">
        <div class="col-5">
            <app-integrations-field-template [subTenants]="subTenants" [masterTables]="masterTables" [record]="record" [field]="field" [hide]="hide" [parentRecord]="parent" [masterTableColumns]="masterTableColumns" (checkValidateButton)="checkValidateButton.emit($event)"></app-integrations-field-template>
        </div>
    </ng-container>
    <ng-container *ngIf="field.displayName === 'Key' || field.displayName === 'Value' || field.displayName === 'Raw Text' || field.displayName === 'Body Type'">
        <app-integrations-field-template [subTenants]="subTenants" [masterTables]="masterTables" [record]="record" [field]="field" [hide]="hide" [parentRecord]="parent"></app-integrations-field-template>
    </ng-container>
</ng-template>
