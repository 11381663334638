import { Component } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { environment } from '@env';
import { translate } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';

import { ApiService } from '../../services/api/api.service';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { FileUpload } from '../../services/file-upload/fileuploadservice';
import { UtilsService } from '../../services/utils/utils.service';
import { getSessionUserId$ } from '../../store/selectors';

@Component({
    selector: 'app-digital-signature',
    templateUrl: './digital-signature.component.html',
    styleUrls: ['./digital-signature.component.scss'],
})
export class DigitalSignatureComponent {
    userDscFileUrl: string;
    userDscFileName: string;
    userDscImgUrl: string;
    userDscImgName: string;
    errors: { [property: string]: string[] } = {};
    showDscUploadBtns = false;
    userDscUpload = {
        filename: '',
        url: '',
        title: translate('Browse'),
        return: true,
    };
    userDscUpload2 = {
        filename: '',
        url: '',
        title: translate('Browse'),
        return: true,
    };
    supportedFileTypes = ['.pfx', '.p12'];
    supportedImageTypes = ['.png', '.jpg', '.jpeg'];
    tempFile: any;
    tempImage: any;
    updateUserObject: any;
    organizationId = this._commonUtils.getFromStorage('currentOrganizationId');
    isFileTypeSupported = true;
    isImageTypeSupported = true;
    files: any[] = [undefined, undefined];
    isPassphraseDisabled = true;
    filenames: any[] = [undefined, undefined];
    fileName = translate('No file chosen') as string;
    fileName2 = translate('No image chosen') as string;
    details = [];
    passPhrase: string;
    lableName = 'change';
    private unSubscribe = new Subject<void>();
    private userId: string;

    constructor(
        public dialog: MatDialog,
        private _api: ApiService,
        private _commonUtils: CommonUtilsService,
        private fileupload: FileUpload,
        public _utils: UtilsService,
        private store$: Store
    ) {}

    closeModal = () => {
        this.dialog.closeAll();
    };

    initBrowse = () => {
        this.userDscUpload.filename = this.fileName;
        this.userDscUpload.url = `${environment.taxilla_user_identity_api}/v1/users/${this.userId}?action=SIGNATURE`;
        this.userDscUpload.title = translate('Browse');
    };

    newFileValue = (file) => {
        this.tempFile = file[0];
    };

    newImageValue = (file) => {
        this.tempImage = file[0];
    };

    onFileTypeSupportCheck = (data: boolean) => {
        this.isFileTypeSupported = data;
        if (data) {
            this.isPassphraseDisabled = false;
            this.files[0] = this.tempFile;
            this.filenames[0] = 'certificate';
        }
    };

    onImageTypeSupportCheck = (data: boolean) => {
        this.isImageTypeSupported = data;
        if (data) {
            this.isPassphraseDisabled = false;
            this.files[1] = this.tempImage;
            this.filenames[1] = 'signImage';
        }
    };

    private getDigitalSignature = () => {
        this._api.user.getDigitalSignatureDetails({
            successCallback: (res) => {
                if (res.passphrase) {
                    this.passPhrase = res.passphrase;
                }
                if (res.signatureFileName) {
                    this.userDscFileName = res.signatureFileName;
                }
                if (res.signatureImageFileName) {
                    this.userDscImgName = res.signatureImageFileName;
                }
            },
            failureCallback: () => {},
        });
    };

    uploadUserDsc = () => {
        const data = {
            filename: this.filenames,
            httpMethod: 'PUT',
            passphrase: this.passPhrase,
        };
        this.fileupload.upload(
            this.files,
            data,
            this.userDscUpload.url,
            undefined,
            () => {
                this._utils.alertSuccess(translate('Digital Signature uploaded successfully'));
                this.getDigitalSignature();
                this.closeModal();
            },
            (res) => {
                this._utils.alertError((res && res.msg) || translate('Failed to upload digital signature'));
            }
        );
    };

    ngOnInit(): void {
        this.getDigitalSignature();
        this.store$
            .select(getSessionUserId$)
            .pipe(takeUntil(this.unSubscribe))
            .subscribe((userId) => {
                this.userId = userId;
            });
    }

    ngOnDestroy() {
        this.unSubscribe.next();
        this.unSubscribe.complete();
    }
}
