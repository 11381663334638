<div class="!min-h-[4%] d-flex justify-content-between align-items-center">
    <h2 class="col-11 !m-0" mat-dialog-title>{{ doc?.fileName }} Preview</h2>
    <div class="col-1 d-flex justify-end align-items-center">
        <a [href]="docData.fileLink" target="_blank" rel="noopener" style="cursor: pointer; display: flex;">
            <span class="material-symbols-outlined">
                download
            </span>
        </a>
        <span class="material-symbols-outlined pl-[20px]" mat-dialog-close>close</span>
    </div>
</div>
<mat-dialog-content class="mat-typography !m-0 !p-0 !overflow-auto !max-h-[96%] px-2" *transloco="let translate;">
    <ng-container *ngIf="doc?.acceptheader === 'application/pdf'">
        <iframe [src]='doc?.fileUrl' title="{{translate('Doc File Url')}}" width="100%" height="100%" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
    </ng-container>

    <ng-container *ngIf="doc?.acceptheader === 'text/html'">
        <div [innerHTML]="docData.htmlContent"></div>
    </ng-container>

    <ng-container *ngIf="doc?.acceptheader === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'">
        <iframe [src]='doc?.fileUrl' title="{{translate('Doc File Url')}}" width="100%" height="100%" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
    </ng-container>

    <ng-container *ngIf="doc?.acceptheader === 'text/plain'">
        <div class="!pt-[30px] text-xl">
            <pre>{{ docData.htmlContent }}</pre>
        </div>
    </ng-container>
</mat-dialog-content>
