import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class NavigationService {
    private history: string[] = [];
    previousUrl: string;

    constructor(private router: Router, private location: Location) {
        this.previousUrl = this.router.url;
    }

    startSaveHistory(): void {
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
            this.history.push(this.previousUrl);
        });
    }

    getHistory(): string[] {
        return this.history;
    }

    goBack(): void {
        if (this.history.length > 0) {
            this.location.back();
        } else {
            this.router.navigateByUrl('/');
        }
    }
}
