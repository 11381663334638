import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';

import { ConfirmationComponent } from '../../components/confirmation/confirmation.component';

@Injectable({
    providedIn: 'root',
})
export class ConfirmationDialogService {
    private dialogRef: MatDialogRef<ConfirmationComponent>;

    constructor(private dialog: MatDialog) {}

    public confirm(title: string, message: string, btnOkText: string = 'Ok', btnCancelText: string = 'Cancel'): Observable<any> {
        this.dialogRef = this.dialog.open(ConfirmationComponent, {
            panelClass: ['confirmationDialog', 'matDialogContainer'],
            disableClose: true,
        });
        this.dialogRef.componentInstance.title = title;
        this.dialogRef.componentInstance.message = message;
        this.dialogRef.componentInstance.btnOkText = btnOkText;
        this.dialogRef.componentInstance.btnCancelText = btnCancelText;
        return this.dialogRef.afterClosed();
    }
}
