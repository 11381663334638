import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { Search, SearchSource } from '../../models/search.class';

@Component({
    selector: 'app-search-builder',
    templateUrl: './search-builder.component.html',
    styleUrls: ['./search-builder.component.scss'],
})
export class SearchBuilderComponent implements OnInit, OnChanges {
    @Input() search: Search;
    @Input() triggerOrigin: string;
    @Input() isSubSourceVisible: { [property: string]: boolean } = {};
    @Input() searchType: 'SEARCH' | 'RELATION';
    @Input() showQueryBuilderDirectly: boolean;
    @Input() relationConfigExists: boolean;
    @Input() disabled: boolean;

    public queryType: 'searchQuery' | 'relationQuery';
    public configType: 'searchConfig' | 'relationConfig';
    public changeObserver: 'searchQueryConfigChange' | 'relationQueryConfigChange';

    constructor() {}

    ngOnChanges(_changes: SimpleChanges): void {
        if (this.search.needSourceStructure) {
            this.toggleEntities();
        }
    }

    private applyType = () => {
        switch (this.searchType) {
            case 'RELATION':
                this.queryType = 'relationQuery';
                this.configType = 'relationConfig';
                this.changeObserver = 'relationQueryConfigChange';
                break;
            default:
                this.queryType = 'searchQuery';
                this.configType = 'searchConfig';
                this.changeObserver = 'searchQueryConfigChange';
                break;
        }
    };

    toggleEntities = () => this.toggle();

    public toggle = async () => {
        const sourcesContainingSubs = this.search.sources.filter((source) => source.sources.length);
        const uids = await this.findParentWithChildSource(sourcesContainingSubs);
        if (uids?.length > 1) {
            this.toggleParentAndChildSources(uids);
        }
    };

    private findParentWithChildSource = async (sources: SearchSource[]) => {
        const uids = await Promise.all(
            sources.map(async (source: SearchSource) => {
                return await this.getParentSourceWithChildSource(source);
            })
        );
        return uids;
    };

    private getParentSourceWithChildSource = (source: SearchSource) => {
        const sourceId = this.search.selectedSource.id;
        return new Promise((resolve) => {
            const uids = this.getIds(source, sourceId);
            if (uids) {
                resolve(uids);
            } else {
                resolve([]);
            }
        });
    };

    private getIds = (source: SearchSource, sourceId: string) => {
        if (source.id === sourceId) {
            return [source.id];
        }
        if (source.sources || Array.isArray(source)) {
            let children = Array.isArray(source) ? source : source.sources;
            for (let child of children) {
                let result = this.getIds(child, sourceId);
                if (result) {
                    if (source.id) {
                        result.unshift(source.id);
                    }
                    return result;
                }
            }
        }
    };

    private toggleParentAndChildSources = (ids: any[]) => {
        const orderUids = [...ids.filter((uid) => uid.length)];
        ids = orderUids[0];
        if (ids?.length > 1) {
            if (ids.includes(this.search.selectedSource.id)) {
                if (this.search.origin === 'PROCESS') {
                    ids.length = 1;
                }
                ids.forEach((sourceId) => {
                    // const element = this._elementRef.nativeElement.querySelector(`.${sourceId}`) as HTMLElement;
                    // element?.click();
                    this.isSubSourceVisible[sourceId] = true;
                });
            }
        }
    };

    trackByIndexMethod(index: number): any {
        return index;
    }

    ngOnInit(): void {
        this.applyType();
        const allowedOrigins = ['revealFilters', 'editSaveFilter'];
        if (this.triggerOrigin && allowedOrigins.includes(this.triggerOrigin)) {
            this.toggleEntities();
        }
    }
}
