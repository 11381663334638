import { Report, UIReport } from 'taxilla-library';

export const reportsState: ReportsState = {
    currentOrganizationId: undefined,
    instanceIdVsReports: {},
    loadingReports: {},
    organizationsMap: {},
};

export interface ReportsState {
    currentOrganizationId: string;
    instanceIdVsReports: {
        [property: string]: UIReport[];
    };
    loadingReports: {
        [property: string]: boolean;
    };

    organizationsMap: {
        [property: string]: {
            // organizationId vs serviceIdMap
            [property: string]: {
                // serviceId vs searchReports Map
                searchReports: UIReport[];
                searchReportsPagination: {
                    pageSize: number;
                    pageIndex: number;
                    pagingState: any;
                    timeRange: any[];
                };
                downloadedSearchReports: {
                    [property: string]: Report;
                };
            };
        };
    };
}
