import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { environment } from '@env';
import { NgRXStoreModule, TaxillaLibraryModule } from 'taxilla-library';

import * as configuration from '../../environments/configuration.json';
import { AppRoutingModule } from './app-routing.module';
import { components } from './components';
import { AppComponent } from './components/app/app.component';
import { CustomsnackbarComponent } from './components/customsnackbar/customsnackbar.component';
import { LoaderComponent } from './components/loader/loader.component';
import { PasswordexpireComponent } from './components/passwordexpire/passwordexpire.component';
import { PostLoginFooterComponent } from './components/postloginfooter/postloginfooter.component';
import { RedirectComponent } from './components/redirect/redirect.component';

export function initApp(http: HttpClient) {
    return () => {
        const origin = window.location.origin;
        let config = {};
        return http
            .get(origin + '/ui-config')
            .toPromise()
            .then((res) => {
                config = res;
            })
            .catch(() => {
                config = configuration;
            })
            .finally(() => {
                resolveConfigs(config);
            });

        function resolveConfigs(response) {
            Object.assign(environment, response);
        }
    };
}

@NgModule({
    declarations: [
        AppComponent,
        LoaderComponent,
        CustomsnackbarComponent,
        PostLoginFooterComponent,
        RedirectComponent,
        PasswordexpireComponent,
        ...components,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule,
        MatIconModule,
        MatCardModule,
        TaxillaLibraryModule,
        AppRoutingModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatMenuModule,
        MatButtonModule,
        NgRXStoreModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initApp,
            multi: true,
            deps: [HttpClient],
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
