import { createReducer, on } from '@ngrx/store';

import * as actions from '../actions';
import { reportsState } from '../states';

export const REPORTS_REDUCER = createReducer(
    reportsState,
    on(actions.SetCurrentOrganization, (state, action) => {
        return {
            ...state,
            currentOrganizationId: action.organization?.id,
        };
    }),
    on(actions.SetReports, (state, action) => {
        return {
            ...state,
            instanceIdVsReports: {
                ...state.instanceIdVsReports,
                [action.instanceId]: action?.reports,
            },
        };
    }),
    on(actions.UpdateSearchReportsPagination, (state, action) => {
        const orgId = state?.currentOrganizationId;
        if (orgId === undefined || orgId?.length === 0) {
            return;
        }
        const pagination: any = state?.organizationsMap?.[orgId]?.[action.serviceId]?.searchReportsPagination || {};
        return {
            ...state,
            organizationsMap: {
                ...state.organizationsMap,
                [orgId]: {
                    ...(state.organizationsMap[orgId] || {}),
                    [action.serviceId]: {
                        ...state.organizationsMap[orgId]?.[action.serviceId],
                        searchReportsPagination: {
                            ...pagination,
                            pageIndex: action?.pageIndex !== undefined ? action?.pageIndex : pagination?.pageIndex,
                            pageSize: action?.pageSize !== undefined ? action?.pageSize : pagination?.pageSize,
                            pagingState: action?.pageSize === pagination?.pageSize ? pagination?.pagingState : undefined,
                            timeRange: action?.timeRange !== undefined ? action?.timeRange : pagination?.timeRange,
                        },
                    },
                },
            },
        };
    }),
    on(actions.ClearSearchReportsPagination, (state, action) => {
        const orgId = state?.currentOrganizationId;
        if (orgId === undefined || orgId?.length === 0) {
            return;
        }
        return {
            ...state,
            organizationsMap: {
                ...state.organizationsMap,
                [orgId]: {
                    ...(state.organizationsMap[orgId] || {}),
                    [action.serviceId]: {
                        ...state.organizationsMap[orgId]?.[action.serviceId],
                        searchReportsPagination: undefined,
                    },
                },
            },
        };
    }),
    on(actions.SetSearchReports, (state, action) => {
        const orgId = state.currentOrganizationId;
        if (orgId === undefined || orgId?.length === 0) {
            return;
        }
        const reports = [...new Set([...(state.organizationsMap[orgId]?.[action.serviceId]?.searchReports || []), ...action.reports])];
        return {
            ...state,
            organizationsMap: {
                ...state.organizationsMap,
                [orgId]: {
                    ...(state.organizationsMap[orgId] || {}),
                    [action.serviceId]: {
                        ...state.organizationsMap[orgId]?.[action.serviceId],
                        searchReports: reports,
                        searchReportsPagination: {
                            ...state.organizationsMap[orgId]?.[action.serviceId]?.searchReportsPagination,
                            pagingState: action.pagingState,
                        },
                    },
                },
            },
        };
    }),
    on(actions.ClearSearchReports, (state, action) => {
        const orgId = state.currentOrganizationId;
        if (orgId === undefined || orgId?.length === 0) {
            return state;
        }
        return {
            ...state,
            organizationsMap: {
                ...state.organizationsMap,
                [orgId]: {
                    ...(state.organizationsMap[orgId] || {}),
                    [action.serviceId]: {
                        ...state.organizationsMap[orgId]?.[action.serviceId],
                        searchReports: [],
                    },
                },
            },
        };
    }),
    on(actions.SetDownloadableSearchReports, (state, action) => {
        const orgId = state.currentOrganizationId;
        if (orgId === undefined || orgId?.length === 0) {
            return state;
        }
        return {
            ...state,
            organizationsMap: {
                ...state.organizationsMap,
                [orgId]: {
                    ...state.organizationsMap?.[orgId],
                    [action.serviceId]: {
                        ...state.organizationsMap?.[orgId]?.[action.serviceId],
                        downloadedSearchReports: action.reports,
                    },
                },
            },
        };
    }),
    on(actions.ClearInstanceData, (state, action) => {
        return {
            ...state,
            instanceIdVsReports: {
                ...state.instanceIdVsReports,
                [action.instanceId]: undefined,
            },
        };
    }),
    on(actions.LoadingReports, (state, action) => {
        return {
            ...state,
            loadingReports: {
                ...state.loadingReports,
                [action.instanceId]: action.loading,
            },
        };
    }),
    on(actions.SessionLogoutSuccessfull, () => reportsState)
);

export const REPORTS_REDUCER_KEY = 'reports';
