import { FilteringAttribute } from '@encomply/components/dashboard-settings/dashboard-filtering-attributes.component';

import { Dashboard } from '../../interface/dashboard.interface';

export const dashboardState: DashboardState = {
    currentOrganizationId: '',
    selectedDashboard: undefined,
    analyticDashboards: {},
    isDashboardsLoading: undefined,
};

export interface DashboardState {
    currentOrganizationId: string;
    selectedDashboard: Dashboard;
    analyticDashboards: {
        // orgid vs details
        [property: string]: {
            dashboards: [];

            filteringAttributes: {
                // serviceId vs filteringAttributes
                [property: string]: {
                    nameVsValues: { [key: string]: any };
                    metadata: FilteringAttribute[];
                };
            };
        };
    };
    isDashboardsLoading: boolean;
}
