import { createFeatureSelector, createSelector } from '@ngrx/store';

import { DASHBOARD_REDUCER_KEY } from '../reducers';
import { DashboardState } from '../states';
import { getCurrentOrganizationId$ } from './session.selector';

const currentState = createFeatureSelector<DashboardState>(DASHBOARD_REDUCER_KEY);

export const getDashboardOrganizationMap$ = createSelector(
    currentState,
    getCurrentOrganizationId$,
    (state, organizationId) => state.analyticDashboards?.[organizationId]
);

export const getDashboardLoader$ = createSelector(currentState, (state) => state.isDashboardsLoading);

export const getSelectedDashboard$ = createSelector(currentState, (state) => state.selectedDashboard);

export const getAnalyticsDashboards$ = createSelector(getDashboardOrganizationMap$, (organizationMap) => organizationMap?.dashboards);

export const getFilteringAttributesMap$ = createSelector(
    getDashboardOrganizationMap$,
    (organizationMap) => organizationMap?.filteringAttributes
);

export const getSelectedFilteringAttributes$ = createSelector(
    getFilteringAttributesMap$,
    getSelectedDashboard$,
    (filteringAttributesMap, selectedDashboard) => filteringAttributesMap?.[selectedDashboard?.serviceId]
);

export const getFilteringAttributesNameVsValues$ = createSelector(
    getSelectedFilteringAttributes$,
    (filteringAttributes) => filteringAttributes?.nameVsValues
);

export const getFilteringAttributesMetadata$ = createSelector(
    getSelectedFilteringAttributes$,
    (filteringAttributes) => filteringAttributes?.metadata
);
