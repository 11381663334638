import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AssetService } from '../../models/assetservice.class';
import { BridgeNode } from '../../models/bridgeNode.interface';
import { AlertError } from '../../store/actions';
import { Service } from '../../store/states';
import { BridgeService } from '../bridge/bridge.service';
import { CommonUtilsService } from '../commonutils/common-utils.service';
import { RootScopeService } from '../rootscope/rootscope.service';
import { StoreService } from '../store/store.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
    providedIn: 'root',
})
export class SubscriptionsService {
    constructor(
        private _bridge: BridgeService,
        private _utils: UtilsService,
        protected R: RootScopeService,
        private _store: StoreService,
        private store$: Store
    ) {}

    /**
     * Method to fetch subscribe services
     * @param data contains organizationId
     * @param callbacks contains success and failure calls
     */
    searchSubscribedServicesByOrganizationId = async (
        data: {
            organizationId: any;
            noAlerts?: boolean;
        },
        callbacks?: {
            successCallback: (services: AssetService[]) => void;
            failureCallback: (res) => void;
        }
    ) => {
        const appsResponse: any = await this._store.publicScope.fetchValues(
            () => {
                return new Promise((resolve) => {
                    this._bridge.searchSubscribedServicesByOrganizationId(
                        data,
                        (res) => {
                            resolve(res?.response || []);
                        },
                        (res) => {
                            callbacks.failureCallback(res);
                        }
                    );
                });
            },
            'searchApps',
            data.organizationId
        );
        callbacks.successCallback(appsResponse?.['searchApps']?.[data.organizationId] || []);
    };

    /**
     * Method to fetch subscribe services
     * @param data contains organizationId
     * @param callbacks contains success and failure calls
     */
    getSubscribedServices = async (
        data?: {
            noAlerts?: boolean;
        },
        callbacks?: {
            successCallback: (
                services: {
                    id?: string;
                    createdDate?: string;
                    lastModifiedDate?: string;
                    name?: string;
                    restApiName?: string;
                    serviceId?: string;
                    assetMetaUId?: string;
                    assetType?: string;
                    filableReport?: boolean;
                }[]
            ) => void;
            failureCallback: (res) => void;
        },
        byPass?: boolean
    ) => {
        byPass && this._store.privateScope.clearValue('services');
        const appsResponse: any = await this._store.privateScope.fetchValues(() => {
            return new Promise(async (resolve, reject) => {
                this._bridge.getSubscribedServices(
                    data,
                    (res) => {
                        const apps = (res && res.response) || [];
                        apps &&
                            apps.forEach((app) => {
                                app.originalLastModifiedDate = app.lastModifiedDate;
                                app.lastModifiedDate = CommonUtilsService.transformDateToLocale(
                                    app.originalLastModifiedDate,
                                    'YYYY-MM-DDTHH:MM:SSZ',
                                    'DD-MM-YYYY HH:MM:SS AM',
                                    false
                                );
                            });
                        resolve(apps);
                    },
                    (res) => {
                        callbacks?.failureCallback?.(res);
                        reject(res?.msg);
                    }
                );
            });
        }, 'services');
        if (callbacks?.successCallback) {
            callbacks.successCallback(appsResponse?.['services'] || []);
        } else {
            return appsResponse?.['services'] || [];
        }
    };

    /**
     * Fetching enreconcile services
     * @param data contains organizationId
     * @param callbacks contains success and failure calls
     */
    getReconciliationServices = (byPass?: boolean, noAlerts?: boolean) => {
        return new Promise<AssetService[]>(async (appsResolve) => {
            byPass && this._store.privateScope.clearValue('reconciliationServices');
            const appsResponse: any = await this._store.privateScope.fetchValues(() => {
                return new Promise(async (resolve, reject) => {
                    this._bridge.getReconciliationServices(
                        {
                            noAlerts,
                        },
                        (res) => {
                            const apps = res?.response || [];
                            apps.forEach((app) => {
                                app.originalLastModifiedDate = app.lastModifiedDate;
                                app.lastModifiedDate = CommonUtilsService.transformDateToLocale(
                                    app.originalLastModifiedDate,
                                    'YYYY-MM-DDTHH:MM:SSZ',
                                    'DD-MM-YYYY HH:MM:SS AM',
                                    false
                                );
                            });
                            resolve(apps);
                        },
                        (res) => {
                            this._utils.alertError(res?.msg || 'Failed to get reconciliation services');
                            reject();
                        }
                    );
                });
            }, 'reconciliationServices');
            appsResolve(appsResponse?.['reconciliationServices'] || []);
        });
    };

    /**
     * Method to fetch subscribe services
     * @param data contains organizationId
     * @param callbacks contains success and failure calls
     */
    getCollaboratedApps = async (
        data?: {
            noAlerts?: boolean;
        },
        callbacks?: {
            successCallback: (
                services: {
                    id?: string;
                    createdDate?: string;
                    lastModifiedDate?: string;
                    name?: string;
                    restApiName?: string;
                    serviceId?: string;
                    assetMetaUId?: string;
                    assetType?: string;
                    filableReport?: boolean;
                }[]
            ) => void;
            failureCallback?: (res) => void;
        },
        byPass?: boolean
    ) => {
        byPass && this._store.privateScope.clearValue('services');
        const appsResponse: any = await this._store.privateScope.fetchValues(() => {
            return new Promise(async (resolve, reject) => {
                this._bridge.getCollaboratedApps(
                    data,
                    (res) => {
                        const apps = res?.response?.services || [];
                        apps?.forEach((app) => {
                            app.originalLastModifiedDate = app.lastModifiedDate;
                            app.lastModifiedDate = CommonUtilsService.transformDateToLocale(
                                app.originalLastModifiedDate,
                                'YYYY-MM-DDTHH:MM:SSZ',
                                'DD-MM-YYYY HH:MM:SS AM',
                                false
                            );
                        });
                        resolve(apps);
                    },
                    (res) => {
                        callbacks?.failureCallback?.(res);
                        reject();
                    }
                );
            });
        }, 'collaboratedServices');
        callbacks.successCallback(appsResponse?.['collaboratedServices'] || []);
    };

    /**
     * Method to fetch services underlying bridge
     * @param data contains assetservice properties
     * @param callbacks contains success and failure calls
     */
    getBridgeNodes = (
        data: { assetMetaUId: string },
        callbacks?: {
            successCallback: (bridgeNodes: {
                [property: string]: {
                    name?: string;
                    id?: string;
                    version?: string;
                    componentType?: string;
                    level?: string;
                    restApiName?: string;
                    nextNodes?: string[];
                };
            }) => void;
            failureCallback: (res) => void;
        }
    ) => {
        if (this.R.bridgeNodes[data.assetMetaUId]) {
            callbacks.successCallback(this.R.bridgeNodes[data.assetMetaUId] as any);
        } else {
            this._bridge.getBridgeNodes(
                data,
                (res) => {
                    this.R.bridgeNodes[data.assetMetaUId] = res && res.response;
                    callbacks.successCallback(this.R.bridgeNodes[data.assetMetaUId] as any);
                },
                (res) => {
                    callbacks.failureCallback(res);
                }
            );
        }
    };

    /**
     * Method to fetch services underlying bridge
     * @param assetMetaUId is asset id
     * @param callbacks contains success and failure calls
     */
    fetchBridgeNodes = (assetMetaUId: string): Observable<{ [property: string]: BridgeNode }> => {
        return new Observable((observer) => {
            this._bridge
                .fetchServicesUnderlyingBridge(assetMetaUId)
                .then(async (nodes) => {
                    await this.fetchServiceIdOfNodes(nodes, true);
                    observer.next(nodes);
                    observer.complete();
                })
                .catch((e) => {
                    observer.error(e?.msg || 'Failed to ge bridge nodes');
                    observer.complete();
                });
        });
    };

    /**
     * Method to fetch services underlying bridge
     * @param assetMetaUId is asset id
     * @param callbacks contains success and failure calls
     */
    fetchServicesUnderlyingBridge = async (
        assetMetaUId: string,
        callbacks?: {
            successCallback: (bridgeNodes: {
                [property: string]: {
                    componentType?: string;
                    id?: string;
                    level?: number;
                    name?: string;
                    nextNodes?: string[];
                    restApiName?: string;
                    version?: string;
                    displayName?: string;
                };
            }) => void;
            failureCallback: (res) => void;
        },
        custom?: any,
        ngRxStore = false
    ) => {
        if (ngRxStore) {
            return new Observable((observer) => {
                this._bridge
                    .fetchServicesUnderlyingBridge(assetMetaUId, custom)
                    .then(async (nodes) => {
                        await this.fetchServiceIdOfNodes(nodes, ngRxStore);
                        observer.next(nodes);
                        observer.complete();
                    })
                    .catch((e) => {
                        observer.error(e?.msg || 'Failed to ge bridge nodes');
                        observer.complete();
                    });
            });
        }
        const appsResponse: any = await this._store.publicScope.fetchValues(
            () => {
                return new Promise(async (resolve) => {
                    this._bridge
                        .fetchServicesUnderlyingBridge(assetMetaUId, custom)
                        .then(async (nodes) => {
                            await this.fetchServiceIdOfNodes(nodes);
                            resolve(nodes);
                        })
                        .catch((e) => {
                            this._utils.alertError(e?.msg || 'Failed to ge bridge nodes');
                            resolve(undefined);
                        });
                });
            },
            'bridgeNodes',
            assetMetaUId
        );
        const response = appsResponse?.['bridgeNodes']?.[assetMetaUId];
        if (callbacks?.successCallback) {
            callbacks.successCallback(response);
        } else {
            return response;
        }
    };

    private fetchServiceIdOfNodes = (
        bridgeNodes: {
            [property: string]: {
                componentType?: string;
                id?: string;
                level?: number;
                name?: string;
                nextNodes?: string[];
                restApiName?: string;
                version?: string;
            };
        },
        ngRxStore = false
    ) => {
        const promises = [];
        Object.keys(bridgeNodes).forEach((nodeId) => {
            promises.push(this.getServiceIdOfNode(bridgeNodes[nodeId], ngRxStore));
        });
        return Promise.all(promises);
    };

    private getServiceIdOfNode = async (
        node: {
            componentType?: string;
            id?: string;
            level?: number;
            name?: string;
            nextNodes?: string[];
            restApiName?: string;
            version?: string;
        },
        ngRxStore = false
    ) => {
        const serviceDetails = await this.getServiceDetails(node.id, ngRxStore);
        node['serviceId'] = serviceDetails?.serviceId;
    };

    /**
     * Method to fetch services underlying bridge using serviceId
     * @param data contains assetservice properties
     * @param callbacks contains success and failure calls
     */
    fetchAppsUnderlyingBridge = (
        data: {
            serviceId: string;
            showLoader?: boolean;
        },
        callbacks?: {
            successCallback: (res) => void;
            failureCallback: (res) => void;
        }
    ) => {
        this._bridge.fetchAppsUnderlyingBridge(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to index the app
     * @param data contains assetservice properties
     * @param callbacks contains success and failure calls
     */
    indexAppOrMasterData = (
        data: {
            orgId: string;
            restApiName: string;
            fromDate?: number;
            toDate?: number;
        },
        callbacks?: {
            successCallback: (res) => void;
            failureCallback: (res) => void;
        }
    ) => {
        this._bridge.indexAppOrMasterData(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to get services
     * @param callbacks contains success and failure calls
     */
    getServiceDetails = async (
        assetMetaUId: string,
        ngRxStore = false
    ): Promise<{
        id?: string;
        repositoryId?: string;
        metadataType?: string;
        metadataName?: string;
        metadataVersion?: string;
        metadataId?: string;
        tableName?: string;
        publicationState?: string;
        serviceId?: any;
        migrated?: boolean;
    }> => {
        if (ngRxStore) {
            const promise = this._bridge.getServiceDetails(assetMetaUId);
            promise.catch((e) => this._utils.alertError(e?.msg || 'Failed to get service details'));
            return promise;
        }
        const appsResponse: any = await this._store.publicScope.fetchValues(
            () => {
                const promise = this._bridge.getServiceDetails(assetMetaUId);
                promise.catch((e) => this._utils.alertError(e?.msg || 'Failed to get service details'));
                return promise;
            },
            'serviceDetails',
            assetMetaUId
        );
        return appsResponse?.['serviceDetails']?.[assetMetaUId] || {};
    };

    /**
     * Method to subscribe to services
     * @param data contains organizationId and payload
     * @param callbacks contains success and failure calls
     */
    subscribeToServices = (data: {
        organizationId: any;
        payload: {
            orgIds: any[];
            itemIds: any[];
        };
    }) => {
        return new Observable((observer) => {
            this._bridge.subscribeToServices(
                data,
                (res) => {
                    observer.next(res?.response);
                    observer.complete();
                },
                (res) => {
                    observer.error(res?.msg);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to subscribe to services
     * @param data contains organizationId and payload
     * @param callbacks contains success and failure calls
     */
    updateSubscribedServices = (data: {
        organizationId: any;
        payload: {
            subscriptionIds: any[];
            status: string;
        };
    }) => {
        return new Observable((observer) => {
            this._bridge.updateSubscribedServices(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res?.msg);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to fetch subscribed widgets
     * @param data contains organizationId
     * @param callbacks contains success and failure calls
     */
    fetchSubscribedWidgets = (
        data: {
            organizationId: string;
        },
        callbacks?: {
            successCallback: (res) => void;
            failureCallback: (res) => void;
        }
    ) => {
        this._bridge.fetchSubscribedWidgets(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to fetch subscribed Assets
     * @param callbacks contains success and failure calls
     */
    public fetchSubscribedAssets = (data: {
        type?: string;
        pageIndex: number;
        size: number;
        organizationId: any;
        subscriptionType?: string;
        status?: string;
        orgIdArray?: any[];
        itemIdArray?: any[];
        noVariableLoading?: boolean;
        getByPagination?: boolean;
    }) => {
        return this.fetchSubscribedAssetsByPagination(data);
    };

    private fetchSubscribedAssetsByPagination = (data: {
        type?: string;
        pageIndex: number;
        size: number;
        organizationId: any;
        subscriptionType?: string;
        status?: string;
        orgIdArray?: any[];
        itemIdArray?: any[];
        noVariableLoading?: boolean;
        getByPagination?: boolean;
    }) => {
        return new Promise<{ services: any[]; orgIdVsName?: any }>((resolve) => {
            this._bridge.fetchSubscribedAssets(
                data,
                async (res) => {
                    let services = res?.response?.subscriptions.reduce((subscriptions, service) => {
                        service.serviceType = res?.response?.serviceIdVsType[service?.serviceId];
                        service.userName = res?.response?.userIdVsName[service?.createdByUserId];
                        service.displayName = service.displayName || service.serviceName || service.itemName;
                        subscriptions.push(service);
                        return subscriptions;
                    }, []);
                    if (data.size === services.length && !data.getByPagination) {
                        data.pageIndex += 1;
                        const nextSetData = await this.fetchSubscribedAssetsByPagination(data);
                        services = [...services, ...nextSetData.services];
                    }
                    resolve({
                        services,
                        orgIdVsName: res?.response?.orgIdVsName,
                    });
                },
                (res) => {
                    this.store$.dispatch(
                        AlertError({
                            message: res?.msg,
                        })
                    );
                    resolve({ services: [] });
                }
            );
        });
    };

    /**
     * Method to fetch subscribed Assets
     * @param callbacks contains success and failure calls
     */
    subscribedAssetsCount = (data: {
        organizationId: any;
        subscriptionType: string;
        status?: string;
        orgIdArray?: any[];
        itemIdArray?: any[];
    }) => {
        return new Observable((observer) => {
            this._bridge.subscribedAssetsCount(
                data,
                (res) => {
                    observer.next(res?.response?.count || 0);
                    observer.complete();
                },
                (res) => {
                    observer.error(res?.msg);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to get widgets
     * @param callbacks contains success and failure calls
     */
    getAllOrganizations = (callbacks?: { successCallback: (res) => void; failureCallback: (res) => void }) => {
        this._bridge.getAllOrganizations(
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to get widgets
     * @param callbacks contains success and failure calls
     */
    searchAllOrganizations = (data: { [property: string]: string | boolean }) => {
        return new Observable<any[]>((observer) => {
            this._bridge.searchAllOrganizations(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to get widgets
     * @param callbacks contains success and failure calls
     */
    getAllAssets = (
        data: { id: string },
        callbacks?: {
            successCallback: (res) => void;
            failureCallback: (res) => void;
        }
    ) => {
        this._bridge.getAllAssets(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to get widgets
     * @param callbacks contains success and failure calls
     */
    getAssets = async (data: {
        type?: string;
        pageIndex: number;
        size: number;
        organizationId: any;
        subscriptionType?: string;
        status?: string;
        orgIdArray?: any[];
        itemIdArray?: any[];
        noVariableLoading?: boolean;
        getByPagination?: boolean;
    }) => {
        data.type = data.type || 'any';
        if (data.getByPagination) {
            return this.fetchSubscribedAssets(data);
        }
        return this.getAssetsByAllStatus(data);
    };

    private getAssetsByAllStatus = (data: {
        type?: string;
        pageIndex: number;
        size: number;
        organizationId: any;
        subscriptionType?: string;
        status?: string;
        orgIdArray?: any[];
        itemIdArray?: any[];
        noVariableLoading?: boolean;
        getByPagination?: boolean;
    }): Promise<{
        allApps: Service[];
        orgIdVsName: any;
    }> => {
        return new Promise((resolve) => {
            Promise.all([this.getAssetsByPagination(data), this.fetchSubscribedAssets(data)]).then(
                ([allApps, { services, orgIdVsName }]) => {
                    allApps.forEach((app) => {
                        app.restApiName = app['restApiServiceName'];
                        app.assetMetaUId = app['itemId'];
                        delete app['restApiServiceName'];
                    });
                    services.forEach((service) => {
                        delete service.organizationId;
                        const app = allApps.find((item) => item.serviceId === service.serviceId);
                        app && Object.assign(app, service);
                    });
                    resolve({
                        allApps: allApps.sort((a, b) => {
                            const aName = a.displayName || a.itemName;
                            const bName = b.displayName || b.itemName;
                            if (aName > bName) {
                                return 1;
                            } else if (aName < bName) {
                                return -1;
                            } else {
                                return 0;
                            }
                        }),
                        orgIdVsName,
                    });
                }
            );
        });
    };

    /**
     * Method to get widgets
     * @param callbacks contains success and failure calls
     */
    private getAssetsByPagination = async ({
        pageIndex,
        size,
        type,
        getByPagination,
    }: {
        type?: string;
        pageIndex: number;
        size: number;
        getByPagination?: boolean;
    }): Promise<Service[]> => {
        pageIndex = pageIndex || 1;
        size = size || 100;
        return new Promise((resolve) => {
            this._bridge.getAssets(
                { pageIndex, size, type },
                async (res) => {
                    let services = res?.response?.records || [];
                    services.forEach((service) => {
                        service.assetType = service.type;
                    });
                    if (services.length === size && !getByPagination) {
                        services = [
                            ...services,
                            ...(await this.getAssetsByPagination({ pageIndex: pageIndex + 1, size, type, getByPagination })),
                        ];
                    }
                    resolve(services);
                },
                (res) => {
                    this.store$.dispatch(
                        AlertError({
                            message: res?.msg || 'Failed to get services',
                        })
                    );
                    resolve([]);
                }
            );
        });
    };

    /**
     * Method to get recently accessed services
     * @param callbacks contains success and failure calls
     */
    getRecentlyAccessedServices = (callbacks?: { successCallback: (res) => void; failureCallback: (res) => void }) => {
        this._bridge.getRecentlyAccessedServices(
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to fetch bridge metadata
     * @param data contains assetservice properties
     * @param callbacks contains success and failure calls
     */
    getBridgeMetaData = (
        data: { assetMetaUId: string; hideAlerts?: boolean },
        callbacks?: {
            successCallback: (res) => void;
            failureCallback: (res) => void;
        }
    ) => {
        this._bridge.getBridgeMetaData(
            data,
            (res) => {
                callbacks.successCallback((res && res.response) || {});
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };
}
