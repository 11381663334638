import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AlertError } from '../../store/actions';
import { BridgeService } from '../bridge/bridge.service';

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {
    constructor(private _bridge: BridgeService, private store$: Store) {}

    getDashboards = (noAlerts?: boolean) => {
        return this._bridge.getDashboards(noAlerts);
    };

    getQuickSightDashboardURL = (data: { serviceId: string; noAlerts?: boolean }) => {
        return this._bridge.getQuickSightDashboardURL(data);
    };

    refreshQuicksightDashboard = (data: { widgetId: string; noAlerts?: boolean }) => {
        return this._bridge.refreshQuicksightDashboard(data);
    };

    quickSightDashboardStatus = (data: { widgetId: string; noAlerts?: boolean }) => {
        return this._bridge.quickSightDashboardStatus(data);
    };

    public getAnalyticsSearchCriteria = (data: {
        chainRestApiName: string;
        sourceName: string;
        searchArguments: { [property: string]: string };
        serviceId: string;
    }) => {
        const criteriaPromise = this._bridge.getAnalyticsSearchCriteria(data);
        criteriaPromise.catch((e) => {
            this.store$.dispatch(
                AlertError({
                    message: e.msg,
                })
            );
        });
        return criteriaPromise;
    };

    /**
     * method to get dashboard Filtering Attributes NameVsValues
     * @param data  Contains dashboard service id
     * @param callbacks Contains Success Callback Method, Failure Callback Method
     */
    public getFilteringAttributesNameVsValues = (serviceId: string) => {
        return new Observable((observer) => {
            this._bridge.getFilteringAttributesNameVsValues(
                serviceId,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * method to save dashboard Filtering Attributes
     * @param data  Contains dashboard service id
     * @param callbacks Contains Success Callback Method, Failure Callback Method
     */
    public saveFilteringAttributes = (data: { payload: any; serviceId: string }) => {
        return new Observable((observer) => {
            this._bridge.saveFilteringAttributes(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * method to get dashboard Filtering Attributes Metadata
     * @param data  Contains dashboard service id
     * @param callbacks Contains Success Callback Method, Failure Callback Method
     */
    public getFilteringAttributesMetadata = (serviceId: string) => {
        return new Observable((observer) => {
            this._bridge.getFilteringAttributesMetadata(
                serviceId,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };
}
