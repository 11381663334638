import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'ObjNgFor',
})
export class ObjNgFor implements PipeTransform {
    transform(value: any): any {
        const keys = [];
        for (const key in value) {
            keys.push(key);
        }
        return keys;
    }
}
