import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of, Subject } from 'rxjs';
import { catchError, delay, map, mergeMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { CommonUtilsService } from 'taxilla-library';

import { ApiService } from '../../services/api/api.service';
import { UtilsService } from '../../services/utils/utils.service';
import { AlertError, LoadingBulkReportsError } from '../actions';
import * as actions from '../actions/instances.actions';
import {
    currentFilingMonth$,
    currentFilingQuarter$,
    currentFilingYear$,
    getAppConfiguratorsMap$,
    getAppsMetaDataMap$,
    getCurrentOrganizationId$,
    getInstancesEntityStatus$,
    getInstancesOrgServicesMap$,
    getInstancesState$,
    getOTPAuthApp$,
    getSearchCriteriaMap$,
    getSubscribedApps$,
    getSubscribedAppsMap$,
} from '../selectors';

@Injectable()
export class InstancesEffects {
    private searchingInstances: {
        [property: string]: Subject<void>;
    } = {};

    constructor(private actions$: Actions, private _api: ApiService, private store$: Store) {}

    getInstance = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.GetInstance),
            delay(500),
            withLatestFrom(this.store$.select(getSubscribedAppsMap$)),
            mergeMap(([action, services]) => {
                const app = services?.[action.serviceId];
                if (!app) {
                    return [];
                }
                return from(
                    this._api.instances.getInstance({
                        instanceId: action.instanceId,
                        noAlerts: action.noAlerts,
                        restApiName: app.restApiName,
                        serviceId: app.serviceId,
                    })
                ).pipe(
                    map((res) =>
                        actions.SetInstance({
                            instance: res,
                            instanceId: action.instanceId,
                        })
                    )
                );
            })
        )
    );

    searchInstances$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.SearchInstances),
            delay(10),
            withLatestFrom(
                this.store$.select(getCurrentOrganizationId$),
                this.store$.select(getInstancesOrgServicesMap$),
                this.store$.select(getSearchCriteriaMap$)
            ),
            mergeMap(([action, organizationId, servicesMap, searchCriteriaMap]) => {
                const userCriteria = CommonUtilsService.cloneObject(
                    searchCriteriaMap?.[action.serviceId]?.instances?.[action.criteriaName]
                );
                const serviceMap = servicesMap?.[action.serviceId];
                const pagination = serviceMap?.pagination;
                if (serviceMap?.loading?.instances && !action.forceFetch) {
                    return [];
                }
                const expectedCount = ((pagination?.pageIndex || 0) + 1) * (pagination?.size || 0);
                const storedCount = serviceMap?.searchedInstances?.[action.criteriaName]?.length;
                if (this.searchingInstances[action.serviceId]) {
                    this.searchingInstances[action.serviceId]?.next();
                    this.searchingInstances[action.serviceId]?.complete();
                    delete this.searchingInstances[action.serviceId];
                }
                if (storedCount >= expectedCount && !action.forceFetch) {
                    return [];
                }
                if (storedCount > 0 && !pagination?.pagingState && !action.forceFetch) {
                    return [];
                }
                if (pagination?.size === undefined) {
                    setTimeout(() => {
                        this.store$.dispatch(actions.SearchInstances(action));
                    }, 100);
                    return [];
                }
                !action.isBackgroundProcess &&
                    this.store$.dispatch(
                        actions.SetInstancesLoading({
                            category: 'instances',
                            loading: true,
                            organizationId,
                            serviceId: action.serviceId,
                        })
                    );
                const searchPromise = this._api.instances.searchInstances({
                    ...userCriteria,
                    noAlerts: action.noAlerts,
                    size: pagination?.size,
                    searchAfter: !action.forceFetch ? pagination?.pagingState : undefined,
                });
                this.searchingInstances[action.serviceId] = new Subject();
                return from(searchPromise).pipe(
                    takeUntil(this.searchingInstances[action.serviceId]),
                    map((res) => {
                        this.searchingInstances[action.serviceId]?.next();
                        this.searchingInstances[action.serviceId]?.complete();
                        delete this.searchingInstances[action.serviceId];
                        !action.isBackgroundProcess &&
                            this.store$.dispatch(
                                actions.SetInstancesLoading({
                                    category: 'instances',
                                    loading: false,
                                    organizationId,
                                    serviceId: action.serviceId,
                                })
                            );
                        this.store$.dispatch(
                            actions.SetInstancesPagingState({
                                organizationId,
                                serviceId: action.serviceId,
                                pagingState: pagination?.size === res?.instances?.length ? res?.searchAfter : undefined,
                            })
                        );
                        this.store$.dispatch(
                            actions.SetSearchInstancesResponse({
                                instances: res.instances,
                                serviceId: action.serviceId,
                                criteriaName: action.criteriaName,
                                organizationId,
                                module: action.module,
                                forceFetch: action.forceFetch,
                            })
                        );
                        return [];
                    }),
                    catchError(() => {
                        !action.isBackgroundProcess &&
                            this.store$.dispatch(
                                actions.SetInstancesLoading({
                                    category: 'instances',
                                    loading: false,
                                    organizationId,
                                    serviceId: action.serviceId,
                                })
                            );
                        this.searchingInstances[action.serviceId]?.next();
                        this.searchingInstances[action.serviceId]?.complete();
                        delete this.searchingInstances[action.serviceId];
                        return [];
                    })
                );
            })
        )
    );

    getGstFilingInstance$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.GetFilingInstances),
            delay(500),
            withLatestFrom(
                this.store$.select(getCurrentOrganizationId$),
                this.store$.select(getAppsMetaDataMap$),
                this.store$.select(currentFilingYear$),
                this.store$.select(currentFilingMonth$),
                this.store$.select(currentFilingQuarter$),
                this.store$.select(getInstancesOrgServicesMap$),
                this.store$.select(getSubscribedAppsMap$),
                this.store$.select(getSearchCriteriaMap$),
                this.store$.select(getAppConfiguratorsMap$)
            ),
            mergeMap(
                ([
                    action,
                    organizationId,
                    assetsMetaDataMap,
                    year,
                    month,
                    quarter,
                    servicesMap,
                    appsMap,
                    searchCriteriaMap,
                    getAppConfiguratorsMap,
                ]) => {
                    const app = appsMap?.[action?.serviceId];
                    const metaData = assetsMetaDataMap?.[action.serviceId]?.[app?.assetMetaUId];
                    const frequency = appsMap?.[action.serviceId]?.tags?.find((tag) => tag.tagKey === 'frequency');
                    const userCriteria = CommonUtilsService.cloneObject(searchCriteriaMap?.[action.serviceId]?.instances?.['filing']);
                    if (frequency?.tagValue === 'yearly' && !year) {
                        return [];
                    }
                    if (frequency?.tagValue === 'monthly' && (!year || !month)) {
                        return [];
                    }
                    if (!metaData) {
                        !action.isBackgroundProcess &&
                            setTimeout(() => {
                                this.store$.dispatch(actions.GetFilingInstances(action));
                            }, 2000);
                        return [];
                    }
                    const yearValue = frequency?.tagValue === undefined ? undefined : year;
                    const monthValue = frequency?.tagValue === 'monthly' ? month : undefined;
                    const quaterValue = frequency?.tagValue === 'quarterly' ? quarter : undefined;
                    const criteriaName = `${yearValue || ''}|${monthValue || ''}|${quaterValue || ''}`;
                    const appConfigurator = getAppConfiguratorsMap?.[action.serviceId]?.[app?.assetMetaUId];
                    const searchCriteria =
                        userCriteria ||
                        UtilsService.getGSTRFilingSearchCriteria(
                            appConfigurator,
                            metaData,
                            action.serviceId,
                            yearValue,
                            monthValue,
                            quaterValue
                        );
                    searchCriteria['noAlerts'] = action.noAlerts;
                    const loading = servicesMap?.[action.serviceId]?.loading?.instances;
                    if (loading) {
                        return [];
                    }
                    !action.isBackgroundProcess &&
                        this.store$.dispatch(
                            actions.SetInstancesLoading({
                                category: 'instances',
                                loading: true,
                                organizationId,
                                serviceId: action.serviceId,
                            })
                        );
                    return from(this._api.instances.searchInstances(searchCriteria as any)).pipe(
                        map((res) => {
                            !action.isBackgroundProcess &&
                                this.store$.dispatch(
                                    actions.SetInstancesLoading({
                                        category: 'instances',
                                        loading: false,
                                        organizationId,
                                        serviceId: action.serviceId,
                                    })
                                );
                            return actions.SetSearchInstancesResponse({
                                instances: res.instances,
                                serviceId: action.serviceId,
                                criteriaName,
                                organizationId,
                            });
                        }),
                        catchError(() =>
                            !action.isBackgroundProcess
                                ? of(
                                      actions.SetInstancesLoading({
                                          category: 'instances',
                                          loading: false,
                                          organizationId,
                                          serviceId: action.serviceId,
                                      })
                                  )
                                : []
                        )
                    );
                }
            )
        )
    );

    getOTPInstances$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.GetOTPInstance),
            delay(500),
            withLatestFrom(
                this.store$.select(getCurrentOrganizationId$),
                this.store$.select(getOTPAuthApp$),
                this.store$.select(getSubscribedApps$),
                this.store$.select(getAppsMetaDataMap$),
                this.store$.select(getInstancesOrgServicesMap$)
            ),
            mergeMap(([action, organizationId, otpApp, apps, assetsMetaDataMap, servicesMap]) => {
                if (!otpApp?.serviceId && apps.length > 0) {
                    return [];
                }
                const metaData = assetsMetaDataMap?.[otpApp?.serviceId]?.[otpApp?.assetMetaUId];
                if (!metaData) {
                    this.store$.dispatch(actions.GetOTPInstance(action));
                    return [];
                }
                const criteriaName = action.gstinNumber;
                const searchCriteria = UtilsService.getGSTROTPSearchCriteria(metaData, action.gstinNumber);
                const loading = servicesMap?.[otpApp.serviceId]?.loading?.statuses;
                if (loading) {
                    return [];
                }
                this.store$.dispatch(
                    actions.SetInstancesLoading({
                        category: 'instances',
                        loading: true,
                        organizationId,
                        serviceId: otpApp.serviceId,
                    })
                );
                return from(
                    this._api.instances.searchInstances({
                        ...searchCriteria,
                        noAlerts: true,
                    } as any)
                ).pipe(
                    map((res) => {
                        this.store$.dispatch(
                            actions.SetInstancesLoading({
                                category: 'instances',
                                loading: false,
                                organizationId,
                                serviceId: otpApp.serviceId,
                            })
                        );
                        return actions.SetSearchInstancesResponse({
                            instances: res.instances,
                            serviceId: otpApp.serviceId,
                            criteriaName,
                            organizationId,
                        });
                    }),
                    catchError(() =>
                        of(
                            actions.SetInstancesLoading({
                                category: 'instances',
                                loading: false,
                                organizationId,
                                serviceId: otpApp.serviceId,
                            })
                        )
                    )
                );
            })
        )
    );

    getInstanceEntityErrors$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.GetInstanceState),
            delay(500),
            withLatestFrom(this.store$.select(getSubscribedAppsMap$), this.store$.select(getInstancesEntityStatus$)),
            mergeMap(([action, services, instanceErrors]) => {
                const service = services[action.serviceId];
                const errors = instanceErrors?.[action.instanceId];
                if (errors !== undefined) {
                    return [];
                }
                return from(
                    this._api.instances.checkInstanceStatus({
                        instanceId: action.instanceId,
                        restApiName: service.restApiName,
                        noAlerts: action.noAlerts,
                    })
                ).pipe(
                    map((res: { [property: string]: string }) =>
                        actions.SetInstanceEntityErrors({
                            instanceId: action.instanceId,
                            state: res,
                        })
                    ),
                    catchError(() => [])
                );
            })
        )
    );

    getInstanceErrors$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.GetInstanceState),
            delay(500),
            withLatestFrom(this.store$.select(getSubscribedAppsMap$), this.store$.select(getInstancesState$)),
            mergeMap(([action, services, instancesErrors]) => {
                const service = services[action.serviceId];
                const errors = instancesErrors?.[action.instanceId];
                if (errors !== undefined) {
                    return [];
                }
                return from(
                    this._api.instances.getInstanceState({
                        instanceId: action.instanceId,
                        restApiName: service.restApiName,
                        noAlerts: action.noAlerts,
                    })
                ).pipe(
                    map((res) =>
                        actions.SetInstanceState({
                            instanceId: action.instanceId,
                            state: res?.instanceInfo,
                        })
                    ),
                    catchError(() => [])
                );
            })
        )
    );

    cancelInstances$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.CancelInstances),
            delay(500),
            withLatestFrom(
                this.store$.select(getSubscribedAppsMap$),
                this.store$.select(getCurrentOrganizationId$),
                this.store$.select(currentFilingYear$),
                this.store$.select(currentFilingMonth$),
                this.store$.select(currentFilingQuarter$)
            ),
            mergeMap(([action, services, organizationId, year, month, quarter]) => {
                const service = services[action.serviceId];
                const searchCriteriaString = action.searchCriteriaString || `${year || ''}|${month || ''}|${quarter || ''}`;
                return from(
                    this._api.instances.cancelInstancesViaPromise({
                        instanceId: action.instanceIds,
                        restApiName: service.restApiName,
                        comment: action.comment,
                    })
                ).pipe(
                    map(() =>
                        actions.RemoveInstances({
                            instanceIds: action.instanceIds,
                            serviceId: action.serviceId,
                            organizationId,
                            criteria: searchCriteriaString,
                        })
                    ),
                    catchError(() => [])
                );
            })
        )
    );

    getProcessStatuses$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.GetProcessStatuses),
            delay(500),
            withLatestFrom(this.store$.select(getCurrentOrganizationId$), this.store$.select(getInstancesOrgServicesMap$)),
            mergeMap(([action, organizationId, servicesMap]) => {
                const loading = servicesMap?.[action.serviceId]?.loading?.statuses;
                if (loading) {
                    return [];
                }
                this.store$.dispatch(
                    actions.SetInstancesLoading({
                        category: 'statuses',
                        loading: true,
                        organizationId,
                        serviceId: action.serviceId,
                    })
                );
                return from(
                    this._api.requests.getStatusBasedProcesses({
                        serviceId: action.serviceId,
                        noAlerts: action.noAlerts,
                    })
                ).pipe(
                    map((res: any) => {
                        this.store$.dispatch(
                            actions.SetInstancesLoading({
                                category: 'statuses',
                                loading: false,
                                organizationId,
                                serviceId: action.serviceId,
                            })
                        );
                        return actions.SetProcessStatuses({
                            organizationId: organizationId,
                            serviceId: action.serviceId,
                            statuses: res.response.statuses,
                        });
                    }),
                    catchError((e) => {
                        this.store$.dispatch(
                            actions.SetInstancesLoading({
                                category: 'statuses',
                                loading: false,
                                organizationId,
                                serviceId: action.serviceId,
                            })
                        );
                        return of(AlertError({ message: e }));
                    })
                );
            })
        )
    );

    selectProcessStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.SelectProcessStatus),
            delay(500),
            withLatestFrom(this.store$.select(getCurrentOrganizationId$)),
            mergeMap(([action, organizationId]) =>
                of(
                    actions.SetSelectedProcessStatus({
                        organizationId: organizationId,
                        serviceId: action.serviceId,
                        processStatus: action.processStatus,
                    })
                )
            )
        )
    );

    getInstanceByProcessStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.GetInstancesByProcessStatus),
            delay(500),
            withLatestFrom(
                this.store$.select(getCurrentOrganizationId$),
                this.store$.select(getAppsMetaDataMap$),
                this.store$.select(currentFilingYear$),
                this.store$.select(currentFilingMonth$),
                this.store$.select(currentFilingQuarter$),
                this.store$.select(getInstancesOrgServicesMap$),
                this.store$.select(getSubscribedAppsMap$),
                this.store$.select(getAppConfiguratorsMap$)
            ),
            mergeMap(([action, organizationId, assetsMetaDataMap, year, month, quarter, servicesMap, appsMap, appConfiguratorsMap]) => {
                const app = appsMap?.[action?.serviceId];
                const metaData = assetsMetaDataMap?.[action.serviceId]?.[app?.assetMetaUId];
                if (!metaData) {
                    this.store$.dispatch(actions.GetFilingInstances(action));
                    return [];
                }
                const searchCriteriaString = `${year || ''}|${month || ''}|${quarter || ''}`;
                const appContigurator = appConfiguratorsMap?.[action.serviceId]?.[app.assetMetaUId];
                const searchCriteria = UtilsService.getGSTRFilingSearchCriteria(
                    appContigurator,
                    metaData,
                    action.serviceId,
                    year,
                    month,
                    quarter,
                    undefined,
                    action.processStatus
                );
                searchCriteria['noAlerts'] = action.noAlerts;
                const loading = servicesMap?.[action.serviceId]?.loading?.statuses;
                if (loading) {
                    return [];
                }
                this.store$.dispatch(
                    actions.SetInstancesLoading({
                        category: 'instances',
                        loading: true,
                        organizationId,
                        serviceId: action.serviceId,
                    })
                );
                return from(this._api.instances.searchInstances(searchCriteria as any)).pipe(
                    map((res) => {
                        this.store$.dispatch(
                            actions.SetInstancesLoading({
                                category: 'instances',
                                loading: false,
                                organizationId,
                                serviceId: action.serviceId,
                            })
                        );
                        return actions.SetInstancesByProcessStatus({
                            organizationId,
                            serviceId: action.serviceId,
                            criteria: searchCriteriaString,
                            processStatus: action.processStatus,
                            instances: res.instances.reduce((instanceIds, instance) => {
                                instanceIds.push(instance.assetDataId);
                                return instanceIds;
                            }, []),
                        });
                    }),
                    catchError(() =>
                        of(
                            actions.SetInstancesLoading({
                                category: 'instances',
                                loading: false,
                                organizationId,
                                serviceId: action.serviceId,
                            })
                        )
                    )
                );
            })
        )
    );

    CreateBulkReportsDownloadRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.CreateBulkReportsDownloadRequest),
            delay(500),
            withLatestFrom(
                this.store$.select(getSubscribedAppsMap$),
                this.store$.select(currentFilingYear$),
                this.store$.select(currentFilingMonth$),
                this.store$.select(currentFilingQuarter$),
                this.store$.select(getInstancesOrgServicesMap$)
            ),
            mergeMap(([action, subscribedApps, year, month, quarter, instancesMap]) => {
                const app = subscribedApps?.[action.serviceId];
                const selectedInstanceIds =
                    instancesMap?.[action.serviceId]?.selectedInstances?.[`${year || ''}|${month || ''}|${quarter || ''}`];
                const payload = {
                    transformations: action.transformationNames,
                    instanceIds: selectedInstanceIds,
                    searchCriteria: action.criteria,
                };
                return from(
                    this._api.instances.createDownloadRequest({
                        restApiName: app.restApiName,
                        payload: payload,
                    })
                ).pipe(
                    map((res: any) =>
                        actions.SetBulkReportsDownloadRequest({
                            downloadUrl: res?.downloadRequest?.response?.url,
                            requestId: res?.downloadRequest?.response?.requestId || res?.response?.requestId,
                            serviceId: action.serviceId,
                        })
                    ),
                    catchError((e) => {
                        this.store$.dispatch(LoadingBulkReportsError());
                        return of(AlertError({ message: e?.msg }));
                    })
                );
            })
        )
    );

    clearSearchInstances$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.ClearSearchedInstances),
            withLatestFrom(this.store$.select(getCurrentOrganizationId$)),
            mergeMap(([{ serviceId, searchString }, organizationId]) => {
                this.store$.dispatch(
                    actions.ClearInstancesPagination({
                        serviceId,
                        organizationId,
                    })
                );
                return of(
                    actions.ClearSearchInstances({
                        serviceId: serviceId,
                        criteriaName: searchString,
                        organizationId,
                    })
                );
            })
        )
    );
}
