import { createFeatureSelector, createSelector } from '@ngrx/store';

import { TAGS_REDUCER_KEY } from '../reducers';
import { getCurrentOrganizationId$ } from './session.selector';

import type { TagsState } from '../states';
const currentState = createFeatureSelector<TagsState>(TAGS_REDUCER_KEY);

export const getTagsOrganizationMap$ = createSelector(
    currentState,
    getCurrentOrganizationId$,
    (state, organizationId) => state.organizationsMap?.[organizationId]
);

export const getTagSubscriptionStatus$ = createSelector(getTagsOrganizationMap$, (orgMap) => orgMap?.subscriptionStatus);

export const getTagKeys$ = createSelector(getTagsOrganizationMap$, (orgMap) => orgMap?.tagKeys || []);

export const getTagKey$ = createSelector(getTagsOrganizationMap$, (orgMap) => orgMap?.currentTagKey);

export const getTagKeyValues$ = createSelector(getTagsOrganizationMap$, (orgMap) => orgMap?.tagValues?.[orgMap?.currentTagKey] || []);

export const getTagsObject$ = createSelector(getTagsOrganizationMap$, (orgMap) => orgMap?.tagKeyValuesVsAppDetails);

export const getTaggedApps$ = createSelector(
    getTagsObject$,
    getTagsOrganizationMap$,
    (tagsMap, orgMap) => tagsMap?.[`${orgMap?.currentTagKey}|${orgMap?.currentTagValue}|${orgMap?.subscriptionStatus}`] || []
);

export const getTaggedAppServiceIds$ = (key: string, value: string, status?: string) =>
    createSelector(getTagsObject$, (tagsMap) =>
        (tagsMap?.[`${key}|${value}|${status || 'APPROVED'}`] || []).reduce((serviceIds, data) => {
            serviceIds.push(data.serviceId);
            return serviceIds;
        }, [])
    );

export const getTagValueVsServiceIds$ = (key: string, value: string, status?: string, array?: boolean) =>
    createSelector(getTagsOrganizationMap$, (orgMap) => {
        if (array) {
            return (orgMap?.tagKeyValuesVsAppDetails?.[`${key}|${value}|${status || 'APPROVED'}`] || []).reduce((values, data) => {
                values.push(data?.serviceId);
                return values;
            }, []);
        }
        return (orgMap?.tagKeyValuesVsAppDetails?.[`${key}|${value}|${status || 'APPROVED'}`] || []).reduce(
            (tagValueMap, data) => {
                tagValueMap[data.tagValue] = data?.serviceId;
                return tagValueMap;
            },
            {} as {
                [property: string]: string;
            }
        );
    });

export const getAssetsTags$ = createSelector(getTagsOrganizationMap$, (orgMap) => orgMap?.serviceIdVsTags);

export const getAssetTags$ = (serviceId: string) => createSelector(getAssetsTags$, (serviceIdsMap) => serviceIdsMap?.[serviceId]);
