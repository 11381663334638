import { Directive, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env';
import { Store } from '@ngrx/store';
import { BroadcasterService } from 'ng-broadcaster';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { GetSessionDetails } from '../../store/actions';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[HubCrossStorage]',
})
export class HubStorageDirective implements OnInit, OnDestroy {
    unSubscribe = new Subject<void>();
    organizationId: string;
    userId: string;

    constructor(
        private _broadcaster: BroadcasterService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _commonUtils: CommonUtilsService,
        private store$: Store
    ) {}

    @HostListener('window:blur', ['$event.target'])
    onBlur = () => {};

    @HostListener('window:focus', ['$event.target'])
    public onFocus = () => {
        if (window.location.pathname.indexOf('redirect') > -1) {
            return;
        }
        const enComply = environment['encomply-ui'];
        const enInvoice = environment['eninvoice-ui'];
        const enCollab = environment['encollab-ui'];
        const routeData = this.getMatchingRoute();
        const organization = this._commonUtils.getFromStorage('currentOrganization');
        const user = this._commonUtils.getFromStorage('user');
        const organizationId = organization?.id;
        const userId = user?.id;
        const session = this._commonUtils.getFromStorage('session');
        if (routeData) {
            if (!routeData.secured) {
                if (session) {
                    if (window.location.href.indexOf('returnUrl') > -1) {
                        const searchText = window.location.search;
                        if (searchText && searchText.indexOf('http') > -1) {
                            const returnString = decodeURIComponent(searchText && searchText.substring(searchText.indexOf('http')));
                            window.location.href =
                                returnString.indexOf(enComply) >= 0 ||
                                returnString.indexOf(enInvoice) >= 0 ||
                                returnString.indexOf(enCollab) >= 0
                                    ? returnString
                                    : window.location.origin;
                        } else {
                            let returnString = decodeURIComponent(searchText);
                            returnString = returnString.substring(returnString.indexOf('=') + 1);
                            this._router.navigateByUrl(decodeURIComponent(returnString));
                            this._broadcaster.broadcast('startSessionServices');
                        }
                    } else if (window.location.pathname === '/') {
                        // Do nothing
                    } else if (routeData['skipSession']) {
                        // Do nothing
                    } else {
                        this._router.navigate(['']);
                    }
                }
            } else if (routeData.secured) {
                if (!session) {
                    this._broadcaster.broadcast('logout', {
                        noRedirect: false,
                        hidePopup: true,
                    });
                } else if (this.userId && userId !== this.userId) {
                    this.reloadContent(true);
                } else if (this.organizationId && organizationId !== this.organizationId) {
                    this.organizationId = organizationId;
                    this.reloadContent();
                } else if (session) {
                    this.organizationId = organizationId;
                    this.userId = userId;
                    this.store$.dispatch(GetSessionDetails({ noAlerts: true }));
                }
            }
        }
    };

    private reloadContent = (reload?: boolean) => {
        const href = window.location.href;
        const enReport = environment['enreport-ui'];
        const enInvoice = environment['eninvoice-ui'];
        const enCollab = environment['encollab-ui'];
        if (href.indexOf(enInvoice) > -1) {
            this._router.navigate(['eninvoice', 'organizations']);
        } else if (href.indexOf(enCollab) > -1) {
            this._router.navigate(['encollab', 'organizations']);
        } else if (href.indexOf(enReport) > -1) {
            this._router.navigate(['enreport', 'organizations', this.organizationId]);
        } else {
            this._router.navigate(['organizations', this.organizationId, 'home']);
        }
        if (reload) {
            setTimeout(() => {
                location.reload();
            }, 500);
        }
    };

    private getMatchingRoute = () => {
        const routeURL = this._router.url;
        const configs = this._router.config;
        let foundConfig;
        configs.forEach((config) => {
            if (foundConfig) {
                return;
            }
            const children = config.children || config?.['_loadedConfig']?.routes?.[0]?.children || undefined;
            const homeChildren = (!config.path.length && config?.['_loadedRoutes']?.[0]?.children) || undefined;
            if (config.path?.length) {
                if (routeURL.split('/').includes(config.path)) {
                    foundConfig = config;
                }
            } else if (children?.length || homeChildren?.length) {
                const childLoop = children ?? homeChildren;
                childLoop.forEach((childConfig) => {
                    if (foundConfig) {
                        return;
                    }
                    if (childConfig.path?.length) {
                        if (routeURL.split('/').includes(childConfig.path)) {
                            foundConfig = childConfig;
                        }
                    }
                });
            }
        });
        if (foundConfig) {
            if (foundConfig.data) {
                return foundConfig.data;
            } else if (foundConfig._loadedConfig) {
                const routes = foundConfig._loadedConfig.routes;
                return routes.find((route) => route.data);
            }
        }
        return foundConfig;
    };

    private getRouteData = (route: ActivatedRoute) => {
        let routeData = (route.data as BehaviorSubject<{ secured: boolean }>).getValue();
        if (routeData && Object.keys(routeData).length === 0) {
            if (route.children && route.children.length > 0) {
                for (let i = 0; i < route.children.length; i++) {
                    routeData = this.getRouteData(route.children[i]);
                    if (routeData && Object.keys(routeData).length > 0) {
                        break;
                    }
                }
            }
        }
        return routeData;
    };

    ngOnInit() {
        const session = this._commonUtils.getFromStorage('session');
        const routeData = this.getRouteData(this._route);
        if (!session) {
            if (routeData?.secured) {
                this._broadcaster.broadcast('logout', {
                    noRedirect: false,
                    hidePopup: true,
                });
                return;
            }
        }
        this._broadcaster
            .on('setValuesInCrossStorage')
            .pipe(takeUntil(this.unSubscribe))
            .subscribe((data: { values: { key: string; value: any }[]; callback: (...args: any[]) => void }) => {
                const callbackPromise = new Promise<void>((resolve) => {
                    data.values.forEach((dataObj) => {
                        this._commonUtils.setInStorage(dataObj.key, dataObj.value);
                        if (dataObj.key.toLowerCase() === 'currentorganization') {
                            this.organizationId = dataObj.value.id;
                        }
                    });
                    resolve();
                });
                callbackPromise.then(() => {
                    data.callback && data.callback();
                });
            });

        this._broadcaster
            .on('setCrossStorage')
            .pipe(takeUntil(this.unSubscribe))
            .subscribe((data: any) => {
                this.organizationId = this._commonUtils.getFromStorage('currentOrganizationId');
                data && data.callback && data.callback();
            });

        this._broadcaster
            .on('getFromCrossStorage')
            .pipe(takeUntil(this.unSubscribe))
            .subscribe(({ property, callback }) => {
                callback(this._commonUtils.getFromStorage(property));
            });

        this._broadcaster.broadcast('hubstorageInitiated');

        const currentOrganization = this._commonUtils.getFromStorage('currentorganization');
        this.organizationId = currentOrganization && currentOrganization.id;
        setTimeout(() => {
            this.onFocus();
        }, 100);
    }

    ngOnDestroy() {
        this.unSubscribe.next();
        this.unSubscribe.complete();
    }
}
