import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { RootScope } from '@enreport/modules/core/services/rootscope/rootscope.service';
import { TaxillaApiService } from '@enreport/modules/core/services/taxillaapi/taxillaapi.service';
import { Utils } from '@enreport/modules/core/services/utils/utils.service';
import { translate } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AlertError, AlertSuccess } from 'taxilla-library';

import { MastersResponseRecord } from '../../interface/master-records-response.interface';
import { AssetService } from '../../models/assetservice.class';
import { Entity } from '../../models/entity.class';
import { RecordPayload } from '../../models/payload/recordpayload.class';
import { Message } from '../../models/record/message.class';
import { Record } from '../../models/record/record.class';
import { RecordField } from '../../models/record/recordfield.class';
import { ValidationsService } from '../../services/validations/validations.service';

@Component({
    selector: 'app-addrecords',
    templateUrl: './addrecords.component.html',
    styleUrls: ['./addrecords.component.scss'],
})
export class AddRecordsComponent implements OnInit {
    private payloadMap: { [recordId: string]: RecordPayload };
    public entities: Entity[];
    public service: AssetService;
    public instanceId: string;
    public parentId: string;
    public parentEntityUid: string;
    public parentMasterTablesData: string = '{}';
    public parentLookupsData: string = '{}';
    public eventType: string;
    public isClosed: boolean;
    public masterRecordsResponse: {
        [tableId: string]: {
            [pipedColumnKeys: string]: MastersResponseRecord[];
        };
    } = {};
    public lookupsResponse: {
        [condition: string]: RecordField['lookupValues'];
    } = {};

    constructor(
        private dialog: MatDialog,
        protected _api: TaxillaApiService,
        public R: RootScope,
        public _utils: Utils,
        private _validations: ValidationsService,
        private store$: Store
    ) {}

    closeModal = () => {
        this.dialog.closeAll();
    };

    public validateRecords = () => {
        if (this._validations.hasValidEntitiesData(this.entities)) {
            this.payloadMap = {};
            this.generatePayloadMap(this.entities);
            if (Object.keys(this.payloadMap).length === 0) {
                return this.store$.dispatch(
                    AlertError({ message: translate('No records found. Add records by clicking on Create New button.') })
                );
            }
            this.entities.forEach((entity) => {
                (entity.entityData as Record[]).forEach((record) => {
                    record.fields.forEach((field) => {
                        field.isFromAddRecord = false;
                    });
                });
            });
            this.entities.forEach(this.addNewRecords);
        }
    };

    public editTable = (event) => {
        this.eventType = event;
    };

    private generatePayloadMap = (entities: Entity[]) => {
        entities.forEach((entity) => {
            (entity.entityData as Record[]).forEach(this.generateRecordPayload);
        });
    };

    private generateRecordPayload = (record: Record) => {
        if (record) {
            this.payloadMap[record?.id || record.tempId] = new RecordPayload(record);
            record.entities?.length > 0 && this.generatePayloadMap(record.entities);
        }
    };

    public addNewRecords = (entity: Entity) => {
        const records = entity.entityData as Record[];
        records.filter((record) => record.id === undefined).length > 0 &&
            this.getSaveRecordsHttpRequest(
                'save',
                entity,
                records.filter((record) => record.id === undefined)
            );
        records.filter((record) => record.id !== undefined).length > 0 &&
            this.getSaveRecordsHttpRequest(
                'update',
                entity,
                records.filter((record) => record.id !== undefined)
            );
    };

    private getSaveRecordsHttpRequest = (methodType: 'save' | 'update', entity: Entity, records: Record[]) => {
        const recordPayload = [];
        const filteredRecords = records.filter((record) => (methodType === 'save' ? record.id === undefined : record.id !== undefined));
        filteredRecords.forEach((record) => recordPayload.push(new RecordPayload(record)));
        let method: (...args: any) => Observable<any>;
        switch (methodType) {
            case 'save':
                method = this._api.records.saveRecords;
                break;
            case 'update':
                method = this._api.records.updateRecordsViaPromise;
                break;
        }
        method({
            assetDataId: this.instanceId,
            entityId: entity.uid,
            payload: recordPayload,
            restApiName: this.service.restApiName,
            noAlerts: false,
            noMessages: true,
        }).subscribe({
            next: (res: { response: { records: RecordPayload[] }; msg: string }) => {
                res?.response?.records?.forEach((record, index) => {
                    this.mapResponseToRecord(filteredRecords[index], new Record(record, entity));
                });
                if (Object.keys(this.payloadMap).length === 0) {
                    this.store$.dispatch(AlertSuccess({ message: res?.msg }));
                }
            },
            error: (e: { response: { records: RecordPayload[] }; msg: string }) => {
                e?.response?.records?.forEach((record, index) => {
                    this.mapResponseToRecord(filteredRecords[index], new Record(record, entity), record?.['failureMessage']);
                });
            },
        });
    };

    private mapResponseToRecord = (matchingRecord: Record, res: Record, failureMessage?: string) => {
        matchingRecord.id = res.id;
        if (matchingRecord.id) {
            delete this.payloadMap[matchingRecord.id];
            delete this.payloadMap[matchingRecord.tempId];
        }
        if (failureMessage?.length > 0) {
            matchingRecord.message = new Message();
            matchingRecord.message.errors.push(failureMessage);
        } else {
            matchingRecord.bindValuesPostSave(res);
        }
        (!failureMessage || matchingRecord.id?.length > 0) &&
            matchingRecord.entities?.forEach((entity) => {
                (entity.entityData as Record[])?.forEach((record) => (record.parentId = res.id));
                (entity.entityData as Record[]).length > 0 && this.addNewRecords(entity);
            });
    };

    public lookUpUpdated = (event: string) => {
        this.parentLookupsData = event;
    };

    ngOnInit(): void {}
}
