import { Component, DoCheck, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { RootScope } from '@encomply-core/services/rootscope/rootscope.service';
import { TaxillaApiService } from '@encomply-core/services/taxillaapi/taxillaapi.service';
import { Utils } from '@encomply-core/services/utils/utils.service';
import { Subscription } from 'rxjs';

import { ModifiedErrorStateMatcher } from '../../../extensions/errorstatematcher/errorstatematcher';

@Component({
    selector: 'states',
    templateUrl: './states.component.html',
    styleUrls: ['./states.component.scss'],
})
export class StatesComponent implements OnInit, DoCheck, OnChanges, OnDestroy {
    @Input() data;
    @Input() country;
    @Input() id;
    @Input() errors: string[];
    @Input() required: boolean;
    @Input() disabled: boolean;
    @Input() blockEvent = false;
    @Input() registerAddressState;

    @Output() stateNameChanged = new EventEmitter();

    states: any;
    control: UntypedFormControl;
    matcher = new ModifiedErrorStateMatcher();
    valueSubscription: Subscription;
    stateClickedSubscription: Subscription;
    public state: string;

    constructor(public R: RootScope, public _utils: Utils, public _taxilla: TaxillaApiService) {
        this.control = new UntypedFormControl({ value: (this.data && this.data.state) || '', disabled: this.disabled });
    }

    ngDoCheck() {
        if (this.errors === undefined && this.control.getError('errors') === undefined) {
            // do nothing
        } else if (
            (this.errors === undefined || (this.errors && this.errors.length === 0)) &&
            this.control.getError('errors') !== undefined
        ) {
            // mark undefined
            this.control.setErrors(undefined);
        } else if (
            this.errors !== undefined &&
            this.errors.length > 0 &&
            JSON.stringify(this.errors) !== JSON.stringify(this.control.getError('errors'))
        ) {
            // show errors
            this.control.setErrors({ errors: this.errors });
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.model) {
            this.control.value !== changes.model.currentValue && this.control.setValue(this.data.state);
        }
        if (changes.data) {
            this.control.value !== changes.data.currentValue && this.control.setValue(this.data.state);
        }
        if (changes.registerAddressState) {
            if (this.disabled) {
                this.control.setValue(changes.registerAddressState.currentValue);
            }
        }
        if (changes && changes.country) {
            const newValue = changes.country.currentValue;
            const oldValue = changes.country.previousValue;
            if (newValue !== oldValue || (parseInt(newValue, 10) > 0 && (!this.states || this.states.length === 0))) {
                if (newValue > 0) {
                    if (!this.states) {
                        this.states = [];
                    }
                    if (!this.R.states[newValue] || (this.R.states[newValue] && this.R.states[newValue].length === 0)) {
                        const data = {
                            countryId: newValue,
                        };
                        this._taxilla.common.getStates(data, {
                            successCallback: (response) => {
                                const states = response || [];
                                for (let i = 0; i < states.length; i++) {
                                    states[i].name = this._utils.transformText(states[i].name);
                                }
                                this.states = states;
                                this.R.states[newValue] = states;
                            },
                            failureCallback: () => {},
                        });
                    } else {
                        this.states = this.R.states && this.R.states[newValue];
                    }
                } else {
                    this.data.city = '';
                    this.data.state = '';
                }
                this.control.setValue(this.data.state);
            }
        }
        if (changes.disabled) {
            if (changes.disabled.currentValue === true) {
                this.control.disable();
            } else {
                this.control.enable();
            }
        }
    }

    getState = () => {
        if (this.data.country === '' || this.data.country === undefined) {
            return;
        }
        if (!this.R.states[this.data.country] || (this.R.states[this.data.country] && this.R.states[this.data.country].lenght === 0)) {
            const data = {
                countryId: this.data.country,
            };
            this._taxilla.common.getStates(data, {
                successCallback: (response) => {
                    const states = response || [];
                    for (let i = 0; i < states.length; i++) {
                        states[i].name = this._utils.transformText(states[i].name);
                    }
                    this.states = states;
                    this.R.states[this.data.country] = states;
                },
                failureCallback: () => {},
            });
        } else {
            this.states = this.R.states && this.R.states[this.data.country];
        }
    };

    stateChanged = (newValue: string) => {
        this.errors = [];
        if (!newValue) {
            delete this.data.state;
        } else {
            this.data.city = '';
            this.data.cityName = '';
            this.data.state = newValue;
            for (let i = 0; i < this.states.length; i++) {
                if (parseInt(newValue, undefined) === this.states[i].id) {
                    this.data.stateName = this.states[i].name;
                }
            }
        }
        this.stateNameChanged.emit(this.data.state);
    };

    ngOnInit() {
        this.valueSubscription = this.control.valueChanges.subscribe((event) => {
            if (!this.blockEvent) {
                this.stateChanged(event);
            }
        });
    }

    ngOnDestroy(): void {
        // Called once, before the instance is destroyed.
        this.valueSubscription && this.valueSubscription.unsubscribe();
    }
}
