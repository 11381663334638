import { createReducer, on } from '@ngrx/store';

import { ResetBundles, SelectBundle } from '../actions';
import * as manageAppsActions from '../actions/manage-apps.actions';
import * as sessionActions from '../actions/session.actions';
import { manageAppsAdapters, ManageAppsOrganizationEntity, manageAppsState } from '../states/manage-apps.state';

export const MANAGE_APPS_REDUCER = createReducer(
    manageAppsState,
    on(sessionActions.SetCurrentOrganization, (state, action) => {
        return {
            ...state,
            currentOrganizationId: action.organization?.id,
        };
    }),
    on(manageAppsActions.ManageAppsSetOrganization, (state, action) => {
        return {
            ...state,
            currentOrganizationId: action.organization.id,
        };
    }),
    on(manageAppsActions.ManageAppsSetOrgsAndApps, (state, action) => {
        return {
            ...state,
            pageIndex: 0,
            orgIdArray: action.orgIdArray,
            itemIdArray: action.itemIdArray,
            itemNames: action.itemNames,
        };
    }),
    on(manageAppsActions.ManageAppsChangeCategory, (state, action) => {
        return {
            ...state,
            pageIndex: 0,
            assetType: action.assetType,
        };
    }),
    on(manageAppsActions.SetManageAppsPageIndex, (state, action) => {
        return {
            ...state,
            pageIndex: action.pageIndex,
        };
    }),
    on(manageAppsActions.SetManageAppsPageSize, (state, action) => {
        return {
            ...state,
            pageIndex: 0,
            pageSize: action.size,
        };
    }),
    on(manageAppsActions.SetManageAppsOrganizationEntity, (state, action) => {
        return {
            ...state,
            organizations: action.organizationsAdapter.addOne(action.servicesEntity, state.organizations),
        };
    }),
    on(manageAppsActions.SetManageAppsStatus, (state, action) => {
        return {
            ...state,
            subscriptionStatus: action.subscriptionStatus,
            pageIndex: 0,
            pageSize: state.pageSize,
            assetType: undefined,
            searchText: undefined,
            currentBridgeId: undefined,
            filterByTags: {
                tagKey: undefined,
                tagValue: undefined,
                serviceIds: [],
                searchText: undefined,
            },
            selectedServiceIds: state.selectedBundleId ? state.selectedServiceIds : [],
            itemIdArray: [],
            itemNames: [],
            orgIdArray: [],
        };
    }),
    on(manageAppsActions.SetManageAppsSearchText, (state, action) => {
        return {
            ...state,
            searchText: action.searchText?.toLowerCase(),
            pageIndex: 0,
            filterByTags: {
                tagKey: undefined,
                tagValue: undefined,
                serviceIds: [],
                searchText: undefined,
            },
        };
    }),
    on(manageAppsActions.SetManageAppsTagsFilter, (state, action) => {
        return {
            ...state,
            searchText: undefined,
            pageIndex: 0,
            filterByTags: {
                tagKey: action.tagKey,
                tagValue: action.tagValue,
                serviceIds: action.serviceIds,
                searchText: action.searchText?.toLowerCase(),
            },
        };
    }),
    on(manageAppsActions.SetManageAppsResponse, (state, action) => {
        const organizationEntity: ManageAppsOrganizationEntity = state.organizations.entities[state.currentOrganizationId];
        return {
            ...state,
            organizations: action.organizationsAdapter.updateOne(
                {
                    id: state.currentOrganizationId,
                    changes: {
                        orgIdVsName: {
                            ...(organizationEntity.orgIdVsName || {}),
                            ...(action.orgIdVsName || {}),
                        },
                        services: action.servicesAdapter.addMany(action.services, organizationEntity.services),
                    },
                },
                state.organizations
            ),
        };
    }),
    on(manageAppsActions.UpdateManageAppsService, (state, action) => {
        const organizationEntity: ManageAppsOrganizationEntity = state.organizations.entities[state.currentOrganizationId];
        return {
            ...state,
            organizations: action.organizationsAdapter.updateOne(
                {
                    id: state.currentOrganizationId,
                    changes: {
                        services: action.servicesAdapter.updateOne(
                            {
                                id: action.assetId,
                                changes: {
                                    [action.property]: action.propertyValue,
                                },
                            },
                            organizationEntity.services
                        ),
                    },
                },
                state.organizations
            ),
        };
    }),
    on(manageAppsActions.UpdateManageAppsBridgeNode, (state, action) => {
        const organizationEntity: ManageAppsOrganizationEntity = state.organizations.entities[state.currentOrganizationId];
        const serviceEntity = organizationEntity.services;
        const service = serviceEntity.entities[action.assetId];
        const bridgeNodes = service.bridgeNodes;
        const bridgeNode = service.bridgeNodes[action.bridgeNodeId];
        return {
            ...state,
            organizations: action.organizationsAdapter.updateOne(
                {
                    id: state.currentOrganizationId,
                    changes: {
                        services: action.servicesAdapter.updateOne(
                            {
                                id: action.assetId,
                                changes: {
                                    bridgeNodes: {
                                        ...bridgeNodes,
                                        [action.bridgeNodeId]: {
                                            ...bridgeNode,
                                            [action.property]: action.propertyValue,
                                        },
                                    },
                                },
                            },
                            organizationEntity.services
                        ),
                    },
                },
                state.organizations
            ),
        };
    }),
    on(manageAppsActions.ManageAppsResetSearchText, (state) => {
        return {
            ...state,
            searchText: undefined,
            filterByTags: {
                tagKey: undefined,
                tagValue: undefined,
                serviceIds: [],
                searchText: undefined,
            },
        };
    }),
    on(manageAppsActions.ManageAppsSetBridgeId, (state, action) => {
        return {
            ...state,
            currentBridgeId: action.assetId,
        };
    }),
    on(manageAppsActions.SetManageAppsSelectedServiceIds, (state, action) => {
        const organizationEntity: ManageAppsOrganizationEntity = state.organizations.entities[state.currentOrganizationId];
        const services = organizationEntity.services;
        return {
            ...state,
            selectedServiceIds: action.ids,
            organizations: manageAppsAdapters.organizations.updateOne(
                {
                    id: state.currentOrganizationId,
                    changes: {
                        services: manageAppsAdapters?.[state.currentOrganizationId].updateMany(
                            services.ids.map((id) => {
                                return Object.assign(
                                    {},
                                    {
                                        id: id,
                                        changes: {
                                            selected: action.ids.includes(services.entities[id].serviceId),
                                        },
                                    }
                                );
                            }),
                            organizationEntity.services
                        ),
                    },
                },
                state.organizations
            ),
        };
    }),
    on(manageAppsActions.ManageAppsUpdateMany, (state, action) => {
        const organizationEntity: ManageAppsOrganizationEntity = state.organizations.entities[state.currentOrganizationId];
        return {
            ...state,
            organizations: action.organizationsAdapter.updateOne(
                {
                    id: state.currentOrganizationId,
                    changes: {
                        services: action.servicesAdapter.updateMany(
                            action.ids.map((id) =>
                                Object.assign(
                                    {},
                                    {
                                        id: id,
                                        changes: {
                                            [action.property]: action.propertyValue,
                                            status: 'PENDING' as any,
                                        },
                                    }
                                )
                            ),
                            organizationEntity.services
                        ),
                    },
                },
                state.organizations
            ),
        };
    }),
    on(SelectBundle, (state, action) => {
        return {
            ...state,
            selectedBundleId: action.id,
        };
    }),
    on(manageAppsActions.SetManageAppsAssignableSubscriptionIds, (state, action) => {
        return {
            ...state,
            assignableSubscriptionIds: {
                ...state.assignableSubscriptionIds,
                [state.currentOrganizationId]: action.ids,
            },
        };
    }),
    on(manageAppsActions.SetManageAppsBundleSubscriptionIds, (state, action) => {
        return {
            ...state,
            bundleSubscribedAppIds: {
                ...state.bundleSubscribedAppIds,
                [state.selectedBundleId]: action.ids,
            },
        };
    }),
    on(ResetBundles, (state) => {
        return {
            ...state,
            selectedBundleId: undefined,
            selectedServiceIds: [],
        };
    }),
    on(manageAppsActions.ManageAppsSetCount, (state, action) => {
        const orgIdString = state.orgIdArray.join(',');
        const itemIdString = state.itemIdArray.join(',');
        return {
            ...state,
            count: {
                ...state.count,
                [`${state.subscriptionStatus}|${orgIdString}|${itemIdString}`]: action.count,
            },
        };
    }),
    on(manageAppsActions.ClearManageAppsSubscriptionApproval, (state) => {
        const servicesAdapter = manageAppsAdapters[state.currentOrganizationId];
        if (!state.organizations.entities[state.currentOrganizationId]) {
            return state;
        }
        return {
            ...state,
            subscriptionStatus: 'ALL' as 'ALL',
            pageIndex: 0,
            pageSize: 20,
            itemIdArray: [],
            orgIdArray: [],
            itemNames: [],
            organizations: manageAppsAdapters.organizations.updateOne(
                {
                    id: state.currentOrganizationId,
                    changes: {
                        orgIdVsName: {},
                        services: servicesAdapter.getInitialState(),
                    },
                },
                state.organizations
            ),
        };
    }),
    on(manageAppsActions.ManageAppsSetGetByPagination, (state, action) => {
        return {
            ...state,
            getAppsByPagination: action.status,
            pageSize: 10,
            count: {
                APPROVED: 0,
                PENDING: 0,
                PENDING_DEACTIVATE: 0,
            },
        };
    }),
    on(manageAppsActions.ManageAppsUpdateProperty, (state, action) => {
        const organizationEntity: ManageAppsOrganizationEntity = state.organizations.entities[state.currentOrganizationId];
        return {
            ...state,
            organizations: action.organizationsAdapter.updateOne(
                {
                    id: state.currentOrganizationId,
                    changes: {
                        services: action.servicesAdapter.updateMany(
                            action.ids.map((id) =>
                                Object.assign(
                                    {},
                                    {
                                        id: id,
                                        changes: {
                                            [action.property]: action.propertyValue,
                                        },
                                    }
                                )
                            ),
                            organizationEntity.services
                        ),
                    },
                },
                state.organizations
            ),
        };
    }),
    on(sessionActions.SessionLogoutSuccessfull, () => manageAppsState)
);

export const MANAGE_APPS_REDUCER_KEY = 'manageApps';
