import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { ViewRecordPagination } from '../../interface/viewrecordpagination.interface';
import { Masters } from '../../models/masters/masters.class';
import { RecordField } from '../../models/record/recordfield.class';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { MastersService } from '../../services/masters/masters.service';

@Component({
    selector: 'asset-masterlookup',
    templateUrl: './masterlookup.component.html',
    styleUrls: ['./masterlookup.component.css'],
})
export class MasterlookupComponent implements OnInit {
    @Input() field: RecordField;
    @Input() value;
    @Input() classList;
    @Input() required;
    @Input() errors: any[];
    @Input() warnings: any[];
    @Input() masters: Masters;
    @Input() disabled: boolean;
    @Input() lookupHint: string;
    @Input() description: string;
    @Input() fieldsMap: BehaviorSubject<{
        [property: string]: { keyColumns: string[]; valuesMap: { id: string; masterColumn: string; value: any }[] };
    }>;
    @Input() isMasterSearchDisabled: boolean;
    @Input() currentOrganizationId: string;

    @Output() modelChange = new EventEmitter();
    @Output() debounceEventHandler = new EventEmitter();
    @Output() selectedLookupRecord = new EventEmitter();

    options: { value: string; displayName: string }[] = [];
    allValues: any[] = [];
    unSubscribe = new Subject<void>();
    fieldsMapState: { [property: string]: { keyColumns: string[]; valuesMap: { id: string; masterColumn: string; value: any }[] } };
    pagination: BehaviorSubject<ViewRecordPagination> = new BehaviorSubject(undefined);
    clearSearch = new Subject<void>();

    constructor(private _masters: MastersService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.isMasterSearchDisabled) {
            if (!changes.isMasterSearchDisabled.currentValue) {
                this.resetAndStartFetchingValues();
            } else if (this.field.value !== undefined) {
                this.resetVariables();
                this.modelChanged(undefined);
            }
        }
    }

    resetAndStartFetchingValues = (fromEvent?: boolean) => {
        if (
            !this.fieldsMapState ||
            !this.fieldsMapState[this.field.masterData.tableUid] ||
            JSON.stringify(this.fieldsMapState[this.field.masterData.tableUid]) !==
                JSON.stringify(this.fieldsMap.value[this.field.masterData.tableUid])
        ) {
            if (fromEvent && this.checkChangeInReferences()) {
                fromEvent = false;
            }
            this.fieldsMapState = CommonUtilsService.cloneObject(this.fieldsMap.value);
        } else {
            if (!this.checkChangeInReferences()) {
                return;
            } else {
                fromEvent = false;
                this.fieldsMapState = CommonUtilsService.cloneObject(this.fieldsMap.value);
            }
        }
        if (this.field.value === undefined) {
            this.clearSearch.next();
            this.resetVariables();
        }
        this.initiatePagination(fromEvent, undefined);
    };

    checkChangeInReferences = () => {
        const references = this.field.masterData.columnRefMetadatas;
        let changed = false;
        references.forEach((reference) => {
            if (this.fieldsMapState) {
                Object.keys(this.fieldsMapState)
                    .filter((tableId) => this.fieldsMapState[tableId].keyColumns.indexOf(reference.fieldUid) > -1)
                    .forEach((tableId) => {
                        changed = changed || JSON.stringify(this.fieldsMapState[tableId]) !== JSON.stringify(this.fieldsMap.value[tableId]);
                    });
            }
        });
        return changed;
    };

    resetPagination = (fromEvent, criteria) => {
        this.resetVariables();
        this.clearSearch.next();
        this.initiatePagination(fromEvent, criteria);
    };

    resetVariables = () => {
        this.options = [];
        this.allValues = [];
    };

    initiatePagination = (fromEvent: boolean, criteria: string) => {
        this.pagination.next({
            currentPageIndex: -1,
            lowerLimit: undefined,
            pageSize: undefined,
            upperLimit: undefined,
            viewIndex: undefined,
            filterTypeSearch: undefined,
            searchCriteria: (criteria || '') as any,
            state: fromEvent as any,
        });
    };

    startFetchingOptions = () => {
        const pagination = this.pagination.value;
        const fromEvent: boolean = pagination.state as any;
        const tablesMap = this.fieldsMap.value;
        const map = tablesMap && tablesMap[this.field.masterData.tableUid];
        const fieldIndex = map.keyColumns.findIndex((column) => column === this.field.id);
        if (fromEvent && fieldIndex === 0) {
            return;
        }
        let valuesMap: { [property: string]: { newValue: any } } = {};
        map.valuesMap
            .filter((valueMap, index) => index <= fieldIndex && valueMap.value && valueMap.value.toLowerCase() !== 'na')
            .forEach(
                (valueMap) =>
                    (valuesMap[valueMap.masterColumn] = {
                        newValue: valueMap.value,
                    })
            );
        if (Object.keys(valuesMap).length === 0) {
            valuesMap = undefined;
        }
        if (fieldIndex > 0 && (valuesMap === undefined || Object.keys(valuesMap).length > fieldIndex)) {
            return;
        }
        if (pagination.state as any as boolean) {
            this.resetVariables();
        }
        if (this.field.masterData.columnRefMetadatas && this.field.masterData.columnRefMetadatas.length > 0) {
            if (!valuesMap) {
                valuesMap = {};
            }
            this.field.masterData.columnRefMetadatas.forEach((reference: { fieldUid: string; entityUid: string }) => {
                const tableMapId = Object.keys(tablesMap).find((tableId) => tablesMap[tableId].keyColumns.indexOf(reference.fieldUid) > -1);
                const tableMap = tablesMap[tableMapId];
                const fieldValueMap = tableMap.valuesMap.find((field) => field.id === reference.fieldUid);
                if (fieldValueMap) {
                    valuesMap[fieldValueMap.masterColumn] = {
                        newValue: fieldValueMap.value,
                    };
                }
            });
        }
        this.fetchOptions(valuesMap);
    };

    fetchOptions = (valuesMap) => {
        const pagination = this.pagination.value;
        this._masters.getPrimaryValuesInMasterTable(
            {
                masterDataRow: {
                    tableId: this.field.masterData.tableUid,
                    unitId: this.currentOrganizationId,
                    columnValues: this.checkMap(valuesMap),
                },
                masterSearchDetail: {
                    pageNo: pagination.currentPageIndex + 1,
                    searchString: pagination.searchCriteria as any,
                },
            },
            {
                successCallback: (response) => {
                    const key = this.field.masterData.columnName;
                    (response || []).forEach((record) => {
                        record.fields?.forEach((fld) => {
                            if (fld.id === key && !this.allValues.includes(fld.value)) {
                                this.allValues.push(fld.value);
                                this.options.push({
                                    displayName: fld.value,
                                    value: fld.value,
                                });
                            }
                        });
                    });
                },
            }
        );
    };

    checkMap = (map) => {
        map &&
            Object.keys(map).forEach((mapId) => {
                if (typeof map[mapId] === 'object') {
                    if (Object.keys(map[mapId]).length === 0) {
                        map[mapId] = undefined;
                    } else {
                        map[mapId] = this.checkMap(map[mapId]);
                        if (Object.keys(map[mapId]).length === 0) {
                            map[mapId] = undefined;
                        }
                    }
                } else if (typeof map[mapId] !== 'object' && map[mapId] === undefined) {
                    delete map[mapId];
                }
            });
        return map;
    };

    modelChanged = (event) => {
        if (
            (event && event.toLowerCase() === 'na') ||
            (event === undefined && this.field.value && (this.field.value as string).toLowerCase() === 'na')
        ) {
            return;
        }
        this.field.value = event;
        const map = CommonUtilsService.cloneObject(this.fieldsMap.value);
        const currentTableId = this.field.masterData.tableUid;
        const tableMap = map[currentTableId];
        const valueMapIndex = tableMap.keyColumns.indexOf(this.field.id);
        if (this.field.value !== undefined) {
            if (valueMapIndex === -1) {
                if (this.field.masterField.primaryKey) {
                    tableMap.keyColumns.push(this.field.id);
                }
                tableMap.valuesMap.push({
                    id: this.field.id,
                    masterColumn: this.field.masterData.columnName,
                    value: this.field.value,
                });
            } else {
                tableMap.valuesMap[valueMapIndex] = {
                    id: this.field.id,
                    masterColumn: this.field.masterData.columnName,
                    value: this.field.value,
                };
                tableMap.valuesMap.filter((_valueMap, index) => index > valueMapIndex).forEach((valueMap) => (valueMap.value = undefined));
            }
        } else {
            if (valueMapIndex === -1) {
                /** Do Nothing */
            } else {
                tableMap.valuesMap.filter((_valueMap, index) => index >= valueMapIndex).forEach((valueMap) => (valueMap.value = undefined));
            }
        }
        this.fieldsMapState = CommonUtilsService.cloneObject(map);
        this.modelChange && this.modelChange.emit(event);
    };

    searchChanged = (value: string) => {
        this.resetVariables();
        this.initiatePagination(false, value);
    };

    ngOnInit() {
        this.fieldsMap
            .pipe(
                takeUntil(this.unSubscribe),
                filter((data) => !this.isMasterSearchDisabled && data !== undefined)
            )
            .subscribe(() => {
                this.resetAndStartFetchingValues(true);
            });
        this.pagination
            .pipe(
                takeUntil(this.unSubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe(() => {
                this.startFetchingOptions();
            });
    }

    ngOnDestroy(): void {
        this.unSubscribe.next();
        this.unSubscribe.complete();
        this.clearSearch.complete();
    }
}
