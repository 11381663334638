import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { CommonUtilsService } from '../services/commonutils/common-utils.service';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
    constructor(private _commonUtils: CommonUtilsService, protected route: ActivatedRoute) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap(
                (event: HttpEvent<any>) => {
                    return event;
                },
                (event: any) => {
                    if (
                        (event.status === 404 || event.status === 200) &&
                        (event.url.indexOf(`${location.origin}/auth/login`) > -1 ||
                            event.url.indexOf(`${location.origin}/auth/validate`) > -1)
                    ) {
                        this._commonUtils.redirectToPage(event.url);
                        return {};
                    } else if (
                        (event.status === 200 || event?.currentTarget?.status === 200) &&
                        event?.currentTarget?.response?.indexOf('doctype html') > -1
                    ) {
                        this._commonUtils.redirectToPage(event.url || event.currentTarget.responseURL || event.currentTarget.url);
                        return {};
                    } else if (event.status === 200 && event?.error?.text?.length > 0) {
                        this._commonUtils.redirectToPage(event.url || event.currentTarget.responseURL || event.currentTarget.url);
                        return {};
                    } else {
                        return event;
                    }
                }
            )
        );
    }
}
