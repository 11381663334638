import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AlertError, AlertSuccess, Entity } from 'taxilla-library';

import { AssetData } from '../../models/assetdata.class';
import { WorkFlow } from '../../models/workflow.class';
import { WorkflowStageEntityReference, WorkflowStageReferences } from '../../models/workflowStageReferences.class';
import { BridgeService } from '../bridge/bridge.service';

@Injectable({
    providedIn: 'root',
})
export class WorkflowService {
    constructor(private _bridge: BridgeService, private store$: Store) {}

    /**
     * Start workflow execution process
     * @param data Contains Workflow, Instance id of the document, Selected api action value, Request id of the document
     * @param callbacks Contains Success callback method
     */
    executeWorkflow = (data: {
        serviceId?: string;
        instanceId?: string | string[];
        workflow?: WorkFlow;
        apiAction: string;
        comment?: string;
        assetId?: string;
        cancel?: boolean;
        noAlerts?: boolean;
        noWorkflows?: boolean;
        formFields?: { [property: string]: string };
        restApiName?: string;
        actionVariables?: any;
    }) => {
        if (data.workflow || data.noWorkflows) {
            // commet field has to be send in formfields object
            const formFieldsObj = data.formFields || {};
            if (data.comment) {
                formFieldsObj['comment'] = data.comment;
            }
            const payload = [];
            if (typeof data.instanceId === 'string') {
                const payloadObject = {
                    instanceId: data.instanceId,
                    action: data.apiAction,
                    formFields: formFieldsObj,
                };
                payload.push(payloadObject);
            } else {
                data.instanceId.forEach((element) => {
                    const payloadObject = {
                        instanceId: element,
                        action: data.apiAction,
                        formFields: formFieldsObj,
                        actionVariables: data.actionVariables,
                    };
                    payload.push(payloadObject);
                });
            }
            return new Promise<void>((resolve, reject) => {
                this._bridge.executeWorkFlow(
                    {
                        restApiName: data.restApiName,
                        payload,
                    },
                    (res) => {
                        !data.noAlerts &&
                            this.store$.dispatch(
                                AlertSuccess({
                                    message: res?.msg || 'Executed action ' + data.apiAction,
                                })
                            );
                        resolve();
                    },
                    (res) => {
                        let message = '';
                        if (res?.errors?.workflowErrors?.length > 0) {
                            message = res?.errors?.workflowErrors.join(',');
                        } else {
                            message = res?.msg || 'Failed to ' + data.workflow.taskDefinitionKey;
                        }
                        this.store$.dispatch(AlertError({ message }));
                        reject();
                    }
                );
            });
        } else {
            return new Promise((resolve, reject) => {
                this.getCurrentWorkflow(
                    {
                        serviceId: data.serviceId,
                        instanceId: data.instanceId as any,
                        noAlerts: data.noAlerts,
                        noWorkflows: data.noWorkflows,
                        restApiName: data.restApiName,
                    },
                    {
                        successCallback: async (workflow, responseObject) => {
                            data.workflow = workflow;
                            data.noWorkflows = responseObject.noWorkflows;
                            this.executeWorkflow(data).then(resolve).catch(reject);
                        },
                    }
                );
            });
        }
    };

    /**
     * Start workflow execution process
     * @param data Contains Instance id of the document, Selected Asset id
     * @param callbacks Contains Success callback method
     */
    getCurrentWorkflow = (
        data: {
            serviceId?: string;
            instanceId: string;
            noAlerts?: boolean;
            noWorkflows?: boolean;
            restApiName?: string;
        },
        callbacks: {
            successCallback: (workflow: WorkFlow, data: { noWorkflows?: boolean }) => void;
        }
    ) => {
        this.getAllWorkflowStages({
            serviceId: data.serviceId,
            instanceId: data.instanceId,
            noAlerts: data.noAlerts,
            restApiName: data.restApiName,
        }).then((tasks) => {
            const rules = tasks;
            let currentRule: WorkFlow = rules.find((rule) => (rule.startTime && !rule.endTime) || rule.cancelled);
            if (!currentRule) {
                currentRule = rules
                    .slice(0)
                    .reverse()
                    .find((rule) => rule.startTime?.length > 0 && rule.endTime?.length > 0);
            }
            const responseObject = {
                noWorkflows: !tasks || tasks.length === 0,
            };
            callbacks.successCallback && callbacks.successCallback(currentRule, responseObject);
        });
    };

    /**
     * Start workflow execution process
     * @param data Contains Instance id of the document, Selected Asset id
     * @param callbacks Contains Success callback method
     */
    getAllWorkflowStages = (data: { serviceId?: string; instanceId: string; noAlerts?: boolean; restApiName?: string }) => {
        return new Promise<WorkFlow[]>((resolve, reject) => {
            this._bridge.getWorkflow(
                {
                    serviceId: data.serviceId,
                    instanceId: data.instanceId,
                    noAlerts: data.noAlerts,
                    restApiName: data.restApiName,
                },
                (res) => {
                    resolve(res.taskDetails);
                },
                (res) => {
                    this.store$.dispatch(AlertError({ message: res?.msg || 'Failed to get instance workflow' }));
                    reject(res);
                }
            );
        });
    };

    /**
     * Start workflow execution process
     * @param data Contains Instance id of the document, Selected Asset id
     * @param callbacks Contains Success callback method
     */
    getWorkflowStages = (
        data: { assetName: string; assetVersion: string; assetUid: string },
        callbacks: { successCallback: (workflows: WorkFlow[]) => void; failureCallback?: (...args: any[]) => void }
    ) => {
        this._bridge.getWorkflowStages(
            data,
            (res) => {
                callbacks.successCallback && callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback && callbacks.failureCallback(res);
            }
        );
    };
    /**
     * to update workflow
     * @param data Contains assetname,assetversion,assetid and taskdetails
     * @param callbacks Contains Success callback method
     */
    updateWorkflow = (
        data: { assetName: string; assetVersion: string; assetId: string; taskDetails: {} },
        callbacks: { successCallback: (res) => void; failureCallback?: (...args: any[]) => void }
    ) => {
        this._bridge.updateWorkflow(
            data,
            (res) => {
                callbacks.successCallback && callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback && callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to apply workflow stage entity references
     * @param data contains assetMetadata, currentRule
     * returns updated currentRule and assetMetadata
     */
    applyWorkflowStageEntityReferences = (
        assetMetaData: AssetData,
        currentRule: WorkFlow,
        triggerOrigin: string,
        activeEntity?: Entity
    ) => {
        const workflowStageReferences: WorkflowStageReferences[] = assetMetaData.workflowStageReferences;
        const currentworkflowStageReference: WorkflowStageReferences = workflowStageReferences.find(
            (reference) => reference.workflowStageUid === currentRule.taskDefinitionKey
        );
        currentRule = { ...currentRule, workflowStageEntityReference: currentworkflowStageReference?.workflowStageEntityReferences };
        if (currentRule?.workflowStageEntityReference) {
            currentRule.workflowStageEntityReference.forEach(
                (workflowStageReference: { entityUid: any; show: boolean; readOnly: boolean }) => {
                    const viewEntity = assetMetaData.getEntity(workflowStageReference.entityUid);
                    if (viewEntity) {
                        viewEntity.show = workflowStageReference.show;
                        viewEntity.toggleReadOnlyEntity(workflowStageReference.readOnly);
                    }
                }
            );
        }
        if (triggerOrigin === 'enreport') {
            const isEntityReadonly = this.applyWorkflowReadlyEntityReferences(currentRule, activeEntity);
            return { assetMetaData, currentRule, isEntityReadonly };
        } else {
            return { assetMetaData, currentRule };
        }
    };

    applyWorkflowReadlyEntityReferences = (currentRule, activeEntity: Entity) => {
        let isEntityReadonly = false;
        if (currentRule?.workflowStageEntityReference) {
            const currentEntity = activeEntity;
            const workflowEntityReference: WorkflowStageEntityReference = currentRule.workflowStageEntityReference?.find(
                (reference) => reference.entityUid === currentEntity.uid
            );
            if (currentEntity.uid === workflowEntityReference?.entityUid) {
                currentEntity.toggleReadOnlyEntity(workflowEntityReference.readOnly);
                isEntityReadonly = workflowEntityReference.readOnly;
            }
        }
        return isEntityReadonly;
    };
}
