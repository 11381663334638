import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';

import { DocPreviewComponent } from '../../components/doc-preview/doc-preview.component';
import { clientData } from '../../models/clientData.class';
import { BridgeService } from '../bridge/bridge.service';
import { StoreService } from '../store/store.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
    providedIn: 'root',
})
export class CommonService {
    public getFile = this._bridge.getFile;

    constructor(private _bridge: BridgeService, private dialog: MatDialog, private _utils: UtilsService, private _store: StoreService) {}

    /**
     * Method to get countries
     * @param callbacks Contains success callback method, failure callback method
     */
    getCountries = async (callbacks: {
        successCallback: (
            response: {
                code: string;
                id: number;
                name: string;
                numericCode: string;
            }[]
        ) => void;
        failureCallback?: (...args: any[]) => void;
    }) => {
        const response = await this._store.publicScope.fetchValues(() => {
            return new Promise((resolve) => {
                this._bridge.getCountries(
                    (res) => {
                        resolve(res || []);
                    },
                    (res) => {
                        callbacks.failureCallback && callbacks.failureCallback(res);
                    }
                );
            });
        }, 'countries');
        callbacks.successCallback(response?.countries);
    };

    /**
     * Method to get states
     * @param data Contains country id,
     * @param callbacks Contains success callback method, failure callback method
     */
    getStates = async (
        data: {
            countryId: number;
        },
        callbacks: {
            successCallback: (
                response: {
                    code: string;
                    country: {
                        code: string;
                        id: number;
                        name: string;
                        numericCode: string;
                    };
                    id: number;
                    name: string;
                }[]
            ) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        const response = await this._store.publicScope.fetchValues(
            () => {
                return new Promise((resolve) => {
                    this._bridge.getStates(
                        data,
                        (res) => {
                            resolve(res || []);
                        },
                        (res) => {
                            callbacks.failureCallback && callbacks.failureCallback(res);
                        }
                    );
                });
            },
            'states',
            data.countryId
        );
        callbacks.successCallback(response?.states[data.countryId]);
    };

    /**
     * Method to get cities
     * @param stateId contains state Id
     * @param s Success callback
     * @param f Failure callback
     */
    getCities = async (
        data: {
            stateId: number;
        },
        callbacks: {
            successCallback: (
                response: {
                    id: number;
                    name: string;
                    state: {
                        code: string;
                        country: {
                            code: string;
                            id: number;
                            name: string;
                            numericCode: string;
                        };
                        id: number;
                        name: string;
                    };
                }[]
            ) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        const response = await this._store.publicScope.fetchValues(
            () => {
                return new Promise((resolve) => {
                    this._bridge.getCities(
                        data,
                        (res) => {
                            resolve(res || []);
                        },
                        (res) => {
                            callbacks.failureCallback && callbacks.failureCallback(res);
                        }
                    );
                });
            },
            'cities',
            data.stateId
        );
        callbacks.successCallback(response?.cities[data.stateId]);
    };

    /**
     * Method to get organization exist or not
     * @param callbacks Contains success callback method, failure callback method
     */
    isOrganizationExists = (
        orgLoginId,
        callbacks?: {
            successCallback: (res: { msg: string; success: boolean }) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.isOrganizationExists(
            orgLoginId,
            (res) => {
                callbacks.successCallback(res || {});
            },
            (res) => {
                callbacks.failureCallback && callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to get Clients
     * @param callbacks Contains success callback method, failure callback method
     */
    getClients = (
        data: { organizationId: string; pageNumber: number; pageSize: number; criterias?: {}; fetchAllProperties?: boolean },
        callbacks: { successCallback: (res, count) => void; failureCallback?: (...args: any[]) => void }
    ) => {
        this._bridge.getClients(
            data,
            (res, count) => {
                callbacks.successCallback && callbacks.successCallback(res, count);
            },
            (res) => {
                callbacks.failureCallback && callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to get Client details
     * @param callbacks Contains success callback method, failure callback method
     */
    getFullDetailsOfClient = (
        data: { organizationId: string; id: string; criterias?: {}; fetchAllProperties?: boolean },
        callbacks: { successCallback: (res) => void; failureCallback?: (...args: any[]) => void }
    ) => {
        this._bridge.getFullDetailsOfClient(
            data,
            (res) => {
                callbacks.successCallback && callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback && callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to add Clients
     * @param callbacks Contains success callback method, failure callback method
     */
    addClient = (data: any[], callbacks: { successCallback: (res) => void; failureCallback?: (...args: any[]) => void }) => {
        this._bridge.addClient(
            data,
            (res) => {
                callbacks.successCallback && callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback && callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to update Clients
     * @param callbacks Contains success callback method, failure callback method
     */
    updateClient = (data: clientData, callbacks: { successCallback: (res) => void; failureCallback?: (...args: any[]) => void }) => {
        this._bridge.updateClient(
            data,
            (res) => {
                callbacks.successCallback && callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback && callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to delete Client
     * @param callbacks Contains success callback method, failure callback method
     */
    deleteClient = (
        data: { clientId: string },
        callbacks: { successCallback: (res) => void; failureCallback?: (...args: any[]) => void }
    ) => {
        this._bridge.deleteClient(
            data,
            (res) => {
                callbacks.successCallback && callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback && callbacks.failureCallback(res);
            }
        );
    };

    /**
     * method to get Event logs
     * @param callbacks Contains Success Callback Method, Failure Callback Method
     */
    getEventTypes = async (callbacks?: { successCallback: (...args: any[]) => void; failureCallback: (...args: any[]) => void }) => {
        const response = await this._store.publicScope.fetchValues(() => {
            return new Promise((resolve) => {
                this._bridge.getEventTypes(
                    (res) => {
                        const eventTypes = {
                            ASSET: [],
                            USER: [],
                        };
                        res.eventTypes.forEach((item) => {
                            if (item.type === 'ASSET') {
                                eventTypes['ASSET'].push(item);
                            } else {
                                eventTypes['USER'].push(item);
                            }
                        });
                        resolve(eventTypes);
                    },
                    (res) => {
                        callbacks.failureCallback(res);
                    }
                );
            });
        }, 'eventLogs');
        callbacks.successCallback(response?.eventLogs);
    };

    /**
     * Method to getAllEvents
     * @param data contains eventType, fetchSize, monthYear
     * @param callbacks Contains success callback method, failure callback method
     */
    getAllEvents = (
        data: {
            payload: {
                eventType?: string;
                fetchSize?: number;
                monthYear?: string;
                pagingState?: string;
                resourceId?: string;
                noAlerts?: boolean;
            };
            noAlerts?: boolean;
        },
        callbacks: { successCallback: (res) => void; failureCallback?: (...args: any[]) => void }
    ) => {
        this._bridge.getAllEvents(
            data,
            (res) => {
                callbacks.successCallback && callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback && callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to get  has 4Ec aprroval access
     * @param callbacks Contains success callback method, failure callback method
     */
    has4EcApprovalAccess = async (callbacks?: { successCallback: (...args: any[]) => void }) => {
        const response = await this._store.privateScope.fetchValues(() => {
            return new Promise<any>((resolve) => {
                this._bridge.has4EcApprovalAccess(
                    (res) => {
                        resolve(res || []);
                    },
                    (res) => {
                        resolve(undefined);
                        this._utils.alertError(res?.msg || 'Failed to get 4EC Access Permissions');
                    }
                );
            });
        }, `4ECAccess`);
        if (callbacks?.successCallback) {
            callbacks.successCallback(response?.[`4ECAccess`]);
            return;
        }
        return response?.[`4ECAccess`];
    };

    /**
     * Method to get 4Ec requests
     * @param data Contains orgId
     * @param callbacks Contains success callback method, failure callback method
     */
    getVersionJson = (
        data: {
            url: string;
        },
        callbacks?: {
            successCallback: (...args: any[]) => void;
            failureCallback: (...args: any[]) => void;
        }
    ) => {
        this._bridge.getVersionJson(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * method to getActiveTokens
     * @param data Contains size and page number
     */
    getActiveTokens = (data: { size: number; page: number }) => {
        return new Observable((observer) => {
            this._bridge.getActiveTokens(
                data,
                (records = [], count) => {
                    observer.next({
                        records,
                        count,
                    });
                    observer.complete();
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    };

    /**
     * method to invalidateTokens
     * @param data Contains active token ids
     */

    invalidateTokens = (data: { tokenIds: number[] }) => {
        return new Observable((observer) => {
            this._bridge.invalidateTokens(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to get 4Ec requests
     * @param data Contains orgId
     * @param callbacks Contains success callback method, failure callback method
     */
    get4ECRequests = (
        data: {
            status: string;
            orgId: string;
            type?: string;
        },
        callbacks?: {
            successCallback: (...args: any[]) => void;
            failureCallback: (...args: any[]) => void;
        }
    ) => {
        this._bridge.get4ECRequests(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to Approve 4EC requests
     * @param data contains data
     * @param s Success callback
     * @param f Failure callback
     */
    aprove4EcRequests = (
        data: {
            id: string;
            type: string;
            status: string;
            organizationId: string;
            comment: string;
        },
        callbacks?: {
            successCallback: (...args: any[]) => void;
            failureCallback: (...args: any[]) => void;
        }
    ) => {
        this._bridge.approve4EcRequests(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to get 4EcRequest Details
     * @param data Contains Id
     * @param callbacks Contains success callback method, failure callback method
     */
    get4ECRequestDetails = (
        data: {
            id: string;
        },
        callbacks?: {
            successCallback: (...args: any[]) => void;
            failureCallback: (...args: any[]) => void;
        }
    ) => {
        this._bridge.get4EcRequestDetails(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to save 4Ec user details
     * @param data Contains Id, requestId, persistedId, organizationId, userId, firstName, lastName, email, roles
     * @param callbacks Contains success callback method, failure callback method
     */

    save4EcUserDetails = (
        data: {
            id: string;
            requestId: string;
            persistedId: string;
            organizationId: string;
            userId: string;
            firstName: string;
            lastName: string;
            email: string;
            emailNotification: boolean;
            roles: any[];
        },
        callbacks?: {
            successCallback: (...args: any[]) => void;
            failureCallback: (...args: any[]) => void;
        }
    ) => {
        this._bridge.save4EcUserDetails(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to get 4ec permission changes
     * @param data Contains roleId
     * @param callbacks Contains success callback method, failure callback method
     */
    get4ecPermissionChanges = (
        data: {
            requestId: string;
        },
        callbacks?: {
            successCallback: (...args: any[]) => void;
            failureCallback: (...args: any[]) => void;
        }
    ) => {
        this._bridge.get4ecPermisionChanges(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to save 4Ec role details
     * @param data Contains Id, requestId, persistedId, organizationId, roleName, description, displayname
     * @param callbacks Contains success callback method, failure callback method
     */

    save4EcRoleDeatils = (
        data: {
            id: string;
            requestId: string;
            persistedId: string;
            organizationId: string;
            roleName: string;
            displayName: string;
            description: string;
        },
        callbacks?: {
            successCallback: (...args: any[]) => void;
            failureCallback: (...args: any[]) => void;
        }
    ) => {
        this._bridge.save4EcRoleDeatils(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    approve4ecRoles = (
        data: {
            requestId: string;
        },
        callbacks?: {
            successCallback: (...args: any[]) => void;
            failureCallback: (...args: any[]) => void;
        }
    ) => {
        this._bridge.approve4ecRoles(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to get all downtime windows
     */
    getDowntimeWindows = (callbacks?: { successCallback: (res: any) => void; failureCallback: (res: any) => void }) => {
        this._bridge.getDowntimeWindows(
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };
    /**
     * Method to getProcessLogs
     * @param data contains eventType, fetchSize
     * @param callbacks Contains success callback method, failure callback method
     */
    getProcessLogs = (data: { resourceId?: String; fetchSize?: number; pagingState?: string }) => {
        return new Observable((observer) => {
            this._bridge.getProcessLogs(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (err) => {
                    observer.error(err);
                    observer.complete();
                }
            );
        });
    };

    viewDocument = (doc: { reportName: any; reportUrl: any; reportLink: any }) => {
        const documentObj = {
            fileName: doc?.reportName,
            fileUrl: doc?.reportUrl,
            fileLink: doc?.reportLink,
            isPdfReport: true,
        };
        const fileExtension = doc?.reportName?.split('.').pop().toLowerCase();
        let acceptHeader;

        switch (fileExtension) {
            case 'pdf':
                acceptHeader = 'application/pdf';
                break;
            case 'html':
                acceptHeader = 'text/html';
                break;
            case 'xlsx':
            case 'csv':
            case 'xlsm':
            case 'xls':
                acceptHeader = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                break;
            case 'txt':
                acceptHeader = 'text/plain';
                break;
            default:
                acceptHeader = 'text/plain';
        }

        this._bridge.getHelpDocumentUrl(
            documentObj,
            (res) => {
                const fileName = doc.reportName;
                this.dialog.open(DocPreviewComponent, {
                    panelClass: ['full-screen-modal', 'doc-preview'],
                    height: '98%',
                    width: '99%',
                    maxWidth: '99%',
                    data: {
                        blob:
                            fileExtension !== 'pdf' &&
                            fileExtension !== 'xlsx' &&
                            fileExtension !== 'csv' &&
                            fileExtension !== 'xls' &&
                            fileExtension !== 'xlsm'
                                ? null
                                : res,
                        htmlContent:
                            fileExtension !== 'pdf' &&
                            fileExtension !== 'xlsx' &&
                            fileExtension !== 'csv' &&
                            fileExtension !== 'xls' &&
                            fileExtension !== 'xlsm'
                                ? res
                                : ``,
                        fileName,
                        fileLink: documentObj.fileLink,
                        acceptHeader: acceptHeader,
                        fileExtension: fileExtension,
                    },
                    disableClose: false,
                });
            },
            () => {
                this._utils.alertError('Failed to get Document data');
            },
            acceptHeader
        );
    };
}
