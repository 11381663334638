import { createReducer, on } from '@ngrx/store';

import {
    ClearInstanceData,
    ClearInstancesPagination,
    ClearSearchInstances,
    RemoveInstances,
    SelectInstances,
    SessionLogoutSuccessfull,
    SetCurrentOrganization,
    SetInstance,
    SetInstanceEntityErrors,
    SetInstancesByProcessStatus,
    SetInstancesLoading,
    SetInstancesPagination,
    SetInstancesPagingState,
    SetInstanceState,
    SetProcessStatuses,
    SetSearchInstancesResponse,
    SetSelectedProcessStatus,
} from '../actions';
import { instancesState } from '../states';

export const INSTANCES_REDUCER = createReducer(
    instancesState,
    on(SetCurrentOrganization, (state, action) => {
        return {
            ...state,
            organizationsMap: {
                ...state.organizationsMap,
                [action.organization.id]: {
                    ...(state.organizationsMap?.[action.organization.id] || {}),
                },
            },
        };
    }),
    on(SetInstance, (state, action) => {
        return {
            ...state,
            instances: {
                ...state.instances,
                [action.instanceId]: {
                    ...state.instances?.[action.instanceId],
                    ...action.instance,
                },
            },
        };
    }),
    on(SetInstancesPagination, (state, action) => {
        const pagination = state.organizationsMap[action.organizationId]?.[action.serviceId]?.pagination;
        return {
            ...state,
            organizationsMap: {
                ...state.organizationsMap,
                [action.organizationId]: {
                    ...(state.organizationsMap[action.organizationId] || {}),
                    [action.serviceId]: {
                        ...state.organizationsMap[action.organizationId]?.[action.serviceId],
                        pagination: {
                            ...pagination,
                            pageIndex: action.pageIndex !== undefined ? action.pageIndex : pagination?.pageIndex,
                            size: action.size !== undefined ? action.size : pagination?.size,
                            pagingState: action.size === pagination?.size ? pagination?.pagingState : undefined,
                        },
                    },
                },
            },
        };
    }),
    on(SetInstancesPagingState, (state, action) => {
        return {
            ...state,
            organizationsMap: {
                ...state.organizationsMap,
                [action.organizationId]: {
                    ...(state.organizationsMap[action.organizationId] || {}),
                    [action.serviceId]: {
                        ...state.organizationsMap[action.organizationId]?.[action.serviceId],
                        pagination: {
                            ...state.organizationsMap[action.organizationId]?.[action.serviceId]?.pagination,
                            pagingState: action.pagingState,
                        },
                    },
                },
            },
        };
    }),
    on(ClearInstancesPagination, (state, action) => {
        return {
            ...state,
            organizationsMap: {
                ...state.organizationsMap,
                [action.organizationId]: {
                    ...(state.organizationsMap[action.organizationId] || {}),
                    [action.serviceId]: {
                        ...state.organizationsMap[action.organizationId]?.[action.serviceId],
                        pagination: {
                            ...state.organizationsMap[action.organizationId]?.[action.serviceId]?.pagination,
                            pageIndex: 0,
                            pagingState: undefined,
                        },
                    },
                },
            },
        };
    }),
    on(ClearSearchInstances, (state, action) => {
        return {
            ...state,
            organizationsMap: {
                ...state.organizationsMap,
                [action.organizationId]: {
                    ...(state.organizationsMap[action.organizationId] || {}),
                    [action.serviceId]: {
                        ...state.organizationsMap[action.organizationId]?.[action.serviceId],
                        searchedInstances: {
                            ...state.organizationsMap[action.organizationId]?.[action.serviceId]?.searchedInstances,
                            [action.criteriaName]: undefined,
                        },
                    },
                },
            },
        };
    }),
    on(SetSearchInstancesResponse, (state, action) => {
        const recordIds = action.forceFetch
            ? [
                  ...action.instances.reduce((instanceIds, instance) => {
                      instanceIds.push(instance.assetDataId);
                      return instanceIds;
                  }, [] as string[]),
              ]
            : [
                  ...new Set([
                      ...(state.organizationsMap?.[action.organizationId]?.[action.serviceId]?.searchedInstances?.[action.criteriaName] ||
                          ([] as string[])),
                      ...action.instances.reduce((instanceIds, instance) => {
                          instanceIds.push(instance.assetDataId);
                          return instanceIds;
                      }, [] as string[]),
                  ]),
              ];
        return {
            ...state,
            organizationsMap: {
                ...state.organizationsMap,
                [action.organizationId]: {
                    ...(state.organizationsMap[action.organizationId] || {}),
                    [action.serviceId]: {
                        ...state.organizationsMap[action.organizationId]?.[action.serviceId],
                        searchedInstances: {
                            ...state.organizationsMap[action.organizationId]?.[action.serviceId]?.searchedInstances,
                            [action.criteriaName]: recordIds,
                        },
                    },
                },
            },
            instances: {
                ...state.instances,
                ...action.instances.reduce((instancesMap, instance) => {
                    instancesMap[instance.assetDataId] = instance;
                    return instancesMap;
                }, {}),
            },
        };
    }),
    on(SelectInstances, (state, action) => {
        return {
            ...state,
            organizationsMap: {
                ...state.organizationsMap,
                [action.organizationId]: {
                    ...(state.organizationsMap[action.organizationId] || {}),
                    [action.serviceId]: {
                        ...state.organizationsMap[action.organizationId]?.[action.serviceId],
                        selectedInstances: {
                            ...state.organizationsMap[action.organizationId]?.[action.serviceId]?.selectedInstances,
                            [action.criteria]: [...new Set(action.instanceIds)],
                        },
                    },
                },
            },
        };
    }),
    on(RemoveInstances, (state, action) => {
        const searchedInstancesMap = state.organizationsMap[action.organizationId]?.[action.serviceId] || { searchedInstances: {} };
        const instanceIds = searchedInstancesMap?.searchedInstances?.[action.criteria];
        return {
            ...state,
            organizationsMap: {
                ...state.organizationsMap,
                [action.organizationId]: {
                    ...state.organizationsMap[action.organizationId],
                    [action.serviceId]: {
                        ...state.organizationsMap[action.organizationId]?.[action.serviceId],
                        searchedInstances: {
                            ...state.organizationsMap[action.organizationId]?.[action.serviceId]?.searchedInstances,
                            [action.criteria]: instanceIds.filter((id) => !action.instanceIds.includes(id)),
                        },
                    },
                },
            },
        };
    }),
    on(SetInstanceState, (state, action) => {
        return {
            ...state,
            instancesState: {
                ...state.instancesState,
                [action.instanceId]: action.state,
            },
        };
    }),
    on(SetInstanceEntityErrors, (state, action) => {
        return {
            ...state,
            instanceEntitiesState: {
                ...state.instanceEntitiesState,
                [action.instanceId]: action.state,
            },
        };
    }),
    on(ClearInstanceData, (state, action) => {
        return {
            ...state,
            instanceEntitiesState: {
                ...state.instanceEntitiesState,
                [action.instanceId]: undefined,
            },
            instancesState: {
                ...state.instancesState,
                [action.instanceId]: undefined,
            },
        };
    }),
    on(SetProcessStatuses, (state, action) => {
        return {
            ...state,
            organizationsMap: {
                ...state.organizationsMap,
                [action.organizationId]: {
                    ...state.organizationsMap[action.organizationId],
                    [action.serviceId]: {
                        ...state.organizationsMap[action.organizationId]?.[action.serviceId],
                        statuses: action.statuses,
                    },
                },
            },
        };
    }),
    on(SetSelectedProcessStatus, (state, action) => {
        return {
            ...state,
            organizationsMap: {
                ...state.organizationsMap,
                [action.organizationId]: {
                    ...(state.organizationsMap[action.organizationId] || {}),
                    [action.serviceId]: {
                        ...state.organizationsMap[action.organizationId]?.[action.serviceId],
                        selectedStatus: action.processStatus,
                    },
                },
            },
        };
    }),
    on(SetInstancesByProcessStatus, (state, action) => {
        return {
            ...state,
            organizationsMap: {
                ...state.organizationsMap,
                [action.organizationId]: {
                    ...(state.organizationsMap[action.organizationId] || {}),
                    [action.serviceId]: {
                        ...state.organizationsMap[action.organizationId]?.[action.serviceId],
                        instancesByStatus: {
                            ...state.organizationsMap[action.organizationId]?.[action.serviceId]?.instancesByStatus,
                            [action.criteria]: {
                                ...state.organizationsMap[action.organizationId]?.[action.serviceId]?.instancesByStatus?.[action.criteria],
                                ...{ [action.processStatus]: action.instances },
                            },
                        },
                    },
                },
            },
        };
    }),
    on(SetInstancesLoading, (state, action) => {
        return {
            ...state,
            organizationsMap: {
                ...state.organizationsMap,
                [action.organizationId]: {
                    ...state.organizationsMap[action.organizationId],
                    [action.serviceId]: {
                        ...state.organizationsMap[action.organizationId]?.[action.serviceId],
                        loading: {
                            ...state.organizationsMap[action.organizationId]?.[action.serviceId]?.loading,
                            [action.category]: action.loading,
                        },
                    },
                },
            },
        };
    }),
    on(SessionLogoutSuccessfull, () => instancesState)
);

export const INSTANCES_REDUCER_KEY = 'instances';
