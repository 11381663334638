import { Component, DoCheck, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { RootScope } from '@encomply-core/services/rootscope/rootscope.service';
import { TaxillaApiService } from '@encomply-core/services/taxillaapi/taxillaapi.service';
import { Utils } from '@encomply-core/services/utils/utils.service';
import { Subscription } from 'rxjs';

import { ModifiedErrorStateMatcher } from '../../../extensions/errorstatematcher/errorstatematcher';

@Component({
    selector: 'cities',
    templateUrl: './cities.component.html',
    styleUrls: ['./cities.component.scss'],
})
export class CitiesComponent implements OnInit, DoCheck, OnChanges, OnDestroy {
    @Input() data;
    @Input() state;
    @Input() disable;
    @Input() id;
    @Input() required: boolean;
    @Input() disabled: boolean;
    @Input() blockEvent = false;
    @Input() errors: string[];
    @Input() registerAddressCity;

    @Output() cityNameChanged = new EventEmitter();

    control: UntypedFormControl;
    matcher = new ModifiedErrorStateMatcher();
    valueSubscription: Subscription;
    cityClickedSubscription: Subscription;
    citiesList: any[];
    cities: any;
    public city: string;

    constructor(public R: RootScope, public _utils: Utils, public _taxilla: TaxillaApiService) {
        this.control = new UntypedFormControl({ value: (this.data && this.data.city) || '', disabled: this.disabled });
    }

    ngDoCheck() {
        if (this.errors === undefined && this.control.getError('errors') === undefined) {
            // do nothing
        } else if (
            (this.errors === undefined || (this.errors && this.errors.length === 0)) &&
            this.control.getError('errors') !== undefined
        ) {
            // mark undefined
            this.control.setErrors(undefined);
        } else if (
            this.errors !== undefined &&
            this.errors.length > 0 &&
            JSON.stringify(this.errors) !== JSON.stringify(this.control.getError('errors'))
        ) {
            // show errors
            this.control.setErrors({ errors: this.errors });
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.model) {
            this.control.value !== changes.model.currentValue && this.control.setValue(this.data.city);
        }
        if (changes.data) {
            this.control.value !== changes.data.currentValue && this.control.setValue(this.data.city);
        }
        if (changes.registerAddressCity) {
            if (this.disabled) {
                this.control.setValue(changes.registerAddressCity.currentValue);
            }
        }
        if (changes.state) {
            const newValue = changes.state.currentValue;
            const oldValue = changes.state.previousValue;
            if (newValue !== oldValue || (parseInt(newValue, 10) > 0 && (!this.cities || this.cities.length === 0))) {
                if (newValue > 0) {
                    if (!this.cities) {
                        this.cities = [];
                    }
                    if (!this.R.cities[newValue] || (this.R.cities[newValue] && this.R.cities[newValue].length === 0)) {
                        const data = {
                            stateId: newValue,
                        };
                        this._taxilla.common.getCities(data, {
                            successCallback: (response) => {
                                const cities = response || [];
                                for (let i = 0; i < cities.length; i++) {
                                    cities[i].name = this._utils.transformText(cities[i].name);
                                }
                                this.cities = cities;
                                this.R.cities[newValue] = cities;
                            },
                            failureCallback: () => {},
                        });
                    } else {
                        this.cities = this.R.cities && this.R.cities[newValue];
                    }
                } else {
                    this.data.city = '';
                }
                this.control.setValue(this.data.city);
            }
            if (newValue === '' || newValue === undefined || newValue === null) {
                if (this.data) {
                    this.data.city = '';
                }
                this.cities = [];
                this.control.setValue(this.data.city);
            }
        }
        if (changes.disabled) {
            if (changes.disabled.currentValue === true) {
                this.control.disable();
            } else {
                this.control.enable();
            }
        }
    }

    cityChanged = (newValue: string) => {
        this.errors = [];
        if (!newValue) {
            delete this.data.city;
        } else {
            this.data.city = newValue;
            for (let i = 0; i < this.cities.length; i++) {
                if (parseInt(newValue, undefined) === this.cities[i].id) {
                    this.data.cityName = this.cities[i].name;
                }
            }
        }
        this.cityNameChanged.emit(this.data.country);
    };
    ngOnInit() {
        this.valueSubscription = this.control.valueChanges.subscribe((event) => {
            if (!this.blockEvent) {
                this.cityChanged(event);
            }
        });
    }

    ngOnDestroy(): void {
        this.valueSubscription && this.valueSubscription.unsubscribe();
    }
}
