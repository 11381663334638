import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { Permission } from '../../models/permission.class';
import { Role } from '../../models/role.class';
import { AlertError } from '../../store/actions';
import { BridgeService } from '../bridge/bridge.service';
import { CommonUtilsService } from '../commonutils/common-utils.service';
import { StoreService } from '../store/store.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
    providedIn: 'root',
})
export class RolesService {
    constructor(private _bridge: BridgeService, private _utils: UtilsService, private _store: StoreService, private store$: Store) {}

    /**
     * Method to get roles
     * @param data contains organizationId
     * @param callbacks contains success and failure callbacks
     */
    getRoles = (organizationId: string) => {
        return new Observable<any>((observer) => {
            this._bridge.getRoles(
                organizationId,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to get all permissions
     * @param data contains OrganizationId
     * @param callbacks contains success and failure callbacks
     */
    getAllPermissions = (
        data: string,
        callbacks?: {
            successCallback: (res: any) => void;
            failureCallback?: (error: any) => void;
        }
    ) => {
        const maproPermissionsPromise = this.getMaproPermissions(data);
        const IDMPermissionsPromise = this.getIDMPermissions(data);
        const promise = Promise.all([maproPermissionsPromise, IDMPermissionsPromise]);
        if (!callbacks) {
            return promise;
        }
        promise
            .then(([maproPermissions, idmPermissions]) => {
                this._utils.copyObjectToObject(maproPermissions, idmPermissions);
                callbacks.successCallback(maproPermissions);
            })
            .catch((error) => {
                this._utils.alertError(error?.msg || error);
            });
    };

    getMaproPermissions = (organizationId: string) => {
        return new Promise((resolve, reject) => {
            this._bridge.getAllPermissions(
                organizationId,
                (res) => {
                    resolve(res);
                },
                (res) => {
                    reject(res);
                }
            );
        });
    };

    getIDMPermissions = (organizationId: string) => {
        return new Promise((resolve, reject) => {
            this._bridge.getAllIDMPermissions(
                organizationId,
                (res) => {
                    (res?.response?.Identity || []).forEach((permission) => {
                        permission.idm = true;
                    });
                    resolve({
                        response: {
                            permissions: {
                                User: res?.response?.Identity,
                            },
                        },
                    });
                },
                (res) => {
                    reject(res);
                }
            );
        });
    };

    /**
     * Method to get role permissions
     * @param data contains role object
     * @param callbacks contains success and failure callbacks
     */
    getRolePermissions = (
        data: Role,
        callbacks?: {
            successCallback: (res) => void;
            failureCallback: (res) => void;
        }
    ) => {
        const maproPromise = this.getMaproRolePermissions(data);
        const idmPromise = this.getIDMRolePermissions(data);
        const promise = Promise.all([maproPromise, idmPromise]);
        if (!callbacks) {
            return promise as any;
        }
        promise
            .then(([maproPermissions, idmPermissions]) => {
                this._utils.copyObjectToObject(maproPermissions, idmPermissions);
                callbacks.successCallback(maproPermissions);
            })
            .catch((error) => {
                this.store$.dispatch(AlertError({ message: error?.msg }));
                callbacks.failureCallback(error);
            });
    };

    getMaproRolePermissions = (role: Role) => {
        return new Promise((resolve, reject) => {
            this._bridge.getMaproRolePermissions(
                role,
                (res) => {
                    let maproRoles = [];
                    if (res?.response?.permissions) {
                        maproRoles = res.response.permissions.map((item) => {
                            return item['uuid'] ?? item['id'];
                        });
                    }
                    resolve(maproRoles);
                },
                (res) => {
                    reject(res);
                }
            );
        });
    };

    getIDMRolePermissions = (role: Role) => {
        return new Promise((resolve, reject) => {
            this._bridge.getIDMRolePermissions(
                role,
                (res) => {
                    let IdmRoles = [];
                    if (res?.response?.permissions) {
                        IdmRoles = res.response.permissions.map((item) => {
                            return item['uuid'] ?? item['id'];
                        });
                    }
                    resolve(IdmRoles);
                },
                (res) => {
                    reject(res);
                }
            );
        });
    };

    /**
     * Method to update roles
     * @param data contains role and permissions
     * @param callbacks contains success and failure callbacks
     */
    updateRole = (
        data: {
            role: Role;
            permissions: Permission[];
        },
        callbacks?: {
            successCallback: (res) => void;
            failureCallback: (res) => void;
        }
    ) => {
        const maproPromise = this.updateMaproPermissions(CommonUtilsService.cloneObject(data));
        const idmPromise = this.updateIDMPermissions(CommonUtilsService.cloneObject(data));
        const promise = Promise.all([maproPromise, idmPromise]);
        if (!callbacks) {
            return promise as any;
        }
        promise
            .then(([mapro, idm]) => {
                this._utils.copyObjectToObject(mapro, idm);
                callbacks.successCallback(mapro);
            })
            .catch((error) => {
                callbacks.failureCallback(error);
            });
    };

    updateMaproPermissions = (data: { role: Role; permissions: Permission[] }) => {
        return new Promise((resolve, reject) => {
            // data.permissions = data.permissions.filter((permission) => !permission.idm);
            (data.permissions || []).forEach((permission) => delete permission.idm);
            this._bridge.updateMaproRole(
                data,
                (res) => {
                    resolve(res);
                },
                (res) => {
                    reject(res);
                }
            );
        });
    };

    updateIDMPermissions = (data: { role: Role; permissions: Permission[] }) => {
        return new Promise((resolve, reject) => {
            data.permissions = data.permissions.filter((permission) => permission.idm);
            (data.permissions || []).forEach((permission) => delete permission.idm);
            this._bridge.updateIDMRole(
                data,
                (res) => {
                    resolve(res);
                },
                (res) => {
                    reject(res);
                }
            );
        });
    };

    /**
     * Method for role users
     * @param data contains roleName and organizationId
     * @param callbacks contains success and failure callbacks
     */

    roleUsers = (roleId) => {
        return new Observable<any>((observer) => {
            this._bridge.roleUsers(
                roleId,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method for copy role
     * @param data contains roleId and locationids
     * @param callbacks contains success and failure callbacks
     */
    copyRole = (data: { roleId: string; locationIds: string }) => {
        return new Observable<any>((observer) => {
            this._bridge.copyRole(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to assign users
     * @param data contains roleId,newuserids and olduserids
     * @param callbacks contains success and failure callbacks
     */
    assignUsers = (
        data: {
            roleId: string;
            ids: string[];
            action: 'ASSIGN' | 'UNASSIGN';
        },
        callbacks?: {
            successCallback: (res) => void;
            failureCallback: (res) => void;
        }
    ) => {
        this._bridge.assignUsers(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to delete roles
     * @param data contains role and permissions
     * @param callbacks contains success and failure callbacks
     */
    deleteRole = (roleId: string) => {
        return new Observable<any>((observer) => {
            this._bridge.deleteRole(
                roleId,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to create roles
     * @param data contains role
     * @param callbacks contains success and failure callbacks
     */
    createRole = (data) => {
        return new Observable<any>((observer) => {
            this._bridge.createRole(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to update roles Data
     * @param data contains role and permissions anad requestId
     * @param callbacks contains success and failure callbacks
     */
    updateRoleData = (data: { requestId: string; roleId: string; role: Role; permissions: Permission[] }) => {
        return new Observable<any>((observer) => {
            data.permissions = CommonUtilsService.cloneObject(data.permissions);
            data.permissions.forEach((permission) => {
                delete permission.idm;
            });
            this._bridge.updateRolesData(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to get role permissions of 4ec request
     * @param data contains roleId
     * @param callbacks contains success and failure callbacks
     */

    getRequestRolePermissions = (
        data: {
            requestId: string;
        },
        callbacks?: {
            successCallback: (res) => void;
            failureCallback: (res) => void;
        }
    ) => {
        this._bridge.getRequestRolePermissions(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to get role permissions of 4ec request
     * @param data contains roleId
     * @param callbacks contains success and failure callbacks
     */

    getRequestRolePermissionsNew = (
        data: {
            requestId: string;
        },
        callbacks?: {
            successCallback: (res) => void;
            failureCallback: (res) => void;
        }
    ) => {
        this._bridge.getRequestRolePermissionsNew(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                callbacks.failureCallback(res);
            }
        );
    };

    /**
     * Method to update role without permissions
     * @param data contains role
     * @param callbacks contains success and failure callbacks
     */
    updateRoleWithoutPermissions = (role: Role) => {
        return new Observable<any>((observer) => {
            this._bridge.updateRoleWithoutPermissions(
                role,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to get Roles By OrganizationId
     * @param data Contains locationId and UserId
     * @param callbacks Contains success callback method, failure callback method
     */

    getRolesByOrganizationId = (data: { locationId: string; userId: string }) => {
        return new Observable<{ msg: string }>((observer) => {
            this._bridge.getRolesByOrganizationId(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to get bundle roles
     * @param data contains organizationId
     * @param callbacks contains success and failure callbacks
     */
    getBundleRoles = (bundleId: string) => {
        const bundlePromise = this._bridge.getBundleRoles(bundleId);
        bundlePromise.catch((e) => {
            this._utils.alertError(e?.msg || 'Failed to get roles');
        });
        return bundlePromise;
    };

    /**
     * Method to get bundle role permissions
     */
    getBundlePermissions = async (bundleId: string, serviceIds: string[]) => {
        const id = serviceIds.join('||');
        const response = await this._store.privateScope.fetchValues(
            () => {
                const bundlePromise = this._bridge.getBundlePermissions(bundleId);
                bundlePromise.catch((e) => {
                    this._utils.alertError(e?.msg || 'Failed to get roles');
                });
                return bundlePromise;
            },
            'bunldePermissions',
            id
        );
        return response?.bunldePermissions?.[id];
    };

    /**
     * Method to create bundle role
     */
    createBundleRole = (bundleId: string, payload) => {
        const bundlePromise = this._bridge.createBundleRole(bundleId, payload);
        bundlePromise.catch((e) => {
            this._utils.alertError(e?.msg || 'Failed to create role');
        });
        return bundlePromise;
    };

    /**
     * Method to update bundle role
     */
    updateBundleRole = (bundleId: string, roleId: string, payload: any) => {
        const bundlePromise = this._bridge.updateBundleRole(bundleId, roleId, payload);
        bundlePromise.catch((e) => {
            this._utils.alertError(e?.msg || 'Failed to update role');
        });
        return bundlePromise;
    };

    /**
     * Method to delete bundle role
     */
    deleteBundleRole = (bundleId: string, roleId: string) => {
        const bundlePromise = this._bridge.deleteBundleRole(bundleId, roleId);
        bundlePromise.catch((e) => {
            this._utils.alertError(e?.msg || 'Failed to delete role');
        });
        return bundlePromise;
    };

    /**
     * Method to get bundle role
     */
    getBundleRolePermissions = (bundleId: string, roleId: string) => {
        const bundlePromise = this._bridge.getBundleRolePermissions(bundleId, roleId);
        bundlePromise.catch((e) => {
            this._utils.alertError(e?.msg || 'Failed to get role permissions');
        });
        return bundlePromise;
    };

    /**
     * Method to update bundle role
     */
    updateBundleRolePermissions = (bundleId: string, roleId: string, payload: { permissions: { uuid: string }[] }) => {
        const bundlePromise = this._bridge.updateBundleRolePermissions(bundleId, roleId, payload);
        bundlePromise.catch((e) => {
            this._utils.alertError(e?.msg || 'Failed to update role permissions');
        });
        return bundlePromise;
    };
}
