import { createFeatureSelector, createSelector } from '@ngrx/store';

import { MastersMetaData } from '../../models/masters/mastersmetadata.class';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { MASTERS_REDUCER_KEY } from '../reducers';
import { MastersInitialState } from '../states/master-management.state';
import { getCurrentOrganizationId$ } from './session.selector';

const currentState = createFeatureSelector<MastersInitialState>(MASTERS_REDUCER_KEY);

export const getMastersOrganizationMap$ = createSelector(
    currentState,
    getCurrentOrganizationId$,
    (state, organizationId) => state.organizationsMap?.[organizationId]
);

export const getMasters$ = createSelector(getMastersOrganizationMap$, (organizationMap) => organizationMap?.masters || []);

export const getSelectedTableId$ = createSelector(currentState, (state) => state.selectedTableId || '');

export const getSelectedRestApiName$ = createSelector(currentState, (state) => state.selectedRestApiName || '');

export const getParentTableId$ = createSelector(currentState, (state) => state.parentTableId);

export const getParentTableRestApiName$ = createSelector(currentState, (state) => state.parentTableRestApiName || '');

export const getMasterLanguageTemplate$ = createSelector(
    getMastersOrganizationMap$,
    getSelectedRestApiName$,
    (masterOrgMap, restApiName) => masterOrgMap?.languageTemplate?.[restApiName]
);

const getSelectedMasterMetadata$ = createSelector(
    getMastersOrganizationMap$,
    getSelectedTableId$,
    (masterOrgMap, tableId) => masterOrgMap?.metaData?.[tableId]
);

export const getMasterMetadata$ = createSelector(getSelectedMasterMetadata$, getMasterLanguageTemplate$, (data, template) => {
    let metaData: MastersMetaData = CommonUtilsService.cloneObject(data);
    if (metaData?.masterItem?.itemId?.length > 0 && template !== undefined) {
        metaData['languageData'] = template;
        return new MastersMetaData(metaData);
    }
    return undefined;
});

export const getSearchFilters$ = createSelector(
    getMastersOrganizationMap$,
    getSelectedRestApiName$,
    (orgMap, restApiName) => orgMap?.searchFilters?.[restApiName]
);

export const getMasterRecordPagination$ = createSelector(
    getMastersOrganizationMap$,
    getSelectedRestApiName$,
    (orgMap, restApiName) => orgMap?.pagination?.[restApiName]
);

export const getSearchedRecordsCount$ = createSelector(currentState, (state) => state.masterSearchCount);

export const getMasterRecordsMap$ = createSelector(
    getMastersOrganizationMap$,
    getSelectedRestApiName$,
    (masterOrgMap, restAPIName) => masterOrgMap?.records?.[restAPIName]
);

export const getMasterRequestsMap$ = createSelector(
    getMastersOrganizationMap$,
    getSelectedRestApiName$,
    (masterOrgMap, restAPIName) => masterOrgMap?.requests?.[restAPIName]
);

export const getParentMasterRecords$ = createSelector(
    getMastersOrganizationMap$,
    getParentTableRestApiName$,
    (masterOrgMap, restAPIName) => masterOrgMap?.records?.[restAPIName]
);

export const getMasterRecords$ = createSelector(getMasterRecordsMap$, getMasterRecordPagination$, (records, pagination) => {
    const start = pagination?.pageIndex * pagination?.pageSize;
    const end = (pagination?.pageIndex + 1) * pagination?.pageSize;
    return records?.filter((_record, index) => index >= start && index < end) || [];
});

export const getMasterRecordsPagingState$ = createSelector(
    getMasterRecordPagination$,
    getSearchedRecordsCount$,
    getMasterRecordsMap$,
    (pagination, recordsCount, records) => {
        const start = pagination?.pageIndex * pagination?.pageSize;
        const end = (pagination?.pageIndex + 1) * pagination?.pageSize;
        const pagingState =
            pagination?.pageState?.[pagination?.pageIndex]?.trim().length > 0 && pagination?.pageState?.[pagination?.pageIndex];
        const searchAfter = pagination?.search?.searchAfter?.length > 0 && (pagination?.search?.searchAfter as any);
        return {
            pagingState: pagingState || searchAfter,
            count: recordsCount || records?.length,
            start: start,
            end: end,
        };
    }
);

export const getIsRecordsLoading$ = createSelector(currentState, (state) => state.isRecordsLoading);

export const getParentPrimaryKeysMap$ = createSelector(getMastersOrganizationMap$, (orgMap) => orgMap?.parentPrimaryKeys);

export const getParentPrimaryKeys$ = createSelector(
    getParentPrimaryKeysMap$,
    getParentTableRestApiName$,
    getParentTableId$,
    (keysMap, restApiName, tableId) => {
        let keyColumns = keysMap?.[tableId] || [];
        if (keyColumns.length > 0 && restApiName.length > 0) {
            return {
                keyColumns,
                restApiName,
            };
        }
        return undefined;
    }
);

export const getMastersInboundMappings$ = createSelector(
    getMastersOrganizationMap$,
    getSelectedTableId$,
    (orgMap, tableId) => orgMap?.inboundMappings?.[tableId] || undefined
);

export const getMasterchainDisplayNames$ = createSelector(
    getMastersOrganizationMap$,
    getSelectedRestApiName$,
    (orgMap, restApiName) => orgMap?.chainDisplayNames?.[restApiName] || undefined
);

export const getMasterRequestsPagination$ = createSelector(
    getMastersOrganizationMap$,
    getSelectedRestApiName$,
    (orgMap, restApiName) => orgMap?.requestsPagination?.[restApiName] || undefined
);

export const getMasterRequests$ = createSelector(getMasterRequestsMap$, getMasterRequestsPagination$, (requests, pagination) => {
    const start = pagination?.pageIndex * pagination?.pageSize;
    const end = (pagination?.pageIndex + 1) * pagination?.pageSize;
    let selectedRequests = requests?.filter((_request, index) => index >= start && index < end) || [];
    return {
        requests: selectedRequests,
        pagingState: pagination?.pageState?.[pagination?.pageIndex] || '',
    };
});

export const getMastersOutboundMappings$ = createSelector(
    getMastersOrganizationMap$,
    getSelectedTableId$,
    (orgMap, tableId) => orgMap?.outboundMappings?.[tableId] || undefined
);

export const getReportDetails$ = createSelector(
    getMastersOrganizationMap$,
    getSelectedTableId$,
    (orgMap, tableId) => orgMap?.reportDetails?.[tableId] || undefined
);

export const getMasterUploadFiles$ = createSelector(
    getMastersOrganizationMap$,
    getSelectedTableId$,
    (orgMap, tableId) => orgMap?.uploadedFiles?.[tableId] || undefined
);
