<mat-form-field>
    <input type="text" matInput [formControl]="control" placeholder="{{placeholder}}" name="{{name}}" title="{{title}}" id="{{id}}" class="{{classList && classList.join(' ')}}" [matAutocomplete]="auto" [errorStateMatcher]="matcher" debounce="{{debounceAfter || 1000}}" (eventHandler)="updateModelAndEmitDebounceEvent()" (click)="panelOpen = true">
    <button mat-button *ngIf="description && description.length > 0" matSuffix mat-icon-button aria-label="Description" tabindex="-1">
        <span class="material-icons-outlined fieldDescInfoIcon" title="{{description}}">
            info
        </span>
    </button>
    <mat-hint align="start" *ngIf="lookupHint">
        <strong>
            <span>* </span>Master Search</strong>
    </mat-hint>
    <mat-hint align="end" *ngIf="maxLength">{{control.value && control.value.length || 0}} / {{maxLength}}</mat-hint>
    <mat-hint align="start" *ngIf="required && !lookupHint && !(errors && errors.length > 0)">
        <strong>
            <span>* </span>Required</strong>
    </mat-hint>
    <mat-error *ngIf="control.hasError('errors')" title="{{errors}}">
        {{errors}}
    </mat-error>
</mat-form-field>
<mat-autocomplete #auto="matAutocomplete" class="matAutocompleteOptionsContainer" (optionSelected)="selectedOption($event)">
    <div *ngIf="hasSearch" class="autoCompleteSearch">
        <material-input [placeholder]="'Search...'" [model]="searchOptions" [debounceAfter]="1000" (modelChange)="searchOptions = $event" (debounceEventHandler)="searchChanged.emit(searchOptions)">
        </material-input>
    </div>
    <mat-option *ngFor="let option of options" [value]="option.value" [ngClass]="{matOptionHide: dataPresentation === 'table'}">
        {{option.displayName}}
    </mat-option>
    <ng-container *ngIf="dataPresentation === 'table'">
        <material-table [allTableColumns]="allColumns" [selectedColumns]="selectedColumns" [data]="tableData" (onDoubleClickEvent)="onRecordSelect($event)" (recordSelectedEvent)="onRecordSelect($event)"></material-table>
    </ng-container>
    <div *ngIf="!masterDisabled && (options && options.length === 0)" class="noOptionsFound">
        No options found
    </div>
</mat-autocomplete>
