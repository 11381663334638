import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { translate } from '@ngneat/transloco';
import { BroadcasterService } from 'ng-broadcaster';
import { NgxMaterialTimepickerComponent, NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { UtilsService } from '../../services/utils/utils.service';

@Component({
    selector: 'app-date-filter',
    templateUrl: './date-filter.component.html',
    styleUrls: ['./date-filter.component.scss'],
})
export class DateFilterComponent implements OnInit, OnDestroy {
    @Input() timeFilter: any;

    @Input() isModal?: boolean;

    @Output() onSearchByTimeRange = new EventEmitter();

    @Output() onClearSearch = new EventEmitter();

    @ViewChild('timeFilterModal') timeFilterDialogModal: TemplateRef<any>;
    @ViewChild('StartTimePick') startPicker: NgxMaterialTimepickerComponent;
    @ViewChild('endTimePick') endPicker: NgxMaterialTimepickerComponent;
    isDateSearch = false;
    darkTheme: NgxMaterialTimepickerTheme = {
        container: {
            buttonColor: '#00a651',
        },
        dial: {
            dialBackgroundColor: '#00a651',
        },
        clockFace: {
            clockHandColor: '#00a651',
            clockFaceTimeInactiveColor: '#00a651',
        },
    };
    public dialogRef: MatDialogRef<DateFilterComponent>;
    isIE: boolean;
    unSubscribe = new Subject<void>();

    constructor(
        private dialog: MatDialog,
        private _libUtils: UtilsService,
        public _commonUtils: CommonUtilsService,
        private _broadcaster: BroadcasterService
    ) {}

    startComponent = () => {
        this.isIEBrowser();
    };

    private isIEBrowser = (): void => {
        this.isIE = this._libUtils.isIEBrowser();
    };

    onDateSearchFocus = () => {
        if (!this.isModal) {
            this.isDateSearch = true;
        } else {
            this.dialogRef = this.dialog.open(this.timeFilterDialogModal, {
                panelClass: ['timeFilterDialogModal', 'matDialogContainer'],
                disableClose: true,
            });
        }
    };

    searchByTimeRange = () => {
        let defaultStartTime = '12:00 AM';
        let defaultEndTime = '11:59 PM';
        const defaultStartTimeString = this.timeFilter?.startTime?.length > 0 ? undefined : 'startTime';
        const defaultEndTimeString = this.timeFilter?.endTime?.length > 0 ? undefined : 'endTime';
        const convertedStartTime = this._commonUtils.getEpochDateFormat(
            this.timeFilter?.startTime ?? defaultStartTime,
            this.timeFilter?.startDate,
            'IE',
            true,
            defaultStartTimeString
        );
        const convertedEndTime = this._commonUtils.getEpochDateFormat(
            this.timeFilter?.endTime ?? defaultEndTime,
            this.timeFilter?.endDate,
            'IE',
            true,
            defaultEndTimeString
        );
        if (this.checkDateRange(convertedStartTime, convertedEndTime)) {
            if (!this.isModal) {
                this.isDateSearch = false;
            } else {
                this.dialogRef.close();
            }
            let timeRange = [convertedStartTime, convertedEndTime];
            this.onSearchByTimeRange.emit(timeRange);
        }
    };

    checkDateRange = (startTime, endTime) => {
        let startTimeStamp = new Date(startTime);
        let endTimeStamp = new Date(endTime);
        if (endTimeStamp < startTimeStamp) {
            this._libUtils.alertError(this.translateMsg('Please enter valid range'));
            return false;
        } else {
            return true;
        }
    };

    clearSearchCriteria = () => {
        if (!this.isModal) {
            this.isDateSearch = false;
        } else {
            this.dialogRef.close();
        }
        this.onClearSearch.emit();
    };

    resetDatePicker = () => {
        if (this.startPicker && this.endPicker) {
            this.startPicker.defaultTime = '12:00 AM';
            this.endPicker.defaultTime = '12:00 AM';
        }
    };

    closePopup = () => {
        this.dialogRef.close();
    };

    ngOnInit() {
        this.startComponent();
        this._broadcaster
            .on('resetDateFilter')
            .pipe(takeUntil(this.unSubscribe))
            .subscribe(() => {
                this.resetDatePicker();
            });
    }

    ngOnDestroy() {
        this.unSubscribe.next();
        this.unSubscribe.complete();
    }

    translateMsg = (msg: string): string => translate(msg);
}
