import { User } from '../../models/user.class';

export interface SessionState {
    timeZoneOffset: number;
    passwordExpiresInDays: number;
    session: boolean;
    currentOrganizationId: string;
    loggedInOrganizationId: string;
    sessionUser: User;
    lastLogin: boolean;
    applicationMiscProperties: {
        'min-mfa-answers': number;
        'password-policy': string;
        'password-policy-msg': string;
        'seed-token': string;
        'x-auth-token': string;
    };
    activeLanguage: 'en' | 'ar' | 'sr-cyrl' | 'sr-latn' | 'pl' | 'ja' | 'ro';
    isAdmin: boolean;
    workQCount: number;
    workQMessages: any[];
    providerAdmin: boolean;
    providerOrganization: boolean;
}

export const sessionState: SessionState = {
    session: undefined,
    currentOrganizationId: undefined,
    loggedInOrganizationId: undefined,
    sessionUser: undefined,
    passwordExpiresInDays: undefined,
    timeZoneOffset: undefined,
    lastLogin: undefined,
    applicationMiscProperties: {
        'min-mfa-answers': undefined,
        'password-policy': undefined,
        'password-policy-msg': undefined,
        'seed-token': undefined,
        'x-auth-token': undefined,
    },
    activeLanguage: undefined,
    isAdmin: undefined,
    workQCount: undefined,
    workQMessages: undefined,
    providerAdmin: undefined,
    providerOrganization: undefined,
};
