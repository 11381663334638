import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { ApiService } from '../../services/api/api.service';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import {
    ActiveTokensFailed,
    ActiveTokensSuccess,
    GetActiveTokens,
    InvalidateTokens,
    InvalidateTokensFailed,
    InvalidateTokensSuccess,
} from '../actions';

@Injectable()
export class TokenEffects {
    constructor(private actions$: Actions, private _api: ApiService) {}

    getActiveTokens$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GetActiveTokens),
            mergeMap((action) => {
                return this._api.common.getActiveTokens(action.payload).pipe(
                    map((response: { records: any[]; count: number }) => {
                        response?.records.forEach((token) => {
                            const expireDate = CommonUtilsService.transformDateToDefaultFormat(token.expires, 'dateTime');
                            token.expires = CommonUtilsService.transformDate(expireDate, 'DD-MM-YYYY HH:MM:SS AM', 'ddMMMyyyy HH:MM:SS AM');
                        });
                        return ActiveTokensSuccess({
                            activeTokens: response?.records,
                            count: response.count,
                        });
                    }),
                    catchError((errorResponse) => of(ActiveTokensFailed({ msg: errorResponse })))
                );
            })
        );
    });

    invalidateActiveTokens$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(InvalidateTokens),
            mergeMap((action) => {
                return this._api.common.invalidateTokens(action.payload).pipe(
                    map((response: { msg: string }) =>
                        InvalidateTokensSuccess({
                            msg: response.msg,
                        })
                    ),
                    catchError((errorResponse) =>
                        of(
                            InvalidateTokensFailed({
                                msg: errorResponse.msg,
                            })
                        )
                    )
                );
            })
        );
    });
}
