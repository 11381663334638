import { createReducer, on } from '@ngrx/store';

import * as manageAppsActions from '../actions/manage-apps.actions';
import * as sessionActions from '../actions/session.actions';
import * as actions from '../actions/tags.actions';
import { tagsState } from '../states/tags.state';

export const TAGS_REDUCER = createReducer(
    tagsState,
    on(manageAppsActions.SetManageAppsStatus, (state, action) => {
        if (!(action.organizationId?.length > 0)) {
            return state;
        }
        return {
            ...state,
            organizationsMap: {
                ...state.organizationsMap,
                [action.organizationId]: {
                    ...state.organizationsMap?.[action.organizationId],
                    subscriptionStatus: action.subscriptionStatus || 'NOT_SUBSCRIBED',
                },
            },
        };
    }),
    on(actions.SetTagKeys, (state, action) => {
        if (!(action.organizationId?.length > 0)) {
            return state;
        }
        return {
            ...state,
            organizationsMap: {
                ...state.organizationsMap,
                [action.organizationId]: {
                    ...state.organizationsMap?.[action.organizationId],
                    tagKeys: action.keys,
                },
            },
        };
    }),
    on(actions.SetTagKey, (state, action) => {
        if (!(action.organizationId?.length > 0)) {
            return state;
        }
        return {
            ...state,
            organizationsMap: {
                ...state.organizationsMap,
                [action.organizationId]: {
                    ...state.organizationsMap?.[action.organizationId],
                    currentTagKey: action.key,
                },
            },
        };
    }),
    on(actions.SetTagKeyValues, (state, action) => {
        if (!(action.organizationId?.length > 0)) {
            return state;
        }
        return {
            ...state,
            organizationsMap: {
                ...state.organizationsMap,
                [action.organizationId]: {
                    ...state.organizationsMap[action.organizationId],
                    tagValues: {
                        ...state.organizationsMap[action.organizationId]?.tagValues,
                        [state.organizationsMap[action.organizationId].currentTagKey]: action.values,
                    },
                },
            },
        };
    }),
    on(actions.SetTagValue, (state, action) => {
        if (!(action.organizationId?.length > 0)) {
            return state;
        }
        return {
            ...state,
            organizationsMap: {
                ...state.organizationsMap,
                [action.organizationId]: {
                    ...state.organizationsMap[action.organizationId],
                    currentTagValue: action.value,
                },
            },
        };
    }),
    on(actions.SetTagAppsByKeyValues, (state, action) => {
        if (!(action.organizationId?.length > 0)) {
            return state;
        }
        const key = action.tagKey || state.organizationsMap[action.organizationId].currentTagKey,
            value = action.tagValue !== undefined ? action.tagValue : state.organizationsMap[action.organizationId].currentTagValue,
            status = action.status || state.organizationsMap[action.organizationId].subscriptionStatus;
        return {
            ...state,
            organizationsMap: {
                ...state.organizationsMap,
                [action.organizationId]: {
                    ...state.organizationsMap[action.organizationId],
                    tagKeyValuesVsAppDetails: {
                        ...state.organizationsMap[action.organizationId]?.tagKeyValuesVsAppDetails,
                        [`${key}|${value}|${status}`]: action.apps,
                    },
                },
            },
        };
    }),
    on(actions.ResetTag, (state, action) => {
        if (!(action.organizationId?.length > 0)) {
            return state;
        }
        return {
            ...state,
            organizationsMap: {
                ...state.organizationsMap,
                [action.organizationId]: {
                    ...state.organizationsMap[action.organizationId],
                    currentTagKey: undefined,
                    currentTagValue: undefined,
                },
            },
        };
    }),
    on(sessionActions.SessionLogoutSuccessfull, () => tagsState)
);

export const TAGS_REDUCER_KEY = 'tags';
