import { AppsEffects } from './apps.effects';
import { AuthEffects } from './auth.effects';
import { AuthorizationSystemsEffects } from './authorization-systems.effects';
import { BundleEffects } from './bundles.effects';
import { DashboardEffects } from './dashboard.effects';
import { EninvoiceEffects } from './eninvoice.effects';
import { GstFilingEffects } from './gst-filing.effects';
import { IdentityProvidersEffects } from './identity-providers.effects';
import { InstancesEffects } from './instances.effects';
import { IntegrationEffects } from './integrations.effects';
import { LocationEffects } from './locations.effects';
import { ManageAppsEffects } from './manage-apps.effects';
import { MasterEffects } from './master-management.effects';
import { NotificationEffects } from './notifications.effects';
import { PartnerEffects } from './partner.effects';
import { PermissionsEffects } from './permissions.effects';
import { RecordsEffects } from './records.effects';
import { ReportsEffects } from './reports.effects';
import { RequestEffects } from './requests.effects';
import { RolesEffects } from './roles.effects';
import { SearchEffects } from './search.effects';
import { SessionEffects } from './session.effects';
import { SharedEffects } from './shared.effects';
import { TagsEffects } from './tags.effects';
import { TokenEffects } from './tokens.effects';
import { TransformationEffects } from './transformations.effects';
import { UserEffects } from './users.effects';
import { widgetTranslateEffects } from './widget.effects';
import { WorkflowEffects } from './workflow.effects';

export const Effects = [
    AuthEffects,
    AuthorizationSystemsEffects,
    EninvoiceEffects,
    SessionEffects,
    SharedEffects,
    UserEffects,
    IntegrationEffects,
    ManageAppsEffects,
    TagsEffects,
    AppsEffects,
    BundleEffects,
    widgetTranslateEffects,
    LocationEffects,
    PartnerEffects,
    TokenEffects,
    InstancesEffects,
    TransformationEffects,
    RequestEffects,
    ReportsEffects,
    WorkflowEffects,
    GstFilingEffects,
    NotificationEffects,
    RecordsEffects,
    RolesEffects,
    MasterEffects,
    DashboardEffects,
    PermissionsEffects,
    IdentityProvidersEffects,
    SearchEffects,
];
