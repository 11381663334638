import { createReducer, on } from '@ngrx/store';

import { ClearInstanceData, SessionLogoutSuccessfull, SetWorkflow, SetWorkflowLoading } from '../actions';
import { workflowState } from '../states/workflow.state';

export const WORKFLOW_REDUCER = createReducer(
    workflowState,
    on(SetWorkflow, (state, action) => {
        return {
            ...state,
            instanceIdVsWorkflowState: {
                ...state.instanceIdVsWorkflowState,
                [action.instanceId]: action.workflows,
            },
        };
    }),
    on(ClearInstanceData, (state, action) => {
        return {
            ...state,
            instanceIdVsWorkflowState: {
                ...state.instanceIdVsWorkflowState,
                [action.instanceId]: undefined,
            },
        };
    }),
    on(SetWorkflowLoading, (state, action) => {
        return {
            ...state,
            loading: {
                ...state.loading,
                ...action.instanceIds.reduce((loadingMap, id) => {
                    loadingMap[id] = action.loading;
                    return loadingMap;
                }, {}),
            },
        };
    }),
    on(SessionLogoutSuccessfull, () => workflowState)
);

export const WORKFLOW_REDUCER_KEY = 'workflows';
