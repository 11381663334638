import { createAction, props } from '@ngrx/store';

import { Chain } from '../../models/chain.interface';
import { TransformationsState } from '../states';

import type { Transformation } from '../../models/transformation';
enum transformationTypes {
    GET_INBOUND_DETAILED_TRANSFORMATIONS = '[TRANSFORMATIONS] Get Inbound Detailed Transformations',
    SET_INBOUND_DETAILED_TRANSFORMATIONS = '[TRANSFORMATIONS] Set Inbound Detailed Transformations',
    SET_UPDATED_TRANSFORMATIONS = '[TRANSFORMATIONS] Set Updated Transformations',
    GET_ALL_TRANSFORMATIONS = '[INSTANCES] Get All Transformations',
    SET_TRANSFORMATIONS = '[INSTANCES] Set Transformations',
    SET_CHAIN_DISPLAY_NAMES = '[TRANSFORMATIONS] Set Chain Display Names',
    PROCESS_ASSET_TO_ASSET = '[TRANSFORMATIONS] Process Asset To Asset',
    ASSET_TO_ASSET_PROCESS_SUCCESS = '[TRANSFORMATIONS] Processing of Asset To Asset successful',
    ASSET_TO_ASSET_PROCESS_FAILURE = '[TRANSFORMATIONS] Processing of Asset To Asset failed',
    GET_ACTIVE_TRANSFORMATIONS_BY_TYPE = '[TRANSFORMATIONS] Get active transformations by type INBOUND | OUTBOUND',
    SET_ACTIVE_TRANSFORMATIONS_BY_TYPE = '[TRANSFORMATIONS] Set active transformations by type INBOUND | OUTBOUND',
    UPDATE_ACTIVE_TRANSFORMATIONS_BY_TYPE = '[TRANSFORMATIONS] update active transformations by type INBOUND | OUTBOUND',
    SET_TRANSFORMATION_LOADING = '[TRANSFORMATIONS] Set transformations loading',
    GET_MASTER_SCHEDULER_TRANSFORMATIONS = '[TRANSFORMATIONS] Get master scheduler transformations',
    SET_MASTER_SCHEDULER_TRANSFORMATIONS = '[TRANSFORMATIONS] Set master scheduler transformations',
    GET_INTEGRATIONS_CHAINS = '[TRANSFORMATIONS] Get integrations chains',
    SET_INTEGRATIONS_CHAINS = '[TRANSFORMATIONS] Set integrations chains',
    GET_OUTBOUND_CHAINS = '[TRANSFORMATIONS] Get Outbound Chains',
    SET_OUTBOUND_CHAINS = '[TRANSFORMATIONS] Set Outbound Chains',
    GET_TRANSFORMATION_NAMES_IN_CHAIN = '[TRANSFORMATIONS] Get Transformation Names in Chain',
    SET_TRANSFORMATION_NAMES_IN_CHAIN = '[TRANSFORMATIONS] Set Transformation Names in Chain',
    GET_TRANSFORMATION_AND_CHAINS = '[TRANSFORMATIONSANDCHAINS] Get Transformation and Chains',
    SET_TRANSFORMATION_AND_CHAINS = '[TRANSFORMATIONSANDCHAINS] Set Transformation and Chains',
}

export const GetInboundDetailedTransformations = createAction(
    transformationTypes.GET_INBOUND_DETAILED_TRANSFORMATIONS,
    props<{
        serviceId: string;
        assetMetaUid: string;
        restApiName: string;
        forceFetch?: boolean;
        noAlerts: boolean;
    }>()
);

export const SetInboundDetailedTransformations = createAction(
    transformationTypes.SET_INBOUND_DETAILED_TRANSFORMATIONS,
    props<{
        serviceId: string;
        organizationId: string;
        relativeOrganizations: any;
        inbound: { [property: string]: { [property: string]: Transformation } };
    }>()
);

export const SetUpdatedTransformations = createAction(
    transformationTypes.SET_UPDATED_TRANSFORMATIONS,
    props<{
        serviceId: string;
        organizationId: string;
        relativeOrganizations: any;
        inbound: { [property: string]: { [property: string]: Transformation } };
    }>()
    /* orgId.services.serviceid.transformations.inbound */
);

export const SetChainDisplayNames = createAction(
    transformationTypes.SET_CHAIN_DISPLAY_NAMES,
    props<{
        serviceId: string;
        organizationId: string;
        chainVsDisplaynames: { [property: string]: string };
    }>()
);

export const GetAllTransformations = createAction(
    transformationTypes.GET_ALL_TRANSFORMATIONS,
    props<{
        noAlerts?: boolean;
        serviceId: string;
        forceFetch: boolean;
    }>()
);

export const SetTransformations = createAction(
    transformationTypes.SET_TRANSFORMATIONS,
    props<{
        serviceId: string;
        organizationId: string;
        transformations: {
            INBOUND: Transformation[];
            OUTBOUND: Transformation[];
        };
    }>()
);

export const GetActiveTransformationsByType = createAction(
    transformationTypes.GET_ACTIVE_TRANSFORMATIONS_BY_TYPE,
    props<{
        transformationType: 'INBOUND' | 'OUTBOUND';
        serviceId: string;
        noAlerts: boolean;
        forceFetch: boolean;
    }>()
);

export const SetActiveTransformationsByType = createAction(
    transformationTypes.SET_ACTIVE_TRANSFORMATIONS_BY_TYPE,
    props<{
        serviceId: string;
        organizationId: string;
        transformationNames: string[];
        transformationType: 'INBOUND' | 'OUTBOUND';
    }>()
);

export const UpdateActiveTransformationsByType = createAction(
    transformationTypes.UPDATE_ACTIVE_TRANSFORMATIONS_BY_TYPE,
    props<{
        serviceId: string;
        activeTrNames: string[];
        transformationType: 'INBOUND' | 'OUTBOUND';
        message?: string;
        noAlerts?: boolean;
    }>()
);

export const SetTransformationsLoading = createAction(
    transformationTypes.SET_TRANSFORMATION_LOADING,
    props<{
        organizationId: string;
        serviceId: string;
        loading: boolean;
        category: 'inbound' | 'outbound' | 'chainNames' | 'transformationsDetail';
    }>()
);

export const GetMasterSchedulerTransformations = createAction(
    transformationTypes.GET_MASTER_SCHEDULER_TRANSFORMATIONS,
    props<{
        assetId: string;
        assetName: string;
        serviceId: string;
        noAlerts?: boolean;
    }>()
);

export const SetMasterSchedulerTransformations = createAction(
    transformationTypes.SET_MASTER_SCHEDULER_TRANSFORMATIONS,
    props<{
        serviceId: string;
        organizationId: string;
        transformations: {
            INBOUND: Chain[];
            OUTBOUND: Chain[];
        };
    }>()
);

export const getIntegrationsChains = createAction(
    transformationTypes.GET_INTEGRATIONS_CHAINS,
    props<{
        serviceId: string;
        assetId: string;
        assetName: string;
    }>()
);

export const GetTransformationNamesInChain = createAction(
    transformationTypes.GET_TRANSFORMATION_NAMES_IN_CHAIN,
    props<{
        repositoryId: string;
        serviceId: string;
        chainName: string;
        assetName: string;
        assetId: string;
    }>()
);

export const SetTransformationNamesInChain = createAction(
    transformationTypes.SET_TRANSFORMATION_NAMES_IN_CHAIN,
    props<{
        organizationId: string;
        serviceId: string;
        chainName: string;
        transformations: string[];
    }>()
);

export const SetIntegrationsChains = createAction(
    transformationTypes.SET_INTEGRATIONS_CHAINS,
    props<{
        serviceId: string;
        organizationId: string;
        chains: {
            INBOUND: Chain[];
            OUTBOUND: Chain[];
        };
    }>()
);

export const GetOutboundChains = createAction(
    transformationTypes.GET_OUTBOUND_CHAINS,
    props<{
        serviceId: string;
    }>()
);

export const SetOutboundChains = createAction(
    transformationTypes.SET_OUTBOUND_CHAINS,
    props<{
        serviceId: string;
        organizationId: string;
        chains: TransformationsState['organizationsMap']['111']['services']['api']['transformations']['chains'];
    }>()
);

export const GetChainsAndTransformation = createAction(
    transformationTypes.GET_TRANSFORMATION_AND_CHAINS,
    props<{
        serviceId: string;
    }>()
);

export const SetTransformationAndChains = createAction(
    transformationTypes.SET_TRANSFORMATION_AND_CHAINS,
    props<{
        serviceId: string;
        organizationId?: string;
        chains: {
            inbound: Chain[];
            outbound: Chain[];
        };
    }>()
);
