import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@env';
import { translate } from '@ngneat/transloco';
import { BehaviorSubject } from 'rxjs';

import { TableData } from '../../interface/table-data.interface';
import { TableRecordAction } from '../../interface/table-record-action.interface';
import { AssetService } from '../../models/assetservice.class';
import { RecordPayload } from '../../models/payload/recordpayload.class';
import { Record } from '../../models/record/record.class';
import { RecordField } from '../../models/record/recordfield.class';
import { ConfirmationDialogService } from '../../services/confirmation/confirmation-dialog.service';
import { FileUpload } from '../../services/file-upload/fileuploadservice';
import { RecordsService } from '../../services/records/records.service';
import { UtilsService } from '../../services/utils/utils.service';

@Component({
    selector: 'app-entity-record-field-attachment',
    templateUrl: './entity-record-field-attachment.component.html',
    styleUrls: ['./entity-record-field-attachment.component.scss'],
})
export class EntityRecordFieldAttachmentComponent implements OnInit {
    @Input() public fieldId: string;
    @Input() public record: Record;
    @Input() public parentId: string;
    @Input() public service: AssetService;
    @Input() public instanceId: string;
    @Input() public eventType: string;

    files: FileList;
    fileName: File['name'];
    clearFiles = new BehaviorSubject(0);
    isFileTypeSupported: boolean = true;
    isMaxFileSizeAllowed: boolean = true;
    maxFileCountReached: boolean = false;
    fieldAttachmentData = {
        filename: '',
        url: '',
        title: translate('Browse'),
        return: 'true',
    };
    uploadedFiles: any[];
    filesListUpdated: BehaviorSubject<any>;
    recordUpdateDisabled: boolean;
    inReadOnlyMode: boolean;
    uploading: boolean;
    tableData: BehaviorSubject<TableData[]> = new BehaviorSubject([]);
    public field: RecordField;
    public parentRecordId: string;
    public allColumns = ['FileName', 'URL'];
    public recordActions: TableRecordAction[] = [];
    public actions: TableData;

    constructor(
        protected _utils: UtilsService,
        protected _fileUploadService: FileUpload,
        private _records: RecordsService,
        private confirmationService: ConfirmationDialogService
    ) {}

    public onNewValue = (data) => {
        this.files = data;
        if (this.files && this.field && this.field.attachment) {
            if (this.files[0].size > this.field.attachment.maxSize * 1024 * 1024) {
                this.isMaxFileSizeAllowed = false;
            } else {
                this.isMaxFileSizeAllowed = true;
            }
        }
    };

    public onFileTypeSupportCheck = (data: boolean) => {
        this.isFileTypeSupported = data;
    };

    public startUpload = () => {
        this.uploading = true;
        const maxUpload = this.field?.attachment?.maxFilesCount;
        const uploadedCount = this.uploadedFiles?.length;
        if (maxUpload !== undefined && maxUpload <= uploadedCount) {
            return this._utils.alertError(translate('Maximum file upload count reached'));
        } else if (!this.files || this.files.length === 0) {
            return this._utils.alertError(translate('Select a file to upload'));
        }
        const parentRecordId = this.parentRecordId || this.record.parentId || '';
        const recordId = this.record.id ? this.record.id : '';
        this.uploadFiles(recordId, parentRecordId).then(
            (response: { records: { fileUris: string[] }; recordId: string; response: boolean }) => {
                this._utils.hideLoading();
                if (!this.record.id) {
                    this._utils.alertSuccess(translate('Record created. File has been uploaded'));
                } else {
                    this._utils.alertSuccess(translate('File has been uploaded'));
                }
                this.pushValuesToRecordField(response?.records);
                this.files = undefined;
                this.fileName = '';
                this.uploading = false;
            }
        );
    };

    private uploadFiles = (recordId: string, parentRecordId: string) => {
        return new Promise((resolve, reject) => {
            const url = `${environment.taxilla_api}/asset/attachments?instanceId=${this.instanceId}&entityRecordId=${encodeURIComponent(
                recordId
            )}&parentEntityRecordId=${encodeURIComponent(parentRecordId)}`;
            const service = this.service;
            this.record.id = recordId;
            this.record.assetDataId = this.instanceId;
            const recordpayload = new RecordPayload(this.record, true);
            recordpayload.fields = recordpayload.fields.filter((field) => field.id === this.field.id);
            const fieldToPush = recordpayload.fields.find((field) => field.id === this.field.id);
            if (!fieldToPush) {
                recordpayload.fields.push({ id: this.field.id, value: this.field.value });
            } else {
                fieldToPush.value = fieldToPush.value || [];
            }
            this._fileUploadService.upload(
                this.files,
                {
                    filename: 'file',
                    entityRecordInfoVO: this._utils.convertJSONtoBlob(recordpayload),
                    attachmentDetail: this._utils.convertJSONtoBlob({
                        assetId: service.assetMetaUId,
                        entityId: this.field.entityUid,
                        fieldId: this.field.id,
                        fileName: this.fileName,
                    }),
                },
                url,
                undefined,
                (response) => {
                    resolve(response);
                },
                (response) => {
                    this._utils.hideLoading();
                    this._utils.alertError((response && response.msg) || translate('Failed to upload the file'));
                    reject(response);
                }
            );
        });
    };

    public pushValuesToRecordField = ({ fileUris }: { fileUris: string[] }) => {
        this.field.value = fileUris;
        this.field.updateFiles();
        this.generateUploadedFiles();
    };

    public transformData = () => {
        const tableData: TableData[] = [];
        this.uploadedFiles.forEach((file, index) => {
            tableData.push({
                recordId: index.toString(),
                fileName: file.name,
                url: file.fileUrl,
                originalUrl: file.originalUrl,
            });
        });
        this.tableData.next(tableData);
        if (this.tableData?.value?.length === this.field?.attachment?.maxFilesCount) {
            this.maxFileCountReached = true;
        }
    };

    public generateUploadedFiles = () => {
        this.recordActions.splice(0);
        !this.inReadOnlyMode &&
            !this.recordUpdateDisabled &&
            this.recordActions.push({
                displayName: translate('Delete'),
                iconName: 'delete',
                onActionTrigger: this.deleteFile,
            });
        this.uploadedFiles = [];
        (this.field?.value as string[])?.forEach((uploadedFileRecordUrl) => {
            const name = uploadedFileRecordUrl.substring(uploadedFileRecordUrl.lastIndexOf('/') + 1, uploadedFileRecordUrl.length);
            this.uploadedFiles.push({
                name,
                fileUrl: environment.taxilla_api + '/asset/attachments?fileUri=' + uploadedFileRecordUrl,
                originalUrl: uploadedFileRecordUrl,
            });
        });
        if (this.field && this.field.attachment && this.field.attachment.maxFilesCount) {
            if (this.uploadedFiles.length >= this.field.attachment.maxFilesCount) {
                this.maxFileCountReached = true;
            } else {
                this.maxFileCountReached = false;
            }
        }
        this.transformData();
    };

    public deleteFile = (file: TableData, index: number) => {
        const confirmConfig = {
            title: this.translateMsg('Confirmation'),
            message: this.translateMsg('Are you sure you want to delete this file?'),
            btnOkText: this.translateMsg('Ok'),
            btnCancelText: this.translateMsg('Cancel'),
        };

        this.confirmationService
            .confirm(confirmConfig.title, confirmConfig.message, confirmConfig.btnOkText, confirmConfig.btnCancelText)
            .subscribe((action) => {
                if (action) {
                    this._records.deleteFieldAttachment(
                        {
                            assetDataId: this.instanceId,
                            assetId: this.service.assetMetaUId || this.service.id,
                            entityId: this.field.entityUid,
                            fieldId: this.field.id,
                            fileUri: file.originalUrl as string,
                            parentRecordId: this.parentRecordId,
                            recordId: this.record.id,
                        },
                        {
                            successCallback: () => {
                                (this.field.value as string[]).splice(index, 1);
                                this.pushValuesToRecordField({
                                    fileUris: this.field.value as string[],
                                });
                            },
                            failureCallback: (response) => {
                                this._utils.alertError(response?.msg || this.translateMsg('Failed to delete attachment'));
                            },
                        }
                    );
                }
            });
    };

    translateMsg = (string: string): string => {
        return translate(`${string}`);
    };

    ngOnInit(): void {
        this.field = this.record.fields.find((fieldObject) => fieldObject.id === this.fieldId);
        this.generateUploadedFiles();
    }
}
