import { createReducer, on } from '@ngrx/store';

import { SessionLogoutSuccessfull } from '../actions';
import * as actions from '../actions/permissions.actions';
import { permissionsState } from '../states';

export const PERMISSIONS_REDUCER = createReducer(
    permissionsState,
    on(actions.SetPermissions, (state, action) => {
        return {
            ...state,
            organizationsMap: {
                ...state.organizationsMap,
                [action.currentOrganizationId]: {
                    ...state.organizationsMap?.[action.currentOrganizationId],
                    sessionUserPermissions: action.permissions,
                },
            },
        };
    }),
    on(actions.SetBundlePermissions, (state, action) => {
        return {
            ...state,
            organizationsMap: {
                ...state.organizationsMap,
                [action.organizationId]: {
                    ...state.organizationsMap?.[action.organizationId],
                    bundlesMap: {
                        ...state.organizationsMap?.[action.organizationId]?.bundlesMap,
                        [action.bundleId]: {
                            ...state.organizationsMap?.[action.organizationId]?.bundlesMap?.[action.bundleId],
                            bundlePermissions: action.permissions,
                        },
                    },
                },
            },
        };
    }),
    on(actions.SetBundleRolePermissions, (state, action) => {
        return {
            ...state,
            organizationsMap: {
                ...state.organizationsMap,
                [action.organizationId]: {
                    ...state.organizationsMap?.[action.organizationId],
                    bundlesMap: {
                        ...state.organizationsMap?.[action.organizationId]?.bundlesMap,
                        [action.bundleId]: {
                            ...state.organizationsMap?.[action.organizationId]?.bundlesMap?.[action.bundleId],
                            bundleRolePermissions: {
                                ...state.organizationsMap?.[action.organizationId]?.bundlesMap?.[action.bundleId]?.bundleRolePermissions,
                                [action.roleId]: action.rolePermissions,
                            },
                        },
                    },
                },
            },
        };
    }),
    on(actions.SetRolePermissions, (state, action) => {
        return {
            ...state,
            organizationsMap: {
                ...state.organizationsMap,
                [action.organizationId]: {
                    ...state.organizationsMap?.[action.organizationId],
                    rolePermissions: {
                        ...state.organizationsMap?.[action.organizationId]?.rolePermissions,
                        [action.roleId]: action.rolePermissions,
                    },
                },
            },
        };
    }),

    on(actions.SetAllRolePermissions, (state, action) => {
        return {
            ...state,
            organizationsMap: {
                ...state.organizationsMap,
                [action.organizationId]: {
                    ...state.organizationsMap?.[action.organizationId],
                    allRolePermissions: action.permissions,
                },
            },
        };
    }),
    on(SessionLogoutSuccessfull, () => permissionsState)
);

export const PERMISSIONS_REDUCER_KEY = 'permissions';
