import { createReducer, on } from '@ngrx/store';

import * as actions from '../actions/integrations.actions';
import * as sessionActions from '../actions/session.actions';
import { IntegrationOrganizationsState } from '../interface/integration-state.interface';
import { integrationsState } from '../states';

export const INTEGRATIONS_REDUCER = createReducer(
    integrationsState,
    on(sessionActions.SetCurrentOrganization, (state, action) => {
        return {
            ...state,
            currentOrganizationId: action.organization?.id,
        };
    }),
    on(actions.SetIntegrationOrganizationEntity, (state, action) => {
        return {
            ...state,
            organizations: action.organizationsEntityAdapter.addOne(action.organizationEntity, state.organizations),
        };
    }),
    on(actions.SetActiveIntegrationTab, (state, { tab }) => {
        return {
            ...state,
            activeTab: tab,
        };
    }),
    on(actions.SetIntegrationConfigEntity, (state, { configsEntity, configsEntityAdapter, organizationAdapter }) => {
        const organizations = state.organizations;
        const organization: IntegrationOrganizationsState = organizations.entities[state.currentOrganizationId];
        const configs = organization.configs;
        return {
            ...state,
            organizations: organizationAdapter.updateOne(
                {
                    id: state.currentOrganizationId,
                    changes: {
                        configs: configsEntityAdapter.addOne(configsEntity, configs),
                    },
                },
                organizations
            ),
        };
    }),
    on(actions.SetIntegrationRecords, (state, { organizationAdapter, configsAdapter, integrationsAdapter, records }) => {
        const organizations = state.organizations;
        const configs = organizations.entities[state.currentOrganizationId].configs;
        const integrations = configs.entities[state.activeTab].integrations;
        return {
            ...state,
            organizations: organizationAdapter.updateOne(
                {
                    id: state.currentOrganizationId,
                    changes: {
                        configs: configsAdapter.updateOne(
                            {
                                id: state.activeTab,
                                changes: {
                                    integrations: integrationsAdapter.addMany(records, integrations),
                                },
                            },
                            configs
                        ),
                    },
                },
                organizations
            ),
        };
    }),
    on(actions.PushIntegrationConfiguration, (state, { organizationAdapter, configsAdapter, integrationsAdapter, record }) => {
        const organizations = state.organizations;
        const configs = organizations.entities[state.currentOrganizationId].configs;
        const integrations = configs.entities[state.activeTab].integrations;
        return {
            ...state,
            organizations: organizationAdapter.updateOne(
                {
                    id: state.currentOrganizationId,
                    changes: {
                        configs: configsAdapter.updateOne(
                            {
                                id: state.activeTab,
                                changes: {
                                    integrations: integrationsAdapter.addOne(record as any, integrations),
                                },
                            },
                            configs
                        ),
                    },
                },
                organizations
            ),
        };
    }),
    on(actions.UpdateIntegrationConfigurationChange, (state, { organizationAdapter, configsAdapter, integrationsAdapter, record }) => {
        const organizations = state.organizations;
        const configs = organizations.entities[state.currentOrganizationId].configs;
        const integrations = configs.entities[state.activeTab].integrations;
        return {
            ...state,
            organizations: organizationAdapter.updateOne(
                {
                    id: state.currentOrganizationId,
                    changes: {
                        configs: configsAdapter.updateOne(
                            {
                                id: state.activeTab,
                                changes: {
                                    integrations: integrationsAdapter.updateOne(
                                        {
                                            id: record.id,
                                            changes: {
                                                ...record,
                                            },
                                        },
                                        integrations
                                    ),
                                },
                            },
                            configs
                        ),
                    },
                },
                organizations
            ),
        };
    }),
    on(actions.RemoveIntegrationConfiguration, (state, { organizationAdapter, configsAdapter, integrationsAdapter, record }) => {
        const organizations = state.organizations;
        const configs = organizations.entities[state.currentOrganizationId].configs;
        const integrations = configs.entities[state.activeTab].integrations;
        return {
            ...state,
            organizations: organizationAdapter.updateOne(
                {
                    id: state.currentOrganizationId,
                    changes: {
                        configs: configsAdapter.updateOne(
                            {
                                id: state.activeTab,
                                changes: {
                                    integrations: integrationsAdapter.removeOne(record.id, integrations),
                                },
                            },
                            configs
                        ),
                    },
                },
                organizations
            ),
        };
    }),
    on(actions.SetAppIntegrationConfigs, (state, { appIntegrationConfigs: supportedIntegrationConfigs }) => ({
        ...state,
        appIntegrationConfigs: {
            ...state.appIntegrationConfigs,
            inbound: supportedIntegrationConfigs.inbound,
            outbound: supportedIntegrationConfigs.outbound,
            masterinbound: supportedIntegrationConfigs.masterinbound,
        },
    })),
    on(actions.SetIntegrationsSupportedTypes, (state, action) => ({
        ...state,
        integrationsSupprtedTypes: {
            ...state.integrationsSupprtedTypes,
            authenticationTypes: action.supportedAuthenticationTypes,
            grantTypes: action.supportedGrantTypes,
        },
    })),
    on(
        actions.SetAppIntegration,
        (state, { organizationAdapter, appIntegrationAdapter, integrationsAdapter, serviceId, appIntegrationDetails }) => {
            const organizations = state.organizations;
            const appIntegrations =
                organizations.entities[state.currentOrganizationId].appIntegrations || appIntegrationAdapter.getInitialState();
            const appIntegrationsExists =
                organizations.entities[state.currentOrganizationId]?.appIntegrations?.entities?.[serviceId] !== undefined;
            const integrationDetails = appIntegrations.entities[serviceId]?.integrationDetails || integrationsAdapter.getInitialState();
            return {
                ...state,
                organizations: organizationAdapter.updateOne(
                    {
                        id: state.currentOrganizationId,
                        changes: {
                            appIntegrations: appIntegrationAdapter?.[appIntegrationsExists ? 'updateOne' : 'addOne']?.(
                                (appIntegrationsExists
                                    ? {
                                          id: serviceId,
                                          changes: {
                                              integrationDetails: integrationsAdapter?.addMany(appIntegrationDetails, integrationDetails),
                                          },
                                      }
                                    : {
                                          id: serviceId,
                                          integrationDetails: integrationsAdapter?.addMany(appIntegrationDetails, integrationDetails),
                                      }) as any,
                                appIntegrations
                            ),
                        },
                    },
                    organizations
                ),
            };
        }
    ),
    on(
        actions.DeleteAppIntegrationDetailsSuccess,
        (state, { organizationAdapter, appIntegrationAdapter, integrationsAdapter, configName, serviceId }) => {
            const organizations = state.organizations;
            const appIntegrations =
                organizations.entities[state.currentOrganizationId].appIntegrations || appIntegrationAdapter.getInitialState();
            const integrationDetails = appIntegrations.entities[serviceId]?.integrationDetails || integrationsAdapter.getInitialState();
            return {
                ...state,
                organizations: organizationAdapter.updateOne(
                    {
                        id: state.currentOrganizationId,
                        changes: {
                            appIntegrations: appIntegrationAdapter?.updateOne(
                                {
                                    id: serviceId,
                                    changes: {
                                        integrationDetails: integrationsAdapter?.removeOne(configName, integrationDetails),
                                    },
                                },
                                appIntegrations
                            ),
                        },
                    },
                    organizations
                ),
            };
        }
    ),
    on(sessionActions.SessionLogoutSuccessfull, () => integrationsState)
);

export const INTEGRATIONS_REDUCER_KEY = 'integrations';
