import { createReducer, on } from '@ngrx/store';

import {
    ClearRecords,
    ClearRecordsPagination,
    ClearSearchCriteria,
    DeletedRecords,
    SessionLogoutSuccessfull,
    SetRecords,
    SetRecordsLoading,
    SetRecordsPagination,
    SetRecordsSearchCriteria,
    SetSearchRecords,
} from '../actions';
import { recordsState } from '../states';

export const RECORDS_REDUCER = createReducer(
    recordsState,
    on(SetRecords, (state, action) => {
        const recordIds: string[] = [];
        action.records.forEach((element) => {
            recordIds.push(element.id);
        });
        const entityId = `${action.parentRecordId || ''}|${action.entityUid}`;
        const uniqIds = [
            ...new Set([
                ...(state.recordIds?.[action.organizationId]?.[action.serviceId]?.[action.instanceId]?.[entityId]?.recordIds || []),
                ...recordIds,
            ]),
        ];
        return {
            ...state,
            recordIds: {
                ...state.recordIds,
                [action.organizationId]: {
                    ...state.recordIds?.[action.organizationId],
                    [action.serviceId]: {
                        ...state.recordIds?.[action.organizationId]?.[action.serviceId],
                        [action.instanceId]: {
                            ...state.recordIds?.[action.organizationId]?.[action.serviceId]?.[action.instanceId],
                            [entityId]: {
                                ...state.recordIds?.[action.organizationId]?.[action.serviceId]?.[action.instanceId]?.[entityId],
                                recordIds: uniqIds,
                            },
                        },
                    },
                },
            },
            records: {
                ...state.records,
                ...action.records.reduce((recordsMap, record) => {
                    recordsMap[record.id] = record;
                    return recordsMap;
                }, {}),
            },
        };
    }),
    on(SetSearchRecords, (state, action) => {
        const recordIds: string[] = [];
        action.records.forEach((element) => {
            recordIds.push(element.id);
        });
        const entityId = `${action.parentRecordId || ''}|${action.entityUid}`;
        const recordsMap = state.recordIds?.[action.organizationId]?.[action.serviceId]?.[action.instanceId]?.[entityId];
        let storedRecordIds = action.reconSpecific
            ? recordsMap?.reconSpecific?.[action.reconSpecific]?.recordIds
            : recordsMap?.searchRecordIds;
        const uniqIds = [...new Set([...(storedRecordIds || []), ...recordIds])];
        return {
            ...state,
            recordIds: {
                ...state.recordIds,
                [action.organizationId]: {
                    ...state.recordIds?.[action.organizationId],
                    [action.serviceId]: {
                        ...state.recordIds?.[action.organizationId]?.[action.serviceId],
                        [action.instanceId]: {
                            ...state.recordIds?.[action.organizationId]?.[action.serviceId]?.[action.instanceId],
                            [entityId]: {
                                ...state.recordIds?.[action.organizationId]?.[action.serviceId]?.[action.instanceId]?.[entityId],
                                searchRecordIds: action.reconSpecific ? recordsMap?.searchRecordIds : uniqIds,
                                reconSpecific: action.reconSpecific
                                    ? {
                                          ...recordsMap?.reconSpecific,
                                          [action.reconSpecific]: {
                                              ...recordsMap?.reconSpecific?.[action.reconSpecific],
                                              recordIds: uniqIds,
                                          },
                                      }
                                    : recordsMap?.reconSpecific,
                            },
                        },
                    },
                },
            },
            records: {
                ...state.records,
                ...action.records.reduce((recordsMap, record) => {
                    recordsMap[record.id] = record;
                    return recordsMap;
                }, {}),
            },
        };
    }),
    on(SetRecordsSearchCriteria, (state, action) => {
        const entityId = `${action.parentRecordId || ''}|${action.entityUid}`;
        const entityMap = state.recordIds?.[action.organizationId]?.[action.serviceId]?.[action.instanceId]?.[entityId];
        return {
            ...state,
            recordIds: {
                ...state.recordIds,
                [action.organizationId]: {
                    ...state.recordIds?.[action.organizationId],
                    [action.serviceId]: {
                        ...state.recordIds?.[action.organizationId]?.[action.serviceId],
                        [action.instanceId]: {
                            ...state.recordIds?.[action.organizationId]?.[action.serviceId]?.[action.instanceId],
                            [entityId]: {
                                ...entityMap,
                                searchCriteria: action.reconSource ? entityMap?.searchCriteria : action.criteria,
                                reconSpecific: action.reconSource
                                    ? {
                                          ...entityMap?.reconSpecific,
                                          [action.reconSource]: {
                                              criteria: action.criteria,
                                              pageSize: 20,
                                              currentPageIndex: 0,
                                              recordIds: [],
                                              pagingState: undefined,
                                          },
                                      }
                                    : entityMap?.reconSpecific,
                            },
                        },
                    },
                },
            },
        };
    }),
    on(ClearSearchCriteria, (state, action) => {
        const entityId = `${action.parentRecordId || ''}|${action.entityUid}`;
        const entityMap = state.recordIds?.[action.organizationId]?.[action.serviceId]?.[action.instanceId]?.[entityId];
        return {
            ...state,
            recordIds: {
                ...state.recordIds,
                [action.organizationId]: {
                    ...state.recordIds?.[action.organizationId],
                    [action.serviceId]: {
                        ...state.recordIds?.[action.organizationId]?.[action.serviceId],
                        [action.instanceId]: {
                            ...state.recordIds?.[action.organizationId]?.[action.serviceId]?.[action.instanceId],
                            [entityId]: {
                                ...entityMap,
                                searchCriteria: undefined,
                                searchRecordIds: [],
                            },
                        },
                    },
                },
            },
        };
    }),
    on(DeletedRecords, (state, action) => {
        const entityId = `${action.parentRecordId || ''}|${action.entityUid}`;
        const recordsMap = state.recordIds?.[action.organizationId]?.[action.serviceId]?.[action.instanceId]?.[entityId];
        let filteredRecordIds: string[];
        let source1RecordIds: string[];
        let source2RecordIds: string[];
        let groupedRecordIds: string[];
        if (action.reconSpecific) {
            const storedS1RecordIds = recordsMap?.reconSpecific?.source1?.recordIds;
            source1RecordIds = storedS1RecordIds?.filter((id) => !action.recordIds.includes(id));
            const storedS2RecordIds = recordsMap?.reconSpecific?.source2?.recordIds;
            source2RecordIds = storedS2RecordIds?.filter((id) => !action.recordIds.includes(id));
            const storedGroupedRecordIds = recordsMap?.reconSpecific?.grouped?.recordIds;
            groupedRecordIds = storedGroupedRecordIds?.filter((id) => !action.recordIds.includes(id));
        } else {
            const storedRecordIds = recordsMap?.recordIds;
            filteredRecordIds = storedRecordIds?.filter((id) => !action.recordIds.includes(id));
        }
        return {
            ...state,
            recordIds: {
                ...state.recordIds,
                [action.organizationId]: {
                    ...state.recordIds?.[action.organizationId],
                    [action.serviceId]: {
                        ...state.recordIds?.[action.organizationId]?.[action.serviceId],
                        [action.instanceId]: {
                            ...state.recordIds?.[action.organizationId]?.[action.serviceId]?.[action.instanceId],
                            [entityId]: {
                                ...recordsMap,
                                recordIds: action.reconSpecific ? recordsMap?.recordIds : filteredRecordIds,
                                reconSpecific: !action.reconSpecific
                                    ? recordsMap.reconSpecific
                                    : {
                                          source1: {
                                              ...recordsMap.reconSpecific.source1,
                                              recordIds: source1RecordIds,
                                          },
                                          source2: {
                                              ...recordsMap.reconSpecific.source2,
                                              recordIds: source2RecordIds,
                                          },
                                          grouped: {
                                              ...recordsMap?.reconSpecific?.grouped,
                                              recordIds: groupedRecordIds,
                                          },
                                      },
                            },
                        },
                    },
                },
            },
        };
    }),
    on(SetRecordsLoading, (state, action) => {
        const entityId = `${action.parentRecordId || ''}|${action.entityUid}`;
        return {
            ...state,
            recordIds: {
                ...state.recordIds,
                [action.organizationId]: {
                    ...state.recordIds?.[action.organizationId],
                    [action.serviceId]: {
                        ...state.recordIds?.[action.organizationId]?.[action.serviceId],
                        [action.instanceId]: {
                            ...state.recordIds?.[action.organizationId]?.[action.serviceId]?.[action.instanceId],
                            [entityId]: {
                                ...state.recordIds?.[action.organizationId]?.[action.serviceId]?.[action.instanceId]?.[entityId],
                                loading: action.loading,
                            },
                        },
                    },
                },
            },
        };
    }),
    on(SetRecordsPagination, (state, action) => {
        if (!action.organizationId) {
            return state;
        }
        const entityId = `${action.parentRecordId || ''}|${action.entityUid}`;
        const stored = state.recordIds?.[action.organizationId]?.[action.serviceId]?.[action.instanceId]?.[entityId];
        const reconData = stored?.reconSpecific?.[action.reconSpecific];
        return {
            ...state,
            recordIds: {
                ...state.recordIds,
                [action.organizationId]: {
                    ...state.recordIds?.[action.organizationId],
                    [action.serviceId]: {
                        ...state.recordIds?.[action.organizationId]?.[action.serviceId],
                        [action.instanceId]: {
                            ...state.recordIds?.[action.organizationId]?.[action.serviceId]?.[action.instanceId],
                            [entityId]: {
                                ...stored,
                                pageSize: action.pageSize !== undefined && !action.reconSpecific ? action.pageSize : stored.pageSize,
                                currentPageIndex:
                                    action.pageIndex !== undefined && !action.reconSpecific ? action.pageIndex : stored.currentPageIndex,
                                recordIds:
                                    action.pageSize === undefined || action.pageSize === stored.pageSize || action.reconSpecific
                                        ? stored.recordIds
                                        : [],
                                pagingState:
                                    action.cursor !== undefined && !action.reconSpecific
                                        ? action.cursor
                                        : action.pageSize === undefined || action.pageSize === stored.pageSize
                                        ? stored?.pagingState
                                        : undefined,
                                reconSpecific: action.reconSpecific
                                    ? {
                                          ...stored.reconSpecific,
                                          [action.reconSpecific]: {
                                              ...reconData,
                                              pagingState:
                                                  action.cursor !== undefined
                                                      ? action.cursor
                                                      : action.pageSize === undefined || action.pageSize === reconData.pageSize
                                                      ? reconData?.pagingState
                                                      : undefined,
                                              pageSize: action.pageSize !== undefined ? action.pageSize : reconData.pageSize,
                                              recordIds:
                                                  action.pageSize === undefined || action.pageSize === reconData.pageSize
                                                      ? reconData.recordIds
                                                      : [],
                                              currentPageIndex:
                                                  action.pageIndex !== undefined ? action.pageIndex : reconData.currentPageIndex,
                                          },
                                      }
                                    : stored.reconSpecific,
                            },
                        },
                    },
                },
            },
        };
    }),
    on(ClearRecordsPagination, (state, action) => {
        if (!action.organizationId) {
            return state;
        }
        const entityId = `${action.parentRecordId || ''}|${action.entityUid}`;
        const stored = state.recordIds?.[action.organizationId]?.[action.serviceId]?.[action.instanceId]?.[entityId];
        return {
            ...state,
            recordIds: {
                ...state.recordIds,
                [action.organizationId]: {
                    ...state.recordIds?.[action.organizationId],
                    [action.serviceId]: {
                        ...state.recordIds?.[action.organizationId]?.[action.serviceId],
                        [action.instanceId]: {
                            ...state.recordIds?.[action.organizationId]?.[action.serviceId]?.[action.instanceId],
                            [entityId]: {
                                ...stored,
                                pageSize: stored?.pageSize || 20,
                                currentPageIndex: 0,
                                pagingState: undefined,
                                searchCriteria: undefined,
                                searchRecordIds: [],
                                recordIds: [],
                                reconSpecific: {
                                    source1:
                                        !action?.reconSpecific || action?.reconSpecific === 'source1'
                                            ? {
                                                  criteria: undefined,
                                                  currentPageIndex: 0,
                                                  pageSize: 20,
                                                  pagingState: undefined,
                                                  recordIds: [],
                                              }
                                            : stored?.reconSpecific?.source1,
                                    source2:
                                        !action?.reconSpecific || action?.reconSpecific === 'source2'
                                            ? {
                                                  criteria: undefined,
                                                  currentPageIndex: 0,
                                                  pageSize: 20,
                                                  pagingState: undefined,
                                                  recordIds: [],
                                              }
                                            : stored?.reconSpecific?.source2,
                                    grouped:
                                        !action?.reconSpecific || action?.reconSpecific === 'grouped'
                                            ? {
                                                  criteria: undefined,
                                                  currentPageIndex: 0,
                                                  pageSize: 20,
                                                  pagingState: undefined,
                                                  recordIds: [],
                                              }
                                            : stored?.reconSpecific?.grouped,
                                },
                            },
                        },
                    },
                },
            },
        };
    }),
    on(ClearRecords, (state, action) => {
        return {
            ...state,
            recordIds: {
                ...state.recordIds,
                [action.organizationId]: {
                    ...state.recordIds?.[action.organizationId],
                    [action.serviceId]: {
                        ...state.recordIds?.[action.organizationId]?.[action.serviceId],
                        [action.instanceId]: undefined,
                    },
                },
            },
        };
    }),
    on(SessionLogoutSuccessfull, () => recordsState)
);

export const RECORDS_REDUCER_KEY = 'records';
