import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, delay, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { ApiService } from '../../services/api/api.service';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import {
    AlertError,
    CheckForMaxTime,
    ExecutedWorkflowStage,
    ExecuteWorkflowStage,
    GetWorkflow,
    SetWorkflow,
    SetWorkflowLoading,
} from '../actions';
import { getAppsMetaDataMap$, getServicesInstancesMap$, getSubscribedAppsMap$, getWorkflowInstances$ } from '../selectors';

@Injectable()
export class WorkflowEffects {
    constructor(private store$: Store, private actions$: Actions, private _api: ApiService) {}

    getWorkflow = createEffect(() =>
        this.actions$.pipe(
            ofType(GetWorkflow),
            delay(10),
            withLatestFrom(this.store$.select(getWorkflowInstances$), this.store$.select(getSubscribedAppsMap$)),
            mergeMap(([action, instancesMap, services]) => {
                const app = services?.[action.serviceId];
                if (!app?.serviceId) {
                    return of(GetWorkflow(action));
                }
                if (instancesMap?.[action.instanceId]?.length > 0) {
                    return [];
                }
                this.store$.dispatch(
                    SetWorkflowLoading({
                        loading: true,
                        instanceIds: [action.instanceId],
                    })
                );
                return from(
                    this._api.workflow.getAllWorkflowStages({
                        instanceId: action.instanceId,
                        noAlerts: action.noAlerts,
                        restApiName: app.restApiName,
                        serviceId: action.serviceId,
                    })
                ).pipe(
                    map((res) => {
                        this.store$.dispatch(
                            SetWorkflowLoading({
                                loading: false,
                                instanceIds: [action.instanceId],
                            })
                        );
                        this.store$.dispatch(
                            SetWorkflow({
                                instanceId: action.instanceId,
                                workflows: res,
                            })
                        );
                        return CheckForMaxTime({
                            instanceId: action.instanceId,
                            notFirstIteration: action.notFirstIteration,
                            configurations: action.configurations,
                        });
                    }),
                    catchError((res) => {
                        this.store$.dispatch(
                            SetWorkflowLoading({
                                loading: false,
                                instanceIds: [action.instanceId],
                            })
                        );
                        this.store$.dispatch(
                            AlertError({
                                message: res?.msg || 'Failed to get instance workflow',
                            })
                        );
                        return [];
                    })
                );
            })
        )
    );

    executeWorkflow = createEffect(() =>
        this.actions$.pipe(
            ofType(ExecuteWorkflowStage),
            delay(10),
            withLatestFrom(
                this.store$.select(getSubscribedAppsMap$),
                this.store$.select(getAppsMetaDataMap$),
                this.store$.select(getServicesInstancesMap$),
                this.store$.select(getWorkflowInstances$)
            ),
            mergeMap(([action, apps, appsMetaData, _serviceVsInstancesMap, workflowInstances]) => {
                if (!action.instanceIds) {
                    return [];
                }
                const app = apps?.[action.serviceId];
                const stages = appsMetaData?.[action.serviceId]?.[app?.assetMetaUId]?.workflowMetadata?.stages;
                const selectedInstance = action.instanceIds?.find((instance) => workflowInstances?.[instance] !== undefined);
                const instanceWorkflow = workflowInstances?.[selectedInstance]?.find((stage) => stage.startTime && !stage.endTime);
                if (!app || !stages || !instanceWorkflow) {
                    return [];
                }
                this.store$.dispatch(
                    SetWorkflowLoading({
                        loading: true,
                        instanceIds: action.instanceIds,
                    })
                );
                return from(
                    this._api.workflow.executeWorkflow({
                        apiAction: action.apiAction,
                        assetId: app.assetMetaUId,
                        cancel: false,
                        comment: action.comment,
                        formFields: CommonUtilsService.cloneObject(action.formFields),
                        instanceId: action.instanceIds,
                        noAlerts: false,
                        noWorkflows: stages.length <= 1,
                        restApiName: app.restApiName,
                        serviceId: app.serviceId,
                        workflow: instanceWorkflow,
                        actionVariables: action.actionVariables,
                    })
                ).pipe(
                    map(() => {
                        this.store$.dispatch(
                            SetWorkflowLoading({
                                loading: false,
                                instanceIds: action.instanceIds,
                            })
                        );
                        return ExecutedWorkflowStage({
                            instanceIds: action.instanceIds,
                            executeTimestamp: action.executeTimestamp,
                        });
                    }),
                    catchError((res) => {
                        this.store$.dispatch(
                            SetWorkflowLoading({
                                loading: false,
                                instanceIds: action.instanceIds,
                            })
                        );
                        this.store$.dispatch(AlertError({ message: res?.msg || 'Failed to get instance workflow' }));
                        return [];
                    })
                );
            })
        )
    );
}
