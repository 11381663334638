import { createFeatureSelector, createSelector } from '@ngrx/store';

import { RECORDS_REDUCER_KEY } from '../reducers';
import { RecordsState } from '../states';
import { getCurrentOrganizationId$ } from './session.selector';

const state = createFeatureSelector<RecordsState>(RECORDS_REDUCER_KEY);

export const getRecords$ = createSelector(state, (state) => state.records);

export const getRecord$ = (recordId: string) => createSelector(getRecords$, (records) => records[recordId]);

export const getRecordsOrg$ = createSelector(state, getCurrentOrganizationId$, (state, orgId) => state.recordIds?.[orgId]);

const getRecordsServiceMap$ = (serviceId: string) => createSelector(getRecordsOrg$, (orgMap) => orgMap?.[serviceId]);

const getRecordsInstanceMap$ = (serviceId: string, instanceId: string) =>
    createSelector(getRecordsServiceMap$(serviceId), (serviceMap) => serviceMap?.[instanceId]);

const getRecordsEntityMap$ = (serviceId: string, instanceId: string, parentRecordId: string, entityId: string) =>
    createSelector(getRecordsInstanceMap$(serviceId, instanceId), (instancesMap) => instancesMap?.[`${parentRecordId || ''}|${entityId}`]);

export const getRecordsPagination$ = (serviceId: string, instanceId: string, parentRecordId: string, entityId: string) =>
    createSelector(getRecordsEntityMap$(serviceId, instanceId, parentRecordId, entityId), (entityMap) => {
        return {
            pageIndex: entityMap?.currentPageIndex || 0,
            pageSize: entityMap?.pageSize || 20,
            cursor: entityMap?.pagingState?.[(entityMap?.currentPageIndex || 0) - 1],
        };
    });

export const getRecordsIds$ = (serviceId: string, instanceId: string, parentRecordId: string, entityId: string) =>
    createSelector(
        getRecordsEntityMap$(serviceId, instanceId, parentRecordId, entityId),
        getRecordsPagination$(serviceId, instanceId, parentRecordId, entityId),
        (entityMap, pagination) => {
            const start = pagination.pageIndex * pagination.pageSize;
            const end = (pagination.pageIndex + 1) * pagination.pageSize;
            return entityMap?.recordIds?.filter((_id, index) => index >= start && index < end);
        }
    );
