import { Injectable } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, delay, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Organization } from '../../models/organization.class';
import { ApiService } from '../../services/api/api.service';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import * as actions from '../actions';
import { AlertError, AlertSuccess, SetFlexFields } from '../actions';
import {
    getAssociatedOrganizationsMap$,
    getCurrentLogo$,
    getCurrentOrganizationId$,
    getFlexFields$,
    getLocationsMap$,
    getLogo$,
    getOrganizationChildHierarchy$,
    getOrganizationLocations$,
} from '../selectors';

@Injectable()
export class LocationEffects {
    private loading = {
        organization: {},
        logo: {},
        currentLogo: {},
    };
    private fetchedAssociatedOrgs: boolean = false;

    constructor(private actions$: Actions, private store$: Store, private _api: ApiService) {}

    sessionLogoutSuccessfull = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.SessionLogoutSuccessfull),
            mergeMap(() => {
                this.fetchedAssociatedOrgs = false;
                return [];
            })
        );
    });

    getOrganizationDetails$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.GetLocation),
            withLatestFrom(
                this.store$.select(getLocationsMap$),
                this.store$.select(getCurrentOrganizationId$),
                this.store$.select(getAssociatedOrganizationsMap$)
            ),
            delay(100),
            mergeMap(([action, detailsMap, organizationId, organizationsMap]) => {
                if (!action.organizationId || detailsMap?.[action.organizationId]?.addresses?.length > 0) {
                    this.setAsCurrentOrganization(detailsMap?.[action.organizationId], action.setAsCurrentOrganization);
                    return [];
                }
                if (this.loading.organization[action.organizationId]) {
                    return [];
                }
                let organization = organizationsMap?.[action.organizationId];
                if (action.fromComponent === 'redirect' && organization) {
                    return [];
                }
                this.loading.organization[action.organizationId] = true;
                return this._api.locations.getOrganizationDetailsData(action.organizationId).pipe(
                    map((res) => {
                        this.loading.organization[action.organizationId] = false;
                        this.buildDisplayName(detailsMap, res);
                        this.setAsCurrentOrganization(res, action.setAsCurrentOrganization);
                        this.store$.dispatch(
                            actions.UpdateLocationInAssociationsTree({
                                organizationId,
                                organizations: [res],
                                parentId: organizationId,
                            })
                        );
                        return actions.UpdatedLocation({
                            organizationId,
                            organization: res,
                            updated: action.update,
                            parentId: organizationId,
                        });
                    }),
                    catchError((res) => {
                        if (action.fromComponent === 'redirect') {
                            this.store$.dispatch(actions.RedirectFailure());
                        }
                        this.loading.organization[action.organizationId] = false;
                        return of(AlertError({ message: res.msg || translate('Failed to get organization details') }));
                    })
                );
            })
        );
    });

    private setAsCurrentOrganization = (organization: Organization, setAsCurrentOrganization: boolean) => {
        if (setAsCurrentOrganization) {
            this.store$.dispatch(actions.SetCurrentOrganization({ organization }));
        }
    };

    getCurrentOrganizationDetails$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.GetCurrentOrganization),
            withLatestFrom(this.store$.select(getCurrentOrganizationId$)),
            mergeMap(([_action, organizationId]) => {
                return of(
                    actions.GetLocation({
                        organizationId: organizationId,
                        update: false,
                    })
                );
            })
        );
    });

    getLocationsCodes$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.GetLocationsCodes),
            withLatestFrom(this.store$.select(getCurrentOrganizationId$)),
            mergeMap(([_action, organizationId]) => {
                return this._api.locations.getLocationsCodesData().pipe(
                    map((res) => {
                        return actions.SetLocationsCodes({
                            organizationId,
                            locationCodes: res,
                        });
                    }),
                    catchError((res) => {
                        return of(AlertError({ message: res.msg || translate('Failed to get locations codes') }));
                    })
                );
            })
        );
    });

    addLocations = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.UpdateLocation),
            withLatestFrom(this.store$.select(getCurrentOrganizationId$), this.store$.select(getAssociatedOrganizationsMap$)),
            mergeMap(([action, organizationId, orgsMap]) => {
                const method = action?.payload?.id ? 'updateOrganizationDetails' : 'addLocation';
                const payload = action?.payload?.id ? { org: action.payload } : (action.payload as any);
                return this._api.locations?.[method]?.(payload).pipe(
                    map((res: Organization) => {
                        const nameChanged = action.payload.name !== orgsMap?.[action.payload.id]?.name;
                        const data = {
                            msgType: 'DETAILS',
                            msg: this.setMsg(res, action.payload),
                            isSuccess: true,
                        };
                        this.store$.dispatch(actions.SetOrgDetailsMessage(data));
                        if (method === 'addLocation') {
                            this.buildDisplayName(orgsMap, res);
                            this.store$.dispatch(
                                actions.SetLocationInAssociationsTree({
                                    organization: res,
                                    organizationId,
                                    parentId: res?.parentId,
                                })
                            );
                            return actions.SetLocation({
                                organizationId,
                                organization: res,
                                parentId: res?.parentId,
                            });
                        } else {
                            const orgsToUpdate: Organization[] = [];
                            if (nameChanged) {
                                const orgsMapClone = {
                                    ...orgsMap,
                                    [res?.id]: res,
                                };
                                [res, ...CommonUtilsService.cloneObject(this.getChildNodes(orgsMapClone, res?.id))].forEach((org) => {
                                    this.buildDisplayName(orgsMapClone, org);
                                    orgsToUpdate.push(org);
                                });
                            }
                            this.store$.dispatch(
                                actions.UpdateLocationInAssociationsTree({
                                    organizationId,
                                    organizations: orgsToUpdate,
                                    parentId: res?.parentId,
                                })
                            );
                            return actions.UpdatedLocation({
                                organizationId,
                                organization: res,
                                updated: action.update,
                                parentId: res?.parentId,
                            });
                        }
                    }),
                    catchError((errorResponse) => {
                        const data = {
                            msgType: 'DETAILS',
                            msg: errorResponse?.['msg'] || translate('Failed to update organization details'),
                            isSuccess: false,
                        };
                        return of(actions.SetOrgDetailsMessage(data));
                    })
                );
            })
        );
    });

    private setMsg = (res, payload) => {
        if (res?.msg) {
            return res?.msg;
        } else if (!payload.fromOrganization) {
            return payload.id ? translate('Location details updated successfully') : translate('Location details added successfully');
        } else {
            return payload.id
                ? translate('Organization details update successfully')
                : translate('Organization details added successfully');
        }
    };

    UpdateOrganizationLogo = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.UpdateLocationLogo),
            mergeMap((action) => {
                return from(this._api.locations.uploadOrganizationLogo(action.payload)).pipe(
                    map((res) => {
                        const data = {
                            msgType: 'LOGO',
                            msg: res?.['msg'] || 'Organization logo updated successfully',
                            isSuccess: true,
                        };
                        this.store$.dispatch(actions.SetOrgDetailsMessage(data));
                        this.store$.dispatch(actions.GetLocationLogo({ enableHierarchy: true, forFetch: true }));
                        return actions.GetLocationLogo({ enableHierarchy: false, forFetch: true });
                    }),
                    catchError((errorResponse) => {
                        const data = {
                            msgType: 'LOGO',
                            msg: errorResponse?.msg || 'Failed to upload logo',
                            isSuccess: false,
                        };
                        return of(actions.SetOrgDetailsMessage(data));
                    })
                );
            })
        );
    });

    DeleteOrganizationLogo = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.DeleteLocationLogo),
            mergeMap(() => {
                return from(this._api.locations.deleteOrganizationLogo()).pipe(
                    map((res) => {
                        const data = {
                            msgType: 'LOGO',
                            msg: res?.['msg'] || 'Organization logo deleted successfully',
                            isSuccess: true,
                        };
                        this.store$.dispatch(actions.SetOrgDetailsMessage(data));
                        this.store$.dispatch(actions.GetLocationLogo({ enableHierarchy: true, forFetch: true }));
                        return actions.GetLocationLogo({ enableHierarchy: false, forFetch: true });
                    }),
                    catchError((errorResponse) => {
                        const data = {
                            msgType: 'LOGO',
                            msg: errorResponse?.msg || 'Failed to delete logo',
                            isSuccess: false,
                        };
                        return of(actions.SetOrgDetailsMessage(data));
                    })
                );
            })
        );
    });

    GetOrganizationLogo = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.GetLocationLogo),
            delay(100),
            withLatestFrom(
                this.store$.select(getCurrentOrganizationId$),
                this.store$.select(getLogo$),
                this.store$.select(getCurrentLogo$)
            ),
            mergeMap(([action, organizationId, logo, currentLogo]) => {
                const loadingObject = action.enableHierarchy ? this.loading.logo : this.loading.currentLogo;
                if (
                    loadingObject[organizationId] ||
                    (!action.forFetch && (action.enableHierarchy ? logo !== undefined : currentLogo !== undefined))
                ) {
                    return [];
                }
                loadingObject[organizationId] = true;
                return from(this._api.locations.getOrganizationLogo(action.enableHierarchy)).pipe(
                    map((res) => {
                        loadingObject[organizationId] = false;
                        return actions.SetLocationLogo({
                            currentOrgId: organizationId,
                            logoData: (res?.length > 0 && atob(res)) || '',
                            locationHierarchy: action.enableHierarchy,
                        });
                    }),
                    catchError((errorResponse) => {
                        loadingObject[organizationId] = false;
                        if (errorResponse) {
                            return of(AlertError({ message: errorResponse }));
                        }
                        return of(
                            actions.SetLocationLogo({
                                currentOrgId: organizationId,
                                locationHierarchy: action.enableHierarchy,
                                logoData: undefined,
                            })
                        );
                    })
                );
            })
        );
    });

    locationActivated = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.ToggleLocation),
            withLatestFrom(this.store$.select(getOrganizationLocations$), this.store$.select(getCurrentOrganizationId$)),
            mergeMap(([action, organizations, organizationId]) => {
                const organization = organizations.find((org) => org.id === action.id);
                const method = action.enabled ? 'activateLocations' : 'deactivateLocations';
                return this._api.locations[method]({ orgId: action.id }).pipe(
                    map((res) => {
                        this.store$.dispatch(AlertSuccess({ message: res as string }));
                        this.store$.dispatch(
                            actions.UpdateLocationInAssociationsTree({
                                organizationId,
                                organizations: [
                                    {
                                        ...organization,
                                        enabled: action.enabled,
                                    },
                                ] as Organization[],
                                parentId: organization?.parentId,
                            })
                        );
                        return actions.UpdatedLocation({
                            organizationId,
                            organization: {
                                ...organization,
                                enabled: action.enabled,
                            } as Organization,
                            updated: true,
                            parentId: organization?.parentId,
                        });
                    }),
                    catchError((errorResponse) => of(AlertError({ message: errorResponse })))
                );
            })
        );
    });

    GetFlexFields = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.GetFlexFields),
            withLatestFrom(this.store$.select(getFlexFields$)),
            mergeMap(([_action, flexFields]) => {
                if (flexFields?.length > 0) {
                    return [];
                }
                return from(this._api.locations.getAllFlexFields()).pipe(
                    map((flexFields) => {
                        return SetFlexFields({ flexFields });
                    }),
                    catchError((errorResponse) => of(AlertError({ message: errorResponse || translate('Failed to GetFlexfields') })))
                );
            })
        );
    });

    GetAllOrganizations$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.GetOrganizationLocations),
            withLatestFrom(this.store$.select(getOrganizationLocations$), this.store$.select(getCurrentOrganizationId$)),
            mergeMap(([_action, organizationLocations, organizationId]) => {
                if (organizationLocations?.length > 1) {
                    return [];
                }
                return this._api.subscriptions
                    .searchAllOrganizations({
                        'parent.id': organizationId,
                    })
                    .pipe(
                        map((res) => {
                            return actions.SetOrganizationLocations({
                                organizations: res,
                                organizationId,
                            });
                        }),
                        catchError((res) => {
                            return of(AlertError({ message: res.msg || '' }));
                        })
                    );
            })
        );
    });

    GetAssociatedOrganizations$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.GetAssociatedLocations),
            mergeMap(() => {
                if (this.fetchedAssociatedOrgs) {
                    return [];
                }
                this.fetchedAssociatedOrgs = true;
                return this._api.locations.getOrganizationsPromise().pipe(
                    map((res) => {
                        const orgMap = this.buildOrganizationsMap(res);
                        this.buildNodes(orgMap);
                        this.buildDisplayNames(orgMap);
                        this.deleteParentRef(orgMap);
                        return actions.SetAssociatedLocations({
                            organizations: this.buildParentChildTree(orgMap),
                        });
                    }),
                    catchError((res) => {
                        this.fetchedAssociatedOrgs = false;
                        return of(AlertError({ message: res.msg || '' }));
                    })
                );
            })
        );
    });

    private deleteParentRef = (orgMap: { [property: string]: Organization }) => {
        Object.keys(orgMap).forEach((id) => {
            if (!orgMap[id]) {
                return;
            }
            const organization = orgMap[id];
            delete organization.parent;
        });
    };

    private buildOrganizationsMap = (list: Organization[]) => {
        const orgMap: { [property: string]: Organization } = {};
        list.forEach((organization) => {
            orgMap[organization.id] = orgMap[organization.id] || organization;
        });
        return orgMap;
    };

    private buildNodes = (orgMap: { [property: string]: Organization }) => {
        Object.keys(orgMap).forEach((id) => {
            const organization = orgMap[id];
            this.buildNode(orgMap, organization);
        });
    };

    private buildNode = (orgMap: { [property: string]: Organization }, organization: Organization) => {
        if (!organization.parentId) {
            return;
        }
        let parentOrganization = orgMap[organization.parentId] || this.buildNoAccessParentNodes(orgMap, organization);
        if (!parentOrganization) {
            return;
        }
        if (!parentOrganization.nextNodes) {
            parentOrganization.nextNodes = [];
        }
        parentOrganization.nextNodes.push(organization.id);
    };

    private buildNoAccessParentNodes = (orgMap: { [property: string]: Organization }, organization: Organization) => {
        if (!orgMap[organization.parentId] && organization.parent) {
            organization.parent.noAccess = true;
            orgMap[organization.parentId] = organization.parent;
            this.buildNode(orgMap, organization.parent);
            return orgMap[organization.parentId];
        }
    };

    private buildDisplayNames = (orgMap: { [property: string]: Organization }) => {
        Object.keys(orgMap).forEach((id) => this.buildDisplayName(orgMap, orgMap[id]));
    };

    private buildDisplayName = (orgMap: { [property: string]: Organization }, org: Organization) => {
        org.displayTenantName = this.getOrganizationName(org, orgMap);
    };

    private getOrganizationName = (organization?: Organization, orgMap?: { [property: string]: Organization }, delimiter?: string) => {
        if (!organization) {
            return '';
        }
        if (!organization.parentId) {
            return organization.name;
        }
        return (
            this.getOrganizationName(organization.parent || orgMap?.[organization.parentId], orgMap) +
            (delimiter ? '  ' + delimiter + '  ' : '  |  ') +
            organization.name
        );
    };

    private buildParentChildTree = (orgMap: { [property: string]: Organization }) => {
        const parentTree: Organization[] = [];
        Object.keys(orgMap).forEach((id) => {
            const parentId = orgMap[id].parentId;
            if (parentId) {
                if (!orgMap[parentId].childNodes) {
                    orgMap[parentId].childNodes = [];
                }
                orgMap[parentId].childNodes.push(orgMap[id]);
            } else {
                parentTree.push(orgMap[id]);
            }
        });
        return parentTree;
    };

    private getChildNodes = (orgMap: { [property: string]: Organization }, organizationId: string) => {
        const childNodes: Organization[] = [];
        Object.keys(orgMap)
            .filter((id) => orgMap?.[id].parentId === organizationId)
            .forEach((id) => {
                childNodes.push(orgMap?.[id], ...(this.getChildNodes(orgMap, id) || []));
            });
        return childNodes;
    };

    GetOrganizationLogoBySetCurrentOrg = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.SetCurrentOrganization),
            mergeMap((action) => {
                if (!action.organization?.id) {
                    return [];
                }
                return of(actions.GetLocationLogo({ enableHierarchy: true }));
            })
        );
    });

    GetOrganizationChildHierarchy$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.GetOrganizationChildHierarchy),
            withLatestFrom(this.store$.select(getCurrentOrganizationId$), this.store$.select(getOrganizationChildHierarchy$)),
            mergeMap(([_action, organizationId, childHierarchy]) => {
                if (childHierarchy?.orgNodes?.length > 0) {
                    return of(
                        actions.SetOrganizationChildHierarchy({
                            orgId: organizationId,
                            childHierarchy: CommonUtilsService.cloneObject(childHierarchy),
                        })
                    );
                }
                return this._api.organization.getOrganizationChildHierarchy(organizationId).pipe(
                    map((res) => {
                        return actions.SetOrganizationChildHierarchy({
                            orgId: organizationId,
                            childHierarchy: res,
                        });
                    }),
                    catchError((res) => {
                        return of(AlertError({ message: res?.msg || 'Failed due to server error' }));
                    })
                );
            })
        );
    });
}
