import { Component, DoCheck, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyFormFieldAppearance } from '@angular/material/legacy-form-field';
import { Subscription } from 'rxjs';

import { ModifiedErrorStateMatcher } from '../../../extensions/errorstatematcher/errorstatematcher';

@Component({
    selector: 'material-textarea',
    templateUrl: './textarea.component.html',
    styleUrls: ['./textarea.component.scss'],
})
export class MaterialTextAreaComponent implements OnInit, OnDestroy, OnChanges, DoCheck {
    @Input() placeholder;
    @Input() name;
    @Input() title;
    @Input() model;
    @Input() id;
    @Input() classList;
    @Input() debounceAfter: number;
    @Input() options: any[];
    @Input() autoComplete: boolean;
    @Input() required: boolean;
    @Input() errors: any[];
    @Input() warnings: any[];
    @Input() disabled: boolean;
    @Input() readonly: boolean;
    @Input() type: string;
    @Input() matTooltip: string;
    @Input() hint: string;
    @Input() maxLength: number;
    @Input() needIcon: boolean;
    @Input() description: string;
    @Input() rows: number;
    @Input() appearance: MatLegacyFormFieldAppearance;

    @Output() modelChange = new EventEmitter();
    @Output() debounceEventHandler = new EventEmitter();
    @Output() keyUpEventHandler = new EventEmitter();
    @Output() onDataClear = new EventEmitter();

    matcher = new ModifiedErrorStateMatcher();
    control = new UntypedFormControl('');
    valueSubscription: Subscription;

    constructor() {}

    ngDoCheck() {
        if (this.errors === undefined && this.control.getError('errors') === undefined) {
            // do nothing
        } else if (
            (this.errors === undefined || (this.errors && this.errors.length === 0)) &&
            this.control.getError('errors') !== undefined
        ) {
            // mark undefined
            this.control.setErrors(undefined);
        } else if (
            this.errors !== undefined &&
            this.errors.length > 0 &&
            JSON.stringify(this.errors) !== JSON.stringify(this.control.getError('errors'))
        ) {
            // show errors
            this.control.setErrors({ errors: this.errors.slice(0) });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        // Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
        if (changes.model) {
            this.control.setValue(this.model);
        }
        if (changes.disabled) {
            if (changes.disabled.currentValue === true) {
                this.control.disable();
            } else {
                this.control.enable();
            }
        }
    }

    modelChanged = (_event) => {
        this.model = _event;
        this.modelChange.next(this.model);
    };

    clearData = (value) => {
        this.onDataClear.emit(value);
    };

    // keyupEvent = (_event) => {
    //     this.keyupEventHandler.emit(_event);
    // }

    inputChange = (data) => {
        this.control.setValue((data.$event && data.$event.target && data.$event.target.value) || (data.target && data.target.value));
    };

    ngOnInit() {
        this.valueSubscription = this.control.valueChanges.subscribe((_event) => this.modelChanged(_event));
    }

    ngOnDestroy(): void {
        // Called once, before the instance is destroyed.
        this.valueSubscription && this.valueSubscription.unsubscribe();
    }
}
