import { AssetEntityConfigurationInterface } from 'taxilla-library';

import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { Entity } from '../entity.class';
import { MasterRecord } from '../masters/masterrecord.class';
import { Masters } from '../masters/masters.class';
import { GridData } from './gridfield.interface';
import { Message } from './message.class';
import { RecordField } from './recordfield.class';

export class Record {
    active: boolean;

    assetDataId: string;

    assetId: string;

    fields: RecordField[];

    id: string;

    message: Message;

    modified: boolean;

    parentId: string;

    requestId: string;

    displayName: string;

    grids: { [property: string]: GridData };

    entities: Entity[];

    entityId: string;

    errorsCount: number;

    warningsCount: number;

    masters: Masters;

    selected?: boolean;

    showEntity?: boolean;

    readOnly?: boolean;

    canAddRecord?: boolean;

    canUpdateRecord?: boolean;

    canDeleteRecord?: boolean;

    hasAddRecordPermission: boolean;

    hasDeleteRecordPermission: boolean;

    hasReadRecordPermission: boolean;

    hasUpdateRecordPermission: boolean;

    labelName?: string;

    saveBtnName?: string;

    updateBtnName?: string;

    editMode?: boolean;

    feedExpand?: boolean;

    templates?: AssetEntityConfigurationInterface['templates'];

    mapToPrimaryEntity?: {
        [property: string]: string;
    };

    lastFieldIdChanges: string[];

    disableIfValueExists?: string[];

    enableChildEntitiesOnlyIfFieldValueExists?: string[];

    disableSubmitIfValueExists?: string[];

    fieldsToDisableOnOldRecord?: string[];

    fieldsToHideOnNewRecord?: string[];

    mandateFieldsOnOldRecord?: string[];

    fieldsToAutoPopulateOnOldRecord?: {
        id?: string;
        by?: string;
        value?: string;
        record?: string;
        showAfter?: boolean;
        entityId?: string;
        fieldId?: string;
    }[];

    fieldsToDisableOnCondtion?: {
        id?: string;
        requiredField: string;
        condition?: string;
    }[];

    businessKeysCount?: number;

    showInListCount?: number;

    preCaptureCount?: number;

    entityIndex?: number;

    readonly errors?: string[];

    readonly warnings?: string[];

    tempId?: string;

    constructor(data, entity: Entity) {
        this.displayName = entity?.displayName || entity?.name;
        this.active = data?.active !== undefined ? data.active : true;
        this.assetDataId = data?.assetDataId;
        this.assetId = data?.assetId;
        this.grids = data?.grids;
        this.id = data?.id;
        this.message = new Message(data?.message || (data?.errors && { errors: data.errors, warnings: data.warnings }) || {});
        this.modified = data?.modified;
        this.parentId = data?.parentId || data?.parentRecordId;
        this.requestId = data?.requestId;
        this.fields = [];
        this.entityId = entity?.uid;
        this.masters = entity?.masters || new Masters();
        this.showEntity = entity?.showEntity !== undefined ? entity.showEntity : true;
        this.readOnly = entity?.readOnly !== undefined ? entity.readOnly : true;
        this.canAddRecord = entity?.canAddRecord !== undefined ? entity.canAddRecord : true;
        this.canUpdateRecord = entity?.canUpdateRecord !== undefined ? entity.canUpdateRecord : true;
        this.canDeleteRecord = entity?.canDeleteRecord !== undefined ? entity.canDeleteRecord : true;
        this.hasDeleteRecordPermission = entity?.hasDeleteRecordPermission;
        this.hasAddRecordPermission = entity?.hasAddRecordPermission;
        this.hasReadRecordPermission = entity?.hasReadRecordPermission;
        this.hasUpdateRecordPermission = entity?.hasUpdateRecordPermission;
        this.businessKeysCount = 0;
        this.showInListCount = 0;
        this.preCaptureCount = 0;
        const isFromAddRecord = data?.id === undefined ? true : false;
        entity?.fields?.forEach((entityField) => {
            let recordField: RecordField;
            entityField.isBusinessKey && this.businessKeysCount++;
            entityField.showInList && this.showInListCount++;
            entityField.preCaptureType && this.preCaptureCount++;
            if (entityField?.datatype === 'GRID') {
                recordField = Array.isArray(data?.grids)
                    ? data?.grids?.find((grid) => grid.id === entityField.uid)
                    : data?.grids?.[entityField.uid];
            } else {
                if (data && Array.isArray(data.fields)) {
                    recordField = data.fields.find((field) => field.id === entityField.uid);
                } else if (data?.fields) {
                    recordField = data.fields[entityField.uid];
                }
            }
            this.fields.push(new RecordField(recordField, entityField, entity, isFromAddRecord));
        });
        if (this.grids && Object.keys(this.grids).length > 0) {
            Object.keys(this.grids).forEach((gridId) => {
                const field = this.getFieldByFieldId(this.grids[gridId].id);
                field.assignGridValues(data.grids[gridId]);
            });
        }
        data?.entities !== undefined && (this.entities = data.entities);
        this.labelName = entity?.labelName;
        this.saveBtnName = entity?.saveBtnName;
        this.updateBtnName = entity?.updateBtnName;
        this.editMode = entity?.editMode;
        this.mapToPrimaryEntity = entity?.mapToPrimaryEntity;
        this.templates = entity?.templates;
        if (data?.fields) {
            if (Array.isArray(data.fields)) {
                this.pushFieldLevelErrorsAndWarningsToRecordLevel();
            } else if (Object.keys(data.fields).length > 0) {
                const errors = [];
                Object.keys(data.fields)?.forEach((fieldId) => {
                    const field = data.fields[fieldId];
                    field.message.errors?.forEach((error) => {
                        errors.push(`${field.displayName}: ${error}`);
                    });
                });
                errors.filter((error) => this.message.errors.indexOf(error) === -1).forEach((error) => this.message.errors.push(error));
            }
        }
        this.enableChildEntitiesOnlyIfFieldValueExists = entity?.enableChildEntitiesOnlyIfFieldValueExists;
        this.disableIfValueExists = entity?.disableIfValueExists;
        this.disableSubmitIfValueExists = entity?.disableSubmitIfValueExists;
        this.fieldsToDisableOnOldRecord = entity?.fieldsToDisableOnOldRecord;
        this.fieldsToAutoPopulateOnOldRecord = entity?.fieldsToAutoPopulateOnOldRecord;
        this.mandateFieldsOnOldRecord = entity?.mandateFieldsOnOldRecord;
        this.fieldsToHideOnNewRecord = entity?.fieldsToHideOnNewRecord;
        this.fieldsToDisableOnCondtion = entity?.fieldsToDisableOnCondtion;
        this.tempId = data?.tempId;
        this.assignMessagesCount();
    }

    getFieldByFieldId = (fieldId): RecordField => {
        return this.fields.find((field) => field.id === fieldId);
    };

    private getFieldByProperty = (property?: string, propertyValue?: any) => {
        return this.fields.find((field) => field[property] === propertyValue);
    };

    getFieldByFieldName = (fieldName) => {
        return this.getFieldByProperty('displayName', fieldName);
    };

    setFieldValue = (fieldId?: string, value?: any, gridData?: RecordField['gridData']) => {
        const field = this.getFieldByProperty('id', fieldId);
        if (field?.datatype === 'BOOLEAN' && field?.uiTypeMetadata === 'TEXTFIELD') {
            switch (typeof value) {
                case 'boolean':
                    field.value = value;
                    break;
                case 'string':
                    switch (value) {
                        case 'true':
                        case 'Yes':
                            field.value = true;
                            break;
                        case 'false':
                        case 'No':
                            field.value = false;
                            break;
                    }
                    break;
                default:
                    field.value = undefined;
                    break;
            }
        } else if (field?.datatype === 'GRID') {
            field.gridData = CommonUtilsService.cloneObject(gridData);
        } else if (field) {
            field.value = value;
        }
    };

    getFieldValue = (fieldId) => {
        const field = this.getFieldByFieldId(fieldId);
        return field?.value;
    };

    public getFieldName = (fieldId: string) => {
        const field = this.getFieldByFieldId(fieldId);
        return field?.displayName;
    };

    getRecord = (parentId) => {
        let parentRecord;
        if (this.id === parentId) {
            parentRecord = this;
        }
        if (!parentRecord) {
            if (Array.isArray(this.entities)) {
                for (let i = 0; i < this.entities.length; i++) {
                    parentRecord = this.entities[i].getEntityDataRecord(parentId);
                }
            } else {
                // console.log('This is not an array');
            }
        }
        return parentRecord;
    };

    bindValuesPostSave = (response) => {
        let responseField: {
            id: string;
            message: Message;
            modified: boolean;
            value: RecordField['value'];
        };
        this.fields.forEach((field) => {
            switch (field.datatype) {
                case 'GRID':
                    responseField =
                        response?.grids &&
                        (Array.isArray(response.grids)
                            ? response.grids.find((resField) => resField.id === field.id)
                            : response.grids[field.id]);
                    break;
                default:
                    responseField =
                        response?.fields &&
                        (Array.isArray(response.fields)
                            ? response.fields.find((resField) => resField.id === field.id)
                            : response.fields[field.id]);
                    break;
            }
            if (responseField) {
                if (field.datatype === 'GRID') {
                    field.assignGridValues(responseField as any);
                } else if (field.attachment) {
                    field.value = CommonUtilsService.cloneObject(responseField.value);
                    field.updateFiles();
                } else if (field.datatype === 'BOOLEAN' && field.uiTypeMetadata === 'TEXTFIELD') {
                    switch (responseField.value) {
                        case 'true':
                            field.value = true;
                            break;
                        case 'false':
                            field.value = false;
                            break;
                    }
                } else if (typeof field.value === 'object') {
                    field.value = CommonUtilsService.cloneObject(responseField.value);
                } else {
                    if (field.uiTypeMetadata === 'TEXTAREA') {
                        field.value = responseField?.value ? decodeURIComponent(responseField.value as string) : '';
                    } else {
                        field.value = CommonUtilsService.cloneObject(responseField.value);
                    }
                }
                if (field.datatype === 'BOOLEAN' && field.uiTypeMetadata === 'TEXTFIELD') {
                    switch (field.value) {
                        case true:
                            field.originalValue = true;
                            break;
                        case false:
                            field.originalValue = false;
                            break;
                    }
                } else {
                    field.originalValue =
                        responseField instanceof RecordField
                            ? CommonUtilsService.cloneObject(responseField.originalValue)
                            : CommonUtilsService.cloneObject(responseField.value);
                }
                field.message = new Message(responseField.message);
                field?.isBusinessKey && (field.disabled = field?.originalValue !== undefined);
            } else {
                field.originalValue = undefined;
                field.value = undefined;
            }
        });
        this.message = new Message(response?.message);
        this.pushFieldLevelErrorsAndWarningsToRecordLevel();
    };

    pushFieldLevelErrorsAndWarningsToRecordLevel = () => {
        const errors = [];
        const warnings = [];
        this.fields?.forEach((field) => {
            field.message.errors?.forEach((error) => {
                errors.push(`${field.displayName}: ${error}`);
            });
            field.message.warnings?.forEach((warning) => {
                warnings.push(`${field.displayName}: ${warning}`);
            });
        });
        errors.filter((error) => this.message.errors.indexOf(error) === -1).forEach((error) => this.message.errors.push(error));
        warnings
            .filter((warning) => this.message.warnings.indexOf(warning) === -1)
            .forEach((warning) => this.message.warnings.push(warning));
    };

    revertValueToOriginalValue = () => {
        if (this.fields && this.fields.length > 0) {
            this.fields.forEach((field) => {
                if (field.datatype === 'BOOLEAN' && field.uiTypeMetadata === 'TEXTFIELD') {
                    switch (field.originalValue) {
                        case true:
                            field.value = true;
                            break;
                        case false:
                            field.value = false;
                            break;
                    }
                }
            });
        }
    };

    removeRecordWhoseParentIdIsAndIdIs = (parentId, id) => {
        if (this.id === parentId) {
            if (this.entities) {
                for (let i = 0; i < this.entities.length; i++) {
                    const entityData = this.entities[i].entityData;
                    if (entityData !== undefined) {
                        if (Array.isArray(entityData)) {
                            for (let j = 0; j < entityData.length; j++) {
                                if (entityData[j].id === id) {
                                    entityData.splice(j, 1);
                                }
                            }
                        } else {
                            if (entityData.id === id) {
                                delete this.entities[i].entityData;
                            }
                        }
                    }
                }
            }
        } else if (this.entities && this.entities.length > 0) {
            for (let i = 0; i < this.entities.length; i++) {
                const entityData: Record | Record[] = this.entities[i].entityData;
                if (Array.isArray(entityData)) {
                    for (let j = 0; j < entityData.length; j++) {
                        entityData[j].removeRecordWhoseParentIdIsAndIdIs(parentId, id);
                    }
                } else {
                    if (this.id === id) {
                        entityData.removeRecordWhoseParentIdIsAndIdIs(parentId, id);
                    }
                }
            }
        }
    };

    bindErrors = (record: Record) => {
        if (this.fields?.length > 0 && record?.fields.length > 0) {
            record.fields?.forEach((recordField) => {
                const field = this.getFieldByFieldId(recordField.id);
                if (field && typeof field === 'object') {
                    field.message.errors = recordField.message?.errors || recordField.errors || [];
                    field.message.warnings = recordField.message?.warnings || recordField.warnings || [];
                    if (field.datatype === 'GRID') {
                        field.gridData?.cells?.forEach((cell) => {
                            field.message.errors = [...field.message.errors, ...cell.errors];
                        });
                    }
                }
            });
            this.assignMessagesCount();
        }
    };

    assignMessagesCount = () => {
        this.errorsCount = 0;
        this.warningsCount = 0;
        this.message.errors?.length > 0 && this.errorsCount++;
        this.message.warnings?.length > 0 && this.warningsCount++;
        this.fields.forEach((field) => {
            field.message.errors?.length > 0 && this.errorsCount++;
            field.message.warnings?.length > 0 && this.warningsCount++;
        });
    };

    toggleShowRecord = (toggle: boolean) => {
        this.showEntity = toggle;
        if (this.entities && this.entities.length > 0) {
            this.entities.forEach((e) => {
                e.toggleShowEntity(toggle);
            });
        }
    };

    toggleReadOnlyRecord = (readOnly: boolean) => {
        this.readOnly = readOnly;
        if (this.entities && this.entities.length > 0) {
            this.entities.forEach((e) => {
                e.toggleReadOnlyEntity(readOnly);
            });
        }
    };

    toggleCanAddRecord = (canAddRecord: boolean) => {
        this.canAddRecord = canAddRecord;
        if (this.entities && this.entities.length > 0) {
            this.entities.forEach((e) => {
                e.toggleCanAddRecordInEntity(canAddRecord);
            });
        }
    };

    toggleCanUpdateAddRecord = (canUpdateRecord: boolean) => {
        this.canUpdateRecord = canUpdateRecord;
        if (this.entities && this.entities.length > 0) {
            this.entities.forEach((e) => {
                e.toggleCanUpdateRecordInEntity(canUpdateRecord);
            });
        }
    };

    toggleCanDeleteRecord = (canDeleteRecord: boolean) => {
        this.canDeleteRecord = canDeleteRecord;
        if (this.entities && this.entities.length > 0) {
            this.entities.forEach((e) => {
                e.toggleCanDeleteRecordInEntity(canDeleteRecord);
            });
        }
    };

    setMasterValues = (
        masterRecord: MasterRecord,
        overwriteHiddenFields?: boolean,
        mainField?: RecordField,
        referenceMap?: {
            keyColumns: string[];
            valuesMap: {
                id: string;
                masterColumn: string;
                value: any;
                references: any[];
            }[];
        },
        initialRender?: boolean
    ) => {
        const columnName = mainField?.masterField?.id;
        const tableId = mainField?.masterData?.tableUid;
        const fieldId = mainField?.id;
        if (referenceMap) {
            referenceMap.valuesMap.forEach((mapObject) => {
                const field = this.getFieldByFieldId(mapObject.id);
                this.setMasterFieldValue(field, masterRecord, initialRender);
            });
        } else {
            if (mainField?.id?.length > 0) {
                const referenceField = this.fields.find((field) =>
                    field?.masterData?.columnRefMetadatas?.find((reference) => reference.fieldUid === mainField.id)
                );
                referenceField.masterData.columnRefMetadatas.forEach((reference) => {
                    this.setMasterFieldValue(this.getFieldByFieldId(reference.fieldUid), masterRecord, initialRender);
                });
            }
            this.fields
                .filter((field) => field.show || overwriteHiddenFields)
                .filter((field) => field.masterData && Object.keys(field.masterData).length > 0)
                .filter((field) => !tableId || field.masterData?.tableUid === tableId)
                .filter((field) => !columnName || field.masterData.columnName !== columnName || field.id === fieldId)
                .filter(
                    (field) =>
                        !fieldId ||
                        field.masterData.columnRefMetadatas.length === 0 ||
                        field.masterData.columnRefMetadatas.find((ref) => ref.fieldUid === fieldId) !== undefined
                )
                .forEach((field) => {
                    this.setMasterFieldValue(field, masterRecord, initialRender);
                });
        }
    };

    private readonly setMasterFieldValue = (field: RecordField, masterRecord: MasterRecord, initialRender?: boolean) => {
        if (initialRender) {
            return;
        }
        const masterLookupId = field.masterData.columnName;
        const masterField = masterRecord?.getFieldByFieldId(masterLookupId);
        const value = masterField?.value;
        switch (field.datatype) {
            case 'DATE':
                field.value = value;
                break;
            case 'BOOLEAN':
                switch (typeof value) {
                    case 'boolean':
                        field.value = value;
                        break;
                    case 'string':
                        switch (value) {
                            case 'true':
                            case 'Yes':
                                field.value = true;
                                break;
                            case 'false':
                            case 'No':
                                field.value = false;
                                break;
                            default:
                                field.value = undefined;
                                break;
                        }
                        break;
                    default:
                        field.value = '';
                        break;
                }
                break;
            default:
                switch (field.uiTypeMetadata) {
                    case 'CHECKBOX':
                        field.value = [...(Array.isArray(value) ? value : [value])];
                        break;
                    default:
                        field.value = value;
                        break;
                }
        }
    };

    buildMasterEntityFieldValueMap = (
        existingValuesMap: {
            [property: string]: {
                keyColumns: string[];
                valuesMap: {
                    id: string;
                    entityId: string;
                    masterColumn: string;
                    value: any;
                    references: any[];
                }[];
            };
        },
        map: { [property: string]: string },
        initialRender: boolean,
        fieldReference?: RecordField
    ) => {
        this.fields
            .filter((field) => field.masterData?.tableUid && field.show)
            .forEach((field) => {
                field.setValueInMasterMap(map);
            });
        return this.checkMasterEntityFieldValueMap(
            CommonUtilsService.cloneObject(existingValuesMap || {}),
            map,
            initialRender,
            fieldReference
        );
    };

    checkMasterEntityFieldValueMap = (
        existingValuesMap: {
            [property: string]: {
                keyColumns: string[];
                valuesMap: {
                    id: string;
                    entityId: string;
                    masterColumn: string;
                    value: any;
                    references: any[];
                }[];
            };
        },
        map: { [property: string]: string },
        initialRender: boolean,
        fieldReference: RecordField
    ) => {
        const masterRefMap = this.getMasterSearchableFieldValues(existingValuesMap);
        Object.keys(existingValuesMap || {}).forEach((tableId) => {
            if (!masterRefMap[tableId]) {
                masterRefMap[tableId] = existingValuesMap[tableId];
            }
        });
        if (fieldReference) {
            this.checkFieldWithFieldReference(map, masterRefMap, initialRender, fieldReference);
        } else {
            this.fields
                .filter((field) => field.hasMasterLookup && field.show)
                .forEach((field) => {
                    field.checkMasterSearchEnabled(map, masterRefMap, initialRender);
                });
        }
        return masterRefMap;
    };

    private checkFieldWithFieldReference = (
        map: { [property: string]: string },
        masterRefMap: {
            [property: string]: {
                keyColumns: string[];
                valuesMap: {
                    id: string;
                    entityId: string;
                    masterColumn: string;
                    value: any;
                    references: any[];
                }[];
            };
        },
        initialRender: boolean,
        fieldReference: RecordField
    ) => {
        let fieldIndex = -1;
        fieldReference.filterMapWithFieldReference(masterRefMap).valuesMap.forEach((valueMap, index) => {
            let field: RecordField;
            if (fieldIndex === -1) {
                fieldIndex = valueMap.id === fieldReference.id ? index : -1;
                field = fieldIndex !== -1 ? this.getFieldByFieldId(valueMap.id) : undefined;
                if (field?.hasMasterLookup) {
                    field.checkMasterSearchEnabled(map, masterRefMap, initialRender, field.filterMapWithFieldReference(masterRefMap));
                    this.fields
                        .filter((checkField) => checkField.hasMasterLookup && checkField.show)
                        .filter((checkField) => checkField.masterData.tableUid !== field.masterData.tableUid)
                        .filter((checkField) =>
                            checkField.masterData.columnRefMetadatas.find((reference) => reference.fieldUid === field.id)
                        )
                        .forEach((checkField) => {
                            checkField.checkMasterSearchEnabled(
                                map,
                                masterRefMap,
                                initialRender,
                                checkField.filterMapWithFieldReference(masterRefMap)
                            );
                            this.checkFieldWithFieldReference(map, masterRefMap, initialRender, checkField);
                        });
                }
            } else {
                const field = this.getFieldByFieldId(valueMap.id);
                field.checkMasterSearchEnabled(map, masterRefMap, initialRender, field.filterMapWithFieldReference(masterRefMap));
                if (field.hasMasterLookup) {
                    this.fields
                        .filter((checkField) => checkField.hasMasterLookup && checkField.show)
                        .filter((checkField) =>
                            checkField.masterData.columnRefMetadatas.find((reference) => reference.fieldUid === field.id)
                        )
                        .forEach((checkField) => {
                            checkField.checkMasterSearchEnabled(
                                map,
                                masterRefMap,
                                initialRender,
                                checkField.filterMapWithFieldReference(masterRefMap)
                            );
                            this.checkFieldWithFieldReference(map, masterRefMap, initialRender, checkField);
                        });
                }
            }
        });
    };

    getMasterSearchableFieldValues = (existingMasterRefMap: {
        [property: string]: {
            keyColumns: string[];
            valuesMap: {
                id: string;
                entityId: string;
                masterColumn: string;
                value: any;
                references: any[];
            }[];
        };
    }) => {
        const masterRefMap: {
            [property: string]: {
                keyColumns: string[];
                valuesMap: {
                    id: string;
                    entityId: string;
                    masterColumn: string;
                    value: any;
                    references: any[];
                }[];
            };
        } = {};
        this.fields
            .filter((field) => field.masterData.tableUid && field.show)
            .forEach((field) => {
                if (!masterRefMap[field.masterData.tableUid]) {
                    masterRefMap[field.masterData.tableUid] = {
                        keyColumns: [],
                        valuesMap: [],
                    };
                }
                const keyColumns = masterRefMap[field.masterData.tableUid].keyColumns;
                const valuesMap = masterRefMap[field.masterData.tableUid].valuesMap;
                if (field.masterField.primaryKey) {
                    field.masterData.columnRefMetadatas
                        .filter((ref) => ref.entityUid !== field.entityUid)
                        .forEach((reference) => {
                            field.pushToKeyColumns(keyColumns, reference.fieldUid);
                            field.pushToValuesMap(valuesMap, field.getValueMapFromTablesMap(existingMasterRefMap, reference.fieldUid));
                        });
                    field.pushToKeyColumns(keyColumns, field.id);
                }
                field.pushToValuesMap(valuesMap, {
                    id: field.id,
                    entityId: field.entityUid,
                    masterColumn: field.masterData.columnName,
                    value: field.value,
                    references: field.masterData.columnRefMetadatas,
                });
            });
        return masterRefMap;
    };

    copyBusinessKeys = (primaryEntity: Entity) => {
        if (!this.mapToPrimaryEntity || !primaryEntity || !primaryEntity.entityData) {
            return;
        }
        const primaryRecord = primaryEntity.entityData as Record;
        const keys = Object.keys(this.mapToPrimaryEntity);
        keys.forEach((key: string) => {
            const relevantFieldId = this.mapToPrimaryEntity[key] as string;
            const currentField: RecordField = this.getFieldByFieldId(key);
            currentField.value = primaryRecord.getFieldValue(relevantFieldId);
            currentField.disabled = true;
            currentField.show = false;
        });
    };

    getChangedFields = (): RecordField[] => {
        const changedFields = [];
        this.fields.filter((field) => field.value !== field.originalValue).forEach((field) => changedFields.push(field));
        return changedFields;
    };

    getChangedFieldIds = (): string[] => {
        const changedFieldIds = [];
        const changedFields = this.getChangedFields();
        changedFields.forEach((field) => changedFieldIds.push(field.id));
        return changedFieldIds;
    };

    revertToOriginalValues = () => {
        this.fields.forEach((field) => this.setFieldValue(field.id, CommonUtilsService.cloneObject(field.originalValue)));
    };

    clearDependentValuesForMasters = (fieldId: string) => {
        const referenceField = this.fields.find((field) =>
            field.masterData.columnRefMetadatas?.find((reference) => reference.fieldUid === fieldId)
        );
        referenceField?.masterData?.columnRefMetadatas?.forEach((reference) => this.setFieldValue(reference.fieldUid, undefined));
        this.fields
            .filter((field) => field.masterData?.columnRefMetadatas?.find((reference) => reference.fieldUid === fieldId))
            .forEach((field) => this.setFieldValue(field.id, undefined));
    };
}
