import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, delay, from, map, mergeMap, of, withLatestFrom } from 'rxjs';

import { ApiService } from '../../services/api/api.service';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { UtilsService } from '../../services/utils/utils.service';
import {
    AlertError,
    BundleRoleCrudOperataions,
    GetAllRolePermissions,
    GetBundlePermissions,
    GetBundleRolePermissions,
    GetPrimaryPermissions,
    GetRolePermissions,
    SetAllRolePermissions,
    SetBundlePermissions,
    SetBundleRolePermissions,
    SetPermissions,
    SetRolePermissions,
    UpdateBundleRolePermissions,
} from '../actions';
import {
    getAllRolePermissions$,
    getBundlePermissions$,
    getBundleRolePermissions$,
    getCurrentOrganizationId$,
    getRolePermissions$,
    getSelectedRoleId$,
} from '../selectors';

@Injectable()
export class PermissionsEffects {
    constructor(
        private actions$: Actions,
        private _api: ApiService,
        private store$: Store,
        private Utils: CommonUtilsService,
        private _utils: UtilsService
    ) {}

    getPermissions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetPrimaryPermissions),
            delay(10),
            withLatestFrom(this.store$.select(getCurrentOrganizationId$)),
            mergeMap(([_action, currentOrganizationId]) => {
                return from(this._api.permissions.getOrganizationRelatedPermissions()).pipe(
                    map((event) => {
                        return SetPermissions({
                            currentOrganizationId,
                            permissions: (event as any) || [],
                        });
                    }),
                    catchError(() => {
                        return [];
                    })
                );
            })
        )
    );

    getBundlePermissions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetBundlePermissions),
            withLatestFrom(this.store$.select(getCurrentOrganizationId$), this.store$.select(getBundlePermissions$)),
            mergeMap(([action, organizationId, bundlePermissions]) => {
                if (bundlePermissions !== undefined && !this.Utils.isEmpty(bundlePermissions)) {
                    return [];
                }
                return from(this._api.roles.getBundlePermissions(action.bundleId, action.selectedApps)).pipe(
                    map((res) => {
                        return SetBundlePermissions({
                            organizationId,
                            bundleId: action.bundleId,
                            permissions: res?.response?.permissions,
                        });
                    }),
                    catchError((e) => of(AlertError({ message: e?.msg })))
                );
            })
        )
    );

    getBundleRolePermissions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetBundleRolePermissions),
            withLatestFrom(this.store$.select(getCurrentOrganizationId$), this.store$.select(getBundleRolePermissions$)),
            mergeMap(([action, organizationId, bundleRolePermissions]) => {
                if (bundleRolePermissions?.length > 0) {
                    return of(
                        SetBundleRolePermissions({
                            organizationId,
                            bundleId: action.bundleId,
                            roleId: action.roleId,
                            rolePermissions: CommonUtilsService.cloneObject(bundleRolePermissions),
                        })
                    );
                }
                return from(this._api.roles.getBundleRolePermissions(action.bundleId, action.roleId)).pipe(
                    map((res) => {
                        return SetBundleRolePermissions({
                            organizationId,
                            bundleId: action.bundleId,
                            roleId: action.roleId,
                            rolePermissions: res?.response?.permissions,
                        });
                    }),
                    catchError((e) => of(AlertError({ message: e?.msg })))
                );
            })
        )
    );

    UpdateBundleRolePermissions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UpdateBundleRolePermissions),
            withLatestFrom(this.store$.select(getCurrentOrganizationId$), this.store$.select(getSelectedRoleId$)),
            mergeMap(([action, organizationId, roleId]) => {
                let permissions = action.permissions.reduce((permissionIds, permission) => {
                    permissionIds.push({ uuid: permission.uuid });
                    return permissionIds;
                }, []);
                return from(this._api.roles.updateBundleRolePermissions(action.bundleId, roleId, permissions)).pipe(
                    map((res) => {
                        this.store$.dispatch(
                            BundleRoleCrudOperataions({
                                msg: res?.msg,
                                actionType: action?.actionType || 'UPDATE',
                            })
                        );
                        let updatedPermissions = [];
                        action.permissions.forEach((permission) => {
                            updatedPermissions.push(permission.uuid);
                        });
                        return SetBundleRolePermissions({
                            organizationId,
                            bundleId: action.bundleId,
                            roleId: roleId,
                            rolePermissions: updatedPermissions,
                        });
                    }),
                    catchError((e) => of(AlertError({ message: e?.msg })))
                );
            })
        )
    );

    getRolePermissions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetRolePermissions),
            withLatestFrom(
                this.store$.select(getCurrentOrganizationId$),
                this.store$.select(getRolePermissions$),
                this.store$.select(getSelectedRoleId$)
            ),
            mergeMap(([action, organizationId, rolePermissions, roleId]) => {
                if (rolePermissions?.length > 0) {
                    return of(
                        SetRolePermissions({
                            organizationId,
                            roleId: roleId,
                            rolePermissions: CommonUtilsService.cloneObject(rolePermissions),
                        })
                    );
                }
                return from(this._api.roles.getRolePermissions(action.role)).pipe(
                    map((res: any) => {
                        return SetRolePermissions({
                            organizationId,
                            roleId: roleId,
                            rolePermissions: res?.[0],
                        });
                    }),
                    catchError((e) => of(AlertError({ message: e?.msg })))
                );
            })
        )
    );

    getAllRolePermissions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetAllRolePermissions),
            withLatestFrom(this.store$.select(getCurrentOrganizationId$), this.store$.select(getAllRolePermissions$)),
            mergeMap(([_action, organizationId, rolePermissions]) => {
                if (rolePermissions?.length > 0) {
                    return of(
                        SetAllRolePermissions({
                            organizationId,
                            permissions: CommonUtilsService.cloneObject(rolePermissions),
                        })
                    );
                }
                return from(this._api.roles.getAllPermissions(organizationId)).pipe(
                    map((res: any) => {
                        let permissions = this._utils.copyObjectToObject(res[0]?.response?.permissions, res[1]?.response?.permissions);
                        return SetAllRolePermissions({
                            organizationId,
                            permissions: permissions,
                        });
                    }),
                    catchError((e) => of(AlertError({ message: e?.msg })))
                );
            })
        )
    );
}
