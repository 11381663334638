import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { CronOptions } from '@cron-builder';
import { translate, TRANSLOCO_SCOPE } from '@ngneat/transloco';

import {
    IntegrationConfigField,
    IntegrationModal,
    IntegrationsSubSection,
    IntegrationSubSectionRecord,
    IntegrationTabStructure,
} from '../../interface/integrationmodal.interface';
import { MastersTableMetaDataInterface } from '../../interface/mastertablesmetadata.interface';
import { AssetService } from '../../models/assetservice.class';
import { BridgeNode } from '../../models/bridgeNode.interface';
import { Chain } from '../../models/chain.interface';
import { FilingAttributeField } from '../../models/filingattributefield.class';
import { AppIntegrationRecord } from '../../models/integrations/appintegrationrecord.class';
import { AppNewIntegrationRecordPayload } from '../../models/integrations/appnewintegrationpayload.class';
import { IntegrationRecord } from '../../models/integrations/integrationrecord.class';
import { MasterTables } from '../../models/masters/mastertables.class';
import { ApiService } from '../../services/api/api.service';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { IntegrationsUtilsService } from '../../services/integrations-utils/integrations-utils.service';
import { UtilsService } from '../../services/utils/utils.service';
import { AddConfigurationModalComponent } from '../addconfigurationmodal/addconfigurationmodal.component';

@Component({
    selector: 'app-integrations-template',
    templateUrl: './integrations-template.component.html',
    styleUrls: ['./integrations-template.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: '',
        },
    ],
})
export class IntegrationsTemplateComponent implements OnInit, OnChanges {
    @Input() title: string;
    @Input() record: AppIntegrationRecord;
    @Input() config: IntegrationModal;
    @Input() app: AssetService;
    @Input() subApp: BridgeNode | AssetService;
    @Input() masterTableColumns: any[];
    @Input() sources: { id: string; displayName: string; value?: string | boolean; pushValue?: string }[] = [];
    @Input() bridge: AssetService;
    @Input() openWidget: boolean;
    @Input() drawer: MatDrawer;
    @Input() master: MastersTableMetaDataInterface;
    @Input() transformationMappings: {
        [property: string]: {
            name: string;
            description: string;
            engineProps: { type: string; version: string };
            sourceParts: {
                name: string;
                sourceDataFormat: string;
                fileExtension: string;
                bulkUpload: false;
                entities: any[];
                settings: {};
            }[];
            mappedTargetMetadata: {
                name: string;
                dataFormat: string;
                fileExtension: string;
                mappedEntities: any[];
                disableStreamBased: boolean;
            };
            mappingMetadata: { mapId: string; mapName: string };
            defaulted: false;
            xmlVersion: string;
        };
    };
    @Input() chains: Chain[];
    @Input() noHeader: boolean;
    @Input() containerOffset: number;
    @Input() masterTables: MasterTables[];
    @Input() subTenants: any[];
    @Input() translated: boolean;
    @Input() dontFetchUsingMasters: boolean;

    @Output() gotoIntegrationsPage = new EventEmitter();

    currentTab: any;
    editRecord: IntegrationRecord;
    public cronOptions: CronOptions = {
        formInputClass: 'form-control cron-editor-input',
        formSelectClass: 'form-control cron-editor-select',
        formRadioClass: 'cron-editor-radio',
        formCheckboxClass: 'cron-editor-checkbox',
        defaultTime: '10:00:00',
        use24HourTime: true,
        hideMinutesTab: false,
        hideHourlyTab: false,
        hideDailyTab: false,
        hideWeeklyTab: false,
        hideMonthlyTab: false,
        hideYearlyTab: false,
        hideAdvancedTab: true,
        hideSeconds: true,
        removeSeconds: true,
        removeYears: true,
    };

    constructor(
        protected _integrationUtils: IntegrationsUtilsService,
        protected _taxilla: ApiService,
        private dialog: MatDialog,
        private _libUtils: UtilsService,
        private _commonUtils: CommonUtilsService
    ) {}

    hideField = this._integrationUtils.hideField;
    hideSubSection = this._integrationUtils.hideSubSection;
    getMasterColumns = (masterId) => this._integrationUtils.getMasterColumns(masterId, this.masterTableColumns);

    addNewSubSectionRecord = (subSection: IntegrationsSubSection) =>
        this._integrationUtils.addNewSubSectionRecord(this.record as any, subSection);

    deleteSubRecord = (records: IntegrationSubSectionRecord[], index: number) => {
        if (records && records.length > 1) {
            records.splice(index, 1);
        } else {
            this._libUtils.alertError(this.translateMsg('Atleast one configuration is required'));
        }
    };

    testConnection = (record: AppIntegrationRecord, index: number) => {
        const subSection = record.recordData.subSections.find((section) => section.id === 'configs');
        const subSectionRecord = subSection.records[index];
        let isNewRecord = record.originalData.createdOn === undefined;
        const isValid = this._integrationUtils.validateSubSection(isNewRecord, subSection, record as any, subSectionRecord, index);
        if (!isValid) {
            return this._libUtils.alertError(this.translateMsg('Please fix errors to proceed'));
        }
        const payload = new AppNewIntegrationRecordPayload(record, this.config, this.app, this.subApp as any, this.master);
        payload.locationId = this._commonUtils.getFromStorage('currentorganizationid');
        const currentRecord = payload.configs[index];
        ['chainName', 'configName', 'integrationType', 'locationId', 'schedulerExpression', 'serviceId', 'transformationName'].forEach(
            (key) => {
                currentRecord[key] = payload[key];
            }
        );
        this._taxilla.integrations.testAppIntegrationConfiguration(currentRecord, {
            successCallback: (response) => {
                if (response && response.connected) {
                    this._libUtils.alertSuccess((response && response.msg) || this.translateMsg('Connected successfully'));
                } else {
                    this._libUtils.alertError(
                        (response && response.msg) || this.translateMsg('Failed to connect to the provided configuration')
                    );
                }
            },
        });
    };

    chainChanged = (value: IntegrationConfigField['value'], field: IntegrationConfigField) => {
        if (this.transformationMappings) {
            if (typeof value === 'object') {
                return;
            }
            const transformationField = this.record.getRecordField('transformationName');
            transformationField.options.splice(0);
            if (value !== undefined && value !== '') {
                transformationField.options.push({
                    displayName: value,
                    id: value,
                    chainName: value,
                } as any);
            }
            transformationField.value = value;
            this.transformationsChanged(value, transformationField);
        } else if (this.app) {
            this._integrationUtils.chainChanged(
                this.record,
                field,
                (this.subApp && this.subApp.name) || this.app.name,
                this.bridge ? this.subApp.id : this.app.assetMetaUId,
                this.chains
            );
        }
    };

    transformationsChanged = (value: IntegrationConfigField['value'], field: IntegrationConfigField) => {
        if (this.transformationMappings) {
            this.sources.splice(0);
            this.transformationMappings[value]?.sourceParts?.forEach((source) => {
                this.sources.push({
                    displayName: source.name,
                    id: source.name,
                    value: source.name,
                });
            });
        } else if (this.app) {
            this._integrationUtils.transformationsChanged(
                this.record,
                field,
                (this.subApp && this.subApp.name) || this.app.name,
                this.bridge ? this.subApp.id : this.app.assetMetaUId,
                this.sources,
                this.chains
            );
        }
        let hasAppsMasterScheduler = this._commonUtils.getFromStorage('hasAppsMasterScheduler');
        if (value?.length > 0 && hasAppsMasterScheduler) {
            const attributes = this.record.getRecordTab('attributes');
            if (attributes?.id) {
                attributes.fields = [];
                attributes.records = [];
                const chainField = this.record.getRecordField('chainName');
                const selectedChain = this.chains?.find((chain) => chain.chainName === chainField.value);
                this._taxilla.assets.getFilteringAttributes(
                    {
                        repositoryId: selectedChain.repositoryId,
                        service: this.subApp?.restApiName ? (this.subApp as any) : this.app,
                        bridge: this.subApp?.restApiName ? this.app : undefined,
                        transformationName: value,
                    },
                    {
                        successCallback: (response) => {
                            if (response?.attributes?.length > 0) {
                                this.pushFilingAttributes(attributes, response?.attributes, this.record);
                            }
                        },
                    }
                );
            }
        }
    };

    pushFilingAttributes = (tab: IntegrationTabStructure, attributes: FilingAttributeField[], record?: AppIntegrationRecord) => {
        const configuredData = record.originalData['configuredData'];
        const list = configuredData && configuredData[tab.id] && configuredData[tab.id][tab.tabsInProperty];
        const options = [
            {
                displayName: 'Current Month',
                id: 'currentMonth',
                value: 'm',
            },
            {
                displayName: 'Current Year',
                id: 'currentYear',
                value: 'y',
            },
            {
                displayName: 'Current Date',
                id: 'currentDate',
                value: 'd',
            },
            {
                displayName: 'Beginning of Month',
                id: 'beginningOfMonth',
                value: 'bom',
            },
            {
                displayName: 'End of Month',
                id: 'endOfMonth',
                value: 'eom',
            },
            {
                displayName: 'Scheduler from Date',
                id: 'schedulerFromDate',
                value: 'schedular_from_date',
            },
            {
                displayName: 'Scheduler to Date',
                id: 'schedulerToDate',
                value: 'schedular_to_date',
            },
            {
                displayName: 'Other',
                id: 'other',
                value: 'other',
            },
        ];
        attributes.forEach((attribute) => {
            const field: IntegrationConfigField = {
                id: attribute.id,
                displayName: attribute.name,
                valueAsArrayInPayload: false,
                conditionalDisplay: false,
                idFieldInSubObjectOnly: true,
                inputTypeConditonal: true,
                type: [
                    {
                        fieldId: 'dynamic',
                        requiredValue: true,
                        type: 'OPTIONS',
                    },
                    {
                        fieldId: 'dynamic',
                        requiredValue: false,
                        type: 'DROPDOWN',
                    },
                ],
                required: false,
                position: 'UNIQUE-SBS',
                options,
                copyToPayload: {
                    dataFormat: attribute.outputFormat,
                    fieldIdInPayloadAs: 'name',
                    dataType: CommonUtilsService.getMaproDataType(attribute.dataType),
                },
                payloadType: 'ATTRIBUTE',
                fieldIdInPayloadAs: 'name',
                fieldValueInPayloadAs: 'value',
                dynamicField: true,
            };
            tab.fields.push(field);
        });
        this.pushRecords(tab, list || []);
    };

    private pushRecords = (tab: IntegrationTabStructure, list) => {
        tab.records = [];
        list?.forEach((subData) => {
            const newRecord: IntegrationTabStructure['records'][0] = {
                fields: [],
                recordActions: [],
            };
            // tslint:disable-next-line:forin
            for (const dataKey in subData) {
                let metaDataField = tab.fields.find((tabField) => dataKey === 'name' && tabField.id === subData[dataKey]);
                if (metaDataField) {
                    metaDataField = CommonUtilsService.cloneObject(metaDataField);
                    metaDataField.value = subData['value'];
                    newRecord.fields.push(metaDataField);
                } else {
                    let metaDataSubField = tab.recordActions.find((tabSubField) => tabSubField.id === dataKey);
                    if (metaDataSubField) {
                        metaDataSubField = CommonUtilsService.cloneObject(metaDataSubField);
                        metaDataSubField.value = subData[dataKey] || metaDataSubField.defaultValue;
                        newRecord.recordActions.push(metaDataSubField);
                    }
                }
            }
            tab.records.push(newRecord);
        });
        tab.fields
            .filter((tabField) => list.findIndex((item) => item.name === tabField.id) === -1)
            .forEach((metaDataField) => {
                const newRecord: IntegrationTabStructure['records'][0] = {
                    fields: [],
                    recordActions: [],
                };
                newRecord['fields'].push(CommonUtilsService.cloneObject(metaDataField));
                tab.recordActions.forEach((action) => {
                    const cloneAction = CommonUtilsService.cloneObject(action);
                    cloneAction.value = cloneAction.defaultValue;
                    newRecord.recordActions.push(cloneAction);
                });
                tab.records.push(newRecord);
            });
    };

    configNameChanged = (field, subSectionRecord: IntegrationsSubSection['records'][0]) =>
        this._integrationUtils.configNameChanged(field, subSectionRecord);

    apiConfigChange = (value: IntegrationConfigField['value'], subSectionRecord: IntegrationsSubSection['records'][0]) =>
        this._integrationUtils.apiConfigChange(value, subSectionRecord, this.config, this.dontFetchUsingMasters);

    openAddConfigurationModal = (value?) => {
        const record = {};
        let title = '';
        if (value) {
            this.editRecord = new IntegrationRecord((record as any) || { type: this.currentTab.api.toUpperCase() }, this.currentTab);
            title = 'Edit';
        } else {
            this.editRecord = new IntegrationRecord({ type: this.currentTab.api.toUpperCase() } as any, this.currentTab);
            title = 'Add';
        }
        const dialogRef = this.dialog.open(AddConfigurationModalComponent, {
            panelClass: ['addConfiguration', 'matDialogContainer'],
            disableClose: true,
        });
        dialogRef.componentInstance.currentTab = this.currentTab;
        dialogRef.componentInstance.record = this.editRecord;
        dialogRef.componentInstance.submitConfiguration = this.submitIntegrationConfiguration;
        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.masterTables = this.masterTables;
        dialogRef.componentInstance.subTenants = this.subTenants;
    };

    submitIntegrationConfiguration = () => {
        this.dialog.closeAll();
    };

    canShowTestConnection = (subSectionRecord: IntegrationSubSectionRecord) => {
        const integrationField = subSectionRecord.fields.find((field) => field.id === 'integrationMethod');
        return integrationField.value !== 'EMAIL' && integrationField.value !== 'WEBSERVICE' && integrationField.value !== 'RESTAPI';
    };

    translateMsg = (msg: string): string => translate('' + msg);

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.openWidget) {
            this.openWidget = changes.openWidget.currentValue;
        }
    }

    ngOnInit() {}
}
