import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { environment } from '@env';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BroadcasterService } from 'ng-broadcaster';
import { filter, Subject, takeUntil } from 'rxjs';
import { getsupportedRegions$ } from 'taxilla-library';

import { RegionsInterface } from '../../interface/regions.interface';
import { Organization } from '../../models/organization.class';
import { User } from '../../models/user.class';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { UtilsService } from '../../services/utils/utils.service';
import { GetSupportedRegions, OpenUpdatePassword, SessionLogout, SetAssociatedLocations } from '../../store/actions';
import { getAssociatedOrganizations$ } from '../../store/selectors';
import { ChangePasswordComponent } from '../changepassword/changepassword.component';
import { DigitalSignatureComponent } from '../digital-signature/digital-signature.component';
import { EditprofileComponent } from '../editprofile/editprofile.component';

@Component({
    selector: 'app-user-details',
    templateUrl: './user-details.component.html',
    styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent implements OnInit {
    @Input() isNewUI: boolean = false;

    private unSubscribe = new Subject<void>();
    public loggedInUser: User = this._commonUtils.getFromStorage('user');
    public loggedInOrgName: any;
    public loggedInOrgId: any;
    public lastLogin: any;
    public currentLanguage: string;
    public availableLanguages: any = [];
    public selectedLanguage: {
        label: string;
        id: string;
    };
    public region: string;
    public regionUrl: string;
    public availableRegions: RegionsInterface[] = [];
    public selectedRegion: {
        code: string;
        defaultLanguage: string;
        defaultTimeZone: string;
        name: string;
        regionUrl: string;
    };
    private associatedOrganizations: Organization[];

    constructor(
        public _commonUtils: CommonUtilsService,
        private _broadcaster: BroadcasterService,
        public dialog: MatDialog,
        public _utils: UtilsService,
        private store$: Store,
        private actions$: Actions
    ) {}

    initiateSubscriptions = () => {
        this.store$
            .select(getAssociatedOrganizations$)
            .pipe(
                takeUntil(this.unSubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe((orgs) => {
                this.associatedOrganizations = orgs;
            });
        this.store$
            .select(getsupportedRegions$)
            .pipe(takeUntil(this.unSubscribe))
            .subscribe((data: any) => {
                this.availableRegions = data || [];
                this.selectedRegion = this.availableRegions.find((region) => region.code === this.region);
            });

        this.actions$.pipe(ofType(OpenUpdatePassword), takeUntil(this.unSubscribe)).subscribe(() => {
            this.dialog.open(ChangePasswordComponent, {
                panelClass: ['changePasswordModal', 'matDialogContainer', 'w-50'],
                disableClose: true,
            });
        });
    };

    setUserDetails = () => {
        this.loggedInUser = this._commonUtils.getFromStorage('user');
        const loggedInOrganization = this._commonUtils.getFromStorage('loggedinorganization');
        const lastLoggedInOrgTime = this._commonUtils.getFromStorage('lastlogintime');
        this.loggedInOrgName = loggedInOrganization && loggedInOrganization.name;
        this.loggedInOrgId = loggedInOrganization && loggedInOrganization.orgLoginId;
        this.lastLogin = lastLoggedInOrgTime;
    };

    updateUserDetails = (user) => {
        this.loggedInUser = user;
        this._broadcaster.broadcast('editedUser', user);
    };

    openEditProfileModal = () => {
        const dialogRef = this.dialog.open(EditprofileComponent, {
            panelClass: ['matDialogContainer', 'editPopupWidth'],
            disableClose: true,
        });
        dialogRef.componentInstance.updateUserObject = this.updateUserDetails;
    };

    openDigitalSignatureModal = () => {
        const dialogRef = this.dialog.open(DigitalSignatureComponent, {
            panelClass: ['matDialogContainer', 'editPopupWidth'],
            disableClose: true,
        });
        dialogRef.componentInstance.updateUserObject = this.updateUserDetails;
    };

    switchToLoggedInOrganization = () => {
        const org = this._commonUtils.getFromStorage('loggedinorganization');
        if (this.isNewUI && this.associatedOrganizations?.length === 1) {
            this.store$.dispatch(
                SetAssociatedLocations({
                    organizations: [],
                })
            );
        }
        if (org?.id) {
            this._utils.navigateToOrganization(org?.id);
        }
    };

    startLanguages = () => {
        this.availableLanguages = this._commonUtils.getAvailableLanguages();
        this.currentLanguage = this._commonUtils.getActiveLanguage();
        this.selectedLanguage = this.availableLanguages.find((language) => language.id === this.currentLanguage);
        this.region = environment.region;
    };

    setLanguage = (id: string) => {
        this._utils.setLanguage(id);
        this.currentLanguage = this._commonUtils.getActiveLanguage();
        location.reload();
    };

    private getSupportedRegions = () => {
        this.store$.dispatch(GetSupportedRegions());
    };

    public setRegion = (code: string) => {
        const region = this.availableRegions.find((item) => item.code === code);
        this._utils.setLanguage(region.defaultLanguage);
        location.href = region.regionUrl;
    };

    openChangePasswordModal = () => {
        this.store$.dispatch(OpenUpdatePassword());
    };

    onLogout = () => {
        this.store$.dispatch(SessionLogout());
        this._commonUtils.deleteCookie('domain');
    };

    ngOnInit(): void {
        this.getSupportedRegions();
        this.initiateSubscriptions();
        this.setUserDetails();
        this.startLanguages();
    }

    ngOnDestroy(): void {
        this.unSubscribe.next();
        this.unSubscribe.complete();
    }
}
