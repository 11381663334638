<ng-container *transloco="let translate;">
    <div [hidden]="!openWidget" class="inboundBox userMainTableConatiner">
        <div class="settingsHeader" *ngIf="!noHeader">
            <span class="settingsNameText">{{translated ? title : translate(title)}}</span>
            <div class="settingsDataActionsContainer">
                <mat-icon [title]="translate('Close')" class="closeIcon" (click)="gotoIntegrationsPage.emit(drawer)">cancel</mat-icon>
            </div>
        </div>
        <div class="inboundIntegrationsBodyContainer !max-h-[calc(100vh-14rem)] !overflow-auto !pb-px">
            <div class="inboundIntegrationsFormContainer">
                <div class="inboundIntegrationsFieldsContainer">
                    <ng-container *ngIf="record && record.recordData">
                        <div class="inboundIntegrationsFieldsContainer row" *ngIf="record.recordData.fields && record.recordData.fields.length > 0">
                            <ng-container *ngFor="let field of record.recordData.fields; let i = index">
                                <ng-container *ngIf="!hideField(field, record) && field.show !== false">
                                    <div class="inboundIntegrationFieldContainer col-6">
                                        <ng-container *ngTemplateOutlet="integrationField; context: { record: record, field: field, hide: hideField(field, record) }">
                                        </ng-container>
                                        <ng-container *ngIf="field && field.id === 'configId'">
                                            <div class="configAddsModalContainer">
                                                <span (click)="openAddConfigurationModal()" class="addConfigSpan">Add</span>
                                                <span *ngIf="field.value && field.value.length > 0" (click)="openAddConfigurationModal(field.value)">Edit</span>
                                            </div>
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                        <div class="inboundIntegrationsSectionsContainer">
                            <ng-container *ngIf="record.recordData.subSections">
                                <ng-container *ngFor="let subSection of record.recordData.subSections">
                                    <ng-container *ngIf="!hideSubSection(subSection, record)">
                                        <ng-container *ngIf="subSection.displayName && subSection.displayName.length > 0">
                                            <div class="subSectionDisplayName">
                                                {{translate(subSection.displayName)}}
                                                <div class="subSectionActionsContainer">
                                                    <div class="subSectionActionContainer" *ngIf="subSection.canAddRecord">
                                                        <mat-icon *ngIf="subSection.records.length < sources.length" [title]="translate('Add New Record')" (click)="addNewSubSectionRecord(subSection)">add</mat-icon>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngTemplateOutlet="integrationSubSections; context: { record: record, subSection: subSection }">
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <ng-template #integrationSubSections let-record="record" let-subSection="subSection" let-subSectionRecord="subSectionRecord">
        <ng-container *ngIf="!hideSubSection(subSection, record, subSectionRecord)">
            <ng-container [ngSwitch]="subSection.structure">
                <ng-container *ngSwitchCase="'RECORDS_FORM'">
                    <ng-container *ngTemplateOutlet="recordsStructure; context: { record: record, subSection: subSection }"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'POSTMAN'">
                    <ng-container *ngTemplateOutlet="postManStructure; context: { record: record, subSection: subSection, subSectionRecord: subSectionRecord }">
                    </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'FORM'">
                    <ng-container *ngTemplateOutlet="formStructure; context: { record: record, subSection: subSection }">
                    </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'FLEX_FORM'">
                    <ng-container *ngTemplateOutlet="flexFormStructure; context: { record: record, subSection: subSection }">
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-template>

    <ng-template #recordsStructure let-record="record" let-subSection="subSection">
        <ng-container *ngTemplateOutlet="subSection.array ? recordsArrayStructure : recordsFormStructure; context: { record: record, subSection: subSection }"></ng-container>
    </ng-template>

    <ng-template #recordsArrayStructure let-record="record" let-subSection="subSection">
        <div *ngFor="let subSectionRecord of subSection.records; let i = index" [class.even]="i%2 === 1" [class.odd]="i%2 === 0" class="subSectionRecordContainer">
            <span class="removeSubRecordAction actionsAtTop">
                <material-button class="deleteButton" [label]="translate('Delete')" [color]="'default'" [icon]="'delete'" (onTrigger)="deleteSubRecord(subSection.records, i)"></material-button>
            </span>
            <span class="removeSubRecordAction actionsAtBottom">
                <material-button *ngIf="canShowTestConnection(subSectionRecord)" [label]="translate('Test Connection')" [color]="'primary'" (onTrigger)="testConnection(record, i)"></material-button>
            </span>
            <ng-container *ngIf="subSectionRecord.fields && subSectionRecord.fields.length > 0">
                <div class="subSectionRecordFieldsContainer row">
                    <ng-container *ngFor="let field of subSectionRecord.fields">
                        <div class="inboundIntegrationFieldContainer col-4" *ngIf="!hideField(field, record, undefined, subSectionRecord) && field.show !== false">
                            <ng-container *ngTemplateOutlet="integrationField; context: { record: record, field: field, subSectionRecord: subSectionRecord, hide : field.hide }"></ng-container>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
            <ng-container *ngIf="subSection.hasChildSubSectons">
                <ng-container *ngFor="let childSubSection of subSectionRecord.childSubSections">
                    <ng-container *ngTemplateOutlet="integrationSubSections; context: { record: record, subSection: childSubSection, subSectionRecord: subSectionRecord }">
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
    </ng-template>
</ng-container>

<ng-template #recordsFormStructure let-record="record" let-subSection="subSection">
    {{subSection | json}}
</ng-template>

<ng-template #postManStructure let-record="record" let-subSection="subSection" let-subSectionRecord="subSectionRecord">
    <app-integrations-postman-structure [subTenants]="subTenants" [masterTables]="masterTables" [record]="record" [subSection]="subSection" [hideField]="hideField" [subSectionRecord]="subSectionRecord" [masterTableColumns]="masterTableColumns">
    </app-integrations-postman-structure>
</ng-template>

<ng-template #formStructure let-record="record" let-subSection="subSection">
    <div class="row">
        <ng-container *ngIf="subSection.fields && subSection.fields.length > 0">
            <ng-container *ngFor="let field of subSection.fields; let i = index">
                <ng-container *ngIf="!hideField(field, record) && field.show !== false">
                    <div class="inboundIntegrationFieldContainer col-6">
                        <ng-container *ngTemplateOutlet="integrationField; context: { record: record, field: field, hide: hideField(field, record) }">
                        </ng-container>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
</ng-template>

<ng-template #flexFormStructure let-record="record" let-subSection="subSection">
    <div class="flexFormContainer">
        <ng-container [ngSwitch]="subSection.id">
            <ng-container *ngSwitchCase="'schedulerExpression'">
                <ng-container *ngFor="let field of subSection.fields; let i = index">
                    <cron-editor [(cron)]="field.value" [options]="cronOptions"></cron-editor>
                </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'cronExpression'">
                <ng-container *ngFor="let field of subSection.fields; let i = index">
                    <cron-editor [(cron)]="field.value" [options]="cronOptions"></cron-editor>
                </ng-container>
            </ng-container>
            <ng-container *ngSwitchDefault>
                <ng-container *ngIf="subSection.fields && subSection.fields.length > 0">
                    <ng-container *ngFor="let field of subSection.fields; let i = index">
                        <ng-container *ngIf="!hideField(field, record) && field.show !== false">
                            <div class="inboundIntegrationFieldContainer">
                                <ng-container *ngTemplateOutlet="integrationField; context: { record: record, field: field }">
                                </ng-container>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
</ng-template>

<ng-template #integrationField let-record="record" let-field="field" let-hide="hide" let-subSectionRecord="subSectionRecord">
    <app-integrations-field-template [record]="record" [field]="field" [masterTableColumns]="masterTableColumns" [hide]="hide" [sources]="sources" [subSectionRecord]="subSectionRecord" [subTenants]="subTenants" [masterTables]="masterTables" (apiConfigChange)="apiConfigChange($event, subSectionRecord)" (configNameChanged)="configNameChanged($event, subSectionRecord)" (chainChanged)="chainChanged($event, field)" (transformationsChanged)="transformationsChanged($event, field)" (getMasterColumns)="getMasterColumns($event)"></app-integrations-field-template>
</ng-template>
