import { Component, OnDestroy, OnInit, SecurityContext } from '@angular/core';
import {
    MatLegacySnackBar as MatSnackBar,
    MatLegacySnackBarConfig as MatSnackBarConfig,
    MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition,
    MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition,
} from '@angular/material/legacy-snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { EnComplySnackbarMessagesComponent } from '@encomply/shared/components';
import { environment } from '@env';
import { Store } from '@ngrx/store';
import { BroadcasterService } from 'ng-broadcaster';
import { Subscription } from 'rxjs';
import { CommonUtilsService, SetActiveLanguage, UtilsService } from 'taxilla-library';

import { RootScope } from '../../services/rootscope.service';
import { CustomsnackbarComponent } from '../customsnackbar/customsnackbar.component';

@Component({
    selector: 'loader',
    templateUrl: './loader.component.html',
    styles: ['.IELoader { width: 26%; }'],
})
export class LoaderComponent implements OnInit, OnDestroy {
    isIE: boolean;
    clearCallsSubscription: Subscription;
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';
    logoutCompletedSubscription: Subscription;

    constructor(
        public R: RootScope,
        public _broadcaster: BroadcasterService,
        public _libUtils: UtilsService,
        public _sanitizer: DomSanitizer,
        public snackBar: MatSnackBar,
        public _commonUtils: CommonUtilsService,
        public store$: Store
    ) {}

    public isIEBrowser = (): void => {
        this.isIE = this._libUtils.isIEBrowser();
    };

    public showLoading = (message?: string, id?: string, variable?: string): string => {
        if (variable) {
            setTimeout(() => {
                this.R.loadingCall[variable] = true;
            }, 100);
            this._broadcaster.broadcast('message', { type: 'loadingCall', message: variable, variable: true });
        }
        if (!variable || ['subscribedServices'].indexOf(variable) > -1) {
            if (message) {
                if (!id) {
                    id = this._libUtils.guid(8);
                }
                if (this.R.loadingCalls) {
                    this.R.loadingCalls.push({
                        id,
                        message,
                    });
                }
            } else {
                this.R.loadingCalls && this.R.loadingCalls.push('loading');
            }
            return id;
        }
    };

    public hideLoading = (id?: string, variable?: string) => {
        if (variable) {
            setTimeout(() => {
                this.R.loadingCall[variable] = false;
            }, 100);
            this._broadcaster.broadcast('message', { type: 'loadingCall', message: variable, variable: false });
        }
        if (!variable || ['subscribedServices'].indexOf(variable) > -1) {
            if (this.R.loadingCalls && this.R.loadingCalls.length === 0) {
            }
            setTimeout(() => {
                if (id) {
                    this.R.loadingCalls?.splice(
                        this.R.loadingCalls.findIndex((call) => call.id === id),
                        1
                    );
                } else {
                    this.R.loadingCalls?.splice(0, 1);
                }
            }, 1000);
        }
    };

    waitTillLoadingCompleted = (callback: (...args: any) => void): void => {
        if (this.R.loadingCalls && this.R.loadingCalls.length > 0) {
            setTimeout(() => this.waitTillLoadingCompleted(callback), 500);
        } else {
            callback();
        }
    };

    /**
     * pushing a success growl message
     */
    alertNotify = (message, timeout?) => {
        const config = new MatSnackBarConfig();
        config.verticalPosition = this.verticalPosition;
        config.horizontalPosition = this.horizontalPosition;
        config.duration = timeout || 4000;
        config.panelClass = ['notifyPanelClass'];
        this.snackBar.open(message, undefined, config);
    };

    /**
     * pushing a error growl message
     */
    alertError = (message, timeout?) => {
        const config = new MatSnackBarConfig();
        config.verticalPosition = this.verticalPosition;
        config.horizontalPosition = this.horizontalPosition;
        config.duration = timeout || 6000;
        config.panelClass = ['failurePanelClass'];
        if (Array.isArray(message)) {
            config.data = this._sanitizer.sanitize(SecurityContext.HTML, message.join('<br>'));
            this.snackBar.openFromComponent(EnComplySnackbarMessagesComponent, config);
        } else {
            this.snackBar.open(message, undefined, config);
        }
    };

    /**
     * pushing a warning growl message
     */
    alertWarning = (message, timeout?) => {
        const config = new MatSnackBarConfig();
        config.verticalPosition = this.verticalPosition;
        config.horizontalPosition = this.horizontalPosition;
        config.duration = timeout || 4000;
        config.panelClass = ['warningPanelClass'];
        this.snackBar.open(message, undefined, config);
    };

    /**
     * pushing a success growl message
     */
    alertSuccess = (message, timeout?, hasAction?, actionText?, onActionCallBack?) => {
        const config = new MatSnackBarConfig();
        config.verticalPosition = this.verticalPosition;
        config.horizontalPosition = this.horizontalPosition;
        config.duration = timeout || 4000;
        config.panelClass = ['successPanelClass', 'word-break-all'];
        if (hasAction) {
            this.snackBar
                .open(message, actionText, config)
                .onAction()
                .subscribe(() => {
                    onActionCallBack && onActionCallBack();
                });
        } else {
            this.snackBar.open(message, undefined, config);
        }
    };

    alertCustomSuccess = (message, timeout?, actionText?, onActionCallBack?) => {
        const snackBarRef = this.snackBar.openFromComponent(CustomsnackbarComponent, {
            data: {
                message,
                action: actionText,
            },
            panelClass: ['successPanelClass', 'word-break-all'],
            duration: timeout || 4000,
        });
        snackBarRef.onAction().subscribe(() => {
            onActionCallBack && onActionCallBack();
        });
    };

    alertCustomFailure = (message, timeout?, actionText?, onActionCallBack?) => {
        const snackBarRef = this.snackBar.openFromComponent(CustomsnackbarComponent, {
            data: {
                message,
                action: actionText,
                icon: 'close',
                iconText: 'Failed:',
            },
            panelClass: ['errorPanelClass', 'word-break-all'],
            duration: timeout || 6000,
        });
        snackBarRef.onAction().subscribe(() => {
            onActionCallBack && onActionCallBack();
        });
    };

    alertCustomWarning = (message, timeout?, actionText?, onActionCallBack?) => {
        const snackBarRef = this.snackBar.openFromComponent(CustomsnackbarComponent, {
            data: {
                message,
                action: actionText,
                icon: 'priority_high',
                iconText: 'Warning:',
            },
            panelClass: ['errorPanelClass', 'word-break-all'],
            duration: timeout || 4000,
        });
        snackBarRef.onAction().subscribe(() => {
            onActionCallBack && onActionCallBack();
        });
    };

    public setLanguage = (language: string) => {
        this._commonUtils.setCookie('language', language);
        this._commonUtils.setActiveLanguage(language);
        this.store$.dispatch(
            SetActiveLanguage({
                language: language as any,
            })
        );
    };

    public getActiveLanguage = (): string => {
        const localeLanguage = navigator.language?.substring(0, navigator.language.indexOf('-'));
        const languageFromCookie = this._commonUtils.getCookie('language');
        const defaultLanguage = this._commonUtils.getDefaultLanguage();
        const languageToRetun = languageFromCookie || localeLanguage || environment?.defaultLanguage || defaultLanguage || 'en';
        const supportedLanguages = (this._commonUtils.getAvailableLanguages() as any)?.reduce((langs, config) => {
            langs.push(config.id);
            return langs;
        }, []);
        return supportedLanguages?.includes(languageToRetun) ? languageToRetun : 'en';
    };

    ngOnInit() {
        this.setLanguage(this.getActiveLanguage());
        this.isIEBrowser();

        this.clearCallsSubscription = this._broadcaster.on('clearLoading')?.subscribe((data: any) => {
            const countToSplice = data?.subscribersCount;
            if (countToSplice !== undefined) {
                setTimeout(() => this.R.loadingCalls.splice(0, countToSplice), 1000);
            } else {
                setTimeout(() => (this.R.loadingCalls = []), 1000);
            }
        });

        this.logoutCompletedSubscription = this._broadcaster.on('logoutCompleted')?.subscribe(() => {
            this.R.clearRootScope();
        });

        this._broadcaster
            .on('message')
            ?.subscribe(
                (event: {
                    type:
                        | 'success'
                        | 'error'
                        | 'note'
                        | 'showLoading'
                        | 'hideLoading'
                        | 'waitForLoading'
                        | 'customFailure'
                        | 'customWarning'
                        | 'customSuccess'
                        | 'setLanguage';
                    message: string;
                    timeout: number;
                    title: string;
                    id?: string;
                    variable?: string;
                    callback: (...args: any) => void;
                    hasAction?: boolean;
                    actionText?: string;
                }) => {
                    switch (event.type) {
                        case 'error':
                            this.alertError(event.message, event.timeout);
                            break;
                        case 'success':
                            this.alertSuccess(event.message, event.timeout, event.hasAction, event.callback);
                            break;
                        case 'customSuccess':
                            this.alertCustomSuccess(event.message, event.timeout, event.actionText, event.callback);
                            break;
                        case 'customFailure':
                            this.alertCustomFailure(event.message, event.timeout, event.actionText, event.callback);
                            break;
                        case 'customWarning':
                            this.alertCustomWarning(event.message, event.timeout, event.actionText, event.callback);
                            break;
                        case 'note':
                            this.alertNotify(event.message, event.timeout);
                            break;
                        case 'showLoading':
                            this.showLoading(event.message, event.id, event.variable);
                            break;
                        case 'hideLoading':
                            this.hideLoading(event.id, event.variable);
                            break;
                        case 'waitForLoading':
                            this.waitTillLoadingCompleted(event.callback);
                            break;
                        case 'setLanguage':
                            this.setLanguage(event.id);
                            break;
                    }
                }
            );
    }

    ngOnDestroy(): void {
        this.clearCallsSubscription?.unsubscribe?.();
        this.logoutCompletedSubscription?.unsubscribe?.();
    }
}
