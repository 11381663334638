export class AuthorizationSystem {
    id: string = '';
    name: string = '';
    accessTokenURL: string = '';
    authorizationURL: string = '';
    restApiBaseUrl: string = '';
    callbackURL: string = '';
    clientAuthentication: string = '';
    clientId: string = '';
    clientSecret: string = '';
    refreshTokenURL: string = '';
    scope: string = '';
    active: boolean = true;
    method: string = '';
    createdOn: string = undefined;
    updatedOn: string = undefined;
    sslEnabled: boolean = false;
    encryptionEnabled: boolean = false;
    ssl_certificates = {};
    encryption_key = [];
}
