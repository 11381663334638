import { createReducer, on } from '@ngrx/store';

import {
    SetEninvoiceSelectedAppDetails,
    SetEninvoiceSelectedInstanceId,
    SetEninvoiceSelectedRequestId,
} from '../actions/eninvoice.actions';
import * as sessionActions from '../actions/session.actions';
import { eninvoiceState } from '../states/eninvoice.state';

export const ENINVOICE_REDUCER = createReducer(
    eninvoiceState,
    on(SetEninvoiceSelectedAppDetails, (state, action) => {
        return {
            ...state,
            selectedAppRestApiName: action.appRestApiName,
            selectedBridgeRestApiName: action.bridgeRestApiName,
            selectedReportName: action.reportName,
        };
    }),
    on(SetEninvoiceSelectedInstanceId, (state, { instanceId }) => {
        return {
            ...state,
            selectedInstanceId: instanceId,
        };
    }),
    on(SetEninvoiceSelectedRequestId, (state, { requestId }) => {
        return {
            ...state,
            selectedRequestId: requestId,
        };
    }),
    on(sessionActions.SessionLogoutSuccessfull, () => eninvoiceState)
);

export const ENINVOICE_REDUCER_KEY = 'eninvoice';
