import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService, AssetService, CommonUtilsService } from 'taxilla-library';

import { MastersResponseRecord } from '../../interface/master-records-response.interface';
import { MastersMetaData } from '../../models/masters/mastersmetadata.class';
import { Record } from '../../models/record/record.class';
import { RecordField } from '../../models/record/recordfield.class';

@Component({
    selector: '[EntityRecordTemplate]',
    templateUrl: './entity-record-template.component.html',
    styleUrls: ['./entity-record-template.component.scss'],
})
export class EntityRecordTemplateComponent implements OnInit {
    @Input() eventType: string;
    @Input() public record: Record;
    @Input() public sections: {
        name: string;
        fields: { name: string; id: string }[];
        colSpan: number;
        rowSpan: number;
    }[];
    @Input() showChildEntities: boolean;
    @Input() parentMasterTables: string;
    @Input() public masterRecordsResponse: {
        [tableId: string]: {
            [pipedColumnKeys: string]: MastersResponseRecord[];
        };
    };
    @Input() public parentLookupsData: string;
    @Input() public service: AssetService;
    @Input() public lookupsResponse: {
        [condition: string]: RecordField['lookupValues'];
    };

    @Output() toggleShowChildEntities = new EventEmitter();
    @Output() toggleErrors = new EventEmitter();
    @Output() removeRecord = new EventEmitter();
    @Output() onRecordSelectToggle: EventEmitter<void> = new EventEmitter<void>();
    @Output() lookUpUpdated = new EventEmitter();
    @Output() openAttachmentEvent = new EventEmitter<any>();
    @Output() openGridEvent = new EventEmitter<any>();
    @Output() editTextAreaEvent = new EventEmitter<any>();

    private organizationId = '';

    constructor(private _api: ApiService, private _commonUtils: CommonUtilsService) {}

    public selectedMasterRecord = async (event: { field: RecordField; masterRecord: MastersResponseRecord }) => {
        const masterTables = JSON.parse(this.record['parentMasterTablesData'] || '{}');
        const tableId = event.field.masterData.tableUid;
        const columnId = event.field.masterData.columnName;
        const tableMeta = this.record.masters?.getTable(tableId);
        const { keyString, keyFieldIdsVsColumnIds } = this.getKeyRecordFields(tableMeta, event.field);
        const dependentFields = this.record.fields
            .filter((field) => field.masterData?.columnRefMetadatas?.find((ref) => ref.fieldUid === event.field.id) !== undefined)
            .sort((a, b) => a.masterData.columnRefMetadatas.length - b.masterData.columnRefMetadatas.length);
        if (!masterTables?.[tableId]) {
            masterTables[tableId] = {};
        }
        if (!masterTables[tableId][keyString]) {
            masterTables[tableId][keyString] = {
                fieldIdVsColumnId: keyFieldIdsVsColumnIds,
                masterRecord: {},
            };
        }
        const masterRecordData = masterTables[tableId][keyString];
        event.masterRecord?.fields.forEach((field) => {
            masterRecordData.masterRecord[field.id] = field?.value?.trim()?.length > 0 ? field?.value : undefined;
        });
        this.clearNextKeyColumnsData(tableMeta, masterRecordData.masterRecord, columnId);
        this.clearNextDependentFields(dependentFields);
        if (this.allColumnsExist(tableMeta, masterRecordData.masterRecord)) {
            const keyColumnsString = tableMeta.getPrimaryColumns().reduce((columnString, column, index) => {
                if (index > 0) {
                    columnString += '|';
                }
                columnString += `${column}:${masterRecordData.masterRecord[column]}`;
                return columnString;
            }, '');
            if (!this.masterRecordsResponse?.[tableId]?.[keyColumnsString]) {
                if (!this.masterRecordsResponse) this.masterRecordsResponse = {};
                if (!this.masterRecordsResponse?.[tableId]) this.masterRecordsResponse[tableId] = {};
                this.masterRecordsResponse[tableId][keyColumnsString] = [];
            }
            let record = this.masterRecordsResponse?.[tableId]?.[keyColumnsString]?.[0];
            if (!record && event.masterRecord) {
                record = await this.getMasterRecord(tableMeta.masterMetadata.uid, event.masterRecord);
                this.masterRecordsResponse[tableId][keyColumnsString][0] = record;
            }
            record?.fields.forEach((field) => (masterRecordData.masterRecord[field.id] = field.value));
            dependentFields.forEach((field) => {
                field.value = masterRecordData.masterRecord[field.masterData.columnName];
            });
        }
        this.record['parentMasterTablesData'] = JSON.stringify(masterTables);
    };

    public openGrid = (field: RecordField) => {
        this.openGridEvent.emit(field);
    };

    editTextArea = (field: RecordField) => {
        this.editTextAreaEvent.emit(field);
    };

    public openAttachment = (field: RecordField) => {
        this.openAttachmentEvent.emit(field);
    };

    private getMasterRecord = (tableId: string, masterRecord: MastersResponseRecord) => {
        return new Promise<MastersResponseRecord>((resolve) => {
            const values: { [property: string]: { newValue: string } } = {};
            masterRecord?.fields.forEach((field) => (values[field.id] = { newValue: field.value }));
            this._api.masters.getMasterRecordWithprimaryValue(
                {
                    masterDataRow: {
                        columnValues: values,
                        tableId: tableId,
                        unitId: this.organizationId,
                    },
                    masterSearchDetail: {
                        pageNo: 0,
                        searchString: '',
                    },
                },
                {
                    successCallback: (response) => {
                        resolve(response?.[0]);
                    },
                    failureCallback: () => {},
                }
            );
        });
    };

    private getKeyRecordFields = (tableMeta: MastersMetaData, eventField: RecordField) => {
        const refFieldIds = [];
        const keyFieldIds = [];
        const keyFieldIdsVsColumnIds = {};
        tableMeta.getPrimaryColumns();
        eventField.masterData.columnRefMetadatas.forEach((ref) => refFieldIds.push(ref.fieldUid));
        refFieldIds.push(eventField.id);
        this.record.fields
            .filter((field) => field.hasMasterLookup && refFieldIds.includes(field.id))
            .sort((a, b) => a.masterData.columnRefMetadatas.length - b.masterData.columnRefMetadatas.length)
            .forEach((field) => {
                keyFieldIds.push(field.id);
                keyFieldIdsVsColumnIds[field.id] = field.masterData.columnName;
            });
        return { keyString: keyFieldIds.join('|'), keyFieldIdsVsColumnIds };
    };

    private clearNextKeyColumnsData = (tableMeta: MastersMetaData, data: { [property: string]: string }, columnId: string) => {
        const primaryColumns = tableMeta.getPrimaryColumns();
        const columnIndex = primaryColumns.indexOf(columnId);
        primaryColumns.forEach((column, index) => {
            if (index <= columnIndex) {
                return;
            }
            data[column] = undefined;
        });
        tableMeta
            .getAllColumnIds()
            .filter((id) => !primaryColumns.includes(id))
            .forEach((id) => (data[id] = undefined));
    };

    private clearNextDependentFields = (fields: RecordField[]) => {
        fields.filter((field) => field?.hasMasterLookup).forEach((field) => (field.value = undefined));
    };

    private allColumnsExist = (tableMeta: MastersMetaData, data: { [property: string]: string }) => {
        const keyColumns = tableMeta.getPrimaryColumns();
        return keyColumns?.find((columnId) => data[columnId] === undefined) === undefined;
    };

    public selectedLookupValue = (event: { fieldId: string; fieldValue: any }) => {
        const valuesMap = JSON.parse(this.parentLookupsData);
        if (!valuesMap[this.record.entityId]) {
            valuesMap[this.record.entityId] = {};
        }
        const entityMap = valuesMap[this.record.entityId];
        entityMap[event.fieldId] = event.fieldValue;
        this.parentLookupsData = JSON.stringify(valuesMap);
        this.lookUpUpdated.emit(this.parentLookupsData);
    };

    private buildLookupsObject = () => {
        const valuesMap = JSON.parse(this.parentLookupsData);
        this.record.id?.length > 0 &&
            this.record.fields.forEach((field) => {
                if (!valuesMap[this.record.entityId]) {
                    valuesMap[this.record.entityId] = {};
                }
                const entityMap = valuesMap[this.record.entityId];
                entityMap[field.id] = field.value;
            });
        this.parentLookupsData = JSON.stringify(valuesMap);
        this.lookUpUpdated.emit(this.parentLookupsData);
    };

    ngOnInit(): void {
        this.organizationId = this._commonUtils.getFromStorage('currentOrganizationId');
        this.buildLookupsObject();
    }
}
