import { Component, ViewChild } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatDrawer } from '@angular/material/sidenav';
import { RootScope } from '@enreconcile/services/rootscope/rootscope.service';
import { translate } from '@ngneat/transloco';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    CommonUtilsService,
    ConfirmationDialogService,
    NewProcessService,
    TableColumn,
    TableData,
    TableRecordAction,
    UtilsService,
} from 'taxilla-library';

@Component({
    selector: 'app-template-list-table',
    templateUrl: './template-list-table.component.html',
    styleUrls: ['./template-list-table.component.scss'],
})
export class TemplateListTableComponent {
    private unsubscribe = new Subject<void>();
    private restApiName = '';
    public templates = new MatTableDataSource<any>();
    public selected: boolean;
    public allTableColumns: BehaviorSubject<TableColumn[]> = new BehaviorSubject([
        {
            name: translate('Select'),
            type: 'SELECTRECORD',
            icon: undefined,
            id: 'select',
            hideInAllColumnsList: false,
            hide: false,
            showCheckboxInHeader: true,
        },
        {
            name: translate('Template Name'),
            type: 'text',
            icon: undefined,
            id: 'templateName',
            hideInAllColumnsList: false,
            hide: false,
            showCheckboxInHeader: false,
        },
        {
            name: translate('Created Date and Time'),
            type: 'text',
            icon: undefined,
            id: 'creationTime',
            hideInAllColumnsList: false,
            hide: false,
            showCheckboxInHeader: false,
        },
        {
            name: translate('Actions'),
            type: 'text',
            icon: undefined,
            id: 'actions',
            hideInAllColumnsList: false,
            hide: false,
            showCheckboxInHeader: false,
        },
    ]);
    public tableData: BehaviorSubject<TableData[]> = new BehaviorSubject([]);
    public selectedColumns: string[] = ['select', 'templateName', 'creationTime'];
    public selectAll;
    public template: any;
    public recordActions: TableRecordAction[] = [];
    public selectedTemplates = [];

    @ViewChild('appSettingsDrawer') appSettingsDrawer: MatDrawer;

    constructor(
        private _utils: UtilsService,
        public R: RootScope,
        private confirmationService: ConfirmationDialogService,
        private _newProcessService: NewProcessService
    ) {}

    public navigateToNewProcess = () => {
        this.appSettingsDrawer?.opened && this.appSettingsDrawer?.toggle();
        this._utils.navigateToCreateNew(undefined, this.R.current.app.restApiName, undefined);
    };

    public navigateToEdit = (template: any) => {
        this._utils.navigateToCreateNew(undefined, this.R.current.app.restApiName, undefined, template.templateId);
    };

    private translateMsg = (msg: string): string => translate('' + msg);

    public deleteSelectedTemplates = () => {
        this.deleteConfirmation({ isMultiDelete: true });
    };

    public deleteTemplate = (template: any) => {
        this.deleteConfirmation({ isMultiDelete: false, template: template });
    };

    private deleteConfirmation = (data: any) => {
        const confirmConfig = {
            title: this.translateMsg('Delete Confirmation'),
            message: data.isMultiDelete
                ? this.translateMsg('Are you sure you want to delete all selected templates?')
                : this.translateMsg('Are you sure you want to delete?'),
            btnOkText: this.translateMsg('Ok'),
            btnCancelText: this.translateMsg('Cancel'),
        };
        this.confirmationService
            .confirm(confirmConfig.title, confirmConfig.message, confirmConfig.btnOkText, confirmConfig.btnCancelText)
            .subscribe((action) => {
                if (action) {
                    if (data.isMultiDelete) {
                        const promises = [];
                        this.selectedTemplates.forEach((template) => {
                            promises.push(this._newProcessService.deleteReconTemplate(this.restApiName, template, true));
                        });
                        Promise.all(promises).then((messages) => {
                            const sucessMessages = messages
                                .filter((message) => message.success)
                                .reduce((messagesList, message) => {
                                    messagesList.push(message.message);
                                    return messagesList;
                                }, []);
                            const failureMessages = messages
                                .filter((message) => !message.success)
                                .reduce((messagesList, message) => {
                                    messagesList.push(message.message);
                                    return messagesList;
                                }, []);
                            sucessMessages?.length > 0 &&
                                this._utils.alertSuccess(`[ ${sucessMessages} ] ${translate('templates are deleted successfully.')}`);
                            failureMessages?.length > 0 &&
                                this._utils.alertError(
                                    `[ ${failureMessages} ] ${translate('templates are got failed while deleting.')}`,
                                    10000
                                );
                            this.selectedTemplates = [];
                        });
                    } else {
                        this._newProcessService.deleteReconTemplate(this.restApiName, data.template, false);
                    }
                }
            });
    };

    private setTableActions = () => {
        this.recordActions.push(
            {
                displayName: 'Delete',
                iconName: 'delete',
                onActionTrigger: this.deleteTemplate,
            },
            {
                displayName: 'Edit',
                iconName: 'edit',
                onActionTrigger: this.navigateToEdit,
            }
        );
    };

    public selectectedTemplate = (data: any) => {
        if (data.selected) {
            this.selectedTemplates.push(data);
        } else {
            this.selectedTemplates = this.selectedTemplates.filter((template) => template.templateId !== data.templateId);
        }
    };

    public ngOnInit(): void {
        this.R.reconciliationConfiguration.pipe(takeUntil(this.unsubscribe)).subscribe((configuration: any) => {
            this.restApiName = configuration?.restapiname;
            this._newProcessService.getReconTemplates(this.restApiName);
        });
        this._newProcessService.reconProcesssTemplates.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
            this.templates.data = data;
            let modifiedData = this.modifyData(data);
            this.tableData.next(modifiedData);
        });
        this.setTableActions();
    }

    private modifyData = (data: any[]) => {
        data.forEach((record) => {
            if (typeof record.creationTime === 'number') {
                record.creationTime = CommonUtilsService.transformDateToLocale(
                    new Date(record.creationTime) as any,
                    'DATE',
                    'DD-MM-YYYY HH:MM:SS AM',
                    true
                );
            }
        });
        return data;
    };

    public ngOnDestroy(): void {
        this.unsubscribe?.next();
        this.unsubscribe?.complete();
    }
}
