import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { BroadcasterService } from 'ng-broadcaster';
import { Observable, Subject } from 'rxjs';
import { shareReplay, takeUntil } from 'rxjs/operators';

import { CommonUtilsService } from '../commonutils/common-utils.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
    providedIn: 'root',
})
export class CommunicationsService {
    protected cache: { [property: string]: Observable<HttpEvent<any>> } = {};
    protected ngUnsubscribe: Subject<void> = new Subject<void>();
    protected callsToCache = [
        /asset\/metadata\/lookup-data:POST/g,
        /metadata\/assets\/([a-zA-Z0-9-]){0,37}\w+:GET/g,
        // /organizations\/([a-zA-Z0-9-]){0,40}\/masters\/metadata\?masterName=([a-zA-Z0-9-_]){0,37}\w+:GET/g,
    ];
    protected unrestrictedCalls = [/associated-orgs:GET/g, /application-properties:GET/g, /version\.json/g];

    constructor(public http: HttpClient, public _utils: UtilsService, private _broadcaster: BroadcasterService) {}

    get = (slug, s, f, p?, data?, custom?) => {
        this.call('GET', slug, s, f, p, data, custom);
    };

    post = (slug, data, s, f, p?, custom?) => {
        this.call('POST', slug, s, f, p, data, custom);
    };

    put = (slug, data, s, f, p?, custom?) => {
        this.call('PUT', slug, s, f, p, data, custom);
    };

    delete = (slug, data, s, f, p?, custom?) => {
        this.call('DELETE', slug, s, f, p, data, custom);
    };

    public call = (method, url, s, f, _p, payload?, custom?) => {
        if (!environment.taxilla_api) {
            return;
        }
        const stringifiedPayload = payload !== undefined ? JSON.stringify(payload) : '';
        const request = this.makeHttpRequest(method, url, payload, custom, stringifiedPayload);
        const guestUrl = environment['encomply-ui'] + '/collaboration';
        const currentUrl = window.location.href;
        if (currentUrl.indexOf(guestUrl) === -1) {
            this.cacheRequest(url, method, stringifiedPayload, request);
        }
        this.parseResponse(request, s, f, { method, stringifiedPayload, url });
    };

    protected makeHttpRequest = (
        method: string,
        url: string,
        payload: any,
        custom: any,
        stringifiedPayload: string
    ): Observable<HttpEvent<any>> => {
        const cachedRequest = this.getCachedRequest(url, method, stringifiedPayload);
        if (cachedRequest) {
            return cachedRequest;
        }
        if (url && url.indexOf('organizations/switch') > -1) {
            this._utils.broadcast('clearLoading', { sessionExists: true, subscribersCount: this.ngUnsubscribe.observers.length });
            this.ngUnsubscribe.next();
        }
        const request = this.createNewRequest(method, url, payload, custom);
        return request;
    };

    protected getCachedRequest = (url: string, method: string, stringifiedPayload: string): Observable<HttpEvent<any>> => {
        let cachedRequest: Observable<HttpEvent<any>>;
        const stringToCheck = url + ':' + method;
        for (let i = 0; i < this.callsToCache.length; i++) {
            if (new RegExp(this.callsToCache[i]).test(stringToCheck)) {
                cachedRequest = this.cache[url + ':' + method + ':' + stringifiedPayload];
                break;
            }
        }
        return cachedRequest;
    };

    protected createNewRequest = (method: string, url: string, payload: any, custom: any): Observable<HttpEvent<any>> => {
        const httpRequest = new HttpRequest(method, url, payload, {
            headers: new HttpHeaders((custom && custom.headers) || {}),
            reportProgress: (custom && custom['reportProgress']) || false,
            responseType: (custom && custom['responseType']) || 'json',
        });
        let found = false;
        const stringToCheck = url + ':' + method;
        this.unrestrictedCalls.forEach((regex) => {
            found = found || new RegExp(regex).test(stringToCheck);
        });
        if (found) {
            return this.http.request(httpRequest).pipe(shareReplay(1));
        } else {
            return this.http.request(httpRequest).pipe(takeUntil(this.ngUnsubscribe)).pipe(shareReplay(1));
        }
    };

    protected cacheRequest = (url: string, method: string, stringifiedPayload: string, request: Observable<HttpEvent<any>>) => {
        const stringToCheck = url + ':' + method;
        for (let i = 0; i < this.callsToCache.length; i++) {
            if (new RegExp(this.callsToCache[i]).test(stringToCheck)) {
                this.cache[url + ':' + method + ':' + stringifiedPayload] = request;
                break;
            }
        }
    };

    protected parseResponse = (
        request: Observable<HttpEvent<any>>,
        s: (...args: any[]) => void,
        f: (...args: any[]) => void,
        { url, method, stringifiedPayload }: { url: string; method: string; stringifiedPayload: string }
    ) => {
        request.subscribe(
            (event: HttpEvent<any>) => {
                if (event) {
                    switch (event.type) {
                        case HttpEventType.Sent:
                            // console.log('Request sent!');
                            break;
                        case HttpEventType.ResponseHeader:
                            // console.log('Response header received!');
                            break;
                        case HttpEventType.DownloadProgress:
                            // console.log(`Download progress`);
                            break;
                        case HttpEventType.Response:
                            if (event.status === 200 || event.status === 201 || event.status === 202) {
                                if (event.url.includes('secret-questions')) {
                                    const seedToken = event.headers.get('seed-token') || event.headers.get('Seed-Token');
                                    if (seedToken) {
                                        this._broadcaster.broadcast('secretSeedToken', seedToken);
                                    }
                                }
                                if (event.body || event.url.indexOf('logout') > -1) {
                                    if (
                                        event.body?.success !== undefined &&
                                        (event.body?.success === false || event.body?.success === 'false')
                                    ) {
                                        f(event.body);
                                    } else if (event.url.indexOf('logout') > -1) {
                                        this.cancelSession(true);
                                    } else {
                                        s(event.body);
                                    }
                                } else {
                                    f(event.body);
                                }
                            } else {
                                f(event.body);
                            }
                            break;
                    }
                }
            },
            (e: HttpErrorResponse) => {
                if (e instanceof HttpErrorResponse) {
                    delete this.cache[url + ':' + method + ':' + stringifiedPayload];
                    const currentUrl = window.location.href;
                    const session = CommonUtilsService.getFromStorage('session');
                    if (e.status === 401 && (currentUrl.indexOf(environment['auth-ui']) === -1 || session)) {
                        const guestUrl = environment['encomply-ui'] + '/collaboration';
                        if (currentUrl.indexOf(guestUrl) > -1) {
                            f(e?.error);
                        } else {
                            this.cancelSession(false);
                        }
                    } else if (e?.status === 200 && e?.error?.text?.includes('<!doctype html>')) {
                        this._utils.hideLoading();
                    } else {
                        f(e.error);
                    }
                } else {
                    this._utils.hideLoading();
                }
            }
        );
    };

    private cancelSession = (cancelLogoutBroadcast?: boolean) => {
        this.ngUnsubscribe.next();
        this._broadcaster.broadcast('clearLoading');
        if (cancelLogoutBroadcast) {
            this._broadcaster.broadcast('logout', { noRedirect: true, hidePopup: true });
        } else {
            this._broadcaster.broadcast('logout', { noRedirect: false, hidePopup: false });
        }
    };

    ngOnDestroy(): void {
        this.ngUnsubscribe && this.ngUnsubscribe.complete();
    }
}
