import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacySelectionList as MatSelectionList } from '@angular/material/legacy-list';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { DomSanitizer } from '@angular/platform-browser';
import { translate } from '@ngneat/transloco';
import { BroadcasterService } from 'ng-broadcaster';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { delay, filter, takeUntil } from 'rxjs/operators';

import { PaginationInterface } from '../../../interface/pagination.interface';
import { TableColumn } from '../../../interface/table-column.interface';
import { TableData } from '../../../interface/table-data.interface';
import { TableRecordAction } from '../../../interface/table-record-action.interface';
import { TableCellDataTypesInterface } from '../../../interface/tablecelldatatype.interface';
import { CommonUtilsService } from '../../../services/commonutils/common-utils.service';
import { UtilsService } from '../../../services/utils/utils.service';

@Component({
    selector: 'material-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class MaterialTableComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
    @ViewChild('selectedOrgMultipleList', { static: false }) subscribeOrg: MatSelectionList;
    @ViewChild('selectedOrgAssetMultipleList', { static: false }) subscribeAsset: MatSelectionList;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

    @Input() data: BehaviorSubject<TableData[]>;
    @Input() allTableColumns: BehaviorSubject<TableColumn[]>;
    @Input() selectedColumns: string[] = [];
    @Input() showRowExpansion: boolean;
    @Input() noRecordMsg: string;
    @Input() wordWrap = false;
    @Input() pagination: BehaviorSubject<PaginationInterface>;
    @Input() hidePaginator;
    @Input() defaultPaginator;
    @Input() hideTableColumns;
    @Input() columnSearchRequired = false;
    @Input() recordActions: TableRecordAction[];
    @Input() noLog = false;
    @Input() columnselectSearchRequired = false;
    @Input() disablePrevious = false;
    @Input() disableNext = false;
    @Input() newCustomPaginator = false;
    @Input() customPageIndex;
    @Input() pageIndexSelected: number;
    @Input() customSize = [10, 20, 50, 100];
    @Input() sizeSelected = 10;
    @Input() loading: boolean;
    @Input() cellDataTypes: TableCellDataTypesInterface;
    @Input() passwordColumns?: any[]; // This is to identify the columns of type password
    @Input() enableColumnEdit: boolean;
    @Input() public columnsToUpdate: { [property: string]: boolean } = {};
    @Input() fieldsDisabledForEdit: string[] = [];
    @Input() noSticky: boolean;
    @Input() showCount: boolean;

    @Output() fetchItemsInPage = new EventEmitter();
    @Output() refreshItemsWithNewPageSize = new EventEmitter();
    @Output() fetchItemsInPageWithPageIndex = new EventEmitter();
    @Output() onSelectCheckbox = new EventEmitter();
    @Output() onSingleClickEvent = new EventEmitter();
    @Output() onDoubleClickEvent = new EventEmitter();
    @Output() recordSelectedEvent = new EventEmitter();
    @Output() filesChanged = new EventEmitter();
    @Output() DownloadSourceTemplate = new EventEmitter();
    @Output() downloadFile = new EventEmitter();
    @Output() emitTableValues = new EventEmitter();
    @Output() editColumnEvent = new EventEmitter();

    selectedAsset = new UntypedFormControl();
    searchColumnControl = new UntypedFormControl();
    allColumns: TableColumn[] = [];
    clonedColumns: string[];
    dataSource = new MatTableDataSource([]);
    selection = new SelectionModel(true, []);

    expandedElement;
    selectAll;
    searchColumns: string;
    actionColumns = ['table-actions', 'log', 'checkbox'];
    throwAtLast = ['table-actions', 'log'];
    showLog = true;
    noRecordsFound = false;
    disbaledData = false;
    searchColumnValue = '';
    selectedStatus = '';
    searchOrgString = '';
    searchAssetOrgString = '';
    selectedSearchColumn = '';
    enteredSearchValue = '';
    selectedOrgsArray = [];
    selectedAssetsArray = [];
    newOrgAdded: Subscription;
    unSubscribe = new Subject<void>();
    columnKeyPair: { [property: string]: TableColumn };
    specialColumnKeyPair: { [property: string]: TableColumn };
    selectAllRecords = false;
    attachmentData = {
        filename: '',
        url: '',
        title: translate('Add File'),
        return: 'true',
    };
    clearFiles = new BehaviorSubject(0);
    actionVisibility: { [property: string]: { [property: string]: boolean } } = {};
    lastPageDisabled: boolean;
    columnTypePair: { [property: string]: string } = {};
    show = false;
    selectedRecordsCount: number;
    private allColumnsResponse: TableColumn[] = [];

    constructor(
        private _broadcaster: BroadcasterService,
        private _libUtils: UtilsService,
        private _commonUtils: CommonUtilsService,
        public sanitizer: DomSanitizer
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.selectedColumns?.currentValue?.length > 0) {
            this.clonedColumns = CommonUtilsService.cloneObject(changes?.selectedColumns?.currentValue);
            this.pushSpecialColumns();
            this.generateColumnTypes();
        }
    }

    buildSelectionModal = () => {
        this.selection.clear();
        for (let i = 0; i < this.dataSource.data.length; i++) {
            if (this.dataSource.data[i].selected) {
                this.selection.select(this.dataSource.data[i]);
            } else {
                this.selection.deselect(this.dataSource.data[i]);
            }
        }
        this.onSelectCheckbox.emit(this.selection.selected);
    };

    checkboxSelect = (checked: boolean, column: TableColumn) => {
        if (checked) {
            let index = this.allColumns.findIndex((columnItem) => columnItem.id === column.id);
            if (index !== -1) {
                if (this.allColumns.findIndex((columnInAllColumns) => columnInAllColumns.type === 'SELECTRECORD') > -1) {
                    index++;
                }
                this.clonedColumns.splice(index, 0, column.id);
            } else {
                this.clonedColumns.push(column.id);
            }
        } else {
            const index = this.clonedColumns.indexOf(column.id);
            this.clonedColumns.splice(index, 1);
        }
        if (this.actionColumns.length !== 0) {
            this.actionColumns.forEach((eachAction) => {
                const actionIndex = this.clonedColumns.indexOf(eachAction);
                if (actionIndex !== -1) {
                    this.clonedColumns.splice(actionIndex, 1);
                }
            });
        }
        this.checkFiltersSelected();
    };

    cloneSubmit = () => {
        const actionColumns = this.actionColumns?.filter((column) => this.throwAtLast.indexOf(column) === -1);
        const newColumns = this.clonedColumns?.filter((column) => {
            if (actionColumns.indexOf(column) === -1 || column === 'checkbox') {
                return column;
            }
        });
        const tempColumns = newColumns?.filter((column) => this.throwAtLast.indexOf(column) === -1);
        if (tempColumns && tempColumns.length > 0) {
            // update condition for row record checkbox
            if (tempColumns.length === 1 && tempColumns[0] === 'checkbox') {
                this._libUtils.alertError('Select atleast one column name');
            } else {
                this.selectedColumns.splice(0);
                tempColumns && tempColumns.forEach((column) => this.selectedColumns.push(column));
                this.pushSpecialColumns();
                this.generateColumnTypes();
            }
        } else if (!this.selectedColumns?.length) {
            this._libUtils.alertError('Select atleast one column name');
        }
        if (this.selectAll) {
            this._commonUtils.setInStorage('filterColumnLog', true);
        } else {
            this._commonUtils.setInStorage('filterColumnLog', false);
        }
    };

    pushSpecialColumns = () => {
        this.throwAtLast
            .filter((columnName) => this.selectedColumns.indexOf(columnName) === -1)
            .forEach((columnName) => {
                if (columnName === 'table-actions') {
                    if (this.recordActions && this.recordActions.length > 0) {
                        this.selectedColumns.push(columnName);
                    }
                } else if (columnName === 'log') {
                    !this.noLog && this.selectedColumns.push(columnName);
                } else {
                    this.selectedColumns.push(columnName);
                }
            });
        this.allColumns
            .filter((column) => column.hideInAllColumnsList && !column.hide)
            .filter((column) => this.selectedColumns.indexOf(column.id) === -1)
            .forEach((column) => this.selectedColumns.unshift(column.id));
    };

    selectAllFilters = (selectedAll) => {
        const oldColumns = this.clonedColumns?.slice(0);
        const actionColumns = this.actionColumns.filter((column) => this.throwAtLast.indexOf(column) === -1);
        this.clonedColumns = oldColumns?.filter((column) => actionColumns.indexOf(column) > -1);
        if (selectedAll) {
            this.allColumns
                .filter((column) => !column.hideInAllColumnsList && !column.hide)
                .filter(
                    (column) =>
                        this.actionColumns.indexOf(column.id) === -1 &&
                        !(this.hideTableColumns && this.hideTableColumns.indexOf(column.id) > -1)
                )
                .forEach((column) => {
                    this.clonedColumns.push(column.id);
                });
        }
    };

    onSingleClick = (record) => {
        this.onSingleClickEvent.emit(record);
    };

    onDoubleClick = (record) => {
        // this.doubleClicked = true;
        const recordClone = CommonUtilsService.cloneObject(record);
        // recordClone['drawer'] = this.drawer;
        this.onDoubleClickEvent.emit(recordClone);
    };

    isLastPageDisabled = () => {
        this.lastPageDisabled = true;
        if (this.hidePaginator === true) {
            this.lastPageDisabled = true;
            return;
        }
        let disabled = true;
        if (this.pagination && this.pagination.value.page) {
            const keys = Object.keys(this.pagination.value.page);
            const lastValue = this.pagination.value.page[keys[keys.length - 1]];
            disabled = lastValue && lastValue !== undefined && lastValue.length > 0;
        }
        this.lastPageDisabled = disabled;
    }; /*EI-1282*/

    checkColumnsCount = () => {
        if (this.allColumns && this.actionColumns) {
            const filteredColumns = this.allColumns && this.allColumns.filter((column) => this.actionColumns.indexOf(column.id) === -1);
            return filteredColumns && filteredColumns.length >= 5;
        }
        return true;
    };

    // checkRecord = (element) => {
    //     this.checkMasterRecord.emit(element);
    // }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected()
            ? // this.selection.selected.forEach(element => {
              //     if (element && element['Role Name'] && (element['Role Name'] === 'CUSTOMER_ADMIN' || element['Role Name'] === 'PROVIDER_ADMIN')) {
              //     } else {
              //         this.selection.deselect(element);
              //     }
              // }) :
              this.selection.clear()
            : this.dataSource.data.forEach((row) => this.selection.select(row));
        this.onSelectCheckbox.emit(this.selection.selected);
    }

    checkFiltersSelected = () => {
        const leftOutColumns =
            this.allColumns &&
            this.allColumns
                .filter((column) => !column.hideInAllColumnsList && !column.hide)
                .filter((column) => !this.hideTableColumns || (this.hideTableColumns && this.hideTableColumns.indexOf(column.id) === -1))
                .filter((column) => this.clonedColumns && this.clonedColumns.indexOf(column.id) === -1);
        this.selectAll = leftOutColumns && leftOutColumns.length === 0;
    };

    getColumn = (columnId: string): TableColumn => {
        const column = this.allColumns.find((tableCoulmn) => tableCoulmn.id === columnId);
        if (this.passwordColumns?.indexOf(column?.name) >= 0) {
            column.type = 'PASSWORD';
        }
        if (column) {
            return column;
        }
        const throwAtLastColumn = this.throwAtLast.indexOf(columnId) > -1;
        if (throwAtLastColumn) {
            if (columnId === 'log') {
                return {
                    id: columnId,
                    hide: false,
                    hideInAllColumnsList: true,
                    icon: undefined,
                    name: translate('Log'),
                    type: 'ALL_COLUMNS_LIST',
                    options: undefined,
                };
            } else if (columnId === 'table-actions') {
                return {
                    id: columnId,
                    hide: false,
                    hideInAllColumnsList: true,
                    icon: undefined,
                    name: translate('Actions'),
                    type: 'ACTIONS',
                    options: undefined,
                };
            }
        }
        return;
    };

    getSpecialColumns = (columnId: string): TableColumn => {
        let column: TableColumn;
        switch (columnId) {
            case 'log':
                column = {
                    id: columnId,
                    hide: false,
                    hideInAllColumnsList: true,
                    icon: undefined,
                    name: translate('Log'),
                    type: 'ALL_COLUMNS_LIST',
                    options: undefined,
                };
                break;
            case 'table-actions':
                column = {
                    id: columnId,
                    hide: false,
                    hideInAllColumnsList: true,
                    icon: undefined,
                    name: translate('Actions'),
                    type: 'ACTIONS',
                    options: undefined,
                };
                break;
        }
        return column;
    };

    generateColumnTypes = () => {
        this.columnTypePair = {};
        this.selectedColumns.forEach((columnId) => {
            this.columnTypePair[columnId] = this.getColumnType(columnId);
        });
        // tslint:disable-next-line: no-unused-expression
        this.show = Object.keys(this.columnTypePair).indexOf('undefined') === -1 && Object.keys(this.columnTypePair).length > 0;
    };

    getColumnType = (columnId: string): string => {
        const column = this.getColumn(columnId);
        return column && column.type;
    };

    generateSpecialColumnsList = () => {
        this.specialColumnKeyPair = {};
    };

    toggleRecordsSelection = (isSelected: boolean) => {
        this.data.value.forEach((record) => {
            record.selected = isSelected;
            this.recordSelectedEvent.emit(record);
        });
        this.setSelectedRecordsCount();
    };

    onRecordSelectionChange = (changedRecord: TableData) => {
        this.checkAllRecordsSelected();
        this.recordSelectedEvent.emit(changedRecord);
    };

    checkAllRecordsSelected = () => {
        const nonSelectedRecords = this.getNonSelectedRecords();
        this.selectAllRecords = nonSelectedRecords.length === 0 && this.dataSource.data.length > 0;
        this.setSelectedRecordsCount();
    };

    setSelectedRecordsCount = () => (this.selectedRecordsCount = this.getSelectedRecords().length);

    getSelectedRecords = (): any[] => this.dataSource.data.filter((record) => record.selected);

    getNonSelectedRecords = (): any[] => this.dataSource.data.filter((record) => !record.selected);

    fileChanged = (files, record) => {
        record.files = [];
        files.forEach((file: File) => {
            record.files.push(file);
        });
        this.filesChanged.emit(record);
    };

    changedFileName = (fileName: string, record) => {
        record.fileName = fileName;
        this.filesChanged.emit(record);
    };

    downloadSourceTemplateUrl = (element) => {
        this.DownloadSourceTemplate.emit(element);
    };

    checkForDynamicActions = (element, action) => {
        if (element?.dynamicActions?.length > 0) {
            const dynamicAction = element.dynamicActions;
            for (let i = 0; i < dynamicAction.length; i++) {
                if (dynamicAction[i].erroneous === false) {
                    if (dynamicAction[i].displayName === action.displayName) {
                        return false;
                    }
                }
            }
        }
        return true;
    };

    downloadUplodedFile = (record, fileName) => {
        record.downloadFile = fileName;
        this.downloadFile.emit(record);
    };

    copyToInboundRequestId = (record) => {
        const input = document.createElement('input');
        input.setAttribute('value', record.requestId);
        document.body.appendChild(input);
        input.select();
        const result = document.execCommand('copy');
        document.body.removeChild(input);
        return result;
    };

    buildClonedColumns = () => {
        this.clonedColumns = this.selectedColumns
            .filter((columnId) => {
                const column = this.allColumns.find((columnRecord) => columnRecord.id === columnId);
                return column && !column.hideInAllColumnsList;
            })
            .slice(0);
    };

    buildActionsShowHideModal = (records: TableData[]) => {
        this.actionVisibility = {};
        (records || []).forEach((record) => {
            this.actionVisibility[record.recordId] = {};
            (this.recordActions || []).forEach((action) => {
                this.actionVisibility[record.recordId][action.displayName] = this.checkForDynamicActions(record, action);
            });
        });
    };

    trackByActionsMethod = (_index: number, action: any): number => {
        return action.displayName;
    };

    trackBySelectedColumnsMethod = (_index: number, columnId: any): number => {
        return columnId;
    };

    trackByAllColumnsMethod = (_index: number, columnId: any): number => {
        return columnId;
    };

    clickedCell = (data: TableColumn, event: Event, value: TableData) => {
        if (data.callback) {
            event.stopPropagation();
            data.callback(value);
        }
    };

    decodeTextFieldValue = (value: any) => {
        try {
            let html = value ? decodeURIComponent(value) : '';
            return html ? this.sanitizer.bypassSecurityTrustHtml(html) : '';
        } catch (e) {
            return value;
        }
    };

    public ngOnInit() {
        this.dataSource.sort = this.sort;
        this.allTableColumns
            .pipe(
                takeUntil(this.unSubscribe),
                delay(0),
                filter((data) => JSON.stringify(data) !== JSON.stringify(this.allColumnsResponse))
            )
            .subscribe(async (columns) => {
                this.allColumnsResponse = columns;
                if (!columns || columns.length === 0) {
                    return;
                }
                this.show = false;
                this.allColumns = columns;
                this.buildClonedColumns();
                await this._libUtils.waitForCertainTime(100);
                this.pushSpecialColumns();
                this.columnKeyPair = {};
                this.allColumns.forEach((column) => {
                    this.columnKeyPair[column.id] = column;
                });
                this.checkFiltersSelected();
                this.generateColumnTypes();
            });

        this.data.pipe(takeUntil(this.unSubscribe)).subscribe((data) => {
            this.buildActionsShowHideModal(data);
            this.buildSelectionModal();
            this.dataSource.data = data || [];
            this.checkAllRecordsSelected();
            this.dataSource.filterPredicate = (tableData, filter) => {
                return this.selectedColumns.some((ele) => {
                    return (
                        ele !== 'table-actions' &&
                        ele !== 'log' &&
                        tableData[ele] &&
                        tableData[ele].toLocaleLowerCase().indexOf(filter) !== -1
                    );
                });
            };
        });
        if (this.pagination) {
            this.pagination.pipe(takeUntil(this.unSubscribe)).subscribe(this.isLastPageDisabled);
        }
        !this.noLog &&
            this.selectedColumns &&
            this.selectedColumns.indexOf('log') === -1 &&
            this.checkColumnsCount() &&
            this.selectedColumns.push('log');
        this._broadcaster
            .on('selectFilterLogColumn')
            .pipe(takeUntil(this.unSubscribe))
            .subscribe(async () => {
                await this._libUtils.waitForCertainTime(100);
                this.selectAll = true;
                this.selectAllFilters(true);
                this.cloneSubmit();
            });
    }

    public ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.paginator?._intl && (this.paginator._intl.itemsPerPageLabel = translate('Items per page'));
    }

    public ngOnDestroy() {
        this.unSubscribe.next();
        this.unSubscribe.complete();
    }
}
