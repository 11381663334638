import { AssetService } from '../assetservice.class';

export class WorkqRecord {
    active: boolean;

    createdOn: string;

    id: string;

    includedURL: string;

    messageCategory: string;

    messageContent: string;

    messageKey: string;

    orgId: string;

    isRead: boolean;

    stageName: string;

    subject: string;

    templateName: string;

    type: string;

    userId: string;

    details: {
        providerOrgId: string;
        rootOrgId: string;
        orgId: string;
        userId: string;
        eventType: string;
        eventNode: string;
        module: string;
        details: {
            partnerExternalId: string;
            invitationType: string;
            partnerReferenceId: string;
            partnerOrgId: string;
            orgName: string;
            orgId: string;
            partnerOrgName: string;
            providerOrgName: string;
            invitationId: string;
            userId: string;
            actionLink: string;
            services?: AssetService[];
        };
        eventTime: number;
    };

    messages = [];

    currenrUrl: string;

    queryParams = [];

    queryString: string;

    messageType: string;

    nextRecordId: string;

    previousRecordId: string;

    redirect: boolean;

    needModal: boolean;

    detailsObject?: { [property: string]: string };

    serviceDisplayName?: string;

    constructor(record, routerMap?, records?, messageType?: string) {
        this.active = record?.active;
        this.createdOn = record?.createdOn;
        this.id = record?.id || record?.messageId;
        this.includedURL = record?.includedURL;
        this.messageCategory = record?.messageCategory;
        this.messageContent = record?.content;
        this.messageKey = record?.messageKey;
        this.orgId = record?.orgId;
        this.isRead = record?.isRead;
        this.stageName = record?.stageName;
        this.subject = this.convertSubject(record?.subject);
        this.templateName = record?.templateName;
        this.type = record?.type;
        this.userId = record?.userId;
        this.messageType = messageType;
        this.messageContent && this.parseHtml(this.messageContent);
        if (this.messageCategory === 'Report Generation') {
            let messageKeyArray = this.messageKey.split('/');
            let fileName = messageKeyArray.splice(-1)[0];
            // this.includedURL = '/asset/reports?reportUrl=' + this.messageKey;
            this.includedURL =
                '/process/v1/files?fileUrl=' + encodeURIComponent(this.messageKey) + '&fileName=' + encodeURIComponent(fileName);
        }
        if (this.messageCategory && this.includedURL && this.includedURL.length > 0) {
            routerMap.forEach((element) => {
                if (element.messageCategory === this.messageCategory) {
                    if (element.redirect) {
                        this.redirect = element.redirect;
                        this.currenrUrl = this.includedURL;
                        this.needModal = element.needModal;
                    } else {
                        this.redirect = element.redirect;
                        this.currenrUrl = element.currentUrlString;
                        this.needModal = element.needModal;
                        this.getQueryParams(this.includedURL);
                    }
                }
            });
        }
        records?.forEach?.((element, index) => {
            if (element.messageId === this.id) {
                const nextIndex = index + 1;
                const previousIndex = index - 1;
                if (nextIndex > -1) {
                    if (records[nextIndex]) {
                        this.nextRecordId = records[nextIndex].messageId;
                    }
                }
                if (previousIndex > -1) {
                    if (records[previousIndex]) {
                        this.previousRecordId = records[previousIndex].messageId;
                    }
                }
            }
        });
        this.detailsObject = {};
        this.details = record?.details?.details;
        Object.keys(this.details || {})
            ?.filter(
                (detail) =>
                    ['Subscription Id', 'Cause', 'Location', 'Request Id', 'Transformation Name', 'Contact Person', 'Request ID'].indexOf(
                        detail
                    ) === -1
            )
            ?.forEach((detail) => (this.detailsObject[detail] = this.details[detail]));
        this.serviceDisplayName = record.details?.details?.services?.[0]?.displayName || this.details?.details?.services?.[0]?.name;
    }

    private convertSubject = (subject: string) => {
        let splitCondition: string;
        let replaceString: string;
        if (subject?.length > 0 && /Request/.test(subject)) {
            splitCondition = 'Request';
            replaceString = 'Inbound Transmission';
        }
        if (splitCondition?.length > 0 && replaceString?.length > 0) {
            subject = subject.replace(new RegExp(splitCondition), replaceString);
        }
        return subject;
    };

    getQueryParams = (includedUrl) => {
        if (includedUrl.indexOf('?') > -1) {
            const splittedUrl = includedUrl.split('?');
            if (splittedUrl[1].indexOf('&') > -1) {
                this.queryParams = splittedUrl[1].split('&');
            } else {
                this.queryString = splittedUrl[1];
            }
        }
    };

    parseHtml = (content) => {
        const str = content.split("<p class='ml10'><strong>");
        str.forEach((element) => {
            if (element?.[0] !== '<') {
                if (element.indexOf('</strong>') > -1) {
                    const elemArry = element.split('</strong>');
                    for (let i = 0; i < elemArry.length; i++) {
                        if (elemArry[i].indexOf('</p>') > -1) {
                            elemArry[i] = elemArry[i].substring(0, elemArry[i].indexOf('</p>'));
                        }
                        if (elemArry[i].indexOf(':') > -1) {
                            elemArry[i] = elemArry[i].substring(0, elemArry[i].indexOf(':'));
                        }
                    }
                }
            } else {
                let convertedString = element.replace(/(<([^>]+)>)/gi, '');
                if (convertedString.indexOf('Regards') > -1) {
                    convertedString = convertedString.substring(0, convertedString.indexOf('Regards'));
                }
                this.messages.push(convertedString.trim());
            }
        });
    };

    getSubstring = (element, content) => {
        // tslint:disable-next-line:quotemark
        const contentString = element.split("<p class='ml10'><strong>").find((el) => el.indexOf(content) > -1);
        const subContent = contentString && contentString.substring(content.length + 10 + 1, contentString.indexOf('</p>'));
        return subContent;
    };
}
