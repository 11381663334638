export interface EninvoiceState {
    selectedBridgeRestApiName: string;
    selectedAppRestApiName: string;
    selectedReportName: string;
    selectedInstanceId: string;
    selectedRequestId: string;
}

export const eninvoiceState: EninvoiceState = {
    selectedAppRestApiName: undefined,
    selectedBridgeRestApiName: undefined,
    selectedReportName: undefined,
    selectedInstanceId: undefined,
    selectedRequestId: undefined,
};
