<mat-toolbar class="postLoginHeaderToobar">
    <mat-toolbar-row class="postLoginHeader">
        <span class="navLogo">
            <a (click)="!isInGuestMode && navigateHome()">
                <ng-container *ngIf="!imgSrc">
                    <img class="headerLogo" src="/assets/images/{{logoHref}}header-logo.png" alt="enComply">
                </ng-container>
                <ng-container *ngIf="imgSrc?.length > 0">
                    <img class="headerLogo" src="data:image/jpeg;base64,{{imgSrc}}" alt="Organization">
                </ng-container>
            </a>
        </span>
        <ul class="d-flex height100" [style.display]="isInGuestMode ? 'none !important' : null">
            <li class="nav-item">
                <button mat-button class="fulwidthmenu fulwidthmenuButton" [matMenuTriggerFor]="tenantsMenu" (click)="setTenantAutoFocus()" #matOrgMenuTrigger="matMenuTrigger">
                    <ng-container *transloco="let translate">
                        <img *ngIf="!matOrgMenuTrigger.menuOpen" class="headerIcon iconCls" src="/assets/images/building-grey.png" alt="" [title]="selectedTenantName || translate('Select Tenant')">
                        <img *ngIf="matOrgMenuTrigger.menuOpen" class="headerIcon iconCls" src="/assets/images/building-green.png" alt="">
                        <span class="btnDisplayText" [ngClass]="{'onThreeRootOrgs': selectedTenantHelperLength > 4}">
                            <ng-container *ngIf="selectedTenantHelperLength && selectedTenantHelperLength > 3;else noMoreChild">
                                <div class="d-flex">
                                    <div class="marquee" title="{{selectedTenantHelper && selectedTenantHelper[0]}}|&nbsp;{{selectedTenantName && selectedTenantName.substring(selectedTenantName.indexOf('|') + 1, selectedTenantName.lastIndexOf('|'))}}">
                                        <div class="marquee-content" id="marquee-content" [style.animationDuration]="selectedTenantHelperLength === 4 ? '10s' : 10 + ((selectedTenantHelperLength - 4) * 8)+'s'">
                                            <span class="item-collection-1" id="item-collection-1" [style.animationDuration]="selectedTenantHelperLength === 4 ? '10s' : 10 + ((selectedTenantHelperLength - 4) * 8)+'s'">
                                                <span class="item1">
                                                    <span style="color: green;">
                                                        &nbsp;{{selectedTenantHelper && selectedTenantHelper[0]}}
                                                    </span>
                                                    <span>
                                                        |&nbsp;{{selectedTenantName && selectedTenantName.substring(selectedTenantName.indexOf('|') + 1, selectedTenantName.lastIndexOf('|'))}}
                                                    </span>
                                                </span>
                                            </span>
                                            <span class="item-collection-2">
                                                <span class="item2">
                                                    <span style="color: green;">
                                                        {{selectedTenantHelper && selectedTenantHelper[0]}}
                                                    </span>
                                                    <span>
                                                        |&nbsp;{{selectedTenantName && selectedTenantName.substring(selectedTenantName.indexOf('|') + 1, selectedTenantName.lastIndexOf('|'))}}
                                                    </span>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="subOrgScroller" [ngClass]="{'hasMoreOrgs': (selectedTenantHelperLength > 4 && (selectedTenantHelper && selectedTenantHelper[selectedTenantHelperLength - 1].length >= 25))}" title="{{selectedTenantHelper && selectedTenantHelper[selectedTenantHelperLength - 1]}}">&nbsp;...|{{selectedTenantHelper && selectedTenantHelper[selectedTenantHelperLength - 1]}}</div>
                                </div>
                            </ng-container>
                            <ng-template #noMoreChild>
                                <ng-container>
                                    {{selectedTenantName || translate('Select Tenant')}}
                                </ng-container>
                            </ng-template>
                        </span>
                        <span class="material-icons defaultDropdownIcon"> arrow_drop_down </span>
                        <span class="material-icons fulWidthMatMenuShowArrowIcon">arrow_drop_up</span>
                    </ng-container>
                </button>
                <ng-container *ngIf="showSwitchToParent">
                    <span class="goToParentOrganization" (click)="selectParentOrganization()">
                        <mat-icon [inline]="true" [title]="'Go to ' + selectedSubTenant.parent.name">arrow_upward</mat-icon>
                    </span>
                </ng-container>
                <mat-menu #tenantsMenu="matMenu" [overlapTrigger]="false" class="fulWidthMatMenu tenantsMenu" backdropClass="header-sub-menu">
                    <div class="tenantsMenuContainer" (click)="$event.stopPropagation()" *transloco="let translate;">
                        <mat-card>
                            <mat-card-content>
                                <div class="tenantStructureCnt d-flex" *ngIf="organizationsMenuOpened">
                                    <div class="levelOneTenants col-md-5">
                                        <p class="section-title">{{translate('Select Tenant')}}</p>
                                        <mat-form-field class="searchTenants searchInputField" *transloco="let translate">
                                            <input matInput [placeholder]="translate('Search Organization')" #tenantToFocus type="text" [(ngModel)]="filterTenantName">
                                            <button mat-button matSuffix mat-icon-button aria-label="clear" (click)='clearSearchTenant()'>
                                                <mat-icon class='locationManagementIcons' *ngIf='filterTenantName'>close </mat-icon>
                                                <mat-icon *ngIf='!filterTenantName' class="locationManagementIcons">search </mat-icon>
                                            </button>
                                        </mat-form-field>
                                        <p class="alphaFilterSection">
                                            <span class="alphaFilter" *ngFor="let alpha of alphabets" (click)="filterTenants(alpha)" [class.active]="selectedAlpha === alpha">{{alpha | uppercase}}</span>
                                            <span class="alphaFilter clearFilter" *ngIf='selectedAlpha' (click)='clearSelectedAlpha()'>x</span>
                                        </p>
                                        <div class="tenantsList">
                                            <ng-container *ngIf="(tenantsToRender | FilterPipe: 'name' : filterTenantName | orderBy: 'name') as result">
                                                <cdk-virtual-scroll-viewport itemSize="40" style="height: 52vh;" class="appScroll noTrack">
                                                    <button class="mat-menu-item" (click)="selectTenant(tenant)" [class.activeOrganization]="tenant.id === selectedTenantId" title="{{tenant.name}} ({{tenant.orgLoginId}})" *cdkVirtualFor="let tenant of result; trackBy: tenantTrackBy">
                                                        <span>{{tenant.name}} <span class="orgId">({{tenant.orgLoginId}})</span></span>
                                                    </button>
                                                </cdk-virtual-scroll-viewport>
                                                <label class="noSearchResults" *ngIf="tenantsToRender.length && !result.length">{{translate('No organizations matching your search')}}</label>
                                            </ng-container>
                                            <label class="noSearchResults" *ngIf="!tenantsToRender.length">{{translate('No organizations found')}}</label>
                                        </div>
                                    </div>
                                    <div class="tenantFlatStructure col-md-7" *ngIf="subTenants && subTenants.length">
                                        <p class="section-title">
                                            <ng-container *ngFor="let name of subTentantToParentStructure; let i = index">
                                                <span class="subTentantOrganizationName" (click)="applyDownwardStructureToSearch(i)"> {{name}} </span>
                                                <span class="subTenantPipe" *ngIf="subTentantToParentStructure[i+1]"> | </span>
                                            </ng-container>
                                        </p>
                                        <mat-form-field class="searchSubTenants searchInputField" *transloco="let translate">
                                            <input matInput [placeholder]="translate('Search Tenant')" type="text" [(ngModel)]="filterSubTenantName">
                                            <button mat-button matSuffix mat-icon-button aria-label="clear" (click)='clearSearchSubTenant()'>
                                                <mat-icon class='locationManagementIcons' *ngIf='filterSubTenantName'>
                                                    close </mat-icon>
                                                <mat-icon *ngIf='!filterSubTenantName' class="locationManagementIcons">
                                                    search </mat-icon>
                                            </button>
                                        </mat-form-field>
                                        <div class="tenantsList">
                                            <ng-container *ngIf="(subTenants | FilterPipe: 'displayTenantName' : filterSubTenantName | orderBy: 'displayTenantName') as result">
                                                <cdk-virtual-scroll-viewport itemSize="40" style="height: 57vh;" class="appScroll noTrack">
                                                    <ng-container *cdkVirtualFor="let subTenant of result; trackBy: subTenantTrackBy">
                                                        <button class="mat-menu-item" *ngIf="!subTenant?.noAccess" title="{{subTenant.displayTenantName || subTenant.name}} ({{subTenant.orgLoginId}})" (click)="selectSubTenant(subTenant)" [class.activeOrganization]="subTenant.id === selectedSubTenant.id">
                                                            <span>{{subTenant.displayTenantName || subTenant.name}} <span class="orgId">({{subTenant.orgLoginId}})</span></span>
                                                        </button>
                                                    </ng-container>
                                                </cdk-virtual-scroll-viewport>
                                                <label class="noSearchResults" *ngIf="subTenants.length && result.length === 0">{{translate('No tenants matching your search')}}</label>
                                            </ng-container>
                                            <label class="noSearchResults" *ngIf="!subTenants.length">No tenants found</label>
                                        </div>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </mat-menu>
            </li>
            <li class="nav-item headerAppsContainer">
                <button mat-button class="fulwidthmenu fulwidthmenuButton appsCurtain" [class.appCurtainOpened]="matAppsMenuTrigger.menuOpen" [matMenuTriggerFor]="appsMenu" #matAppsMenuTrigger="matMenuTrigger">
                    <img *ngIf="!matAppsMenuTrigger.menuOpen && !(currentApp?.id)" class="headerIcon iconCls" src="/assets/images/apps-grey.png" alt="">
                    <img *ngIf="matAppsMenuTrigger.menuOpen || (currentApp?.id)" class="headerIcon iconCls" src="/assets/images/apps-green.png" alt="">
                    <ng-container *transloco="let translate;">
                        <span class="btnDisplayText" [title]="selectedAppName || translate('Apps')">{{selectedAppName || translate('Apps')}}</span>
                    </ng-container>
                    <span class="material-icons defaultDropdownIcon"> arrow_drop_down </span>
                    <span class="material-icons fulWidthMatMenuShowArrowIcon">arrow_drop_up</span>
                </button>
                <mat-menu #appsMenu="matMenu" [overlapTrigger]="false" class="fulWidthMatMenu appsMenu" backdropClass="header-sub-menu">
                    <div class="tenantsMenuContainer" (click)="$event.stopPropagation()" *transloco="let translate;">
                        <mat-card>
                            <mat-card-content>
                                <ng-container *ngTemplateOutlet="appsMenuTemplate"> </ng-container>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </mat-menu>
            </li>
            <li class="nav-item" *ngIf="(_commonUtils.analyticsServiceTypes?.split(',').includes('DASHBOARD') || _commonUtils.analyticsServiceTypes?.split(',').includes('WIDGET')) && canShowAnalytics">
                <button (click)="getDashboardCharts()" mat-button class="fulwidthmenu fulwidthmenuButton appsCurtain dashboardMenu" [class.appCurtainOpened]="matChartsMenuTrigger.menuOpen" [matMenuTriggerFor]="chartsMenu" #matChartsMenuTrigger="matMenuTrigger" *transloco="let translate;">
                    <span class="material-icons-outlined mr-2" [title]="translate('Dashboard')"> insights </span>
                    <ng-container>
                        <span class="btnDisplayText" [title]="translate('Dashboard')">{{translate('Dashboard')}}</span>
                    </ng-container>
                    <span *ngIf="insights.length > 0" class="material-icons defaultDropdownIcon"> arrow_drop_down </span>
                    <span *ngIf="insights.length > 0" class="material-icons fulWidthMatMenuShowArrowIcon">arrow_drop_up</span>
                </button>
                <div *ngIf="isDashboardsLoading" class="d-flex justify-content-center relative bottom-0.5">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </div>
                <mat-menu #chartsMenu="matMenu" [overlapTrigger]="false" class="fulWidthMatMenu chartsMenu" backdropClass="header-sub-menu" xPosition="after">
                    <div class="tenantsMenuContainer">
                        <mat-card>
                            <mat-card-content>
                                <a *ngFor="let insight of insights" class="mat-menu-item appName text-center appReportContainer" mat-menu-item (click)="openInsights()" routerLink="organizations/{{currentOrganizationId.value}}/dashboard" [queryParams]="{insight: insight.serviceName}" routerLinkActive="activeOrganization">
                                    <div title="{{insight.displayName || insight.serviceName}}" class="appDisplayName">
                                        {{insight.displayName || insight.serviceName}}
                                    </div>
                                </a>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </mat-menu>
            </li>
        </ul>
        <span class="spacer"></span>
        <button *ngIf="!isInGuestMode && showWorkQ" mat-button class="nav-item loaderBlock messagesCount" [class.isLoading]="loading.activeMessagesCount" title="WorkQ" (click)="navigateWorkQ()" [ngClass]="{'workqActive': workqSelected}">
            <span class="btnDisplayText">WorkQ <span *ngIf="totalWorkqCount!==undefined">({{totalWorkqCount}})</span></span>
        </button>
        <ng-container *ngIf="!isInGuestMode">
            <app-settings-nested-menu [menu]="settings" [main]="true" [isSettingsSelected]="isSettingsSelected"></app-settings-nested-menu>
            <div class="block relative mx-[5px]" [hidden]="hideNewUISkin" *transloco="let translate;">
                <span (click)="switchToNewUI()" class="cursor-pointer font-bold shadow-md bg-white hover:bg-[#00a651] text-[#00a651] hover:text-white py-2 px-4 text-xs rounded-2xl text-center no-underline">{{onNewUI ? translate('Switch to Old UI') : translate('Switch to New UI')}}</span>
            </div>
            <app-user-details></app-user-details>
        </ng-container>
    </mat-toolbar-row>
</mat-toolbar>

<ng-template #appsMenuTemplate>
    <div class="row appsCurtainContainer">
        <div class="col-3">
            <ng-container *ngTemplateOutlet="appCategoriesTemplate"></ng-container>
        </div>
        <div class="col-9">
            <ng-container *ngTemplateOutlet="appsTemplate"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #appCategoriesTemplate>
    <div class="categoriesContainer" *transloco="let translate" [class.hiddenAppCategory]="_schema.schema.header.hideAppCategory || !uiList || uiList.length === 0">
        <div class="packagesContainer packageTagsContainer">
            <div class="categoriesContainerHeader appPackageHeader row">
                <span class="categoriesContainerHeaderText col-6">
                    {{translate('By Package')}}
                </span>
                <div class="col-6 align-content-end appsSearchContainer">
                    <mat-form-field class="searchTenants searchInputField" [style.width]="'100%'">
                        <input matInput [placeholder]="translate('Search')" #appToFocus type="text" [(ngModel)]="filterTagName">
                        <button mat-button matSuffix mat-icon-button aria-label="clear" (click)="filterTagName = ''">
                            <mat-icon class='locationManagementIcons' *ngIf='filterTagName'> close </mat-icon>
                            <mat-icon *ngIf='!filterTagName' class="locationManagementIcons"> search
                            </mat-icon>
                        </button>
                    </mat-form-field>
                </div>
            </div>
            <div class="categoriesContainerBody">
                <ng-container *ngIf="(packageTags | FilterPipe: 'displayName' : filterTagName: null: 'name' | AlphabeticalOrder: 'displayName') as result">
                    <div class="categoryBody" [class.selectedCategory]="categoryFilter?.key === 'tag' && categoryFilter?.value === tag?.value" *ngFor="let tag of result" (click)="startAppFilter('tag', tag?.value, tag?.displayName)">
                        <mat-icon class="material-symbols-outlined categoryBodyIcon packageIcon"> web_stories </mat-icon>
                        <span class="categoryBodyText">
                            {{tag.displayName}}
                        </span>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="packagesContainer platformsContainer" [hidden]="!uiList || uiList.length === 0">
            <div class="categoriesContainerHeader pointer d-flex justify-content-between align-items-center" (click)="toggleAppCategory()">
                <span class="categoriesContainerHeaderText">
                    {{translate('By App Category')}}
                </span>
                <div class="float-right mt-3">
                    <mat-icon [hidden]="!_schema.schema.header.hideAppCategory">chevron_right</mat-icon>
                    <mat-icon [hidden]="_schema.schema.header.hideAppCategory">expand_more</mat-icon>
                </div>
            </div>
            <div class="categoriesContainerBody">
                <ng-container *ngFor="let list of uiList">
                    <div class="categoryBody" [class.selectedCategory]="categoryFilter?.key === 'platform' && categoryFilter?.value === list?.value" *ngIf="list.value !== 'enreport'" (click)="startAppFilter('platform', list?.value, list?.displayName, undefined)">
                        <mat-icon class="categoryBodyIcon"> app_registration </mat-icon>
                        <span class="categoryBodyText">
                            {{list.displayName}}
                        </span>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #appsTemplate>
    <ng-container *transloco="let translate">
        <div class="appTemplateHeader row">
            <div class="col-7 font-weight-bold appsHeaderText" [title]="categoryFilter.displayName ? (categoryFilter.displayName + (currentApp?.assetType === 'BRIDGE_ASSET' ? ' >> ' + currentApp.displayName : '')) : !categoryFilter.displayName && 'My Apps'">
                <ng-container *ngIf="categoryFilter.displayName">
                    {{categoryFilter.displayName}}
                    <span class="appsHeaderTextIcons" [hidden]="categoryFilter.key !== 'platform'">
                        <span class="material-icons-outlined p-1" [title]="translate('Home')" (click)="navigateToHome()"> home </span>
                        <span class="material-icons-outlined p-1" *ngIf="_commonUtils.analyticsServiceTypes?.split(',').includes('DASHBOARD') || _commonUtils.analyticsServiceTypes?.split(',').includes('WIDGET')" [hidden]="!showDashboardIcon" [title]="translate('Dashboard')" (click)="navigateToWidgets()"> insights </span>
                    </span>
                    <ng-container *ngIf="currentApp?.assetType === 'BRIDGE_ASSET'">
                        >> {{currentApp.displayName}}
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="!categoryFilter.displayName">
                    My Apps
                </ng-container>
            </div>
            <div class="col-5 align-content-end appsSearchContainer">
                <ng-container *ngIf="currentApp?.assetType !== 'BRIDGE_ASSET'">
                    <mat-form-field class="searchTenants searchInputField" [style.width]="'100%'">
                        <input matInput [placeholder]="translate('Search App')" #appToFocus type="text" [(ngModel)]="filterAppName">
                        <button mat-button matSuffix mat-icon-button aria-label="clear" (click)='clearSearchAppName()'>
                            <mat-icon class='locationManagementIcons' *ngIf='filterAppName'> close </mat-icon>
                            <mat-icon *ngIf='!filterAppName' class="locationManagementIcons"> search
                            </mat-icon>
                        </button>
                    </mat-form-field>
                </ng-container>
                <ng-container *ngIf="currentApp?.assetType === 'BRIDGE_ASSET'">
                    <mat-form-field class="searchTenants searchInputField" [style.width]="'calc(100% - 100px)'">
                        <input matInput [placeholder]="translate('Search App')" #appToFocus type="text" [(ngModel)]="filterSubAppName">
                        <button mat-button matSuffix mat-icon-button aria-label="clear" (click)='filterSubAppName = ""'>
                            <mat-icon class='locationManagementIcons' *ngIf='filterSubAppName'> close </mat-icon>
                            <mat-icon *ngIf='!filterSubAppName' class="locationManagementIcons"> search
                            </mat-icon>
                        </button>
                    </mat-form-field>
                </ng-container>
                <button class="backButton" mat-button (click)='clearAppSelection()' *ngIf="currentApp?.assetType === 'BRIDGE_ASSET'">
                    <mat-icon class="backIcon"> keyboard_backspace </mat-icon>
                    Back
                </button>
            </div>
        </div>
        <div class="appsBodyContainer" *ngIf="!loadingApps">
            <div class="mainAppsContainer appsListView" *ngIf="currentApp?.assetType !== 'BRIDGE_ASSET'">
                <ng-container *ngIf="(allApps | FilterPipe: 'displayName' : filterAppName: null: 'name' | AlphabeticalOrder: 'displayName') as result">
                    <ng-container *ngFor="let app of result; let i = index; trackBy: allAppsTrackBy" (click)="selectApp($event, app)">
                        <ng-container *ngTemplateOutlet="appTemplate; context: { app: app }"></ng-container>
                    </ng-container>
                    <ng-container *ngIf="result.length === 0">
                        <div class="noAppFound">
                            {{translate('No Apps Found')}}
                        </div>
                    </ng-container>
                </ng-container>
            </div>
            <div class="mainAppsContainer appsListView" *ngIf="currentApp?.assetType === 'BRIDGE_ASSET'">
                <div style="width: 100%;" *ngFor='let level of levelWiseNodeMap | keyvalue; let i = index; levelWiseNodeMapTrackBy' class="bridgeNodesLevelContainer">
                    <div class="bridgeNodesLevelLabel">
                        Level {{i+1}} :
                    </div>
                    <ng-container *ngIf="(levelWiseNodeMap[$any(level.key)] | FilterPipe: 'displayName' : filterSubAppName:null:'name': null : 'reports' | AlphabeticalOrder: 'displayName') as result">
                        <ng-container *ngIf="result.length > 0; else noAppsFound">
                            <ng-container *ngFor="let app of result; subLevelWiseNodeMapTrackBy">
                                <ng-container *ngTemplateOutlet="appTemplate; context: { app: app, bridge: currentApp }"></ng-container>
                                <ng-container *ngIf="(app.reports | FilterPipe: 'displayName': filterSubAppName : null :'name' : null :'reports'|AlphabeticalOrder: 'displayName') as reports">
                                    <ng-container *ngFor="let report of reports">
                                        <ng-container *ngTemplateOutlet="appTemplate; context: { app: app, bridge: currentApp, report: report }"></ng-container>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="appsBodyContainer" *ngIf="loadingApps">
            <div class="mainAppsContainer">
                <ng-container *ngFor="let app of [1, 2, 3, 4, 5, 6, 7, 8];">
                    <div class="appName text-center loaderBlock isLoading"></div>
                </ng-container>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #noAppsFound>
    <div *transloco="let translate;">
        <div class="noAppFoundLevel">
            {{translate('No Apps Found')}}
        </div>
    </div>
</ng-template>

<ng-template #appTemplate let-app="app" let-report="report" let-bridge="bridge">
    <ng-container [ngSwitch]="report?.componentType">
        <ng-container *ngSwitchCase="'FILE'">
            <button class="mat-menu-item appName text-center appReportContainer" AppSelection [bridge]="bridge" [app]="app" [report]="report" [uiType]="categoryFilter.key === 'platform' && categoryFilter.value" mat-menu-item (click)="selectApp($event, bridge, report)" [class.activeOrganization]="(currentReport?.id === report.id && currentSubApp?.restApiName === app.restApiName)">
                <div class="headerAppLinks">
                    <app-header-shortcuts [uiType]="categoryFilter.key === 'platform' && categoryFilter.value" [mainApp]="app" [subApp]="bridge" [report]="report" [appsMenuTrigger]="_matAppsMenuTrigger"></app-header-shortcuts>
                </div>
                <div class="buttonImageContainer">
                    <mat-icon class="material-symbols-outlined appReportImage"> insights </mat-icon>
                </div>
                <div title="{{report.displayName || report.name}}" class="appDisplayName">
                    {{report.displayName || report.name}}
                </div>
            </button>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <button class="mat-menu-item appName text-center" AppSelection [uiType]="categoryFilter.key === 'platform' && categoryFilter.value" [bridge]="bridge" [app]="app" [class.activeOrganization]="(currentApp?.restApiName === app.restApiName || currentSubApp?.restApiName === app.restApiName) && !currentReport?.id" (click)="selectApp($event, app)">
                <div class="headerAppLinks" [hidden]="app.assetType === 'RECON'">
                    <app-header-shortcuts [uiType]="categoryFilter.key === 'platform' && categoryFilter.value" [mainApp]="app" [subApp]="bridge" [appsMenuTrigger]="_matAppsMenuTrigger"></app-header-shortcuts>
                </div>
                <div class="buttonImageContainer">
                    <img class="headerIcon" src="/assets/images/folder.png" alt="Bridge App" *ngIf="app?.assetType === 'BRIDGE_ASSET'">
                    <img class="headerIcon" src="/assets/images/file.png" alt="App" *ngIf="!app.assetType || (app.assetType !== 'BRIDGE_ASSET' && app.assetType !== 'RECON')">
                    <mat-icon class="material-symbols-outlined appReportImage" *ngIf="app?.assetType === 'RECON'"> compare </mat-icon>
                </div>
                <div title="{{app.displayName || app.name}}" class="appDisplayName">
                    {{app.displayName || app.name}}
                </div>
            </button>
        </ng-container>
    </ng-container>
</ng-template>
