import { createReducer, on } from '@ngrx/store';

import { Organization } from '../../models/organization.class';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { CompletedSwitchToOrganization, SessionLogoutSuccessfull, SetCurrentOrganization } from '../actions';
import * as LocationActionTypes from '../actions/locations.actions';
import { locationsInitialState } from '../states/locations.state';

export const LOCATIONS_REDUCER = createReducer(
    locationsInitialState,
    on(CompletedSwitchToOrganization, (state, action) => {
        const organizationId = action.organization?.id;
        return {
            ...state,
            currentOrganizationId: organizationId,
        };
    }),
    on(SetCurrentOrganization, (state, action) => {
        const organizationId = action.organization.id;
        return {
            ...state,
            currentOrganizationId: organizationId,
        };
    }),
    on(LocationActionTypes.UpdatedLocation, (state, action) => {
        const organizations: Organization[] = CommonUtilsService.cloneObject(state?.organizationLocations?.[action.organizationId] || []);
        if (action.parentId && action.organization.id !== action.parentId) {
            const orgIndex = organizations?.findIndex((org) => org.id === action.organization.id);
            if (orgIndex > -1) {
                organizations.splice(orgIndex, 1, action.organization);
            } else {
                organizations.unshift(action.organization);
            }
        }
        const organizationLocations = { ...state.organizationLocations };
        if (action.parentId) organizationLocations[action.parentId] = organizations;
        return {
            ...state,
            organizationLocations: organizationLocations,
        };
    }),
    on(LocationActionTypes.SetLocation, (state, action) => {
        const organizations: Organization[] = CommonUtilsService.cloneObject(state?.organizationLocations?.[action.organizationId] || []);
        if (action.organization.id !== action.parentId) {
            const orgIndex = organizations?.findIndex((org) => org.id === action.organization.id);
            if (orgIndex > -1) {
                organizations.splice(orgIndex, 1, action.organization);
            } else {
                organizations.unshift(action.organization);
            }
        }
        const organizationLocations = { ...state.organizationLocations };
        if (action.parentId) organizationLocations[action.parentId] = organizations;
        return {
            ...state,
            organizationLocations: organizationLocations,
        };
    }),
    on(LocationActionTypes.SetLocationInAssociationsTree, (state, action) => {
        const orgTree: Organization[] = CommonUtilsService.cloneObject(state.associatedOrganizations) as Organization[];
        setInOrgTree(orgTree, action.organization);
        return {
            ...state,
            associatedOrganizations: orgTree,
        };
    }),
    on(LocationActionTypes.UpdateLocationInAssociationsTree, (state, action) => {
        const orgTree: Organization[] = CommonUtilsService.cloneObject(state.associatedOrganizations);
        action.organizations?.forEach((organization) => {
            const found = updateOrgTree(orgTree, organization);
            const completed = !found && setInOrgTree(orgTree, organization);
            if (!found && !completed) {
                orgTree.push(organization);
            }
        });
        return {
            ...state,
            associatedOrganizations: orgTree,
        };
    }),
    on(LocationActionTypes.SetLocationsCodes, (state, action) => {
        return {
            ...state,
            locationCodes: action.locationCodes,
        };
    }),
    on(LocationActionTypes.SetFlexFields, (state, action) => {
        return {
            ...state,
            flexfields: action.flexFields,
        };
    }),
    on(LocationActionTypes.SetOrganizationLocations, (state, action) => {
        if (!action.organizationId) return state;
        return {
            ...state,
            organizationLocations: {
                ...state.organizationLocations,
                [action.organizationId]: action.organizations,
            },
        };
    }),
    on(LocationActionTypes.SetAssociatedLocations, (state, action) => {
        return {
            ...state,
            associatedOrganizations: action.organizations,
        };
    }),
    on(LocationActionTypes.SetLocationLogo, (state, action) => {
        return {
            ...state,
            logoMapWithHierarchy: {
                ...state.logoMapWithHierarchy,
                [action.currentOrgId]: action.locationHierarchy ? action.logoData : state.logoMapWithHierarchy?.[action.currentOrgId],
            },
            logoMap: {
                ...state.logoMap,
                [action.currentOrgId]: !action.locationHierarchy ? action.logoData : state.logoMap?.[action.currentOrgId],
            },
        };
    }),
    on(LocationActionTypes.SetOrgDetailsMessage, (state, action) => {
        let existingOrgDetails = CommonUtilsService.cloneObject(state.orgDetailsMessage);
        if (action.msgType === 'DETAILS') {
            existingOrgDetails.orgDetails = {
                success: action.isSuccess,
                msg: action.msg,
            };
        } else {
            existingOrgDetails.logo = {
                success: action.isSuccess,
                msg: action.msg,
            };
        }
        return {
            ...state,
            orgDetailsMessage: existingOrgDetails,
        };
    }),
    on(LocationActionTypes.ClearOrgDetailsMessage, (state) => {
        return {
            ...state,
            orgDetailsMessage: {
                orgDetails: undefined,
                logo: undefined,
            },
        };
    }),
    on(LocationActionTypes.SetOrganizationChildHierarchy, (state, action) => {
        return {
            ...state,
            orgChildHierarchy: {
                ...state.organizationLocations,
                [action.orgId]: action.childHierarchy,
            },
        };
    }),
    on(SessionLogoutSuccessfull, () => locationsInitialState)
);

const updateOrgTree = (tree: Organization[], organization: Organization) => {
    let found = false;
    tree?.forEach((org) => {
        if (found) {
            return;
        }
        if (org.id === organization.id) {
            found = true;
            Object.assign(org, organization);
            return;
        }
        if (org.childNodes?.length > 0) {
            found = updateOrgTree(org.childNodes, organization);
        }
    });
    return found;
};

const setInOrgTree = (tree: Organization[], organization: Organization) => {
    let found = false;
    tree?.forEach((org) => {
        if (found) {
            return;
        }
        if (org.id === organization.parentId) {
            found = true;
            org.nextNodes = org.nextNodes || [];
            org.childNodes = org.childNodes || [];
            org.nextNodes.push(organization.id);
            org.childNodes.push(organization);
            return;
        }
        if (org.childNodes?.length > 0) {
            found = setInOrgTree(org.childNodes, organization);
        }
    });
    return found;
};

export const LOCATIONS_REDUCER_KEY = 'locations';
