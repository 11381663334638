import { Component, ElementRef, Input, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { RootScope } from '@enreconcile/services/rootscope/rootscope.service';
import { environment } from '@env';
import { translate } from '@ngneat/transloco';
import { BroadcasterService } from 'ng-broadcaster';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { AssetData } from '../../models/assetdata.class';
import { AssetService } from '../../models/assetservice.class';
import { FilingAttributeField } from '../../models/filingattributefield.class';
import { Organization } from '../../models/organization.class';
import { ReconciliationConfiguration } from '../../models/reconciliation-configuration.class';
import { ReconciliationSource } from '../../models/reconciliation-source.class';
import { ApiService } from '../../services/api/api.service';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { NewProcessService } from '../../services/new-process/new-process.service';
import { UtilsService } from '../../services/utils/utils.service';
import { SaveReconTemplateComponent } from '../saverecontemplate/save-recon-template.component';

@Component({
    selector: 'app-new-reconciliation-process',
    templateUrl: './new-reconciliation-process.component.html',
    styleUrls: ['./new-reconciliation-process.component.scss'],
})
export class NewReconciliationProcessComponent implements OnInit, OnDestroy {
    @Input() selectedTemplateId: string;
    @Input() configuration: BehaviorSubject<ReconciliationConfiguration>;
    @Input() app: AssetService;
    @Input() apps: AssetService[];
    @Input() hideSelectIfValueExists: boolean;
    @Input() model: any;

    @ViewChild('nextBtn', { static: true }) nextBTnDiv: ElementRef;
    @ViewChild('appSettingsDrawer') appSettingsDrawer: MatDrawer;

    public attachmentData = {
        filename: 'File upload from computer',
        url: '',
        title: translate('Add File'),
        return: 'true',
    };
    public clearFiles = new BehaviorSubject(0);

    private unsubscribe = new Subject<void>();
    public inputTypeNames = [];
    public allInputTypes = [];
    public scrollToBottom = new BehaviorSubject(0);
    public sourceTableColumns = [
        {
            name: 'name',
            displayName: 'Source Name',
            show: true,
            type: 'text',
        },
        {
            name: 'sourceDataFormat',
            displayName: 'Supported Format',
            show: true,
            type: 'text',
        },
        {
            name: 'from',
            displayName: 'Choose File',
            show: true,
            type: 'file',
        },
        {
            name: 'downloadSourceTemplate',
            displayName: 'Download Source Template',
            show: true,
            type: 'link',
        },
        {
            name: 'fileExtension',
            displayName: 'File Extension',
            show: false,
            type: 'text',
        },
    ];
    public hidePaginator = true;
    public primaryColumns = ['Source Name', 'Supported Format'];
    public tenantsToRender = [];
    private subTenants: Organization[] = [];
    public selectedTenantId: string;
    public templates: any[] = [];
    public selectedTemplate: BehaviorSubject<any> = new BehaviorSubject({});
    public hideTemplateButton: boolean = true;
    public filingAttributeFields: FilingAttributeField[];
    public allSubscribedApps: AssetService[];
    public reconConfig: ReconciliationConfiguration;
    public saveDialogRef: MatDialogRef<SaveReconTemplateComponent>;
    public appliedTemplateId: string;
    public searchedTemplate: string;

    constructor(
        private _api: ApiService,
        private _newProcessService: NewProcessService,
        private _utils: UtilsService,
        private _broadcaster: BroadcasterService,
        private _commonUtils: CommonUtilsService,
        private dialog: MatDialog,
        public R: RootScope
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.selectedTemplateId.currentValue) {
            this.reconConfig?.sources.forEach((source) => {
                source.search.setCriterias();
            });
            setTimeout(() => {
                this.bindingTemplateValues();
            }, 100);
        }
    }

    private startComponent = async (config: ReconciliationConfiguration) => {
        this.reconConfig = new ReconciliationConfiguration(config, undefined, undefined);
        this.buildTabStructure();
        this._newProcessService.getReconTemplates(config.restapiname);
        const source1Promise = this._newProcessService.getReconciliationTransformations({
            assetId: this.app?.assetMetaUId,
            restApiName: this.app?.restApiName,
            sourceId: this.reconConfig.sources[0]?.id,
        });
        const source2Promise = this._newProcessService.getReconciliationTransformations({
            assetId: this.app?.assetMetaUId,
            restApiName: this.app?.restApiName,
            sourceId: this.reconConfig.sources[1]?.id,
        });
        const appsPromise = this.getSubscribedAppsForCurrentLocation();
        Promise.all([source1Promise, source2Promise, appsPromise]).then(([source1, source2, apps]) => {
            const sources = this.reconConfig.sources;
            sources[0].transformations = source1.transformations;
            sources[0].assetToAssetTransformations = source1.assetToAssetTransformations;
            sources[1].transformations = source2.transformations;
            sources[1].assetToAssetTransformations = source2.assetToAssetTransformations;
            this.filingAttributeFields = CommonUtilsService.cloneObject(this.reconConfig.requestParameters);
            this.allSubscribedApps = apps;
        });
    };

    private getSubscribedAppsForCurrentLocation = (): Promise<AssetService[]> => {
        return new Promise((resolve, reject) => {
            this._api.subscriptions.getSubscribedServices(
                {},
                {
                    successCallback: (response) => {
                        resolve(response as any);
                    },
                    failureCallback: (response) => {
                        this._utils.alertError((response && response.msg) || translate('Failed to get subscribed apps'));
                        reject();
                    },
                }
            );
        });
    };

    private buildTabStructure = () => {
        this.allInputTypes = [];
        this.inputTypeNames = [];
        this.allInputTypes.push(
            {
                name: 'Upload File',
                value: 'UPLOAD',
            },
            {
                name: 'Pull the source file through an App',
                value: 'assetToAsset',
            }
        );
        this.allInputTypes.forEach((element) => {
            this.inputTypeNames.push(element.name);
        });
    };

    public selectFirstTransformation = (source: ReconciliationSource) => {
        const trans = source.transformations?.[0]?.transformations;
        source.selectedTransformationName = trans?.[0]?.id;
        this.getSelectedTransformation(source);
    };

    public getSelectedTransformation = (source: ReconciliationSource) => {
        source.transformationSources = [];
        if (source.selectedTransformationName?.length > 0) {
            for (let i = 0; i < source.transformations.length; i++) {
                for (let j = 0; j < source.transformations[i].transformations.length; j++) {
                    if (source.selectedTransformationName === source.transformations[i].transformations[j].id) {
                        source.selectedRepositoryTransformations = source.transformations[i];
                        source.selectedTransformation = source.transformations[i].transformations[j];
                        source.transformationSources = CommonUtilsService.cloneObject(
                            source.transformations[i].transformations[j].sourceParts
                        );
                    }
                }
            }
            this.scrollToBottm();
        }
        this.buildTableData(source);
    };

    private buildTableData = (source: ReconciliationSource) => {
        source.sourceTableData.next(source.transformationSources);
    };

    public scrollToBottm = (time?) => {
        let timeOut;
        if (time) {
            timeOut = time;
        } else {
            timeOut = 0;
        }
        setTimeout(() => {
            this.scrollToBottom.next(this.scrollToBottom.value + 1);
            this.nextBTnDiv?.['_elementRef']?.['nativeElement']?.focus();
        }, timeOut);
    };

    public getSelectedAssetToAssetTransformation = (source: ReconciliationSource) => {
        // this.transformationSources = [];
        if (source.selectedAssetToAssetTrnsName?.length > 0) {
            for (let i = 0; i < source.assetToAssetTransformations.length; i++) {
                for (let j = 0; j < source.assetToAssetTransformations[i].transformations.length; j++) {
                    if (
                        source.selectedAssetToAssetTrnsName === source.assetToAssetTransformations[i].transformations[j].id ||
                        source.selectedAssetToAssetTrnsName === source.assetToAssetTransformations[i].transformations[j].chainRestApiName
                    ) {
                        source.selectedAssetToAssetTransformation = source.assetToAssetTransformations[i].transformations[j];
                        source.selectedAssetToAssetTrnsName = source.selectedAssetToAssetTransformation?.id;
                        break;
                    }
                }
                if (source.selectedAssetToAssetTransformation) {
                    break;
                }
            }
            if (!this.tenantsToRender || (this.tenantsToRender && this.tenantsToRender.length === 0)) {
                this._newProcessService.getAllTenantsForFlatStructure().then((data) => {
                    this.tenantsToRender = data.tenantsToRender;
                    this.subTenants = data.subTenants;
                    this.selectedTenantId = data.selectedTenantId;
                });
            }
        }
        source.selectedAssetToAssetTrnsName?.length > 0 && this.buildTransformationAsset(source);
    };

    private buildTransformationAsset = async (source: ReconciliationSource) => {
        const transformation = source.selectedAssetToAssetTransformation;
        const appName = transformation?.sourceParts?.[0]?.sourceAssetOrMasterName;
        if (!appName) {
            return;
        }
        const app = this.allSubscribedApps.find((service) => service.name === appName);
        if (!app) {
            this._utils.alertError(
                `${translate('It seems the selected source app')} "${appName}" ${translate('is not subscribed, please subscribe the app.')}`
            );
            return;
        }
        source.assetToAssetTransformationMetaData = await this.getAppMetaData(app);
        const metaData = source.assetToAssetTransformationMetaData;
        source.search.setSources(source.search.convertEntities(metaData.entities));
        source.search.setSource(source.assetToAssetTransformationMetaData.getPrimaryEntity()?.uid);
        source.search.pushFieldOptions(
            'instanceStatus.raw',
            source.assetToAssetTransformationMetaData?.workflowMetadata?.stages?.reduce((options, stage) => {
                options.push({
                    name: stage.displayName || stage.name,
                    value: stage.name,
                });
                return options;
            }, [])
        );
    };

    private getAppMetaData = (app: AssetService) => {
        return new Promise<AssetData>((resolve) => {
            this._api.assets.getAssetMetadata(
                {
                    assetMetaUId: app.assetMetaUId || app.id,
                    name: app.displayName || app.name,
                },
                {
                    successCallback: (response) => {
                        resolve(response);
                    },
                }
            );
        });
    };

    public cancelNewProcessCreation = () => {
        this._utils.navigateToApp({
            appApi: this.app.restApiName,
        });
    };

    private submitAttributeFields = () => {
        return this._newProcessService.validateFilingAttributes(this.filingAttributeFields);
    };

    private validateSourceData = (source: ReconciliationSource) => {
        if (source.selected.value === 0) {
            if (source.selectedTransformationName?.length > 0) {
                const fileSources = source.sourceTableData.value;
                let count = 0;
                let sourceFileFound = 0;
                fileSources.forEach((fileSource) => {
                    if (fileSource.files) {
                        sourceFileFound = sourceFileFound + 1;
                        const givenFileFormat = fileSource.files[0].name.substring(fileSource.files[0].name.lastIndexOf('.'));
                        const requiredFileFormats = [];
                        requiredFileFormats.push(fileSource.fileExtension);
                        requiredFileFormats.push('.zip');
                        if (requiredFileFormats.indexOf(givenFileFormat.toLowerCase()) === -1) {
                            count = count + 1;
                        }
                    }
                });
                if (!sourceFileFound) {
                    source.uploadError = true;
                    this._utils.alertError(translate('Please upload a file'));
                    return false;
                } else {
                    if (count) {
                        this._utils.alertError(`${source.name}: ` + translate('Please select valid file format'));
                        return false;
                    } else {
                        return true;
                    }
                }
            } else {
                const message = `${translate('Please select')} ${source.name} ${translate('transformation')}`;
                source.transformationError = [];
                source.transformationError.push(message) && this._utils.alertError(translate('Please select transformation'));
                return false;
            }
        } else if (source.selected.value === 1) {
            if (source.selectedAssetToAssetTrnsName?.length > 0) {
                if (!source.search.buildCriterias(true)) {
                    return false;
                }
                const selectedTenantIds = Object.keys(source.tenants).reduce((previousValue, tenantId) => {
                    tenantId.indexOf('selectAllOrganizations_') === -1 && source.tenants[tenantId] && previousValue.push(tenantId);
                    return previousValue;
                }, []);
                if (selectedTenantIds.length === 0) {
                    // this._utils.alertError(translate('Please select atleast one location'));
                    // return false;
                }
            } else {
                const message = `${translate('Please select')} ${source.name} ${translate('transformation')}`;
                this._utils.alertError(message);
                return false;
            }
        }
        return true;
    };

    public validateComponents = async () => {
        return new Promise(async (resolve) => {
            let result = true;
            const sources = this.reconConfig?.sources;
            if (this.filingAttributeFields?.length > 0) {
                result = await this.submitAttributeFields();
            }
            if (this.reconConfig.steps.filter((step) => step.selected).length === 0) {
                this._utils.alertError('Please select atleast one step');
                result = false;
            }
            sources.forEach((source) => {
                result = this.validateSourceData(source) && result;
            });
            return resolve(result);
        });
    };

    public selectTenantAndSubTenantsForTransformation = (tenant: Organization, selected: boolean, source: ReconciliationSource) => {
        this.subTenants = this._newProcessService.tenantPreOrderTraversal(tenant, []);
        this.subTenants.forEach((subTenant) => (source.tenants[subTenant.id] = selected));
        this.scrollToBottm();
    };

    public showSubTenants = (tenant: Organization) => {
        this.subTenants = this._newProcessService.tenantPreOrderTraversal(tenant, []);
    };

    public selectIfParentOrg = (event, tenant: Organization, source: ReconciliationSource) => {
        source.tenants[tenant.id] = event;
        const parentId = this.subTenants?.[0]?.id;
        const filteredTenants = (this.subTenants || []).filter((subTenant) => !source.tenants[subTenant.id]);
        if (source.tenants['selectAllOrganizations_' + parentId]) {
            if (filteredTenants.length > 0) {
                source.tenants['selectAllOrganizations_' + parentId] = false;
            }
        } else {
            if (filteredTenants.length === 0) {
                source.tenants['selectAllOrganizations_' + parentId] = true;
            }
        }
        this.scrollToBottm();
    };

    public validateAndSubmitData = async () => {
        if (await this.validateComponents()) {
            this.submitData();
        }
    };

    private submitData = () => {
        const formData = new FormData();
        this.appendFilingAttributeFields(formData);
        this.appendSteps(formData);
        this.reconConfig.sources.forEach((source) => this.appendSourceData(formData, source));
        this._api.requests
            .createNewReconciliation({
                payload: formData,
                restApiName: this.app.restApiName,
            })
            .then((res: any) => {
                this._utils.alertSuccess(res?.message || res?.msg || 'Request created successfully');
                this._broadcaster.broadcast('selectRequest', res?.requestId);
            });
    };

    private appendFilingAttributeFields = (formData: FormData) => {
        this.filingAttributeFields
            .filter((field) => !field.autoCalculate && field.value !== undefined)
            .forEach((field) => {
                if (Array.isArray(field.value)) {
                    field.value.forEach((element) => {
                        formData.append(field.id, element);
                    });
                } else {
                    let value;
                    if (field.uiType === 'DATE') {
                        value = CommonUtilsService.transformDate(field.value as string, 'dd/mm/yyyy', field.outputFormat as any);
                        formData.append(field.id, value);
                    } else {
                        formData.append(field.id, field.value);
                    }
                }
            });
    };

    private appendSteps = (formData: FormData) => {
        const steps = [];
        this.reconConfig.steps.filter((step) => step.selected).forEach((step) => steps.push(step.index));
        formData.append(`${this.reconConfig.restapiname}_steps`, JSON.stringify(steps));
    };

    private appendSourceData = (formData: FormData, source: ReconciliationSource) => {
        switch (source.selected.value) {
            case 0:
                this.appendFile(formData, source);
                break;
            case 1:
                this.appendAssetToAssetTransformationData(formData, source);
                break;
        }
    };

    private appendFile = (formData: FormData, source: ReconciliationSource) => {
        const fileSources = source.sourceTableData.value;
        const isIE = this._utils.isIEBrowser();
        formData.append(`${source.id}_transformation`, source.selectedTransformation.chainRestApiName);
        fileSources.forEach((fileSource) => {
            fileSource.files?.forEach((file) => {
                const name = source.id;
                formData.append(name, file, isIE ? file.name : undefined);
            });
        });
    };

    private appendAssetToAssetTransformationData = (formData: FormData, source: ReconciliationSource) => {
        formData.append(source.id + '_transformation', source.selectedAssetToAssetTransformation.chainRestApiName);
        const requestCriteria = {
            restApiServiceName: source.assetToAssetTransformationMetaData?.restAPIName,
            unitId: this._commonUtils.getFromStorage('currentOrganizationId'),
            orgId: this._commonUtils.getFromStorage('rootOrganizationId'),
            filterCriteria: source.search.optimizeCriteria(),
        };
        requestCriteria.filterCriteria['unitConsiderationEnabled'] = source.unitConsiderationEnabled;
        delete requestCriteria.filterCriteria.outputEntities;
        formData.append(source.id + '_query', JSON.stringify(requestCriteria));
        Object.keys(source.tenants).reduce((previousValue, tenantId) => {
            tenantId.indexOf('selectAllOrganizations_') === -1 && source.tenants[tenantId] && previousValue.push(tenantId);
            return previousValue;
        }, []);
        /**
         * @todo Need to remove below data
         */
        // formData.append(source.id + '_unitIds', this._utils.convertJSONtoBlob(selectedTenantIds));
    };

    public fileChanged = (files, source, record) => {
        record.files = [];
        files.forEach((file: File) => {
            record.files.push(file);
        });
        source.uploadError = false;
        source.sourceTableData.next(source.transformationSources);
    };

    public changedFileName = (fileName: string, source, record) => {
        record.fileName = fileName;
        source.sourceTableData.next(source.transformationSources);
    };

    translateMsg = (msg: string): string => translate(msg);

    public saveOrUpdateTemplate = async () => {
        if (await this.validateComponents()) {
            const formData = new FormData();
            const template = this.templates.find((template) => template.templateId === this.selectedTemplateId);
            const selectedTemplateName = template?.templateName || '';
            formData.append('template_name', selectedTemplateName);
            this.appendFilingAttributeFields(formData);
            this.appendSteps(formData);
            this.reconConfig.sources.forEach((source) => this.appendSourceData(formData, source));
            formData.append('templateId', this.selectedTemplateId);
            if (this.selectedTemplateId) {
                this._newProcessService.saveReconTemplate({
                    formData: formData,
                    restApiName: this.app.restApiName,
                    selectedTemplateId: this.selectedTemplateId,
                });
            } else {
                this.saveDialogRef = this.dialog.open(SaveReconTemplateComponent, {
                    panelClass: ['saveCustomFilterDialog', 'matDialogContainer'],
                    disableClose: true,
                    data: {
                        title: this.translateMsg('Save Template'),
                        formData: formData,
                        restApiName: this.app.restApiName,
                        templates: this.templates,
                    },
                });
            }
        }
    };

    public onInputTypeTabChange = () => {
        this.hideTemplateButton = false;
        this.reconConfig.sources.forEach((src) => {
            if (src.selected.value === 0) {
                this.hideTemplateButton = true;
            }
        });
    };

    public applyReconTemplate = async () => {
        this._utils.navigateToCreateNew(undefined, this.R.current.app.restApiName, undefined, this.appliedTemplateId);
    };

    private bindingTemplateValues = () => {
        const template = this.templates.find((temp) => temp.templateId === this.selectedTemplateId);
        this.appliedTemplateId = template?.templateId;
        if (!template) {
            return;
        }
        this.filingAttributeFields.forEach((attribute) => {
            let value = template?.requestParams[attribute.id];
            attribute.value = value === 'true' ? true : value === 'false' ? false : value;
        });
        this.reconConfig.steps.forEach((step) => {
            step.selected = template?.steps.includes(step.index);
        });
        this.reconConfig.sources.forEach((source) => {
            let sourceDetailsString = template?.sources[source.id];
            let sourceDetails = JSON.parse(sourceDetailsString);
            source.selected.setValue(1);
            source.selectedAssetToAssetTrnsName = template?.transformations[source.id];
            this.getSelectedAssetToAssetTransformation(source);
            source.unitConsiderationEnabled = sourceDetails?.filterCriteria?.unitConsiderationEnabled ? true : false;
            source.search.setCriterias(sourceDetails.filterCriteria);
            source.search.criteriaUpdated = true;
        });
    };

    public viewTemplates = () => {
        this._utils.navigateToTemplates(undefined, this.R.current.app.restApiName, undefined);
    };

    public downloadSourceTemplateUrl = (source: ReconciliationSource, data) => {
        const url = `${environment.taxilla_api}/user-template/download?repositoryId=${source.selectedRepositoryTransformations.repositoryId}&metadataId=${this.app.assetMetaUId}&transformationName=${source.selectedTransformation.chainName}&sourceName=${data.name}`;
        window.open(url);
    };

    ngOnInit(): void {
        this.configuration.pipe(takeUntil(this.unsubscribe)).subscribe((configuration) => {
            configuration?.restapiname && this.startComponent(configuration);
        });
        this._newProcessService.reconProcesssTemplates
            .pipe(
                takeUntil(this.unsubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe((templates: any) => {
                this.templates = templates;
                setTimeout(() => {
                    this.bindingTemplateValues();
                }, 500);
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
