import { Injectable } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { Observable } from 'rxjs';

import { Integration } from '../../interface/integration.interface';
import { AppIntegrationRecord } from '../../models/integrations/appintegrationrecord.class';
import { AppNewIntegrationRecordPayload } from '../../models/integrations/appnewintegrationpayload.class';
import { IntegrationRecordPayload } from '../../models/integrations/integrationrecordpayload.class';
import { BridgeService } from '../bridge/bridge.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
    providedIn: 'root',
})
export class IntegrationsService {
    public appIntegrations: AppIntegrationRecord[] = [];
    constructor(private _bridge: BridgeService, private _utils: UtilsService) {}

    /**
     * Method to test connection of integration record
     * @param payload Contains configuredData, appId, name, organizationId, type
     * @param callbacks Contains success callback method & failure callback method
     */
    public testConnection = (data: { configuredData: string; appId: string; name: string; organizationId: string; type: string }) => {
        return new Promise<{ connected: boolean; msg: string }>((resolve, reject) => {
            this._bridge.testIntegrationRecordConnection(
                data,
                (res) => {
                    resolve(res);
                },
                (response) => {
                    reject(response?.msg || translate('Testing connection failed'));
                }
            );
        });
    };

    /**
     * Method to delete connection of integration record
     * @param payload Contains configuredData, appId, name, organizationId, type
     * @param callbacks Contains success callback method & failure callback method
     */
    public deleteIntegrationRecord = (data: { name: string; organizationId: string; type: string }) => {
        return new Observable<{ connected: boolean; msg: string }>((observer) => {
            this._bridge.deleteIntegrationRecord(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (response) => {
                    observer.error(response?.msg);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to get integration records for a particular configuration
     * @param data Contains config name, isMaster boolean data
     * @param callbacks Contains success callback method & failure callback method
     */
    public getIntegrationConfigurations = (data: {
        config: 'ftp' | 'webservice' | 'sambashare' | 's3' | 'email' | 'restapi';
        dontFetchUsingMasters: boolean;
        isMaster: boolean;
    }) => {
        return new Promise<Integration[]>((resolve, reject) => {
            this._bridge.getIntegrationConfigurations(
                data,
                (res) => {
                    let response;
                    if (Array.isArray(res?.response)) {
                        resolve(res.response);
                    } else if (res?.response) {
                        response = Object.keys(res.response).reduce((configs, key) => {
                            const configItems = Object.keys(res.response[key]).reduce((items, configId) => {
                                items.push({
                                    id: res.response[key][configId],
                                    name: configId,
                                });
                                return items;
                            }, []);
                            configItems?.length > 0 &&
                                configs.push({
                                    orgName: key,
                                    configs: configItems,
                                });
                            return configs;
                        }, []);
                        resolve(response);
                    }
                },
                (response) => {
                    reject(response?.msg || 'Failed to get configurations');
                }
            );
        });
    };

    /**
     * Method to save integration record
     * @param data Contains id
     * @param callbacks Contains success callback method & failure callback method
     */
    getConfigurationById = (
        data: { id: string },
        callbacks?: {
            successCallback: (response: {
                appId: string;
                configuredData: string;
                id: string;
                lastUpdatedBy: string;
                lastUpdatedOn: string;
                name: string;
                organizationId: string;
                type: string;
            }) => void;
            failureCallback?: (...args: any[]) => void;
        }
    ) => {
        this._bridge.getIntegrationConfigurationById(data.id, callbacks.successCallback, (res) => {
            if (callbacks.failureCallback) {
                callbacks.failureCallback(res);
            } else {
                this._utils.alertError((res && res.msg) || 'Failed to get integration configuration');
            }
        });
    };

    /**
     * Method to save integration record
     * @param payload Contains configuredData, id, name, organizationId, type
     * @param callbacks Contains success callback method & failure callback method
     */
    public saveIntegrationRecord = (payload: IntegrationRecordPayload) => {
        return new Promise<IntegrationRecordPayload>((resolve, reject) => {
            this._bridge.saveIntegrationRecord(
                payload,
                (res) => {
                    resolve(res.response || {});
                },
                (res) => {
                    reject(res?.msg || 'Failed to save integration changes');
                }
            );
        });
    };

    /**
     * Method to validate integration record
     * @param payload Contains configuredData, id, name, organizationId, type
     * @param callbacks Contains success callback method & failure callback method
     */
    public validateIntegrationRecord = (payload: IntegrationRecordPayload) => {
        return new Promise<IntegrationRecordPayload>((resolve, reject) => {
            this._bridge.validateIntegrationRecord(
                payload,
                (res) => {
                    resolve(res.response || {});
                },
                (res) => {
                    reject(res?.msg || 'Failed to save integration changes');
                }
            );
        });
    };

    /**
     * Method to masterSyncNow
     * @param data contains integrationType and serviceId
     * @param s Success callback
     * @param f Failure callback
     */
    getAppIntegrations = (
        data: { integrationType: string; serviceId: string },
        callbacks: {
            successCallback: (
                response: {
                    chainName: string;
                    configName: string;
                    configs: {
                        chainName: string;
                        configName: string;
                        configuredData: string;
                        createdBy: string;
                        createdOn: string;
                        id: string;
                        integrationMethod: string;
                        integrationType: string;
                        locationId: string;
                        resourceName: string;
                        schedulerExpression: string;
                        serviceId: string;
                        transformationName: string;
                    }[];
                    integrationType: string;
                    locationId: string;
                    schedulerExpression: string;
                    serviceId: string;
                    transformationName: string;
                }[]
            ) => void;
            failureCallback?: (response) => void;
        }
    ) => {
        this._bridge.getAppResourceIntegrations(
            data,
            (res) => {
                callbacks.successCallback((res && res.response) || []);
                this.appIntegrations = res ? res.response : [];
            },
            (res) => {
                if (callbacks.failureCallback) {
                    callbacks.failureCallback(res);
                } else {
                    this._utils.alertError((res && res.msg) || 'Failed to get integration configurations');
                }
            }
        );
    };

    getAppIntegrationsObservable = (data: { integrationType: string; serviceId: string }) => {
        return new Observable((observer) => {
            this._bridge.getAppResourceIntegrations(
                data,
                (res) => {
                    observer.next(res?.response);
                    observer.complete();
                },
                (res) => {
                    observer.error(res?.msg);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to masterSyncNow
     * @param data contains integrationType and serviceId
     * @param s Success callback
     * @param f Failure callback
     */
    getAppMasterSchedulerIntegrations = (
        data: { integrationType: string; serviceId: string },
        callbacks: {
            successCallback: (
                response: {
                    id: string;
                    locationId: string;
                    serviceId: string;
                    integrationType: string;
                    integrationMethod: string;
                    configName: string;
                    chainName: string;
                    transformationName: string;
                    resourceName: string;
                    configuredData: string;
                    schedulerExpression: string;
                    appId: string;
                    masterId: string;
                    lastExecutionField: string;
                    integrationSettings: {
                        type: string;
                        orgUnitDetail: [
                            {
                                unitId: string;
                            },
                            {
                                unitId: string;
                            }
                        ];
                        attributes: {
                            list: [
                                {
                                    name: string;
                                    value: string;
                                    dataType: string;
                                    dynamic: boolean;
                                },
                                {
                                    name: string;
                                    value: string;
                                    dataType: string;
                                    dynamic: boolean;
                                }
                            ];
                        };
                        active: boolean;
                    };
                }[]
            ) => void;
            failureCallback?: (response) => void;
        }
    ) => {
        this._bridge.getAppMasterSchedulerIntegrations(
            data,
            (res) => {
                callbacks.successCallback(res?.response || []);
            },
            (res) => {
                if (callbacks.failureCallback) {
                    callbacks.failureCallback(res);
                } else {
                    this._utils.alertError(res?.msg || 'Failed to get integration configurations');
                }
            }
        );
    };

    /**
     * Method to delete App integration configuration
     * @param data contains location id, service id, integration type and config name
     * @param s Success callback
     * @param f Failure callback
     */
    delteAppIntegrationConfiguration = (
        data: { locationId: string; serviceId: string; integrationType: string; configName: string },
        callbacks: {
            successCallback: (response) => void;
            failureCallback?: (response) => void;
        }
    ) => {
        this._bridge.deleteAppIntegrationConfiguration(
            data,
            (res) => {
                callbacks.successCallback((res && res.response) || []);
            },
            (res) => {
                if (callbacks.failureCallback) {
                    callbacks.failureCallback(res);
                } else {
                    this._utils.alertError((res && res.msg) || 'Failed to get integration configurations');
                }
            }
        );
    };

    /**
     * Method to delete App integration configuration
     * @param data contains location id, service id, integration type and config name
     * @param s Success callback
     * @param f Failure callback
     */
    deleteAppMasterSchedulerConfiguration = (data: AppNewIntegrationRecordPayload) => {
        return new Promise((resolve) => {
            this._bridge.deleteAppMasterSchedulerConfiguration(
                data,
                (res) => {
                    resolve(res?.response || []);
                },
                (res) => {
                    this._utils.alertError((res && res.msg) || 'Failed to get integration configurations');
                }
            );
        });
    };

    /**
     * Method to run App integration configuration
     * @param data contains location id, service id, integration type and config name
     * @param s Success callback
     * @param f Failure callback
     */
    runAppIntegrationConfiguration = (
        data: { locationId: string; serviceId: string; configName: string; api?: string; isEninvoiceApp?: boolean },
        callbacks: {
            successCallback: (response) => void;
            failureCallback?: (response) => void;
        }
    ) => {
        this._bridge.runAppIntegrationConfiguration(
            data,
            (res) => {
                callbacks.successCallback(res);
            },
            (res) => {
                if (callbacks.failureCallback) {
                    callbacks.failureCallback(res);
                } else {
                    this._utils.alertError(res?.msg || res?.response?.msg || 'Failed to get integration configurations');
                }
            }
        );
    };

    /**
     * Method to test App integration configuration
     * @param data contains location id, service id, integration type and config name
     * @param s Success callback
     * @param f Failure callback
     */
    testAppIntegrationConfiguration = (
        data: {
            chainName: string;
            configName: string;
            configuredData: string;
            createdBy: string;
            createdOn: string;
            id: string;
            integrationMethod: string;
            integrationType: string;
            locationId: string;
            resourceName: string;
            schedulerExpression: string;
            serviceId: string;
            transformationName: string;
        },
        callbacks: {
            successCallback: (response) => void;
            failureCallback?: (response) => void;
        }
    ) => {
        this._bridge.testAppIntegrationConfiguration(
            data,
            (res) => {
                callbacks.successCallback((res && res.response) || {});
            },
            (res) => {
                if (callbacks.failureCallback) {
                    callbacks.failureCallback(res);
                } else {
                    this._utils.alertError(res?.response?.msg || 'Failed to test integration configurations');
                }
            }
        );
    };

    /**
     * Method to get authorization systems
     */
    public getIntegrationsConfig = () => {
        return new Observable<any>((observer) => {
            this._bridge.getIntegrationsConfig(
                (res) => {
                    observer.next(res?.response);
                    observer.complete();
                },
                (response) => {
                    observer.error(response?.msg);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to get authorization systems
     */
    public getAuthorizationSystems = () => {
        return new Observable<any>((observer) => {
            this._bridge.getAuthorizationSystems(
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (response) => {
                    observer.error(response?.msg);
                    observer.complete();
                }
            );
        });
    };

    /**
     * Method to create or update authorization system
     */
    public submitAuthorizationSystems = (data: { authorizationSystem: FormData; update: boolean }) => {
        return new Observable<any>((observer) => {
            this._bridge.submitAuthorizationSystems(
                data,
                (res) => {
                    observer.next(res);
                    observer.complete();
                },
                (res) => {
                    observer.error(res);
                    observer.complete();
                }
            );
        });
    };
}
