import { Injectable } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { ApiService } from '../../services/api/api.service';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { AlertError, AlertSuccess } from '../actions';
import * as actions from '../actions/identity-providers.actions';
import { getIdentityProviders$ } from '../selectors';

@Injectable()
export class IdentityProvidersEffects {
    constructor(private store$: Store, private actions$: Actions, private _api: ApiService) {}

    getIdentityProviders = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.GetIdentityProviders),
            withLatestFrom(this.store$.select(getIdentityProviders$)),
            mergeMap(([_action, identityProviders]) => {
                if (identityProviders?.length > 0) return [];

                return this._api.identityProviders.getIdentityProviders().pipe(
                    map((res: any) => {
                        return actions.SetIdentityProviders({ identityProviders: res });
                    }),
                    catchError((error) => of(AlertError({ message: error.msg || translate('Failed to get identity providers') })))
                );
            })
        );
    });

    createIdentityProvider = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.CreateIdentityProvider),
            mergeMap((action) => {
                return this._api.identityProviders.createIdentityProvider(action.payload).pipe(
                    map((res: any) => {
                        this.store$.dispatch(actions.IdentityProviderCloseDrawer());
                        this.store$.dispatch(AlertSuccess({ message: translate('Identity provider created successfully') }));
                        return actions.SetIdentityProvider({ identityProvider: res.response, actionType: 'CREATE' });
                    }),
                    catchError((error) => of(AlertError({ message: error.msg || translate('Failed to create identity provider') })))
                );
            })
        );
    });

    updateIdentityProvider = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.UpdateIdentityProvider),
            mergeMap((action) => {
                return this._api.identityProviders.updateIdentityProvider(action.data).pipe(
                    map((res: any) => {
                        this.store$.dispatch(actions.IdentityProviderCloseDrawer());
                        this.store$.dispatch(AlertSuccess({ message: res?.msg || translate('Identity provider updated successfully') }));
                        let identityProvider = CommonUtilsService.cloneObject(action.data.payload);
                        identityProvider['id'] = action.data.providerId;
                        return actions.SetIdentityProvider({ identityProvider, actionType: 'EDIT' });
                    }),
                    catchError((error) => of(AlertError({ message: error?.msg || translate('Failed to update identity provider') })))
                );
            })
        );
    });

    deleteIdentityProvider = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.DeleteIdentityProvider),
            mergeMap((action) => {
                return this._api.identityProviders.deleteIdentityProvider(action.id).pipe(
                    map(() => {
                        this.store$.dispatch(AlertSuccess({ message: translate('Identity provider deleted successfully') }));
                        return actions.SetIdentityProvider({ identityProvider: { id: action.id }, actionType: 'DELETE' });
                    }),
                    catchError((error) => of(AlertError({ message: error.msg || translate('Failed to delete identity provider') })))
                );
            })
        );
    });
}
