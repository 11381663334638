import { HttpClient, HttpErrorResponse, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BroadcasterService } from 'ng-broadcaster';

import { UtilsService } from '../utils/utils.service';

@Injectable()
export class FileUpload {
    constructor(private http: HttpClient, private _broadcastService: BroadcasterService, private _utils: UtilsService) {}

    upload = (files, data, url, progressCallBack, successCallBack, failureCallBack) => {
        const formData = new FormData();
        let httpMethod: 'POST' | 'json-blob' | 'blob' | 'PUT' = 'POST';
        if (files) {
            const isIE = this._utils.isIEBrowser();
            for (let i = 0; i < files.length; i++) {
                if (Array.isArray(data.filename)) {
                    if (isIE) {
                        formData.append(data.filename[i], files[i], files[i].name);
                    } else {
                        formData.append(data.filename[i], files[i]);
                    }
                } else {
                    if (isIE) {
                        formData.append(data.filename, files[i], files[i].name);
                    } else {
                        formData.append(data.filename, files[i]);
                    }
                }
            }
        }
        if (data && Object.keys(data).length > 0) {
            for (const key in data) {
                if (key !== 'filename' && key !== 'httpMethod') {
                    formData.append(key, data[key]);
                }
            }
            if (data.httpMethod) {
                httpMethod = data.httpMethod;
            }
        }
        this.uploadRequest(httpMethod, url, formData, progressCallBack, successCallBack, failureCallBack);
    };

    uploadRequest = (method: 'POST' | 'json-blob' | 'blob' | 'PUT', url, formData, progressCallBack, successCallBack, failureCallBack) => {
        const req = new HttpRequest(method, url, formData, {
            reportProgress: true,
        });

        this._utils.showLoading();
        this.http.request(req).subscribe(
            (event) => {
                if (event.type === HttpEventType.UploadProgress) {
                    progressCallBack && progressCallBack(Math.round((100 * event.loaded) / event.total));
                } else if (event instanceof HttpResponse && (event.status === 200 || event.status === 202)) {
                    this._utils.hideLoading();
                    if (!event.body || event.body['success'] === false || event.body['success'] === 'false') {
                        failureCallBack && failureCallBack(event.body);
                    } else {
                        successCallBack && successCallBack(event.body);
                        this._broadcastService.broadcast('fileUploaded');
                    }
                } else if (event instanceof HttpErrorResponse && event.status === 500) {
                    this._utils.hideLoading();
                    failureCallBack && failureCallBack(event && event.error);
                }
            },
            (event) => {
                if (event instanceof HttpErrorResponse) {
                    this._utils.hideLoading();
                    failureCallBack && failureCallBack(event && event.error);
                }
            }
        );
    };

    public uploadRequestViaPromise = (method: 'POST' | 'json-blob' | 'blob' | 'PUT', url: string, formData: FormData) => {
        const req = new HttpRequest(method, url, formData, {
            reportProgress: true,
        });
        this._utils.showLoading();
        return new Promise<any>((resolve, reject) => {
            this.http.request(req).subscribe(
                (event) => {
                    if (event instanceof HttpResponse && (event.status === 200 || event.status === 202)) {
                        this._utils.hideLoading();
                        if (!event.body || event.body['success'] === false || event.body['success'] === 'false') {
                            reject(event.body);
                        } else {
                            resolve(event.body);
                        }
                    } else if (event instanceof HttpErrorResponse && event.status === 500) {
                        this._utils.hideLoading();
                        reject(event?.error?.msg);
                    }
                },
                (event) => {
                    if (event instanceof HttpErrorResponse) {
                        this._utils.hideLoading();
                        reject(event?.error?.msg);
                    }
                }
            );
        });
    };
}
