import { Directive, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env';

import { AssetService } from '../../models/assetservice.class';
import { ApiService } from '../../services/api/api.service';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { UtilsService } from '../../services/utils/utils.service';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[AppSelection]',
})
export class AppSelectionDirective {
    @Input() app: AssetService;
    @Input() bridge: AssetService;
    @Input() report: {
        componentType: string;
        id: string;
        level: number;
        name: string;
        nextNodes: [];
    };
    @Input() navigateToAppHomeOnly: boolean;
    @Input() uiType: string;

    constructor(
        private _api: ApiService,
        private _utils: UtilsService,
        private _commonUtils: CommonUtilsService,
        private _router: Router
    ) {}

    @HostListener('click', ['$event'])
    onAppSelection = async (event?: Event) => {
        if (!this.bridge) {
            await this._api.permissions.transformAppWithPermissions(this.app);
        }
        if (this.app.assetType === 'BRIDGE_ASSET') {
            return;
        } else if (this.app.assetType === 'RECON') {
            return this._utils.navigateToApp({
                appApi: this.app.restApiName,
                bridgeApi: undefined,
                reportApi: undefined,
                switchToUi: 'enreconcile',
            });
        }
        event?.stopPropagation();
        if (['encomply', 'enreport', 'eninvoice', 'encollab', 'enreconcile'].includes(this.uiType)) {
            this.navigateToPlatformSupportedTag(this.uiType);
        } else {
            this.checkRouteBasedOnTag();
        }
    };

    private checkRouteBasedOnTag = async () => {
        const assetTags = this.app.tags || [];
        const tagValues = assetTags.reduce((list, tag) => {
            list.push(tag.tagValue);
            return list;
        }, [] as string[]);
        const currentPlatformAllowedTags = environment.tags;
        const href = window.location.href;
        const enComply = environment['encomply-ui'];
        const enReport = environment['enreport-ui'];
        const enInvoice = environment['eninvoice-ui'];
        const enCollab = environment['encollab-ui'];
        const enReconcile = environment['enreconcile-ui'];
        if (!this.navigateToAppHomeOnly) {
            let platformSupportsTag = false;
            let found = false;
            tagValues?.forEach((tag) => {
                if (platformSupportsTag) {
                    return;
                } else {
                    if (environment.restrictedProcessingUIs?.indexOf(tag.toLowerCase()) >= 0) {
                        tag = environment.defaultProcessingUI ? environment.defaultProcessingUI : 'enreport';
                    }
                    currentPlatformAllowedTags?.forEach((assetTag: string) => {
                        found = assetTag.toLowerCase() === tag.toLowerCase();
                    });
                    platformSupportsTag = platformSupportsTag || found;
                }
            });
            if (platformSupportsTag) {
                this.navigateToPlatformSupportedTag();
            } else {
                const supportedTag = ['eninvoice', 'encollab', 'enreport', 'enreconcile'].find((tag) => tagValues.includes(tag));
                if (supportedTag) {
                    this.navigateToPlatformSupportedTag(supportedTag);
                    return;
                }
                const collaborationApps = await this.fetchCollaborationApps();
                const isEnCollaborateApp = collaborationApps?.find((app) => app.restApiName === this.app.restApiName);
                this.navigateToPlatformSupportedTag(isEnCollaborateApp ? 'encollab' : 'enreport');
            }
        } else if (this.navigateToAppHomeOnly) {
            this.setWindowRoute();
        } else if (
            href.indexOf(enInvoice) > -1 ||
            href.indexOf(enReport) > -1 ||
            href.indexOf(enComply) > -1 ||
            href.indexOf(enCollab) > -1 ||
            href.indexOf(enReconcile) > -1
        ) {
            this.navigateToPlatformSupportedTag();
        } else {
            this.setWindowRoute();
        }
    };

    private fetchCollaborationApps = (): Promise<AssetService[]> => {
        return new Promise((resolve) => {
            this._api.subscriptions.getCollaboratedApps(
                {},
                {
                    successCallback: (response) => {
                        resolve(response as any);
                    },
                }
            );
        });
    };

    private navigateToPlatformSupportedTag = (uiType?: string) => {
        const app = this.app;
        if (
            !app.canViewAllProcesses &&
            !app.canCreateNewProcess &&
            !app.canManageWorkflow &&
            !app.canViewIntergrations &&
            !app.canViewVDM
        ) {
            return this._utils.alertError('You donot have permissions for viewing the processes.');
        }
        const routes = [];
        const href = window.location.href;
        const enReport = environment['enreport-ui'];
        const enInvoice = environment['eninvoice-ui'];
        const enCollab = environment['encollab-ui'];
        const enReconcile = environment['enreconcile-ui'];
        const isInEnInvoice = href.indexOf(enInvoice) > -1 || uiType === 'eninvoice';
        const isInEnCollab = href.indexOf(enCollab) > -1 || uiType === 'encollab';
        const isInEnRecon = href.indexOf(enReconcile) > -1 || uiType === 'enreconcile';
        if (uiType) {
            switch (uiType) {
                case 'encomply':
                    routes.push('enreport');
                    break;
                default:
                    routes.push(uiType);
                    break;
            }
        } else {
            if (isInEnInvoice) {
                routes.push('eninvoice');
            } else if (isInEnCollab) {
                routes.push('encollab');
            } else if (isInEnRecon) {
                routes.push('enreconcile');
            } else if (href.indexOf(enReport) > -1 || isInEnInvoice || isInEnCollab || isInEnRecon) {
                routes.push('enreport');
            }
        }
        routes.push('organizations', this._commonUtils.getFromStorage('currentOrganizationId'));
        isInEnRecon && (uiType === undefined || uiType === 'enreconcile') && routes.push('home');
        const queryParams: { [property: string]: string } = {};
        if (this.uiType === 'encollab' && !this.report) {
            queryParams['appName'] = app.displayName || app.name;
        } else {
            if (this.bridge) {
                routes.push('packages', this.bridge.restApiName);
            }
            if (app) {
                routes.push('apps', app.restApiName);
            }
            if (this.report) {
                routes.push('reports', encodeURIComponent(this.report.name));
            }
        }
        if (!isInEnInvoice && !isInEnCollab && !enReconcile) {
            if (this.report) {
                routes.push('report-transmissions');
            } else {
                if (href.indexOf(enReport) > -1) {
                    routes.push('processes');
                    if (!this.report) {
                        if (app.canViewAllProcesses) {
                            routes.push('state', 'all');
                        } else {
                            return this._utils.alertError('You donot have permissions for viewing the processes.');
                        }
                    }
                } else {
                    routes.push('home');
                }
            }
        } else if (!this.report) {
            routes.push('processes');
        }
        this._router.navigate(routes, {
            queryParams,
        });
    };

    private setWindowRoute = () => {
        let subRoute = '';
        const href = window.location.href;
        const enComply = environment['encomply-ui'];
        const enReport = environment['enreport-ui'];
        if (href.indexOf(enComply) > -1 || href.indexOf(enReport) > -1) {
            subRoute += 'organizations/' + this._commonUtils.getFromStorage('currentOrganizationId') + '/';
        }
        if (this.bridge && this.bridge.restApiName) {
            subRoute += 'packages/' + this.bridge.restApiName + '/';
        }
        if (this.app && this.app.restApiName) {
            subRoute += 'apps/' + this.app.restApiName;
        }
        if (this.report) {
            subRoute += '/reports/' + encodeURIComponent(this.report.name);
        }
        subRoute += '/home';
        this._commonUtils.setInStorage('app-home-redirect-url', window.location.pathname);
        this._router.navigateByUrl(subRoute);
    };
}
