import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { createEntityAdapter } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, delay, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { ApiService } from '../../services/api/api.service';
import { AlertError, AlertSuccess, SetManageAppsBundleSubscriptionIds } from '../actions';
import * as actions from '../actions/bundles.actions';
import { SetCurrentOrganization } from '../actions/session.actions';
import { getManageAppsBundleSubscribedAppIds$, getManageAppsSelectedServicesIds$ } from '../selectors';
import {
    getBundleInvitations$,
    getBundles$,
    getBundlesOrganization$,
    getBundlesOrganizationEntity$,
    getSelectedBundle$,
    getSelectedBundleId$,
} from '../selectors/bundles.selector';
import { Bundle, bundleAdapters, BundlesOrganizationEntity, Invitation } from '../states/bundles.state';

@Injectable()
export class BundleEffects {
    constructor(private actions$: Actions, private _api: ApiService, private store$: Store) {}

    setCurrentOrganization$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SetCurrentOrganization),
            delay(10),
            withLatestFrom(this.store$.select(getBundlesOrganizationEntity$)),
            mergeMap(([action, organizationEntity]) => {
                if (!organizationEntity) {
                    const servicesAdapter = createEntityAdapter<Bundle>();
                    const bundlesEntity: BundlesOrganizationEntity = {
                        id: action.organization?.id,
                        bundles: servicesAdapter.getInitialState(),
                    };
                    bundleAdapters[action.organization.id] = servicesAdapter;
                    return of(
                        actions.SetBundlesOrganizationEntity({
                            bundlesEntity,
                            organizationsAdapter: bundleAdapters.organizations,
                        })
                    );
                }
                return [];
            })
        );
    });

    getBundles$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.GetBundles),
            delay(10),
            withLatestFrom(this.store$.select(getBundlesOrganization$), this.store$.select(getBundles$)),
            mergeMap(([_action, organizationId, bundles]) => {
                if (bundles?.length > 0) {
                    return [];
                }
                return from(this._api.assets.getBundles()).pipe(
                    map((response) => {
                        return actions.SetBundles({
                            organizationsAdapter: bundleAdapters.organizations,
                            bundlesAdapter: bundleAdapters[organizationId],
                            bundlesResponse: response as Bundle[],
                        });
                    }),
                    catchError((error) => of(AlertError({ message: error })))
                );
            })
        );
    });

    submitBundleDetails$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.SubmitBundleDetails),
            withLatestFrom(this.store$.select(getBundlesOrganization$), this.store$.select(getSelectedBundle$)),
            mergeMap(([action, organizationId, bundle]) => {
                const payload = {
                    description: action.description,
                    id: action.id,
                    name: action.name,
                };
                if (
                    JSON.stringify(payload) ===
                    JSON.stringify({
                        description: bundle.description,
                        id: bundle.id,
                        name: bundle.name,
                    })
                ) {
                    return of(actions.BundleUpdateSuccess({ success: true }));
                }
                return from(this._api.assets.updateBundle(payload)).pipe(
                    map((res: any) => {
                        res?.msg && this.store$.dispatch(AlertSuccess({ message: res?.msg }));
                        if (!action.id) {
                            this.store$.dispatch(
                                actions.AddBundleDetails({
                                    organizationsAdapter: bundleAdapters.organizations,
                                    bundlesAdapter: bundleAdapters[organizationId],
                                    response: res?.response,
                                })
                            );
                            this.store$.dispatch(actions.SelectBundle({ id: res?.response?.id }));
                            return actions.BundleUpdateSuccess({ success: true });
                        }
                        this.store$.dispatch(actions.BundleUpdateSuccess({ success: true }));
                        return actions.UpdateBundleDetails({
                            organizationsAdapter: bundleAdapters.organizations,
                            bundlesAdapter: bundleAdapters[organizationId],
                            response: {
                                description: action.description,
                                id: action.id,
                                name: action.name,
                            } as any,
                        });
                    }),
                    catchError((error) => of(AlertError({ message: error })))
                );
            })
        );
    });

    updateBundleAppSubscriptions$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.UpdateBundleAppSubscriptions),
            withLatestFrom(
                this.store$.select(getSelectedBundleId$),
                this.store$.select(getManageAppsSelectedServicesIds$),
                this.store$.select(getManageAppsBundleSubscribedAppIds$)
            ),
            mergeMap(([_action, bundleId, selectedIds, subscriptionIds]) => {
                if (JSON.stringify(selectedIds) === JSON.stringify(subscriptionIds)) {
                    return of(actions.BundleUpdateSuccess({ success: true }));
                }
                return from(
                    this._api.assets.updateBundleSubscriptions(bundleId, {
                        serviceIds: selectedIds,
                    })
                ).pipe(
                    map((response: any) => {
                        response?.msg && this.store$.dispatch(AlertSuccess({ message: response?.msg }));
                        this.store$.dispatch(actions.BundleUpdateSuccess({ success: true }));
                        return SetManageAppsBundleSubscriptionIds({
                            ids: selectedIds as string[],
                        });
                    }),
                    catchError((error) => of(AlertError({ message: error })))
                );
            })
        );
    });

    getBundleInvitations$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.GetBundleInvitations),
            delay(10),
            withLatestFrom(this.store$.select(getSelectedBundleId$), this.store$.select(getBundleInvitations$)),
            mergeMap(([_action, bundleId, invitations]) => {
                if (invitations?.length > 0) {
                    return [];
                }
                return from(this._api.assets.getBundleInvitations(bundleId)).pipe(
                    map((response) => {
                        return actions.SetBundleInviations({
                            invitations: response as Invitation[],
                        });
                    }),
                    catchError((error) => of(AlertError({ message: error })))
                );
            })
        );
    });

    deleteBundleInvitation$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.DeleteBundleInvitation),
            delay(10),
            withLatestFrom(this.store$.select(getSelectedBundleId$), this.store$.select(getBundleInvitations$)),
            mergeMap(([action, bundleId, invitations]) => {
                return from(this._api.assets.deleteBundleInvitations(bundleId, action.invitationId)).pipe(
                    map((response) => {
                        response && this.store$.dispatch(AlertSuccess({ message: response }));
                        return actions.SetBundleInviations({
                            invitations: invitations
                                .filter((invitation) => invitation.id !== action.invitationId)
                                .reduce((newList, invitation) => {
                                    newList.push(invitation);
                                    return newList;
                                }, []),
                        });
                    }),
                    catchError((error) => of(AlertError({ message: error })))
                );
            })
        );
    });

    deleteBundle$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.DeleteBundle),
            withLatestFrom(this.store$.select(getBundlesOrganization$)),
            mergeMap(([action, organizationId]) => {
                return from(this._api.assets.deleteBundle(action.bundleId)).pipe(
                    map((response) => {
                        response && this.store$.dispatch(AlertSuccess({ message: response }));
                        return actions.RemoveBundle({
                            organizationsAdapter: bundleAdapters.organizations,
                            bundlesAdapter: bundleAdapters[organizationId],
                            bundleId: action.bundleId,
                        });
                    }),
                    catchError((error) => of(AlertError({ message: error })))
                );
            })
        );
    });

    sendBundleInvitations$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.SendBundleInvitations),
            withLatestFrom(this.store$.select(getSelectedBundleId$), this.store$.select(getBundleInvitations$)),
            mergeMap(([action, bundleId, invitations]) => {
                return from(this._api.assets.createBundleInvitations(bundleId, action.partnerRefs)).pipe(
                    map((res) => {
                        res?.msg && this.store$.dispatch(AlertSuccess({ message: res?.msg }));
                        this.store$.dispatch(actions.SendBundleInvitationSuccess({ success: true }));
                        if (invitations?.length > 0) {
                            return actions.SetBundleInviations({
                                invitations: [...invitations, ...res?.response?.invitations],
                            });
                        }
                        return actions.GetBundleInvitations({ id: bundleId });
                    }),
                    catchError((error) => of(AlertError({ message: error })))
                );
            })
        );
    });
}
