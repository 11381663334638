import { Directive } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';

import { MY_DATE_FORMATS, MyDateAdapter } from '../../../../../extensions/dateadapter/date.adapter';

@Directive({
    selector: '[appDefaultDateFormat]',
    providers: [
        { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
        { provide: DateAdapter, useClass: MyDateAdapter },
    ],
})
export class DefaultDateFormatDirective {}

/**
 * For dd/yyyy/MM
 */
@Directive({
    selector: '[appCustomDateFormat2]',
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'dd/yyyy/MM',
                },
                display: {
                    dateInput: 'dd/yyyy/MM',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
        { provide: DateAdapter, useClass: MyDateAdapter },
    ],
})
export class CustomDateFormat2Directive {}

/**
 * For MM/dd/yyyy
 */
@Directive({
    selector: '[appCustomDateFormat3]',
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'MM/dd/yyyy',
                },
                display: {
                    dateInput: 'MM/dd/yyyy',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
        { provide: DateAdapter, useClass: MyDateAdapter },
    ],
})
export class CustomDateFormat3Directive {}

/**
 * For yyyy-MM-dd
 */
@Directive({
    selector: '[appCustomDateFormat4]',
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'yyyy-MM-dd',
                },
                display: {
                    dateInput: 'yyyy-MM-dd',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
        { provide: DateAdapter, useClass: MyDateAdapter },
    ],
})
export class CustomDateFormat4Directive {}

/**
 * For ddMMyyyy
 */
@Directive({
    selector: '[appCustomDateFormat5]',
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'ddMMyyyy',
                },
                display: {
                    dateInput: 'ddMMyyyy',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
        { provide: DateAdapter, useClass: MyDateAdapter },
    ],
})
export class CustomDateFormat5Directive {}

/**
 * For dd-mm-yyyy
 */
@Directive({
    selector: '[appCustomDateFormat6]',
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'dd-mm-yyyy',
                },
                display: {
                    dateInput: 'dd-mm-yyyy',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
        { provide: DateAdapter, useClass: MyDateAdapter },
    ],
})
export class CustomDateFormat6Directive {}

/**
 * For DD-MMM-YYYY
 */
@Directive({
    selector: '[appCustomDateFormat7]',
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'DD-MMM-YYYY',
                },
                display: {
                    dateInput: 'DD-MMM-YYYY',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
        { provide: DateAdapter, useClass: MyDateAdapter },
    ],
})
export class CustomDateFormat7Directive {}

/**
 * For YYYY/dd/MM
 */
@Directive({
    selector: '[appCustomDateFormat8]',
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'yyyy/dd/MM',
                },
                display: {
                    dateInput: 'yyyy/dd/MM',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
        { provide: DateAdapter, useClass: MyDateAdapter },
    ],
})
export class CustomDateFormat8Directive {}

@Directive({
    selector: '[appCustomDateFormat9]',
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'MM-yyyy-dd',
                },
                display: {
                    dateInput: 'MM-yyyy-dd',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
        { provide: DateAdapter, useClass: MyDateAdapter },
    ],
})
export class CustomDateFormat9Directive {}

/**
 * For yyyy/MM/dd
 */
@Directive({
    selector: '[appCustomDateFormat10]',
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'yyyy/MM/dd',
                },
                display: {
                    dateInput: 'yyyy/MM/dd',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
        { provide: DateAdapter, useClass: MyDateAdapter },
    ],
})
export class CustomDateFormat10Directive {}

/**
 * For yyyy-dd-MM
 */
@Directive({
    selector: '[appCustomDateFormat11]',
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'yyyy-dd-MM',
                },
                display: {
                    dateInput: 'yyyy-dd-MM',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
        { provide: DateAdapter, useClass: MyDateAdapter },
    ],
})
export class CustomDateFormat11Directive {}

/**
 * For dd-yyyy-MM
 */
@Directive({
    selector: '[appCustomDateFormat12]',
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'dd-yyyy-MM',
                },
                display: {
                    dateInput: 'dd-yyyy-MM',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
        { provide: DateAdapter, useClass: MyDateAdapter },
    ],
})
export class CustomDateFormat12Directive {}

/**
 * For MM-dd-yyyy
 */
@Directive({
    selector: '[appCustomDateFormat13]',
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'MM-dd-yyyy',
                },
                display: {
                    dateInput: 'MM-dd-yyyy',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
        { provide: DateAdapter, useClass: MyDateAdapter },
    ],
})
export class CustomDateFormat13Directive {}

/**
 * For MM/yyyy/dd
 */
@Directive({
    selector: '[appCustomDateFormat14]',
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'MM/yyyy/dd',
                },
                display: {
                    dateInput: 'MM/yyyy/dd',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
        { provide: DateAdapter, useClass: MyDateAdapter },
    ],
})
export class CustomDateFormat14Directive {}

/**
 * For yyyyMMdd
 */
@Directive({
    selector: '[appCustomDateFormat15]',
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'yyyyMMdd',
                },
                display: {
                    dateInput: 'yyyyMMdd',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
        { provide: DateAdapter, useClass: MyDateAdapter },
    ],
})
export class CustomDateFormat15Directive {}
