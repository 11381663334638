import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { translate } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import {
    ClearUpdatePasswordSuccessfullMessage,
    getUpdatePasswordResponse$,
    GetUserSettings,
    getUserSettingsState$,
    UpdatePassword,
    UtilsService,
} from 'taxilla-library';

@Component({
    selector: 'app-changepassword',
    templateUrl: './changepassword.component.html',
    styleUrls: ['./changepassword.component.scss'],
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
    errors: { [property: string]: string[] } = {};
    passwords = {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    };

    // passwordPolicyRegex: string;
    // passwordPolicyMsg: string;
    settings: {
        passwordPolicy: string;
        passwordPolicyMsg: string;
    };
    private unsubscribe = new Subject<void>();

    constructor(public _utils: UtilsService, private dialog: MatDialog, private ngRxStore: Store) {}

    closeModal = () => {
        this.dialog.closeAll();
    };

    submitPassword = () => {
        if (this.validateFields()) {
            this.ngRxStore.dispatch(
                UpdatePassword({
                    oldPassword: this.passwords.oldPassword,
                    newPassword: this.passwords.newPassword,
                    confirmPassword: this.passwords.confirmPassword,
                })
            );
        } else {
            this._utils.alertError(translate('Enter valid details to proceed'));
        }
    };

    validateFields = (): boolean => {
        let count = 0;
        const passwordMessage = this._utils.checkPassword(
            this.passwords.newPassword,
            this.settings?.passwordPolicy,
            this.settings?.passwordPolicyMsg
        );
        if (!this.passwords.oldPassword || this.passwords.oldPassword.length === 0) {
            this.errors.oldPassword = [translate('Enter current password')];
            count++;
        }
        if (!this.passwords.newPassword || this.passwords.newPassword.length === 0) {
            this.errors.newPassword = [translate('Enter new password')];
            count++;
        } else if (passwordMessage !== true) {
            this.errors.newPassword = [passwordMessage || translate('error_newPassword')];
            count++;
        }
        if (!this.passwords.confirmPassword || this.passwords.confirmPassword.length === 0) {
            this.errors.confirmPassword = [translate('Enter confirm password')];
            count++;
        } else if (this.passwords.newPassword !== this.passwords.confirmPassword) {
            this.errors.confirmPassword = [translate('Passwords do not match')];
            count++;
        }
        return count === 0;
    };

    resetPasswordObj = () => {
        this.passwords = {
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
        };
    };

    private initiateSubscriptions = () => {
        this.ngRxStore
            .select(getUserSettingsState$)
            .pipe(
                takeUntil(this.unsubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe((data) => {
                this.settings = data;
            });
        this.ngRxStore
            .select(getUpdatePasswordResponse$)
            .pipe(
                takeUntil(this.unsubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe(() => {
                this.closeModal();
            });
    };

    private runPreLoadDispatches = () => {
        this.ngRxStore.dispatch(ClearUpdatePasswordSuccessfullMessage());
    };

    private initiateDispatches = () => {
        this.ngRxStore.dispatch(GetUserSettings());
    };

    ngOnInit() {
        this.runPreLoadDispatches();
        this.initiateSubscriptions();
        this.initiateDispatches();
        this.resetPasswordObj();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
