import { createAction, props } from '@ngrx/store';

import { Dashboard } from '../../interface/dashboard.interface';

export enum DashboardActionType {
    DASHBAORD_SELECT = '[DASHBOARD] Select',
    GET_DASHBOARDS = '[DASHBOARD] Get Dashboards',
    SET_DASHBOARDS = '[DASHBOARD] Set Dashboards',
    SET_DASHBOARD_LOADER = '[DASHBOARD] Set Dashboard Loader',
    GET_FILTERING_ATTRIBUTES_NAMEVSVALUES = '[DASHBOARD] Get Filtering Attributes Name Vs Values',
    SET_FILTERING_ATTRIBUTES_NAMEVSVALUES = '[DASHBOARD] Set Filtering Attributes Name Vs Values',
    GET_FILTERING_ATTRIBUTES_METADATA = '[DASHBOARD] Get Filtering Attributes Metadata',
    SET_FILTERING_ATTRIBUTES_METADATA = '[DASHBOARD] Set Filtering Attributes Metadata',
    UPDATE_FILTERING_ATTRIBUTES = '[DASHBOARD] Update Filtering Attributes',
}

export const SelectDashboard = createAction(
    DashboardActionType.DASHBAORD_SELECT,
    props<{
        dashboard: Dashboard;
    }>()
);

export const GetDashboards = createAction(
    DashboardActionType.GET_DASHBOARDS,
    props<{
        noAlerts?: boolean;
    }>()
);

export const SetDashboards = createAction(
    DashboardActionType.SET_DASHBOARDS,
    props<{
        analyticDashboards: [];
        currentOrganizationId: string;
    }>()
);

export const SetDashboardLoader = createAction(
    DashboardActionType.SET_DASHBOARD_LOADER,
    props<{
        isDashboardsLoading: boolean;
    }>()
);

export const GetFilteringAttributesNameVsValues = createAction(
    DashboardActionType.GET_FILTERING_ATTRIBUTES_NAMEVSVALUES,
    props<{
        serviceId: string;
    }>()
);

export const SetFilteringAttributesNameVsValues = createAction(
    DashboardActionType.SET_FILTERING_ATTRIBUTES_NAMEVSVALUES,
    props<{
        filteringAttributes: any;
        serviceId: string;
        organizationId: string;
    }>()
);

export const GetFilteringAttributesMetadata = createAction(
    DashboardActionType.GET_FILTERING_ATTRIBUTES_METADATA,
    props<{
        serviceId: string;
    }>()
);

export const SetFilteringAttributesMetadata = createAction(
    DashboardActionType.SET_FILTERING_ATTRIBUTES_METADATA,
    props<{
        metadata: any;
        serviceId: string;
        organizationId: string;
    }>()
);

export const UpdateFilteringAttributes = createAction(
    DashboardActionType.UPDATE_FILTERING_ATTRIBUTES,
    props<{
        payload: any;
        actionType: 'UPDATE' | 'RESET';
    }>()
);
