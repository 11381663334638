import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TaxillaApiService } from '@encomply-core/services/taxillaapi/taxillaapi.service';
import { AssetService, Field, RecordField, SetupAttributesInterface } from 'taxilla-library';

@Component({
    selector: 'app-assetlookup',
    templateUrl: './assetlookup.component.html',
    styleUrls: ['./assetlookup.component.scss'],
})
export class AssetlookupComponent implements OnInit, OnChanges {
    @Input() field: SetupAttributesInterface['field'] | any;
    @Input() conditionalValue: string;
    @Input() required: boolean;
    @Input() errors: [];
    @Input() item;
    @Input() disabled: boolean;
    @Input() service: AssetService;

    @Input() fieldValue: SetupAttributesInterface['fieldValue'];

    @Output() modelChange = new EventEmitter();

    options: any[];

    constructor(private _taxilla: TaxillaApiService) {}

    ngOnChanges(changes: SimpleChanges) {
        // Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
        if (changes.conditionalValue) {
            if (!changes.conditionalValue.firstChange) {
                if (this.field instanceof RecordField) {
                    this.field.value = '';
                } else {
                    this.fieldValue = '';
                }
                this.modelChange.emit('');
                this.fetchOptionsByCondition();
            } else if (
                changes.conditionalValue &&
                changes.conditionalValue.currentValue &&
                changes.conditionalValue.currentValue.length > 0
            ) {
                this.fetchOptionsByCondition();
            }
        }
    }

    fetchOptionsByCondition = () => {
        if (this.field instanceof RecordField) {
            this._taxilla.lookups.fetchLookupValuesByRecordField(
                {
                    conditionalValue: this.conditionalValue,
                    field: this.field,
                    service: this.service,
                },
                {
                    successCallback: (lookupValues) => {
                        if (this.field instanceof RecordField) {
                            this.field.setLookupValues(lookupValues);
                            this.loadOptions();
                        } else {
                            const response = lookupValues && lookupValues.displayVsValue;
                            this.options = [];
                            Object.keys(response).forEach((key) => {
                                this.options.push({
                                    displayName: key,
                                    value: response[key],
                                });
                            });
                        }
                        if (this.options && this.options.length <= 2 && this.field.originalUiType === 'RADIOBUTTON') {
                            this.field.uiType = 'RADIOBUTTON';
                        }
                    },
                }
            );
        } else if (this.field instanceof Field) {
        } else {
            this._taxilla.lookups.fetchLookupValuesByField(
                {
                    conditionalValue: this.conditionalValue,
                    field: this.field,
                    service: this.service,
                },
                {
                    successCallback: (lookupValues) => {
                        if (this.field instanceof RecordField) {
                            this.field.setLookupValues(lookupValues);
                            this.loadOptions();
                        } else {
                            const response = lookupValues && lookupValues.displayVsValue;
                            this.options = [];
                            Object.keys(response).forEach((key) => {
                                this.options.push({
                                    displayName: key,
                                    value: response[key],
                                });
                            });
                        }
                        if (this.options && this.options.length <= 2 && this.field.originalUiType === 'RADIOBUTTON') {
                            this.field.uiType = 'RADIOBUTTON';
                        }
                    },
                }
            );
        }
    };

    loadOptions = () => {
        if (this.field instanceof RecordField && this.field.lookupValues && this.field.lookupValues.displayVsValue) {
            this.options = [];
            for (let i = 0; i < this.field.lookupValues.displayVsValue.length; i++) {
                this.options.push({
                    displayName: this.field.lookupValues.displayVsValue[i].key,
                    value: this.field.lookupValues.displayVsValue[i].value,
                });
            }
        } else {
            this.fetchOptionsByCondition();
        }
    };

    checkIfRadioBtnSelected = (option) => {
        if (this.item && this.item.fieldValue && this.item.fieldValue === option.displayName) {
            return true;
        }
        return false;
    };

    ngOnInit() {
        this.loadOptions();
    }
}
