import { Injectable } from '@angular/core';
import { WidgetService } from '@eninvoice-app/class/widget.class';
import { environment } from '@env';
import { BehaviorSubject } from 'rxjs';
import {
    AssetData,
    AssetService,
    AuditTrailLog,
    BridgeNode,
    Masters,
    MastersTableMetaDataInterface,
    Organization,
    User,
    UserPermissions,
} from 'taxilla-library';

@Injectable({
    providedIn: 'root',
})
export class RootScope {
    loadingAssetData = false;
    session = new BehaviorSubject<boolean>(false);
    TAXILLA_API_URL = environment.taxilla_api;
    TAXILLA_IDM_API_URL = environment.taxilla_user_identity_api;
    TAXILLA_SEARCH_API = environment.taxilla_search_api;
    ENTRANSACT_API_URL = environment.eninvoice_api;
    TAXILLA_HOST_API_URL = environment['encomply-ui'];
    BASE_HREF = environment['eninvoiceBaseHref'];
    LOGO_HREF = environment['eninvoiceLogoHref'];
    ENVNAME = environment.envName;
    APPS_CONFIG = environment['eninvoiceAppsConfig'];
    currentOrganization = new BehaviorSubject(new Organization({}));
    currentOrganizationId = new BehaviorSubject('');
    rootOrganization = new Organization({});
    switchB: boolean;
    rootOrganizationId: string;
    stateOrganization = {};
    stateOrganizationId: string;
    cityOrganization = {};
    cityOrganizationId: string;
    user = new User({});
    locations: any[] = [];
    countries: any[];
    states: any[];
    cities: any;
    switchedCities = [];
    subscribedServices = new BehaviorSubject<AssetService[]>([]);
    subscribedWidgets = new BehaviorSubject([]);
    invoicePopupVariable: boolean;
    SwitchVariable: boolean;
    permissions = new UserPermissions({});
    permissionsSource = new BehaviorSubject<UserPermissions>({});
    currentPermissionsObject = this.permissionsSource.asObservable();
    rootLocations: Organization[] = [];
    stateLocations: Organization[] = [];
    assetMetadata = {};
    assetMetadataId: any;
    cancelLookup = [];
    firstEvents = true;
    partnerDetails = {};
    selectedState = new BehaviorSubject(new Organization({}));
    selectedLocation = new BehaviorSubject(new Organization({}));
    selectedTenant = new BehaviorSubject(new Organization({}));
    userPermissions: BehaviorSubject<any> = new BehaviorSubject(undefined);
    loadedAnalytics = false;
    orientation: string;
    device: string;
    assetsData: {
        assets: AssetService[];
        widgets: WidgetService[];
        assetsData: AssetData[];
        mastersTables: { property?: MastersTableMetaDataInterface };
        masters: Masters;
        current: {
            service: BehaviorSubject<AssetService>;
            assetRestApi: BehaviorSubject<string>;
            bridgeRestApi: BehaviorSubject<string>;
            assetData: BehaviorSubject<AssetData>;
            // recordId: BehaviorSubject<string>,
            master: BehaviorSubject<any[]>;
            selectedMaster: BehaviorSubject<string>;
            bridge: BehaviorSubject<AssetService>;
            reportName: BehaviorSubject<string>;
            report: BehaviorSubject<BridgeNode>;
        };
    } = {
        assets: [],
        assetsData: [],
        widgets: [],
        mastersTables: {},
        masters: new Masters(),
        current: {
            service: new BehaviorSubject(new AssetService({})),
            assetRestApi: new BehaviorSubject(''),
            bridgeRestApi: new BehaviorSubject(''),
            assetData: new BehaviorSubject(new AssetData({})),
            // recordId: new BehaviorSubject(''),
            master: new BehaviorSubject([]),
            selectedMaster: new BehaviorSubject(''),
            bridge: new BehaviorSubject(new AssetService()),
            reportName: new BehaviorSubject(undefined),
            report: new BehaviorSubject(undefined),
        },
    };
    startingChartDate: string;
    endingChartDate: string;
    isAdmin: boolean;
    googleTrackingCode = 'UA-144014959-1';
    isAdvancedSearchQuery = new BehaviorSubject(false);
    customSearchCriteria = new BehaviorSubject(undefined);
    customSortCriteria = new BehaviorSubject<boolean>(false);
    showRequestBasedProcesses = new BehaviorSubject<boolean>(false);
    searchDefaultFilters = new BehaviorSubject<boolean>(false);
    defaultFilterOptionValue: string;
    subTenants: any[];
    currentInstanceId: BehaviorSubject<string> = new BehaviorSubject(undefined);
    enableEntityActions: BehaviorSubject<boolean> = new BehaviorSubject(false);
    relatedApps = [];
    relatedTenants = [];
    allSubscribedApps: AssetService[] = [];
    reportRestApiName: any;
    reportsServiceData: AssetService;
    storedSearchFilters = [];
    isSearchFiltersLoaded: boolean;
    collaboration = {
        userId: '',
        collaborationRequestId: '',
        unitId: '',
        restAPIName: '',
        assetId: '',
        instanceId: '',
        requestId: '',
        expired: false,
        requestedBy: '',
        collaborators: '',
        token: '',
        completed: false,
        id: '',
        serviceId: '',
    };
    eiOrganizations = null;
    ewaybillApps = [
        {
            assetName: this.APPS_CONFIG && this.APPS_CONFIG['outwardEwb'] ? this.APPS_CONFIG['outwardEwb'].name : 'E-Waybill-V12A',
            restApiName: this.APPS_CONFIG && this.APPS_CONFIG['outwardEwb'] ? this.APPS_CONFIG['outwardEwb'].restAPIName : 'ewaybillv12a',
            displayName: 'Outward E-Way Bills',
        },
        {
            assetName: this.APPS_CONFIG && this.APPS_CONFIG['inwardEwb'] ? this.APPS_CONFIG['inwardEwb'].name : 'Consignee E-Waybill-V12A',
            restApiName:
                this.APPS_CONFIG && this.APPS_CONFIG['inwardEwb'] ? this.APPS_CONFIG['inwardEwb'].restAPIName : 'consigneeewaybillv12a',
            displayName: 'Inward E-Way Bills',
        },
        {
            assetName: 'E-Waybill-V14A',
            restApiName: 'ewaybillv14A',
            displayName: 'Outward E-Way Bills 14A',
        },
        {
            assetName:
                this.APPS_CONFIG && this.APPS_CONFIG['transporterEwb']
                    ? this.APPS_CONFIG['transporterEwb'].name
                    : 'Transporter E-Waybill-V12A',
            restApiName:
                this.APPS_CONFIG && this.APPS_CONFIG['transporterEwb']
                    ? this.APPS_CONFIG['transporterEwb'].restAPIName
                    : 'transporterewaybillv12a',
            displayName: 'Transporter E-Way Bills',
        },
        {
            assetName:
                this.APPS_CONFIG && this.APPS_CONFIG['consolidatedEwb']
                    ? this.APPS_CONFIG['consolidatedEwb'].name
                    : 'Consolidated e-WayBill v6',
            restApiName:
                this.APPS_CONFIG && this.APPS_CONFIG['consolidatedEwb']
                    ? this.APPS_CONFIG['consolidatedEwb'].restAPIName
                    : 'consolidatedewaybillv6',
            displayName: 'Consolidated E-Way Bills',
        },
        {
            assetName: this.APPS_CONFIG && this.APPS_CONFIG['ewbupdate'] ? this.APPS_CONFIG['ewbupdate'].name : 'E-Way Bill-Update',
            restApiName: this.APPS_CONFIG && this.APPS_CONFIG['ewbupdate'] ? this.APPS_CONFIG['ewbupdate'].restAPIName : 'ewbupdate',
            displayName: 'E-Way Bill Update',
        },
        {
            assetName:
                this.APPS_CONFIG && this.APPS_CONFIG['ewaybillreport'] ? this.APPS_CONFIG['ewaybillreport'].name : 'E-waybill-ReportV1',
            restApiName:
                this.APPS_CONFIG && this.APPS_CONFIG['ewaybillreport']
                    ? this.APPS_CONFIG['ewaybillreport'].restAPIName
                    : 'ewaybillreportv1',
            displayName: 'E-waybill-ReportV1',
        },
        {
            assetName: this.APPS_CONFIG && this.APPS_CONFIG['ewbpanindia'] ? this.APPS_CONFIG['ewbpanindia'].name : 'EWB-Pan-India',
            restApiName: this.APPS_CONFIG && this.APPS_CONFIG['ewbpanindia'] ? this.APPS_CONFIG['ewbpanindia'].restAPIName : 'ewbpanindia',
            displayName: 'EWB-Pan-India',
        },
        {
            assetName: this.APPS_CONFIG && this.APPS_CONFIG['ewbstatewide'] ? this.APPS_CONFIG['ewbstatewide'].name : 'EWB-Statewide',
            restApiName:
                this.APPS_CONFIG && this.APPS_CONFIG['ewbstatewide'] ? this.APPS_CONFIG['ewbstatewide'].restAPIName : 'ewbstatewide',
            displayName: 'EWB-Statewide',
        },
        {
            assetName: this.APPS_CONFIG && this.APPS_CONFIG['ewbsplit'] ? this.APPS_CONFIG['ewbsplit'].name : 'EWB-Split',
            restApiName: this.APPS_CONFIG && this.APPS_CONFIG['ewbsplit'] ? this.APPS_CONFIG['ewbsplit'].restAPIName : 'ewbsplit',
            displayName: 'EWB-Split',
        },
    ];
    inAuditMode: boolean;
    isInCreateNewPage: boolean;
    currentAuditStage: AuditTrailLog = {
        actionTaken: undefined,
        additionalParams: undefined,
        comments: undefined,
        id: undefined,
        loggedOn: undefined,
        timeStamp: undefined,
        userName: undefined,
    };
    INSTANCE_STATES: any[] = [
        {
            name: 'In-Progress',
            value: 'IN_PROGRESS',
        },
        {
            name: 'Request Completed',
            value: 'REQUEST_COMPLETED',
        },
        {
            name: 'Process Cancelled',
            value: 'REQUEST_CANCELLED',
        },
    ];
    VALIDATION_STATUS: any[] = [
        {
            name: 'Success',
            value: 'SUCCESS',
        },
        {
            name: 'Warning',
            value: 'WARNING',
        },
        {
            name: 'Error',
            value: 'ERROR',
        },
    ];
    isInEnInvoice = location.href.indexOf(environment['eninvoice-ui']) > -1;
    isInEnCollab = location.href.indexOf(environment['encollab-ui']) > -1;
    pendingAssetData: {
        sourceAssetRestApiName: string;
        targetAssetRestApiName: string;
        filteringAttributes: any[];
        instanceIds: any[];
        targetReportName: string;
    } = {
        sourceAssetRestApiName: undefined,
        filteringAttributes: undefined,
        targetAssetRestApiName: undefined,
        instanceIds: undefined,
        targetReportName: undefined,
    };

    constructor() {
        setTimeout(() => {
            this.firstEvents = false;
        });
    }

    changePermissionsObject = (permissionsObject: UserPermissions) => {
        this.permissionsSource.next(permissionsObject);
    };

    clearRootScope = () => {
        this.isAdmin = true;
        this.rootOrganization = new Organization({});
        this.switchB = false;
        this.currentOrganization.next(new Organization({}));
        this.stateOrganization = {};
        this.user = new User({});
        this.locations = [];
        this.states = [];
        this.currentOrganizationId.next('');
        this.rootOrganizationId = '';
        this.stateOrganizationId = '';
        this.permissions = new UserPermissions({});
        this.selectedState.next(new Organization({}));
        this.selectedLocation.next(new Organization({}));
        this.rootLocations = [];
        this.stateLocations = [];
        this.cancelLookup = [];
        this.assetsData.assets = [];
        this.assetsData.assetsData = [];
        this.assetsData.masters = new Masters();
        this.assetsData.mastersTables = {};
        this.subscribedServices.next([]);
        this.clearCurrentAssetData();
        this.userPermissions.next(undefined);
        this.allSubscribedApps = [];
        this.storedSearchFilters = [];
        this.isSearchFiltersLoaded = false;
        this.inAuditMode = false;
        this.eiOrganizations = null;
        this.currentAuditStage = {
            actionTaken: undefined,
            additionalParams: undefined,
            comments: undefined,
            id: undefined,
            loggedOn: undefined,
            timeStamp: undefined,
            userName: undefined,
        };
        this.pendingAssetData = {
            sourceAssetRestApiName: undefined,
            filteringAttributes: undefined,
            targetAssetRestApiName: undefined,
            instanceIds: undefined,
            targetReportName: undefined,
        };
        this.collaboration = {
            userId: '',
            collaborationRequestId: '',
            unitId: '',
            restAPIName: '',
            assetId: '',
            instanceId: '',
            requestId: '',
            expired: false,
            requestedBy: '',
            collaborators: '',
            token: '',
            completed: false,
            id: '',
            serviceId: '',
        };
    };

    clearCurrentAssetData = () => {
        this.assetsData.current.report.next(undefined);
        this.assetsData.current.service.next(undefined);
        this.assetsData.current.bridge.next(undefined);
        this.assetsData.current.reportName.next(undefined);
        this.assetsData.current.assetRestApi.next(undefined);
        this.assetsData.current.bridgeRestApi.next(undefined);
    };
}
