import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { translate } from '@ngneat/transloco';

import { MasterRecord } from '../../models/masters/masterrecord.class';

@Component({
    selector: 'master-change-logs',
    templateUrl: './masterchangelog.component.html',
})
export class MasterChangeLogComponent implements OnInit {
    changeLogs: any;
    masterRecord: MasterRecord;

    constructor(private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any) {}

    closeModal = () => {
        this.dialog.closeAll();
    };

    trackByFn(_index: number, item: any): any {
        return item;
    }

    ngOnInit() {}

    // tslint:disable-next-line: use-life-cycle-interface
    ngOnDestroy(): void {}

    translateMsg = (msg: string): string => translate('' + msg);
}
