import { Message } from './record/message.class';

export class WorkflowStageData {
    category: string;

    emailEnabled: boolean;

    uid: string;

    id: string;

    originalName: string;

    name: string;

    displayName: string;

    nodePosition: any[];

    order: number;

    ruleBean: any;

    skippable: boolean;

    userActions?: Array<WorkflowUserAction>;

    stageTransitions: Array<{
        expression: string;

        nextStageId: string;
    }>;

    userTask: boolean;

    constructor(data, template?: WorkflowStageData) {
        this.category = data?.category;
        this.emailEnabled = data?.emailEnabled;
        this.id = data?.id;
        this.originalName = data?.originalName || data?.name;
        this.name = template?.displayName || data?.name;
        this.nodePosition = data?.nodePosition;
        this.order = data?.order;
        this.ruleBean = data?.ruleBean;
        this.skippable = data?.skippable;
        this.stageTransitions = data?.stageTransitions;
        this.userTask = data?.userTask;
        this.userActions = [];
        data?.userActions?.forEach((action) => {
            const actionTemplate = template?.['workflowStageActions']?.find((item) => item.name === action.name);
            this.userActions.push(new WorkflowUserAction(action, actionTemplate));
        });
    }
}

export class WorkflowUserAction {
    defaultAction?: boolean;

    originalName?: string;

    name?: string;

    displayName?: string;

    nextStageId?: string;

    nextStageName?: string;

    onClick?: (...args: any[]) => void;

    /**
     * UI related variables
     */
    show?: boolean;

    readOnly?: boolean;

    formFields?: WorkflowFormField[];

    commentEnabled?: boolean;

    expression?: string;

    message?: string;

    comment?: string;

    messages?: Message;

    constructor(
        data: WorkflowUserAction,
        template: { actionFormFields: { name: string; displayName: string }[]; description: string; displayName: string; name: string }
    ) {
        this.defaultAction = data?.defaultAction;
        this.originalName = data?.originalName || data?.name;
        this.name = template?.name || data?.name;
        this.displayName = template?.displayName || data?.displayName;
        this.nextStageId = data?.nextStageId;
        this.nextStageName = data?.nextStageName;
        this.show = data?.show;
        this.readOnly = data?.readOnly;
        this.commentEnabled = data?.commentEnabled;
        this.expression = data?.expression;
        this.message = data?.message;
        this.formFields = [];
        data?.formFields?.forEach((formField) => {
            const actionTemplate = template?.actionFormFields?.find((item) => item.name === formField.name);
            this.formFields.push(new WorkflowFormField(formField, actionTemplate));
        });
    }
}

export class WorkflowFormField {
    dataType: 'STRING' | 'DATE' | 'LONG' | 'INT';

    defaultValue: string;

    name: string;

    options: string[];

    uiType: 'DROPDOWN' | 'TEXTFIELD';

    mandatory?: boolean;

    value?: any;

    outputFormat?: string;

    format?: string;

    displayName?: string;

    message?: Message;

    constructor(data: WorkflowFormField, template: { displayName: string; name: string }) {
        this.dataType = data?.dataType;
        this.defaultValue = data?.defaultValue;
        this.name = template?.name || data?.name;
        this.options = data?.options;
        this.uiType = data?.uiType;
        this.mandatory = data?.mandatory;
        this.value = data?.value;
        this.outputFormat = data?.outputFormat;
        this.format = data?.format;
        this.displayName = template?.displayName || data?.displayName;
        this.message = new Message();
    }
}
