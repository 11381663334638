import { Injectable } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, mergeMap, of, withLatestFrom } from 'rxjs';

import { AuthorizationSystem } from '../../models/integrations/authorization-system.class';
import { ApiService } from '../../services/api/api.service';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import {
    AlertError,
    AlertSuccess,
    DeleteAuthorizationSystem,
    GetAuthorizationSystems,
    SetAuthorizationSystems,
    SubmitAuthorizationSystem,
} from '../actions';
import { getAuthorizationSystems$ } from '../selectors';

@Injectable()
export class AuthorizationSystemsEffects {
    constructor(private actions$: Actions, private _api: ApiService, private store$: Store) {}

    getAuthorizationSystems$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GetAuthorizationSystems),
            withLatestFrom(this.store$.select(getAuthorizationSystems$)),
            mergeMap(([_action, existingSystems]) => {
                if (existingSystems?.length > 0) {
                    return [];
                }
                return this._api.integrations.getAuthorizationSystems().pipe(
                    map((response) => {
                        response?.forEach((config) => {
                            config.updatedOn =
                                config.updatedOn &&
                                CommonUtilsService.transformDateToLocale(
                                    config.updatedOn,
                                    'YYYY-MM-DDTHH:MM:SSZ',
                                    'DD-MM-YYYY HH:MM:SS AM',
                                    false
                                );
                            config.createdOn =
                                config.createdOn &&
                                CommonUtilsService.transformDateToLocale(
                                    config.createdOn,
                                    'YYYY-MM-DDTHH:MM:SSZ',
                                    'DD-MM-YYYY HH:MM:SS AM',
                                    false
                                );
                            this.transformAuthSystems(config);
                        });

                        return SetAuthorizationSystems({
                            authorizationSystems: response,
                        });
                    }),
                    catchError((e) => of(AlertError({ message: e || translate('Unable to get authorization systems') })))
                );
            })
        );
    });

    deleteAuthorizationSystem$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DeleteAuthorizationSystem),
            withLatestFrom(this.store$.select(getAuthorizationSystems$)),
            mergeMap(([action, configs]) => {
                return this._api.integrations
                    .submitAuthorizationSystems({
                        authorizationSystem: action.authorizationSystem,
                        update: true,
                    })
                    .pipe(
                        map((response) => {
                            this.store$.dispatch(
                                AlertSuccess({
                                    message: translate(response?.msg || `Authorization system deleted`),
                                })
                            );
                            return SetAuthorizationSystems({
                                authorizationSystems: configs.filter((config) => config.id !== action.authorizationSystem['id']),
                                updated: true,
                            });
                        }),
                        catchError((e) => of(AlertError({ message: e?.msg || translate(`Unable to save authorization ystem`) })))
                    );
            })
        );
    });

    submitAuthorizationSystem$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SubmitAuthorizationSystem),
            withLatestFrom(this.store$.select(getAuthorizationSystems$)),
            mergeMap(([action, existingSystems]) => {
                return this._api.integrations
                    .submitAuthorizationSystems({
                        authorizationSystem: action.authorizationSystem,
                        update: action.update,
                    })
                    .pipe(
                        map((response) => {
                            this.transformAuthSystems(response);
                            this.store$.dispatch(
                                AlertSuccess({
                                    message: translate(
                                        response?.msg || `Authorization system details ${action.update ? 'updated' : 'saved'} successfully`
                                    ),
                                })
                            );
                            let clonedSystems: AuthorizationSystem[] = CommonUtilsService.cloneObject(existingSystems);
                            if (response?.createdOn || response?.updatedOn) {
                                response.updatedOn =
                                    response.updatedOn &&
                                    CommonUtilsService.transformDateToLocale(
                                        response.updatedOn,
                                        'YYYY-MM-DDTHH:MM:SSZ',
                                        'DD-MM-YYYY HH:MM:SS AM',
                                        false
                                    );
                                response.createdOn =
                                    response.createdOn &&
                                    CommonUtilsService.transformDateToLocale(
                                        response.createdOn,
                                        'YYYY-MM-DDTHH:MM:SSZ',
                                        'DD-MM-YYYY HH:MM:SS AM',
                                        false
                                    );
                            }
                            const existingIndex = clonedSystems.findIndex((config) => config.id === response?.id);
                            if (existingIndex !== -1) {
                                clonedSystems.splice(existingIndex, 1, response);
                                clonedSystems[existingIndex] = response;
                            } else {
                                clonedSystems.unshift(response);
                            }
                            return SetAuthorizationSystems({
                                authorizationSystems: clonedSystems,
                                updated: true,
                            });
                        }),
                        catchError((e) => of(AlertError({ message: e?.msg || translate(`Unable to save authorization ystem`) })))
                    );
            })
        );
    });

    public transformAuthSystems = (config) => {
        if (config.sslCertificates) {
            Object.entries(config.sslCertificates || {}).forEach(([fileName, base64Data]) => {
                if (typeof base64Data === 'string') {
                    const byteCharacters = atob(base64Data);
                    const byteArrays = [];
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteArrays.push(byteCharacters.charCodeAt(i));
                    }
                    const byteArray = new Uint8Array(byteArrays);
                    const blob = new Blob([byteArray]);
                    const file = new File([blob], fileName);
                    config.sslCertificates[fileName] = file;
                }
            });
        }
        if (config.encryptionKey) {
            Object.entries(config.encryptionKey || {}).forEach(([fileName, base64Data]) => {
                if (typeof base64Data === 'string') {
                    const byteCharacters = atob(base64Data);
                    const byteArrays = [];
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteArrays.push(byteCharacters.charCodeAt(i));
                    }
                    const byteArray = new Uint8Array(byteArrays);
                    const blob = new Blob([byteArray]);
                    const file = new File([blob], fileName);
                    config.encryptionKey[fileName] = file;
                }
            });
        }
    };
}
