import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Utils } from '@encomply-core/services/utils/utils.service';
import { translate, TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import {
    ApiService,
    AssetService,
    BridgeNode,
    CommonUtilsService,
    getApp$,
    GetAppBridgeNodes,
    GetAppDescription,
    GetAppHelpDocumentation,
    getBridgeNodes$,
    getBridgeService$,
    UtilsService,
} from 'taxilla-library';

@Component({
    selector: 'app-fetch-assets-modal',
    templateUrl: './fetch-assets-modal.component.html',
    styleUrls: ['./fetch-assets-modal.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: '',
        },
    ],
})
export class FetchAssetsModalComponent implements OnInit, OnDestroy {
    public asset: any;
    public allSubAppsArr: any[] = [];
    public currentOrgDetails: any;
    private unsubscribe = new Subject<void>();
    public appServiceId: string;
    public appAssetId: string;

    constructor(
        private _utils: Utils,
        private _api: ApiService,
        private _libUtils: UtilsService,
        private dialog: MatDialog,
        private _commonUtils: CommonUtilsService,
        private store$: Store
    ) {}

    private loadingBridgeApps = async (asset) => {
        if (asset?.bridgeNodes) {
            const bridgeNodes = asset.bridgeNodes;
            let bridgePermissions;
            if (asset.status === 'APPROVED' || this.appServiceId) {
                const userPermissions = await this._api.permissions.getBridgePermissions(asset.serviceId);
                bridgePermissions = userPermissions?.BRIDGE_ASSET?.[asset.serviceId];
                Object.keys(bridgeNodes || {}).forEach((id) => {
                    const appPermissions = bridgePermissions?.[bridgeNodes[id].name];
                    if (appPermissions?.permissions) {
                        this._libUtils.addNodePermissions(bridgeNodes[id], appPermissions?.permissions);
                    } else {
                        bridgeNodes[id].noPermissions = true;
                    }
                });
            }
            this.arrangeAssetsInBridgeAsset(asset, bridgeNodes);
            this.allSubAppsArr = asset.assets;
            this.allSubAppsArr.forEach((element) => {
                element['organizationId'] = asset.organizationId;
                element.assetMetaUId = element.assetMetaUId || element.id;
            });
        }
    };

    private arrangeAssetsInBridgeAsset = async (service: AssetService, bridgeNodes: Map<string, BridgeNode>) => {
        service.assets = [];
        const sortedBridges = this._utils.sortBridgeAssets(bridgeNodes);
        sortedBridges.forEach((node) => {
            this.pushFilesToReports(node, sortedBridges);
            if (node.componentType !== 'FILE') {
                service.assets.push(node);
            }
        });
        return service;
    };

    private pushFilesToReports = (service: BridgeNode, nodes: BridgeNode[]) => {
        (service as any).reports = (service as any).reports || [];
        service.nextNodes.forEach((serviceNode) => {
            const nextNode = nodes.find((node) => node.id === serviceNode);
            nextNode && nextNode.componentType === 'FILE' && (service as any).reports.push(nextNode);
        });
    };

    public closeModal = () => {
        this.dialog.closeAll();
    };

    public getHelpDocument = (app) => {
        this.store$.dispatch(
            GetAppHelpDocumentation({ serviceId: app.serviceId, bridgeServiceId: this.asset.serviceId, bridgeNodeId: app.id })
        );
    };

    public downloadHelpDocument = (docObj) => {
        this._utils.downloadHelpDocument(docObj);
    };

    public viewHelpDocument = (doc: any) => {
        this._api.assets.viewHelpDocument(doc);
    };

    public trackById = (_index: number, item: any) => {
        return item.id;
    };

    public getAssetDescription = (asset) => {
        asset.description = translate('Fetching description');
        this.store$.dispatch(
            GetAppDescription({
                serviceId: asset.serviceId,
            })
        );
    };

    ngOnInit() {
        if (this.appServiceId) {
            this.store$
                .select(getApp$(this.appServiceId))
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((asset) => {
                    this.asset = CommonUtilsService.cloneObject(asset);
                });
            this.store$
                .select(getBridgeNodes$(this.appAssetId))
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((nodes) => {
                    this.asset.bridgeNodes = CommonUtilsService.cloneObject(nodes);
                    this.loadingBridgeApps(this.asset);
                });
            this.store$.dispatch(
                GetAppBridgeNodes({
                    serviceId: this.appServiceId,
                })
            );
        } else {
            this.store$
                .select(getBridgeService$)
                .pipe(
                    takeUntil(this.unsubscribe),
                    filter((count) => count !== undefined)
                )
                .subscribe((asset) => {
                    const app = CommonUtilsService.cloneObject(asset);
                    this.asset = app;
                    if (app.bridgeNodes) {
                        this.loadingBridgeApps(app);
                    } else {
                        this.store$.dispatch(GetAppBridgeNodes({ assetId: app.id }));
                    }
                });
        }
        this.currentOrgDetails = this._commonUtils.getFromStorage('currentOrganization');
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
