import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { BroadcasterService } from 'ng-broadcaster';
import { from, of, pipe } from 'rxjs';
import { catchError, delay, filter, map, mergeMap, take, withLatestFrom } from 'rxjs/operators';
import {
    CommonUtilsService,
    GetAssociatedLocations,
    GetLocation,
    GetPrimaryPermissions,
    GetSubscribedApps,
    Organization,
    UtilsService,
} from 'taxilla-library';

import { ApiService } from '../../services/api/api.service';
import {
    CannotSwitchToOrganization,
    CanSwitchToOrganization,
    CheckOrganizationExists,
    CompletedSwitchToOrganization,
    GetApplicationMiscProperties,
    GetSessionDetails,
    GetWorkQCount,
    getWorkQMessages,
    SessionLogout,
    SessionLogoutSuccessfull,
    SetApplicationMiscProperties,
    SetCurrentOrganization,
    SetProviderAdmin,
    SetSessionDetails,
    setWorkQCount,
    setWorkQMessageAsRead,
    setWorkQMessages,
    SwitchToOrganization,
    workQMessageMarkAsRead,
} from '../actions/session.actions';
import { AlertError } from '../actions/shared.actions';
import {
    getApplicationMiscPropertiesState,
    getAssociatedOrganizationsMap$,
    getCurrentOrganizationId$,
    getSessionDetails$,
    getSessionState$,
    isProviderAdmin$,
} from '../selectors';
import { SessionState } from '../states';

@Injectable()
export class SessionEffects {
    constructor(
        private store$: Store<SessionState>,
        private actions$: Actions,
        private _api: ApiService,
        private _store: Store,
        private _commonUtils: CommonUtilsService,
        private _broadcaster: BroadcasterService,
        private _router: Router,
        private _utils: UtilsService
    ) {}

    getSessionDetails$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GetSessionDetails),
            withLatestFrom(this.store$.select(getSessionDetails$)),
            mergeMap(([action, session]) => {
                return this._api.session.getSessionDetails({ noAlerts: action.noAlerts }).pipe(
                    map((res) => {
                        if (res?.currentOrg.id !== session.currentOrganizationId) {
                            this.store$.dispatch(
                                CompletedSwitchToOrganization({ organization: res?.currentOrg as Organization, switched: true })
                            );
                        }
                        return SetSessionDetails(res);
                    }),
                    catchError((errorResponse) => {
                        this.store$.dispatch(
                            SetSessionDetails({
                                currentOrg: undefined,
                                isAdmin: undefined,
                                lastLogin: undefined,
                                loggedInOrg: undefined,
                                passwordExpiresInDays: undefined,
                                user: undefined,
                                timeZoneOffset: undefined,
                            })
                        );
                        return of(AlertError({ message: errorResponse?.msg }));
                    })
                );
            })
        );
    });

    getRolesByOrganizationId$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SetSessionDetails),
            pipe(delay(100)),
            withLatestFrom(this.store$.select(isProviderAdmin$)),
            mergeMap(([action, providerAdmin]) => {
                if (providerAdmin !== undefined) {
                    return [];
                }
                return this._api.roles.getRolesByOrganizationId({ locationId: action.loggedInOrg?.id, userId: action.user?.id }).pipe(
                    map((res: any) => {
                        const providerRole = res?.response?.roles?.find((role) => role.roleName === 'PROVIDER_ADMIN');
                        return SetProviderAdmin({ providerAdmin: providerRole ? true : false });
                    }),
                    catchError((errorResponse) => of(AlertError({ message: errorResponse?.msg })))
                );
            })
        );
    });

    sessionLogout$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SessionLogout),
            mergeMap(() => {
                this._commonUtils.deleteCookie('domain');
                this._commonUtils.clearStorage();
                return this._api.session.logout().pipe(
                    map(() => SessionLogoutSuccessfull()),
                    catchError((errorResponse) => {
                        errorResponse?.msg && this._store.dispatch(AlertError({ message: errorResponse?.msg }));
                        return of(SessionLogoutSuccessfull());
                    })
                );
            })
        );
    });

    sessionLogoutSuccessfull$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SessionLogoutSuccessfull),
            mergeMap(() => {
                this._broadcaster.broadcast('logout', { noRedirect: true, hidePopup: true });
                return [];
            })
        );
    });

    getApplicationMiscProperties = createEffect(() => {
        return this.actions$.pipe(
            ofType(GetApplicationMiscProperties),
            mergeMap(() => {
                let appSettings: SessionState['applicationMiscProperties'];
                this.store$
                    .pipe(select(getApplicationMiscPropertiesState))
                    .pipe(take(1))
                    .subscribe((data) => (appSettings = data));
                if (appSettings?.['x-auth-token']) {
                    return [];
                }
                return from(this._api.session.getApplicationMiscProps()).pipe(
                    map((response) => SetApplicationMiscProperties(response)),
                    catchError((errorResponse) => of(AlertError({ message: errorResponse })))
                );
            })
        );
    });

    checkOrganizationExists$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CheckOrganizationExists),
            withLatestFrom(
                this.store$.select(getAssociatedOrganizationsMap$),
                this.store$.select(getCurrentOrganizationId$),
                this.store$.select(getSessionState$)
            ),
            mergeMap(([action, organizations, currentOrganizationId]) => {
                if (!action.organizationId) {
                    this._utils.navigateToOrganization();
                    return [];
                }
                const organization = organizations?.[action.organizationId];
                const onNewUI = this._commonUtils.getCookie('new-ui') === 'true';
                if (!organization && (Object.keys(organizations)?.length || 0) < 2) {
                    setTimeout(() => {
                        this.store$.dispatch(CheckOrganizationExists(action));
                    }, 1000);
                    if (onNewUI) {
                        return of(GetLocation({ organizationId: action.organizationId, update: false }));
                    } else {
                        return of(GetAssociatedLocations());
                    }
                } else if (!organization && action.organizationId?.length > 0 && organizations?.[currentOrganizationId]) {
                    this._utils.navigateToOrganization();
                    return [];
                }
                if (organization && !organization.noAccess) {
                    return of(CanSwitchToOrganization({ organizationId: action.organizationId }));
                }
                return of(CannotSwitchToOrganization({ failed: true }));
            })
        );
    });

    canSwitchToOrganization$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CanSwitchToOrganization),
            withLatestFrom(this.store$.select(getCurrentOrganizationId$), this.store$.select(getAssociatedOrganizationsMap$)),
            mergeMap(([action, organizationId, orgMap]) => {
                const stored = this._commonUtils.getFromStorage('currentOrganizationId');
                if (action.organizationId === stored && organizationId && action.organizationId !== organizationId) {
                    return of(SwitchToOrganization({ organizationId: stored }));
                }
                if (!organizationId && action.organizationId === stored) {
                    return of(CompletedSwitchToOrganization({ switched: false, organization: orgMap?.[action.organizationId] }));
                }
                if (action.organizationId === organizationId) {
                    return of(CompletedSwitchToOrganization({ switched: false, organization: orgMap?.[action.organizationId] }));
                }
                return of(SwitchToOrganization({ organizationId: action.organizationId }));
            })
        );
    });

    switchToOrganization$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SwitchToOrganization),
            withLatestFrom(this.store$.select(getAssociatedOrganizationsMap$)),
            mergeMap(([action, orgsMap]) => {
                [
                    'userPermissions',
                    'allusers',
                    'userpagesize',
                    'roles',
                    'selectedUserId',
                    'selectedUserRoles',
                    'rolePermissions',
                    'clickedUser',
                    'clickedrole',
                    'locations',
                    'selectedroleid',
                    'selectedrolepermissions',
                ].forEach((storedValue) => {
                    this._commonUtils.removeFromStorage(storedValue);
                });
                return this._api.locations.switchToOrganization({ firstEvent: false, orgId: action.organizationId }).pipe(
                    map(() => {
                        return CompletedSwitchToOrganization({
                            switched: true,
                            organization: orgsMap?.[action.organizationId],
                        });
                    }),
                    catchError((e) => of(AlertError({ message: e })))
                );
            })
        );
    });

    switchedToOrganization$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CompletedSwitchToOrganization),
            mergeMap((action) => {
                this.store$.dispatch(SetCurrentOrganization({ organization: action.organization }));
                this.store$.dispatch(
                    GetLocation({
                        organizationId: action.organization.id,
                        update: false,
                    })
                );
                this.store$.dispatch(GetSubscribedApps());
                this.store$.dispatch(GetPrimaryPermissions());
                return [];
            })
        );
    });

    setCurrentOrganization$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SetCurrentOrganization),
            mergeMap((action) => {
                this._commonUtils.setInStorage('currentOrganization', action.organization);
                this._commonUtils.setInStorage('currentOrganizationId', action.organization?.id);
                this._broadcaster.broadcast('setStoreContext');
                return [];
            })
        );
    });

    cannotSwitchToOrganization$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CannotSwitchToOrganization),
            filter((data) => data.failed),
            mergeMap(() => {
                this.store$.dispatch(AlertError({ message: 'User does not have access' }));
                const orgId = this._commonUtils.getFromStorage('currentOrganizationId');
                this._router.navigate(['organizations', orgId]);
                return [];
            })
        );
    });

    getWorkQCount = createEffect(() => {
        return this.actions$.pipe(
            ofType(GetWorkQCount),
            mergeMap(() => {
                return from(this._api.workQ.getCountOfActiveMessages()).pipe(
                    map((response: number) => setWorkQCount({ count: response })),
                    catchError((errorResponse) => of(AlertError({ message: errorResponse })))
                );
            })
        );
    });

    getWorkQMessages = createEffect(() => {
        return this.actions$.pipe(
            ofType(getWorkQMessages),
            mergeMap((action) => {
                return from(this._api.workQ.getInboxMessages(action)).pipe(
                    map((response: any[]) => setWorkQMessages({ messages: response })),
                    catchError((errorResponse) => of(AlertError({ message: errorResponse?.msg })))
                );
            })
        );
    });

    workQMessageMarkAsRead = createEffect(() => {
        return this.actions$.pipe(
            ofType(workQMessageMarkAsRead),
            mergeMap((action) => {
                return from(this._api.workQ.markMessageAsRead(action)).pipe(
                    map(() => setWorkQMessageAsRead()),
                    catchError((errorResponse) => {
                        console.error('Error here:', errorResponse);
                        return of(AlertError(errorResponse));
                    })
                );
            })
        );
    });
}
