import { createAction, props } from '@ngrx/store';
import { Report, UIReport } from 'taxilla-library';

enum reportTypes {
    GET_REPORTS = '[REPORTS] Get Reports',
    SET_REPORTS = '[REPORTS] Set Reports',
    GENERATE_REPORT = '[REPORTS] Generate Reports',
    GENERATED_REPORT = '[REPORTS] Generated Report',
    GENERATED_REPORT_ERROR = '[REPORTS] Generated Report Error',
    LOADING_REPORTS = '[REPORTS] Set Loading',
    GET_SEARCH_REPORTS_PAGINATION = '[REPORTS] Get Search Reports Pagination',
    UPDATE_SEARCH_REPORTS_PAGINATION = '[REPORTS] Update Search Reports Pagination',
    CLEAR_SEARCH_REPORTS_PAGINATION = '[REPORTS] Clear Search Reports Pagination',
    GET_SEARCH_REPORTS = '[REPORTS] Get Search Reports',
    SET_SEARCH_REPORTS = '[REPORTS] Set Search Reports',
    CLEAR_SEARCH_REPORTS = '[REPORTS] Clear Search Reports',
    DOWNLOAD_SEARCH_REPORT = '[REPORTS] Download Search Report',
    SET_DOWNLOADABLE_SEARCH_REPORTS = '[REPORTS] Set Downloadable Search Reports',
}

export const GetReports = createAction(
    reportTypes.GET_REPORTS,
    props<{
        instanceId: string;
        serviceId: string;
        assetId: string;
        forceFetch?: boolean;
        noAlerts: boolean;
    }>()
);

export const SetReports = createAction(
    reportTypes.SET_REPORTS,
    props<{
        instanceId: string;
        reports: UIReport[];
    }>()
);

export const GenerateReport = createAction(
    reportTypes.GENERATE_REPORT,
    props<{
        instanceId: string;
        serviceId: string;
        repositoryId: string;
        chainName: UIReport['chains']['0']['name'];
        transformationName?: string;
        noAlerts: boolean;
        timeStamp: number;
    }>()
);

export const GeneratedReport = createAction(
    reportTypes.GENERATED_REPORT,
    props<{
        instanceId: string;
        serviceId: string;
        repositoryId: string;
        chainName: UIReport['chains']['0']['name'];
        transformationName: string;
        timeStamp: number;
    }>()
);

export const GenerateReportError = createAction(
    reportTypes.GENERATED_REPORT_ERROR,
    props<{
        instanceId: string;
        transformationName?: string;
    }>()
);
export const LoadingReports = createAction(
    reportTypes.LOADING_REPORTS,
    props<{
        instanceId: string;
        loading: boolean;
    }>()
);

export const GetSearchReportsPagination = createAction(
    reportTypes.GET_SEARCH_REPORTS_PAGINATION,
    props<{
        serviceId: string;
        noAlerts?: boolean;
    }>()
);

export const UpdateSearchReportsPagination = createAction(
    reportTypes.UPDATE_SEARCH_REPORTS_PAGINATION,
    props<{
        serviceId: string;
        pageSize: number;
        pageIndex: number;
        pagingState: any;
        timeRange: any[];
        noAlerts?: boolean;
    }>()
);

export const ClearSearchReportsPagination = createAction(
    reportTypes.CLEAR_SEARCH_REPORTS_PAGINATION,
    props<{
        serviceId: string;
        noAlerts?: boolean;
    }>()
);

export const GetSearchReports = createAction(reportTypes.GET_SEARCH_REPORTS, props<{ serviceId: string; noAlerts?: boolean }>());

export const SetSearchReports = createAction(
    reportTypes.SET_SEARCH_REPORTS,
    props<{
        serviceId: string;
        reports: UIReport[];
        pagingState: string;
    }>()
);

export const ClearSearchReports = createAction(
    reportTypes.CLEAR_SEARCH_REPORTS,
    props<{
        serviceId: string;
        noAlerts?: boolean;
    }>()
);

export const DownloadSearchReport = createAction(
    reportTypes?.DOWNLOAD_SEARCH_REPORT,
    props<{
        serviceId: string;
        assetId: string;
        requestId: string;
        repositoryId: string;
        transformationName: string;
        noAlerts?: boolean;
    }>()
);

export const SetDownloadableSearchReports = createAction(
    reportTypes?.SET_DOWNLOADABLE_SEARCH_REPORTS,
    props<{
        serviceId: string;
        reports: { [property: string]: Report };
    }>()
);
