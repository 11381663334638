import { createFeatureSelector, createSelector } from '@ngrx/store';

import { USERS_REDUCER_KEY } from '../reducers';
import { getCurrentOrganizationId$, getSessionUserId$ } from './session.selector';

import type { UsersState } from '../states';
const getUsersState = createFeatureSelector<UsersState>(USERS_REDUCER_KEY);

export const getUserSettingsState$ = createSelector(getUsersState, (state) => state.settings);

export const getUpdatePasswordResponse$ = createSelector(getUsersState, (state) => state.passwordUpdate.message);

export const getUsersPaginationStatus$ = createSelector(getUsersState, (state) => state.usersPagination);

export const getUsersSearchState$ = createSelector(getUsersState, (state) => state.search);

export const getUsers$ = createSelector(
    getUsersState,
    getCurrentOrganizationId$,
    getUsersSearchState$,
    (state, currentOrganizationId, search) => (state.usersList[`${currentOrganizationId}|${JSON.stringify(search)}`] || []) as string[]
);

export const getUsersMap$ = createSelector(getUsersState, (state) => state.usersMap);

export const getUsersByPagination$ = createSelector(getUsers$, getUsersPaginationStatus$, getUsersMap$, (userIds, pagination, usersMap) => {
    const start = pagination.index * pagination.pageSize;
    const end = (pagination.index + 1) * pagination.pageSize;
    return userIds
        .filter((_id, index) => index >= start && index < end)
        .reduce((usersList, id) => {
            usersList.push(usersMap[id]);
            return usersList;
        }, []);
});

export const getAddUserResponse$ = createSelector(getUsersState, (state) => state.addUser);

export const getSelectedOrgIdToAssignUsers$ = createSelector(getUsersState, (state) => state.selectedOrgIdToAssignUsers);

export const getOrgUsers$ = createSelector(
    getUsersState,
    getSelectedOrgIdToAssignUsers$,
    (state, selectedOrgId) => state?.organizationUsers?.[selectedOrgId]
);

export const getSessionUser$ = createSelector(getUsersMap$, getSessionUserId$, (usersMap, userId) => {
    return usersMap?.[userId];
});
