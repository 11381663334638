import { Component, DoCheck, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyFormFieldAppearance } from '@angular/material/legacy-form-field';
import { Subscription } from 'rxjs';

import { ModifiedErrorStateMatcher } from '../../../extensions/errorstatematcher/errorstatematcher';

@Component({
    selector: 'material-input-password',
    templateUrl: './inputpassword.component.html',
    styleUrls: ['./inputpassword.component.css'],
})
export class MaterialInputPasswordComponent implements OnInit, DoCheck, OnChanges, OnDestroy {
    @Input() placeholder;
    @Input() name;
    @Input() autofocus;
    @Input() title;
    @Input() model;
    @Input() id;
    @Input() classList;
    @Input() debounceAfter: number;
    @Input() options: any[];
    @Input() autoComplete: boolean;
    @Input() required: boolean;
    @Input() errors: any;
    @Input() warnings: any[];
    @Input() disabled: boolean;
    @Input() maxLength: number;
    @Input() description: string;
    @Input() encrypted?: boolean;
    @Input() appearance: MatLegacyFormFieldAppearance;

    @Output() modelChange = new EventEmitter();
    @Output() debounceEventHandler = new EventEmitter();
    @Output() keyUpEventHandler = new EventEmitter();
    @Output() encryptionOverrided = new EventEmitter();

    matcher = new ModifiedErrorStateMatcher();
    control = new UntypedFormControl('');
    valueSubscription: Subscription;
    public dataType: 'password' | 'text' = 'password';

    constructor() {}

    ngDoCheck() {
        if (this.errors === undefined && this.control.getError('errors') === undefined) {
            // do nothing
        } else if (
            (this.errors === undefined || (this.errors && this.errors.length === 0)) &&
            this.control.getError('errors') !== undefined
        ) {
            // mark undefined
            this.control.setErrors(undefined);
        } else if (
            this.errors !== undefined &&
            this.errors.length > 0 &&
            JSON.stringify(this.errors) !== JSON.stringify(this.control.getError('errors'))
        ) {
            // show errors
            this.control.setErrors({ errors: this.errors });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        // Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
        if (changes.model) {
            this.control.setValue(this.model);
        }
        if (changes.disabled) {
            if (changes.disabled.currentValue === true) {
                this.control.disable();
            } else {
                this.control.enable();
            }
        }
        if (changes.encrypted?.currentValue === false && changes.encrypted?.previousValue) {
            if (this.control.value === 'encrypted') {
                this.control.setValue('');
            }
        }
    }

    modelChanged = (event) => {
        this.model = event;
        this.modelChange.next(this.model);
    };

    public toggleEncryption = () => {
        if (this.encrypted) {
            this.control.setValue('');
        }
    };

    public startedEditing = (event: KeyboardEvent) => {
        if (!this.encrypted || (this.encrypted && event.keyCode === 9)) {
            return;
        }
        this.encrypted = false;
        this.encryptionOverrided.emit(true);
        this.control.setValue('');
    };

    public clickedInput = () => {
        if (!this.encrypted) {
            return;
        }
        this.control.setValue('');
    };

    public clickedOut = () => {
        if (!this.encrypted) {
            return;
        }
        this.control.setValue('encrypted');
    };

    ngOnInit() {
        if (this.encrypted && this.dataType === 'password') {
            this.control.setValue('encrypted');
        }
        this.valueSubscription = this.control.valueChanges.subscribe((event) => {
            if (this.encrypted) {
                return;
            }
            this.modelChanged(event);
        });
    }

    ngOnDestroy(): void {
        // Called once, before the instance is destroyed.
        this.valueSubscription && this.valueSubscription.unsubscribe();
    }
}
