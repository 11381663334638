import { UntypedFormControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

/** Error when invalid control is dirty, touched, or submitted. */
export class ModifiedErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: UntypedFormControl | null): boolean {
        // return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted || (control.hasError('errors') && control.getError('errors').length > 0)));
        return control && control.hasError('errors') && control.getError('errors').length > 0;
    }
}
