import { createReducer, on } from '@ngrx/store';

import * as actions from '../actions/authorizations-system.actions';
import * as sessionActions from '../actions/session.actions';
import { authorizationsSystemState } from '../states';

export const AUTHORIZATION_SYSTEMS_REDUCER = createReducer(
    authorizationsSystemState,
    on(actions.SetAuthorizationSystems, (state, action) => {
        return {
            ...state,
            configs: action.authorizationSystems,
            configOptions: !action.updated
                ? state.configOptions
                : {
                      EMAIL: [],
                      RESTAPI: [],
                  },
        };
    }),
    on(actions.SetAuthorizationSystemOptions, (state, action) => {
        return {
            ...state,
            configOptions: {
                EMAIL: action.EMAIL,
                RESTAPI: action.RESTAPI,
            },
        };
    }),
    on(actions.SetAuthorizationSystemId, (state, action) => {
        return {
            ...state,
            authorizationId: action.authorizationId,
        };
    }),
    on(actions.SetAuthorizationSystemName, (state, action) => {
        return {
            ...state,
            authorizationName: action.name?.trim?.()?.toLowerCase?.(),
        };
    }),
    on(sessionActions.SessionLogoutSuccessfull, () => authorizationsSystemState)
);

export const AUTHORIZATION_SYSTEMS_REDUCER_REDUCER_KEY = 'authorization-systems';
