import { animate, state, style, transition, trigger } from '@angular/animations';
import { VIRTUAL_SCROLL_STRATEGY } from '@angular/cdk/scrolling';
import { Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { translate } from '@ngneat/transloco';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { PaginationInterface } from '../../../interface/pagination.interface';
import { TableColumn } from '../../../interface/table-column.interface';
import { TableData } from '../../../interface/table-data.interface';
import { TableRecordAction } from '../../../interface/table-record-action.interface';
import { CommonUtilsService } from '../../../services/commonutils/common-utils.service';
import { UtilsService } from '../../../services/utils/utils.service';
import { TableVirtualScrollStrategy } from './table-vs-strategy.service';

@Component({
    selector: 'app-virtual-table',
    templateUrl: './virtual-table.component.html',
    styleUrls: ['./virtual-table.component.scss'],
    providers: [
        {
            provide: VIRTUAL_SCROLL_STRATEGY,
            useClass: TableVirtualScrollStrategy,
        },
    ],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class VirtualTableComponent implements OnInit, OnDestroy {
    constructor(
        private _libUtils: UtilsService,
        private element: ElementRef,
        @Inject(VIRTUAL_SCROLL_STRATEGY)
        private readonly scrollStrategy: TableVirtualScrollStrategy,
        private _commonUtils: CommonUtilsService
    ) {}

    static BUFFER_SIZE = 3;

    @Input() data: BehaviorSubject<TableData[]>;
    @Input() allTableColumns: BehaviorSubject<TableColumn[]>;
    @Input() selectedColumns: string[] = [];
    @Input() showRowExpansion: boolean;
    @Input() noRecordMsg: string;
    @Input() wordWrap = false;
    @Input() pagination: BehaviorSubject<PaginationInterface>;
    @Input() hidePaginator;
    @Input() defaultPaginator;
    @Input() hideTableColumns;
    @Input() hiddenRowCheckbox;
    @Input() columnSearchRequired = false;
    @Input() recordActions: TableRecordAction[];
    @Input() noLog = false;
    @Input() columnselectSearchRequired = false;
    @Input() disablePrevious = false;
    @Input() disableNext = false;
    @Input() newCustomPaginator = false;
    @Input() customPageIndex;
    @Input() pageIndexSelected = 1;
    @Input() customSize = [];
    @Input() scrollHeight: number | string;
    @Input() loading: boolean;
    @Input() height: string;
    @Input() rowHeight: number = 48;

    @Output() fetchItemsInPage = new EventEmitter();
    @Output() refreshItemsWithNewPageSize = new EventEmitter();
    @Output() fetchItemsInPageWithPageIndex = new EventEmitter();
    @Output() onSelectCheckbox = new EventEmitter();
    @Output() onSingleClickEvent = new EventEmitter();
    @Output() onDoubleClickEvent = new EventEmitter();
    @Output() recordSelectedEvent = new EventEmitter();
    @Output() filesChanged = new EventEmitter();
    @Output() DownloadSourceTemplate = new EventEmitter();
    @Output() elementClicked = new EventEmitter();

    selectedAsset = new UntypedFormControl();
    searchColumnControl = new UntypedFormControl();
    allColumns: TableColumn[] = [];
    clonedColumns: string[] = [];
    dataSource = new MatTableDataSource([]);
    expandedElement;
    selectAll;
    searchColumns: string;
    actionColumns = ['table-actions', 'log', 'table-checkbox'];
    throwAtLast = ['table-actions', 'log'];
    showLog = true;
    noRecordsFound = false;
    disbaledData = false;
    searchColumnValue = '';
    selectedStatus = '';
    searchOrgString = '';
    searchAssetOrgString = '';
    selectedSearchColumn = '';
    enteredSearchValue = '';
    selectedOrgsArray = [];
    selectedAssetsArray = [];
    searchSubscription: Subscription;
    newOrgAdded: Subscription;
    unSubscribe = new Subject<void>();
    columnKeyPair: { [property: string]: TableColumn } = {};
    specialColumnKeyPair: { [property: string]: TableColumn };
    selectAllRecords = false;
    attachmentData = {
        filename: '',
        url: '',
        title: 'Choose File',
        return: 'true',
    };
    clearFiles = new BehaviorSubject(0);
    actionVisibility: { [property: string]: { [property: string]: boolean } } = {};
    columnTypePair: { [property: string]: string } = {};
    tableHeight: any;
    headerHeight = 56;
    range: number;
    virtualDataSource: Observable<any[]>;
    selectAllColumnSubscription: Subscription;
    selectedRecordsCount: number;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.selectedColumns) {
            this.pushSpecialColumns();
        }
    }

    checkboxSelect = (checked: boolean, column: TableColumn) => {
        if (checked) {
            let index = this.allColumns.findIndex((columnItem) => columnItem.id === column.id);
            if (index !== -1) {
                if (this.allColumns.findIndex((columnInAllColumns) => columnInAllColumns.type === 'SELECTRECORD') > -1) {
                    index++;
                }
                this.clonedColumns.splice(index, 0, column.id);
            } else {
                this.clonedColumns.push(column.id);
            }
        } else {
            const index = this.clonedColumns.indexOf(column.id);
            this.clonedColumns.splice(index, 1);
        }
        if (this.actionColumns.length !== 0) {
            this.actionColumns.forEach((eachAction) => {
                const actionIndex = this.clonedColumns.indexOf(eachAction);
                if (actionIndex !== -1) {
                    this.clonedColumns.splice(actionIndex, 1);
                }
            });
        }
        this.checkFiltersSelected();
    };

    cloneSubmit = () => {
        const actionColumns = this.actionColumns.filter((column) => this.throwAtLast.indexOf(column) === -1);
        const newColumns = this.clonedColumns.filter((column) => {
            if (actionColumns.indexOf(column) === -1 || column === 'table-checkbox') {
                return column;
            }
        });
        const tempColumns = newColumns.filter((column) => this.throwAtLast.indexOf(column) === -1);
        const hiddenColumns = [];
        this.allColumns
            .filter((column) => column.hideInAllColumnsList && this.selectedColumns.indexOf(column.id) > -1)
            .forEach((column) => hiddenColumns.push(column.id));
        if (tempColumns && tempColumns.length > 0) {
            if (tempColumns.length === 1 && tempColumns[0] === 'table-checkbox') {
                this._libUtils.alertError(translate('Select atleast one column name'));
            } else {
                const newTempColumns = [...hiddenColumns, ...tempColumns];
                this.selectedColumns.splice(0);
                newTempColumns && newTempColumns.forEach((column) => this.selectedColumns.push(column));
                this.pushSpecialColumns();
            }
        } else {
            this._libUtils.alertError(translate('Select atleast one column name'));
        }
        if (this.selectAll) {
            this._commonUtils.setInStorage('filterColumnLog', true);
        } else {
            this._commonUtils.setInStorage('filterColumnLog', false);
        }
    };

    pushSpecialColumns = () => {
        this.throwAtLast
            .filter((columnName) => this.selectedColumns.indexOf(columnName) === -1)
            .forEach((columnName) => {
                if (columnName === 'table-actions') {
                    if (this.recordActions && this.recordActions.length > 0) {
                        this.selectedColumns.push(columnName);
                    }
                } else if (columnName === 'log') {
                    !this.noLog && this.selectedColumns.push(columnName);
                } else {
                    this.selectedColumns.push(columnName);
                }
            });
        const filterActionIndex = this.selectedColumns.indexOf('dynamicActions');
        if (filterActionIndex !== -1) {
            return this.selectedColumns.splice(filterActionIndex, 1);
        }
        this.allColumns
            .filter((column) => column.hideInAllColumnsList && !column.hide)
            .filter((column) => this.selectedColumns.indexOf(column.id) === -1)
            .forEach((column) => this.selectedColumns.unshift(column.id));
        this.generateColumnTypes();
    };

    selectAllFilters = (selectedAll) => {
        const oldColumns = this.clonedColumns.slice(0);
        const actionColumns = this.actionColumns.filter((column) => this.throwAtLast.indexOf(column) === -1);
        this.clonedColumns = oldColumns.filter((column) => actionColumns.indexOf(column) > -1);
        if (selectedAll) {
            this.allColumns
                .filter((column) => !column.hideInAllColumnsList && !column.hide)
                .filter(
                    (column) =>
                        this.actionColumns.indexOf(column.id) === -1 &&
                        !(this.hideTableColumns && this.hideTableColumns.indexOf(column.id) > -1)
                )
                .forEach((column) => {
                    this.clonedColumns.push(column.id);
                });
        }
    };

    onSingleClick = (record) => {
        this.onSingleClickEvent.emit(record);
    };

    onDoubleClick = (record) => {
        const recordClone = CommonUtilsService.cloneObject(record);
        this.onDoubleClickEvent.emit(recordClone);
    };

    checkColumnsCount = () => {
        if (this.allColumns && this.actionColumns) {
            const filteredColumns = this.allColumns && this.allColumns.filter((column) => this.actionColumns.indexOf(column.id) === -1);
            return filteredColumns && filteredColumns.length >= 5;
        }
        return true;
    };

    checkFiltersSelected = () => {
        const leftOutColumns =
            this.allColumns &&
            this.allColumns
                .filter((column) => !column.hideInAllColumnsList && !column.hide)
                .filter((column) => !this.hideTableColumns || (this.hideTableColumns && this.hideTableColumns.indexOf(column.id) === -1))
                .filter((column) => this.clonedColumns && this.clonedColumns.indexOf(column.id) === -1);
        this.selectAll = leftOutColumns && leftOutColumns.length === 0;
    };

    getColumn = (columnId: string): TableColumn => {
        const column = this.allColumns.find((tableCoulmn) => tableCoulmn.id === columnId);
        if (column) {
            return column;
        }
        const throwAtLastColumn = this.throwAtLast.indexOf(columnId) > -1;
        if (throwAtLastColumn) {
            if (columnId === 'log') {
                return {
                    id: columnId,
                    hide: false,
                    hideInAllColumnsList: true,
                    icon: undefined,
                    name: translate('Log'),
                    type: 'ALL_COLUMNS_LIST',
                    options: undefined,
                };
            } else if (columnId === 'table-actions') {
                return {
                    id: columnId,
                    hide: false,
                    hideInAllColumnsList: true,
                    icon: undefined,
                    name: translate('Actions'),
                    type: 'ACTIONS',
                    options: undefined,
                };
            }
        }
        return;
    };

    getSpecialColumns = (columnId: string): TableColumn => {
        let column: TableColumn;
        switch (columnId) {
            case 'log':
                column = {
                    id: columnId,
                    hide: false,
                    hideInAllColumnsList: true,
                    icon: undefined,
                    name: translate('Log'),
                    type: 'ALL_COLUMNS_LIST',
                    options: undefined,
                };
                break;
            case 'table-actions':
                column = {
                    id: columnId,
                    hide: false,
                    hideInAllColumnsList: true,
                    icon: undefined,
                    name: translate('Actions'),
                    type: 'ACTIONS',
                    options: undefined,
                };
                break;
        }
        return column;
    };

    generateColumnTypes = () => {
        this.columnTypePair = {};
        this.selectedColumns.forEach((columnId) => {
            this.columnTypePair[columnId] = this.getColumnType(columnId);
        });
    };

    getColumnType = (columnId: string): string => {
        const column = this.getColumn(columnId);
        return column && column.type;
    };

    generateSpecialColumnsList = () => {
        this.specialColumnKeyPair = {};
    };

    toggleRecordsSelection = (isSelected: boolean) => {
        this.data.value.forEach((record) => {
            record.selected = isSelected;
        });
        this.recordSelectedEvent.emit(this.data.value[0]);
        this.setSelectedRecordsCount();
    };

    onRecordSelectionChange = (changedRecord: TableData) => {
        this.checkAllRecordsSelected();
        this.recordSelectedEvent.emit(changedRecord);
    };

    checkAllRecordsSelected = () => {
        const nonSelectedRecords = this.getNonSelectedRecords();
        this.selectAllRecords = nonSelectedRecords.length === 0 && this.dataSource.data.length > 0;
        this.setSelectedRecordsCount();
    };

    setSelectedRecordsCount = () => (this.selectedRecordsCount = this.getSelectedRecords().length);

    getSelectedRecords = (): any[] => this.dataSource.data.filter((record) => record.selected);

    getNonSelectedRecords = (): any[] => this.dataSource.data.filter((record) => !record.selected);

    fileChanged = (files, record) => {
        record.files = [];
        files.forEach((file: File) => {
            record.files.push(file);
        });
        this.filesChanged.emit(record);
    };

    changedFileName = (fileName: string, record) => {
        record.fileName = fileName;
        this.filesChanged.emit(record);
    };

    downloadSourceTemplateUrl = (element) => {
        this.DownloadSourceTemplate.emit(element);
    };

    checkForDynamicActions = (element, action) => {
        if (element && element.dynamicActions && element.dynamicActions.length > 0) {
            const dynamicAction = element.dynamicActions;
            for (let i = 0; i < dynamicAction.length; i++) {
                if (dynamicAction[i].erroneous === false) {
                    if (dynamicAction[i].displayName === action.displayName) {
                        return false;
                    }
                }
            }
        }
        return true;
    };

    onElementClick = (record, fileName) => {
        record.downloadFile = fileName;
        this.elementClicked.emit(record);
    };

    buildClonedColumns = () => {
        this.clonedColumns = this.selectedColumns
            .filter((columnId) => {
                const column = this.allColumns.find((columnRecord) => columnRecord.id === columnId);
                return column && !column.hideInAllColumnsList;
            })
            .slice(0);
    };

    buildActionsShowHideModal = (records: TableData[]) => {
        this.actionVisibility = {};
        (records || []).forEach((record) => {
            this.actionVisibility[record.recordId] = {};
            (this.recordActions || []).forEach((action) => {
                this.actionVisibility[record.recordId][action.displayName] = this.checkForDynamicActions(record, action);
            });
        });
    };

    trackByActionsMethod = (_index: number, action: any): number => {
        return action.displayName;
    };

    trackBySelectedColumnsMethod = (_index: number, columnId: any): number => {
        return columnId;
    };

    trackByAllColumnsMethod = (_index: number, columnId: any): number => {
        return columnId;
    };

    trackDataSource = (_index: number, record: TableData) => {
        return record && record.recordId;
    };

    copyToInboundRequestId = (record) => {
        const input = document.createElement('input');
        input.setAttribute('value', record.requestId);
        document.body.appendChild(input);
        input.select();
        const result = document.execCommand('copy');
        document.body.removeChild(input);
        return result;
    };

    private calculateTableHeight = () => {
        const definedHeight = this.height && parseInt(this.height.substring(0, this.height.indexOf('px')) || '0');
        const tableContainerElement = this.element.nativeElement.children[0];
        const height = definedHeight || tableContainerElement.offsetHeight;
        if (height) {
            this.tableHeight = height;
            this.range = Math.ceil(height / this.rowHeight) + VirtualTableComponent.BUFFER_SIZE;
            this.scrollStrategy.setScrollHeight(this.rowHeight, this.headerHeight);
        }
    };

    private startDataSource = () => {
        this.virtualDataSource = combineLatest([this.data, this.scrollStrategy.scrolledIndexChange]).pipe(
            map((value: any) => {
                const start = Math.max(0, (value[1] || 0) - VirtualTableComponent.BUFFER_SIZE);
                const end = Math.min(value[0].length, (value[1] || 0) + this.range);
                const dataToPush = value[0].slice(start, end);
                return dataToPush;
            })
        );
    };

    trackRecord = (item: any) => (item as TableData)?.recordId;

    ngOnInit() {
        this.calculateTableHeight();
        this.allTableColumns.pipe(takeUntil(this.unSubscribe)).subscribe((columns) => {
            if (columns?.length > 0) {
                this.calculateTableHeight();
            }
            this.pushSpecialColumns();
            this.columnKeyPair = {};
            this.allColumns = columns;
            this.allColumns.forEach((column) => {
                this.columnKeyPair[column.id] = column;
            });
            this.buildClonedColumns();
            this.checkFiltersSelected();
        });
        this.selectedColumns && this.selectedColumns.indexOf('log') === -1 && this.checkColumnsCount() && this.selectedColumns.push('log');
        this.generateColumnTypes();
        this.data.pipe(takeUntil(this.unSubscribe)).subscribe((data) => {
            if (data?.length > 0) {
                this.buildActionsShowHideModal(data);
                this.dataSource.data = data || [];
                this.checkAllRecordsSelected();
                this.dataSource.filterPredicate = (tableData, filter) => {
                    return this.selectedColumns.some((ele) => {
                        return (
                            ele !== 'table-actions' &&
                            ele !== 'log' &&
                            tableData[ele] &&
                            tableData[ele].toLocaleLowerCase().indexOf(filter) !== -1
                        );
                    });
                };
            } else if (!data || data.length === 0) {
                if (this.dataSource.data.length > 0) {
                    this.buildActionsShowHideModal([]);
                    this.dataSource.data = [];
                    this.checkAllRecordsSelected();
                }
            }
            !this.range && this.calculateTableHeight();
            if (!this.virtualDataSource) {
                this.startDataSource();
            }
        });
    }

    ngOnDestroy() {
        this.searchSubscription && this.searchSubscription.unsubscribe();
        this.selectAllColumnSubscription && this.selectAllColumnSubscription.unsubscribe();
        this.unSubscribe.next();
        this.unSubscribe.complete();
    }
}
